<form class="pesq-form">
  <mat-form-field appearance="outline" class="input-real">
      <mat-label>Status</mat-label>
      <mat-select >
        <mat-option value="option1">Todos</mat-option>
        <mat-option value="option2">Aguardando Processamento</mat-option>
        <mat-option value="option3">Em Processamento</mat-option>
        <mat-option value="option4">Em Validação</mat-option>
        <mat-option value="option5">Finalizada</mat-option>
        <mat-option value="option6">Cancelada</mat-option>
      </mat-select>
  </mat-form-field>
  <mat-form-field class="example-full-width input-real" appearance="outline">
      <mat-label>CPF/CNPJ</mat-label>
      <input matInput>
  </mat-form-field>
  <mat-form-field class="example-full-width input-real" appearance="outline">
      <mat-label>Nome</mat-label>
      <input matInput>
  </mat-form-field>

  <mat-form-field class="example-form-field input-real" appearance="outline">
    <mat-label>Periodo</mat-label>
    <mat-date-range-input
      [formGroup]="campaignOne"
      [rangePicker]="campaignOnePicker"
      [comparisonStart]="campaignTwo.value.start"
      [comparisonEnd]="campaignTwo.value.end">
      <input matStartDate placeholder="Start date" formControlName="start">
      <input matEndDate placeholder="End date" formControlName="end">
    </mat-date-range-input>
    <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
    <mat-datepicker-toggle matSuffix [for]="campaignOnePicker"></mat-datepicker-toggle>
    <mat-date-range-picker #campaignOnePicker></mat-date-range-picker>
  </mat-form-field>

  <button mat-raised-button color="primary" class="input-real-button">
      Filtrar
      <i class="material-icons search" >
          search
      </i>
  </button>
</form>
