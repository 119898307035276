import { Location } from '@angular/common';
import { Token } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/shared/services/http/auth.service';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit {
user
  constructor(
    public router: Router,
    private authService: AuthService,
    private location: Location
  ) { }

  ngOnInit(): void {
    this.verificaUsuario()
  }

  verificaUsuario(){
    this.user = localStorage.getItem('token')
    if(this.user == null){
     return this.user = false
    }
    return this.user = true
  }

  backtoHome(){ 
    console.log(this.user)
    if(this.user == true){
      this.location.back()
    }
    this.router.navigate([''])
  }
}
