
<div class="header text-center d-flex justify-content-between">
    <div></div>
    <h2>Exportar Pesquisa em Lote</h2>
    <button mat-icon-button aria-label="close dialog" mat-dialog-close style="margin-top: -4px;">
        <mat-icon>close</mat-icon>
    </button>
</div>

<div class="container mt-5">
    <p class="text"> Escolha em qual formato deseja exportar as pesquisas selecionadas:</p>
    <div class="d-flex justify-content-between">
        <mat-slide-toggle [checked]="form.pdf" (change)="form.pdf = $event.source.checked" name="pdf">PDF</mat-slide-toggle>
        <mat-slide-toggle [checked]="form.excel" (change)="form.excel = $event.source.checked"  name="excel">EXCEL</mat-slide-toggle>
    </div>
</div>
<div class="d-flex justify-content-between" style="margin: 10px 0 -24px 0;">
    <button mat-flat-button mat-dialog-close class="cancelar m-3"> Cancelar</button>
    <button  mat-flat-button class="gs-botaoAzulPadrao m-3" (click)="verificaTipoExportacao()"><mat-icon>download</mat-icon> Exportar</button>
</div>