<div class="form-ext">
  <mat-card class="pesq-re mt-3">
    <div class="header-pesq">
      <div class="title-pesq">
        <img class="img-title-pesq" src="../../../assets/icones/lupa-azul.svg" alt="view">
        <p>Histórico de Pesquisas</p>
      </div>
      <div class="container">
        <form class="novapesq-form">
        <div class="container">
          <div class="row d-flex justify-content-center align-items-center">
            <div class="col-md-2">
              <mat-form-field appearance="outline">
                <mat-label>Status</mat-label>
                <mat-select [(ngModel)]="formFiltro.status" name="status">
                  <mat-option value="">Todos</mat-option>
                  <mat-option value="2">Aguardando Processamento</mat-option>
                  <mat-option value="3">Em Processamento</mat-option>
                  <mat-option value="5">Em Validação</mat-option>
                  <mat-option value="4">Finalizada</mat-option>
                  <mat-option value="6">Cancelada</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-md-2">
              <mat-form-field class="example-full-width" appearance="outline">
                <mat-label>CPF/CNPJ</mat-label>
                <input matInput mask="CPF_CNPJ" [(ngModel)]="formFiltro.numero" name="numero" (keyup.enter)="pesquisarClicked()">
            </mat-form-field>
            </div>
            <div class="col-md">
              <mat-form-field class="example-full-width" appearance="outline">
                <mat-label>Nome</mat-label>
                <input matInput [(ngModel)]="formFiltro.nome" name="nome" (keyup.enter)="pesquisarClicked()">
              </mat-form-field>
            </div>
            <div class="col-md">
              <mat-form-field appearance="outline">
                <mat-label>{{labelTag}}</mat-label>
                <mat-select [(ngModel)]="formFiltro.tag" name="tag">
                  <mat-option value="">Todos</mat-option>
                  <mat-option *ngFor="let option of tagIds" [value]="option.tagId">{{option.tag}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-md col-mail">
              <mat-form-field class="example-form-field" appearance="outline">
                <mat-label>Periodo</mat-label>
                <mat-date-range-input
                  [formGroup]="campaignOne"
                  [rangePicker]="campaignOnePicker"
                  [comparisonStart]="campaignTwo.value.start"
                  [comparisonEnd]="campaignTwo.value.end">
                  <input matStartDate placeholder="DD/MM/AAAA" formControlName="start" [(ngModel)]="formFiltro.cadastroDe" name="CadastroAte" (keyup.enter)="pesquisarClicked()">
                  <input matEndDate placeholder="DD/MM/AAAA" formControlName="end" [(ngModel)]="formFiltro.cadastroAte" name="CadastroAte" (keyup.enter)="pesquisarClicked()">
                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix [for]="campaignOnePicker"></mat-datepicker-toggle>
                <mat-date-range-picker #campaignOnePicker></mat-date-range-picker>
              </mat-form-field>
              <button mat-flat-button (click)="pesquisarClicked()" class="gs-botaoAzulPadrao m-3">Filtrar <mat-icon>search</mat-icon></button>
              <button mat-flat-button (click)="clearForm(); pesquisarClicked()" class="gs-botaoAzulPadrao m-3">Limpar Filtros</button>
            </div>
          </div>
        </div>  
        </form>
      </div>  
    </div>

    <div class="separador"></div>
    <div class="resultado">
      <div class="pesq-table" infiniteScroll
        [infiniteScrollDistance]="2"
        [infiniteScrollThrottle]="50"
        (scrolled)="onScroll()"
        [infiniteScrollUpDistance]="1.5"
        [scrollWindow]="false">

        <div class="outer-wrapper">
          <div style="text-align: center;" *ngIf="resultadoPesquisa.length == 0">
            Nenhuma pesquisa encontrada.
          </div>
          <div class="table-wrapper" *ngIf="resultadoPesquisa.length != 0">
            <table class="table-grid">
              <thead class="thead-grid">
                  <th class="th-grid">Relatório</th>
                  <th class="th-grid">{{labelTag}}</th>
                  <th class="th-grid">CPF/CNPJ</th>
                  <th class="th-grid">Nome</th>
                  <th class="th-grid">Emissão</th>
                  <th class="th-grid">Data</th>
                  <th class="th-grid">Status</th>
                  <th class="th-grid">Progresso</th>
                  <th class="th-grid">Ações</th>
              </thead>
              <tbody class="tbody-grid">
                  <tr *ngFor="let o of resultadoPesquisa" class="tr-grid">
                      <td class="td-grid">
                        <div class="border-column">{{ajustaNome(o.pesquisa.fonteGrupo.produto.descricao,28)}}</div>
                      </td>
                      <td class="td-grid">
                        <div class="border-column" *ngIf="o.pesquisa.tags.length > 0">{{o.pesquisa?.tags[0]?.clienteTag.tag }}</div>
                        <div class="border-column" *ngIf="o.pesquisa.tags == 0"> - </div>
                      </td>
                      <td class="td-grid">
                        <div class="border-column">
                          {{o.pesquisa.numero}}
                        </div>  
                      </td>
                      <td class="td-grid">
                        <div class="border-column">
                          {{ajustaNome(o.pesquisa?.nome, 40)}}
                        </div>  
                      </td>
                      <td class="td-grid">
                        <div class="border-column">
                          {{o.pesquisa.usuarioCadastro?.nome}}
                        </div>
                      </td>
                      <td class="td-grid">
                        <div class="border-column">
                          {{ o.pesquisa.dataCadastro | date: 'dd/MM/yyyy HH:mm' }}
                        </div>
                      </td>
                      <td class="td-grid">
                        <div class="border-column">
                          {{ StatusPesquisa[o.pesquisa.status] == 'ResolucaoManual' ? 'Aguardando liberação' : StatusPesquisa[o.pesquisa.status] }}
                        </div>
                      </td>
                      <td *ngIf="o.pesquisa.status == StatusPesquisa.Rascunho" class="td-grid">
                        <div class="border-column">
                          2%
                          <div class="loader dot-space">
                            <span class="loader__dot">.</span><span class="loader__dot">.</span><span class="loader__dot">.</span>
                          </div>
                        </div>
                      </td>
                      <td *ngIf="o.pesquisa.status == StatusPesquisa.ResolucaoAutomatica" class="td-grid">
                        <div class="border-column">
                          {{ o.fontesProcessadas && o.fontes ? ((100 * (o.fontesProcessadas / o.fontes)).toFixed(0) +'%') : '0%' }}
                          <div class="loader dot-space">
                            <span class="loader__dot">.</span><span class="loader__dot">.</span><span class="loader__dot">.</span>
                          </div>
                        </div>
                      </td>
                      <td *ngIf="o.pesquisa.status == StatusPesquisa.ResolucaoManual" class="td-grid">
                        <div class="border-column">
                          {{ (100 * (o.fontesProcessadas / o.fontes)).toFixed(0) - 1 >= 1 ? (100 * (o.fontesProcessadas / o.fontes)).toFixed(0) - 1 +'%' : 1 + '%'}}
                          <div class="loader dot-space">
                            <span class="loader__dot">.</span><span class="loader__dot">.</span><span class="loader__dot">.</span>
                          </div>
                        </div>
                      </td>
                      <td *ngIf="o.pesquisa.status == StatusPesquisa.Finalizada" class="td-grid">
                        <div class="border-column">
                          100%
                        </div>
                      </td>
                      <td *ngIf="o.pesquisa.status == StatusPesquisa.FinalizadaParcial" class="td-grid">
                        <div class="border-column">
                          100%
                        </div> 
                      </td>
                      <td *ngIf="o.pesquisa.status == StatusPesquisa.Cancelada" class="td-grid">
                        <div class="border-column">
                          - 
                        </div>
                      </td>
                      <td>
                        <ul class="ul-table">
                          <!-- <li class="li-table"><button [disabled]="o.pesquisa.status != StatusPesquisa.Finalizada"><img class="icon-table" src="../../../assets/icones/ICONE-NOVO-VISUALIZAÇÃO.svg" width="20px" height="20px" (click)="visualizarClicked(o)" title="Visualizar a pesquisa em tela"></button></li> -->
                          <li class="li-table"><button [disabled]="o.pesquisa.status != StatusPesquisa.Finalizada" (click)="exportarClicked(o)"><img class="icon-table" width="20px" height="20px" src="../../../assets/icones/ICONE-NOVO-PDF.svg" title="Exportar a pesquisa em PDF"></button></li>
                          <li class="li-table"><button [disabled]="o.pesquisa.status != StatusPesquisa.Finalizada" (click)="exportarExcelClicked(o)"><img class="icon-table"width="20px" height="20px"  src="../../../assets/icones/ICONE-NOVO-EXCEL.svg" title="Exportar a pesquisa em Excel"></button></li>
                          <li class="li-table"><button [disabled]="o.pesquisa.status != StatusPesquisa.Finalizada" (click)="exportarReciboClicked(o)"><img class="icon-table" width="20px" height="20px" src="../../../assets/icones/novo-icone-recibo.svg" title="Emitir o recibo"></button></li>
                        </ul>
                      </td>
                  </tr>
              </tbody>
          </table>
        </div>
      </div>
      </div>
    </div>
  </mat-card>
</div>
