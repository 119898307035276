<div class="container">
    <div *ngFor="let form of forms; let i = index" class="form-container">
      <!-- <h3>Ativo {{ i + 1 }}</h3> -->
      <div class="row d-flex mt-2">
        <div class="col">
          <form [formGroup]="form">
            <mat-form-field appearance="outline" class="m-1">
              <mat-label>Tipo do Processo</mat-label>
              <input formControlName="tipoProcesso" name="tipoProcesso{{i}}" matInput>
            </mat-form-field>

            <mat-form-field class="example-full-width" appearance="outline" class="m-1">
              <mat-label>Número do Processo</mat-label>
              <input formControlName="numeroProcesso" name="numeroProcesso{{i}}" matInput appProcessoMask maxlength="25">
            </mat-form-field>
  
            <mat-form-field class="example-full-width" appearance="outline" class="m-1">
              <mat-label>Comarca</mat-label>
              <input formControlName="comarca" name="comarca{{i}}" matInput>
            </mat-form-field>
  
            <mat-form-field class="example-full-width" appearance="outline" class="m-1">
              <mat-label>UF</mat-label>
              <input formControlName="uf" name="uf{{i}}" matInput>
            </mat-form-field>
  
            <mat-form-field class="example-full-width" appearance="outline" class="m-1">
              <mat-label>Credor</mat-label>
              <input formControlName="credor" name="credor{{i}}" matInput>
            </mat-form-field>

            <mat-form-field class="example-full-width" appearance="outline" class="m-1">
              <mat-label>Valor de Causa</mat-label>
              <input formControlName="valorCausa" name="valorCausa{{i}}"  matInput currencyMask [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', align:' left' }">
            </mat-form-field>

            <mat-form-field class="example-full-width" appearance="outline" class="m-1">
              <mat-label>Ano do Processo</mat-label>
              <input formControlName="anoProcesso" name="anoProcesso{{i}}" matInput>
            </mat-form-field>

            <mat-form-field class="example-full-width" appearance="outline" class="m-1">
              <mat-label>Prazo de Recuperação</mat-label>
              <span matSuffix>Meses</span>
              <input type="number" formControlName="prazoRecuperacao" name="prazoRecuperacao{{i}}" matInput>
            </mat-form-field>

            <mat-form-field class="example-full-width" appearance="outline" class="m-1">
              <mat-label>Despesas Gerais</mat-label>
              <input formControlName="valorTotalCustaProcessos" name="valorTotalCustaProcessos{{i}}"  matInput currencyMask [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', align:' left' }">
            </mat-form-field>

            <mat-form-field class="example-full-width" appearance="outline" class="m-1">
              <mat-label>Tem proposta de acordo?</mat-label>
              <mat-select formControlName="propostaAcordo" name="propostaAcordo{{i}}">
                <mat-option [value]=true>Sim</mat-option>
                <mat-option [value]=false>Não</mat-option>
              </mat-select>
            </mat-form-field> 
          </form>
          <div class="d-flex justify-content-end" style="margin-top: -10px;">
            <mat-icon class="delete-icon" *ngIf="forms.length > 0" (click)="removeForm(i)">delete</mat-icon>
          </div>
          <hr>
        </div>
  
      </div>
    </div>
    <div class="m-1 mb-2 d-flex div-more" (click)="addForm()">
      <mat-icon class="icon-more">add</mat-icon> <span class="more">&nbsp;Adicionar Fatores</span>
    </div>
  </div>
  