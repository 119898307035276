import { Component, Inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Data } from '@angular/router';
import { DashboardService } from 'src/app/shared/services/dashboard/dashboard-service';
import { UiService } from 'src/app/shared/services/ui.service';

@Component({
  selector: 'app-contrato-cliente',
  templateUrl: './contrato-cliente.component.html',
  styleUrls: ['./contrato-cliente.component.scss']
})
export class ContratoClienteComponent implements OnInit {
  listaAnexos = [];
  documentoAnexado;
  nomeAnexo;
  @ViewChild('modalVisualizacaoContrato', { read: TemplateRef }) modalVisualizacaoContratoRef: TemplateRef<any>;
  modalVisualizacaoContrato;
  public innerHtml: any;

  constructor(
    private dashboardService: DashboardService,
    private dialogService: MatDialog,
    private uiService: UiService,
    private sanitizer: DomSanitizer,
    public dialogRef: MatDialogRef<ContratoClienteComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Data,
  ) { }

  ngOnInit(): void {
    this.uiService.loading();
    this.dashboardService.anexos(this.data.idCliente).subscribe(u => {
      this.uiService.loaded().subscribe(() => {
        this.listaAnexos = u;
        console.log(this.listaAnexos)
      });
    });

  }


  visualizarContrato(anexo) {
    let request = {
      idCliente: this.data.idCliente.toString(),
      id: anexo.id
    }
    this.dashboardService.downloadAnexo(request).subscribe(data => {
      var a = document.createElement("a");
      a.href = "data:application/pdf;base64," + data.arquivoConteudo;
      this.documentoAnexado = data.arquivoConteudo;
      this.nomeAnexo = data.arquivoNome;
      const base64Data = this.documentoAnexado; // Sua string base64 aqui
      const binaryData = atob(base64Data);
      const arrayBuffer = new Uint8Array(binaryData.length);

      for (let i = 0; i < binaryData.length; i++) {
        arrayBuffer[i] = binaryData.charCodeAt(i);
      }

      const blob = new Blob([arrayBuffer], { type: 'application/pdf' });
      const blobUrl = URL.createObjectURL(blob);
      this.setInnerHtml(blobUrl)
    });
    this.modalVisualizacaoContrato = this.dialogService.open(this.modalVisualizacaoContratoRef, {
      hasBackdrop: true,
    });
  }

  public setInnerHtml(pdfurl: string) {
    this.innerHtml = this.sanitizer.bypassSecurityTrustHtml(
      "<object data='" + pdfurl + "' type='application/pdf' class='embed-responsive-item' width='100%'' height='700px'>" +
      "Object " + pdfurl + " failed" +
      "</object>");
  }


}
