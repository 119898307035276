<div class="form-ext">
  <mat-card class="pesq-info">
    <mat-card-header class="title-pesq">
      <img class="img-title-pesq" src="../../../assets/icones/lupa-azul.svg" alt="view">
      <p> Pesquisa de Certidões</p>
    </mat-card-header>
    <form class="novapesq-form" [formGroup]="formCadastro">
      <div class="container">
        <div class="row d-flex justify-content-center align-items-center">
          <!-- <div class="col-sm">
              <mat-form-field appearance="outline">
                <mat-label>Tipo Documento</mat-label>
                <mat-select [(ngModel)]="formPesquisa.tipo" name="tipo">
                  <mat-option value="1">CPF</mat-option>
                  <mat-option value="2">CNPJ</mat-option>
                </mat-select>
              </mat-form-field>
            </div> -->
          <div class="col-md d-none">
            <mat-form-field appearance="outline">
              <mat-label class="label-black">Fontes</mat-label>
              <mat-select formControlName="idFonteGrupo" name="idFonteGrupo" status="primary" fullWidth size="small"
                (selectionChange)="onFonteValue($event)">
                <mat-option *ngFor="let u of listaFonteGrupo" [value]="u.fonteGrupo.id"> {{ u.fonteGrupo.nome }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-md">
            <mat-form-field class="example-full-width" appearance="outline">
              <mat-label>Numero do Documento</mat-label>
              <input matInput formControlName="numero" mask="CPF_CNPJ" name="numero">
            </mat-form-field>
          </div>
          <div class="col-md col-mail">
            <mat-slide-toggle formControlName="notificacao" (change)="checkEmail($event)" name="notificacao">Enviar por
              E-mail?</mat-slide-toggle>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 ml-2">
            <mat-form-field class="example-full-width" appearance="outline">
              <div class="chip-container">
                <mat-chip *ngFor="let item of addedItems" (removed)="removeChip(item)" class="rounded-chip d-flex justify-content-center align-items-center">
                  {{ item.tag }}
                  <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
              </div>
              <mat-label *ngIf="addedItems.length == 0">{{labelTag}}</mat-label>
              <input #autoInput matInput type="text" matInput [formControl]="myControl" [matAutocomplete]="auto" (keydown.enter)="onEnter($event)" [ngClass]="addedItems.length > 0 ? 'mt-3' : '' ">
              <mat-hint class="mt-3">Aperte ENTER para adicionar a {{labelTag}}</mat-hint>
              <mat-autocomplete #auto="matAutocomplete">
                <mat-option *ngFor="let option of filteredTags | async" class="d-flex justify-content-between">
                  <div class="d-flex justify-content-between">
                    <div (click)="addChip(option)" style="width: -webkit-fill-available;">{{ option.tag }}</div>
                    <div><mat-icon class="trashIcon" (click)="deletarTagExistente(option)">delete</mat-icon></div>
                  </div>
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
      </div>

        </div>
      </div>


      <div *ngIf="listaFontesAdicionais.length == 0" class="text-center m-4"> Não existem grupos de Certidões configuradas para esse usuário</div>
      <div class="m-4" *ngIf="listaFontesAdicionais.length > 0">
        <div class="row d-flex justify-content-between column" style="max-height: 380px; margin-top: 20px;">
          <p class="p-text">Selecione abaixo as Certidões que deseja emitir:</p>
          <div class="row col-8 row-fontes">
            <mat-checkbox style="margin-right: 5px;" [checked]="allChecked" (change)="toogleSelecionarTodos($event);"
              formControlName="selecionarTodasFontesOpcionais" name="selecionarTodasFontesOpcionais">
              <label
                class="label-black">{{formCadastro.get('selecionarTodasFontesOpcionais').value ? 'Desselecionar Todas' : 'Selecionar Todas'}}</label>
            </mat-checkbox>
            <div class="container">
              <ul class="list-unstyled ul-certidoes">
                  <li  class="list-item" *ngFor="let fonteItem of listaFontesAdicionais; let i = index ">
                    <div class="d-flex col-elipsis"
                    formArrayName="fontesSelecionaveis">
                    <mat-icon class="tooltip-icon mt-1" matTooltip={{fonteItem.fonteConfiguracao.descricaoFonte}}>help</mat-icon>
                    <div [formGroupName]="i" class="col-elipsis">
                      <mat-checkbox style="margin-right: 5px;" [checked]="itemChecked"
                        (change)="updateChkbxArray(fonteItem, $event); verificaCheckbox()" formControlName="selecionado"
                        name="selecionado"><label class="label-black">{{fonteItem.fonteConfiguracao.nome}}</label>
                      </mat-checkbox>
                    </div>
                  </div>
                  </li>
              </ul>
          </div>
            <div class="col-3 input-nirf">
              <div *ngFor="let item of formParametros">
                <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label>{{item.title}}</mat-label>
                  <input formControlName="{{item.key}}" name="{{item.key}}" type="tel" placeholder="0000000-0"
                    maxlength="8" matInput fullWidth fieldSize="small" autocomplete="off">
                </mat-form-field>
                <!-- <small class=" small-info text-danger">*Para emitir a certidao 12 sera necessario informar o NIRF.</small> -->
              </div>
            </div>
          </div>

          <div class="row col-2 cardResultadoSel text-center d-flex justify-content-center d-flex flex-column"
            *ngIf="formCadastro.value.fontesSelecionaveis">
            <div class="num-creditosSel">
              <div class="titulo-selecionado">
                <p class="total-fontes-selecionadas">Fontes Selecionadas</p>
                <span class="valor-selecionado">{{ formCadastro.value.fontesSelecionaveis.length }}</span></div>
            </div>
            <button mat-flat-button [disabled]="formCadastro.invalid" (click)="verificaTipoDocumento(); cadastrar();"
              class="gs-botaoAzulPadrao m-3">Emitir Certidões</button>
            <!-- <div class="col-6 num-creditosSel text-left">
              <div class="titulo-selecionado">Valor Total: <span
                  class="valor-selecionado">{{ ('R$ '+ valorTotalFontesSelecionadas().toFixed(2)) }}</span></div>
            </div> -->
          </div>

        </div>


        <div class="container mt-3">
          <div class="row d-flex~">
            <!-- <div class="col-sm">
                  <mat-form-field appearance="outline">
                    <mat-label>Tipo Documento</mat-label>
                    <mat-select [(ngModel)]="formPesquisa.tipo" name="tipo">
                      <mat-option value="1">CPF</mat-option>
                      <mat-option value="2">CNPJ</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div> -->
            <!-- <div class="col-3" *ngFor="let item of formFiltros">
              <mat-form-field appearance="outline">
                <mat-label class="label-black">{{item.title}}</mat-label>
                <mat-select status="primary" multiple formControlName="{{item.key}}" name="{{item.key}}" fullWidth
                  size="small">
                  <mat-option *ngFor="let valor of item.valor" value="{{valor}}">{{valor}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div> -->


            <!-- ============================nirf ====================== -->

            <!-- <div class="col-3" *ngFor="let item of formParametros">
              <mat-form-field class="example-full-width" appearance="outline">
                <mat-label>{{item.title}}</mat-label>
                <input formControlName="{{item.key}}" name="{{item.key}}" type="text" matInput fullWidth
                  fieldSize="small" autocomplete="off">
              </mat-form-field>
            </div> -->






            <!-- <div class="col-2">
              <button mat-flat-button (click)="adicionarFiltro()" class="gs-botaoAzulPadrao m-3">Adicionar
                Filtro</button>
            </div> -->
          </div>
          <div class="col-5 m-2" *ngIf="filtros && filtros.length > 0">
            <div class="row">
              <table cellpadding="5" style="width: 100%; border: 1px solid #004563b3; margin-top: 0.5rem;">
                <tr>
                  <th *ngFor="let item of formFiltros" style="text-align: center;">{{item.title}}</th>
                </tr>
                <tr *ngFor="let filtro of filtros">
                  <td *ngFor="let x of filtro" style="text-align: center;">{{ x.value }} </td>
                  <td style="text-align: center;" class="text-danger">
                    <mat-icon style="cursor: pointer;" (click)="excluirClicked(filtro)">delete_forever</mat-icon>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </form>
  </mat-card>
  <div class="separador"></div>
  <mat-card class="pesq-re mt-3">
    <div class="header-pesq">
      <div class="title-pesq">
        <img class="img-title-pesq" src="../../../assets/icones/lupa-azul.svg" alt="view">
        <p>Histórico de Certidões Solicitadas</p>
      </div>
      <div class="container">
        <form class="novapesq-form">
          <div class="container">
            <div class="row d-flex justify-content-center align-items-center">
              <div class="col-md-2">
                <mat-form-field appearance="outline">
                  <mat-label>Status</mat-label>
                  <mat-select [(ngModel)]="formFiltro.status" name="status">
                    <mat-option value="">Todos</mat-option>
                    <mat-option value="2">Aguardando Processamento</mat-option>
                    <mat-option value="3">Em Processamento</mat-option>
                    <mat-option value="5">Em Validação</mat-option>
                    <mat-option value="4">Finalizada</mat-option>
                    <mat-option value="6">Cancelada</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-md-2">
                <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label>CPF/CNPJ</mat-label>
                  <input matInput mask="CPF_CNPJ" [(ngModel)]="formFiltro.numero" name="numero"
                    (keyup.enter)="pesquisarClicked()">
                </mat-form-field>
              </div>
              <div class="col-md">
                <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label>Nome</mat-label>
                  <input matInput [(ngModel)]="formFiltro.nome" name="nome" (keyup.enter)="pesquisarClicked()">
                </mat-form-field>
              </div>
              <div class="col-md">
                <mat-form-field appearance="outline">
                  <mat-label>{{labelTag}}</mat-label>
                  <mat-select [(ngModel)]="formFiltro.tag" name="tag">
                    <mat-option value="">Todos</mat-option>
                    <mat-option *ngFor="let option of tagIds" [value]="option.tagId">{{option.tag}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-md col-mail">
                <mat-form-field class="example-form-field" appearance="outline">
                  <mat-label>Periodo</mat-label>
                  <mat-date-range-input [formGroup]="campaignOne" [rangePicker]="campaignOnePicker"
                    [comparisonStart]="campaignTwo.value.start" [comparisonEnd]="campaignTwo.value.end">
                    <input matStartDate placeholder="DD/MM/AAAA" formControlName="start"
                      [(ngModel)]="formFiltro.cadastroDe" name="CadastroAte" (keyup.enter)="pesquisarClicked()">
                    <input matEndDate placeholder="DD/MM/AAAA" formControlName="end"
                      [(ngModel)]="formFiltro.cadastroAte" name="CadastroAte" (keyup.enter)="pesquisarClicked()">
                  </mat-date-range-input>
                  <mat-datepicker-toggle matSuffix [for]="campaignOnePicker"></mat-datepicker-toggle>
                  <mat-date-range-picker #campaignOnePicker></mat-date-range-picker>
                </mat-form-field>
                <button mat-flat-button (click)="pesquisarClicked()" class="gs-botaoAzulPadrao m-3">Filtrar <mat-icon>
                    search</mat-icon></button>
                <button mat-flat-button (click)="clearForm(); pesquisarClicked()" class="gs-botaoAzulPadrao m-3">Limpar
                  Filtros</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>

    <div class="separador"></div>
    <div class="resultado d-flex justify-content-center">
      <div class="line-center"></div>

      <div class="pesq-table" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50"
        (scrolled)="onScroll()" [infiniteScrollUpDistance]="1.5" [scrollWindow]="false">

        <div class="outer-wrapper">
          <div style="text-align: center;" *ngIf="resultadoPesquisa.length == 0">
            Nenhuma pesquisa encontrada.
          </div>
          <div class="table-wrapper" *ngIf="resultadoPesquisa.length != 0">
            <table class="table-grid">
              <thead class="thead-grid">
                <th class="th-grid">{{labelTag}}</th>
                <th class="th-grid">Relatório</th>
                <th class="th-grid">CPF/CNPJ</th>
                <th class="th-grid">Nome</th>
                <th class="th-grid">Emissão</th>
                <th class="th-grid">Data</th>
                <th class="th-grid">Certidões</th>
                <th class="th-grid">Status</th>
                <th class="th-grid">Progresso</th>
                <th class="th-grid">Ações</th>
              </thead>
              <tbody class="tbody-grid">
                <tr *ngFor="let o of resultadoPesquisa" class="tr-grid">
                  <td class="td-grid">
                    <div class="border-column hover-tag" *ngIf="o.pesquisa.tags.length > 0" (click)="editarTag(o.pesquisa)"  title="Clique para editar {{labelTag}}">{{o.pesquisa?.tags[0]?.clienteTag.tag }}</div>
                    <div class="border-column hover-tag" *ngIf="o.pesquisa.tags == 0" (click)="editarTag(o.pesquisa)"  title="Clique para editar {{labelTag}}"> - </div>
                  </td>
                  <td class="td-grid">
                    <div class="border-column">{{ajustaNome(o.pesquisa.fonteGrupo.produto.descricao,28)}}</div>
                  </td>
                  <td class="td-grid">
                    <div class="border-column">
                      {{o.pesquisa.numero}}
                    </div>
                  </td>
                  <td class="td-grid">
                    <div class="border-column">
                      {{ajustaNome(o.pesquisa?.nome, 40)}}
                    </div>
                  </td>
                  <td class="td-grid">
                    <div class="border-column">
                      {{o.pesquisa.usuarioCadastro?.nome}}
                    </div>
                  </td>
                  <td class="td-grid">
                    <div class="border-column">
                      {{ o.pesquisa.dataCadastro | date: 'dd/MM/yyyy HH:mm' }}
                    </div>
                  </td>
                  <td class="td-grid text-center">
                    <div class="border-column">
                      {{o.fontes -1}}
                    </div>
                  </td>
                  <td class="td-grid">
                    <div class="border-column">
                      {{ StatusPesquisa[o.pesquisa.status] == 'ResolucaoManual' ? 'Aguardando liberação' : StatusPesquisa[o.pesquisa.status] }}
                    </div>
                  </td>
                  <td *ngIf="o.pesquisa.status == StatusPesquisa.Rascunho" class="td-grid">
                    <div class="border-column dots-center">
                      2%
                      <div class="loader dot-space">
                        <span class="loader__dot">.</span><span class="loader__dot">.</span><span
                          class="loader__dot">.</span>
                      </div>
                    </div>
                  </td>
                  <td *ngIf="o.pesquisa.status == StatusPesquisa.ResolucaoAutomatica" class="td-grid">
                    <div class="border-column dots-center">
                      {{ o.fontesProcessadas && o.fontes ? ((100 * (o.fontesProcessadas / o.fontes)).toFixed(0) +'%') : '0%' }}
                      <div class="loader dot-space">
                        <span class="loader__dot">.</span><span class="loader__dot">.</span><span
                          class="loader__dot">.</span>
                      </div>
                    </div>
                  </td>
                  <td *ngIf="o.pesquisa.status == StatusPesquisa.ResolucaoManual" class="td-grid">
                    <div class="border-column dots-center">
                      {{ (100 * (o.fontesProcessadas / o.fontes)).toFixed(0) - 1 +'%' }}*
                      <div class="loader dot-space">
                        <span class="loader__dot">.</span><span class="loader__dot">.</span><span
                          class="loader__dot">.</span>
                      </div>
                    </div>
                  </td>
                  <td *ngIf="o.pesquisa.status == StatusPesquisa.Finalizada" class="td-grid">
                    <div class="border-column">
                      100%
                    </div>
                  </td>
                  <td *ngIf="o.pesquisa.status == StatusPesquisa.Cancelada" class="td-grid">
                    <div class="border-column">
                      -
                    </div>
                  </td>
                  <td>
                    <ul *ngIf="o.pesquisa.status == StatusPesquisa.Finalizada" class="ul-table d-flex justify-content-center">
                      <li class="li-table"><button
                          (click)="exportarClicked(o)"><img class="icon-table" width="20px" height="20px"
                            src="../../../assets/icones/ICONE-NOVO-PDF.svg" title="Exportar a pesquisa em PDF"></button>
                      </li>
                      <li class="li-table"><button [disabled]="o.pesquisa.status != StatusPesquisa.Finalizada" (click)="exportarReciboClicked(o)"><img class="icon-table" width="20px" height="20px" src="../../../assets/icones/novo-icone-recibo.svg" title="Emitir o recibo"></button></li>
                    </ul>
                    <ul *ngIf="o.pesquisa.status != StatusPesquisa.Finalizada" class="ul-table d-flex justify-content-center">
                      <li class="li-table"><button
                          (click)="pdfNaoConcluido()"><img class="icon-table" width="20px" height="20px"
                            src="../../../assets/icones/ICONE-NOVO-PDF.svg" title="Exportar a pesquisa em PDF"></button>
                      </li>
                    </ul>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </mat-card>
</div>
