import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { Console } from 'console';
import { takeWhile, timer } from 'rxjs';
import { PesquisaService } from 'src/app/shared/services/http/pesquisa.service';

@Component({
    selector: 'app-escritura-e-procuracoes',
    templateUrl: './escritura-e-procuracoes.component.html',
    styleUrls: ['./escritura-e-procuracoes.component.scss']
})
export class EscrituraEProcuracoesComponent implements OnInit {

    @Input() idPesquisa: number;
    @Input() aba: any;
    @Input() statusPesquisa: number;


    dataEscrituraEProcuracoes;
    isLoadingEscrituraEProcuracoes = false;
    exibeEscrituraEProcuracoes = false;
    exibeMensagemEscrituraEProcuracoes = false;
    exibeReprocessarEscrituraEProcuracoes = false;

    fontesSelecionadas

    panelOpenState = false


    constructor(
        private pesquisaService: PesquisaService
    ) { }

    ngOnInit(): void {
        if (this.aba.novaPesquisa) {
            this.fontesSelecionadas = this.aba?.dadosPesquisa?.valorAdicional?.fontesAdicionais;
            console.log(this.fontesSelecionadas);
        }

        if (!this.aba.novaPesquisa) {
            let objetoParse = JSON.parse(this.aba.dadosPesquisa);
            this.fontesSelecionadas = objetoParse.FontesAdicionais
            console.log(this.fontesSelecionadas);
        }

        const escrituraEProcuracoesMap = {
            230: this.getEscrituraEProcuracoes.bind(this),

        };

        this.fontesSelecionadas.forEach(fonteId => {
            const escrituraEProcuracoesFunc = escrituraEProcuracoesMap[fonteId];
            if (escrituraEProcuracoesFunc) {
                escrituraEProcuracoesFunc(this.idPesquisa, fonteId);
            }
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.statusPesquisa && changes.statusPesquisa.currentValue !== undefined) {
        }
    }

    getEscrituraEProcuracoes(idPesquisa, fonte) {
        this.isLoadingEscrituraEProcuracoes = true;
        this.exibeMensagemEscrituraEProcuracoes = false;
        this.exibeReprocessarEscrituraEProcuracoes = false;
        let attempts = 0;

        const subscription = timer(0, 15000).pipe(
            takeWhile(() => attempts < 5) // Limita a 5 tentativas
        ).subscribe(() => {
            this.pesquisaService.getEscrituraEProcuracoes(idPesquisa, fonte).subscribe(
                response => {
                    this.dataEscrituraEProcuracoes = response;
                    console.log(response.codigo)
                    switch (response.codigo) {
                        case 4000:
                            this.exibeEscrituraEProcuracoes = false;
                            this.isLoadingEscrituraEProcuracoes = false;
                            subscription.unsubscribe();
                            break;
                        case 1000:
                            this.exibeEscrituraEProcuracoes = true;
                            this.isLoadingEscrituraEProcuracoes = false;
                            this.exibeMensagemEscrituraEProcuracoes = false;
                            subscription.unsubscribe();
                            break;
                        case 2000:
                            this.exibeEscrituraEProcuracoes = true;
                            this.isLoadingEscrituraEProcuracoes = false;
                            subscription.unsubscribe();
                            break;
                        case 3001:
                            this.exibeEscrituraEProcuracoes = true;
                            this.isLoadingEscrituraEProcuracoes = false;
                            this.exibeMensagemEscrituraEProcuracoes = true;
                            subscription.unsubscribe();
                            break;
                        case 3000:
                            this.exibeEscrituraEProcuracoes = true;
                            this.isLoadingEscrituraEProcuracoes = false;
                            this.exibeMensagemEscrituraEProcuracoes = true;
                            attempts++;
                            if (attempts >= 5) {
                                this.exibeReprocessarEscrituraEProcuracoes = true;
                                subscription.unsubscribe();
                            }
                            break;
                    }
                },
                error => {
                    attempts++;
                    if (attempts >= 5) {
                        this.exibeEscrituraEProcuracoes = true;
                        this.isLoadingEscrituraEProcuracoes = false;
                        subscription.unsubscribe(); // Interrompe o intervalo após 5 tentativas
                    }
                    else {
                        this.exibeEscrituraEProcuracoes = true;
                        this.isLoadingEscrituraEProcuracoes = true;
                    }
                }
            );
        });
    }

}
