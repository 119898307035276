import { ViewEncapsulation } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { Router,NavigationEnd  } from '@angular/router';
import { filter } from 'rxjs/operators';

export interface PeriodicElement {
  relatorio: string;
  cpf: string;
  nome: string;
  emissao: string;
  data: string;
  status: string;
  progresso: number;
}

@Component({
  selector: 'table-result',
  templateUrl: './table-result.component.html',
  styleUrls: ['./table-result.component.scss'],
  // encapsulation: ViewEncapsulation.None
})


export class TableResultComponent implements OnInit {
  currentRoute: string;

  resultadoPesquisa : any  = [
    {relatorio: 'Informações Cadastrais', cpf: '091.183.037-57' , nome: 'ULISSES ROSARIO MAEDA', emissao: 'Bonatte', data:'05/10/2022 12:33:01', status:'Finalizada', progresso:100},
    {relatorio: 'Informações Cadastrais', cpf: '091.183.037-57' , nome: 'ULISSES ROSARIO MAEDA', emissao: 'Bonatte', data:'05/10/2022 12:33:01', status:'Finalizada', progresso:100},
    {relatorio: 'Informações Cadastrais', cpf: '091.183.037-57' , nome: 'ULISSES ROSARIO MAEDA', emissao: 'Bonatte', data:'05/10/2022 12:33:01', status:'Finalizada', progresso:100},
    {relatorio: 'Informações Cadastrais', cpf: '091.183.037-57' , nome: 'ULISSES ROSARIO MAEDA', emissao: 'Bonatte', data:'05/10/2022 12:33:01', status:'Finalizada', progresso:100},
    {relatorio: 'Informações Cadastrais', cpf: '091.183.037-57' , nome: 'ULISSES ROSARIO MAEDA', emissao: 'Bonatte', data:'05/10/2022 12:33:01', status:'Finalizada', progresso:100},
    {relatorio: 'Informações Cadastrais', cpf: '091.183.037-57' , nome: 'ULISSES ROSARIO MAEDA', emissao: 'Bonatte', data:'05/10/2022 12:33:01', status:'Finalizada', progresso:100},
    {relatorio: 'Informações Cadastrais', cpf: '091.183.037-57' , nome: 'ULISSES ROSARIO MAEDA', emissao: 'Bonatte', data:'05/10/2022 12:33:01', status:'Finalizada', progresso:100},
    {relatorio: 'Informações Cadastrais', cpf: '091.183.037-57' , nome: 'ULISSES ROSARIO MAEDA', emissao: 'Bonatte', data:'05/10/2022 12:33:01', status:'Finalizada', progresso:100},
    {relatorio: 'Informações Cadastrais', cpf: '091.183.037-57' , nome: 'ULISSES ROSARIO MAEDA', emissao: 'Bonatte', data:'05/10/2022 12:33:01', status:'Finalizada', progresso:100},
    {relatorio: 'Informações Cadastrais', cpf: '091.183.037-57' , nome: 'ULISSES ROSARIO MAEDA', emissao: 'Bonatte', data:'05/10/2022 12:33:01', status:'Finalizada', progresso:100},
  ];
  displayedColumns: string[] = ['relatorio', 'cpf', 'nome', 'emissao', 'data', 'status', 'progresso', 'acoes'];
  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
    console.log(this.router['location']._platformLocation.location.origin);
  }

}
