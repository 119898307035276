import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserStore } from 'src/app/@core/stores/user.store';
import { ConfiguracaoService } from 'src/app/shared/services/http/configuracao.service';
import { FonteGrupoService } from 'src/app/shared/services/http/fonte-grupo.service';
import { UsuarioService } from 'src/app/shared/services/http/usuario.service';
import { UtilService } from 'src/app/shared/services/http/util.service';
import { UiService } from 'src/app/shared/services/ui.service';
import { ClientePagamentoService } from './../../../shared/services/http/cliente-pagamento.service';
import { FormaPagamento } from './../../../shared/enums/forma-pagamento.enum';
import { User } from 'src/app/@core/interfaces/common/users';
import { ToastrService } from 'ngx-toastr';
import { ColdObservable } from 'rxjs/internal/testing/ColdObservable';

@Component({
  selector: 'app-popcredito',
  templateUrl: './popcredito.component.html',
  styleUrls: ['./popcredito.component.scss']
})
export class PopcreditoComponent implements OnInit {

  formNovoCredito: FormGroup;
  formaPagamento: any  = []; 
  usuario: any;

  constructor(
    protected fb: FormBuilder, 
    private clientePagamentoService: ClientePagamentoService, 
    private usuarioService: UsuarioService, 
    private utilService: UtilService, 
    private fonteGrupoService : FonteGrupoService,
    private userStore: UserStore,
    private toastr: ToastrService,
    private configuracaoService : ConfiguracaoService,
    private ui: UiService
  ) { }
  

  ngOnInit(): void {
    this.formNovoCredito = this.fb.group({  
      clienteId:              0, 
      pacoteId:               0,      
      formapagamento:         this.fb.control(FormaPagamento['Cartão Crédito'].toString(), []),
      valor:                  this.fb.control('', [Validators.required]),
      numerocartao:           this.fb.control('', []),
      validadecartao:         this.fb.control('', []),
      codigosegurancacartao:  this.fb.control('', []),
      nometitularcartao:      this.fb.control('', []),
      payment_token:          this.fb.control('', []),
      rua:                    this.fb.control('', []),
      numero:                 this.fb.control('', []),
      cep:                    this.fb.control('', []),
      bairro:                 this.fb.control('', []),
      municipio:              this.fb.control('', []),
      uf:                     this.fb.control('', []),
      complemento:            this.fb.control('', [])
    }, { updateOn: 'blur' });  
    this.userStore.onUserStateChange().subscribe((user: User) => {        
      if(!user) return;    
      this.usuario = user; 
    });  

    console.log(this.usuario);

    this.formaPagamento = 3;
  }

  
    formaPagamentoChanged(event: any) {

      this.formaPagamento = event.value;

        this.formNovoCredito.markAsPristine();
        if(this.formNovoCredito != null){

        // CARTAO DE CREDITO
        if(this.formaPagamento == '3') {
          console.log(event.value);

            this.formNovoCredito.get("numerocartao").setValidators([Validators.required]);
            this.formNovoCredito.get("validadecartao").setValidators([Validators.required]);
            this.formNovoCredito.get("codigosegurancacartao").setValidators([Validators.required]);
            this.formNovoCredito.get("nometitularcartao").setValidators([Validators.required]);

            // TODO BONATTE: coloquei esses dados do endereço como obrigatórios mas não sei se são realmente obrigatórios na API da gerencia net
            this.formNovoCredito.get("rua").setValidators([Validators.required]);
            this.formNovoCredito.get("numero").setValidators([Validators.required]);
            this.formNovoCredito.get("cep").setValidators([Validators.required]);
            this.formNovoCredito.get("bairro").setValidators([Validators.required]);
            this.formNovoCredito.get("municipio").setValidators([Validators.required]);
            this.formNovoCredito.get("uf").setValidators([Validators.required]);
        } else {
            this.formNovoCredito.get("numerocartao").clearValidators();
            this.formNovoCredito.get("numerocartao").setErrors(null);
            this.formNovoCredito.get("validadecartao").clearValidators();
            this.formNovoCredito.get("validadecartao").setErrors(null);
            this.formNovoCredito.get("codigosegurancacartao").clearValidators();
            this.formNovoCredito.get("codigosegurancacartao").setErrors(null);
            this.formNovoCredito.get("nometitularcartao").clearValidators();
            this.formNovoCredito.get("nometitularcartao").setErrors(null);

            
            this.formNovoCredito.get("rua").clearValidators();
            this.formNovoCredito.get("rua").setErrors(null);
            this.formNovoCredito.get("numero").clearValidators();
            this.formNovoCredito.get("numero").setErrors(null);
            this.formNovoCredito.get("bairro").clearValidators();
            this.formNovoCredito.get("bairro").setErrors(null);
            this.formNovoCredito.get("cep").clearValidators();
            this.formNovoCredito.get("cep").setErrors(null);
            this.formNovoCredito.get("municipio").clearValidators();
            this.formNovoCredito.get("municipio").setErrors(null);
            this.formNovoCredito.get("uf").clearValidators();
            this.formNovoCredito.get("uf").setErrors(null);
        }
      }
    }

    
    confirmarNovoCreditoClicked() {   
      Object.keys(this.formNovoCredito.controls).forEach(f => this.formNovoCredito.get(f).markAsDirty({ onlySelf: true }));
      if(!this.formNovoCredito.valid) {
        this.toastr.error("Valores preenchidos incorretamente no formulário");
          return;
      }

      this.ui.loading();

      const formaPagamentoSel = Number(this.formaPagamento);
      this.formNovoCredito.get("formapagamento").setValue(this.formaPagamento);

      let callback = () => {
        this.pagamentoClicked();                                    
      };

      let requisicaoPagamento = this.formNovoCredito.value;
      requisicaoPagamento.valor = requisicaoPagamento.valor.replace(",", ".");
      
      // PIX
      if(formaPagamentoSel == 1) {
          this.clientePagamentoService.registrarPagamentoGerencianetPIX(requisicaoPagamento).subscribe(callback);
          return;
      }

      // BOLETO
      if(formaPagamentoSel == 2) {
          this.clientePagamentoService.registrarPagamentoGerencianetBoleto(requisicaoPagamento).subscribe(callback);
          return;
      }

      // CREDITO
      if(formaPagamentoSel == 3) {
          this.getPaymentToken(requisicaoPagamento).then((response: any) => {
              console.log(`token`, response);
              if(response.token) {
                  requisicaoPagamento.payment_token = response.token;
                  this.clientePagamentoService.registrarPagamentoGerencianetCartao(requisicaoPagamento).subscribe(callback);
              } else {
                  this.ui.loaded().subscribe(() => {
                      this.ui.error("Ocorreu uma falha inesperada ao tentar realizar o pagamento via cartão.");
                  });                    
              }
          });    
          return;        
      }        
  }

  pagamentoPendente: any;
  pagamentoClicked() {
    this.ui.loading();
    this.clientePagamentoService.getPagamentoPendente(this.usuario.cliente.id).subscribe(data => {
        // se possui pagamento pendente, abre o modal com informacoes deste pagamento
        if(data) {
            this.pagamentoPendente = data;
            if(this.pagamentoPendente) {
                this.pagamentoPendente.dados = JSON.parse(this.pagamentoPendente.dados);
            }
            //this.ui.loaded().subscribe(() => {
            //   this.modalPagamentoPendente = this.dialogService.open(this.modalPagamentoPendenteRef, {
            //        hasBackdrop: true,
            //        closeOnBackdropClick: false,
            //        closeOnEsc: false
            //    });
            //});
            return;
        }
        
        // caso nao possua pagamento pendente, abre modal de novo pagamento
        this.formNovoCredito.reset();
        this.formNovoCredito.get('clienteId').setValue(this.usuario.cliente.id);
        this.formNovoCredito.get('pacoteId').setValue(this.usuario.cliente.pacote.id);
        this.formaPagamento  = "1";
        this.formNovoCredito.get("nometitularcartao").setValue("");
        this.formNovoCredito.get("payment_token").setValue("");

        //this.ui.loaded().subscribe(() => {
        //    this.modalNovoCredito = this.dialogService.open(this.modalNovoCreditoRef, {
        //        hasBackdrop: true,
        //        closeOnBackdropClick: false,
        //        closeOnEsc: false
        //    });
        //});
    });
  }

  //TODO
  pesquisaCep(event :any) {}

  bandeiraCartao = '-';

    atualizaBandeira(event :any) {
        let numero = event.target.value;
        
        if(!numero) {
            this.bandeiraCartao = "-";
            return;
        }
        this.bandeiraCartao = "-";

        if(numero.startsWith("4")) {
            this.bandeiraCartao =  "VISA";
        }
        if(numero.startsWith("5")) {
            this.bandeiraCartao =  "MASTERCARD";
        }
        // TODO BONATTE: conferir se está correto padrão do ELO
        if(numero.startsWith("6") && !numero.startsWith("60")) {
            this.bandeiraCartao =  "ELO";
        }
        if(numero.startsWith("34") || numero.startsWith("37")) {
            this.bandeiraCartao =  "AMEX";
        }
        if(numero.startsWith("38") || numero.startsWith("60")) {
            this.bandeiraCartao =  "HIPERCARD";
        }        
    }
   
  
  getPaymentToken(dadosCartao: any) {
    // {
    //     brand: 'visa', 
    //     number: '4012001038443335',
    //     cvv: '123', 
    //     expiration_month: '05',
    //     expiration_year: '2021'
    // }            
    let requisicaoPaymentToken = {
        brand: this.bandeiraCartao.toLowerCase(),
        number: dadosCartao.numerocartao,
        cvv: dadosCartao.codigosegurancacartao,
        expiration_month: dadosCartao.validadecartao.substring(0, 2),
        expiration_year: dadosCartao.validadecartao.substring(2)
    };

    console.log(requisicaoPaymentToken);
    return new Promise((resolve) => {
        window['checkoutApi'].getPaymentToken(requisicaoPaymentToken,
            function (error: any, response: any) {
                if (error) {
                  console.log("sdfsdf");
                    console.error(error);
                    resolve(error);
                } else {
                    resolve( { token: response.data.payment_token });                        
                }
            });
    });
  }

  inicializarGerenciaNet() {
    //payment_token
    var s = document.createElement('script');
    s.type = 'text/javascript';
    let random = Math.random() * 1000000;
    let v = random.toString() + "";
    s.src = 'https://api.gerencianet.com.br/v1/cdn/0ecc50288844179e6d084ad4f795606f/' + v;
    s.async = false;
    s.id = '0ecc50288844179e6d084ad4f795606f';
    if (!document.getElementById('0ecc50288844179e6d084ad4f795606f')) {
        document.getElementsByTagName('head')[0].appendChild(s);
    };

    window['$gn'] = {
        validForm: true,
        processed: false,
        done: {},
        ready: function(fn: any) {
            window['$gn'].done = fn;
        }
    };


    window['$gn'].ready(function (checkout: any) {
        window['checkoutApi'] = checkout;
    });
  }
  
}
