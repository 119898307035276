import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class UtilService {

    // private baseUrl = environment.baseUrl + "/api/motivo-ajuste-massivo";
    private baseUrl = environment.apiUrl + "/util";

    constructor(public http: HttpClient) { }

    resolverNome(item:any, produto: string): Observable<any> {
        return this.http.post(`${this.baseUrl}/resolver-nome`, item,  { headers: { "Produto": produto }});
    }

}
