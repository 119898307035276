import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { MatListModule } from '@angular/material/list';
import { MatNativeDateModule }from '@angular/material/core';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTreeModule } from '@angular/material/tree';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule, MatCalendar } from '@angular/material/datepicker'; 
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { NgApexchartsModule } from 'ng-apexcharts';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDialogModule } from '@angular/material/dialog';
import { NgxMaskModule, IConfig } from 'ngx-mask'
import { NgxSpinnerModule } from 'ngx-spinner';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import {MatMenuModule} from '@angular/material/menu';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { NgxCpfCnpjModule } from  'ngx-cpf-cnpj';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { ExportAsModule } from 'ngx-export-as';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatTabsModule} from '@angular/material/tabs';
import {MatChipsModule} from '@angular/material/chips';
import {MatRadioModule} from '@angular/material/radio';
import {MatExpansionModule} from '@angular/material/expansion';
import { NgxGraphModule } from '@swimlane/ngx-graph';

import localePt from '@angular/common/locales/pt';
import { registerLocaleData, DecimalPipe } from '@angular/common';
import { ReplaceCommaWithDotDirective } from './directives/replace-comma-with-dot.directive';
import { CurrencyMaskDirective } from './directives/currency-mask.directive';
import { ProcessoMaskDirective } from './directives/processo-mask.directive';
registerLocaleData(localePt, 'pt-BR');

@NgModule({
  declarations: [
    ReplaceCommaWithDotDirective,
    CurrencyMaskDirective,
    ProcessoMaskDirective
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatButtonModule,
    MatSnackBarModule,
    MatFormFieldModule,
    MatInputModule,
    MatCardModule,
    MatNativeDateModule,
    MatListModule,
    MatSidenavModule,
    MatTreeModule,
    MatIconModule,
    MatDividerModule,
    MatSelectModule,
    MatDatepickerModule,
    MatPaginatorModule,
    MatTableModule,
    NgApexchartsModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    NgxMaskModule.forRoot(),
    InfiniteScrollModule,
    NgxSpinnerModule,
    MatMenuModule,
    MatSlideToggleModule,
    NgxCpfCnpjModule,
    NgxChartsModule,
    ExportAsModule,
    MatCheckboxModule,
    MatTooltipModule,
    MatAutocompleteModule,
    MatButtonToggleModule,
    MatTabsModule,
    MatChipsModule,
    MatRadioModule,
    MatExpansionModule,
    NgxGraphModule
  ],

  exports:[
    BrowserModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatButtonModule,
    MatSnackBarModule,
    MatFormFieldModule,
    MatInputModule,
    MatCardModule,
    MatNativeDateModule,
    MatListModule,
    MatSidenavModule,
    MatTreeModule,
    MatIconModule,
    MatDividerModule,
    MatSelectModule,
    MatDatepickerModule,
    MatPaginatorModule,
    MatTableModule,
    NgApexchartsModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    InfiniteScrollModule,
    NgxCpfCnpjModule,
    NgxSpinnerModule,
    MatMenuModule,
    MatSlideToggleModule,
    NgxChartsModule,
    ExportAsModule,
    MatCheckboxModule,
    MatTooltipModule,
    MatAutocompleteModule,
    MatButtonToggleModule,
    MatTabsModule,
    MatChipsModule, 
    ReplaceCommaWithDotDirective,
    CurrencyMaskDirective,
    ProcessoMaskDirective,
    MatRadioModule,
    MatExpansionModule,
    NgxGraphModule
  ],
  providers: [{ provide: LOCALE_ID, useValue: 'pt-BR' },DecimalPipe],
//   bootstrap: [AppComponent]
})
export class SharedModule { }
