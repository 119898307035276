<div *ngIf="exibeDadosCompletos">
    <div *ngIf="dadosCompletos?.dadosCnpj">
        <mat-accordion>
          <mat-expansion-panel class="mt-3 py-2" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <h3 class="mb-0 d-flex align-items-center">
                  <mat-icon>person</mat-icon>&nbsp;
                  SÓCIOS - Boa Vista
                </h3>
              </mat-panel-title>
        
            </mat-expansion-panel-header>
            <table class="table">
              <thead class="thead-light">
                <tr class="text-center">
                  <th scope="col" class="thead-top-left p-2">Nome</th>
                  <th scope="col" class="thead p-2">CPF/CNPJ</th>
                  <th scope="col" class="thead p-2">Data Entrada</th>
                  <th scope="col" class="thead p-2">Participação</th>
                  <th scope="col" class="thead-top-right p-2">Assina</th>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngIf="dadosCompletos.dadosCnpj?.socios.socio?.length > 0; else noDataSocios">
                  <tr class="text-center table-info" *ngFor="let socio of dadosCompletos.dadosCnpj.socios.socio">
                    <td>{{socio?.nomeEmpresa}}</td>
                    <td>{{socio?.cpfCnpj}}</td>
                    <td>{{socio?.dataEntrada}}</td>
                    <td>{{socio?.participacao}}</td>
                    <td>{{socio?.assinaEmpresa}}</td>
                  </tr>
                </ng-container>
                <ng-template #noDataSocios>
                    <tr>
                      <td colspan="4" class="text-center">Não há sócios disponíveis</td>
                    </tr>
                  </ng-template>
              </tbody>
            </table>
          </mat-expansion-panel>
        </mat-accordion>
        
        <mat-accordion>
          <mat-expansion-panel class="mt-3 py-2" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <h3 class="mb-0 d-flex align-items-center">
                  <mat-icon>person</mat-icon>&nbsp;
                  ADMINISTRADORES
                </h3>
              </mat-panel-title>
        
            </mat-expansion-panel-header>
            <table class="table">
              <thead class="thead-light">
                <tr class="text-center">
                  <th scope="col" class="thead-top-left p-2">Nome</th>
                  <th scope="col" class="thead p-2">CPF</th>
                  <th scope="col" class="thead p-2">Data Entrada</th>
                  <th scope="col" class="thead-top-right p-2">Cargo</th>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngIf="dadosCompletos.dadosCnpj?.administradores?.administrador?.length > 0; else noDataAdm">
                  <tr class="text-center table-info" *ngFor="let adm of dadosCompletos?.dadosCnpj.administradores.administrador">
                    <td>{{adm?.nome}}</td>
                    <td>{{adm?.cpf}}</td>
                    <td>{{adm?.entrada}}</td>
                    <td>{{adm?.cargo}}</td>
                  </tr>
                </ng-container>
                <ng-template #noDataAdm>
                    <tr>
                      <td colspan="4" class="text-center">Não há administradores disponíveis</td>
                    </tr>
                  </ng-template>
              </tbody>
            </table>
          </mat-expansion-panel>
        </mat-accordion>
        
        <mat-accordion>
          <mat-expansion-panel class="mt-3 py-2" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <h3 class="mb-0 d-flex align-items-center">
                  <mat-icon>home</mat-icon>&nbsp;
                  ENDEREÇOS - Boa Vista
                </h3>
              </mat-panel-title>
        
            </mat-expansion-panel-header>
            <table class="table">
              <thead class="thead-light">
                <tr class="text-center">
                  <th scope="col" class="thead-top-left p-2">endereço</th>
                  <th scope="col" class="thead p-2">Número</th>
                  <th scope="col" class="thead p-2">Bairro</th>
                  <th scope="col" class="thead p-2">Cidade</th>
                  <th scope="col" class="thead p-2">UF</th>
                  <th scope="col" class="thead-top-right p-2">CEP</th>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngIf="dadosCompletos.dadosCnpj?.localizacaoBasico?.logradouro; else noDataEndere">
                  <tr class="text-center table-info">
                    <td>{{dadosCompletos.dadosCnpj?.localizacaoBasico?.logradouro}}</td>
                    <td>{{dadosCompletos.dadosCnpj?.localizacaoBasico?.numero}}</td>
                    <td>{{dadosCompletos.dadosCnpj?.localizacaoBasico?.bairro}}</td>
                    <td>{{dadosCompletos.dadosCnpj?.localizacaoBasico?.cidade}}</td>
                    <td>{{dadosCompletos.dadosCnpj?.localizacaoBasico?.uf}}</td>
                    <td>{{dadosCompletos.dadosCnpj?.localizacaoBasico?.cep}}</td>
                  </tr>
                </ng-container>
                <ng-template #noDataEndere>
                    <tr>
                      <td colspan="7" class="text-center">Não há endereços disponíveis</td>
                    </tr>
                  </ng-template>
              </tbody>
            </table>
          </mat-expansion-panel>
        </mat-accordion>
        
        <mat-accordion>
          <mat-expansion-panel class="mt-3 py-2" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <h3 class="mb-0 d-flex align-items-center">
                  <mat-icon>attach_money</mat-icon>&nbsp;
                  PENDÊNCIAS E RESTRIÇÕES FINANCEIRAS (ÚLTIMAS OCORRÊNCIAS)
                </h3>
              </mat-panel-title>
        
            </mat-expansion-panel-header>
            <table class="table">
              <thead class="thead-light">
                <tr class="text-center">
                  <th scope="col" class="thead-top-left p-2">Informante</th>
                  <th scope="col" class="thead p-2">Tipo</th>
                  <th scope="col" class="thead p-2">Cidade</th>
                  <th scope="col" class="thead p-2">UF</th>
                  <th scope="col" class="thead p-2">Origem</th>
                  <th scope="col" class="thead p-2">Data</th>
                  <th scope="col" class="thead p-2">Disponivel</th>
                  <th scope="col" class="thead-top-right p-2">Valor</th>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngIf="dadosCompletos.dadosCnpj?.pendenciasRestricoes?.ultimasOcorrencias?.pendenciaRestricao?.length > 0; else noDataPendencias">
                  <tr class="text-center table-info" *ngFor="let pendencias of dadosCompletos.dadosCnpj?.pendenciasRestricoes?.ultimasOcorrencias.pendenciaRestricao">
                    <td> {{pendencias?.informante}} </td>
                    <td> {{pendencias?.tipo}} </td>
                    <td> {{pendencias?.cidade}} </td>
                    <td> {{pendencias?.uf}} </td>
                    <td> {{pendencias?.documentoOrigem}} </td>
                    <td> {{pendencias?.dataDebito}} </td>
                    <td> {{pendencias?.disponivelEm}} </td>
                    <td> {{pendencias?.valorFormatado}} </td>
                  </tr>
                </ng-container>
                <ng-template #noDataPendencias>
                    <tr>
                      <td colspan="7" class="text-center">Não há pendencias disponíveis</td>
                    </tr>
                  </ng-template>
              </tbody>
            </table>
          </mat-expansion-panel>
        </mat-accordion>
        
        <!-- <mat-accordion>
          <mat-expansion-panel class="mt-3 py-2" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <h3 class="mb-0 d-flex align-items-center">
                  <mat-icon>money</mat-icon>&nbsp;
                  CHEQUES SEM FUNDOS
                </h3>
              </mat-panel-title>
        
            </mat-expansion-panel-header>
            <table class="table">
              <thead class="thead-light">
                <tr class="text-center">
                  <th scope="col" class="thead-top-left p-2">Informante</th>
                  <th scope="col" class="thead p-2">Tipo</th>
                  <th scope="col" class="thead p-2">Cidade</th>
                  <th scope="col" class="thead p-2">UF</th>
                  <th scope="col" class="thead p-2">Origem</th>
                  <th scope="col" class="thead p-2">Data</th>
                  <th scope="col" class="thead p-2">Disponivel</th>
                  <th scope="col" class="thead-top-right p-2">Valor</th>
                </tr>
              </thead>
              <tbody>
                <ng-container>
                  <tr class="text-center table-info">
                    <td>R ZERO, 150 -SALA 01 </td>
                    <td>xaxaxa</td>
                    <td>xaxax</td>
                    <td>xaxaxax</td>
                    <td>xaxaxaxa</td>
                    <td>xaxaxa</td>
                    <td>xaxaxaxa</td>
                    <td>xaxaxa</td>
                  </tr>
                </ng-container>
                <ng-template #noDataEndere>
                    <tr>
                      <td colspan="7" class="text-center">Não há endereços disponíveis</td>
                    </tr>
                  </ng-template>
              </tbody>
            </table>
          </mat-expansion-panel>
        </mat-accordion>
        
        <mat-accordion>
          <mat-expansion-panel class="mt-3 py-2" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <h3 class="mb-0 d-flex align-items-center">
                  <mat-icon>money</mat-icon>&nbsp;
                  CHEQUES SUSTADOS MOTIVO 21
                </h3>
              </mat-panel-title>
        
            </mat-expansion-panel-header>
            <table class="table">
              <thead class="thead-light">
                <tr class="text-center">
                  <th scope="col" class="thead-top-left p-2">Informante</th>
                  <th scope="col" class="thead p-2">Tipo</th>
                  <th scope="col" class="thead p-2">Cidade</th>
                  <th scope="col" class="thead p-2">UF</th>
                  <th scope="col" class="thead p-2">Origem</th>
                  <th scope="col" class="thead p-2">Data</th>
                  <th scope="col" class="thead p-2">Disponivel</th>
                  <th scope="col" class="thead-top-right p-2">Valor</th>
                </tr>
              </thead>
              <tbody>
                <ng-container>
                  <tr class="text-center table-info">
                    <td>R ZERO, 150 -SALA 01 </td>
                    <td>xaxaxa</td>
                    <td>xaxax</td>
                    <td>xaxaxax</td>
                    <td>xaxaxaxa</td>
                    <td>xaxaxa</td>
                    <td>xaxaxaxa</td>
                    <td>xaxaxa</td>
                  </tr>
                </ng-container>
                <ng-template #noDataEndere>
                    <tr>
                      <td colspan="7" class="text-center">Não há endereços disponíveis</td>
                    </tr>
                  </ng-template>
              </tbody>
            </table>
          </mat-expansion-panel>
        </mat-accordion>
        
        <mat-accordion>
          <mat-expansion-panel class="mt-3 py-2" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <h3 class="mb-0 d-flex align-items-center">
                  <mat-icon>money</mat-icon>&nbsp;
                  CHEQUES DEVOLVIDOS INFORMADOS PELO USUÁRIO
                </h3>
              </mat-panel-title>
        
            </mat-expansion-panel-header>
            <table class="table">
              <thead class="thead-light">
                <tr class="text-center">
                  <th scope="col" class="thead-top-left p-2">Informante</th>
                  <th scope="col" class="thead p-2">Tipo</th>
                  <th scope="col" class="thead p-2">Cidade</th>
                  <th scope="col" class="thead p-2">UF</th>
                  <th scope="col" class="thead p-2">Origem</th>
                  <th scope="col" class="thead p-2">Data</th>
                  <th scope="col" class="thead p-2">Disponivel</th>
                  <th scope="col" class="thead-top-right p-2">Valor</th>
                </tr>
              </thead>
              <tbody>
                <ng-container>
                  <tr class="text-center table-info">
                    <td>R ZERO, 150 -SALA 01 </td>
                    <td>xaxaxa</td>
                    <td>xaxax</td>
                    <td>xaxaxax</td>
                    <td>xaxaxaxa</td>
                    <td>xaxaxa</td>
                    <td>xaxaxaxa</td>
                    <td>xaxaxa</td>
                  </tr>
                </ng-container>
                <ng-template #noDataEndere>
                    <tr>
                      <td colspan="7" class="text-center">Não há endereços disponíveis</td>
                    </tr>
                  </ng-template>
              </tbody>
            </table>
          </mat-expansion-panel>
        </mat-accordion> -->
        
        <mat-accordion>
          <mat-expansion-panel class="mt-3 py-2" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <h3 class="mb-0 d-flex align-items-center">
                  <mat-icon>gavel</mat-icon>&nbsp;
                  PROTESTOS
                </h3>
              </mat-panel-title>
        
            </mat-expansion-panel-header>
            <table class="table">
              <thead class="thead-light">
                <tr class="text-center">
                  <th scope="col" class="thead-top-left p-2">Informações do cliente</th>
                  <th scope="col" class="thead p-2"></th>
                  <th scope="col" class="thead p-2"></th>
                  <th scope="col" class="thead p-2"></th>
                  <th scope="col" class="thead p-2">Data</th>
                  <th scope="col" class="thead-top-right p-2">Valor</th>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngIf="dadosCompletos.dadosCnpj?.protestos?.totalProtestos; else noDataProtestos">
                  <tr class="text-center table-info">
                    <td>Total de Protestos:</td>
                    <td>{{dadosCompletos?.dadosCnpj?.protestos?.totalProtestos}}</td>
                    <td></td>
                    <td>Primeiro</td>
                    <td>{{dadosCompletos?.dadosCnpj?.protestos?.dataPrimeiro}}</td>
                    <td>{{dadosCompletos?.dadosCnpj?.protestos?.valorPrimeiro}}</td>
                  </tr>
                  <tr class="text-center table-info">
                    <td>Valor (R$): </td>
                    <td>{{dadosCompletos?.dadosCnpj?.protestos?.valorTotal}}</td>
                    <td></td>
                    <td>Maior</td>
                    <td>{{dadosCompletos?.dadosCnpj?.protestos?.dataMaior}}</td>
                    <td>{{dadosCompletos?.dadosCnpj?.protestos?.valorMaior}}</td>
                  </tr>
                </ng-container>
                <ng-template #noDataProtestos>
                    <tr>
                      <td colspan="7" class="text-center">Não há protestos disponíveis</td>
                    </tr>
                  </ng-template>
              </tbody>
            </table>
          </mat-expansion-panel>
        </mat-accordion>
        
        <!-- <mat-accordion>
          <mat-expansion-panel class="mt-3 py-2" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <h3 class="mb-0 d-flex align-items-center">
                  <mat-icon>gavel</mat-icon>&nbsp;
                  PROTESTOS (5 ÚLTIMAS OCORRENCIAS)
                </h3>
              </mat-panel-title>
        
            </mat-expansion-panel-header>
            <table class="table">
              <thead class="thead-light">
                <tr class="text-center">
                  <th scope="col" class="thead-top-left p-2">Informações do cliente</th>
                  <th scope="col" class="thead p-2">Vencimento</th>
                  <th scope="col" class="thead p-2">Cartorio</th>
                  <th scope="col" class="thead p-2">Cidade</th>
                  <th scope="col" class="thead p-2">Estado</th>
                  <th scope="col" class="thead-top-right p-2">Valor</th>
                </tr>
              </thead>
              <tbody>
                <ng-container>
                  <tr class="text-center table-info">
                    <td>R ZERO, 150 -SALA 01 </td>
                    <td>xaxaxa</td>
                    <td>xaxax</td>
                    <td>xaxaxax</td>
                    <td>xaxaxaxa</td>
                    <td>xaxaxa</td>
                  </tr>
                </ng-container>
                <<ng-template #noDataEndere>
                    <tr>
                      <td colspan="7" class="text-center">Não há endereços disponíveis</td>
                    </tr>
                  </ng-template>
              </tbody>
            </table>
          </mat-expansion-panel>
        </mat-accordion> -->
      
        <mat-accordion>
          <mat-expansion-panel class="mt-3 py-2" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <h3 class="mb-0 d-flex align-items-center">
                  <mat-icon>attach_money</mat-icon>&nbsp;
                  PAGAMENTO PONTUAL (ATÉ 5 DIAS APÓS VENCIMENTO)
                </h3>
              </mat-panel-title>
        
            </mat-expansion-panel-header>
            <table class="table overflow-x-auto">
              <thead class="thead-light">
                <tr class="text-center">
                  <th scope="col" class="thead-top-left p-2">Pontuação</th>
                  <th scope="col" class="thead p-2">{{dadosCompletos?.dadosCnpj?.pagamentoPontual?.listaIndicadorComportamento?.itemIndicador[0]?.mesAno}}</th>
                  <th scope="col" class="thead p-2">{{dadosCompletos?.dadosCnpj?.pagamentoPontual?.listaIndicadorComportamento?.itemIndicador[1]?.mesAno}}</th>
                  <th scope="col" class="thead p-2">{{dadosCompletos?.dadosCnpj?.pagamentoPontual?.listaIndicadorComportamento?.itemIndicador[2]?.mesAno}}</th>
                  <th scope="col" class="thead p-2">{{dadosCompletos?.dadosCnpj?.pagamentoPontual?.listaIndicadorComportamento?.itemIndicador[3]?.mesAno}}</th>
                  <th scope="col" class="thead p-2">{{dadosCompletos?.dadosCnpj?.pagamentoPontual?.listaIndicadorComportamento?.itemIndicador[4]?.mesAno}}</th>
                  <th scope="col" class="thead p-2">{{dadosCompletos?.dadosCnpj?.pagamentoPontual?.listaIndicadorComportamento?.itemIndicador[5]?.mesAno}}</th>
                  <th scope="col" class="thead p-2">{{dadosCompletos?.dadosCnpj?.pagamentoPontual?.listaIndicadorComportamento?.itemIndicador[6]?.mesAno}}</th>
                  <th scope="col" class="thead p-2">{{dadosCompletos?.dadosCnpj?.pagamentoPontual?.listaIndicadorComportamento?.itemIndicador[7]?.mesAno}}</th>
                  <th scope="col" class="thead p-2">{{dadosCompletos?.dadosCnpj?.pagamentoPontual?.listaIndicadorComportamento?.itemIndicador[8]?.mesAno}}</th>
                  <th scope="col" class="thead p-2">{{dadosCompletos?.dadosCnpj?.pagamentoPontual?.listaIndicadorComportamento?.itemIndicador[9]?.mesAno}}</th>
                  <th scope="col" class="thead p-2">{{dadosCompletos?.dadosCnpj?.pagamentoPontual?.listaIndicadorComportamento?.itemIndicador[10]?.mesAno}}</th>
                  <th scope="col" class="thead-top-right p-2">{{dadosCompletos?.dadosCnpj?.pagamentoPontual?.listaIndicadorComportamento?.itemIndicador[11]?.mesAno}}</th>
                </tr>
              </thead>
              <tbody>
                <ng-container>
                  <tr class="text-center table-info">
                    <td>{{dadosCompletos?.dadosCnpj?.pagamentoPontual?.pontuacao}}</td>
                    <td>{{dadosCompletos?.dadosCnpj?.pagamentoPontual?.listaIndicadorComportamento?.itemIndicador[0]?.pontuacaoMensal}}</td>
                    <td>{{dadosCompletos?.dadosCnpj?.pagamentoPontual?.listaIndicadorComportamento?.itemIndicador[1]?.pontuacaoMensal}}</td>
                    <td>{{dadosCompletos?.dadosCnpj?.pagamentoPontual?.listaIndicadorComportamento?.itemIndicador[2]?.pontuacaoMensal}}</td>
                    <td>{{dadosCompletos?.dadosCnpj?.pagamentoPontual?.listaIndicadorComportamento?.itemIndicador[3]?.pontuacaoMensal}}</td>
                    <td>{{dadosCompletos?.dadosCnpj?.pagamentoPontual?.listaIndicadorComportamento?.itemIndicador[4]?.pontuacaoMensal}}</td>
                    <td>{{dadosCompletos?.dadosCnpj?.pagamentoPontual?.listaIndicadorComportamento?.itemIndicador[5]?.pontuacaoMensal}}</td>
                    <td>{{dadosCompletos?.dadosCnpj?.pagamentoPontual?.listaIndicadorComportamento?.itemIndicador[6]?.pontuacaoMensal}}</td>
                    <td>{{dadosCompletos?.dadosCnpj?.pagamentoPontual?.listaIndicadorComportamento?.itemIndicador[7]?.pontuacaoMensal}}</td>
                    <td>{{dadosCompletos?.dadosCnpj?.pagamentoPontual?.listaIndicadorComportamento?.itemIndicador[8]?.pontuacaoMensal}}</td>
                    <td>{{dadosCompletos?.dadosCnpj?.pagamentoPontual?.listaIndicadorComportamento?.itemIndicador[9]?.pontuacaoMensal}}</td>
                    <td>{{dadosCompletos?.dadosCnpj?.pagamentoPontual?.listaIndicadorComportamento?.itemIndicador[10]?.pontuacaoMensal}}</td>
                    <td>{{dadosCompletos?.dadosCnpj?.pagamentoPontual?.listaIndicadorComportamento?.itemIndicador[11]?.pontuacaoMensal}}</td>
                  </tr>
                </ng-container>
                <!-- <ng-template #noDataEndere>
                    <tr>
                      <td colspan="7" class="text-center">Não há endereços disponíveis</td>
                    </tr>
                  </ng-template> -->
              </tbody>
            </table>
          </mat-expansion-panel>
        </mat-accordion>
      
        <mat-accordion>
          <mat-expansion-panel class="mt-3 py-2" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <h3 class="mb-0 d-flex align-items-center">
                  <mat-icon>attach_money</mat-icon>&nbsp;
                  PAGAMENTO ATRASADO
                </h3>
              </mat-panel-title>
        
            </mat-expansion-panel-header>
            <table class="table overflow-x-auto">
              <thead class="thead-light">
                <tr class="text-center">
                  <th scope="col" class="thead-top-left p-2">Período</th>
                  <th scope="col" class="thead p-2">{{dadosCompletos?.dadosCnpj?.pagamentoAtrasado?.atrasoMedioDias?.listaIndicadorComportamento?.itemIndicador[0]?.mesAno}}</th>
                  <th scope="col" class="thead p-2">{{dadosCompletos?.dadosCnpj?.pagamentoAtrasado?.atrasoMedioDias?.listaIndicadorComportamento?.itemIndicador[1]?.mesAno}}</th>
                  <th scope="col" class="thead p-2">{{dadosCompletos?.dadosCnpj?.pagamentoAtrasado?.atrasoMedioDias?.listaIndicadorComportamento?.itemIndicador[2]?.mesAno}}</th>
                  <th scope="col" class="thead p-2">{{dadosCompletos?.dadosCnpj?.pagamentoAtrasado?.atrasoMedioDias?.listaIndicadorComportamento?.itemIndicador[3]?.mesAno}}</th>
                  <th scope="col" class="thead p-2">{{dadosCompletos?.dadosCnpj?.pagamentoAtrasado?.atrasoMedioDias?.listaIndicadorComportamento?.itemIndicador[4]?.mesAno}}</th>
                  <th scope="col" class="thead p-2">{{dadosCompletos?.dadosCnpj?.pagamentoAtrasado?.atrasoMedioDias?.listaIndicadorComportamento?.itemIndicador[5]?.mesAno}}</th>
                  <th scope="col" class="thead p-2">{{dadosCompletos?.dadosCnpj?.pagamentoAtrasado?.atrasoMedioDias?.listaIndicadorComportamento?.itemIndicador[6]?.mesAno}}</th>
                  <th scope="col" class="thead p-2">{{dadosCompletos?.dadosCnpj?.pagamentoAtrasado?.atrasoMedioDias?.listaIndicadorComportamento?.itemIndicador[7]?.mesAno}}</th>
                  <th scope="col" class="thead p-2">{{dadosCompletos?.dadosCnpj?.pagamentoAtrasado?.atrasoMedioDias?.listaIndicadorComportamento?.itemIndicador[8]?.mesAno}}</th>
                  <th scope="col" class="thead p-2">{{dadosCompletos?.dadosCnpj?.pagamentoAtrasado?.atrasoMedioDias?.listaIndicadorComportamento?.itemIndicador[9]?.mesAno}}</th>
                  <th scope="col" class="thead p-2">{{dadosCompletos?.dadosCnpj?.pagamentoAtrasado?.atrasoMedioDias?.listaIndicadorComportamento?.itemIndicador[10]?.mesAno}}</th>
                  <th scope="col" class="thead p-2">{{dadosCompletos?.dadosCnpj?.pagamentoAtrasado?.atrasoMedioDias?.listaIndicadorComportamento?.itemIndicador[11]?.mesAno}}</th>
                  <th scope="col" class="thead-top-right p-2">{{dadosCompletos?.dadosCnpj?.pagamentoAtrasado?.atrasoMedioDias?.listaIndicadorComportamento?.itemIndicador[12]?.mesAno}}</th>
                </tr>
              </thead>
              <tbody>
                <ng-container>
                  <tr class="text-center table-info">
                    <td>6 a 15 dias</td>
                    <td *ngFor="let seisQuinzeDias of dadosCompletos?.dadosCnpj?.pagamentoAtrasado?.periodo6A15Dias?.listaIndicadorComportamento?.itemIndicador">{{seisQuinzeDias?.pontuacaoMensal}}</td>
                  </tr>
                  <tr class="text-center table-info">
                    <td>16 a 30 dias</td>
                    <td *ngFor="let dezesseisDias of dadosCompletos?.dadosCnpj?.pagamentoAtrasado?.periodo16A30Dias?.listaIndicadorComportamento?.itemIndicador">{{dezesseisDias?.pontuacaoMensal}}</td>
                  </tr>
                  <tr class="text-center table-info">
                    <td>31 a 60 dias</td>
                    <td *ngFor="let trintaUmDias of dadosCompletos?.dadosCnpj?.pagamentoAtrasado?.periodo31A60Dias?.listaIndicadorComportamento?.itemIndicador">{{trintaUmDias?.pontuacaoMensal}}</td>
                  </tr>
                  <tr class="text-center table-info">
                    <td>Mais 60 dias</td>
                    <td *ngFor="let maisSessenta of dadosCompletos?.dadosCnpj?.pagamentoAtrasado?.maisDe60Dias?.listaIndicadorComportamento?.itemIndicador">{{maisSessenta?.pontuacaoMensal}}</td>
                  </tr>
                  <tr class="text-center table-info">
                    <td>Média de dias</td>
                    <td *ngFor="let media of dadosCompletos?.dadosCnpj?.pagamentoAtrasado?.atrasoMedioDias?.listaIndicadorComportamento?.itemIndicador">{{media?.pontuacaoMensal}}</td>
                  </tr>
                </ng-container>
                <!-- <ng-template #noDataEndere>
                    <tr>
                      <td colspan="7" class="text-center">Não há endereços disponíveis</td>
                    </tr>
                  </ng-template> -->
              </tbody>
            </table>
          </mat-expansion-panel>
        </mat-accordion>
      
        <mat-accordion>
          <mat-expansion-panel class="mt-3 py-2" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <h3 class="mb-0 d-flex align-items-center">
                  <mat-icon>account_balance</mat-icon>&nbsp;
                  COMPROMISSOS
                </h3>
              </mat-panel-title>
        
            </mat-expansion-panel-header>
            <table class="table overflow-x-auto">
              <thead class="thead-light">
                <tr class="text-center">
                  <th scope="col" class="thead-top-left p-2">Pontuação</th>
                  <th scope="col" class="thead p-2">{{dadosCompletos?.dadosCnpj?.compromissos?.listaIndicadorComportamento?.itemIndicador[0]?.mesAno}}</th>
                  <th scope="col" class="thead p-2">{{dadosCompletos?.dadosCnpj?.compromissos?.listaIndicadorComportamento?.itemIndicador[1]?.mesAno}}</th>
                  <th scope="col" class="thead p-2">{{dadosCompletos?.dadosCnpj?.compromissos?.listaIndicadorComportamento?.itemIndicador[2]?.mesAno}}</th>
                  <th scope="col" class="thead p-2">{{dadosCompletos?.dadosCnpj?.compromissos?.listaIndicadorComportamento?.itemIndicador[3]?.mesAno}}</th>
                  <th scope="col" class="thead p-2">{{dadosCompletos?.dadosCnpj?.compromissos?.listaIndicadorComportamento?.itemIndicador[4]?.mesAno}}</th>
                  <th scope="col" class="thead p-2">{{dadosCompletos?.dadosCnpj?.compromissos?.listaIndicadorComportamento?.itemIndicador[5]?.mesAno}}</th>
                  <th scope="col" class="thead p-2">{{dadosCompletos?.dadosCnpj?.compromissos?.listaIndicadorComportamento?.itemIndicador[6]?.mesAno}}</th>
                  <th scope="col" class="thead p-2">{{dadosCompletos?.dadosCnpj?.compromissos?.listaIndicadorComportamento?.itemIndicador[7]?.mesAno}}</th>
                  <th scope="col" class="thead p-2">{{dadosCompletos?.dadosCnpj?.compromissos?.listaIndicadorComportamento?.itemIndicador[8]?.mesAno}}</th>
                  <th scope="col" class="thead p-2">{{dadosCompletos?.dadosCnpj?.compromissos?.listaIndicadorComportamento?.itemIndicador[9]?.mesAno}}</th>
                  <th scope="col" class="thead p-2">{{dadosCompletos?.dadosCnpj?.compromissos?.listaIndicadorComportamento?.itemIndicador[10]?.mesAno}}</th>
                  <th scope="col" class="thead-top-right p-2">{{dadosCompletos?.dadosCnpj?.compromissos?.listaIndicadorComportamento?.itemIndicador[11]?.mesAno}}</th>
                </tr>
              </thead>
              <tbody>
                <ng-container>
                  <tr class="text-center table-info">
                    <td>{{dadosCompletos?.dadosCnpj?.compromissos?.pontuacao}}</td>
                    <td>{{dadosCompletos?.dadosCnpj?.compromissos?.listaIndicadorComportamento?.itemIndicador[0]?.pontuacaoMensal}}</td>
                    <td>{{dadosCompletos?.dadosCnpj?.compromissos?.listaIndicadorComportamento?.itemIndicador[1]?.pontuacaoMensal}}</td>
                    <td>{{dadosCompletos?.dadosCnpj?.compromissos?.listaIndicadorComportamento?.itemIndicador[2]?.pontuacaoMensal}}</td>
                    <td>{{dadosCompletos?.dadosCnpj?.compromissos?.listaIndicadorComportamento?.itemIndicador[3]?.pontuacaoMensal}}</td>
                    <td>{{dadosCompletos?.dadosCnpj?.compromissos?.listaIndicadorComportamento?.itemIndicador[4]?.pontuacaoMensal}}</td>
                    <td>{{dadosCompletos?.dadosCnpj?.compromissos?.listaIndicadorComportamento?.itemIndicador[5]?.pontuacaoMensal}}</td>
                    <td>{{dadosCompletos?.dadosCnpj?.compromissos?.listaIndicadorComportamento?.itemIndicador[6]?.pontuacaoMensal}}</td>
                    <td>{{dadosCompletos?.dadosCnpj?.compromissos?.listaIndicadorComportamento?.itemIndicador[7]?.pontuacaoMensal}}</td>
                    <td>{{dadosCompletos?.dadosCnpj?.compromissos?.listaIndicadorComportamento?.itemIndicador[8]?.pontuacaoMensal}}</td>
                    <td>{{dadosCompletos?.dadosCnpj?.compromissos?.listaIndicadorComportamento?.itemIndicador[9]?.pontuacaoMensal}}</td>
                    <td>{{dadosCompletos?.dadosCnpj?.compromissos?.listaIndicadorComportamento?.itemIndicador[10]?.pontuacaoMensal}}</td>
                    <td>{{dadosCompletos?.dadosCnpj?.compromissos?.listaIndicadorComportamento?.itemIndicador[11]?.pontuacaoMensal}}</td>
                  </tr>
                </ng-container>
                <!-- <ng-template #noDataEndere>
                    <tr>
                      <td colspan="7" class="text-center">Não há endereços disponíveis</td>
                    </tr>
                  </ng-template> -->
              </tbody>
            </table>
          </mat-expansion-panel>
        </mat-accordion>
        
        <mat-accordion>
          <mat-expansion-panel class="mt-3 py-2" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <h3 class="mb-0 d-flex align-items-center">
                  <mat-icon>account_balance</mat-icon>&nbsp;
                  COMPROMETIMENTO FUTURO
                </h3>
              </mat-panel-title>
        
            </mat-expansion-panel-header>
            <table class="table">
              <thead class="thead-light">
                <tr class="text-center">
                  <th scope="col" class="thead-top-left p-2">Período</th>
                  <th scope="col" class="thead p-2">Pontuação</th>
                  <th scope="col" class="thead p-2">Até 30 dias</th>
                  <th scope="col" class="thead p-2">De 31 a 60 dias</th>
                  <th scope="col" class="thead p-2">De 61 a 90 dias</th>
                  <th scope="col" class="thead-top-right p-2">Acima de 91 dias</th>
                </tr>
              </thead>
              <tbody>
                <ng-container>
                  <tr class="text-center table-info">
                    <td>Compromissos</td>
                    <td>{{dadosCompletos?.dadosCnpj?.comprometimentoFuturo?.listaCompromissos?.compromisso[0]?.pontuacao}}</td>
                    <td>{{dadosCompletos?.dadosCnpj?.comprometimentoFuturo?.listaCompromissos?.compromisso[1]?.pontuacao}}</td>
                    <td>{{dadosCompletos?.dadosCnpj?.comprometimentoFuturo?.listaCompromissos?.compromisso[2]?.pontuacao}}</td>
                    <td>{{dadosCompletos?.dadosCnpj?.comprometimentoFuturo?.listaCompromissos?.compromisso[3]?.pontuacao}}</td>
                    <td>{{dadosCompletos?.dadosCnpj?.comprometimentoFuturo?.listaCompromissos?.compromisso[4]?.pontuacao}}</td>
                  </tr>
                  <tr class="text-center table-info">
                    <td>Crédito</td>
                    <td>{{dadosCompletos?.dadosCnpj?.comprometimentoFuturo?.listaCredito?.credito[0]?.pontuacao}}</td>
                    <td>{{dadosCompletos?.dadosCnpj?.comprometimentoFuturo?.listaCredito?.credito[1]?.pontuacao}}</td>
                    <td>{{dadosCompletos?.dadosCnpj?.comprometimentoFuturo?.listaCredito?.credito[2]?.pontuacao}}</td>
                    <td>{{dadosCompletos?.dadosCnpj?.comprometimentoFuturo?.listaCredito?.credito[3]?.pontuacao}}</td>
                    <td>{{dadosCompletos?.dadosCnpj?.comprometimentoFuturo?.listaCredito?.credito[4]?.pontuacao}}</td>
                  </tr>
                </ng-container>
                <!-- <ng-template #noDataEndere>
                    <tr>
                      <td colspan="7" class="text-center">Não há endereços disponíveis</td>
                    </tr>
                  </ng-template> -->
              </tbody>
            </table>
          </mat-expansion-panel>
        </mat-accordion>
      
        <mat-accordion>
          <mat-expansion-panel class="mt-3 py-2" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <h3 class="mb-0 d-flex align-items-center">
                  <mat-icon>account_balance</mat-icon>&nbsp;
                  CRÉDITO OBTIDO
                </h3>
              </mat-panel-title>
        
            </mat-expansion-panel-header>
            <table class="table overflow-x-auto">
              <thead class="thead-light">
                <tr class="text-center">
                  <th scope="col" class="thead-top-left p-2">Pontuação</th>
                  <th scope="col" class="thead p-2">{{dadosCompletos?.dadosCnpj?.creditoObtido?.listaIndicadorComportamento?.itemIndicador[0]?.mesAno}}</th>
                  <th scope="col" class="thead p-2">{{dadosCompletos?.dadosCnpj?.creditoObtido?.listaIndicadorComportamento?.itemIndicador[1]?.mesAno}}</th>
                  <th scope="col" class="thead p-2">{{dadosCompletos?.dadosCnpj?.creditoObtido?.listaIndicadorComportamento?.itemIndicador[2]?.mesAno}}</th>
                  <th scope="col" class="thead p-2">{{dadosCompletos?.dadosCnpj?.creditoObtido?.listaIndicadorComportamento?.itemIndicador[3]?.mesAno}}</th>
                  <th scope="col" class="thead p-2">{{dadosCompletos?.dadosCnpj?.creditoObtido?.listaIndicadorComportamento?.itemIndicador[4]?.mesAno}}</th>
                  <th scope="col" class="thead p-2">{{dadosCompletos?.dadosCnpj?.creditoObtido?.listaIndicadorComportamento?.itemIndicador[5]?.mesAno}}</th>
                  <th scope="col" class="thead p-2">{{dadosCompletos?.dadosCnpj?.creditoObtido?.listaIndicadorComportamento?.itemIndicador[6]?.mesAno}}</th>
                  <th scope="col" class="thead p-2">{{dadosCompletos?.dadosCnpj?.creditoObtido?.listaIndicadorComportamento?.itemIndicador[7]?.mesAno}}</th>
                  <th scope="col" class="thead p-2">{{dadosCompletos?.dadosCnpj?.creditoObtido?.listaIndicadorComportamento?.itemIndicador[8]?.mesAno}}</th>
                  <th scope="col" class="thead p-2">{{dadosCompletos?.dadosCnpj?.creditoObtido?.listaIndicadorComportamento?.itemIndicador[9]?.mesAno}}</th>
                  <th scope="col" class="thead p-2">{{dadosCompletos?.dadosCnpj?.creditoObtido?.listaIndicadorComportamento?.itemIndicador[10]?.mesAno}}</th>
                  <th scope="col" class="thead-top-right p-2">{{dadosCompletos?.dadosCnpj?.creditoObtido?.listaIndicadorComportamento?.itemIndicador[11]?.mesAno}}</th>
                </tr>
              </thead>
              <tbody>
                <ng-container>
                  <tr class="text-center table-info">
                    <td>{{dadosCompletos?.dadosCnpj?.creditoObtido?.pontuacao}}</td>
                    <td>{{dadosCompletos?.dadosCnpj?.creditoObtido?.listaIndicadorComportamento?.itemIndicador[0]?.pontuacaoMensal}}</td>
                    <td>{{dadosCompletos?.dadosCnpj?.creditoObtido?.listaIndicadorComportamento?.itemIndicador[1]?.pontuacaoMensal}}</td>
                    <td>{{dadosCompletos?.dadosCnpj?.creditoObtido?.listaIndicadorComportamento?.itemIndicador[2]?.pontuacaoMensal}}</td>
                    <td>{{dadosCompletos?.dadosCnpj?.creditoObtido?.listaIndicadorComportamento?.itemIndicador[3]?.pontuacaoMensal}}</td>
                    <td>{{dadosCompletos?.dadosCnpj?.creditoObtido?.listaIndicadorComportamento?.itemIndicador[4]?.pontuacaoMensal}}</td>
                    <td>{{dadosCompletos?.dadosCnpj?.creditoObtido?.listaIndicadorComportamento?.itemIndicador[5]?.pontuacaoMensal}}</td>
                    <td>{{dadosCompletos?.dadosCnpj?.creditoObtido?.listaIndicadorComportamento?.itemIndicador[6]?.pontuacaoMensal}}</td>
                    <td>{{dadosCompletos?.dadosCnpj?.creditoObtido?.listaIndicadorComportamento?.itemIndicador[7]?.pontuacaoMensal}}</td>
                    <td>{{dadosCompletos?.dadosCnpj?.creditoObtido?.listaIndicadorComportamento?.itemIndicador[8]?.pontuacaoMensal}}</td>
                    <td>{{dadosCompletos?.dadosCnpj?.creditoObtido?.listaIndicadorComportamento?.itemIndicador[9]?.pontuacaoMensal}}</td>
                    <td>{{dadosCompletos?.dadosCnpj?.creditoObtido?.listaIndicadorComportamento?.itemIndicador[10]?.pontuacaoMensal}}</td>
                    <td>{{dadosCompletos?.dadosCnpj?.creditoObtido?.listaIndicadorComportamento?.itemIndicador[11]?.pontuacaoMensal}}</td>
                  </tr>
                </ng-container>
                <!-- <ng-template #noDataEndere>
                    <tr>
                      <td colspan="7" class="text-center">Não há endereços disponíveis</td>
                    </tr>
                  </ng-template> -->
              </tbody>
            </table>
          </mat-expansion-panel>
        </mat-accordion>
      
      
        <mat-accordion>
          <mat-expansion-panel class="mt-3 py-2" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <h3 class="mb-0 d-flex align-items-center">
                  <mat-icon>account_balance</mat-icon>&nbsp;
                  DEMAIS INFORMAÇÕES
                </h3>
              </mat-panel-title>
        
            </mat-expansion-panel-header>
            <table class="table">
              <thead class="thead-light">
                <tr class="text-center">
                  <th scope="col" class="thead-top-left p-2">Tempo de relacionamento com fornecedores (%) </th>
                  <th scope="col" class="thead p-2">Até 6 meses</th>
                  <th scope="col" class="thead p-2">De 7 a 12 meses</th>
                  <th scope="col" class="thead p-2">De 1 a 2 anos</th>
                  <th scope="col" class="thead p-2">De 3 a 5 anos </th>
                  <th scope="col" class="thead p-2">De 6 a 10 anos</th>
                  <th scope="col" class="thead-top-right p-2">Mais de 10 anos</th>
                </tr>
              </thead>
              <tbody>
                <ng-container>
                  <tr class="text-center table-info">
                    <td>-</td>
                    <td>{{dadosCompletos?.dadosCnpj?.demaisInformacoes?.tempoRelacionamentoFornecedores?.historicoPeriodo[0]?.valor}}</td>
                    <td>{{dadosCompletos?.dadosCnpj?.demaisInformacoes?.tempoRelacionamentoFornecedores?.historicoPeriodo[1]?.valor}}</td>
                    <td>{{dadosCompletos?.dadosCnpj?.demaisInformacoes?.tempoRelacionamentoFornecedores?.historicoPeriodo[2]?.valor}}</td>
                    <td>{{dadosCompletos?.dadosCnpj?.demaisInformacoes?.tempoRelacionamentoFornecedores?.historicoPeriodo[3]?.valor}}</td>
                    <td>{{dadosCompletos?.dadosCnpj?.demaisInformacoes?.tempoRelacionamentoFornecedores?.historicoPeriodo[4]?.valor}}</td>
                    <td>{{dadosCompletos?.dadosCnpj?.demaisInformacoes?.tempoRelacionamentoFornecedores?.historicoPeriodo[5]?.valor}}</td>
                  </tr>
                </ng-container>
                <!-- <ng-template #noDataEndere>
                    <tr>
                      <td colspan="7" class="text-center">Não há endereços disponíveis</td>
                    </tr>
                  </ng-template> -->
              </tbody>
            </table>
          </mat-expansion-panel>
        </mat-accordion>
      
        <mat-accordion>
          <mat-expansion-panel class="mt-3 py-2" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <h3 class="mb-0 d-flex align-items-center">
                  <mat-icon>account_balance</mat-icon>&nbsp;
                  ACONTECIMENTOS IMPORTANTES
                </h3>
              </mat-panel-title>
        
            </mat-expansion-panel-header>
            <table class="table">
              <thead class="thead-light">
                <tr class="text-center">
                  <th scope="col" class="thead-top-left p-2">Acontecimentos Importantes  </th>
                  <th scope="col" class="thead p-2">Última Compra</th>
                  <th scope="col" class="thead p-2">Maior Fatura</th>
                  <th scope="col" class="thead-top-right p-2">Maior Crédito</th>
                </tr>
              </thead>
              <tbody>
                <ng-container>
                  <tr class="text-center table-info">
                    <td>{{dadosCompletos?.dadosCnpj?.demaisInformacoes?.acontecimentosImportantes?.historicoPeriodoPontuacao[0]?.periodo}}</td>
                    <td>{{dadosCompletos?.dadosCnpj?.demaisInformacoes?.acontecimentosImportantes?.historicoPeriodoPontuacao[1]?.periodo}}</td>
                    <td>{{dadosCompletos?.dadosCnpj?.demaisInformacoes?.acontecimentosImportantes?.historicoPeriodoPontuacao[2]?.periodo}}</td>
                    <td>{{dadosCompletos?.dadosCnpj?.demaisInformacoes?.acontecimentosImportantes?.historicoPeriodoPontuacao[3]?.periodo}}</td>
                  </tr>
                  <tr class="text-center table-info">
                    <td>{{dadosCompletos?.dadosCnpj?.demaisInformacoes?.acontecimentosImportantes?.historicoPeriodoPontuacao[0]?.pontuacao}}</td>
                    <td>{{dadosCompletos?.dadosCnpj?.demaisInformacoes?.acontecimentosImportantes?.historicoPeriodoPontuacao[1]?.pontuacao}}</td>
                    <td>{{dadosCompletos?.dadosCnpj?.demaisInformacoes?.acontecimentosImportantes?.historicoPeriodoPontuacao[2]?.pontuacao}}</td>
                    <td>{{dadosCompletos?.dadosCnpj?.demaisInformacoes?.acontecimentosImportantes?.historicoPeriodoPontuacao[3]?.pontuacao}}</td>
                  </tr>
                </ng-container>
                <!-- <ng-template #noDataEndere>
                    <tr>
                      <td colspan="7" class="text-center">Não há endereços disponíveis</td>
                    </tr>
                  </ng-template> -->
              </tbody>
            </table>
          </mat-expansion-panel>
        </mat-accordion>
        
      </div> 
      
      <div *ngIf="dadosCompletos?.dadosCpf">
      
        <mat-accordion>
          <mat-expansion-panel class="mt-3 py-2" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <h3 class="mb-0 d-flex align-items-center">
                  <mat-icon>timeline</mat-icon>&nbsp;
                  CONSULTAS ANTERIORES
                </h3>
              </mat-panel-title>
        
            </mat-expansion-panel-header>
            <table class="table">
              <thead class="thead-light">
                <tr class="text-center">
                  <th scope="col" class="thead-top-left p-2">Data</th>
                  <th scope="col" class="thead p-2">Empresa</th>
                  <th scope="col" class="thead-top-right p-2">Tipo</th>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngIf="dadosCompletos.dadosCpf?.consultasAnteriores?.length > 0; else noDataConsultas">
                  <tr class="text-center table-info" *ngFor="let consultas of dadosCompletos.dadosCpf.consultasAnteriores">
                    <td>{{consultas?.informante}}</td>
                    <td>{{consultas?.data}}</td>
                    <td>{{consultas?.tipoOcorrenciaDescricao}}</td>
                  </tr>
                </ng-container>
                <ng-template #noDataConsultas>
                    <tr>
                      <td colspan="4" class="text-center">Não há consultas disponíveis</td>
                    </tr>
                  </ng-template>
              </tbody>
            </table>
          </mat-expansion-panel>
        </mat-accordion>
      
        <mat-accordion>
          <mat-expansion-panel class="mt-3 py-2" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <h3 class="mb-0 d-flex align-items-center">
                  <mat-icon>person</mat-icon>&nbsp;
                  REGISTROS DE DÉBITOS
                </h3>
              </mat-panel-title>
        
            </mat-expansion-panel-header>
            <table class="table">
              <thead class="thead-light">
                <tr class="text-center">
                  <th scope="col" class="thead-top-left p-2">Ocorrência</th>
                  <th scope="col" class="thead p-2">Disponib.</th>
                  <th scope="col" class="thead p-2">Informante</th>
                  <th scope="col" class="thead p-2">Tipo</th>
                  <th scope="col" class="thead p-2">Contrato</th>
                  <th scope="col" class="thead p-2">Cidade</th>
                  <th scope="col" class="thead p-2">UF</th>
                  <th scope="col" class="thead p-2">Situação</th>
                  <th scope="col" class="thead-top-right p-2">Valor</th>
                </tr>
              </thead>
              <tbody>
                <ng-container  *ngIf="dadosCompletos.dadosCpf?.debitos[0].valor; else noDataDebitos">
                  <tr class="text-center table-info" *ngFor="let debitos of dadosCompletos.dadosCpf.debitos">
                    <td>{{debitos?.dataOcorrencia}}</td>
                    <td>{{debitos?.dataDisponibilizacao}}</td>
                    <td>{{debitos?.informante}}</td>
                    <td>{{debitos?.tipoOcorrencia}}</td>
                    <td>{{debitos?.contrato}}</td>
                    <td>{{debitos?.cidade}}</td>
                    <td>{{debitos?.uf}}</td>
                    <td>{{debitos?.situacao}}</td>
                    <td>{{debitos?.moeda}} {{debitos?.valor}}</td>
                  </tr>
                </ng-container>
                <ng-template #noDataDebitos>
                    <tr>
                      <td colspan="9" class="text-center">Não há debitos disponíveis</td>
                    </tr>
                  </ng-template>
              </tbody>
            </table>
          </mat-expansion-panel>
        </mat-accordion>
      
        <mat-accordion>
          <mat-expansion-panel class="mt-3 py-2" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <h3 class="mb-0 d-flex align-items-center">
                  <mat-icon>person</mat-icon>&nbsp;
                  AÇÕES CIVEIS
                </h3>
              </mat-panel-title>
        
            </mat-expansion-panel-header>
            <table class="table">
              <thead class="thead-light">
                <tr class="text-center">
                  <th scope="col" class="thead-top-left p-2">Vara</th>
                  <th scope="col" class="thead p-2">Processo</th>
                  <th scope="col" class="thead p-2">Data</th>
                  <th scope="col" class="thead p-2">Autor</th>
                  <th scope="col" class="thead p-2">Valor</th>
                  <th scope="col" class="thead p-2">Cidade</th>
                  <th scope="col" class="thead-top-right p-2">UF</th>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngIf="dadosCompletos.dadosCpf?.relacaoDeAcoesCiveis[0]?.valor; else noDataAcoesCiveis">
                  <tr class="text-center table-info" *ngFor="let acoes of dadosCompletos.dadosCpf.relacaoDeAcoesCiveis">
                    <td>{{acoes?.vara}}</td>
                    <td>{{acoes?.processo}}</td>
                    <td>{{acoes?.dataDistribuicao}}</td>
                    <td>{{acoes?.autor}}</td>
                    <td>{{acoes?.valor}}</td>
                    <td>{{acoes?.praca}}</td>
                    <td>{{acoes?.uf}}</td>
                  </tr>
                </ng-container>
                <ng-template #noDataAcoesCiveis>
                    <tr>
                      <td colspan="7" class="text-center">Não há ações civeis disponíveis</td>
                    </tr>
                  </ng-template>
              </tbody>
            </table>
          </mat-expansion-panel>
        </mat-accordion>
      
        <mat-accordion>
          <mat-expansion-panel class="mt-3 py-2" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <h3 class="mb-0 d-flex align-items-center">
                  <mat-icon>person</mat-icon>&nbsp;
                  RECUPERAÇÕES E FALÊNCIAS
                </h3>
              </mat-panel-title>
        
            </mat-expansion-panel-header>
            <table class="table">
              <thead class="thead-light">
                <tr class="text-center">
                  <th scope="col" class="thead-top-left p-2">CNPJ</th>
                  <th scope="col" class="thead p-2">Razão Social</th>
                  <th scope="col" class="thead p-2">Cartório</th>
                  <th scope="col" class="thead p-2">Data Ocorrencia</th>
                  <th scope="col" class="thead p-2">Praça</th>
                  <th scope="col" class="thead-top-right p-2">UF</th>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngIf="dadosCompletos.dadosCpf?.relacaoFalenciaRecuperacaoJudicial[0]?.razaoSocial; else noDataFalencias">
                  <tr class="text-center table-info" *ngFor="let falencias of dadosCompletos.dadosCpf?.relacaoFalenciaRecuperacaoJudicial">
                    <td>{{falencias?.numeroDocumentoFormatado}}</td>
                    <td>{{falencias?.razaoSocial}}</td>
                    <td>{{falencias?.cartorioFormatado}}</td>
                    <td>{{falencias?.dataOcorrencia}}</td>
                    <td>{{falencias?.praca}}</td>
                    <td>{{falencias?.uf}}</td>
                  </tr>
                </ng-container>
                <ng-template #noDataFalencias>
                    <tr>
                      <td colspan="7" class="text-center">Não há recuperações e falencias disponíveis</td>
                    </tr>
                  </ng-template>
              </tbody>
            </table>
          </mat-expansion-panel>
        </mat-accordion>
      
        <mat-accordion>
          <mat-expansion-panel class="mt-3 py-2" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <h3 class="mb-0 d-flex align-items-center">
                  <mat-icon>business</mat-icon>&nbsp;
                  PARTICIPAÇÕES EM EMPRESAS
                </h3>
              </mat-panel-title>
        
            </mat-expansion-panel-header>
            <table class="table">
              <thead class="thead-light">
                <tr class="text-center">
                  <th scope="col" class="thead-top-left p-2">Razão Social </th>
                  <th scope="col" class="thead p-2">%</th>
                  <th scope="col" class="thead p-2">Data Entrada</th>
                  <th scope="col" class="thead p-2">Tipo</th>
                  <th scope="col" class="thead-top-right p-2">CNPJ</th>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngIf="dadosCompletos.dadosCpf?.participacoesDoDocumentoConsultado[0]?.razaoSocial; else noDataParticipacoesDoDocumentoConsultado">
                  <tr class="text-center table-info" *ngFor="let participacoes of dadosCompletos.dadosCpf.participacoesDoDocumentoConsultado">
                    <td>{{participacoes?.razaoSocial}}</td>
                    <td>{{participacoes?.valorEmPercentual}}</td>
                    <td>{{participacoes?.dataDeEntrada}}</td>
                    <td>{{participacoes?.funcao}}</td>
                    <td>{{participacoes?.numeroDocumentoBFormatado}}</td>
                  </tr>
                </ng-container>
                <ng-template #noDataParticipacoesDoDocumentoConsultado>
                    <tr>
                      <td colspan="7" class="text-center">Não há participacoes em empresas disponíveis</td>
                    </tr>
                  </ng-template>
              </tbody>
            </table>
          </mat-expansion-panel>
        </mat-accordion>
      
        <!-- <mat-accordion>
          <mat-expansion-panel class="mt-3 py-2" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <h3 class="mb-0 d-flex align-items-center">
                  <mat-icon>money</mat-icon>&nbsp;
                  CHEQUES SUSTADOS MOTIVO 21
                </h3>
              </mat-panel-title>
        
            </mat-expansion-panel-header>
            <table class="table">
              <thead class="thead-light">
                <tr class="text-center">
                  <th scope="col" class="thead-top-left p-2">Razão Social </th>
                  <th scope="col" class="thead p-2">%</th>
                  <th scope="col" class="thead p-2">Data Entrada</th>
                  <th scope="col" class="thead p-2">Tipo</th>
                  <th scope="col" class="thead-top-right p-2">CNPJ</th>
                </tr>
              </thead>
              <tbody>
                <ng-container>
                  <tr class="text-center table-info" *ngFor="let participacoes of dadosCompletos.dadosCpf.participacoesDoDocumentoConsultado">
                    <td>{{participacoes?.razaoSocial}}</td>
                    <td>{{participacoes?.valorEmPercentual}}</td>
                    <td>{{participacoes?.dataDeEntrada}}</td>
                    <td>{{participacoes?.funcao}}</td>
                    <td>{{participacoes?.numeroDocumentoBFormatado}}</td>
                  </tr>
                </ng-container>
                 <ng-template #noDataSocios>
                    <tr>
                      <td colspan="4" class="text-center">Não há sócios disponíveis</td>
                    </tr>
                  </ng-template> 
              </tbody>
            </table>
          </mat-expansion-panel>
        </mat-accordion>
      
      
        <mat-accordion>
          <mat-expansion-panel class="mt-3 py-2" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <h3 class="mb-0 d-flex align-items-center">
                  <mat-icon>money</mat-icon>&nbsp;
                  CHEQUES DEVOLVIDOS INFORMADOS PELO USUÁRIO
                </h3>
              </mat-panel-title>
        
            </mat-expansion-panel-header>
            <table class="table">
              <thead class="thead-light">
                <tr class="text-center">
                  <th scope="col" class="thead-top-left p-2">Razão Social </th>
                  <th scope="col" class="thead p-2">%</th>
                  <th scope="col" class="thead p-2">Data Entrada</th>
                  <th scope="col" class="thead p-2">Tipo</th>
                  <th scope="col" class="thead-top-right p-2">CNPJ</th>
                </tr>
              </thead>
              <tbody>
                <ng-container>
                  <tr class="text-center table-info" *ngFor="let participacoes of dadosCompletos.dadosCpf.participacoesDoDocumentoConsultado">
                    <td>{{participacoes?.razaoSocial}}</td>
                    <td>{{participacoes?.valorEmPercentual}}</td>
                    <td>{{participacoes?.dataDeEntrada}}</td>
                    <td>{{participacoes?.funcao}}</td>
                    <td>{{participacoes?.numeroDocumentoBFormatado}}</td>
                  </tr>
                </ng-container>
                 <ng-template #noDataSocios>
                    <tr>
                      <td colspan="4" class="text-center">Não há sócios disponíveis</td>
                    </tr>
                  </ng-template>
              </tbody>
            </table>
          </mat-expansion-panel>
        </mat-accordion> -->
      
      
        <mat-accordion>
          <mat-expansion-panel class="mt-3 py-2" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <h3 class="mb-0 d-flex align-items-center">
                  <mat-icon>gavel</mat-icon>&nbsp;
                  PROTESTOS
                </h3>
              </mat-panel-title>
        
            </mat-expansion-panel-header>
            <table class="table">
              <thead class="thead-light">
                <tr class="text-center">
                  <th scope="col" class="thead-top-left p-2">Data </th>
                  <th scope="col" class="thead p-2">Cartório</th>
                  <th scope="col" class="thead p-2">Cidade</th>
                  <th scope="col" class="thead p-2">Estado</th>
                  <th scope="col" class="thead-top-right p-2">Valor</th>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngIf="dadosCompletos.dadosCpf?.titulosProtestados[0].dataOcorrencia; else noDataTitulosProtestados">
                  <tr class="text-center table-info" *ngFor="let protestos of dadosCompletos.dadosCpf?.titulosProtestados">
                    <td>{{protestos?.dataOcorrencia}}</td>
                    <td>{{protestos?.cartorioFormatado}}</td>
                    <td>{{protestos?.cidade}}</td>
                    <td>{{protestos?.uf}}</td>
                    <td>{{protestos?.moeda}} {{protestos?.valor}}</td>
                  </tr>
                </ng-container>
                <ng-template #noDataTitulosProtestados>
                    <tr>
                      <td colspan="7" class="text-center">Não há protestos disponíveis</td>
                    </tr>
                  </ng-template>
              </tbody>
            </table>
          </mat-expansion-panel>
        </mat-accordion>
      
      </div>
</div>
  
  