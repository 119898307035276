<div class="header text-center d-flex justify-content-between">
    <div></div>
    <h2>Pesquisa em Lotes</h2>
    <button mat-icon-button aria-label="close dialog" mat-dialog-close style="margin-top: -4px;">
        <mat-icon>close</mat-icon>
    </button>
</div>
<div class="container mt-5">

    <div class="d-flex justify-content-center flex-column align-items-center border-grey">
        <div>
            <p class="title-select-type">Selecione um tipo de pesquisa em lote</p>
        </div>
        <div class="mt-1">
            <mat-radio-group aria-label="Select an option" [(ngModel)]="tipoPesquisaLote">
                <mat-radio-button value="1"> Upload Documento </mat-radio-button>
                <mat-radio-button value="2" class="radio-right"> Importar Manualmente </mat-radio-button>
            </mat-radio-group>
        </div>
    </div>


    <section class="d-flex justify-content-end flex-column align-items-end mt-4 buttons-anexo" *ngIf="tipoPesquisaLote == '1'">
        <div class="mb-4">
            <p class="text-grey" id="text"> Baixe a Planilha em Excel de modelo para preenchimento <a
                    matTooltip="Clique e baixe a planilha modelo" class="download-planilha"
                    href="../../../../../assets/pesquisa-lote/MODELO_PESQUISA_EM_LOTE_.xlsx" download>Download
                    Planilha</a></p>
        </div>
        <div>
            <p class="text-grey d-flex align-items-center" id="text"> Adicione o arquivo no formato XLSX
                <label *ngIf="!anexandoArquivo" class="gs-botaoAzulPadrao"
                    matTooltip="Clique e anexe a planilha preenchida">
                    <input type="file" (change)="handleUpload($event)" accept=".xlsx">
                    Anexar Arquivo
                </label>

                <label *ngIf="anexandoArquivo" class="gs-botaoAzulPadrao d-flex"
                    matTooltip="Clique e anexe a planilha preenchida">
                    <input class="input">
                    <div class="loader dot-space">
                        <span class="loader__dot">.</span><span class="loader__dot">.</span><span
                            class="loader__dot">.</span>
                    </div> Lendo Arquivo
                </label>
            </p>
        </div>
    </section>

    <section *ngIf="tipoPesquisaLote == '2'">
        <div class="mt-4">
            <mat-label>cole ou digite os documentos desejados (um abaixo do outro):</mat-label>
            <textarea [(ngModel)]="inputManual" rows="6" class="text-area-docs"></textarea>
            <div class="d-flex justify-content-end">
                <button class="gs-botaoAzulPadrao button-iniciar" (click)="gerarExcelManualmente()">Enviar
                    Documentos</button>
            </div>
        </div>
    </section>

    <section *ngIf="dataTable.length != 0" style="margin-top: -30px;">
        <div class="box-wrap">
            <div class="table-wrap tableFixHead">
                <table>
                    <thead>
                        <tr>
                            <th>Documento</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let o of dataTable.linhas">
                            <td class="otherStatus">{{o.documento}}</td>
                            <td [ngClass]="o.erroMensagem != undefined ? 'invalido' : 'otherStatus'">{{o.erroMensagem ==
                                undefined ? 'Sucesso' : o.erroMensagem}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <div class="row" style="margin-top: -24px;">
            <div class="col d-flex justify-content-between totalItens">
                <p class="total">total de documentos: <span> {{ totalDocumentos.length }}/{{dataTable.linhas.length}}
                    </span></p>
                <button class="gs-botaoAzulPadrao button-iniciar" (click)="pesquisarEmMassa()">Iniciar Pesquisa em Lote
                    <mat-icon>search</mat-icon> </button>
            </div>
        </div>
    </section>
</div>