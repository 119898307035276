<mat-sidenav-container *ngIf="title !==''">
  <mat-sidenav class="sidenav mat-elevation-z8" mode="side" opened fixedInViewport="true" fixedTopGap="64">
    <div class="img-user">
      <a class="a-logo">
        <img src="assets/img/user_default.svg" alt="logo" class="logo" (click)="toggleMenu()">
      </a>
      <div class="sub-menu-wrap" id="subMenu">
        <div class="sub-menu-cfg">
          <a href="/alteracao-senha" class="senha">Mudar senha</a>
          <a href="/auth/logout" class="sair">Sair</a>
        </div>
      </div>
      <p class="user">{{usuario?.nome}}</p>
    </div>
    <mat-nav-list>
      <mat-tree [dataSource]="dataSource" class="sidenav nav" [treeControl]="treeControl" >
        <mat-tree-node class="{{node.link}}" *matTreeNodeDef="let node" matTreeNodePadding >
          <a [routerLink]="'/' +node.link" mat-list-item>
            <i class="material-icons" >
              {{ node.icon }}
            </i>
            {{node.name}}
          </a>
        </mat-tree-node>
      </mat-tree>
    </mat-nav-list>
  </mat-sidenav>
</mat-sidenav-container>
