import { Component, Input, ElementRef, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Data } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { PesquisaService } from 'src/app/shared/services/http/pesquisa.service';
import { UiService } from 'src/app/shared/services/ui.service';

@Component({
  selector: 'app-linha-tempo',
  templateUrl: './linha-tempo.component.html',
  styleUrls: ['./linha-tempo.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LinhaTempoComponent implements OnInit  {

  chartData;
  tipoParametro = '';
  chartMaxValue;
  chartYValues: number[] = [];
  dadosGrafico;
  isLoadingGrafico = true;
  dados;
  @Input() idPesquisa: number;
  @Input() dadosSintese: any

  constructor(
    private pesquisaService: PesquisaService,
    public ui: UiService,
    public toastr: ToastrService,
    // public dialogRef: MatDialogRef<LinhaTempoComponent>,
    // @Inject(MAT_DIALOG_DATA) public data: Data,
    ) {}

  ngOnInit(){
    // this.getProcessosPorAno(this.data.idPesquisa);
    this.getProcessosPorAno();
    this.tipoParametro = 'quantidade'
  }

  changeTipoParametro(value){
    if(value ==  'valor'){
      this.tipoParametro = 'valor';
      this.getProcessosPorAnoValor();
    }

    if(value ==  'quantidade'){
      this.tipoParametro = 'quantidade';
      this.getProcessosPorAnoQuantidade()
    }

    if(value ==  'linha'){
      this.tipoParametro = 'linha';
    }
  }

  getProcessosPorAno() {
      this.dadosGrafico = this.dadosSintese.sinteseProcessosPorAno;
      this.isLoadingGrafico = false
      // Ordenar a resposta por ano
      this.dadosSintese.sinteseProcessosPorAno.sort((a, b) => parseInt(a.ano) - parseInt(b.ano));
  
      const chartData = this.dadosSintese.sinteseProcessosPorAno.reduce((acc, item) => {
        const yearData = acc.find(data => data.year === item.ano);
        if (yearData) {
          yearData.data[0].value += item.ocorrenciasPoloAtivo;
          yearData.data[0].valor += item.valorPoloAtivo;
          yearData.data[1].value += item.ocorrenciasPoloPassivo;
          yearData.data[1].valor += item.valorPoloPassivo;
          yearData.data[2].value += item.ocorrenciasPoloNaoIdentificado;
          yearData.data[2].valor += item.valorPoloNaoIdentificado;
        } else {
          acc.push({
            year: item.ano,
            data: [
              { label: 'Polo Ativo', value: item.ocorrenciasPoloAtivo, valor: item.valorPoloAtivo },
              { label: 'Polo Passivo', value: item.ocorrenciasPoloPassivo, valor: item.valorPoloPassivo },
              { label: 'Outros', value: item.ocorrenciasPoloNaoIdentificado, valor: item.valorPoloNaoIdentificado }
            ]
          });
        }
        return acc;
      }, []);
  
      const maiorOcorrencia = this.dadosSintese.sinteseProcessosPorAno.reduce((max, obj) => {
        const ocorrencias = Math.max(obj.ocorrenciasPoloAtivo, obj.ocorrenciasPoloPassivo, obj.ocorrenciasPoloNaoIdentificado);
        return Math.max(max, ocorrencias);
      }, 0);
      const yStep = Math.ceil(maiorOcorrencia / 5); // Dividir em 5 etapas e arredondar para cima
      this.chartYValues = Array.from({ length: 6 }, (_, i) => i * yStep).reverse();

  
      this.ui.loaded().subscribe(() => {
        this.chartData = chartData;
        this.chartMaxValue = maiorOcorrencia;
      });
    
  }

  getProcessosPorAnoValor() {
    this.isLoadingGrafico = true
    setTimeout(() => {
      this.isLoadingGrafico = false;
    }, 2000);

      this.dadosGrafico.sort((a, b) => parseInt(a.ano) - parseInt(b.ano));
  
      const chartData = this.dadosGrafico.reduce((acc, item) => {
        const yearData = acc.find(data => data.year === item.ano);
        if (yearData) {
          yearData.data[0].value += item.valorPoloAtivo; // Alterado para valor
          yearData.data[1].value += item.valorPoloPassivo; // Alterado para valor
          yearData.data[2].value += item.valorPoloNaoIdentificado; // Alterado para valor
        } else {
          console.log(item)
          acc.push({
            year: item.ano,
            data: [
              { label: 'Polo Ativo', valor: item.valorPoloAtivo, value: item.ocorrenciasPoloAtivo}, // Alterado para valor
              { label: 'Polo Passivo', valor: item.valorPoloPassivo, value: item.ocorrenciasPoloPassivo}, // Alterado para valor
              { label: 'Outros', valor: item.valorPoloNaoIdentificado, value: item.ocorrenciasPoloNaoIdentificado } // Alterado para valor
            ]
          });
        }
        return acc;
      }, []);
  
      const maiorValor = this.dadosGrafico.reduce((max, obj) => {
        const valores = [obj.valorPoloAtivo, obj.valorPoloPassivo, obj.valorPoloNaoIdentificado];
        const maiorValor = Math.max(...valores);
        return Math.max(max, maiorValor);
      }, 0);
      const yStep = Math.ceil(maiorValor / 5); // Dividir em 5 etapas e arredondar para cima
      this.chartYValues = Array.from({ length: 6 }, (_, i) => i * yStep).reverse();

  
      this.ui.loaded().subscribe(() => {
        this.chartData = chartData;
        this.chartMaxValue = maiorValor;
      });
  }
  getProcessosPorAnoQuantidade() {
    this.isLoadingGrafico = true
    setTimeout(() => {
      this.isLoadingGrafico = false;
    }, 2000);

    this.isLoadingGrafico = true;
      // Ordenar a resposta por ano
      this.dadosGrafico.sort((a, b) => parseInt(a.ano) - parseInt(b.ano));
  
      const chartData = this.dadosGrafico.reduce((acc, item) => {
        const yearData = acc.find(data => data.year === item.ano);
        if (yearData) {
          yearData.data[0].value += item.ocorrenciasPoloAtivo;
          yearData.data[0].valor += item.valorPoloAtivo;
          yearData.data[1].value += item.ocorrenciasPoloPassivo;
          yearData.data[1].valor += item.valorPoloPassivo;
          yearData.data[2].value += item.ocorrenciasPoloNaoIdentificado;
          yearData.data[2].valor += item.valorPoloNaoIdentificado;
        } else {
          acc.push({
            year: item.ano,
            data: [
              { label: 'Polo Ativo', value: item.ocorrenciasPoloAtivo, valor: item.valorPoloAtivo },
              { label: 'Polo Passivo', value: item.ocorrenciasPoloPassivo, valor: item.valorPoloPassivo },
              { label: 'Outros', value: item.ocorrenciasPoloNaoIdentificado, valor: item.valorPoloNaoIdentificado }
            ]
          });
        }
        return acc;
      }, []);
  
      const maiorOcorrencia = this.dadosGrafico.reduce((max, obj) => {
        const ocorrencias = Math.max(obj.ocorrenciasPoloAtivo, obj.ocorrenciasPoloPassivo, obj.ocorrenciasPoloNaoIdentificado);
        return Math.max(max, ocorrencias);
      }, 0);
      const yStep = Math.ceil(maiorOcorrencia / 5); // Dividir em 5 etapas e arredondar para cima
      this.chartYValues = Array.from({ length: 6 }, (_, i) => i * yStep).reverse();

  
      this.ui.loaded().subscribe(() => {
        this.chartData = chartData;
        this.chartMaxValue = maiorOcorrencia;
      });
  }

  formatarValor(value: number): string {
    if (value >= 1000000000) {
      return (value / 1000000000).toFixed(1) + 'B';
    } else if (value >= 1000000) {
      return (value / 1000000).toFixed(1) + 'M';
    } else if (value >= 1000) {
      return (value / 1000).toFixed(1) + 'K';
    } else {
      return value.toString();
    }
  }

  
  
  getTooltipContentQuantidade(item: any): string {
    return `${item.label} \n \n Quantidade: ${item?.value} \n Valor: ${this.formatValue(item?.valor)}`;
  }

  getTooltipContentValor(item: any): string {
    return `${item.label} \n \n Quantidade: ${item?.value} \n Valor: ${this.formatValue(item?.valor)}`;
  }
  
  
  formatValue(value: number): string {
    if(value){
      return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    }
    if(!value){
      value = 0;
      return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    }
  }
}
