import { Component, OnInit, Inject} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserStore } from 'src/app/@core/stores/user.store';
import { ConfiguracaoService } from 'src/app/shared/services/http/configuracao.service';
import { FonteGrupoService } from 'src/app/shared/services/http/fonte-grupo.service';
import { UsuarioService } from 'src/app/shared/services/http/usuario.service';
import { UtilService } from 'src/app/shared/services/http/util.service';
import { UiService } from 'src/app/shared/services/ui.service';
import { ClientePagamentoService } from '../../../shared/services/http/cliente-pagamento.service';
import { FormaPagamento } from '../../../shared/enums/forma-pagamento.enum';
import { User } from 'src/app/@core/interfaces/common/users';
import { ToastrService } from 'ngx-toastr';
import { ColdObservable } from 'rxjs/internal/testing/ColdObservable';
import { PesquisaService } from '../../../shared/services/http/pesquisa.service';
import { StatusPesquisa } from '../../../shared/enums/status-pesquisa.enum';

@Component({
  selector: 'app-poppesqrealizada',
  templateUrl: './poppesqrealizada.component.html',
  styleUrls: ['./poppesqrealizada.component.scss']
})
export class PopPesqRealizadaComponent implements OnInit {
  StatusPesquisa = StatusPesquisa;
  formNovoCredito: FormGroup;
  formaPagamento: any  = []; 
  usuario: any;

  constructor(
    private pesquisaService: PesquisaService,
    protected fb: FormBuilder, 
    private userStore: UserStore,
    private configuracaoService : ConfiguracaoService,
    private ui: UiService,public dialogRef: MatDialogRef<PopPesqRealizadaComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }
  

  ngOnInit(): void { 
    
    this.userStore.onUserStateChange().subscribe((user: User) => {        
      if(!user) return;    
      this.usuario = user; 
    });  

    console.log(this.usuario);

  }


  cadastrarPesquisa(){
    // AQUI ELE PASSAR O VALOR DE DATA QUANDO A MODAL FECHAR CHAMANDO O AFTERCLOSED NO COMPONENTE PAI
    this.dialogRef.close({ data: true })
  }

  exportarClicked(o: { pesquisa: { id: any; }; }) {
    this.ui.loading();
    this.pesquisaService.exportar({ id: o.pesquisa.id }, "").subscribe(data => {
        this.ui.loaded().subscribe(() => {
          var a = document.createElement("a");
          a.href = "data:application/pdf;base64," + data.arquivoConteudo;
          a.download = data.arquivoNome;
          a.click();
        });
    });
  }

  exportarReciboClicked(o: { pesquisa: { id: any; }; }) {
      this.ui.loading();
      this.pesquisaService.exportarRecibo({ id: o.pesquisa.id }, "").subscribe(data => {
          this.ui.loaded().subscribe(() => {
              var a = document.createElement("a");
              a.href = "data:application/pdf;base64," + data.arquivoConteudo;
              a.download = data.arquivoNome;
              a.click();
          });
      });
  }

  exportarExcelClicked(o: { pesquisa: { id: any; }; }) {
      this.ui.loading();
      this.pesquisaService.exportarExcel({ id: o.pesquisa.id }, "").subscribe(data => {
          this.ui.loaded().subscribe(() => {
              var a = document.createElement("a");
              a.href = "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," + data.arquivoConteudo;
              a.download = data.arquivoNome;
              a.click();
          });
      });
  }

}
