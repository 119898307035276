import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ClientePagamentoService {

  private baseUrl = environment.apiUrl + "/cliente-pagamento";

  constructor(public http: HttpClient) { }

  registrarPagamentoGerencianetPIX(item : any) : Observable<any> {
    return this.http.post(`${this.baseUrl}/pix`, item);
  }

  registrarPagamentoGerencianetBoleto(item : any) : Observable<any> {
      return this.http.post(`${this.baseUrl}/boleto`, item);
  }

  registrarPagamentoGerencianetCartao(item : any) : Observable<any> {
      return this.http.post(`${this.baseUrl}/cartao-credito`, item);
  }

  cancelar(item : any) : Observable<any> {
      return this.http.post(`${this.baseUrl}/cancelar`, item);
  }


  getPagamentoPendente(idCliente : any) : Observable<any> {
    return this.http.get(`${this.baseUrl}/pendente?idCliente=${idCliente}`);
  }
}
