import { Component, Input, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { PrecificadorService } from 'src/app/shared/services/precificador/precificador.service';
import { UiService } from 'src/app/shared/services/ui.service';

@Component({
  selector: 'app-step-final',
  templateUrl: './step-final.component.html',
  styleUrls: ['./step-final.component.scss']
})
export class StepFinalComponent implements OnInit {

  @Input() precificadorId: number;
  prescrito;
  creditoAjuizado;

  form: any = {};
  //precificadorId = 0;

  constructor(
    private precificadorService: PrecificadorService,
    private ui: UiService,
    private toastr: ToastrService,
  ) { }

  ngOnInit() {
    this.precificadorId = this.precificadorService.precificadorIdPaivalue;
    console.log(this.precificadorId)
    this.getCreditosAtualizados(this.precificadorId)
  }

  getCreditosAtualizados(idPrecificador) {
    this.ui.loading()
    this.precificadorService.creditosAtualizados(idPrecificador).subscribe(data => {
      this.ui.loaded().subscribe(() => {
        if (data) {
          this.form.descricao = data.identificacao;
          this.form.valorCreditoJudicializado = data.valorAtualizado;
          this.form.valorTotalAtivos = data.valorTotalAtivos;
          this.form.valorTotalPassivos = data.valorTotalPassivos,
            this.form.totalCustas = data.valorTotalCustas;
          this.form.totalAtingivel = data.valortotalAtingivel;
          this.form.recuperacao = data.tempoRecuperacao
          this.form.valorFinalCredito = data.valorFinalCredito
          this.prescrito = data?.prescrito
          this.creditoAjuizado = data.creditoAjuizado
        }
        else {
          console.log('vazio')
        }
      })
    }, (err:any) => {
      this.ui.loaded().subscribe(() => {
        this.toastr.info('Ocorreu um erro carregar a listagem, tente novamente.')
      })
    });
  }

}
