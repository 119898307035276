<div class="content-modalmenu">
    <div mat-dialog-title class="dialog-title">
        <h4>Documento: {{data?.id}} <mat-icon class="icon-title" (click)="copiar(data?.id)" matTooltip="Copiar">file_copy</mat-icon></h4>
        <button mat-icon-button aria-label="close dialog" mat-dialog-close>
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <ul>
        <li class="border-bottom2" (click)="expandirVinculos(data)"> Expandir vínculos societários </li>
        <li class="border-bottom2" (click)="openInformacoesAdicionais()"> Informações Adicionais </li> 
        <li class="border-bottom2"  (click)="expandirFiliais()"> Exibir Filiais</li> 
        <li class="border-bottom2" (click)="ocultarFiliaisNo()"> Ocultar Filiais</li> 
        <li class="border-bottom2"  (click)="ocultarElementos()"> Ocultar Nó</li> 
        <!--<li class="border-bottom2"  (click)="exibirElementos()"> Exibir nós ocultos</li> -->
        <li class="border-bottom2"  (click)="expandirPessoasRelacionadas()"> Exibir Pessoas Relacionadas</li> 
        <li class="border-bottom2"  (click)="ocultarPessoasRelacionadasNo()">Ocultar Pessoas Relacionadas </li> 
    </ul>
    
    <div mat-dialog-title class="dialog-title pt-1 pb-1">
        <h4>Pesquisas</h4>
    </div>
    <ul>
        <li class="border-bottom2" (click)="verificaSeTemProdutoVinculado(1, 'cadastrais')"> Pesquisa de dados cadastrais </li>
        <li class="border-bottom2" (click)="verificaSeTemProdutoVinculado(2, 'processos')">Pesquisa de processos</li>
        <li class="border-bottom2" (click)="verificaSeTemProdutoVinculado(3, 'patrimonial')">Pesquisa patrimonial</li>
        <li class="border-bottom2" (click)="verificaSeTemProdutoVinculado(4, 'analise-credito')"> Score de crédito </li>
        <li class="border-bottom2" (click)="verificaSeTemProdutoVinculado(5, 'dossie-completo')"> Dossiê completo</li>
    </ul>    
</div>