import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';

@Component({
  selector: 'app-janela-selecao',
  templateUrl: './janela-selecao.component.html',
  styleUrls: ['./janela-selecao.component.scss']
})
export class JanelaSelecaoComponent {

  @ViewChild('container') containerRef!: ElementRef;
  @ViewChild('selectionBox') selectionBoxRef!: ElementRef;

  isSelecting = false;
  initialMouseX = 0;
  initialMouseY = 0;
  @Input() movimentoEnabled: any;
  @Output() selecaoChange: EventEmitter<string[]> = new EventEmitter<string[]>();

  @HostListener('document:mousemove', ['$event'])
  onMouseMove(event: MouseEvent) {
    this.onMouseMoveHandler(event);
  }

  @HostListener('document:mouseup')
  onMouseUp() {
    this.endSelection();
  }

  constructor() {}

  onMouseMoveHandler(event: MouseEvent) {
    if (this.isSelecting) {
      const containerRect = this.containerRef.nativeElement.getBoundingClientRect();
      const mouseX = event.clientX - containerRect.left;
      const mouseY = event.clientY - containerRect.top;

      const minX = Math.min(this.initialMouseX, mouseX);
      const minY = Math.min(this.initialMouseY, mouseY);
      const maxX = Math.max(this.initialMouseX, mouseX);
      const maxY = Math.max(this.initialMouseY, mouseY);

      this.selectionBoxRef.nativeElement.style.left = `${minX}px`;
      this.selectionBoxRef.nativeElement.style.top = `${minY}px`;
      this.selectionBoxRef.nativeElement.style.width = `${maxX - minX}px`;
      this.selectionBoxRef.nativeElement.style.height = `${maxY - minY}px`;
      this.selectionBoxRef.nativeElement.style.display = 'block';

      // Atualize a seleção sempre que o mouse se mover durante a seleção
      this.updateSelection();
    }
  }

  startSelection(event: MouseEvent) {
    if (event && this.movimentoEnabled === true) {
      document.getElementById('element').style.pointerEvents = 'none';
      this.isSelecting = true;
  
      // Armazene a posição do mouse em relação ao container
      const containerRect = this.containerRef.nativeElement.getBoundingClientRect();
      this.initialMouseX = event.clientX - containerRect.left;
      this.initialMouseY = event.clientY - containerRect.top;
  
      // Atualize a posição da janela de seleção para a posição inicial do mouse
      this.selectionBoxRef.nativeElement.style.left = `${this.initialMouseX}px`;
      this.selectionBoxRef.nativeElement.style.top = `${this.initialMouseY}px`;
  
      // Limpe a seleção anterior quando iniciar uma nova seleção
      this.clearSelection();
    }
  }

  endSelection() {
    if (this.isSelecting) {
      this.isSelecting = false;
      this.selectionBoxRef.nativeElement.style.display = 'none';
      this.updateSelection(); // Atualize a seleção quando a seleção terminar
    }
  }

  updateSelection() {
    const elements = this.containerRef.nativeElement.getElementsByClassName('nodesSelection');
    const selectedNodes = [];
  
    for (let i = 0; i < elements.length; i++) {
      const element = elements[i];
      const rect = element.getBoundingClientRect();
      const isInBox =
        rect.left >= this.initialMouseX &&
        rect.top >= this.initialMouseY &&
        rect.right <= this.initialMouseX + parseInt(this.selectionBoxRef.nativeElement.style.width) &&
        rect.bottom <= this.initialMouseY + parseInt(this.selectionBoxRef.nativeElement.style.height);
  
      if (isInBox) {
        element.classList.add('selected');
        var doc = element.getElementsByClassName('node-selection');
        selectedNodes.push(doc[0].innerHTML);
      } else {
        element.classList.remove('selected');
      }
    }
  
    // console.log('Selected nodes:', selectedNodes); // Adicione este console.log para verificar se os nós estão sendo selecionados corretamente
  
    // Emita os nós selecionados para o componente pai (SeuComponente)
    this.selecaoChange.emit(selectedNodes);
  }
  

  clearSelection() {
    const elements = this.containerRef.nativeElement.getElementsByClassName('nodesSelection');
    for (let i = 0; i < elements.length; i++) {
      const element = elements[i];
      element.classList.remove('selected');
    }
  }
}