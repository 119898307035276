import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, Subject, catchError, tap, throwError } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { ToastrService } from 'ngx-toastr';

@Injectable({
    providedIn: 'root'
})
export class NotificacoesService {

    private baseUrl = environment.apiUrl + "/notificacoes";

    private functionCalledSource = new Subject<void>();
    functionCalled$ = this.functionCalledSource.asObservable();

    constructor(public http: HttpClient, private toastr: ToastrService,) { }

    listar(idUsuario: number, idCliente: number): Observable<any> {
        return this.http.get(`${this.baseUrl}/listar-notificacao/?IdUsuario=${idUsuario}&IdCliente=${idCliente}`);
    } 

    atualizar(item): Observable<any> {
        return this.http.put(`${this.baseUrl}/atualizar`,item);
    }

    cadastrarNotificacao(item): Observable<any> {
        return this.http.post(`${this.baseUrl}/cadastrar`, item);
    }

    deletar(id: number): Observable<any> {
        return this.http.get(`${this.baseUrl}/deletar/?IdNotificacao${id}`);
    }

    marcarTodasComoLido(item): Observable<any>{
        return this.http.put(`${this.baseUrl}/marcar-todas`, item);
    }

    callFunction() {
        this.functionCalledSource.next();
    }
}
