<mat-accordion *ngIf="exibeAcordosLeniencia">
    <mat-expansion-panel class="mt-3 py-2" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <h3 class=" title-cordion mb-0 d-flex align-items-center">
            <mat-icon class="detech-icon">label_important</mat-icon>&nbsp; Colaboração premiada em investigações
          </h3>
        </mat-panel-title>
  
      </mat-expansion-panel-header>
      <table class="table" *ngIf="!isLoadingAcordosLeniencia && !exibeMensagemAcordosLeniencia">
        <thead class="thead-light">
          <tr class="text-center">
            <th scope="col" class="thead-top-left p-2">ID do Acordo</th>
            <th scope="col" class="thead p-2">Data Inicio do Acordo</th>
            <th scope="col" class="thead p-2">Data Final do Acordo</th>
            <th scope="col" class="thead p-2">Situação do Acordo</th>
            <th scope="col" class="thead p-2">Número do Processo</th>
            <th scope="col" class="thead-top-right p-2">Orgão Sancionador</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngIf="dataAcordosLeniencia.dados?.length > 0; else noDataAcordosLeniencia">
            <tr class="text-center table-info" *ngFor="let dados of dataAcordosLeniencia.dados">
              <td>{{dados?.id_do_acordo}}</td>
              <td>{{dados?.data_de_inicio_do_acordo}}</td>
              <td>{{dados?.data_de_final_do_acordo}}</td>
              <td>{{dados?.situacao_do_acordo_de_leniencia}}</td>
              <td>{{dados?.numero_do_processo}}</td>
              <td>{{dados?.orgao_sancionador}}</td>
            </tr>
          </ng-container>
          <ng-template #noDataAcordosLeniencia>
            <tr>
              <td colspan="6" class="text-center">Não há resultados disponíveis</td>
            </tr>
          </ng-template>
        </tbody>
      </table>
      <div class="d-flex justify-content-center text-center flex-column" *ngIf="!isLoadingAcordosLeniencia && exibeMensagemAcordosLeniencia">
        <div class="d-flex justify-content-end" *ngIf="exibeReprocessarAcordosLeniencia">
          <button mat-flat-button class="gs-botaoAzulPadrao m-3" (click)="getAcordosLeniencia(idPesquisa, 129)">Refresh <mat-icon>cached
          </mat-icon></button>
        </div>
        <div>
          <p>{{dataAcordosLeniencia.mensagem}}</p>
        </div>
      </div>
       <loader-only *ngIf="isLoadingAcordosLeniencia"></loader-only>
    </mat-expansion-panel>
  </mat-accordion>