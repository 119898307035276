import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, catchError, tap, throwError } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { FormaPagamento } from '../../enums/forma-pagamento.enum';
import { ToastrService } from 'ngx-toastr';

@Injectable({
    providedIn: 'root'
})
export class PesquisaMassaService {

    private baseUrl = environment.apiUrl + "/pesquisas-em-massa";
    private baseUrlDownload = environment.apiUrl + "/pesquisa";

    constructor(public http: HttpClient, private toastr: ToastrService,) { }

    validarPlanilha(file: any): Observable<any> {
        return this.http.post(`${this.baseUrl}/validar-planilha`, file);
    }

    pesquisarEmMassa(file: any, produto: string): Observable<any> {
        return this.http.post(`${this.baseUrl}/importar-planilha`, file,  { headers: { "Produto": produto }});
    }

    downloadEmMassa(item,  produto: string): Observable<any> {
        return this.http.post(`${this.baseUrlDownload}/exportar-multiplos-relatorios-cliente`, item,  { headers: { "Produto": produto }});
    }

}
