import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { takeWhile, timer } from 'rxjs';
import { PesquisaService } from 'src/app/shared/services/http/pesquisa.service';

@Component({
  selector: 'app-sintese-patrimonial',
  templateUrl: './sintese-patrimonial.component.html',
  styleUrls: ['./sintese-patrimonial.component.scss']
})
export class SintesePatrimonialComponent implements OnInit {

  @Input() idPesquisa: number;
  @Input() aba: any;
  @Input() statusPesquisa: number;

  dataVeiculos;
  isLoadingVeiculos = false;
  exibeVeiculos = false;
  exibeMensagemVeiculos = false;
  exibeReprocessarVeiculos = false;

  dataMinerios;
  isLoadingMinerios = false;
  exibeMinerios = false;
  exibeMensagemMinerios = false;
  exibeReprocessarMinerios = false;

  dataImovelRequerimento;
  isLoadingImovelRequerimento = false;
  exibeImovelRequerimento = false;
  exibeMensagemImovelRequerimento = false;
  exibeReprocessarImovelRequerimento = false;

  dataImovelParcela;
  isLoadingImovelParcela = false;
  exibeImovelParcela = false;
  exibeMensagemImovelParcela = false;
  exibeReprocessarImovelParcela = false;

  dataImovelSerpro;
  isLoadingImovelSerpro = false;
  exibeImovelSerpro = false;
  exibeMensagemImovelSerpro = false;
  exibeReprocessarImovelSerpro = false;

  dataAeronaves;
  isLoadingAeronaves = false;
  exibeAeronaves = false;
  exibeMensagemAeronave = false;
  exibeReprocessarAeronaves = false;

  dataEmbarcacoes;
  isLoadingEmbarcacoes = false;
  exibeEmbarcacoes = false;
  exibeMensagemEmbarcacoes = false;
  exibeReprocessarEmbarcacoes = false;
  
  dataSafra;
  isLoadingSafra = false;
  exibeSafra = false;
  exibeMensagemSafra = false;
  exibeReprocessarSafra = false;

  dataImovelUniao;
  isLoadingImovelUniao = false;
  exibeImovelUniao = false;
  exibeMensagemImovelUniao = false;
  exibeReprocessarImovelUniao = false;

  dataRegistradoresRioGrandeSul;
  isLoadingRegistradoresRioGrandeSul = false;
  exibeRegistradoresRioGrandeSul = false;
  exibeMensagemRioGrandeSul = false;
  exibeReprocessarRegistradoresRioGrandeSul = false

  dataRegistradoresSantaCatarina;
  isLoadingRegistradoresSantaCatarina = false;
  exibeRegistradoresSantaCatarina = false;
  exibeMensagemSantaCatarina = false;
  exibeReprocessarRegistradoresSantaCatarina = false;

  dataRegistradoresSaoPaulo;
  isLoadingRegistradoresSaoPaulo = false;
  exibeRegistradoresSaoPaulo = false;
  exibeMensagemSaoPaulo = false;
  exibeReprocessarRegistradoresSaoPaulo = false;

  dataRegistradoresEspiritoSanto;
  isLoadingRegistradoresEspiritoSanto = false;
  exibeRegistradoresEspiritoSanto = false;
  exibeMensagemEspiritoSanto =  false;
  exibeReprocessarRegistradoresEspiritoSanto = false;

  dataRegistradoresRioJaneiro;
  isLoadingRegistradoresRioJaneiro = false;
  exibeRegistradoresRioJaneiro = false;
  exibeMensagemRioJaneiro = false;
  exibeReprocessarRegistradoresRioJaneiro = false;

  dataRegistradoresRondonia;
  isLoadingRegistradoresRondonia = false;
  exibeRegistradoresRondonia = false;
  exibeMensagemRondonia = false;
  exibeReprocessarRegistradoresRondonia = false;

  dataRegistradoresParana;
  isLoadingRegistradoresParana = false;
  exibeRegistradoresParana = false;
  exibeMensagemParana = false;
  exibeReprocessarRegistradoresParana = false;

  dataRegistradoresDistritoFederal;
  isLoadingRegistradoresDistritoFederal = false;
  exibeRegistradoresDistritoFederal = false;
  exibeMensagemDistritoFederal = false;
  exibeReprocessarRegistradoresDistritoFederal = false;

  dataRegistradoresMatoGrossoSul;
  isLoadingRegistradoresMatoGrossoSul = false;
  exibeRegistradoresMatoGrossoSul = false;
  exibeMensagemMatoGrossoSul = false;
  exibeReprocessarRegistradoresMatoGrossoSul = false;

  dataRegistradoresQualificada;
  isLoadingRegistradoresQualificada = false;
  exibeRegistradoresQualificada = false;
  exibeMensagemQualificada = false;
  exibeReprocessarQualificada = false;

  dataRegistradoresQualificadaBA;
  isLoadingRegistradoresQualificadaBA = false;
  exibeRegistradoresQualificadaBA = false;
  exibeMensagemQualificadaBA = false;
  exibeReprocessarQualificadaBA = false;

  dataRegistradoresQualificadaGO;
  isLoadingRegistradoresQualificadaGO = false;
  exibeRegistradoresQualificadaGO = false;
  exibeMensagemQualificadaGO = false;
  exibeReprocessarQualificadaGO = false;

  fontesSelecionadas

  panelOpenState = false


  constructor(
    private pesquisaService: PesquisaService
  ) { }

  ngOnInit(): void {
    this.getVeiculos(this.idPesquisa);
    this.getMinerios(this.idPesquisa);
    this.getImovelRequerimento(this.idPesquisa);
    this.getImovelParcela(this.idPesquisa);
    this.getImovelSerpro(this.idPesquisa);
    this.getAeronave(this.idPesquisa);
    this.getEmbarcacoes(this.idPesquisa);
    this.getSafra(this.idPesquisa);
    this.getImovelUniao(this.idPesquisa);

    if(this.aba.novaPesquisa){
      this.fontesSelecionadas = this.aba?.dadosPesquisa?.valorAdicional?.fontesAdicionais;
    }

    if(!this.aba.novaPesquisa){
      let objetoParse =  JSON.parse(this.aba.dadosPesquisa);
      this.fontesSelecionadas = objetoParse.FontesAdicionais
    }

    const registradoresMap = {
      218: this.getRegistradoresQualificada.bind(this),
      225: this.getRegistradoresQualificadaGO.bind(this),
      224: this.getRegistradoresQualificadaBA.bind(this),
      77: this.getRegistradoresRioGrandeSul.bind(this),
      79: this.getRegistradoresSantaCatarina.bind(this),
      91: this.getRegistradoresSaoPaulo.bind(this),
      129: this.getRegistradoresEspiritoSanto.bind(this),
      198: this.getRegistradoresRioJaneiro.bind(this),
      206: this.getRegistradoresRondonia.bind(this),
      207: this.getRegistradoresParana.bind(this),
      208: this.getRegistradoresDistritoFederal.bind(this),
      209: this.getRegistradoresMatoGrossoSul.bind(this)
    };
    
    this.fontesSelecionadas.forEach(fonteId => {
      const registradorFunc = registradoresMap[fonteId];
      if (registradorFunc) {
        registradorFunc(this.idPesquisa, fonteId);
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.statusPesquisa && changes.statusPesquisa.currentValue !== undefined) {
    }
  }

  getVeiculos(idPesquisa) {
    this.isLoadingVeiculos = true;
    this.exibeMensagemVeiculos = false;
    this.exibeReprocessarVeiculos = false;
    let attempts = 0;
  
    const subscription = timer(0, 15000).pipe(
      takeWhile(() => attempts < 5) // Limita a 5 tentativas
    ).subscribe(() => {
      this.pesquisaService.getVeiculos(idPesquisa).subscribe(
        response => {
          this.dataVeiculos = response;
          switch (response.codigo) {
            case 4000:
              this.exibeVeiculos = false;
              this.isLoadingVeiculos = false;
              subscription.unsubscribe();
              break;
            case 1000:
              this.exibeVeiculos = true;
              this.isLoadingVeiculos = false;
              subscription.unsubscribe();
              break;
            case 2000:
              this.exibeVeiculos = true;
              this.isLoadingVeiculos = false;
              subscription.unsubscribe();
              break;
            case 3001:
              this.exibeVeiculos = true;
              this.isLoadingVeiculos = false;
              this.exibeMensagemVeiculos = true;
              subscription.unsubscribe();
              break;
            case 3000:
              this.exibeVeiculos = true;
              this.isLoadingVeiculos = false;
              this.exibeMensagemVeiculos = true;
              attempts++;
              if (attempts >= 5){
                this.exibeReprocessarVeiculos = true;
                subscription.unsubscribe();
              }
              break;
          }
        },
        error => {
          attempts++;
          if (attempts >= 5) {
            this.exibeVeiculos = false;
            this.isLoadingVeiculos = false;
            subscription.unsubscribe(); // Interrompe o intervalo após 5 tentativas
          }
        }
      );
    });
  }  
  
  getRegistradoresQualificada(idPesquisa, fonte) {
    this.isLoadingRegistradoresQualificada = true;
    this.exibeMensagemQualificada = false;
    this.exibeReprocessarQualificada = false;
    let attempts = 0;
  
    const subscription = timer(0, 15000).pipe(
      takeWhile(() => attempts < 5) // Limita a 5 tentativas
    ).subscribe(() => {
      this.pesquisaService.getRegistradoresQualificada(idPesquisa, fonte).subscribe(
        response => {
          this.dataRegistradoresQualificada = response;
          switch (response.codigo) {
            case 4000:
              this.exibeRegistradoresQualificada = false;
              this.isLoadingRegistradoresQualificada = false;
              subscription.unsubscribe();
              break;
            case 1000:
              this.exibeRegistradoresQualificada = true;
              this.isLoadingRegistradoresQualificada = false;
              this.exibeMensagemQualificada = false;
              subscription.unsubscribe();
              break;
              case 2000:
                this.exibeRegistradoresQualificada = true;
                this.isLoadingRegistradoresQualificada = false;
                subscription.unsubscribe();
                break;
            case 3001:
              this.exibeRegistradoresQualificada = true;
              this.isLoadingRegistradoresQualificada = false;
              this.exibeMensagemQualificada = true;
              subscription.unsubscribe();
              break;
            case 3000:
              this.exibeRegistradoresQualificada = true;
              this.isLoadingRegistradoresQualificada = false;
              this.exibeMensagemQualificada = true;
              attempts++;
              if (attempts >= 5){
                this.exibeReprocessarQualificada = true;
                subscription.unsubscribe();
              }
              break;
          }
        },
        error => {
          attempts++;
          if (attempts >= 5) {
            this.exibeRegistradoresQualificada = true;
            this.isLoadingRegistradoresQualificada = false;
            subscription.unsubscribe(); // Interrompe o intervalo após 5 tentativas
          }
          else{
            this.exibeRegistradoresQualificada = true; 
            this.isLoadingRegistradoresQualificada = true; 
          }
        }
      );
    });
  }  

  getRegistradoresQualificadaBA(idPesquisa, fonte) {
    this.isLoadingRegistradoresQualificadaBA = true;
    this.exibeMensagemQualificadaBA = false;
    this.exibeReprocessarQualificadaBA = false;
    let attempts = 0;
  
    const subscription = timer(0, 15000).pipe(
      takeWhile(() => attempts < 5) // Limita a 5 tentativas
    ).subscribe(() => {
      this.pesquisaService.getRegistradoresQualificada(idPesquisa, fonte).subscribe(
        response => {
          this.dataRegistradoresQualificadaBA = response;
          switch (response.codigo) {
            case 4000:
              this.exibeRegistradoresQualificadaBA = false;
              this.isLoadingRegistradoresQualificadaBA = false;
              subscription.unsubscribe();
              break;
            case 1000:
              this.exibeRegistradoresQualificadaBA = true;
              this.isLoadingRegistradoresQualificadaBA = false;
              this.exibeMensagemQualificadaBA = false;
              subscription.unsubscribe();
              break;
              case 2000:
                this.exibeRegistradoresQualificadaBA = true;
                this.isLoadingRegistradoresQualificadaBA = false;
                subscription.unsubscribe();
                break;
            case 3001:
              this.exibeRegistradoresQualificadaBA = true;
              this.isLoadingRegistradoresQualificadaBA = false;
              this.exibeMensagemQualificadaBA = true;
              subscription.unsubscribe();
              break;
            case 3000:
              this.exibeRegistradoresQualificadaBA = true;
              this.isLoadingRegistradoresQualificadaBA = false;
              this.exibeMensagemQualificadaBA = true;
              attempts++;
              if (attempts >= 5){
                this.exibeReprocessarQualificadaBA = true;
                subscription.unsubscribe();
              }
              break;
          }
        },
        error => {
          attempts++;
          if (attempts >= 5) {
            this.exibeRegistradoresQualificadaBA = true;
            this.isLoadingRegistradoresQualificadaBA = false;
            subscription.unsubscribe(); // Interrompe o intervalo após 5 tentativas
          }
          else{
            this.exibeRegistradoresQualificadaBA = true; 
            this.isLoadingRegistradoresQualificadaBA = true; 
          }
        }
      );
    });
  }  

  getRegistradoresQualificadaGO(idPesquisa, fonte) {
    this.isLoadingRegistradoresQualificadaGO = true;
    this.exibeMensagemQualificadaGO = false;
    this.exibeReprocessarQualificadaGO = false;
    let attempts = 0;
  
    const subscription = timer(0, 15000).pipe(
      takeWhile(() => attempts < 5) // Limita a 5 tentativas
    ).subscribe(() => {
      this.pesquisaService.getRegistradoresQualificada(idPesquisa, fonte).subscribe(
        response => {
          this.dataRegistradoresQualificadaGO = response;
          switch (response.codigo) {
            case 4000:
              this.exibeRegistradoresQualificadaGO = false;
              this.isLoadingRegistradoresQualificadaGO = false;
              subscription.unsubscribe();
              break;
            case 1000:
              this.exibeRegistradoresQualificadaGO = true;
              this.isLoadingRegistradoresQualificadaGO = false;
              this.exibeMensagemQualificadaGO = false;
              subscription.unsubscribe();
              break;
              case 2000:
                this.exibeRegistradoresQualificadaGO = true;
                this.isLoadingRegistradoresQualificadaGO = false;
                subscription.unsubscribe();
                break;
            case 3001:
              this.exibeRegistradoresQualificadaGO = true;
              this.isLoadingRegistradoresQualificadaGO = false;
              this.exibeMensagemQualificadaGO = true;
              subscription.unsubscribe();
              break;
            case 3000:
              this.exibeRegistradoresQualificadaGO = true;
              this.isLoadingRegistradoresQualificadaGO = false;
              this.exibeMensagemQualificadaGO = true;
              attempts++;
              if (attempts >= 5){
                this.exibeReprocessarQualificadaGO = true;
                subscription.unsubscribe();
              }
              break;
          }
        },
        error => {
          attempts++;
          if (attempts >= 5) {
            this.exibeRegistradoresQualificadaGO = true;
            this.isLoadingRegistradoresQualificadaGO = false;
            subscription.unsubscribe(); // Interrompe o intervalo após 5 tentativas
          }
          else{
            this.exibeRegistradoresQualificadaGO = true; 
            this.isLoadingRegistradoresQualificadaGO = true; 
          }
        }
      );
    });
  }  

  getRegistradoresRioGrandeSul(idPesquisa, fonte) {
    this.isLoadingRegistradoresRioGrandeSul = true;
    this.exibeMensagemRioGrandeSul = false;
    this.exibeReprocessarRegistradoresRioGrandeSul = false
    let attempts = 0;
  
    const subscription = timer(0, 15000).pipe(
      takeWhile(() => attempts < 5) // Limita a 5 tentativas
    ).subscribe(() => {
      this.pesquisaService.getRegistradores(idPesquisa, fonte).subscribe(
        response => {
          this.dataRegistradoresRioGrandeSul = response;
          switch (response.codigo) {
            case 4000:
              this.exibeRegistradoresRioGrandeSul = false;
              this.isLoadingRegistradoresRioGrandeSul = false;
              subscription.unsubscribe();
              break;
            case 1000:
              this.exibeRegistradoresRioGrandeSul = true;
              this.isLoadingRegistradoresRioGrandeSul = false;
              this.exibeMensagemRioGrandeSul= false;
              subscription.unsubscribe();
              break;
            case 2000:
              this.exibeRegistradoresRioGrandeSul = true;
              this.isLoadingRegistradoresRioGrandeSul = false;
              subscription.unsubscribe();
              break;
            case 3001:
              this.exibeRegistradoresRioGrandeSul = true;
              this.isLoadingRegistradoresRioGrandeSul = false;
              this.exibeMensagemRioGrandeSul = true;
              subscription.unsubscribe();
              break;
            case 3000:
              this.exibeRegistradoresRioGrandeSul = true;
              this.isLoadingRegistradoresRioGrandeSul = false;
              this.exibeMensagemRioGrandeSul = true;
              attempts++;
              if (attempts >= 5) {
                this.exibeReprocessarRegistradoresRioGrandeSul = true;
                subscription.unsubscribe();
              }
              break;
          }
        },
        error => {
          attempts++;
          if (attempts >= 5) {
            this.exibeRegistradoresRioGrandeSul = false;
            this.isLoadingRegistradoresRioGrandeSul = false;
            subscription.unsubscribe(); // Interrompe o intervalo após 5 tentativas
          }
        }
      );
    });
  }

  getRegistradoresSantaCatarina(idPesquisa, fonte) {
    this.isLoadingRegistradoresSantaCatarina = true;
    this.exibeMensagemSantaCatarina = false;
    this.exibeReprocessarRegistradoresSantaCatarina = false;
    let attempts = 0;
  
    const subscription = timer(0, 15000).pipe(
      takeWhile(() => attempts < 5) // Limita a 5 tentativas
    ).subscribe(() => {
      this.pesquisaService.getRegistradores(idPesquisa, fonte).subscribe(
        response => {
          this.dataRegistradoresSantaCatarina = response;
          switch (response.codigo) {
            case 4000:
              this.exibeRegistradoresSantaCatarina = false;
              this.isLoadingRegistradoresSantaCatarina = false;
              subscription.unsubscribe();
              break;
            case 1000:
              this.exibeRegistradoresSantaCatarina = true;
              this.isLoadingRegistradoresSantaCatarina = false;
              this.exibeMensagemSantaCatarina = false;
              subscription.unsubscribe();
              break;
            case 2000:
              this.exibeRegistradoresSantaCatarina = true;
              this.isLoadingRegistradoresSantaCatarina = false;
              subscription.unsubscribe();
              break;
            case 3001:
              this.exibeRegistradoresSantaCatarina = true;
              this.isLoadingRegistradoresSantaCatarina = false;
              this.exibeMensagemSantaCatarina = true;
              subscription.unsubscribe();
              break;
            case 3000:
              this.exibeRegistradoresSantaCatarina = true;
              this.isLoadingRegistradoresSantaCatarina = false;
              this.exibeMensagemSantaCatarina = true;
              attempts++;
              if (attempts >= 5) {
                this.exibeReprocessarRegistradoresSantaCatarina = true;
                subscription.unsubscribe();
              }
              break;
          }
        },
        error => {
          attempts++;
          if (attempts >= 5) {
            this.exibeRegistradoresSantaCatarina = false;
            this.isLoadingRegistradoresSantaCatarina = false;
            subscription.unsubscribe(); // Interrompe o intervalo após 5 tentativas
          }
        }
      );
    });
  }  

  getRegistradoresSaoPaulo(idPesquisa, fonte) {
    this.isLoadingRegistradoresSaoPaulo = true;
    this.exibeMensagemSaoPaulo = false;
    this.exibeReprocessarRegistradoresSaoPaulo = false;
    let attempts = 0;
  
    const subscription = timer(0, 15000).pipe(
      takeWhile(() => attempts < 5) // Limita a 5 tentativas
    ).subscribe(() => {
      this.pesquisaService.getRegistradores(idPesquisa, fonte).subscribe(
        response => {
          this.dataRegistradoresSaoPaulo = response;
          switch (response.codigo) {
            case 4000:
              this.exibeRegistradoresSaoPaulo = false;
              this.isLoadingRegistradoresSaoPaulo = false;
              subscription.unsubscribe();
              break;
            case 1000:
              this.exibeRegistradoresSaoPaulo = true;
              this.isLoadingRegistradoresSaoPaulo = false;
              this.exibeMensagemSaoPaulo = false;
              subscription.unsubscribe();
              break;
            case 2000:
                this.exibeRegistradoresSaoPaulo = true;
                this.isLoadingRegistradoresSaoPaulo = false;
                subscription.unsubscribe();
                break;
            case 3001:
              this.exibeRegistradoresSaoPaulo = true;
              this.isLoadingRegistradoresSaoPaulo = false;
              this.exibeMensagemSaoPaulo = true;
              subscription.unsubscribe();
              break;
            case 3000:
              this.exibeRegistradoresSaoPaulo = true;
              this.isLoadingRegistradoresSaoPaulo = false;
              this.exibeMensagemSaoPaulo = true;
              attempts++;
              if (attempts >= 5) {
                this.exibeReprocessarRegistradoresSaoPaulo = true;
                subscription.unsubscribe();
              }
              break;
          }
        },
        error => {
          attempts++;
          if (attempts >= 5) {
            this.exibeRegistradoresSaoPaulo = false;
            this.isLoadingRegistradoresSaoPaulo = false;
            subscription.unsubscribe(); // Interrompe o intervalo após 5 tentativas
          }
        }
      );
    });
  }  

  getRegistradoresEspiritoSanto(idPesquisa, fonte) {
    this.isLoadingRegistradoresEspiritoSanto = true;
    this.exibeMensagemEspiritoSanto = false;
    this.exibeReprocessarRegistradoresEspiritoSanto = false;
    let attempts = 0;
  
    const subscription = timer(0, 15000).pipe(
      takeWhile(() => attempts < 5) // Limita a 5 tentativas
    ).subscribe(() => {
      this.pesquisaService.getRegistradores(idPesquisa, fonte).subscribe(
        response => {
          this.dataRegistradoresEspiritoSanto = response;
          switch (response.codigo) {
            case 4000:
              this.exibeRegistradoresEspiritoSanto = false;
              this.isLoadingRegistradoresEspiritoSanto = false;
              subscription.unsubscribe();
              break;
            case 1000:
              this.exibeRegistradoresEspiritoSanto = true;
              this.isLoadingRegistradoresEspiritoSanto = false;
              this.exibeMensagemEspiritoSanto = false;
              subscription.unsubscribe();
              break;
            case 2000:
                this.exibeRegistradoresEspiritoSanto = true;
                this.isLoadingRegistradoresEspiritoSanto = false;
                subscription.unsubscribe();
                break;
            case 3001:
              this.exibeRegistradoresEspiritoSanto = true;
              this.isLoadingRegistradoresEspiritoSanto = false;
              this.exibeMensagemEspiritoSanto = true;
              subscription.unsubscribe();
              break;
            case 3000:
              this.exibeRegistradoresEspiritoSanto = true;
              this.isLoadingRegistradoresEspiritoSanto = false;
              this.exibeMensagemEspiritoSanto = true;
              attempts++;
              if (attempts >= 5) {
                this.exibeReprocessarRegistradoresEspiritoSanto = true;
                subscription.unsubscribe();
              }
              break;
          }
        },
        error => {
          attempts++;
          if (attempts >= 5) {
            this.exibeRegistradoresEspiritoSanto = false;
            this.isLoadingRegistradoresEspiritoSanto = false;
            subscription.unsubscribe(); // Interrompe o intervalo após 5 tentativas
          }
        }
      );
    });
  }  

  getRegistradoresRioJaneiro(idPesquisa, fonte) {
    this.isLoadingRegistradoresRioJaneiro = true;
    this.exibeMensagemRioJaneiro = false;
    this.exibeReprocessarRegistradoresRioJaneiro = false;
    let attempts = 0;
  
    const subscription = timer(0, 15000).pipe(
      takeWhile(() => attempts < 5) // Limita a 5 tentativas
    ).subscribe(() => {
      this.pesquisaService.getRegistradores(idPesquisa, fonte).subscribe(
        response => {
          this.dataRegistradoresRioJaneiro = response;
          switch (response.codigo) {
            case 4000:
              this.exibeRegistradoresRioJaneiro = false;
              this.isLoadingRegistradoresRioJaneiro = false;
              subscription.unsubscribe();
              break;
            case 1000:
              this.exibeRegistradoresRioJaneiro = true;
              this.isLoadingRegistradoresRioJaneiro = false;
              this.exibeMensagemRioJaneiro = false;
              subscription.unsubscribe();
              break;
            case 2000:
                this.exibeRegistradoresRioJaneiro = true;
                this.isLoadingRegistradoresRioJaneiro = false;
                subscription.unsubscribe();
                break;
            case 3001:
              this.exibeRegistradoresRioJaneiro = true;
              this.isLoadingRegistradoresRioJaneiro = false;
              this.exibeMensagemRioJaneiro = true;
              subscription.unsubscribe();
              break;
            case 3000:
              this.exibeRegistradoresRioJaneiro = true;
              this.isLoadingRegistradoresRioJaneiro = false;
              this.exibeMensagemRioJaneiro = true;
              attempts++;
              if (attempts >= 5) {
                this.exibeReprocessarRegistradoresRioJaneiro = true;
                subscription.unsubscribe();
              }
              break;
          }
        },
        error => {
          attempts++;
          if (attempts >= 5) {
            this.exibeRegistradoresRioJaneiro = false;
            this.isLoadingRegistradoresRioJaneiro = false;
            subscription.unsubscribe(); // Interrompe o intervalo após 5 tentativas
          }
        }
      );
    });
  }  

  getRegistradoresRondonia(idPesquisa, fonte) {
    this.isLoadingRegistradoresRondonia = true;
    this.exibeMensagemRondonia = false;
    this.exibeReprocessarRegistradoresRondonia = false;
    let attempts = 0;
  
    const subscription = timer(0, 15000).pipe(
      takeWhile(() => attempts < 5) // Limita a 5 tentativas
    ).subscribe(() => {
      this.pesquisaService.getRegistradores(idPesquisa, fonte).subscribe(
        response => {
          this.dataRegistradoresRondonia = response;
          switch (response.codigo) {
            case 4000:
              this.exibeRegistradoresRondonia = false;
              this.isLoadingRegistradoresRondonia = false;
              subscription.unsubscribe();
              break;
            case 1000:
              this.exibeRegistradoresRondonia = true;
              this.isLoadingRegistradoresRondonia = false;
              this.exibeMensagemRondonia = false;
              subscription.unsubscribe();
              break;
            case 2000:
              this.exibeRegistradoresRondonia = true;
              this.isLoadingRegistradoresRondonia = false;
              subscription.unsubscribe();
              break;
            case 3001:
              this.exibeRegistradoresRondonia = true;
              this.isLoadingRegistradoresRondonia = false;
              this.exibeMensagemRondonia = true;
              subscription.unsubscribe();
              break;
            case 3000:
              this.exibeRegistradoresRondonia = true;
              this.isLoadingRegistradoresRondonia = false;
              this.exibeMensagemRondonia = true;
              attempts++;
              if (attempts >= 5) {
                this.exibeReprocessarRegistradoresRondonia = true;
                subscription.unsubscribe();
              }
              break;
          }
        },
        error => {
          attempts++;
          if (attempts >= 5) {
            this.exibeRegistradoresRondonia = false;
            this.isLoadingRegistradoresRondonia = false;
            subscription.unsubscribe(); // Interrompe o intervalo após 5 tentativas
          }
        }
      );
    });
  }  

  getRegistradoresParana(idPesquisa, fonte) {
    this.isLoadingRegistradoresParana = true;
    this.exibeMensagemParana = false;
    this.exibeReprocessarRegistradoresParana = false;
    let attempts = 0;
  
    const subscription = timer(0, 15000).pipe(
      takeWhile(() => attempts < 5) // Limita a 5 tentativas
    ).subscribe(() => {
      this.pesquisaService.getRegistradores(idPesquisa, fonte).subscribe(
        response => {
          this.dataRegistradoresParana = response;
          switch (response.codigo) {
            case 4000:
              this.exibeRegistradoresParana = false;
              this.isLoadingRegistradoresParana = false;
              subscription.unsubscribe();
              break;
            case 1000:
              this.exibeRegistradoresParana = true;
              this.isLoadingRegistradoresParana = false;
              this.exibeMensagemParana = false;
              subscription.unsubscribe();
              break;
              case 2000:
                this.exibeRegistradoresParana = true;
                this.isLoadingRegistradoresParana = false;
                subscription.unsubscribe();
                break;
            case 3001:
              this.exibeRegistradoresParana = true;
              this.isLoadingRegistradoresParana = false;
              this.exibeMensagemParana = true;
              subscription.unsubscribe();
              break;
            case 3000:
              this.exibeRegistradoresParana = true;
              this.isLoadingRegistradoresParana = false;
              this.exibeMensagemParana = true;
              attempts++;
              if (attempts >= 5) {
                this.exibeReprocessarRegistradoresParana = false;
                subscription.unsubscribe();
              }
              break;
          }
        },
        error => {
          attempts++;
          if (attempts >= 5) {
            this.exibeRegistradoresParana = false;
            this.isLoadingRegistradoresParana = false;
            subscription.unsubscribe(); // Interrompe o intervalo após 5 tentativas
          }
        }
      );
    });
  }  

  getRegistradoresDistritoFederal(idPesquisa, fonte) {
    this.isLoadingRegistradoresDistritoFederal = true;
    this.exibeMensagemDistritoFederal = false;
    this.exibeReprocessarRegistradoresDistritoFederal = false;
    let attempts = 0;
  
    const subscription = timer(0, 15000).pipe(
      takeWhile(() => attempts < 5) // Limita a 5 tentativas
    ).subscribe(() => {
      this.pesquisaService.getRegistradores(idPesquisa, fonte).subscribe(
        response => {
          this.dataRegistradoresDistritoFederal = response;
          switch (response.codigo) {
            case 4000:
              this.exibeRegistradoresDistritoFederal = false;
              this.isLoadingRegistradoresDistritoFederal = false;
              subscription.unsubscribe();
              break;
            case 1000:
              this.exibeRegistradoresDistritoFederal = true;
              this.isLoadingRegistradoresDistritoFederal = false;
              this.exibeMensagemDistritoFederal = false;
              subscription.unsubscribe();
              break;
            case 2000:
                this.exibeRegistradoresDistritoFederal = true;
                this.isLoadingRegistradoresDistritoFederal = false;
                subscription.unsubscribe();
                break;
            case 3001:
              this.exibeRegistradoresDistritoFederal = true;
              this.isLoadingRegistradoresDistritoFederal = false;
              this.exibeMensagemDistritoFederal = true;
              subscription.unsubscribe();
              break;
            case 3000:
              this.exibeRegistradoresDistritoFederal = true;
              this.isLoadingRegistradoresDistritoFederal = false;
              this.exibeMensagemDistritoFederal = true;
              attempts++;
              if (attempts >= 5) {
                this.exibeReprocessarRegistradoresDistritoFederal = false;
                subscription.unsubscribe();
              }
              break;
          }
        },
        error => {
          attempts++;
          if (attempts >= 5) {
            this.exibeRegistradoresDistritoFederal = false;
            this.isLoadingRegistradoresDistritoFederal = false;
            subscription.unsubscribe(); // Interrompe o intervalo após 5 tentativas
          }
        }
      );
    });
  }
  

  getRegistradoresMatoGrossoSul(idPesquisa, fonte) {
    this.isLoadingRegistradoresMatoGrossoSul = true;
    this.exibeMensagemMatoGrossoSul = false;
    this.exibeReprocessarRegistradoresMatoGrossoSul = false;
    let attempts = 0;
  
    const subscription = timer(0, 15000).pipe(
      takeWhile(() => attempts < 5) // Limita a 5 tentativas
    ).subscribe(() => {
      this.pesquisaService.getRegistradores(idPesquisa, fonte).subscribe(
        response => {
          this.dataRegistradoresMatoGrossoSul = response;
          switch (response.codigo) {
            case 4000:
              this.exibeRegistradoresMatoGrossoSul = false;
              this.isLoadingRegistradoresMatoGrossoSul = false;
              subscription.unsubscribe();
              break;
            case 1000:
              this.exibeRegistradoresMatoGrossoSul = true;
              this.isLoadingRegistradoresMatoGrossoSul = false;
              this.exibeMensagemMatoGrossoSul = false;
              subscription.unsubscribe();
              break;
            case 2000:
                this.exibeRegistradoresMatoGrossoSul = true;
                this.isLoadingRegistradoresMatoGrossoSul = false;
                subscription.unsubscribe();
                break;
            case 3001:
              this.exibeRegistradoresMatoGrossoSul = true;
              this.isLoadingRegistradoresMatoGrossoSul = false;
              this.exibeMensagemMatoGrossoSul = true;
              subscription.unsubscribe();
              break;
            case 3000:
              this.exibeRegistradoresMatoGrossoSul = true;
              this.isLoadingRegistradoresMatoGrossoSul = false;
              this.exibeMensagemMatoGrossoSul = true;
              attempts++;
              if (attempts >= 5) {
                this.exibeReprocessarRegistradoresMatoGrossoSul = true;
                subscription.unsubscribe();
              }
              break;
          }
        },
        error => {
          attempts++;
          if (attempts >= 5) {
            this.exibeRegistradoresMatoGrossoSul = false;
            this.isLoadingRegistradoresMatoGrossoSul = false;
            subscription.unsubscribe(); // Interrompe o intervalo após 5 tentativas
          }
        }
      );
    });
  }  


  getMinerios(idPesquisa) {
    this.isLoadingMinerios = true;
    this.exibeMensagemMinerios = false;
    this.exibeReprocessarMinerios = false;
    let attempts = 0;
  
    const subscription = timer(0, 15000).pipe(
      takeWhile(() => attempts < 5) // Limita a 5 tentativas
    ).subscribe(() => {
      this.pesquisaService.getMinerios(idPesquisa).subscribe(
        response => {
          this.dataMinerios = response;
          switch (response.codigo) {
            case 4000:
              this.exibeMinerios = false;
              this.isLoadingMinerios = false;
              subscription.unsubscribe();
              break;
            case 1000:
              this.exibeMinerios = true;
              this.isLoadingMinerios = false;
              this.exibeMensagemMinerios = false;
              subscription.unsubscribe();
              break;
            case 2000:
                this.exibeMinerios = true;
                this.isLoadingMinerios = false;
                subscription.unsubscribe();
                break;
            case 3001:
              this.exibeMinerios = true;
              this.isLoadingMinerios = false;
              this.exibeMensagemMinerios = true;
              subscription.unsubscribe();
              break;
            case 3000:
              this.exibeMinerios = true;
              this.isLoadingMinerios = false;
              this.exibeMensagemMinerios = true;
              attempts++;
              if (attempts >= 5) {
                this.exibeReprocessarMinerios = true;
                subscription.unsubscribe();
              }
              break;
          }
        },
        error => {
          attempts++;
          if (attempts >= 5) {
            this.exibeMinerios = false;
            this.isLoadingMinerios = false;
            subscription.unsubscribe(); // Interrompe o intervalo após 5 tentativas
          }
        }
      );
    });
  }  

  getImovelRequerimento(idPesquisa) {
    this.isLoadingImovelRequerimento = true;
    this.exibeMensagemImovelRequerimento = false;
    this.exibeReprocessarImovelRequerimento = false;
    let attempts = 0;
  
    const subscription = timer(0, 15000).pipe(
      takeWhile(() => attempts < 5) // Limita a 5 tentativas
    ).subscribe(() => {
      this.pesquisaService.getImovelRequerimento(idPesquisa).subscribe(
        response => {
          this.dataImovelRequerimento = response;
          switch (response.codigo) {
            case 4000:
              this.exibeImovelRequerimento = false;
              this.isLoadingImovelRequerimento = false;
              subscription.unsubscribe();
              break;
            case 1000:
              this.exibeImovelRequerimento = true;
              this.isLoadingImovelRequerimento = false;
              this.exibeMensagemImovelRequerimento = false;
              subscription.unsubscribe();
              break;
            case 2000:
                this.exibeImovelRequerimento = true;
                this.isLoadingImovelRequerimento = false;
                subscription.unsubscribe();
                break;
            case 3001:
              this.exibeImovelRequerimento = true;
              this.isLoadingImovelRequerimento = false;
              this.exibeMensagemImovelRequerimento = true
              subscription.unsubscribe();
              break;
            case 3000:
              this.exibeImovelRequerimento = true;
              this.isLoadingImovelRequerimento = false;
              this.exibeMensagemImovelRequerimento = true
              attempts++;
              if (attempts >= 5) {
                this.exibeReprocessarImovelRequerimento = true;
                subscription.unsubscribe();
              }
              break;
          }
        },
        error => {
          attempts++;
          if (attempts >= 5) {
            this.exibeImovelRequerimento = false;
            this.isLoadingImovelRequerimento = false;
            subscription.unsubscribe(); // Interrompe o intervalo após 5 tentativas
          }
        }
      );
    });
  }  

  getImovelParcela(idPesquisa) {
    this.isLoadingImovelParcela = true;
    this.exibeMensagemImovelParcela = false;
    this.exibeReprocessarImovelParcela = false;
    let attempts = 0;
  
    const subscription = timer(0, 15000).pipe(
      takeWhile(() => attempts < 5) // Limita a 5 tentativas
    ).subscribe(() => {
      this.pesquisaService.getImovelParcela(idPesquisa).subscribe(
        response => {
          this.dataImovelParcela = response;
          switch (response.codigo) {
            case 4000:
              this.exibeImovelParcela = false;
              this.isLoadingImovelParcela = false;
              subscription.unsubscribe();
              break;
            case 1000:
              this.exibeImovelParcela = true;
              this.isLoadingImovelParcela = false;
              this.exibeMensagemImovelParcela = false;
              subscription.unsubscribe();
              break;
            case 2000:
                this.exibeImovelParcela = true;
                this.isLoadingImovelParcela = false;
                subscription.unsubscribe();
                break;
            case 3001:
              this.exibeImovelParcela = true;
              this.isLoadingImovelParcela = false;
              this.exibeMensagemImovelParcela = true;
              subscription.unsubscribe();
              break;
            case 3000:
              this.exibeImovelParcela = true;
              this.isLoadingImovelParcela = false;
              this.exibeMensagemImovelParcela = true;
              attempts++;
              if (attempts >= 5) {
                this.exibeReprocessarImovelParcela = true;
                subscription.unsubscribe();
              }
              break;
          }
        },
        error => {
          attempts++;
          if (attempts >= 5) {
            this.exibeImovelParcela = false;
            this.isLoadingImovelParcela = false;
            subscription.unsubscribe(); // Interrompe o intervalo após 5 tentativas
          }
        }
      );
    });
  }  

  getImovelSerpro(idPesquisa) {
    this.isLoadingImovelSerpro = true;
    this.exibeMensagemImovelSerpro = false;
    this.exibeReprocessarImovelSerpro = false;
    let attempts = 0;
  
    const subscription = timer(0, 15000).pipe(
      takeWhile(() => attempts < 5) // Limita a 5 tentativas
    ).subscribe(() => {
      this.pesquisaService.getImovelSerpro(idPesquisa).subscribe(
        response => {
          this.dataImovelSerpro = response;
          console.log(this.dataImovelSerpro)
          switch (response.codigo) {
            case 4000:
              this.exibeImovelSerpro = false;
              this.isLoadingImovelSerpro = false;
              subscription.unsubscribe();
              break;
            case 1000:
              this.exibeImovelSerpro = true;
              this.isLoadingImovelSerpro = false;
              this.exibeMensagemImovelSerpro = false;
              subscription.unsubscribe();
              break;
            case 2000:
                this.exibeImovelSerpro = true;
                this.isLoadingImovelSerpro = false;
                subscription.unsubscribe();
                break;
            case 3001:
              this.exibeImovelSerpro = true;
              this.isLoadingImovelSerpro = false;
              this.exibeMensagemImovelSerpro = true;
              subscription.unsubscribe();
              break;
            case 3000:
              this.exibeImovelSerpro = true;
              this.isLoadingImovelSerpro = false;
              this.exibeMensagemImovelSerpro = true;
              attempts++;
              if (attempts >= 5) {
                this.exibeReprocessarImovelSerpro = false;
                subscription.unsubscribe();
              }
              break;
          }
        },
        error => {
          attempts++;
          if (attempts >= 5) {
            this.exibeImovelSerpro = false;
            this.isLoadingImovelSerpro = false;
            subscription.unsubscribe(); // Interrompe o intervalo após 5 tentativas
          }
        }
      );
    });
  }  

  getAeronave(idPesquisa) {
    this.isLoadingAeronaves = true;
    this.exibeMensagemAeronave = false;
    this.exibeReprocessarAeronaves = false;
    let attempts = 0;
  
    const subscription = timer(0, 15000).pipe(
      takeWhile(() => attempts < 5) // Limita a 5 tentativas
    ).subscribe(() => {
      this.pesquisaService.getAeronave(idPesquisa).subscribe(
        response => {
          this.dataAeronaves = response;
          switch (response.codigo) {
            case 4000:
              this.exibeAeronaves = false;
              this.isLoadingAeronaves = false;
              subscription.unsubscribe();
              break;
            case 1000:
              this.exibeAeronaves = true;
              this.isLoadingAeronaves = false;
              this.exibeMensagemAeronave = false;
              subscription.unsubscribe();
              break;
            case 2000:
                this.exibeAeronaves = true;
                this.isLoadingAeronaves = false;
                subscription.unsubscribe();
                break;
            case 3001:
              this.exibeAeronaves = true;
              this.isLoadingAeronaves = false;
              this.exibeMensagemAeronave = true;
              subscription.unsubscribe();
              break;
            case 3000:
              this.exibeAeronaves = true;
              this.isLoadingAeronaves = false;
              this.exibeMensagemAeronave = true;
              attempts++;
              if (attempts >= 5) {
                this.exibeReprocessarAeronaves = true;
                subscription.unsubscribe();
              }
              break;
          }
        },
        error => {
          attempts++;
          if (attempts >= 5) {
            this.exibeAeronaves = false;
            this.isLoadingAeronaves = false;
            subscription.unsubscribe(); // Interrompe o intervalo após 5 tentativas
          }
        }
      );
    });
  }  

  getEmbarcacoes(idPesquisa) {
    this.isLoadingEmbarcacoes = true;
    this.exibeMensagemEmbarcacoes = false;
    this.exibeReprocessarEmbarcacoes = false;
    let attempts = 0;
  
    const subscription = timer(0, 15000).pipe(
      takeWhile(() => attempts < 5) // Limita a 5 tentativas
    ).subscribe(() => {
      this.pesquisaService.getEmbarcacoes(idPesquisa).subscribe(
        response => {
          this.dataEmbarcacoes = response;
          switch (response.codigo) {
            case 4000:
              this.exibeEmbarcacoes = false;
              this.isLoadingEmbarcacoes = false;
              subscription.unsubscribe();
              break;
            case 1000:
              this.exibeEmbarcacoes = true;
              this.isLoadingEmbarcacoes = false;
              this.exibeMensagemEmbarcacoes = false;
              subscription.unsubscribe();
              break;
            case 2000:
                this.exibeEmbarcacoes = true;
                this.isLoadingEmbarcacoes = false;
                subscription.unsubscribe();
              break;
            case 3001:
              this.exibeEmbarcacoes = true;
              this.isLoadingEmbarcacoes = false;
              this.exibeMensagemEmbarcacoes = true;
              subscription.unsubscribe();
              break;
            case 3000:
              this.exibeEmbarcacoes = true;
              this.isLoadingEmbarcacoes = false;
              this.exibeMensagemEmbarcacoes = true;
              attempts++;
              if (attempts >= 5) {
                this.exibeReprocessarEmbarcacoes = true;
                subscription.unsubscribe();
              }
              break;
          }
        },
        error => {
          attempts++;
          if (attempts >= 5) {
            this.exibeEmbarcacoes = false;
            this.isLoadingEmbarcacoes = false;
            subscription.unsubscribe(); // Interrompe o intervalo após 5 tentativas
          }
        }
      );
    });
  }  

  getSafra(idPesquisa) {
    this.isLoadingSafra = true;
    this.exibeMensagemSafra = false;
    this.exibeReprocessarSafra = false;
    let attempts = 0;
  
    const subscription = timer(0, 15000).pipe(
      takeWhile(() => attempts < 5) // Limita a 5 tentativas
    ).subscribe(() => {
      this.pesquisaService.getSafra(idPesquisa).subscribe(
        response => {
          this.dataSafra = response;
          switch (response.codigo) {
            case 4000:
              this.exibeSafra = false;
              this.isLoadingSafra = false;
              subscription.unsubscribe();
              break;
            case 1000:
              this.exibeSafra = true;
              this.isLoadingSafra = false;
              this.exibeMensagemSafra = false;
              subscription.unsubscribe();
              break;
            case 2000:
                this.exibeSafra = true;
                this.isLoadingSafra = false;
                subscription.unsubscribe();
                break;
            case 3001:
              this.exibeSafra = true;
              this.isLoadingSafra = false;
              this.exibeMensagemSafra = true;
              subscription.unsubscribe();
              break;
            case 3000:
              this.exibeSafra = true;
              this.isLoadingSafra = false;
              this.exibeMensagemSafra = true;
              attempts++;
              if (attempts >= 5) {
                this.exibeReprocessarSafra = true;
                subscription.unsubscribe();
              }
              break;
          }
        },
        error => {
          attempts++;
          if (attempts >= 5) {
            this.exibeSafra = false;
            this.isLoadingSafra = false;
            subscription.unsubscribe(); // Interrompe o intervalo após 5 tentativas
          }
        }
      );
    });
  }  

  getImovelUniao(idPesquisa) {
    this.isLoadingImovelUniao = true;
    this.exibeMensagemImovelUniao = false;
    this.exibeReprocessarImovelUniao = false;
    let attempts = 0;
  
    const subscription = timer(0, 15000).pipe(
      takeWhile(() => attempts < 5) // Limita a 5 tentativas
    ).subscribe(() => {
      this.pesquisaService.getImovelUniao(idPesquisa).subscribe(
        response => {
          this.dataImovelUniao = response;
          switch (response.codigo) {
            case 4000:
              this.exibeImovelUniao = false;
              this.isLoadingImovelUniao = false;
              subscription.unsubscribe();
              break;
            case 1000:
              this.exibeImovelUniao = true;
              this.isLoadingImovelUniao = false;
              this.exibeMensagemImovelUniao = false;
              subscription.unsubscribe();
              break;
            case 2000:
                this.exibeImovelUniao = true;
                this.isLoadingImovelUniao = false;
                subscription.unsubscribe();
              break;
            case 3001:
              this.exibeImovelUniao = true;
              this.isLoadingImovelUniao = false;
              this.exibeMensagemImovelUniao = true;
              subscription.unsubscribe();
              break;
            case 3000:
              this.exibeImovelUniao = true;
              this.isLoadingImovelUniao = false;
              this.exibeMensagemImovelUniao = true;
              attempts++;
              if (attempts >= 5) {
                this.exibeReprocessarImovelUniao = true;
                subscription.unsubscribe();
              }
              break;
          }
        },
        error => {
          attempts++;
          if (attempts >= 5) {
            this.exibeImovelUniao = false;
            this.isLoadingImovelUniao = false;
            subscription.unsubscribe(); // Interrompe o intervalo após 5 tentativas
          }
        }
      );
    });
  }
  
}
