import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Data } from '@angular/router';

@Component({
  selector: 'app-modal-confirmacao-nirf',
  templateUrl: './modal-confirmacao-nirf.component.html',
  styleUrls: ['./modal-confirmacao-nirf.component.scss']
})
export class ModalConfirmacaoNirfComponent implements OnInit {

  enviar = false;
  constructor(
    public dialogRef: MatDialogRef<ModalConfirmacaoNirfComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Data,
  ) { }

  ngOnInit(): void {
  }

  emitirCertidao(){
    this.dialogRef.close()  
     this.enviar = true
    console.log(this.data.res)
  }

}
