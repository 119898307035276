import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Data } from '@angular/router';
import { TeiaRelacionamentoService } from 'src/app/shared/services/teia-relacionamento/teia-relacionamento.service';
import { TeiaRelacionamentoComponent } from '../teia-relacionamento.component';

@Component({
  selector: 'app-modal-confirmacao',
  templateUrl: './modal-confirmacao.component.html',
  styleUrls: ['./modal-confirmacao.component.scss']
})
export class ModalConfirmacaoComponent implements OnInit {
  result;
  @Input() item: TeiaRelacionamentoComponent;
  constructor(
    public teiaRelacionamentoService: TeiaRelacionamentoService,
    public dialogRef: MatDialogRef<ModalConfirmacaoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Data,
  ) { }

  ngOnInit(): void {
    console.log('teste modal confirmacao', this.data)
  }

  getNewTeia(){
    this.dialogRef.close()  
    this.dialogRef.afterClosed().subscribe(result => {
     this.data.res = 1;
    }) 
    console.log(this.data)
  }

  buscarDocumento(){
  this.item.dadosGrafico = []
    this.teiaRelacionamentoService.buscarDocumento(this.data).subscribe(data => {
      this.item.dadosGrafico = data
      // this.chart()
      this.item.highChart()
    });
  }

}
