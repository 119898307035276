import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Data, Router } from '@angular/router';

@Component({
  selector: 'app-modal-valores-pesquisa',
  templateUrl: './modal-valores-pesquisa.component.html',
  styleUrls: ['./modal-valores-pesquisa.component.scss']
})
export class ModalValoresPesquisaComponent implements OnInit {

  valorTotalParametros;
  produto;
  isLote = false;

  fontesQualificadas = [
    231, 232, 233, 234, 235, 236, 237, 238, 239, 240,
    241, 242, 243, 244, 245, 246, 247, 248, 249, 250,
    224, 225, 218
  ];

  fontesPrevias = [77, 79, 91, 129, 198, 206, 207, 208, 209, 222];

  fontesQualificadasSelecionada = [];
  fontesPreviasSelecionada = []

  parametrosAvulsos = [];

  constructor(
    private route: Router,
    public dialogRef: MatDialogRef<ModalValoresPesquisaComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Data,
  ) { }

  ngOnInit(): void {
    console.log(this.data)
    if (this.route.url == '/dossie') {
      this.produto = 'Dossiê Completo'
    }
    if (this.route.url == '/credito') {
      this.produto = 'Analise de Crédito'
    }
    if (this.route.url == '/cadastrais') {
      this.produto = 'Cadastrais'
    }
    if (this.route.url == '/patrimonial') {
      this.produto = 'Patrimonial'
    }

    if(this.data.tipoPesquisa == 1){
      this.isLote = true;
      this.verificaSeTemFontesQualificadaLote(this.fontesQualificadas);
      this.verificaSeTemPreviaLote(this.fontesPrevias)
      this.verificaSeTemParametrosLote()
    }
    if(!this.data.tipoPesquisa){
      this.verificaSeTemFontesQualificada(this.fontesQualificadas);
      this.verificaSeTemPrevia(this.fontesPrevias)
      this.verificaSeTemParametros()
    }
  }

  verificaSeTemFontesQualificada(fontes: number[]) {
    fontes.forEach(fonteId => {
      const fonteQF = this.data.dados.fontesAdicionaisSelecionadas.find(x => x.fonte === fonteId);
  
      if (fonteQF) {
        const novoParametro = {
          mensagem: fonteQF.descricao,
          valor: fonteQF.valor,
          fonte: fonteQF.fonte
        };
  
        // Remove a fonte processada da lista
        this.data.dados.fontesAdicionaisSelecionadas = this.data.dados.fontesAdicionaisSelecionadas.filter(
          adicional => adicional.fonte !== fonteId
        );
       
        this.fontesQualificadasSelecionada.push(novoParametro)
        console.log(this.fontesQualificadasSelecionada)
      }
    });
  }

  verificaSeTemPrevia(fontes: number[]) {
    fontes.forEach(fonteId => {
      const fontePrevia = this.data.dados.fontesAdicionaisSelecionadas.find(x => x.fonte === fonteId);
  
      if (fontePrevia) {
        const novoParametro = {
          mensagem: fontePrevia.descricao,
          valor: fontePrevia.valor,
          fonte: fontePrevia.fonte
        };
       
        this.data.dados.fontesAdicionaisSelecionadas = this.data.dados.fontesAdicionaisSelecionadas.filter(
          adicional => adicional.fonte !== fonteId
        );

        this.fontesPreviasSelecionada.push(novoParametro)
        console.log(this.fontesPreviasSelecionada)
      }
    });
  }

  verificaSeTemParametros(){
    const faturamento = this.data.dados.parametrosSelecionados.find(x => x.idParametro === "FATURAMENTO");
    const segundoNivel = this.data.dados.parametrosSelecionados.find(x => x.idParametro === "NIVEL2");
    const gravame =  this.data.dados.parametrosSelecionados.find(x => x.idParametro === 'gravame');
    if(faturamento){
      this.data.dados.parametrosSelecionados = this.data.dados.parametrosSelecionados.filter(x => x.idParametro !== 'FATURAMENTO');
      this.parametrosAvulsos.push(faturamento);
    }
    if(segundoNivel){
      this.data.dados.parametrosSelecionados = this.data.dados.parametrosSelecionados.filter(x => x.idParametro !== 'NIVEL2');
      this.parametrosAvulsos.push(segundoNivel);
    }
    if(gravame){
      this.data.dados.parametrosSelecionados = this.data.dados.parametrosSelecionados.filter(x => x.idParametro !== 'gravame');
      this.parametrosAvulsos.push(gravame);
    }
  }

  exibirMensagemQualificada(): boolean {
    return this.data?.adicionais?.fontesAdicionais.includes(218);
  }

  exibirMensagemQualificadaLote(): boolean {
    return this.data?.somaValores?.fontesAdicionaisSelecionadas.map(x => x.fonte).includes(218);
  }

  verificaSeTemParametrosLote(){
    const faturamento = this.data.somaValores.parametrosSelecionados.find(x => x.idParametro === "FATURAMENTO");
    const segundoNivel = this.data.somaValores.parametrosSelecionados.find(x => x.idParametro === "NIVEL2");
    const gravame = this.data.somaValores.parametrosSelecionados.find(x => x.idParametro === "gravame");
    if(faturamento){
      this.data.somaValores.parametrosSelecionados = this.data.somaValores.parametrosSelecionados.filter(x => x.idParametro !== 'FATURAMENTO');
      this.parametrosAvulsos.push(faturamento);
    }
    if(segundoNivel){
      this.data.somaValores.parametrosSelecionados = this.data.somaValores.parametrosSelecionados.filter(x => x.idParametro !== 'NIVEL2');
      this.parametrosAvulsos.push(segundoNivel);
    }
    if(gravame){
      this.data.somaValores.parametrosSelecionados = this.data.somaValores.parametrosSelecionados.filter(x => x.idParametro !== 'gravame');
      this.parametrosAvulsos.push(gravame);
    }
  }

  verificaSeTemMGLote(){
    const fonteMG = this.data.somaValores.fontesAdicionaisSelecionadas.find(x => x.fonte === 218);

    if(fonteMG){
      const novoParametro = {
        mensagem: fonteMG.descricao,
        valor: fonteMG.valor,
        fonte: fonteMG.fonte
      };
      this.data.somaValores.fontesAdicionaisSelecionadas = this.data.somaValores.fontesAdicionaisSelecionadas.filter(adicional => adicional.fonte !== 218);
      // this.data.dados.parametrosSelecionados.push(novoParametro);
    }
  }

  verificaSeTemFontesQualificadaLote(fontes: number[]) {
    fontes.forEach(fonteId => {
      const fonteQF = this.data.somaValores.fontesAdicionaisSelecionadas.find(x => x.fonte === fonteId);
  
      if (fonteQF) {
        const novoParametro = {
          mensagem: fonteQF.descricao,
          valor: fonteQF.valor,
          fonte: fonteQF.fonte
        };
  
        // Remove a fonte processada da lista
        this.data.somaValores.fontesAdicionaisSelecionadas = this.data.somaValores.fontesAdicionaisSelecionadas.filter(
          adicional => adicional.fonte !== fonteId
        );
       
        this.fontesQualificadasSelecionada.push(novoParametro);
        console.log(this.fontesQualificadasSelecionada)
      }
    });
  }

  verificaSeTemPreviaLote(fontes: number[]) {
    fontes.forEach(fonteId => {
      const fontePrevia = this.data.somaValores.fontesAdicionaisSelecionadas.find(x => x.fonte === fonteId);
  
      if (fontePrevia) {
        const novoParametro = {
          mensagem: fontePrevia.descricao,
          valor: fontePrevia.valor,
          fonte: fontePrevia.fonte
        };
       
        this.data.somaValores.fontesAdicionaisSelecionadas = this.data.somaValores.fontesAdicionaisSelecionadas.filter(
          adicional => adicional.fonte !== fonteId
        );

        this.fontesPreviasSelecionada.push(novoParametro)
        console.log(this.fontesPreviasSelecionada)
      }
    });
  }

  // calculaTotalParametros(dados){
  //   let valores = dados.map(x => x.selecionados)
  //   this.valorTotalParametros = valores.reduce((acc, curr) => acc + curr, 0);
  // }

  realizarPesquisa(): void {
    this.dialogRef.close('realizarPesquisa');
  }

  fecharModal(): void {
    this.dialogRef.close();
  }

}

