export enum Fonte {
    // TJSP = 1,
    // TRF3 = 2,
    // Registradores = 3,
    // CRIRS = 4,
    // ANVISA = 5,
    // PortalDaTransparencia = 6,
    DadosCadastrais = 1,
    PessoasExpostasPoliticamente = 7,
    Anac = 8,
    CNDT = 9,
    TrabalhoEscravo = 10,
    ImoveisMiami = 11,
    ImoveisOrlando = 12,
    IPTUSP = 13,
    IbamaAreasEmbargadas = 14,
    IbamaCertRegularidade = 15,
    CNDTributosSP = 16,
    ProcessosAcre = 18,
    ProcessosAmazonas = 19,
    ProcessosBahia = 20,
    ProcessosRioGrandeDoNorte = 21,
    ProcessosCeara = 22,
    ProcessosSaoPaulo = 23,
    ProcessosRoraima = 24,
    ProcessosSantaCatarina = 25,
    ProcessosParana = 26,
    Ceiam = 27,
    IdoneidadeEmpresa = 28,
    ImovelRural = 29,
    ListaDevedores = 31,
    SeguroRural = 32,
    ProcessosMatoGrosso = 33,
    ProcessosTocantins = 34,
    ProcessosFederaisTerceiraRegiao = 35,
    ProcessosMarinha = 36,
    ProcessosFederaisQuartaRegiao = 37,
    RegistroImoveis = 38,
    ProcessosRioDeJaneiro = 39,
    Anvisa = 40,
    ProcessosFederaisQuintaRegiao = 41,
    CadastroInscricaoEstadual = 42,
    INPIMarca = 43,
    ProcessosAdministrativos = 44,
    RTDPJBrasil = 45,
    Fundo157 = 46,
    AntecedentesCriminaisPF = 47,
    SituacaoCadastralFGTS = 48,
    HabilitacaoParaExportacaoEImportacao = 50,
    Minerios = 51,
    ProcessosEspiritoSanto = 52,
    Bndes = 53,
    Cesdi = 54,
    ImoveisUniao = 55,
    Protestos = 56,
    AgenciaNacionalAguas = 57,
    TransitoAnimal = 58,
    ProcessosGoias = 59,
    ProcessosMaranhao = 60,
    ProcessosDigitaisEspiritoSanto = 61,
    ProcessosFederaisAlagoas = 62,
    ProcessosFederaisCeara = 63,
    ProcessosFederaisParaiba = 64,
    ProcessosFederaisPernambuco = 65,
    ProcessosFederaisRioGrandeDoNorte = 66,
    ProcessosAlagoas = 67,
    ProcessosAmapa = 68,
    ProcessosCearaConsultaPublica = 69,
    ProcessosDistritoFederal = 70,
    ProcessosSecaoSergipe = 71,
    ProcessosFederaisSergipe = 72,
    Offshore = 73,
    Aneel = 74,
    CVM = 75,
    ProcessosEletronicosParana = 76,
    ImoveisRS = 77,
    ProcessosMinasGerais = 78,
    ImoveisSC = 79,
    ProcessosMatoGrossoDoSul = 80,
    ProcessosParaiba = 81,
    ProcessosPara = 82,
    ProcessosPernambuco = 83,
    ProcessosPiaui = 84,
    ProcessosRioGrandeDoSul = 85,
    ProcessosRondonia = 86,
    ImoveisMaranhao = 87,
    Veiculos = 88,
    ImovelRuralSNCR = 89,
    AtosMatoGrosso = 90,
    Registradores = 91,

    RegistroBR = 93,
    JUCESP = 94,
    SIATER = 95,
    ANTAQ = 96,
    CadastroAmbientalRuralPara = 99,
    CARF = 100,
    CertidoesAdministracaoPublicaFederal = 101,
    BusinessMonitor = 103,
    RegistroDeImoveisGoias = 104,
    CUITOnline = 105,
    OffshoreDateasArgentina = 106,
    ProcessosTRF1 = 108,
    MarcasIrlanda = 109,
    ProcessosBahiaPJE = 110,
    ProcessosDigitaisMaranhao = 111,
    ProcessosDigitaisPiaui = 112,
    ProcessosRioGrandeDoNortePJE = 113,
    ProcessosAmapaTucujuris = 116,
    ProcessosFederaisEspiritoSantoEPROC = 117,
    ProcessosFederaisRioDeJaneiroEPROC = 118,
    ProcessosEstaduaisRoraimaProjudi = 119,
    ProcessosEstaduaisParanaProjudi = 120,
    ProcessosDigitaisTocantinsProjudi = 121,
    ProcessosDigitaisDistritoFederalProjudi = 122,
    ProcessosEstaduaisSantaCatarinaEPROC = 123,
    ProcessosEstatuaisTribunalJusticaParaiba = 124,
    ProcessosDigitaisParaProjudi = 125,
    // ProcessosDigitaisBahiaProjudi = 126,
    ProcessosDigitaisMinasGeraisProjudi = 127,
    ImoveisMinasGerais = 128,
    RegistradoresEspiritoSanto = 129,
    // ProcessosTocantinsEproc = 129,
    ProcessosEstaduaisMaranhaoJurisConsult = 130,
    ProcessosEstaduaisPernambucoTJPE = 131,
    ProcessosEstaduaisMatoGrossoTJMT = 132,
    // ProcessosEstaduaisRioGrandeDoSulTJRS = 133,
    ProcessosEstaduaisSergipeTJSE = 134,
    ProcessosEstaduaisRondoniaTJRO = 135,
    OffshoreFlorida = 136,
    OffshoreReinoUnido = 137,
    DoadoresFornecedoresTSE = 138,
    FinanciamentoColetivoTSE = 139,
    OffshorePanama = 140,
    MarcasUniaoEuropeia = 141,
    InformacoesNewYork = 142,
    ContribuintesMobiliariosSP = 143,
    Transportadores = 144,
    DomicilioEleitoral = 145,
    ImprobidadeAdministrativa = 146,
    TribunalDeContasDaUniao = 147,
    ImovelRuralParcela = 148,
    AnacAeronave = 149,
    CertidaoCartaoCnpj = 150,
    CertidaoDividaUniaoPessoaFisica = 151,
    CertidaoProtesto = 152,
    CertidaoDividaUniaoImovelRural = 153,
    CertidaoCadastroImovelRural = 154,
    CertidaoDebitoIbama = 155,
    CertidaoImovelUniaoSpu = 156,
    CertidaoAutorizacaoTransferencia = 157,
    CertidaoDebitosObra = 158,
    CertidaoDividaUniaoPessoaJuridica = 17,
    CertidaoImprobidadeAdministrativa = 159,
    CertidaoSituacaoCadastralFgts = 160,
    CertidaoDebitosPrevidenciarios = 161,
    CertidaoNadaConstaEmbargos = 162,
    CertidaoMPFederal = 163,
    CertidaoContratacaoPessoasDeficienciaEBeneficiariosReabilitados = 164,
    CertidaoFazendaEstadualSaoPaulo = 165,
    CertidaoFazendaEstadualBahia = 166,
    CertidaoNegativaProcessoTribunalContasUniao = 167,
    CertidaoContasJulgadasIrregulares = 168,
    CertidaoContasJulgadasIrregularesComImplicacaoEleitoral = 169,
    CertidaoNegativaDeInabilitadosTribunalContasUniao = 170,
    CertidaoNegativaDeInidoneoTribunalContasUniao = 171,
    CertidaoFazendaEstadualMatoGrosso = 172,
    CertidaoFazendaEstadualMatoGrossoDoSul = 173,
    CertidaoFazendaEstadualEspiritoSanto = 174,
    CertidaoFazendaEstadualMinasGerais = 175,
    CertidaoFazendaEstadualPernambuco = 176,
}