import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { FlatTreeControl } from '@angular/cdk/tree';
import { Router } from '@angular/router';
import { NavService } from './nav.service';
import { AuthService } from 'src/app/shared/services/http/auth.service';
import { MatSidenav } from '@angular/material/sidenav';

interface NavNode {
  name: string;
  icon: string;
  link: string;
  children?: NavNode[];
}

const TREE_DATA: NavNode[] = [
  {
    name: 'Dashboard',
    icon: '',
    link: 'dashboard',
  },
  {
    name: 'Informações Cadastrais',
    icon: '',
    link: 'cadastrais',
  },
  {
    name: 'Pesquisa de Processos',
    icon: '',
    link: 'processo',
  },
  {
    name: 'Pesquisa Patrimonial',
    icon: '',
    link: 'patrimonial',

  },
  {
    name: 'Análise de Crédito',
    icon: ' ',
    link: 'credito',
  },
  {
    name: 'Dossiê Completo',
    icon: '',
    link: 'dossie',
  },{
    name: 'Certidões',
    icon: '',
    link: 'certidoes'
  },
  {
    name: 'Histórico',
    icon: '',
    link: 'historico'
  }
];

/** Flat node with expandable and level information */
interface ExampleFlatNode {
  expandable: boolean;
  name: string;
  icon: string;
  link: string;
  level: number;
}

/**
 * @title Tree with flat nodes
 */


@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.css']
})
export class NavComponent implements OnInit {
  @ViewChild(MatSidenav)
  sidenav!: MatSidenav;
  /*---------navbar---------*/


  private _transformer = (node: NavNode, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.name,
      icon: node.icon,
      link: node.link,
      level: level,
    };
  };

  treeControl = new FlatTreeControl<ExampleFlatNode>(
    node => node.level,
    node => node.expandable,
  );

  treeFlattener = new MatTreeFlattener(
    this._transformer,
    node => node.level,
    node => node.expandable,
    node => node.children,
  );

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
  usuario = {};

  constructor(
    private router : Router, 
    private navService: NavService, 
    private authService : AuthService
    ) {
    this.dataSource.data = TREE_DATA;
  }


  hasChild = (_: number, node: ExampleFlatNode) => node.expandable;

  /*------------------*/

  /*---------navconfig---------*/
  private toggleMenu(){
    let subMenu = document.getElementById("subMenu");
    subMenu.classList.toggle("open-menu");
  }
  /*------------------*/

  ngOnInit(): void {
    this.authService.callbackUsuario().subscribe(usuario => {
      if(!usuario) {
        return;
      }
      this.usuario = usuario;
    });
  }




  get title(): string {
    return this.navService.navData.title
  }

}

