<!-- 


<div class="div-ext" >
  <div class="container" [formGroup]="loginForm" aria-labelledby="title">
    <div class="input">
      <div class="form-control-group">
        <label class="login" for="input-email">LOGIN</label>
        <input class="input-user" placeholder="Usuário" id="input-email" formControlName="email" >
      </div>
      <div class="form-control-group">
        <label class="senha" for="input-password">SENHA</label>
        <input class="input-password" placeholder="Senha" id="input-password" formControlName="password" type="password">
        <a class="forgot-password" routerLink="../recuperacao-senha">ESQUECEU A SENHA?</a>
      </div>
    </div>
    <div class="button">
      <button fullWidth status="primary" size="large" (click)="login()" [disabled]="!loginForm.valid">
          ENTRAR
      </button>
    </div>
  </div>
</div> -->

<div class="card-body px-4 py-3 px-md-5">
  <form [formGroup]="loginForm" >
    <!-- 2 column grid layout with text inputs for the first and last names -->

    <!-- Email input -->
    <div class="form-outline mb-4 text-left">
        <label class="form-label" for="form3Example3">E-mail</label>
      <input type="email" id="form3Example3" class="form-control" formControlName="email" placeholder="Digite o seu e-mail" />
    </div>

    <!-- Password input -->
    <div class="form-outline mb-2 text-left">
        <label class="form-label" for="form3Example4">Senha</label>
      <input type="password" id="form3Example4" class="form-control form" formControlName="password" placeholder="Digite sua senha"/>
    </div>

    <!-- Checkbox -->
    <div class="form-check d-flex flex-row-reverse mb-4">
      <a class="form-check-label text-decoration-none" for="form2Example33" routerLink="../recuperacao-senha">
        Esqueceu a senha?
      </a>
    </div>

    <!-- Submit button -->
    <button type="submit" class="btn btn-primary btn-block mb-4 btn-entrar pt-2 pb-2" (click)="login()" [disabled]="!loginForm.valid">
      Entrar
    </button>
  </form>
</div>



