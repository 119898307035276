<div class="container-modal">
    <div class="header">
        <h3>Termos e condições</h3>
    </div>
    <div class="content">
        <p>Os dados aqui contidos são confidenciais, sendo o proibido o uso desses dados para finalidades que não sejam o teste de uso da plataforma. Ao fim do período de teste, os dados deverão ser eliminados, nos termos da legislação aplicável.Os dados apresentados nesta plataforma são estritamente confidenciais e destinam-se exclusivamente ao teste de uso da plataforma. Qualquer uso, divulgação ou reprodução desses dados para fins que não estejam diretamente relacionados ao teste é expressamente proibido.</p>
        <p>Após a conclusão do período de teste, todos os dados deverão ser destruídos ou apagados em conformidade com as disposições da legislação aplicável sobre proteção de dados. A não conformidade pode resultar em medidas legais.</p>
    </div>
    <div class="footer d-flex justify-content-end">
        <button mat-flat-button class="gs-botaoAzulPadrao btn" (click)="aceitarTermos()">Aceitar</button>
    </div>
</div>
