import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { info } from 'console';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, catchError, interval, throwError } from 'rxjs';
import { OrdemProduto } from 'src/app/shared/enums/ordem-produto.enum';
import { PesquisaService } from 'src/app/shared/services/http/pesquisa.service';
import { UiService } from 'src/app/shared/services/ui.service';

@Component({
  selector: 'app-visao-em-tela',
  templateUrl: './visao-em-tela.component.html',
  styleUrls: ['./visao-em-tela.component.scss']
})
export class VisaoEmTelaComponent implements OnInit {
  @Input() aba: any
  @Input() dataNasc: any;
  idPesquisa;
  documento;
  isLoadingPdf =  true;
  porcentagemPesquisa;
  statusPesquisa = new BehaviorSubject<number | null>(null);
  isCadastrais = false;
  isProcessos = false;
  isPatrimonial = false;
  isAnaliseCredito = false;
  isDossie = false;
  dadosAba;
  exibeAnaliseCredito = true
  infoCadastraisGeral = []
  infoCadastrais = []

  ordemComponentes = [];
  isLoadingCadastraisCompletos =  true

  constructor(
    private ui: UiService,
    private toastr: ToastrService,
    private pesquisaService: PesquisaService,
    public sanitizer: DomSanitizer,
    public router: Router,
  ) { }

  ngOnInit(): void {
    this.dadosAba = this.aba
    this.documento = this.aba.titulo.replaceAll('.', '').replaceAll('-', '').replaceAll('/', '');
    if (this.aba.id.id) {
      this.idPesquisa = this.aba.id.id
    }
    else {
      this.idPesquisa = this.aba.id
    }

    if(this.router.url == '/cadastrais'){ 
      this.isCadastrais = true;
      this.ordemComponentes = [
        OrdemProduto.sinteseCadastral, 
        OrdemProduto.midiasNegativas,
        OrdemProduto.dadosCadastraisCompleto,
        OrdemProduto.escrituraEProcuracoes,            
        OrdemProduto.detech
      ]
     }
    if(this.router.url == '/processo'){ 
      this.isProcessos = true;
      this.ordemComponentes = [
        OrdemProduto.sinteseCadastral,
        OrdemProduto.sinteseProcesso,
        OrdemProduto.midiasNegativas,
        OrdemProduto.dadosCadastraisCompleto,
        OrdemProduto.escrituraEProcuracoes,            
        OrdemProduto.detech
      ];
     }
    if(this.router.url == '/patrimonial'){
       this.isPatrimonial = true;
       this.ordemComponentes = [
        OrdemProduto.sinteseCadastral,
        OrdemProduto.patrimonialCompleto,
        OrdemProduto.midiasNegativas,
        OrdemProduto.dadosCadastraisCompleto,
        OrdemProduto.escrituraEProcuracoes,            
        OrdemProduto.detech
      ];
      }
    if(this.router.url == '/credito'){ 
      this.isAnaliseCredito = true;
      this.ordemComponentes = [
        OrdemProduto.sinteseCadastral,
        OrdemProduto.sinteseCredito,
        OrdemProduto.creditoCompleto,
        OrdemProduto.midiasNegativas,
        OrdemProduto.dadosCadastraisCompleto,
        OrdemProduto.escrituraEProcuracoes,            
        OrdemProduto.detech
      ];
     }
    if(this.router.url == '/dossie'){
      this.isDossie = true;
      this.ordemComponentes = [
        OrdemProduto.sinteseCadastral,
        OrdemProduto.midiasNegativas,
        OrdemProduto.dadosCadastraisCompleto,
        OrdemProduto.sinteseProcesso,
        OrdemProduto.sinteseCredito,
        OrdemProduto.creditoCompleto,
        OrdemProduto.patrimonialCompleto,   
        OrdemProduto.escrituraEProcuracoes,           
        OrdemProduto.detech

      ];
    }

    this.verificaStatusPesquisa(this.idPesquisa)
  }

  handleArrayChange(newArray: any[]) {
    this.isLoadingCadastraisCompletos = true
    if(this.dadosAba.novaPesquisa){
      this.infoCadastraisGeral = newArray;
      if(this.infoCadastraisGeral){
        this.isLoadingCadastraisCompletos = false
      }
    }
    if(!this.dadosAba.novaPesquisa){
      this.infoCadastrais = newArray;
      if(this.infoCadastrais){
        this.isLoadingCadastraisCompletos = false
      }
    }
  }

  atualizarExibeAnaliseCredito(valor: boolean) {
    this.exibeAnaliseCredito = valor;
  }

  exportarClicked() {
    this.ui.loading();
    this.pesquisaService.exportar({ id: this.idPesquisa }, "processos").subscribe(data => {
      this.ui.loaded().subscribe(() => {
        let a = document.createElement("a");
        a.href = "data:application/pdf;base64," + data.arquivoConteudo;
        a.download = data.arquivoNome;
        a.click();
      });
    }, (err: any) => {
      this.ui.loaded().subscribe(() => {
        // this.openSnackBarWarning('Atenção', err.error);
      })
    });
  }

  async verificaStatusPesquisa(idPesquisa) {
   // Inicializa com um valor diferente de 4
    await new Promise<void>((resolve) => {
      const subscription = interval(10000).subscribe(async () => {
        let response = await this.pesquisaService.getStatusPesquisa(idPesquisa)
          .pipe(
            catchError(error => {
              this.isLoadingPdf = true;
              subscription.unsubscribe();
              return throwError('Erro interno'); // Lança um novo erro para ser capturado pelo catch
            })
          )
          .toPromise();
          this.porcentagemPesquisa = response.procentagem;
          this.statusPesquisa = response.status
  
        if (response.status == 4 || response.status == 7) {
          subscription.unsubscribe(); // para o looping da api 
          this.isLoadingPdf = false; // Define isLoadingPdf como false
          resolve();
        }
      });
    });
  }

  getCorPorcentagem(porcentagem: number): string {
    const corDefault = '#c5c5c5'; // Azul
    const corPercentual = '#00ccffdd'; // Verde
  
    if (porcentagem === undefined || isNaN(porcentagem)) {
      return corDefault;
    }
    
    const cor1Percentual = porcentagem;
    const cor2Percentual = 100 - porcentagem;
    return `linear-gradient(to right, ${corPercentual} ${cor1Percentual}%, ${corDefault} ${cor2Percentual}%)`;
  }
  

}

