<div class="header text-center d-flex justify-content-between">
    <div></div>
    <h2>Pesquisa em Lotes</h2>
    <button mat-icon-button aria-label="close dialog" mat-dialog-close style="margin-top: -4px;">
        <mat-icon>close</mat-icon>
    </button>
</div>

<div class="container mt-2">
    <section style="margin-top: -30px;"> <!-- Ajuste do margin-top -->
        <div class="box-wrap mt-2">
            <div class="table-wrap tableFixHead" style="max-height: 400px; overflow-y: auto;" *ngIf="documentosProcessados.length > 0"> <!-- Ajuste do tamanho da tabela -->
                <table style="width: 100%;"> <!-- Tabela ocupando 100% da largura disponível -->
                    <thead>
                        <tr>
                            <th>Documento</th>
                            <th>Nome</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let o of documentosProcessados">
                            <td class="otherStatus">{{o.numero}}</td>
                            <td class="otherStatus">{{o.nome}}</td>
                            <td class="otherStatus">{{o.mensagem}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <div class="progress-bar-container mt-2" style="font-size: large;" *ngIf="progresso > 0 && progresso < 100">
            <mat-progress-bar mode="determinate" [value]="progresso"></mat-progress-bar>
            <p>{{progresso}}% concluído</p>
        </div>        

        <div class="row" style="margin-top: -24px;">
            <div class="col d-flex justify-content-between totalItens align-items-center">
                <p class="total" *ngIf="!isLoading">total de documentos: 
                    <span>{{ documentosProcessadosComSucesso}}/{{totalDocumentos}}</span>
                </p>
            </div>

            <div class="col d-flex justify-content-end" style="margin-top: -10px;">
                <!-- Botão de Cancelar durante o processamento -->
                <button class="gs-botaoAzulPadrao btn btn-primary btn-cancel" *ngIf="isLoading" mat-dialog-close (click)="fecharModal()">
                    Cancelar
                </button>
                
                <!-- Botões exibidos após o término do processamento -->
                <div *ngIf="!isLoading" class="d-flex justify-content-between mt-3">
                    <button class="gs-botaoAzulPadrao btn btn-primary btn-cancel" mat-dialog-close (click)="fecharModal()" 
                            *ngIf="documentosProcessadosComSucesso > 0">
                        Cancelar
                    </button>                        
                    <button class="gs-botaoAzulPadrao btn btn-primary btn-go d-inline-block ml-2" 
                            *ngIf="documentosProcessadosComSucesso > 0" 
                            (click)="enviarParaProcessamento()">
                        Iniciar Pesquisa
                        <!-- <mat-icon>search</mat-icon> -->
                    </button>
                </div>
            </div>   
        </div>

    </section>
</div>
