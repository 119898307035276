import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appReplaceCommaWithDot]'
})
export class ReplaceCommaWithDotDirective {

  constructor(private el: ElementRef) { }

  @HostListener('input', ['$event'])
  onInput(event: Event) {
    const input = this.el.nativeElement as HTMLInputElement;
    input.value = input.value.replace(/,/g, '.');
  }

}
