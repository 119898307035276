import { Component, Input, OnInit } from '@angular/core';
import { PesquisaService } from 'src/app/shared/services/http/pesquisa.service';
import { UiService } from 'src/app/shared/services/ui.service';

@Component({
  selector: 'midias-negativas',
  templateUrl: './midias-negativas.component.html',
  styleUrls: ['./midias-negativas.component.scss']
})
export class MidiasNegativasComponent implements OnInit {

  dadosMidiasNegativas;
  dadosPessoasVips;
  loadingMidiasNegativas = false;
  loadingPessoasVips = false;
  panelOpenState = false
  exibeMidiasNegativas = false;
  exibeReprocessarMidiasNegativas = false;
  exibeBotaoRefreshMidias = false;
  exibeReprocessarVips = false;
  exibePessoasVips = false;
  exibeBotaoRefreshVips = false;

  erroVips = false;
  erroNegativas = false;


  @Input() dadosPesquisa: any

  constructor(
    private ui: UiService,
    private pesquisaService: PesquisaService
  ) { }

  ngOnInit(): void {
    this.getMidiasNegativas(this.dadosPesquisa)
    this.getPessoasVips(this.dadosPesquisa)
  }

  mensagemSemConteudo(texto: string) {
    texto = texto.toLowerCase()
    if (texto == 'nenhum link encontrado para a pesquisa')
      return true
    else
      return false
  }

  getMidiasNegativas(idPesquisa) {
    this.loadingMidiasNegativas = true;
    this.exibeReprocessarMidiasNegativas = false;
    this.exibeBotaoRefreshMidias = false;
    this.exibeMidiasNegativas = false
    let tentativas = 0;

    const tryGetMidiasNegativas = () => {
      this.pesquisaService.getMidiasNegativas(idPesquisa).subscribe(
        (response) => {
          this.ui.loaded().subscribe(() => {
            if (response.codigo === 1000) {
              this.dadosMidiasNegativas = response;
              this.loadingMidiasNegativas = false;
              this.exibeMidiasNegativas = true;
            }

            if (response.codigo === 2000 && tentativas <= 4) {
              this.dadosMidiasNegativas = response;
              this.loadingMidiasNegativas = false;
              this.exibeMidiasNegativas = true;
              // this.exibeMensagemAtualizacaoProcesso = true;
              setTimeout(() => {
                tentativas++;
                tryGetMidiasNegativas();
              }, 15000);

              if (tentativas === 4) {
                // this.exibeMensagemAtualizacaoProcesso = true;
                this.exibeBotaoRefreshMidias = true
              }
            }

            if (response.codigo === 4000) {
              this.dadosMidiasNegativas = response;
              this.loadingMidiasNegativas = false;
              this.exibeReprocessarMidiasNegativas = true;
              this.erroNegativas = true
            }

            if (response.codigo === 3000 && tentativas <= 10) {
              this.dadosMidiasNegativas = response;
              this.exibeReprocessarMidiasNegativas = true;
              this.exibeMidiasNegativas = false
              setTimeout(() => {
                tentativas++;
                tryGetMidiasNegativas();
              }, 6000);

              // Verificar o número máximo de tentativas para exibir o reprocessamento
              if (tentativas === 10) {
                this.exibeReprocessarMidiasNegativas = true;
                this.exibeBotaoRefreshMidias = true
              }
            }
          });
        },
        (error) => {
          this.exibeMidiasNegativas = false;
          this.exibeReprocessarMidiasNegativas = true
          this.loadingMidiasNegativas = false;
          this.exibeBotaoRefreshMidias = true;
          this.erroNegativas = true
        }
      );
    };

    tryGetMidiasNegativas();
  }

  getPessoasVips(idPesquisa) {
    this.loadingPessoasVips = true;
    this.exibeReprocessarVips = false;
    this.exibeBotaoRefreshVips = false;
    this.exibePessoasVips = false
    let tentativas = 0;

    const tryGetPessoasVIps = () => {
      this.pesquisaService.getPessoasVips(idPesquisa).subscribe(
        (response) => {
          this.ui.loaded().subscribe(() => {
            if (response.codigo === 1000) {
              this.dadosPessoasVips = response;
              this.loadingPessoasVips = false;
              this.exibePessoasVips = true;
            }

            if (response.codigo === 2000 && tentativas <= 4) {
              this.dadosPessoasVips = response;
              this.loadingPessoasVips = false;
              this.exibePessoasVips = true;
              // this.exibeMensagemAtualizacaoProcesso = true;
              setTimeout(() => {
                tentativas++;
                tryGetPessoasVIps();
              }, 15000);

              if (tentativas === 4) {
                // this.exibeMensagemAtualizacaoProcesso = true;
                this.exibeBotaoRefreshVips = true
              }
            }

            if (response.codigo === 4000) {
              this.dadosPessoasVips = response;
              this.loadingPessoasVips = false;
              this.exibeReprocessarVips = true;
              this.erroVips = true
            }

            if (response.codigo === 3000 && tentativas <= 10) {
              this.dadosPessoasVips = response;
              this.exibeReprocessarVips = true;
              setTimeout(() => {
                tentativas++;
                tryGetPessoasVIps();
              }, 6000);

              // Verificar o número máximo de tentativas para exibir o reprocessamento
              if (tentativas === 10) {
                this.exibeBotaoRefreshVips = true;
                this.exibeReprocessarVips = true;
              }
            }
          });
        },
        (error) => {
          this.exibePessoasVips = false;
          this.loadingPessoasVips = false;
          this.exibeBotaoRefreshVips = true;
          this.exibeReprocessarVips = true
          this.erroVips = true;
        }
      );
    };

    tryGetPessoasVIps();
  }

  // getPessoasVips(idPesquisa) {
  //   this.loadingPessoasVips = true;
  //   this.pesquisaService.getPessoasVips(idPesquisa).subscribe(response => {
  //     this.ui.loaded().subscribe(() => {
  //       if (response.codigo === 1000) {
  //         this.dadosPessoasVips = response;
  //         this.loadingPessoasVips = false
  //       }
  //       // console.log(this.dadosMidiasNegativas)
  //     });
  //   });
  // }

}
