import { Component, Input, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { takeWhile, timer } from 'rxjs';
import { PesquisaService } from 'src/app/shared/services/http/pesquisa.service';

@Component({
  selector: 'app-analise-credito-completo',
  templateUrl: './analise-credito-completo.component.html',
  styleUrls: ['./analise-credito-completo.component.scss']
})
export class AnaliseCreditoCompletoComponent implements OnInit {

  dadosCompletos
  @Input() idPesquisa: any
  exibeDadosCompletos = false;
  // dadosCompletos;

  constructor(
    private pesquisaService: PesquisaService,
    public toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.getSinteseCredito(this.idPesquisa)
  }

  // getAnaliseCreditoCompleto(id) {
  //   this.exibeDadosCompletos = false
  //   this.pesquisaService.getAnaliseCreditoCompleto(id).subscribe(response => {
  //     this.dadosCompletos = response.dados
  //     this.exibeDadosCompletos = true
  //   }, (err: any) => {
  //     // this.toastr.error('Ocorreu um erro, tente novamente.')
  //   });
  // }

  getSinteseCredito(idPesquisa){
    let attempts = 0;

    const subscription = timer(0, 10000).pipe(
      takeWhile(() => attempts < 5) // Limita a 5 tentativas
    ).subscribe(() => {
      this.pesquisaService.getAnaliseCreditoCompleto(idPesquisa).subscribe(
        response => {
          if (response.codigo == 1000) {
            this.dadosCompletos = response.dados
            this.exibeDadosCompletos = true
            subscription.unsubscribe(); // Interrompe o intervalo após uma resposta bem-sucedida
          }
        },
        error => {
          attempts++;
          if(error.status == 500){
            this.exibeDadosCompletos = false
            subscription.unsubscribe();
          }

          if (attempts >= 5) {
            this.exibeDadosCompletos = false
            subscription.unsubscribe(); // Interrompe o intervalo após 5 tentativas
            // this.toastr.warning(`Erro ao obter as informações do pesquisado.`);
          }
          subscription.unsubscribe();
        }
      );
    });
  }

}
