import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-modal-adicionar-grafico',
  templateUrl: './modal-adicionar-grafico.component.html',
  styleUrls: ['./modal-adicionar-grafico.component.scss']
})
export class ModalAdicionarGraficoComponent implements OnInit {

  @Output() valorSelecionado = new EventEmitter<string>();

  documento;

  constructor( public dialogRef: MatDialogRef<ModalAdicionarGraficoComponent>) { }

  ngOnInit(): void {
  }

  getNovoRelacionamento(documento, salvar: boolean){
    this.documento = documento;
    if (salvar) {
      this.dialogRef.close();
      this.valorSelecionado.emit(documento); // Emitir o valor para o componente pai apenas se salvar for true
    } else {
      this.dialogRef.close(); // Fechar a modal sem emitir o valor
    }
  }

}
