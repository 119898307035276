<div class="modal-content">
  <div mat-dialog-title class="dialog-title px-4">
    <h4 class="text-uppercase">informações adicionais - Documento {{data.id}} <mat-icon class="icon-title"
        matTooltip="Copiar" (click)="copiar(data.id)">file_copy</mat-icon>
    </h4>
    <button mat-icon-button aria-label="close dialog" mat-dialog-close>
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <section style="padding: 0 20px 0 20px;" *ngFor="let info of informacoes">
    <div class="row mt-2 borders">
      <div class="col-7">
        <h3 class="titulo" *ngIf="docCpf">Nome Completo:</h3>
        <h3 class="titulo" *ngIf="docCnpj">Razão Social:</h3>
        <p class="informacao"> {{info.nome}} </p>
      </div>
      <div class="col-5">
        <h3 class="titulo">Situação Cadastral:</h3>
        <p class="informacao">{{info?.situacaoCadastral}}</p>
      </div>

      <div class="col-5" *ngIf="docCnpj">
        <h3 class="titulo">Capital Social:</h3>
        <p class="informacao">R${{info?.capitalSocial}}</p>
      </div>
    </div>

    <div class="row mt-2 borders">
      <div class="col-12">
        <h3 class="titulo">Endereço:</h3>
        <p class="informacao" *ngIf="info.enderecos.length == 0"> indisponível</p>
        <p class="informacao" *ngIf="info.enderecos.length > 0"> {{info.enderecos[0].tipoLogradouro}} {{info.enderecos[0].logradouro}}
          Nº{{info.enderecos[0].numero}} {{info.enderecos[0]?.complemento}} - {{info.enderecos[0]?.bairro}}</p>
      </div>
    </div>
    <div class="row mt-2 borders">
      <div class="col-4">
        <h3 class="titulo">CEP:</h3>
        <p class="informacao" *ngIf="info.enderecos.length == 0"> indisponível</p>
        <p class="informacao" *ngIf="info.enderecos.length > 0">{{info.enderecos[0].cep}}</p>
      </div>
      <div class="col-6">
        <h3 class="titulo">Cidade:</h3>
        <p class="informacao" *ngIf="info.enderecos.length == 0"> indisponível</p>
        <p class="informacao" *ngIf="info.enderecos.length > 0">{{info.enderecos[0].cidade}}</p>
      </div>
      <div class="col-2">
        <h3 class="titulo">UF:</h3>
        <p class="informacao" *ngIf="info.enderecos.length == 0"> indisponível</p>
        <p class="informacao" *ngIf="info.enderecos.length > 0">{{info.enderecos[0].uf}}</p>
      </div>
    </div>

    <div class="row  mt-2 borders" *ngIf="docCnpj">
      <div class="col-6">
        <h3 class="titulo">Data de abertura</h3>
        <p class="informacao">{{info?.dadosPJ?.dataInicioAtividades | date: 'dd/MM/yyyy'}}</p>
      </div>
      <div class="col-6">
        <h3 class="titulo">Porte</h3>
        <p class="informacao">{{info?.dadosPJ?.porte}}</p>
      </div>
    </div>

    <div class=" row  mt-2 borders" *ngIf="docCnpj">
      <div class="col-12">
        <h3 class="titulo">Código e descrição da atividade econômica principal</h3>
        <p class="informacao" *ngIf="info?.dadosPJ?.cnaeNome"> 
          <span><strong class="text-black">{{info?.dadosPJ?.cnaeFiscal}}</strong></span> - {{info?.dadosPJ?.cnaeNome}}
        </p>
        <p class="informacao text-center" *ngIf="!info?.dadosPJ?.cnaeNome"> 
         Indisponível
        </p>
      </div>
    </div>

    <div class="row mt-2 borders" *ngIf="docCnpj">
      <div class="col-12">
        <h3 class="titulo"> Código e descrição das atividades econômicas Secundárias</h3>
        <div *ngFor="let cnaeSecundario of info?.dadosPJ?.listaCnaesSecundarios">
          <p class="informacao" *ngIf="info?.dadosPJ?.listaCnaesSecundarios.length > 0">
            <span><strong class="text-black">{{cnaeSecundario?.codigo}}</strong></span> - {{cnaeSecundario?.descricao}}
          </p>
        </div>
          <p class="informacao text-center" *ngIf="info?.dadosPJ?.listaCnaeSecundario.length == 0">indisponível</p>
      </div>
    </div>

    <div class="row mt-2">
      <div class="col-4">
        <div class="btn-group mb-3">
          <button type="button" class="btn btn-fone sair dropdown-toggle text-black d-flex align-items-center"
            data-toggle='dropdown' aria-haspopup="true" aria-expanded="false">
            <mat-icon class="phone-icon"> phone</mat-icon> Telefone
          </button>
          <div class="dropdown-menu" *ngIf="info.telefones.length > 0">
            <button class="dropdown-item d-flex justify-content-between" *ngFor="let tel of telefones">
              <span class="d-inline-flex info-dropdown">
                <mat-icon class="phone-icon-dropdown"> phone</mat-icon>
               {{tel}} </span>
              <mat-icon class="icon-info" (click)="copiar(tel)" matTooltip="Copiar">file_copy</mat-icon>
            </button>
          </div>
          <div class="dropdown-menu" *ngIf="info.telefones.length == 0">
            <button class="dropdown-item d-flex justify-content-between">
             indisponível
            </button>
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="btn-group mb-2">
          <button type="button" class="btn btn-fone sair dropdown-toggle text-black d-flex align-items-center"
            data-toggle='dropdown' aria-haspopup="true" aria-expanded="false">
            Email:
          </button>
          <div class="dropdown-menu dropdown-email" *ngIf="info.emails.length > 0">
            <button class="dropdown-item d-flex justify-content-between" *ngFor="let email of emails">
              <span class="info-dropdown">{{email}}</span>
              <mat-icon class="icon-info" (click)="copiar(email)" matTooltip="Copiar">file_copy</mat-icon>
            </button>
          </div>
          <div class="dropdown-menu" *ngIf="info.emails.length == 0">
            <button class="dropdown-item d-flex justify-content-between">
             indisponível
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="justify-content-center d-flex">
        <h3 *ngIf="info.enderecos.length == 0" class="titulo">Mapa Indisponível:</h3>
        <!-- <img *ngIf="info.enderecos.length == 0" src="../../../../../../assets/img/Indisponível.png" alt="mapa indisponível" width="85%" height="90%"> -->
         <iframe *ngIf="info.enderecos.length > 0" id="localizacao" width="90%" height="300" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"
        [src]="url"></iframe>
    </div>
  </section>
  <footer class="d-flex justify-content-center mt-5">
    <div>
      <p class="informacao" *ngIf="ultimaAtualizacao != 'indisponível'">Última atualização: {{ultimaAtualizacao | date: 'dd/MM/yyyy'}}</p>
      <p class="informacao" *ngIf="ultimaAtualizacao == 'indisponível' ">Última atualização: indisponível</p>
    </div>
  </footer>
</div>
