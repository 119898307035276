import { NavService } from 'src/app/components/template/nav/nav.service';
import { HeaderService } from 'src/app/components/template/header/header.service';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { PesquisaService } from 'src/app/shared/services/http/pesquisa.service';
import { StatusPesquisa } from 'src/app/shared/enums/status-pesquisa.enum';
import { StatusRegistro } from 'src/app/shared/enums/status-registro.enum';
import { TipoPesquisa } from 'src/app/shared/enums/tipo-pesquisa.enum';
import { UsuarioService } from 'src/app/shared/services/http/usuario.service';
import { UiService } from 'src/app/shared/services/ui.service';
import { ConfiguracaoService } from 'src/app/shared/services/http/configuracao.service';
import { FonteGrupoService } from 'src/app/shared/services/http/fonte-grupo.service';
import { UserStore } from 'src/app/@core/stores/user.store';
import { User } from 'src/app/@core/interfaces/common/users';
import { ToastrService } from 'ngx-toastr';
import { forkJoin } from 'rxjs';
import { PrecificadorService } from 'src/app/shared/services/precificador/precificador.service';

const today = new Date();
const month = today.getMonth();
const year = today.getFullYear();

@Component({
  selector: 'app-precificador-listagem',
  templateUrl: './precificador-listagem.component.html',
  styleUrls: ['./precificador-listagem.component.scss']
})
export class PrecificadorListagemComponent implements OnInit {
  StatusRegistro = StatusRegistro;
  StatusPesquisa = StatusPesquisa;
  TipoPesquisa = TipoPesquisa;


  formFiltro: any = { page: 1, pageSize: 50, perfil: "", status: "" };
  formFiltroRenderizado: any = this.formFiltro;
  precificadorId = 1;
  resultadoPesquisa: any = [];
  usuario: any;
  configuracao: any = {};
  listaFonteGrupo = [];

  campaignOne = new FormGroup({
    start: new FormControl(new Date(13, month, year)),
    end: new FormControl(new Date(16, month, year)),
  });
  campaignTwo = new FormGroup({
    start: new FormControl(new Date(15, month, year)),
    end: new FormControl(new Date(19, month, year)),
  });

  constructor(
    private precificadorService: PrecificadorService,
    private usuarioService: UsuarioService,
    private ui: UiService,
    private toastr: ToastrService,
    private configuracaoService: ConfiguracaoService,
    private fonteGrupoService: FonteGrupoService,
    private userStore: UserStore,
    private headerService: HeaderService,
    private navService: NavService) {
    navService.navData = {
      title: 'Historico'
    }
    headerService.headerData = {
      title: 'Historico'
    }
  }

  ngOnInit(): void {
    this.pesquisarClicked();
  }

  clickAbrirItemPrecificacao(item) {
    console.log(item)
    this.precificadorService.onChangeNumber(item.id);
    this.precificadorService.abrirPrecificador(true);
  }

  pesquisarClicked(page = 1) {
    this.formFiltro.page = page;
    this.ui.loading();

    if (page == 1)
      this.resultadoPesquisa = [];

    let request = Object.assign({}, this.formFiltro);
    this.precificadorService.listarPrecificaoesRealizadas(request).subscribe(response => {
      this.formFiltroRenderizado = Object.assign({}, this.formFiltro);
      this.ui.loaded().subscribe(() => {
        this.resultadoPesquisa = this.resultadoPesquisa.concat(response);
      });
    });
  }

  clearForm() {
    this.formFiltro.nome = '';
    this.formFiltro.numero = '';
    this.formFiltro.status = '';
  }

  onScroll() {
    let page = this.formFiltro.page + 1;
    console.log('scrolled!!' + page);
    this.pesquisarClicked(page);

  }

  onScrollDown() {
    console.log('scrolled down!!');
  }

  onScrollUp() {
    let page = this.formFiltro.page - 1;
    if (page > 0) {
      this.pesquisarClicked(page);
    }
    console.log('scrolled up!!' + page);
  }

  visualizarClicked(o: { pesquisa: { id: any; }; }) {
    this.toastr.warning("Em desenvolvimento", "Portal Credvalue")
  }
  ajustaNome(nome: string, tamanho: any) {
    let nomeRetorno = nome;
    if (nome.length >= tamanho) {
      nomeRetorno = nome.substring(0, tamanho - 3) + '...';
    }
    return nomeRetorno;
  }

  filtrar() {
    this.ui.loading();
    const request = {
      idCliente: 3
    }
    this.precificadorService.listarPrecificaoesRealizadas(request).subscribe(response => {
      this.formFiltroRenderizado = Object.assign({}, this.formFiltro);
      this.ui.loaded().subscribe(() => {
        this.resultadoPesquisa = this.resultadoPesquisa.concat(response);
      })
    }, (err: any) => {
      this.ui.loaded().subscribe(() => {
        this.toastr.info('Ocorreu um erro carregar a listagem, tente novamente.')
      })
    });
  }

}