import { Component, ElementRef, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Data } from '@angular/router';
import {Clipboard} from '@angular/cdk/clipboard';
import { ModalConfirmacaoComponent } from '../modal-confirmacao/modal-confirmacao.component';
import { MenuTeiaItens } from '../teia-relacionamento.enum';
import { InformacoesAdicionaisComponent } from './acoes-menu/informacoes-adicionais/informacoes-adicionais.component';
import { PesquisaService } from 'src/app/shared/services/http/pesquisa.service';
import { HttpStatusCode } from '@angular/common/http';
import { UiService } from 'src/app/shared/services/ui.service';
import { MensageResponseComponent } from 'src/app/components/view/mensage-response/mensage-response.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ToastrService } from 'ngx-toastr';
import { UtilService } from 'src/app/shared/services/http/util.service';
import { Textos } from 'src/app/shared/textos';
import { ProdutosEnum } from 'src/app/shared/enums/produtos.enum';
import { FonteGrupoService } from 'src/app/shared/services/http/fonte-grupo.service';
import { ModalGrupoPesquisaComponent } from './acoes-menu/modal-grupo-pesquisa/modal-grupo-pesquisa.component';

@Component({
  selector: 'app-menu-teia-relacionamento',
  templateUrl: './menu-teia-relacionamento.component.html',
  styleUrls: ['./menu-teia-relacionamento.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class MenuTeiaRelacionamentoComponent implements OnInit {

  teiaMenuItens: typeof MenuTeiaItens = MenuTeiaItens;
  info: Data
  doc;
  nome;
  paramAtivos;

  constructor(
    public dialog: MatDialog,
    private clipboard: Clipboard,
    private ui: UiService,
    private toastr: ToastrService,
    private utilService: UtilService,
    private _snackBar: MatSnackBar,
    private fonteGrupoService: FonteGrupoService,
    private pesquisaService: PesquisaService,
    public dialogRef: MatDialogRef<MenuTeiaRelacionamentoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Data,
  ) { }

  ngOnInit(): void {
    console.log('teste id no', this.data)
    this.doc = this.data?.id
  }

  expandirVinculos(id){
    const dialog = this.dialog.open(ModalConfirmacaoComponent, {
      width: '320px',
      panelClass: 'bg-color',
      data: {
        id: this.data?.id,
      }
    });
    dialog.afterClosed().subscribe(result => {
      if(result == undefined){
        this.dialogRef.close(this.teiaMenuItens.expandirNos);
      }
    });
  }

  openInformacoesAdicionais(){
    const dialog =  this.dialog.open(InformacoesAdicionaisComponent, {
      width: 'auto',
      panelClass: 'informacoesAdicionais-dialog-container',
      data: {
        id: this.data.id,
        docForm: this.data.docForm
      }
    });
    dialog.afterClosed().subscribe(res => {
      res = 1
      if(res == 1){
        this.dialogRef.close(this.teiaMenuItens.informacoesAdicionais)
      }
    })
  }

  openGrupoPesquisa(grupos, produtoEnum, doc){
    const dialog =  this.dialog.open(ModalGrupoPesquisaComponent, {
      width: '280px',
      data: {
        idProduto: produtoEnum,
        grupos: grupos,
        doc: doc,
        header: ""
      }
    });
  }

  exibirElementos(){
    this.dialogRef.close(this.teiaMenuItens.exibirNoOculto)
  }


  ocultarElementos(){
    this.dialogRef.close(this.teiaMenuItens.ocultarNo)
  }

  expandirFiliais(){
    this.dialogRef.close(this.teiaMenuItens.exibirFiliais)
  }

  ocultarFiliaisNo(){
    this.dialogRef.close(this.teiaMenuItens.ocultarFiliais)
  }

  expandirPessoasRelacionadas(){
    this.dialogRef.close(this.teiaMenuItens.exibirPessoasRelacionadas)
  }

  ocultarPessoasRelacionadasNo(){
    this.dialogRef.close(this.teiaMenuItens.ocultarPessoasRelacionadas)
  }

  copiar(data){
    this.clipboard.copy(data);
  }

  verificaSeTemProdutoVinculado(produtoEnum){
    this.fonteGrupoService.listAtivoProduto(produtoEnum).subscribe(response => {
      this.paramAtivos = response
      console.log(this.paramAtivos.length)
       if (this.paramAtivos.length == 1) {
        this.realizarPesquisa(produtoEnum, this.paramAtivos[0].fonteGrupo.id)
       }
       if(this.paramAtivos.length > 1){
        this.openGrupoPesquisa(response, produtoEnum, this.data.id)
       }
      if(this.paramAtivos.length == 0){
        this.toastr.warning('Usuário sem vinculos de pesquisa, entre em contato com o suporte', 'Detech')
       }
     }, (err:any) => {
      this.ui.loaded().subscribe(() => {
        return
      })
    });
  }

  realizarPesquisa(tipoPesquisa ,idPesquisa: number){
    this.ui.loading()
       // verifica o tipo do documento 
    let tipoDocumento = this.data.id.replaceAll('.','').replaceAll('-','').replaceAll('/','');
    if(tipoDocumento.length == 11){
      tipoDocumento = 1
    }
    if(tipoDocumento.length == 14){
      tipoDocumento = 2
    }
    
    const request = {
      nome: '',
      notificacao: true,
      numero: this.data.id.replaceAll('.','').replaceAll('-','').replaceAll('/',''),
      tipo: tipoDocumento
    }

    this.utilService.resolverNome(request, "").subscribe(resultadoResolverNome => {
      if(!resultadoResolverNome || !resultadoResolverNome.nome) {
          this.ui.loaded().subscribe(() => {
              this.nome = "";
              this.toastr.warning(Textos.NOME_NAO_ENCONTRADO, "Portal Credvalue")
          });
          return;
      }
       const requestPesquisa = {
        idFonteGrupo: idPesquisa,
        nome: resultadoResolverNome.nome,
        notificacao: true,
        numero: this.data.id.replaceAll('.','').replaceAll('-','').replaceAll('/',''),
        tipo: tipoDocumento
      }
       this.pesquisaService.cadastrarCliente(requestPesquisa).subscribe(data => {
        if (HttpStatusCode.Ok) {
          this.ui.loaded().subscribe(() => {
            if(tipoPesquisa == ProdutosEnum.Cadastrais){
              this.openSnackBar('Sucesso', 'Pesquisa Cadastral Lançada com Sucesso');
            }
            if(tipoPesquisa == ProdutosEnum.Processo){
              this.openSnackBar('Sucesso', 'Pesquisa de Processos Lançada com Sucesso');
            }
            if(tipoPesquisa == ProdutosEnum.Patrimonial){
              this.openSnackBar('Sucesso', 'Pesquisa Patrimonial Lançada com Sucesso');
            }
            if(tipoPesquisa == ProdutosEnum.Credito){
              this.openSnackBar('Sucesso', 'Pesquisa de Crédito Lançada com Sucesso');
            }
            if(tipoPesquisa == ProdutosEnum.Dossie){
              this.openSnackBar('Sucesso', 'Pesquisa de Dossiê Completo Lançada com Sucesso');
            }
          })
        }
      });
    }, (err:any) => {
      this.ui.loaded().subscribe(() => {
        this.toastr.info('Ocorreu um erro ao realizar a pesquisa, tente novamente.')
      })
    });
  }

  openSnackBar(titulo: string, mensagem: string) {
    this._snackBar.openFromComponent(MensageResponseComponent, {
       data: {
         titulo: titulo,
         mensagem: mensagem,
       },
       duration: 5000,
       horizontalPosition: 'right',
       verticalPosition: 'top'
    });
   }
}
