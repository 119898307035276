import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import localePt from '@angular/common/locales/pt';
import { registerLocaleData, DecimalPipe, CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';
import { AuthModule } from 'src/app/@auth/auth.module';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { PrecificadorListagemComponent } from './precificador-listagem/precificador-listagem.component';
import { PrecificadorCreditoComponent } from './precificador-credito.component';
import { PrecificadorWizardComponent } from './precificador-wizard/precificador-wizard.component';
import { StepOneComponent } from './precificador-wizard/step-one/step-one.component';
import { StepTwoComponent } from './precificador-wizard/step-two/step-two.component';
import { StepThreeComponent } from './precificador-wizard/step-three/step-three.component';
import { StepFourComponent } from './precificador-wizard/step-four/step-four.component';
import { StepFinalComponent } from './precificador-wizard/step-final/step-final.component';
import { CurrencyMaskModule } from "ng2-currency-mask";
import { NgxMaskModule } from 'ngx-mask';
import { ReplaceCommaWithDotDirective } from 'src/app/shared/directives/replace-comma-with-dot.directive';
import { AnexarAtivosComponent } from './precificador-wizard/step-two/anexar-ativos/anexar-ativos.component';
import { AnexarPassivosComponent } from './precificador-wizard/step-three/anexar-passivos/anexar-passivos.component';

registerLocaleData(localePt, 'pt-BR');

@NgModule({
  declarations: [
    PrecificadorCreditoComponent,
    PrecificadorListagemComponent,
    PrecificadorWizardComponent,
    StepOneComponent,
    StepTwoComponent,
    StepThreeComponent,
    StepFourComponent,
    StepFinalComponent,
    AnexarAtivosComponent,
    AnexarPassivosComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    CurrencyMaskModule,
    ReactiveFormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    AppRoutingModule,
    NgxMaskModule.forRoot() ,
    AuthModule.forRoot(),
  ],
  exports:[
    PrecificadorCreditoComponent,
    PrecificadorListagemComponent 

  ],
  providers: [{ provide: LOCALE_ID, useValue: 'pt-BR' },DecimalPipe],
})
export class PrecificadorModule { }
