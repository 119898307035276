
export class Textos  {

    public static CADASTRADO_SUCESSO : string = "Cadastro realizado com sucesso"; 
    public static ALTERADO_SUCESSO : string = "Cadastro alterado com sucesso"; 
    public static REMOVIDO_SUCESSO : string = "Cadastro removido com sucesso"; 

    public static CONFIRMACAO_EXCLUSAO : string = "Tem certeza de que deseja excluir este registro?"; 

    public static NOME_NAO_ENCONTRADO: string = "O nome correspondente ao CPF/CNPJ digitado não foi encontrado.";

    public static NOME_DUPLICADA: string = "A pesquisa correspondente ao CPF/CNPJ digitado já foi realizada";
    
}