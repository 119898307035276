<div class="container">
    <div class="row text-left mb">
        <loading *ngIf="isLoading"></loading>
      <div class="col-6">
        <div class="form">
            <mat-form-field class="example-form-field mb-5" appearance="outline">
                <mat-label>Periodo</mat-label>
                <mat-date-range-input
                  [formGroup]="campaignOne"
                  [rangePicker]="campaignOnePicker"
                  [comparisonStart]="campaignTwo.value.start"
                  [comparisonEnd]="campaignTwo.value.end">
                  <input matStartDate placeholder="DD/MM/AAAA" formControlName="start" [(ngModel)]="formFiltro.cadastroDe" name="CadastroAte">
                  <input matEndDate placeholder="DD/MM/AAAA" formControlName="end" [(ngModel)]="formFiltro.cadastroAte" name="CadastroAte">
                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix [for]="campaignOnePicker"></mat-datepicker-toggle>
                <mat-date-range-picker #campaignOnePicker></mat-date-range-picker>
            </mat-form-field>
            <button mat-flat-button (click)="getValueToCards()" [disabled]="!formFiltro.cadastroDe || !formFiltro.cadastroAte" class="gs-botaoAzulPadrao m-3">Filtrar <mat-icon>search</mat-icon></button>
        </div>
        <div class="container d-flex justify-content-center">
            <div class="row mt-3">
                <div class="col-6 col-info">
                        <div class="row cards m-4">
                            <div class="infoHighValue col-4">
                                {{totalDossies}}                       
                            </div>
                            <div class="infoHigh col-8">
                                Dossies                        
                            </div>
                        </div>

                        <div class="row cards m-4">
                            <div class="infoHighValue col-6">
                                {{totalPesquisasPessoaFisica}} <span class="infoHigh">CPF</span>                      
                            </div>
                            <div class="infoHighValue1 col-6">
                                {{totalPesquisasPessoaJuridica}} <span class="infoHigh">CNPJ</span>                       
                            </div>
                        </div>

                        <div class="row cards m-4">
                            <div class="infoHighValue col-4">
                                {{totalCertidoes}}                
                            </div>
                            <div class="infoHigh col-8">
                                certidões                      
                            </div>
                        </div>
                </div>
                <div class="col-6 col-info">
                        <div class="row cards m-4">
                            <div class="infoHighValue col-4">
                                {{totalProcessos}}                     
                            </div>
                            <div class="infoHigh col-8">
                                Processos identificados                       
                            </div>
                        </div>

                        <div class="row cards m-4">
                            <div class="infoHighValue col-4">
                                {{totalPesquisasComProcesso}}                     
                            </div>
                            <div class="infoHigh col-8">
                                pesquisados com pelo menos 1 processo                       
                            </div>
                        </div>

                        <div class="row cards m-4">
                            <div class="infoHighValue col-4">
                                {{totalPesquisasSemProcesso}}                    
                            </div>
                            <div class="infoHigh col-8">
                                pesquisados não possuem processos                     
                            </div>
                        </div>
                </div>
            </div>
        </div>
    </div>

    <div class="col-6 lft">
        <div class="d-flex div-graph">
            <h2>Visão Gráfica Por:</h2>
            <mat-form-field appearance="outline">
                <mat-select  name="status" [(ngModel)]="selected">
                    <!--<mat-option value="">Selecione </mat-option>-->
                  <mat-option value="1" (click)="getValue()">Dossiês</mat-option>
                  <mat-option value="2" (click)="getValue()">Certidões</mat-option>
                  <mat-option value="3"(click)="getValue()">Processos</mat-option>
                </mat-select>
              </mat-form-field>
        </div>
        <div id="chart">
            <!--<p *ngIf="selected ===  '' ">selecione o tipo de grafico</p>-->
            <div id="chartRelatorio" class="d-flex justify-content-center"></div>
        </div>
    </div>
    </div>
</div>    