import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class FonteGrupoService {

    private baseUrl = environment.apiUrl + "/fonte-grupo";

    constructor(public http: HttpClient) { }

    listAtivo(): Observable<any> {
        return this.http.get(`${this.baseUrl}/listar/ativos`);
    }

    listAtivoProduto(produtoId: number): Observable<any> {
        return this.http.get(`${this.baseUrl}/${produtoId}/listar/ativos`);
    }
}
