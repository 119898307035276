<mat-toolbar class="mat-elevation-z8 justify-content-between">
  <button mat-icon-button *ngIf="sidenav.mode === 'over'" (click)="sidenav.toggle()">
    <mat-icon *ngIf="!sidenav.opened"> menu </mat-icon>
    <mat-icon *ngIf="sidenav.opened"> close </mat-icon>
  </button>
  <div class="d-flex justify-content-between">
    <div class="logo-box">
      <img src="../assets/img/logo-credvalue-v2.svg" alt="Cred value" class="logo-header img-fluid">
    </div>
  </div>
  <div class="logout float-right">
    <button mat-icon-button (click)="openNotificacoes()">
      <div class="dot-number-notification">
        <div class="content-notification-number">{{totalNotificacoes.length}}</div>
      </div>
      <mat-icon>notifications</mat-icon>
    </button>
    <mat-menu #notifications="matMenu">
      <div class="d-flex justify-content-center flex-column">
        <div class="text-left d-flex px-3 pt-2 justify-content-center">
          <div class="">
            <img src="../../../../assets/icones/ICONE-NOVO-LUPA.svg" alt="" width="28px">
          </div>
          <div class="px-3">
            <p class="text-notification"><span>Pesquisa Patrimonial</span> foi concluida com sucesso!</p>
          </div>
        </div>
        <hr>
        <div class="text-left d-flex px-3 justify-content-center">
          <div class="">
            <img src="../../../../assets/icones/ICONE-NOVO-LUPA.svg" alt="" width="28px">
          </div>
          <div class="px-3">
            <p class="text-notification"><span>Pesquisa Patrimonial</span> foi concluida com sucesso!</p>
          </div>
        </div>
      </div>
      <div class="text-center pt-2 view-all-notifications">
        <p>visualizar historico de notificacoes</p>
      </div>
    </mat-menu>


    <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
      <mat-icon>exit_to_app</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <button mat-menu-item routerLink="/auth/alteracao-senha">
        <mat-icon>lock_open</mat-icon>
        <span>Alterar Senha</span>
      </button>
      <button mat-menu-item routerLink="/auth/logout">
        <mat-icon>exit_to_app</mat-icon>
        <span>Sair</span>
      </button>
      <button mat-menu-item (click)="cadastrarNotificacao()" *ngIf="usuario.login == 'contato@credvalue.com.br'">
        <mat-icon>notifications</mat-icon>
        <span>Cadastrar Notificação</span>
      </button>
    </mat-menu>
  </div>
</mat-toolbar>

<mat-sidenav-container>
  <mat-sidenav #sidenav="matSidenav" class="mat-elevation-z8">
    <div class="image-container" (mouseenter)="showEditButton = true" (mouseleave)="showEditButton = false">
      <img 
      *ngIf="!usuario?.usuarioFoto?.image" 
        class="avatar mat-elevation-z8" 
        src="../assets/img/user_default.svg"
        [class.hovered]="showEditButton"
      />
      
      <img 
        *ngIf="usuario?.usuarioFoto?.image" 
        class="avatar mat-elevation-z8" 
        [src]="'data:image/png;base64,' + usuario.usuarioFoto.image"
        [class.hovered]="showEditButton"
      /> 
    
      <button *ngIf="showEditButton" class="edit-button" (click)="openEditarFoto()">
        Editar Imagem
      </button> 
    </div>
    <h4 class="name">{{usuario.nome}}</h4>
    <div class="mt-5">
      <mat-divider></mat-divider>
      <button mat-button class="menu-button" routerLink="/dashboard">
        <mat-icon>assessment</mat-icon>
        <span>Dashboard</span>
      </button>
      <mat-divider></mat-divider>
      <button mat-button class="menu-button" routerLink="/compliance" *ngIf="habilitarCompliance()">
        <mat-icon>check_box</mat-icon>
        <span>Compliance</span>
      </button>
      <mat-divider></mat-divider>
      <button mat-button class="menu-button" routerLink="/localizador" *ngIf="habilitaLocalizador()">
        <mat-icon>search</mat-icon>
        <span>Localizador</span>
      </button>
      <mat-divider></mat-divider>
      <button mat-button class="menu-button" (click)="goToDetech()" *ngIf="habilitaTeia()">
        <mat-icon>games</mat-icon>
        <span>Detech</span>
      </button>
      <mat-divider *ngIf="habilitaTeia()"></mat-divider>
      <button mat-button class="menu-button" routerLink="/cadastrais" *ngIf="habilitaCadastrais()">
        <mat-icon>assignment_ind</mat-icon>
        <span>Informações Cadastrais</span>
      </button>
      <mat-divider></mat-divider>
      <button mat-button class="menu-button" routerLink="/processo" *ngIf="habilitaProcessos()">
        <mat-icon>find_in_page</mat-icon>
        <span>Pesquisa de Processos</span>
      </button>
      <mat-divider></mat-divider>
      <button mat-button class="menu-button" routerLink="/patrimonial" *ngIf="habilitaPatrimonios()">
        <mat-icon>account_balance</mat-icon>
        <span>Pesquisa Patrimonial</span>
      </button>
      <mat-divider></mat-divider>
      <button mat-button class="menu-button" routerLink="/dossie" *ngIf="habilitaDossies()">
        <mat-icon>inbox</mat-icon>
        <span>Dossiê Completo</span>
      </button>
      <mat-divider></mat-divider>
      <button mat-button class="menu-button" routerLink="/credito" *ngIf="habilitaAnaliseCreditos()">
        <mat-icon>show_chart</mat-icon>
        <span>Análise de Crédito</span>
      </button>
      <mat-divider></mat-divider>
      <button mat-button class="menu-button" routerLink="/cruzamento-dados" *ngIf="habilitarCruzamento()">
        <mat-icon>compare_arrows</mat-icon>
        <span>Cruzamento de Dados</span>
      </button>
      <mat-divider *ngIf="habilitarCruzamento()"></mat-divider>
      <button mat-button class="menu-button" routerLink="/certidoes" *ngIf="habilitaCertidoes()">
        <mat-icon>file_copy</mat-icon>
        <span>Certidões</span>
      </button>
      <mat-divider></mat-divider>
      <button mat-button class="menu-button" routerLink="/precificador-credito" *ngIf="habilitaPrecificadorCredito()">
        <mat-icon>calculate</mat-icon>
        <span>Precificador de Crédito</span>
      </button>
      <mat-divider></mat-divider>
      <button mat-button class="menu-button" routerLink="/historico" *ngIf="habilitaHistoricos()">
        <mat-icon>history</mat-icon>
        <span>Histórico</span>
      </button>
      <mat-divider></mat-divider>
    </div>
  </mat-sidenav>
  <div class="content mat-elevation-z8" [ngClass]="title ? 'padding16': ''">
    <router-outlet></router-outlet>
  </div>
</mat-sidenav-container>
