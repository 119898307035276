import { Component, Inject, OnInit } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
  selector: 'app-mensage-response',
  templateUrl: './mensage-response.component.html',
  styleUrls: ['./mensage-response.component.scss']
})
export class MensageResponseComponent implements OnInit {

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any ) { }

  ngOnInit(): void {
  }

}
