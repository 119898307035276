import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Data, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { PesquisaMassaService } from 'src/app/shared/services/http/pesquisa-massa.service';
import { UiService } from 'src/app/shared/services/ui.service';

@Component({
  selector: 'app-modal-exportar-pesquisa-lote',
  templateUrl: './modal-exportar-pesquisa-lote.component.html',
  styleUrls: ['./modal-exportar-pesquisa-lote.component.scss']
})
export class ModalExportarPesquisaLoteComponent implements OnInit {

  form: any = {};
  produtoReferencia;

  constructor(
    private uiService: UiService,
    private router: Router,
    private toast: ToastrService,
    private pesquisaMassaService: PesquisaMassaService,
    public dialogRef: MatDialogRef<ModalExportarPesquisaLoteComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Data,
  ) { }

  ngOnInit(): void {
    this.form.pdf = true;
    this.form.excel = true;
    this.getProductParamter()
  }

  getProductParamter(){
    let route = this.router.url;
    if(route == '/cadastrais'){
      this.produtoReferencia = "cadastrais";
    }
    if(route == '/credito'){
      this.produtoReferencia = "analise-credito";
    }
    if(route == '/dossie'){
      this.produtoReferencia = "dossie-completo";
    }
    if(route == '/patrimonial'){
      this.produtoReferencia = "patrimonial";
    }

    if(route == '/processo'){
      this.produtoReferencia = "processos";
    }
  }

  verificaTipoExportacao(){
    const pdf = this.form.pdf;
    const excel = this.form.excel;
    if(!pdf && !excel){
      this.toast.info('Para exportar as pesquisa e necessario escolher um pelo menos um tipo de formato.')
    }
    else{
      this.exportarPesquisas(this.data, this.form)
    }
  }

  exportarPesquisas(id, tipoPesquisa){
    this.uiService.loading();
    const request = {
      ids: id.map(x => x.id),
      incluirPdf: tipoPesquisa.pdf,
      incluirExcel: tipoPesquisa.excel
    }

    this.pesquisaMassaService.downloadEmMassa(request, this.produtoReferencia).subscribe(data => {
      this.uiService.loaded().subscribe(()=> {
        var a = document.createElement("a");
        a.href = "data:application/zip;base64," + data.arquivoConteudo; 
        a.download = data.arquivoNome;
        a.click();
      })
      this.dialogRef.close();
      this.toast.success('Pesquisas Exportadas com Sucesso!')
    }, (err:any) => {
      this.uiService.loaded().subscribe(() => {
        if(err.error != null || err.error != "")
          this.toast.info(err.error)
        
        else
          this.toast.info('Ocorreu um erro ao exportar as pesquisas, tente novamente.')
      })
    });
  }

}
