<div class="content-grey p-4 mt-3">
    <div class="d-flex justify-content-end" *ngIf="exibeReprocessar">
        <button mat-flat-button class="gs-botaoAzulPadrao m-3" (click)="getSinteseCredito()">Reprocessar síntese de crédito <mat-icon>cached</mat-icon></button>
      </div>
    <div class="d-flex justify-content-center align-items-center">
        <h3 class="title-sintese">Síntese de Crédito</h3>
    </div>

    <div class="table-responsive">
        <loader-only *ngIf="isLoading" style="margin-top: 40px !important;"></loader-only>
        <table class="table table-bordered" *ngIf="!isLoading">
            <thead>
              <tr>
                <th scope="col" class="title-table">Score</th>
                <th scope="col" class="title-table">Definição</th>
                <th scope="col" class="title-table">Chance</th>
                <th scope="col" class="title-table">Ocorrências</th>
                <th scope="col" class="title-table">Quantidade</th>
                <th scope="col" class="title-table">Valor</th>
                <th scope="col" class="title-table">Data da última</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="td-number">
                    <div class="d-flex justify-content-center align-items-center p-4 flex-column">
                        <p class="number-table-content" *ngIf="isCnpj">{{sinteseCredito.scorePj.score}}</p>
                          <mat-icon  [ngClass]="{
                            'vermelho': sinteseCredito.scorePj.score >= 0 && sinteseCredito.scorePj.score <= 300,
                            'amarelo': sinteseCredito.scorePj.score >= 301 && sinteseCredito.scorePj.score <= 699,
                            'verde': sinteseCredito.scorePj.score >= 700 && sinteseCredito.scorePj.score <= 1000
                          }" *ngIf="isCnpj">close</mat-icon>

                        <p class="number-table-content" *ngIf="isCpf">{{sinteseCredito.score[0].score}}</p>
                          <mat-icon [ngClass]="{
                            'vermelho': sinteseCredito.score[0].score >= 0 && sinteseCredito.score[0].score <= 300,
                            'amarelo': sinteseCredito.score[0].score >= 301 && sinteseCredito.score[0].score <= 699,
                            'verde': sinteseCredito.score[0].score >= 700 && sinteseCredito.score[0].score <= 1000
                          }" *ngIf="isCpf">close</mat-icon>
                    </div>
                </td>

                <td class="td-p">
                    <div class="d-flex justify-content-center align-items-center">
                        <p class="text-table-content text-p" style="width: 137px;" *ngIf="isCpf">{{sinteseCredito.score[0].texto}}</p>
                        <p class="text-table-content text-p" style="width: 137px;" *ngIf="isCnpj">{{sinteseCredito.scorePj.texto}}</p>
                    </div>
                </td>

                <td class="td-number">
                    <div class="d-flex justify-content-center align-items-center p-4 flex-column">
                        <p class="number-table-content" *ngIf="isCnpj">{{sinteseCredito.scorePj.probabilidade}}%</p>
                        <mat-icon  [ngClass]="{
                            'vermelho': sinteseCredito.scorePj.probabilidade <= 100 && sinteseCredito.scorePj.probabilidade >= 70,
                            'amarelo': sinteseCredito.scorePj.probabilidade <= 69 && sinteseCredito.scorePj.probabilidade >= 30,
                            'verde': sinteseCredito.scorePj.probabilidade >= 0 && sinteseCredito.scorePj.probabilidade <= 29
                          }" *ngIf="isCnpj">close</mat-icon>
                        <p class="number-table-content" *ngIf="isCpf">{{ (sinteseCredito.score[0].probabilidade / 100)}}%</p>
                        <mat-icon  [ngClass]="{
                            'vermelho': probabilidadePf <= 100 && probabilidadePf >= 70,
                            'amarelo': probabilidadePf <= 69 && probabilidadePf >= 30,
                            'verde': probabilidadePf >= 0 && probabilidadePf <= 29
                          }" *ngIf="isCpf">close</mat-icon>
                    </div>
                </td>

                <td>
                    <div class="d-flex flex-column justify-content-center text-left p-4">
                        <div *ngIf="isCnpj">
                            <p class="text-p d-flex align-items-center"> <mat-icon [ngClass]="sinteseCredito.pendenciasRestricoes.quantidade == 0 ? 'icon-info-aproved': 'icon-info-alert'">close</mat-icon> &nbsp;  Pendências e Restrições Financeiras </p>
                            <p class="text-p d-flex align-items-center"> <mat-icon [ngClass]="sinteseCredito.chequesSemFundo.quantidade == 0 ? 'icon-info-aproved': 'icon-info-alert'">close</mat-icon> &nbsp; Cheques Sem Fundos </p>
                            <p class="text-p d-flex align-items-center"> <mat-icon [ngClass]="sinteseCredito.chequesSustados?.total == 0 ? 'icon-info-aproved': 'icon-info-alert'">close</mat-icon> &nbsp; Cheques Sustados </p>
                            <p class="text-p d-flex align-items-center"> <mat-icon [ngClass]="sinteseCredito.chequesDevolvidosInformadosUsuario?.quantidadeTotal == 0 ? 'icon-info-aproved': 'icon-info-alert'">close</mat-icon> &nbsp; Cheques Devolvidos Informados Pelo Usuário </p>
                            <p class="text-p d-flex align-items-center"> <mat-icon [ngClass]="sinteseCredito.protestos?.totalProtestos == 0 ? 'icon-info-aproved': 'icon-info-alert'">close</mat-icon> &nbsp; Protestos </p>
                        </div>

                        <div *ngIf="isCpf">
                            <p class="text-p d-flex align-items-center"> <mat-icon [ngClass]="(sinteseCredito.resumoOcorrenciasDeDebitos?.totalDevedor == 0 || sinteseCredito.resumoOcorrenciasDeDebitos?.totalDevedor == '-') ? 'icon-info-aproved': 'icon-info-alert'">close</mat-icon> &nbsp; Registro de Débito </p>
                            <p class="text-p d-flex align-items-center"> <mat-icon [ngClass]="(sinteseCredito.resumoDeAcoesCiveis.quantidade == 0 || sinteseCredito.resumoDeAcoesCiveis.quantidade == '-') ? 'icon-info-aproved': 'icon-info-alert'">close</mat-icon> &nbsp; Ações Cíveis </p>
                            <!-- <p class="text-p d-flex align-items-center"> <mat-icon [ngClass]="(sinteseCredito.resumoDevolucoesInformadaPeloUsuario?.tamanho)Registro ? 'icon-info-aproved': 'icon-info-alert'">close</mat-icon> &nbsp; Cheques Sem Fundos</p> -->
                            <p class="text-p d-flex align-items-center"> <mat-icon [ngClass]="(sinteseCredito.chequesSustadosPeloMotivo21?.registro == 0 || sinteseCredito.chequesSustadosPeloMotivo21?.registro == '-') ? 'icon-info-aproved': 'icon-info-alert'">close</mat-icon> &nbsp; Cheques Sustados </p>
                            <p class="text-p d-flex align-items-center"> <mat-icon [ngClass]="(sinteseCredito.resumoDevolucoesInformadaPeloUsuario?.total == 0 || sinteseCredito.resumoDevolucoesInformadaPeloUsuario?.total == '-') ? 'icon-info-aproved': 'icon-info-alert'">close</mat-icon> &nbsp; Devoluções Informadas pelo Usuário </p>
                            <p class="text-p d-flex align-items-center"> <mat-icon [ngClass]="(sinteseCredito.resumoTitulosProtestados?.total == 0 || sinteseCredito.resumoTitulosProtestados?.total == '-') ? 'icon-info-aproved': 'icon-info-alert'">close</mat-icon> &nbsp; Protestos </p>
                            <p class="text-p d-flex align-items-center"> <mat-icon class="icon-info-aproved">close</mat-icon> &nbsp; Participação em Empresas </p>
                            <p class="text-p d-flex align-items-center"> <mat-icon [ngClass]="(sinteseCredito.relacaoFalenciaRecuperacaoJudicial?.registro == 0 || sinteseCredito.relacaoFalenciaRecuperacaoJudicial?.registro == '-') ? 'icon-info-aproved': 'icon-info-alert'">close</mat-icon> &nbsp; Recuperações e Falências </p>
                        </div>
                    </div>
                </td>

                <td class="text-center">
                    <div class="d-flex flex-column justify-content-center align-items-center p-4">
                        <p class="text-p" *ngIf="isCnpj"> {{sinteseCredito.pendenciasRestricoes.quantidade}}</p>
                        <p class="text-p" *ngIf="isCpf"> {{sinteseCredito.resumoOcorrenciasDeDebitos?.totalDevedor}}</p>

                        <p class="text-p" *ngIf="isCnpj"> {{sinteseCredito.chequesSemFundo.quantidade}} </p>
                        <p class="text-p" *ngIf="isCpf"> {{sinteseCredito.resumoDeAcoesCiveis?.quantidade}} </p>

                        <p class="text-p" *ngIf="isCnpj"> {{sinteseCredito.chequesSustados.total}} </p>
                        <p class="text-p" *ngIf="isCpf"> {{sinteseCredito.chequesSustadosPeloMotivo21?.registro}} </p>

                        <p class="text-p" *ngIf="isCnpj"> {{sinteseCredito.chequesDevolvidosInformadosUsuario.quantidadeTotal}} </p>
                        <p class="text-p" *ngIf="isCpf"> {{sinteseCredito.resumoDevolucoesInformadaPeloUsuario?.total}} </p>

                        <p class="text-p" *ngIf="isCnpj"> {{sinteseCredito.protestos.totalProtestos}} </p>
                        <p class="text-p" *ngIf="isCpf"> {{sinteseCredito.resumoTitulosProtestados?.total}} </p>

                        <p class="text-p" *ngIf="isCpf"> {{sinteseCredito.participacoesDoDocumentoConsultado?.registro}} </p>
                        <p class="text-p" *ngIf="isCpf"> {{sinteseCredito.relacaoFalenciaRecuperacaoJudicial?.registro}} </p>
                    </div>
                </td>

                <td>
                    <div class="d-flex flex-column justify-content-center align-items-center p-4">
                        <div *ngIf="isCnpj">
                            <p class="text-p"> {{sinteseCredito?.pendenciasRestricoes.valor | currency:'BRL'}} </p>
                            <p class="text-p"> {{sinteseCredito?.chequesSemFundo.valor | currency:'BRL'}} </p>
                            <p class="text-p"> - </p>
                            <p class="text-p"> - </p>
                            <p class="text-p"> {{sinteseCredito?.protestos.valorTotal | currency:'BRL'}} </p>
                        </div>

                        <div *ngIf="isCpf">
                            <p class="text-p"> R$ {{sinteseCredito.resumoOcorrenciasDeDebitos?.valorAcomulado }} </p>
                            <p class="text-p"> - </p>
                            <p class="text-p"> - </p>
                            <p class="text-p"> - </p>
                            <p class="text-p"> R$ {{sinteseCredito.resumoTitulosProtestados?.valorAcumulado}} </p>
                            <p class="text-p"> - </p>
                            <p class="text-p"> - </p>
                        </div>
                    </div>
                </td>
                <td>
                    <div class="d-flex flex-column justify-content-center align-items-center p-4">
                        <div *ngIf="isCnpj">
                            <p class="text-p"> {{sinteseCredito?.pendenciasRestricoes.dataMaior ? sinteseCredito?.pendenciasRestricoes.dataMaior : '-'}} </p>
                            <p class="text-p"> {{sinteseCredito?.chequesSemFundo.Ultima ? sinteseCredito?.chequesSemFundo.Ultima : '-'}} </p>
                            <p class="text-p"> {{sinteseCredito?.chequesSustados.UltimasOcorrencias ? sinteseCredito?.chequesSustados.UltimasOcorrencias : '-'}} </p>
                            <p class="text-p"> {{sinteseCredito?.chequesDevolvidosInformadosUsuario.UltimasOcorrencias ? sinteseCredito?.chequesDevolvidosInformadosUsuario.UltimasOcorrencias : '-'}} </p>
                            <p class="text-p"> {{sinteseCredito?.protestos.ultima ? sinteseCredito?.protestos.ultima : '-'}} </p>
                        </div>

                        <div *ngIf="isCpf">
                            <p class="text-p"> {{sinteseCredito.resumoOcorrenciasDeDebitos?.dataPrimeiroDebito}}  </p>
                            <p class="text-p"> {{sinteseCredito.resumoDeAcoesCiveis?.periodoInicial}} </p>
                            <p class="text-p"> - </p>
                            <p class="text-p"> {{sinteseCredito.resumoDevolucoesInformadaPeloUsuario?.dataUltimaDevolucao}} </p>
                            <p class="text-p"> {{sinteseCredito.resumoTitulosProtestados?.periodoFinal}} </p>
                            <p class="text-p"> - </p>
                            <p class="text-p"> - </p>
                        </div>
                    </div>
                </td>
              </tr>

            </tbody>
          </table>
    </div>
</div>
