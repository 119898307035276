
export enum ProdutosEnum {
    Cadastrais = 1,
    Processo = 2,
    Patrimonial = 3,
    Credito = 4,
    Dossie = 5,
    Certidoes = 6,
    dueInteligence = 7,
    compliance = 11
}