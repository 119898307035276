import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, catchError, throwError } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { ToastrService } from 'ngx-toastr';
import { UiService } from '../ui.service';

@Injectable({
    providedIn: 'root'
})
export class TeiaRelacionamentoService {

    private baseUrl = `${environment.apiUrl}/mapa-relacionamento`;

    constructor(public http: HttpClient, private toastr: ToastrService,) { }

    buscarDocumento(cpfCnpj: any): Observable<any> {
        return this.http.get(`${this.baseUrl}` + `?cpfCnpj=${cpfCnpj}`)
    }

    buscarDocumentoMenorIdade(cpfCnpj: any, dataNasc: any): Observable<any> {
        return this.http.get(`${this.baseUrl}` + `?cpfCnpj=${cpfCnpj}&dataNascimento=${dataNasc}`)
    }

    buscarDocumento2(cpfCnpj: any, doc: any, nodes: any): Observable<any> {
        return this.http.post(`${this.baseUrl}/relacionamento`, { cpfCnpjOrigemMapa: cpfCnpj, cpfCnpj: doc, nos: nodes })
    }

    buscarDocumento2MenorIdade(cpfCnpj: any, doc: any, nodes: any, dataNasc: any): Observable<any> {
        return this.http.post(`${this.baseUrl}/relacionamento`, { cpfCnpjOrigemMapa: cpfCnpj, cpfCnpj: doc, nos: nodes, dataNascimento: dataNasc })
    }

    exibirOcultarNo(cpfCnpj: any, doc: any, exibirNo): Observable<any> {
        return this.http.get(`${this.baseUrl}/visibilidade-no` + `?cpfCnpjOrigemMapa=${cpfCnpj}&cpfCnpj=${doc}&exibirNo=${exibirNo}`).pipe(
            catchError((err) => {
                this.toastr.info('Ocorreu um erro ao tentar realizar sua requisicao', "DETECH",
                    {
                        positionClass: 'toast-bottom-right'
                    });
                return throwError(err)
            })
        );
    }

    listarPesquisasTeia(filter: any): Observable<any> {
        return this.http.get(`${environment.apiUrl}/minhasPesquisas/`, filter).pipe(
            catchError((err) => {
                this.toastr.info('Ocorreu um erro ao tentar realizar sua requisicao', "DETECH",
                    {
                        positionClass: 'toast-bottom-right'
                    });
                return throwError(err)
            })
        );
    }

    salvarNos(cpfCnpj: any, nodes: any): Observable<any> {
        return this.http.post(`${this.baseUrl}/salvar-nos`, { cpfCnpjOrigemMapa: cpfCnpj, nos: nodes }).pipe(
            catchError((err) => {
                this.toastr.info('Ocorreu um erro ao tentar realizar sua requisicao', "DETECH",
                    {
                        positionClass: 'toast-bottom-right'
                    });
                return throwError(err)
            })
        );
    }

    exibirOcultarFiliais(cpfCnpj: any, doc: any, nodes: any, exibirFiliais): Observable<any> {
        return this.http.post(`${this.baseUrl}/visibilidade-filiais`, { cpfCnpjOrigemMapa: cpfCnpj, cpfCnpj: doc, nos: nodes, exibir: exibirFiliais }).pipe(
            catchError((err) => {
                this.toastr.info('Ocorreu um erro ao tentar realizar sua requisicao', "DETECH",
                    {
                        positionClass: 'toast-bottom-right'
                    });
                return throwError(err)
            })
        );
    }

    exibirOcultarPessoasRelacionadas
        (cpfCnpj: any, doc: any, nodes: any, exibir): Observable<any> {
        return this.http.post(`${this.baseUrl}/visibilidade-pessoas-relacionadas`, { cpfCnpjOrigemMapa: cpfCnpj, cpfCnpj: doc, nos: nodes, exibir: exibir }).pipe(
            catchError((err) => {
                this.toastr.info('Ocorreu um erro ao tentar realizar sua requisicao', "DETECH",
                    {
                        positionClass: 'toast-bottom-right'
                    });
                return throwError(err)
            })
        );
    }

    detalharNo(cpfCnpj: number, doc: number): Observable<any> {
        return this.http.get(`${this.baseUrl}/detalhar-no` + `?cpfCnpjOrigemMapa=${cpfCnpj}&cpfCnpj=${doc}`).pipe(
            catchError((err) => {
                if (err.error.codigo != 20) {
                    this.toastr.info('Ocorreu um erro ao tentar realizar sua requisicao', "DETECH",
                        {
                            positionClass: 'toast-bottom-right'
                        });
                }
                return throwError(err)
            })
        );
    }

    detalharNoMenorIdade(cpfCnpj: number, doc: number, dataNasc: any): Observable<any> {
        return this.http.get(`${this.baseUrl}/detalhar-no` + `?cpfCnpjOrigemMapa=${cpfCnpj}&cpfCnpj=${doc}&dataNascimento=${dataNasc}`).pipe(
            catchError((err) => {
                this.toastr.info('Ocorreu um erro ao tentar realizar sua requisicao', "DETECH",
                    {
                        positionClass: 'toast-bottom-right'
                    });
                return throwError(err)
            })
        );
    }

    exibirOcultarMultiplosNos(obj: any): Observable<any> {
        return this.http.post(`${this.baseUrl}/visibilidade-selecao-nos`, obj).pipe(
            catchError((err) => {
                this.toastr.info('Ocorreu um erro ao tentar realizar sua requisicao', "DETECH",
                    {
                        positionClass: 'toast-bottom-right'
                    });
                return throwError(err)
            })
        );
    }

    adicionarGrafico(cpfCnpj: any, doc: any, nodes: any): Observable<any> {
        return this.http.post(`${this.baseUrl}/relacionamento-sem-vinculo`, { cpfCnpjOrigemMapa: cpfCnpj, cpfCnpj: doc, nos: nodes }).pipe(
            catchError((err) => {
                if (err.error.codigo != 20) {
                    this.toastr.info('Ocorreu um erro ao tentar realizar sua requisicao', "DETECH",
                        {
                            positionClass: 'toast-bottom-right'
                        });
                }
                return throwError(err)
            })
        );
    }

    adicionarGraficoMenorIdade(cpfCnpj: any, doc: any, nodes: any, dataNasc: any): Observable<any> {
        return this.http.post(`${this.baseUrl}/relacionamento-sem-vinculo`, { cpfCnpjOrigemMapa: cpfCnpj, cpfCnpj: doc, nos: nodes, dataNascimento: dataNasc }).pipe(
            catchError((err) => {
                this.toastr.info('Ocorreu um erro ao tentar realizar sua requisicao', "DETECH",
                    {
                        positionClass: 'toast-bottom-right'
                    });
                return throwError(err)
            })
        );
    }

    visualizarRelatorio(id): Observable<any> {
        return this.http.get(`${this.baseUrl}/relatorio-html?cpfCnpj=${id}`, { responseType: 'text' });
    }


    buscarDocumentoPesquisa(cpfCnpj: any, dataNasc: any): Observable<any> {
        return this.http.get(`${this.baseUrl}` + `?cpfCnpj=${cpfCnpj}&dataNascimento=${dataNasc}&OperarSemCusto=true`).pipe(
            catchError((err) => {
                this.toastr.info('Ocorreu um erro ao tentar realizar sua requisicao, verifique os parametros digitados', "DETECH",
                    {
                        positionClass: 'toast-bottom-right'
                    });
                return throwError(err)
            })
        );
    }
}
