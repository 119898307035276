import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { Data } from '@angular/router';
import {Clipboard} from '@angular/cdk/clipboard';
import { MensageErrorResponseComponent } from 'src/app/components/view/mensage-error-response/mensage-error-response.component';
import { TeiaRelacionamentoService } from 'src/app/shared/services/teia-relacionamento/teia-relacionamento.service';
import { UiService } from 'src/app/shared/services/ui.service';
import { EventService } from 'src/app/shared/services/event.service';
import { ModalMenorIdadeComponent } from 'src/app/components/template/produto-pesquisa/modal-menor-idade/modal-menor-idade.component';

@Component({
  selector: 'app-informacoes-adicionais',
  templateUrl: './informacoes-adicionais.component.html',
  styleUrls: ['./informacoes-adicionais.component.scss']
})
export class InformacoesAdicionaisComponent implements OnInit {

  docCpf = false
  docCnpj = false
  informacoes: any = [];
  emails;
  telefones;

  enderecoUrl;
  url
  enderecoUrlCompleto;
  ultimaAtualizacao;

  objetoEndereco = {
    tipoLogradradouro: '',
    endereco: '',
    numero: '',
    bairro: '',
    cidade: '',
    uf: ''
  };

  constructor(
    public sanitizer: DomSanitizer,
    private ui: UiService,
    private _snackBar: MatSnackBar,
    private clipboard: Clipboard,
    public dialog: MatDialog,
    private teiaRelacionamentoService: TeiaRelacionamentoService,
    public dialogRef: MatDialogRef<InformacoesAdicionaisComponent>,
    private eventService: EventService,
    @Inject(MAT_DIALOG_DATA) public data: Data,
  ) { }

  ngOnInit(): void {
    console.log(this.data);
    this.isCpf()
    this.detalharNo()
  }

  detalharNo() {
    var id = this.data.id.replaceAll('.', '').replaceAll('-', '').replaceAll('/', '');;
    var doc = this.data.docForm
    const objInformacoesAdicionais = {
      doc: doc,
      id: id,
      tipoPesquisa: 4
    }
    this.ui.loading();
    this.teiaRelacionamentoService.detalharNo(doc, id).subscribe(response => {
      this.ui.loaded().subscribe(() => {
        if(response.descricao){
          this.dialogRef.close()
          this.openSnackBarErro('Status', response.descricao);
          return

        }
        else{
          this.eventService.broadcastMessage(response);
          this.informacoes.push(response)
          this.emails = this.informacoes[0].emails;
          this.telefones = this.informacoes[0].telefones;
          this.ultimaAtualizacao = this.informacoes[0].dataUltimaAtualizacao
          if(this.ultimaAtualizacao == null || this.ultimaAtualizacao == undefined){
            this.ultimaAtualizacao = 'indisponível'
          }
          console.log(this.ultimaAtualizacao)
          this.objetoEndereco.tipoLogradradouro = response.enderecos[0].tipoLogradouro;
          this.objetoEndereco.endereco = response.enderecos[0].logradouro;
          this.objetoEndereco.numero = response.enderecos[0].numero;
          this.objetoEndereco.bairro = response.enderecos[0].bairro;
          this.objetoEndereco.cidade = response.enderecos[0].cidade;
          this.objetoEndereco.uf = response.enderecos[0].uf;

          var enderecoCompleto = `${this.objetoEndereco.endereco + '+' + this.objetoEndereco.numero + '+' + this.objetoEndereco.bairro + '+' + this.objetoEndereco.cidade + '+' + this.objetoEndereco.uf}`;
          this.montaurlEndereco(enderecoCompleto)
        }
      });
    }, (err:any) => {
      this.ui.loaded().subscribe(() => {
        if(err.error.codigo == 20 && err.error.descricao == 'este cpf pertence a menor de idade, é necessária a data de aniversário para processar a solicitação'){
          this.abrirModalMenorIdade(objInformacoesAdicionais)
        }
        return
      })
    });
  }

  abrirModalMenorIdade(doc){
    const modalMenorIdade = this.dialog.open(ModalMenorIdadeComponent, {
      maxWidth: "470px",
      data: doc
    })
    modalMenorIdade.afterClosed().subscribe(result => {
      if(result && result.isAdicionais){
        this.eventService.broadcastMessage(result.data);
        this.informacoes.push(result.data)
        this.emails = this.informacoes[0].emails;
        this.telefones = this.informacoes[0].telefones;
        this.ultimaAtualizacao = this.informacoes[0].dataUltimaAtualizacao
        if(this.ultimaAtualizacao == null || this.ultimaAtualizacao == undefined){
          this.ultimaAtualizacao = 'indisponível'
        }
        console.log(this.ultimaAtualizacao)
        this.objetoEndereco.tipoLogradradouro = result.data.enderecos[0].tipoLogradouro;
        this.objetoEndereco.endereco = result.data.enderecos[0].logradouro;
        this.objetoEndereco.numero = result.data.enderecos[0].numero;
        this.objetoEndereco.bairro = result.data.enderecos[0].bairro;
        this.objetoEndereco.cidade = result.data.enderecos[0].cidade;
        this.objetoEndereco.uf = result.data.enderecos[0].uf;

        var enderecoCompleto = `${this.objetoEndereco.endereco + '+' + this.objetoEndereco.numero + '+' + this.objetoEndereco.bairro + '+' + this.objetoEndereco.cidade + '+' + this.objetoEndereco.uf}`;
        this.montaurlEndereco(enderecoCompleto)
      }
      else {
        this.openSnackBarErro('Detech', 'Ocorreu um erro, tente novamente')
      }
    });
  }

  openSnackBarErro(titulo: string, mensagem: string) {
    this._snackBar.openFromComponent(MensageErrorResponseComponent, {
       data: {
         titulo: titulo,
         mensagem: mensagem,
       },
       panelClass: 'error',
       duration: 5000,
       horizontalPosition: 'right',
       verticalPosition: 'bottom'
    });
   }


  isCpf() {
    if (this.data.id.length > 14) {
      this.docCnpj = true
    }
    else {
      this.docCpf = true
    }
  }

  montaurlEndereco(enderecourl) {
    this.enderecoUrl = enderecourl
    this.enderecoUrl = this.enderecoUrl.replaceAll(' ', '+')
    this.enderecoUrlCompleto = `https://www.google.com/maps/embed/v1/place?q=${this.enderecoUrl}&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8`;
    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.enderecoUrlCompleto)
  }

  copiar(data) {
    this.clipboard.copy(data);
  }

  spliceIntoChunks(arr, chunkSize) {
    const res = [];
    while (arr.length > 0) {
      const chunk = arr.splice(0, chunkSize);
      res.push(chunk);
    }
    return res;
  }



}
