<div class="header">
    <h3>Cadastrar notificação</h3>
</div>

<div class="container mt-5">
    <form>
        <div class="row">
            <div class="col-12">
                <mat-form-field appearance="outline" class="d-block">
                    <mat-label>Titulo da notificação</mat-label>
                    <input matInput [(ngModel)]="formNotificacao.titulo" name="titulo">
                </mat-form-field>
            </div>
            <div class="col-12">
                <mat-form-field appearance="outline" class="d-block">
                    <mat-label>Detalhes da notificação</mat-label>
                    <textarea matInput [(ngModel)]="formNotificacao.mensagem" name="mensagem"></textarea>
                </mat-form-field>
            </div>
            <div class="d-flex justify-content-between mt-3">
                <button class="btn btn-primary btn-cancel" mat-dialog-close>Cancelar</button>
                <button class="btn btn-primary btn-go"  mat-dialog-close (click)="enviarNotificacao()">Enviar notificação</button>
            </div>
        </div>
    </form>
</div>
