import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import { DashboardService } from 'src/app/shared/services/dashboard/dashboard-service';
import { AuthService } from 'src/app/shared/services/http/auth.service';
import { UiService } from 'src/app/shared/services/ui.service';

const today = new Date();
const month = today.getMonth();
const year = today.getFullYear();

@Component({
  selector: 'app-visao-processual',
  templateUrl: './visao-processual.component.html',
  styleUrls: ['./visao-processual.component.scss']
})

export class VisaoProcessualComponent implements OnInit {
  serializedDate = new FormControl((new Date()).toISOString());
  campaignOne = new FormGroup({
    start: new FormControl(new Date(year, month, 13)),
    end: new FormControl(new Date(year, month, 16)),
  });
  campaignTwo = new FormGroup({
    start: new FormControl(new Date(year, month, 15)),
    end: new FormControl(new Date(year, month, 19)),
  });
  formFiltro: any = { page: 1};
  constructor(
    public dashboardService: DashboardService,
    private ui: UiService,
    private authService: AuthService,
  ) { }
  
  isLoading = false;
  dados;
  dataFim
  tribunal;
  classeProcessual;
  polo;
  totalPolo; 
  totalClasseProcessual;
  totalTribunal
  usuario;


  ngOnInit(): void {
    this.formFiltro.cadastroDe = this.subtractMonths(new Date(), 1);
    this.formFiltro.cadastroAte = new Date((new Date()))
    this.getClienteId();
    this.getFontes()
  }

  subtractMonths(date, months) {
    date.setMonth(date.getMonth() - months);
  
    return date;
  }

  getClienteId(){
    this.authService.callbackUsuario().subscribe(usuario => {
      if(!usuario) {
        return;
      }
      this.usuario = usuario.id;
      return this.usuario
    });
  }

  getFontes(){
    const obj = {
      IdCliente: 52,
      dataDe: this.formFiltro.cadastroDe,
      dataAte: this.formFiltro.cadastroAte
    }
    this.isLoading = true
    this.dados = []
    this.dashboardService.getVisaoProcessual(obj).subscribe(response => {
        this.dados = response;
        this.polo = response.totalPorPolo
        this.tribunal = response.totalPorTribunal
        this.classeProcessual = response.totalPorClasseProcessual
        this.getTotalTribunal();
        this.getTotalClasseProcessal();
        this.getTotalPolo();
        this.isLoading = false
      })
  }

  getTotalTribunal(){
    let value = this.tribunal.map( x => x.total)
    var sum = 0;
    for(var i = 0; i <value.length; i ++){
      sum += value[i]
    }
    this.totalTribunal = sum
  }

  getTotalClasseProcessal(){
    let value = this.classeProcessual.map( x => x.total)
    var sum = 0;
    for(var i = 0; i <value.length; i ++){
      sum += value[i]
    }
    this.totalClasseProcessual = sum
  }

  getTotalPolo(){
    let value = this.polo.map( x => x.total)
    var sum = 0;
    for(var i = 0; i <value.length; i ++){
      sum += value[i]
    }
    this.totalPolo = sum
  }

}
