import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { catchError, interval, takeWhile, throwError, timer } from 'rxjs';
import { PesquisaService } from 'src/app/shared/services/http/pesquisa.service';
import { UiService } from 'src/app/shared/services/ui.service';
import { LinhaTempoComponent } from 'src/app/views/processo/visao-processo/linha-tempo/linha-tempo.component';

@Component({
  selector: 'aba-ss',
  templateUrl: './dados-cadastrais.component.html',
  styleUrls: ['./dados-cadastrais.component.scss']
})
export class DadosCadastraisComponent implements OnInit {

  @Input() aba: any
  idPesquisa;
  isLoadingCadastrais = false;

  panelOpenState = false;

  isCpf = false;
  isCnpj = false;
  @Input() objetoRelatorioCadastral: any;
  @Input() objetoNovaPesquisa: any

  isNovaPesquisa = false;
  pesquisaExistente = false;


  isLoadingTelefones = false;
  isLoadingEnderecos = false;
  isLoadingEmails = false;

  isLoadingSintese = false
  sintese = [];
  infoCadastrais = [];
  documento;
  enderecoUrl;
  enderecoUrlCompleto;
  objetoEndereco = {
    endereco: '',
    numero: '',
    bairro: '',
    cidade: '',
    uf: ''
  };
  url;
  enderecoCompleto;

  isProcessoOuDossie = false;
  isAnaliseCredito = false;
  isDossie = false;

  infoCadastraisTelefone: any = []
  infoCadastraisEmails: any = [];
  infoCadastraisEnderecos: any = []

  exibirDadosCadastrais = true;
  exibirSinteseProcesso = true;
  exibirSinteseCredito = true;
  exibeAnaliseCredito = true;
  exibeReprocessarSintese=  false;

  isLoadingPdf =  true;
  porcentagemPesquisa;
  statusPesquisa;



  constructor(
    public dialog: MatDialog,
    private ui: UiService,
    private toastr: ToastrService,
    private pesquisaService: PesquisaService,
    public sanitizer: DomSanitizer,
    public router: Router,
  ) { }

  ngOnInit(): void {
    this.documento = this.aba.titulo.replaceAll('.', '').replaceAll('-', '').replaceAll('/', '');
    if (this.aba.id.id) {
      this.idPesquisa = this.aba.id.id
    }
    else {
      this.idPesquisa = this.aba.id
    }
    if(this.aba.novaPesquisa && this.router.url != '/credito'){
      // this.consultaDadosCadastraisEmail(this.documento);
      // this.consultaDadosCadastraisEndereco(this.documento);
      // this.consultaDadosCadastraisTelefone(this.documento);
    }
    if(!this.aba.novaPesquisa && this.router.url != '/credito'){
      this.getInfoCadastrais();
    }

    if(this.router.url == '/processo' || this.router.url == '/dossie'){
      this.getSinteseProcessos();
      this.isProcessoOuDossie = true;
    }

    if(this.router.url == '/dossie'){
      this.isDossie = true;
    }

  // ===== credito ========
    if(this.aba.novaPesquisa && this.router.url == '/credito'){
      this.isAnaliseCredito = true
      this.getinfoCadastraisCredito(this.idPesquisa);
    }

    if(!this.aba.novaPesquisa && this.router.url == '/credito'){
      this.isAnaliseCredito = true
      this.getinfoCadastraisCredito(this.idPesquisa);
    }

    this.verificaStatusPesquisa(this.idPesquisa)

    console.log(this.objetoNovaPesquisa)
    if (this.objetoNovaPesquisa && this.objetoNovaPesquisa.tipoDocumento) {
      this.isNovaPesquisa = true;
    
      if (this.objetoNovaPesquisa.tipoDocumento === 2) {
        this.isCnpj = true;
      }
      if (this.objetoNovaPesquisa.tipoDocumento === 1) {
        this.isCpf = true;
      }
    }
    
    if (this.objetoRelatorioCadastral && (this.objetoRelatorioCadastral.cnpj || this.objetoRelatorioCadastral.cpf)) {
      this.pesquisaExistente = true;
      if (this.objetoRelatorioCadastral.cnpj) {
        this.isCnpj = true;
      }
      if (this.objetoRelatorioCadastral.cpf) {
        this.isCpf = true;
      }
    }
  }

  getInfoCadastrais() {
    this.isLoadingCadastrais = true;
    let attempts = 0;

    const subscription = timer(0, 10000).pipe(
      takeWhile(() => attempts < 5) // Limita a 5 tentativas
    ).subscribe(() => {
      this.pesquisaService.getDadosCadastraisPesquisa(this.idPesquisa).subscribe(
        response => {
          if (response) {
            console.log(response)
            this.infoCadastrais = response;
            if(response.cpf){
              this.objetoEndereco.endereco = response.enderecos[0]?.logradouro;
              this.objetoEndereco.numero = response.enderecos[0]?.numero;
              this.objetoEndereco.bairro = response.enderecos[0]?.bairro;
              this.objetoEndereco.uf = response.enderecos[0]?.uf;
            }
            if(response.cnpj){
              this.objetoEndereco.endereco = response?.logradouro;
              this.objetoEndereco.numero = response?.numero;
              this.objetoEndereco.bairro = response?.bairro;
              this.objetoEndereco.uf = response?.uf;
            }
            var enderecoCompleto = `${this.objetoEndereco.endereco + '+' + this.objetoEndereco.numero + '+' + this.objetoEndereco.bairro + '+' + this.objetoEndereco.cidade + '+' + this.objetoEndereco.uf}`;
            this.montaurlEndereco(enderecoCompleto)
            
            this.isLoadingCadastrais = false;
            subscription.unsubscribe(); // Interrompe o intervalo após uma resposta bem-sucedida
          }
        },
        error => {
          attempts++;

          if(error.status == 501){
            this.isLoadingCadastrais = false;
            subscription.unsubscribe(); // Interrompe o intervalo após 5 tentativas
            this.exibirDadosCadastrais = false;
          }

          if (attempts >= 5) {
            this.isLoadingCadastrais = true;
            subscription.unsubscribe(); // Interrompe o intervalo após 5 tentativas
            this.toastr.warning(`Erro ao obter as informações cadastrais do pesquisado.`);
          }
        }
      );
    });
  }

  // getSinteseProcessos() {
  //   this.isLoadingSintese = true;
  //   let attempts = 0;
  
  //   const subscription = this.pesquisaService.getSinteseProcesso(this.idPesquisa).subscribe(
  //     response => {
  //       this.sintese = response;
  //       this.isLoadingSintese = false;
  //       subscription.unsubscribe(); // Interrompe a subscrição após uma resposta bem-sucedida
  //     },
  //     error => {
  //       if (error.status === 400 && error.error === "Pesquisa em andamento...") {
  //         attempts++;
  //         if (attempts < 5) {
  //           // Se o erro for 501 e o número de tentativas for menor que 5, tenta novamente
  //           // this.toastr.warning(`Tentativa ${attempts} de obter a síntese do processo`);
  //           this.getSinteseProcessos(); // Chama a função novamente
  //         } else {
  //           // Se o número de tentativas exceder 5, exibe uma mensagem de erro
  //           this.toastr.warning(`Erro ao obter a síntese do processo`);
  //           this.isLoadingSintese = false;
  //           this.exibeReprocessarSintese = true;
  //         }
  //       } else {
  //         // Se o erro não for 400, exibe uma mensagem de erro genérica
  //         this.toastr.warning(`Erro ao obter a síntese do processo`);
  //         this.isLoadingSintese = false;
  //         this.exibeReprocessarSintese = true;
  //       }
  //     }
  //   );
  // }

  getSinteseProcessos(attempts: number = 0) {
    if (attempts >= 5) {
      this.toastr.warning(`Erro ao obter a síntese do processo após 5 tentativas`);
      this.isLoadingSintese = false;
      this.exibeReprocessarSintese = true;
      return;
    }
  
    this.isLoadingSintese = true;
    this.exibeReprocessarSintese =  false;
  
    this.pesquisaService.getSinteseProcesso(this.idPesquisa).subscribe(
      response => {
        this.sintese = response;
        this.isLoadingSintese = false;
      },
      error => {
        if (error.status === 400 && error.error === "Pesquisa em andamento...") {
          // this.toastr.warning(`Tentativa ${attempts + 1} de obter a síntese do processo`);
          setTimeout(() => {
            this.getSinteseProcessos(attempts + 1); // Chama a função novamente com a próxima tentativa
          }, 6000);
        } else {
          this.toastr.warning(`Erro ao obter a síntese do processo`);
          this.isLoadingSintese = false;
          this.exibeReprocessarSintese = true;
        }
      }
    );
  }

  openLinhaTempo() {
    const menu = this.dialog.open(LinhaTempoComponent, {
      width: '90%',
      panelClass: 'bg-color',
      height: '90%',
      data: {
        idPesquisa: this.idPesquisa,
        documento: this.documento
      }
    });
  }

  montaurlEndereco(enderecourl) {
    this.enderecoUrl = enderecourl
    this.enderecoUrl = this.enderecoUrl.replaceAll(' ', '+')
    this.enderecoUrlCompleto = `https://www.google.com/maps/embed/v1/place?q=${this.enderecoUrl}&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8`;
    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.enderecoUrlCompleto)
  }

  // consultaDadosCadastraisEmail(documento){
  //   this.isLoadingEmails = true;
  //   this.pesquisaService.getConsultaCadastralEmail(documento).subscribe(response => {
  //     this.ui.loaded().subscribe(() => {
  //      this.infoCadastraisEmails = response;
  //      this.isLoadingEmails = false;
  //     });
  //   });
  // }

  // consultaDadosCadastraisTelefone(documento){
  //   this.isLoadingTelefones = true;
  //   this.pesquisaService.getConsultaCadastralTelefone(documento).subscribe(response => {
  //     this.ui.loaded().subscribe(() => {
  //      this.infoCadastraisTelefone = response;
  //      this.isLoadingTelefones = false;
  //     });
  //   });
  // }

  // consultaDadosCadastraisEndereco(documento){
  //   this.isLoadingEnderecos = true;
  //   this.pesquisaService.getConsultaCadastralEndereco(documento).subscribe(response => {
  //     this.ui.loaded().subscribe(() => {
  //       this.infoCadastraisEnderecos = response;
  //      this.isLoadingEnderecos = false;
  //      if(this.infoCadastraisEnderecos.tipoDocumento == 2){
  //         this.objetoEndereco.endereco = this.infoCadastraisEnderecos.dadosCnpj.logradouro;
  //         this.objetoEndereco.numero = this.infoCadastraisEnderecos.dadosCnpj.numero;
  //         this.objetoEndereco.bairro = this.infoCadastraisEnderecos.dadosCnpj.bairro;
  //         this.objetoEndereco.uf = this.infoCadastraisEnderecos.dadosCnpj.uf;
  //      }

  //      if(this.infoCadastraisEnderecos.tipoDocumento == 1){
  //       this.objetoEndereco.endereco = this.infoCadastraisEnderecos.dadosCpf?.enderecos[0].logradouro;
  //       this.objetoEndereco.numero = this.infoCadastraisEnderecos.dadosCpf?.enderecos[0].numero;
  //       this.objetoEndereco.bairro = this.infoCadastraisEnderecos.dadosCpf?.enderecos[0].bairro;
  //       this.objetoEndereco.uf = this.infoCadastraisEnderecos.dadosCpf?.enderecos[0].uf;
  //      }
  //      var enderecoCompleto = `${this.objetoEndereco.endereco + '+' + this.objetoEndereco.numero + '+' + this.objetoEndereco.bairro + '+' + this.objetoEndereco.cidade + '+' + this.objetoEndereco.uf}`;
  //       this.montaurlEndereco(enderecoCompleto)
  //     });
  //   });
  // }

  getinfoCadastraisCredito(idPesquisa){
    idPesquisa = this.idPesquisa;
    this.isLoadingCadastrais = true;
    let attempts = 0;

    const subscription = timer(0, 10000).pipe(
      takeWhile(() => attempts < 5) // Limita a 5 tentativas
    ).subscribe(() => {
      this.pesquisaService.getCadastralAnaliseCredito(idPesquisa).subscribe(
        response => {
          if (response) {
            this.isLoadingCadastrais = false;
            if(response.dadosCpf.cpf){
              this.infoCadastrais = response.dadosCpf;
              this.objetoEndereco.endereco = response.dadosCpf?.enderecos[0]?.logradouro;
              this.objetoEndereco.numero = response.dadosCpf?.enderecos[0]?.numero;
              this.objetoEndereco.bairro = response.dadosCpf?.enderecos[0]?.bairro;
              this.objetoEndereco.uf = response.dadosCpf?.enderecos[0]?.uf;
            }
            if(response.dadosCnpj.cnpj){
              this.infoCadastrais = response.dadosCnpj;
              this.objetoEndereco.endereco = response.dadosCnpj.logradouro;
              this.objetoEndereco.numero = response.dadosCnpj.numero;
              this.objetoEndereco.bairro = response.dadosCnpj.bairro;
              this.objetoEndereco.uf = response.dadosCnpj.uf;
            }
            console.log(this.infoCadastrais)
            var enderecoCompleto = `${this.objetoEndereco.endereco + '+' + this.objetoEndereco.numero + '+' + this.objetoEndereco.bairro + '+' + this.objetoEndereco.cidade + '+' + this.objetoEndereco.uf}`;
            this.montaurlEndereco(enderecoCompleto)
            
            subscription.unsubscribe(); // Interrompe o intervalo após uma resposta bem-sucedida
          }
        },
        error => {
          attempts++;
          if(error.status == 501){
            this.isLoadingCadastrais = false;
            subscription.unsubscribe(); // Interrompe o intervalo após 5 tentativas
            this.exibirDadosCadastrais = false;
          }

          if (attempts >= 5) {
            // this.isLoadingCadastrais = true;
            subscription.unsubscribe(); // Interrompe o intervalo após 5 tentativas
            this.toastr.warning(`Erro ao obter as informações cadastrais do pesquisado.`);
            this.isLoadingCadastrais = false;
          }
        }
      );
    });
  }

  getinfoCadastraisCreditoNovaPesquisa(numero){
    console.log('credito nova')
    this.isLoadingCadastrais = true;
    this.pesquisaService.getCadastralCreditoNovaPesquisa(numero).subscribe(
      response => {
        if (response) {
          console.log(response)
          this.isLoadingCadastrais = false;
          if(response.dadosCpf.cpf){
            this.infoCadastrais = response.dadosCpf;
            this.objetoEndereco.endereco = response.dadosCpf?.enderecos[0]?.logradouro;
            this.objetoEndereco.numero = response.dadosCpf?.enderecos[0]?.numero;
            this.objetoEndereco.bairro = response.dadosCpf?.enderecos[0]?.bairro;
            this.objetoEndereco.uf = response.dadosCpf?.enderecos[0]?.uf;
          }
          if(response.dadosCnpj.cnpj){
            this.infoCadastrais = response.dadosCnpj;
            this.objetoEndereco.endereco = response.dadosCnpj.logradouro;
            this.objetoEndereco.numero = response.dadosCnpj.numero;
            this.objetoEndereco.bairro = response.dadosCnpj.bairro;
            this.objetoEndereco.uf = response.dadosCnpj.uf;
          }
          var enderecoCompleto = `${this.objetoEndereco.endereco + '+' + this.objetoEndereco.numero + '+' + this.objetoEndereco?.bairro + '+' + this.objetoEndereco.cidade + '+' + this.objetoEndereco.uf}`;
          console.log(enderecoCompleto)
          this.montaurlEndereco(enderecoCompleto)
          
          // subscription.unsubscribe(); // Interrompe o intervalo após uma resposta bem-sucedida
        }
      },
      error => {
        if(error.status == 501){
          this.isLoadingCadastrais = false;
          this.exibirDadosCadastrais = false;
        }
        this.toastr.warning(`Erro ao obter as informações cadastrais do pesquisado.`);
      }
    );
  }

  atualizarExibeAnaliseCredito(valor: boolean) {
    console.log(valor)
    this.exibeAnaliseCredito = valor;
  }


  exportarClicked() {
    this.ui.loading();
    this.pesquisaService.exportar({ id: this.idPesquisa }, "processos").subscribe(data => {
      this.ui.loaded().subscribe(() => {
        let a = document.createElement("a");
        a.href = "data:application/pdf;base64," + data.arquivoConteudo;
        a.download = data.arquivoNome;
        a.click();
      });
    }, (err: any) => {
      this.ui.loaded().subscribe(() => {
        // this.openSnackBarWarning('Atenção', err.error);
      })
    });
  }

  async verificaStatusPesquisa(idPesquisa) {
   // Inicializa com um valor diferente de 4
    await new Promise<void>((resolve) => {
      const subscription = interval(10000).subscribe(async () => {
        let response = await this.pesquisaService.getStatusPesquisa(idPesquisa)
          .pipe(
            catchError(error => {
              this.isLoadingPdf = true;
              subscription.unsubscribe();
              return throwError('Erro interno'); // Lança um novo erro para ser capturado pelo catch
            })
          )
          .toPromise();
          this.porcentagemPesquisa = response.procentagem;
          this.statusPesquisa = response.status
  
        if (response.status == 4) {
          subscription.unsubscribe(); // para o looping da api 
          this.isLoadingPdf = false; // Define isLoadingPdf como false
          resolve();
        }
      });
    });
  }

  getCorPorcentagem(porcentagem: number): string {
    const corDefault = '#c5c5c5'; // Azul
    const corPercentual = '#00ccffdd'; // Verde
  
    if (porcentagem === undefined || isNaN(porcentagem)) {
      return corDefault;
    }
    
    const cor1Percentual = porcentagem;
    const cor2Percentual = 100 - porcentagem;
    return `linear-gradient(to right, ${corPercentual} ${cor1Percentual}%, ${corDefault} ${cor2Percentual}%)`;
  }
  

}
