<div class="container">
  <div *ngFor="let form of forms; let i = index" class="form-container">
    <div class="row d-flex mt-2">
      <div class="col">
        <form [formGroup]="form">
          <mat-form-field appearance="outline" class="m-1">
            <mat-label>Tipo de Ativo</mat-label>
            <mat-select formControlName="tipoId" name="tipoId{{i}}" (change)="onValorSelecionadoChange()">
              <mat-option value="1">Imoveis</mat-option>
              <mat-option value="2">Veiculos</mat-option>
              <mat-option value="3">Embarcações</mat-option>
              <mat-option value="4">Avião</mat-option>
              <mat-option value="5">Maquinario</mat-option>
              <mat-option value="6">Faturamento</mat-option>
              <mat-option value="7">Recebiveis Processuais</mat-option>
              <mat-option value="8">Outros</mat-option>
            </mat-select>
          </mat-form-field>
          <!-- ============ IMOVEIS ============= -->
          <mat-form-field class="example-full-width" appearance="outline" class="m-1"
            *ngIf="form.get('tipoId').value == 1">
            <mat-label>Numero de Matricula</mat-label>
            <input formControlName="numeroMatricula" name="numeroMatricula{{i}}" matInput>
          </mat-form-field>

          <mat-form-field class="example-full-width" appearance="outline" class="m-1"
            *ngIf="form.get('tipoId').value == 1">
            <mat-label>descricao</mat-label>
            <input formControlName="descricao" name="descricao{{i}}" matInput>
          </mat-form-field>

          <mat-form-field class="example-full-width" appearance="outline" class="m-1"
            *ngIf="form.get('tipoId').value == 1">
            <mat-label>Área (M²/Ha)</mat-label>
            <input formControlName="area" name="area{{i}}" matInput>
          </mat-form-field>

          <mat-form-field class="example-full-width" appearance="outline" class="m-1"
            *ngIf="form.get('tipoId').value == 1">
            <mat-label>Cidade</mat-label>
            <input formControlName="cidade" name="cidade{{i}}" matInput>
          </mat-form-field>

          <mat-form-field class="example-full-width" appearance="outline" class="m-1"
            *ngIf="form.get('tipoId').value == 1">
            <mat-label>UF</mat-label>
            <input formControlName="uf" name="uf{{i}}" matInput>
          </mat-form-field>

          <mat-form-field class="example-form-field" appearance="outline" class="m-1"
            *ngIf="form.get('tipoId').value == 1">
            <mat-label>Data de Avaliação</mat-label>
            <input matInput type="date" formControlName="dataAvaliacao" name="dataAvaliacao{{i}}">
          </mat-form-field>

          <mat-form-field class="example-form-field" appearance="outline" class="m-1"
            *ngIf="form.get('tipoId').value == 1">
            <mat-label>Valor de Avaliação</mat-label>
            <input matInput formControlName="valorAtivo" name="valorAtivo{{i}}" matInput currencyMask [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', align:' left' }">
          </mat-form-field>

          <!-- ============ VEÍCULOS ============= -->
          <mat-form-field class="example-full-width" appearance="outline" class="m-1"
            *ngIf="form.get('tipoId').value == 2">
            <mat-label>Placa</mat-label>
            <input formControlName="placa" name="placa{{i}}" matInput>
          </mat-form-field>

          <mat-form-field class="example-full-width" appearance="outline" class="m-1"
            *ngIf="form.get('tipoId').value == 2">
            <mat-label>Renavam</mat-label>
            <input formControlName="renavam" name="renavam{{i}}" matInput>
          </mat-form-field>

          <mat-form-field class="example-full-width" appearance="outline" class="m-1"
            *ngIf="form.get('tipoId').value == 2">
            <mat-label>Marca e Modelo</mat-label>
            <input formControlName="marcaModelo" name="marcaModelo{{i}}" matInput>
          </mat-form-field>

          <mat-form-field class="example-full-width" appearance="outline" class="m-1"
            *ngIf="form.get('tipoId').value == 2">
            <mat-label>Ano de Fabricaçã</mat-label>
            <input formControlName="anoFabricacao" name="anoFabricacao{{i}}" matInput>
          </mat-form-field>

          <mat-form-field class="example-full-width" appearance="outline" class="m-1"
            *ngIf="form.get('tipoId').value == 2">
            <mat-label>Cidade</mat-label>
            <input formControlName="cidade" name="cidade{{i}}" matInput>
          </mat-form-field>

          <mat-form-field class="example-full-width" appearance="outline" class="m-1"
            *ngIf="form.get('tipoId').value == 2">
            <mat-label>UF</mat-label>
            <input formControlName="uf" name="uf{{i}}" matInput>
          </mat-form-field>

          <mat-form-field class="example-form-field" appearance="outline" class="m-1"
            *ngIf="form.get('tipoId').value == 2">
            <mat-label>Data de Avaliação</mat-label>
            <input matInput type="date" formControlName="dataAvaliacao" name="dataAvaliacao{{i}}">
          </mat-form-field>

          <mat-form-field class="example-form-field" appearance="outline" class="m-1"
            *ngIf="form.get('tipoId').value == 2">
            <mat-label>Valor de Avaliação</mat-label>
            <input matInput formControlName="valorAtivo" name="valorAtivo{{i}}"  matInput currencyMask [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', align:' left' }">
          </mat-form-field>

          <!-- ============ Embarcacoes ou aviao ============= -->


          <mat-form-field class="example-full-width" appearance="outline" class="m-1"
            *ngIf="form.get('tipoId').value == 3 || form.get('tipoId').value == 4">
            <mat-label>Marca e Modelo</mat-label>
            <input formControlName="marcaModelo" name="marcaModelo{{i}}" matInput>
          </mat-form-field>

          <mat-form-field class="example-full-width" appearance="outline" class="m-1"
            *ngIf="form.get('tipoId').value == 3 || form.get('tipoId').value == 4">
            <mat-label>Número de Registro ou Série</mat-label>
            <input formControlName="numRegistro" name="numRegistro{{i}}" matInput>
          </mat-form-field>

          <mat-form-field class="example-full-width" appearance="outline" class="m-1"
            *ngIf="form.get('tipoId').value == 3 || form.get('tipoId').value == 4">
            <mat-label>Ano de Fabricação</mat-label>
            <input formControlName="anoFabricacao" name="anoFabricacao{{i}}" matInput>
          </mat-form-field>

          <mat-form-field class="example-full-width" appearance="outline" class="m-1"
            *ngIf="form.get('tipoId').value == 3 || form.get('tipoId').value == 4">
            <mat-label>Cidade</mat-label>
            <input formControlName="cidade" name="cidade{{i}}" matInput>
          </mat-form-field>

          <mat-form-field class="example-full-width" appearance="outline" class="m-1"
            *ngIf="form.get('tipoId').value == 3">
            <mat-label>UF</mat-label>
            <input formControlName="uf" name="uf{{i}}" matInput>
          </mat-form-field>

          <mat-form-field class="example-form-field" appearance="outline" class="m-1"
            *ngIf="form.get('tipoId').value == 3 || form.get('tipoId').value == 4">
            <mat-label>Data de Avaliação</mat-label>
            <input matInput type="date" formControlName="dataAvaliacao" name="dataAvaliacao{{i}}">
          </mat-form-field>

          <mat-form-field class="example-form-field" appearance="outline" class="m-1"
            *ngIf="form.get('tipoId').value == 3 || form.get('tipoId').value == 4">
            <mat-label>Valor de Avaliação</mat-label>
            <input matInput formControlName="valorAtivo" name="valorAtivo{{i}}"  matInput currencyMask [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', align:' left' }">
          </mat-form-field>

          <!-- ============ MAQUINÁRIO ============= -->

          <mat-form-field class="example-full-width" appearance="outline" class="m-1"
            *ngIf="form.get('tipoId').value == 5">
            <mat-label>Marca e Modelo</mat-label>
            <input formControlName="marcaModelo" name="marcaModelo{{i}}" matInput>
          </mat-form-field>

          <mat-form-field class="example-full-width" appearance="outline" class="m-1"
            *ngIf="form.get('tipoId').value == 5">
            <mat-label>Ano de Fabricação</mat-label>
            <input formControlName="anoFabricacao" name="anoFabricacao{{i}}" matInput>
          </mat-form-field>

          <mat-form-field class="example-full-width" appearance="outline" class="m-1"
            *ngIf="form.get('tipoId').value == 5">
            <mat-label>Cidade</mat-label>
            <input formControlName="cidade" name="cidade{{i}}" matInput>
          </mat-form-field>

          <mat-form-field class="example-full-width" appearance="outline" class="m-1"
            *ngIf="form.get('tipoId').value == 5">
            <mat-label>UF</mat-label>
            <input formControlName="uf" name="uf{{i}}" matInput>
          </mat-form-field>

          <mat-form-field class="example-form-field" appearance="outline" class="m-1"
            *ngIf="form.get('tipoId').value == 5">
            <mat-label>Data de Avaliação</mat-label>
            <input matInput type="date" formControlName="dataAvaliacao" name="dataAvaliacao{{i}}">
          </mat-form-field>

          <mat-form-field class="example-form-field" appearance="outline" class="m-1"
            *ngIf="form.get('tipoId').value == 5">
            <mat-label>Valor de Avaliação</mat-label>
            <input matInput formControlName="valorAtivo" name="valorAtivo{{i}}"  matInput currencyMask [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', align:' left' }">
          </mat-form-field>

          <!-- ============ FATURAMENTO ============= -->

          <mat-form-field class="example-full-width" appearance="outline" class="m-1"
            *ngIf="form.get('tipoId').value == 6">
            <mat-label>Nome da Empresa</mat-label>
            <input formControlName="nomeEmpresa" name="nomeEmpresa{{i}}" matInput>
          </mat-form-field>

          <mat-form-field class="example-full-width" appearance="outline" class="m-1"
            *ngIf="form.get('tipoId').value == 6">
            <mat-label>Nome do Sócio</mat-label>
            <input formControlName="nomeSocio" name="nomeSocio{{i}}" matInput>
          </mat-form-field>

          <mat-form-field class="example-full-width" appearance="outline" class="m-1"
            *ngIf="form.get('tipoId').value == 6">
            <mat-label>Faturamento Anual</mat-label>
            <input formControlName="faturamentoAnual" name="faturamentoAnual{{i}}" matInput>
          </mat-form-field>

          <mat-form-field class="example-full-width" appearance="outline" class="m-1"
            *ngIf="form.get('tipoId').value == 6">
            <mat-label>Quantidade de Meses para considerar</mat-label>
            <input formControlName="qtdMesesConsiderar" name="qtdMesesConsiderar{{i}}" matInput>
          </mat-form-field>

          <mat-form-field class="example-full-width" appearance="outline" class="m-1"
            *ngIf="form.get('tipoId').value == 6">
            <mat-label>Cidade</mat-label>
            <input formControlName="cidade" name="cidade{{i}}" matInput>
          </mat-form-field>

          <mat-form-field class="example-full-width" appearance="outline" class="m-1"
            *ngIf="form.get('tipoId').value == 6">
            <mat-label>UF</mat-label>
            <input formControlName="uf" name="uf{{i}}" matInput>
          </mat-form-field>

          <mat-form-field class="example-full-width" appearance="outline" class="m-1"
            *ngIf="form.get('tipoId').value == 6">
            <mat-label>Valor do Faturamento</mat-label>
            <input formControlName="valorAtivo" name="valorAtivo{{i}}"  matInput currencyMask [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', align:' left' }">
          </mat-form-field>

          <!-- ============ RECEBÍVEIS PROCESSUAIS ============= -->

          <mat-form-field class="example-full-width" appearance="outline" class="m-1"
            *ngIf="form.get('tipoId').value == 7">
            <mat-label>Número do Processo</mat-label>
            <input formControlName="numProcesso" name="numProcesso{{i}}" matInput appProcessoMask maxlength="25">
          </mat-form-field>

          <mat-form-field class="example-full-width" appearance="outline" class="m-1"
            *ngIf="form.get('tipoId').value == 7">
            <mat-label>Tipo do Processo</mat-label>
            <input formControlName="tipoProcesso" name="tipoProcesso{{i}}" matInput>
          </mat-form-field>

          <mat-form-field class="example-full-width" appearance="outline" class="m-1"
            *ngIf="form.get('tipoId').value == 7">
            <mat-label>Comarca</mat-label>
            <input formControlName="comarca" name="comarca{{i}}" matInput>
          </mat-form-field>

          <mat-form-field class="example-full-width" appearance="outline" class="m-1"
            *ngIf="form.get('tipoId').value == 7">
            <mat-label>UF</mat-label>
            <input formControlName="uf" name="uf{{i}}" matInput>
          </mat-form-field>

          <mat-form-field class="example-form-field" appearance="outline" class="m-1"
            *ngIf="form.get('tipoId').value == 7">
            <mat-label>Data de Avaliação</mat-label>
            <input matInput type="date" formControlName="dataAvaliacao" name="dataAvaliacao{{i}}">
          </mat-form-field>

          <mat-form-field class="example-form-field" appearance="outline" class="m-1"
            *ngIf="form.get('tipoId').value == 7">
            <mat-label>Valor de Avaliação</mat-label>
            <input matInput formControlName="valorAtivo" name="valorAtivo{{i}}"  matInput currencyMask [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', align:' left' }">
          </mat-form-field>

          <!-- ============ OUTROS ATIVOS ============= -->

          <mat-form-field class="example-full-width" appearance="outline" class="m-1"
            *ngIf="form.get('tipoId').value == 8">
            <mat-label>Descrição</mat-label>
            <input formControlName="descricao" name="descricao{{i}}" matInput>
          </mat-form-field>

          <mat-form-field class="example-full-width" appearance="outline" class="m-1"
            *ngIf="form.get('tipoId').value == 8">
            <mat-label>Cidade</mat-label>
            <input formControlName="cidade" name="cidade{{i}}" matInput>
          </mat-form-field>

          <mat-form-field class="example-full-width" appearance="outline" class="m-1"
            *ngIf="form.get('tipoId').value == 8">
            <mat-label>UF</mat-label>
            <input formControlName="uf" name="uf{{i}}" matInput>
          </mat-form-field>

          <mat-form-field class="example-form-field" appearance="outline" class="m-1"
            *ngIf="form.get('tipoId').value == 8">
            <mat-label>Data de Avaliação</mat-label>
            <input matInput type="date" formControlName="dataAvaliacao" name="dataAvaliacao{{i}}">
          </mat-form-field>

          <mat-form-field class="example-form-field" appearance="outline" class="m-1"
            *ngIf="form.get('tipoId').value == 8">
            <mat-label>Valor de Avaliação</mat-label>
            <input matInput formControlName="valorAtivo" name="valorAtivo{{i}}"  matInput currencyMask [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', align:' left' }">
          </mat-form-field>
        </form>
        <div class="d-flex justify-content-end" style="margin-top: -10px;">
          <mat-icon class="delete-icon" *ngIf="forms.length > 0" (click)="removeForm(i)">delete</mat-icon>
        </div>
        <hr>
      </div>

    </div>
  </div>
  <div class="m-1 mb-2 d-flex div-more" >
    <div class="m-2 d-flex align-items-center" (click)="addForm()">
      <mat-icon class="icon-more">add</mat-icon> <span class="more">&nbsp;Adicionar Ativo</span>
    </div>
    <div class="m-2 d-flex align-items-center" (click)="openModalAnexo()">
      <mat-icon class="icons-action-file">attach_file</mat-icon> <span class="more">&nbsp;Importar Ativos</span>
    </div>
    <div class="m-2 d-flex align-items-center" (click)="deletarTodosAtivos()">
      <mat-icon class="icons-action-delete">delete</mat-icon> <span class="more">&nbsp;Deletar Todos os Ativos</span>
    </div>
  </div>
</div>
