<div class="form-ext">
    
    <mat-card class="pesq-re mt-3">
      <div class="header-pesq">
        <div class="title-pesq">
          <img class="img-title-pesq" src="../../../assets/icones/lupa-azul.svg" alt="view">
          <p>Histórico de Precificações</p>
        </div>
        <div class="container">
          <form class="novapesq-form">
          <div class="container">
            <div class="row d-flex justify-content-between align-items-center">
              <div class="col-md-6">
                <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label>Identificação</mat-label>
                  <input matInput [(ngModel)]="formFiltro.identificacao" name="identificacao" (keyup.enter)="pesquisarClicked()">
              </mat-form-field>
              </div>
              <div class="col">
                <button mat-flat-button (click)="pesquisarClicked()" class="gs-botaoAzulPadrao m-3">Filtrar <mat-icon>search</mat-icon></button>
              </div>
              <!-- <div class="col-md">
                <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label>Titulo</mat-label>
                  <input matInput [(ngModel)]="formFiltro.titulo" name="titulo" (keyup.enter)="pesquisarClicked()">
                </mat-form-field>
              </div> -->
            </div>
          </div>  
          </form>
        </div>  
      </div>
  
      <div class="separador"></div>
      <div class="resultado">
        <div class="pesq-table" infiniteScroll
          [infiniteScrollDistance]="2"
          [infiniteScrollThrottle]="50"
          (scrolled)="onScroll()"
          [infiniteScrollUpDistance]="1.5"
          [scrollWindow]="false">
  
          <div class="outer-wrapper">
            <div style="text-align: center;" *ngIf="resultadoPesquisa.length == 0">
              Nenhuma pesquisa encontrada.
            </div>
            <div class="table-wrapper" *ngIf="resultadoPesquisa.length != 0">
              <table class="table-grid">
                <thead class="thead-grid">
                    <th class="th-grid">Identificação</th>
                    <th class="th-grid">Titulo</th>
                    <th class="th-grid">Data de Cadastro</th>
                    <th class="th-grid">Valor Atualizado</th>
                    <th class="th-grid">Valor Atingivel</th>
                    <th class="th-grid"></th>
                </thead>
                <tbody class="tbody-grid">
                    <tr *ngFor="let o of resultadoPesquisa" class="tr-grid">
                        <td class="td-grid">
                          <div class="border-column">{{o.identificacao}}</div>
                        </td>
                        <td class="td-grid">
                          <div class="border-column">
                            {{o.titulo}}
                          </div>  
                        </td>
                        <td class="td-grid">
                          <div class="border-column">
                            {{o.dataCadastro | date: 'dd/MM/yyyy'}}
                          </div>  
                        </td>
                        <td class="td-grid">
                          <div class="border-column">
                            {{o.valorAtualizado | currency : 'BRL'}}
                          </div>
                        </td>
                        <td class="td-grid">
                          <div class="border-column">
                            {{ o.valortotalAtingivel | currency : 'BRL'}}
                          </div>
                        </td>
                        <td class="td-grid">
                          <div class="col">
                            <button mat-flat-button (click)="clickAbrirItemPrecificacao(o)" class="gs-botaoAzulPadrao m-3">Abrir <mat-icon>search</mat-icon></button>
                          </div>
                        </td>
                    </tr>
                </tbody>
            </table>
          </div>
        </div>
        </div>
      </div>
    </mat-card>
  </div>
  