import { Component, Input, OnInit } from '@angular/core';
import { takeWhile, timer } from 'rxjs';
import { PesquisaService } from 'src/app/shared/services/http/pesquisa.service';

@Component({
  selector: 'app-cadastro-empresas-punidas',
  templateUrl: './cadastro-empresas-punidas.component.html',
  styleUrls: ['./cadastro-empresas-punidas.component.scss']
})
export class CadastroEmpresasPunidasComponent implements OnInit {
  @Input() idPesquisa: number;
  @Input() aba: any;


  dataCadastroEmpresasPunidas;
  isLoadingCadastroEmpresasPunidas = false;
  exibeCadastroEmpresasPunidas = false;
  exibeMensagemCadastroEmpresasPunidas =  false;
  exibeReprocessarCadastroEmpresasPunidas = false;

  panelOpenState = false


  constructor(
      private pesquisaService: PesquisaService
  ) { }

  ngOnInit(): void {
     this.getCadastroEmpresasPunidas(this.idPesquisa)
  }

  getCadastroEmpresasPunidas(idPesquisa) {
    this.isLoadingCadastroEmpresasPunidas = true;
    this.exibeMensagemCadastroEmpresasPunidas = false;
    this.exibeReprocessarCadastroEmpresasPunidas = false;
    let attempts = 0;
  
    const subscription = timer(0, 15000).pipe(
      takeWhile(() => attempts < 5) // Limita a 5 tentativas
    ).subscribe(() => {
      this.pesquisaService.getCadastroEmpresasPunidas(idPesquisa).subscribe(
        response => {
          this.dataCadastroEmpresasPunidas = response;
          switch (response.codigo) {
            case 4000:
              this.exibeCadastroEmpresasPunidas = false;
              this.isLoadingCadastroEmpresasPunidas = false;
              subscription.unsubscribe();
              break;
            case 1000:
              this.exibeCadastroEmpresasPunidas = true;
              this.isLoadingCadastroEmpresasPunidas = false;
              this.exibeMensagemCadastroEmpresasPunidas = false;
              subscription.unsubscribe();
              break;
            case 2000:
                this.exibeCadastroEmpresasPunidas = true;
                this.isLoadingCadastroEmpresasPunidas = false;
                subscription.unsubscribe();
                break;
            case 3001:
              this.exibeCadastroEmpresasPunidas = true;
              this.isLoadingCadastroEmpresasPunidas = false;
              this.exibeMensagemCadastroEmpresasPunidas = true;
              subscription.unsubscribe();
              break;
            case 3000:
              this.exibeCadastroEmpresasPunidas = true;
              this.isLoadingCadastroEmpresasPunidas = false;
              this.exibeMensagemCadastroEmpresasPunidas = true;
              attempts++;
              if (attempts >= 5) {
                this.exibeReprocessarCadastroEmpresasPunidas = true;
                subscription.unsubscribe();
              }
              break;
          }
        },
        error => {
          attempts++;
          if (attempts >= 5) {
            this.exibeCadastroEmpresasPunidas = false;
            this.isLoadingCadastroEmpresasPunidas = false;
            subscription.unsubscribe(); // Interrompe o intervalo após 5 tentativas
          }
        }
      );
    });
  }  
}
