import { NavService } from './../../components/template/nav/nav.service';
import { HeaderService } from './../../components/template/header/header.service';
import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation, inject } from '@angular/core';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { FormControl, FormGroup } from '@angular/forms';
import { PesquisaService } from '../../shared/services/http/pesquisa.service';
import { StatusPesquisa } from '../../shared/enums/status-pesquisa.enum';
import { StatusRegistro } from '../../shared/enums/status-registro.enum';
import { TipoPesquisa } from '../../shared/enums/tipo-pesquisa.enum';
import { UiService } from '../../shared/services/ui.service';
import { ConfiguracaoService } from '../../shared/services/http/configuracao.service';
import { FonteGrupoService } from '../../shared/services/http/fonte-grupo.service';
import { UtilService } from '../../shared/services/http/util.service';
import { ToastrService } from 'ngx-toastr';
import { Observable, forkJoin, map, startWith } from 'rxjs';
import { Textos } from '../../shared/textos';
import { Fonte } from '../../shared/enums/fonte.enum';
import { MatDialog } from '@angular/material/dialog';
import { PopPesqRealizadaComponent } from './../../components/view/poppesqrealizada/poppesqrealizada.component';
import { HttpStatusCode } from '@angular/common/http';
import { MensageResponseComponent } from 'src/app/components/view/mensage-response/mensage-response.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ProdutosEnum } from 'src/app/shared/enums/produtos.enum';
import { MensageErrorResponseComponent } from 'src/app/components/view/mensage-error-response/mensage-error-response.component';
import { AuthService } from 'src/app/shared/services/http/auth.service';
import { MatCheckbox, MatCheckboxChange } from '@angular/material/checkbox';
import { ModalExportarPesquisaLoteComponent } from 'src/app/components/view/modal-exportar-pesquisa-lote/modal-exportar-pesquisa-lote.component';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { ModalConfirmacaoComponent } from 'src/app/components/view/modal-confirmacao/modal-confirmacao.component';
import { ModalEdicaoTagComponent } from 'src/app/components/view/modal-edicao-tag/modal-edicao-tag.component';
import { ModalValoresAdicionaisComponent } from '../patrimonial/modal-valores-adicionais/modal-valores-adicionais.component';
import { MatTabGroup } from '@angular/material/tabs';

const today = new Date();
const month = today.getMonth();
const year = today.getFullYear();

export interface PeriodicElement {
  id: number;
  relatorio: string;
  cpf: string;
  nome: string;
  emissao: string;
  horario: string;
  status: string;
  progresso: string;
}

@Component({
  selector: 'app-cadastrais',
  templateUrl: './cadastrais.component.html',
  styleUrls: ['./cadastrais.component.scss'],
  // encapsulation: ViewEncapsulation.None
})
export class CadastraisComponent implements OnInit {

  StatusRegistro = StatusRegistro;
  StatusPesquisa = StatusPesquisa;
  TipoPesquisa = TipoPesquisa;

  pesquisasSelecionadas = [];

  formCadastroValorAdicional: boolean = false;
  formCadastroValorAdicionalLabel: string = "";
  Fonte = Fonte;
  tipoLabels: Map<Fonte, string> = new Map<Fonte, string>();

  formPesquisa: any = {};
  formFiltro: any = { page: 1, pageSize: 50, perfil: "", status: "", produtoID: 1 };
  formFiltroRenderizado: any = this.formFiltro;

  refazer: boolean = false;
  resultadoPesquisa: any = [];
  usuario: any;
  configuracao: any = {};
  listaFonteGrupo: any = [];
  cpfMask: string;
  exibeToast = false
  selected: any;
  isSuzano = true;
  isDisabled = false

  listaFontesAdicionais;
  listaFontesAdicionaisSemFiltro = [];
  listaConfiguracoesParametros = [];
  parametrosToRequest: any = [];
  adicionaisToRequest = [];
  formParametros = []
  valorAdicionalParametro: number = 0;

  // ==== automplete chips ====
  myControl = new FormControl();
  tagIds = [];
  addedItems = [];
  autoCompletePanel: any;
  labelTag: String = 'Tag';
  filteredTags: Observable<any[]>;

  campaignOne = new FormGroup({
    start: new FormControl(new Date(year, month, 13)),
    end: new FormControl(new Date(year, month, 16)),
  });
  campaignTwo = new FormGroup({
    start: new FormControl(new Date(year, month, 15)),
    end: new FormControl(new Date(year, month, 19)),
  });

  abas: { titulo: string, id: number, novaPesquisa:boolean }[] = [{ titulo: 'Histórico', id: null, novaPesquisa: false}];
  selectedIndex = 0;
  @ViewChild(MatTabGroup) tabGroup: MatTabGroup;

  constructor(
    private pesquisaService: PesquisaService,
    private utilService: UtilService,
    private ui: UiService,
    private toastr: ToastrService,
    private configuracaoService: ConfiguracaoService,
    private fonteGrupoService: FonteGrupoService,
    private headerService: HeaderService,
    private navService: NavService,
    private authService: AuthService,
    private _snackBar: MatSnackBar,
    public dialog: MatDialog) {
    navService.navData = {
      title: 'Cadastrais'
    }
    headerService.headerData = {
      title: 'Cadastrais'
    }
  }

  ngOnInit(): void {
    // this.getUsuario();
    // this.formPesquisa.numero = "";
    // this.formPesquisa.notificacao = true
    // this.ui.loading();

    // let requests = [
    //   this.configuracaoService.get(),
    //   this.fonteGrupoService.listAtivoProduto(ProdutosEnum.Cadastrais)
    // ];

    // forkJoin(requests).subscribe(data => {
    //   if(data && data[1].length == 0){
    //     this.ui.loaded().subscribe(() =>{
    //       this.toastr.warning('Nenhum grupo de fontes vinculado ao pacote do usuário, entre em contato com o suporte.');
    //       this.pesquisarClickedSemLoader(1);
    //     })
    //   }
    //   this.configuracao = data[0];
    //   this.listaFonteGrupo = data[1];
    //   this.listaFontesAdicionais = data[1];

    //   this.formPesquisa.idFonteGrupo = this.listaFonteGrupo[0]?.fonteGrupo?.id;

    //   this.listaFontesAdicionais = this.listaFontesAdicionais.find(x => x.fonteGrupo.id == this.formPesquisa.idFonteGrupo)
    //   this.listaConfiguracoesParametros = this.listaFontesAdicionais.fonteGrupo.listaFonteGrupoItem.filter(x => x.fonteConfiguracao.configuracaoParametros.length > 0 && x.fonteSelecionavel == false)
    //   this.listaConfiguracoesParametros = this.listaConfiguracoesParametros.map(x => x.fonteConfiguracao.configuracaoParametros[0])
    //   console.log(this.listaConfiguracoesParametros)
    //   this.listaFontesAdicionais = this.listaFontesAdicionais.fonteGrupo.listaFonteGrupoItem.filter(x => x.fonteSelecionavel == true)
    //   console.log(this.listaFontesAdicionais)
    //   this.listaFontesAdicionaisSemFiltro = this.listaFontesAdicionais.filter(x => x.fonteConfiguracao.categoria == 0)
    //   this.getChips();
    //   this.pesquisarClickedSemLoader(1)
    //   this.filteredTags = this.myControl.valueChanges.pipe(
    //     startWith(''),
    //     map(value => this._filterTags(value))
    //   );
    // });

    // this.tipoLabels.set(Fonte.CertidaoDividaUniaoImovelRural, "NIRF");

    // setInterval(() => {
    //   let page = this.formFiltro.page;
    //   this.pesquisarClickedSemLoader(page);
    // }, 1000 * 60)
  }

  // selecionarEstado(event: MatCheckbox, value) {
  //   if (value.fonteConfiguracao.configuracaoParametros.length > 0 && !event.checked) {
  //     for (const checkbox of this.listaConfiguracoesParametros) {
  //       checkbox.selecionado = false;
  //       const { [checkbox.descricao]: omit, ...rest } = this.parametrosToRequest;
  //       this.parametrosToRequest = { ...rest };
  //     }
  //     var indiceParam = this.listaConfiguracoesParametros.indexOf(value.fonteConfiguracao.configuracaoParametros[0]);
  //     this.listaConfiguracoesParametros.splice(indiceParam, 1);
  //   }
  //   if (value.fonteConfiguracao.configuracaoParametros.length > 0 && event.checked) {
  //     this.listaConfiguracoesParametros.push(value.fonteConfiguracao.configuracaoParametros[0])
  //   }
  //   if (event.checked) {
  //     this.formPesquisa.valorAdicional.push(value)
  //   }
  //   else {
  //     var indice = this.formPesquisa.valorAdicional.indexOf(value);
  //     this.formPesquisa.valorAdicional.splice(indice, 1);
  //   }
  //   this.adicionaisToRequest = this.formPesquisa.valorAdicional

  // }

  // selecionarParametros(event: MatCheckboxChange, value) {

  //   value.selecionado = event.checked;
  //   const objSelecionado = {
  //     [value.descricao]: event.checked
  //   }
  //   if (event.checked) {
  //     this.parametrosToRequest = { ...this.parametrosToRequest, ...objSelecionado };
  //   }
  //   // if(event.checked && event.source.disabled){
  //   //   const { [value.descricao]: omit, ...rest } = this.parametrosToRequest;
  //   //   this.parametrosToRequest = { ...rest };
  //   // }
  //   else {
  //     const { [value.descricao]: omit, ...rest } = this.parametrosToRequest;
  //     this.parametrosToRequest = { ...rest };
  //   }
  // }

  // private _filterTags(value: string): any[] {
  //   const filterValue = value.toLowerCase();
  //   return this.tagIds.filter(tag => tag.tag.toLowerCase().includes(filterValue));
  // }

  // getUsuario() {
  //   this.authService.callbackUsuario().subscribe(usuario => {
  //     if (!usuario) {
  //       return;
  //     }
  //     if (usuario) {
  //       this.usuario = usuario;
  //       if(usuario.cliente.descricaoTag && usuario.cliente.descricaoTag.trim().length > 0){
  //         this.labelTag = usuario.cliente.descricaoTag;
  //       }
  //     }
  //     if (usuario.cliente.id == 52) {
  //       this.isSuzano = true
  //     }
  //   })
  // }

  // addChip(value) {
  //   this.myControl.reset();
  //   if (!this.addedItems.find(item => item.tag === value.tag)) {
  //     this.addedItems.push(value);
  //   }
  //   this.tagIds = this.tagIds.filter(item => item.tag !== value.tag);
  //   this.filteredTags = this.filteredTags.pipe(
  //     map(tags => tags.filter(tag => tag.tagId !== value.tagId))
  //   );
  //   this.myControl.reset();
  // }

  // removeChip(item): void {
  //   const index = this.addedItems.indexOf(item);
  // if (index !== -1) {
  //   this.addedItems.splice(index, 1);
  //   this.tagIds.push(item);  // Adiciona o item de volta ao array tagIds
  //   this.filteredTags = this.myControl.valueChanges.pipe(
  //     startWith(''),
  //     map(value => this._filterTags(value))
  //   );
  // }
  // }

  // enviarChips(value) {
  //   this.ui.loading();
  //   const obj = {
  //     clienteId: this.usuario.cliente.id,
  //     tags: [{
  //       tagId: 0,
  //       tag: value
  //     }]
  //   }
  //   this.pesquisaService.cadastrarTagId(obj).subscribe(response => {
  //     this.ui.loaded().subscribe(() => {
  //       this.addedItems.push(response[0])
  //       this.filteredTags = this.myControl.valueChanges.pipe(
  //         startWith(''),
  //         map(value => this._filterTags(value))
  //       );
  //       this.myControl.reset();
  //     })
  //   }, (err:any) => {
  //     this.ui.loaded().subscribe(() => {
  //         this.toastr.error('Ocorreu um erro, tente novamente.')
  //     })
  //   });
  // }

  // onEnter(event: KeyboardEvent): void {
  //   if (document.activeElement === event.target) {
  //     event.preventDefault();

  //     const value = this.myControl.value.trim();
  //     const valueAdded = this.addedItems.map(x => x.tag)
  //     const valueGetted = this.tagIds.map(x => x.tag)
  //     if (value !== '' && !valueAdded.includes(value) && valueGetted.includes(value)) {
  //       let tagEnter = this.tagIds.filter(x => x.tag == value);
  //       this.addChip(tagEnter[0])
  //      }
  //     if (value !== '' && !valueAdded.includes(value) && !valueGetted.includes(value)) {
  //       this.enviarChips(value)
  //     }

  //     this.myControl.reset();
  //   }
  // }

  // onInputBlur(): void {
  //   const value = this.myControl.value.trim();
  //     const valueAdded = this.addedItems.map(x => x.tag)
  //     const valueGetted = this.tagIds.map(x => x.tag)
  //     if (value !== '' && !valueAdded.includes(value) && !valueGetted.includes(value)) {
  //       this.enviarChips(value)
  //     }
  //     this.myControl.reset();
  // }

  // getChips() {
  //   this.ui.loading()
  //   this.pesquisaService.getTagID(this.usuario.cliente.id).subscribe(response => {
  //     this.ui.loaded().subscribe(() => {
  //       this.tagIds = response
  //     })
  //   }, (err:any) => {
  //     this.ui.loaded().subscribe(() => {
  //         this.toastr.error('Ocorreu um erro, tente novamente.')
  //     })
  //   });
  // }

  // deletarTagExistente(value) {
  //   const dados = {
  //     title: '',
  //     message: 'Deseja realmente excluir esse item?',
  //     cancelText: 'Cancelar',
  //     confirmText: 'excluir'
  //   }
  //   const dialogRef = this.dialog.open(ModalConfirmacaoComponent, {
  //     maxWidth: "470px",
  //     data: dados
  //   });

  //   dialogRef.afterClosed().subscribe(dialogResult => {
  //     if (dialogResult == true) {
  //       this.ui.loading()
  //       this.pesquisaService.excluirTag(value.tagId).subscribe(response => {
  //         this.ui.loaded().subscribe(() => {
  //           // this.tagIds = response
  //           this.toastr.info('Tag excluída com sucesso.', 'CredValue');
  //           this.tagIds = this.tagIds.filter(i => i.tagId != value.tagId);
  //           this.filteredTags = this.filteredTags.pipe(
  //             map(tags => tags.filter(tag => tag.tagId != value.tagId))
  //           );
  //           this.getChips()
  //         })
  //       }, (err:any) => {
  //         this.ui.loaded().subscribe(() => {
  //             this.toastr.error('Ocorreu um erro, tente novamente.')
  //         })
  //       });
  //     }
  //   });

  // }

  // toggleItemSelection(pesquisa) {
  //   if (this.isItemSelected(pesquisa)) {
  //     this.pesquisasSelecionadas = this.pesquisasSelecionadas.filter(
  //       (selectedItem) => selectedItem.id !== pesquisa.id
  //     );
  //   } else {
  //     this.pesquisasSelecionadas.push(pesquisa);
  //   }

  // }

  // // Verifica se o item está selecionado
  // isItemSelected(item: any): boolean {
  //   return this.pesquisasSelecionadas.some((selectedItem) => selectedItem.id === item.id);
  // }

  // selecionarTodosItens(isSelected: MatCheckbox) {
  //   if (isSelected.checked) {
  //     this.resultadoPesquisa.forEach(item => {
  //       if (!this.isItemSelected(item)) {
  //         this.pesquisasSelecionadas.push(item);
  //       }
  //     });
  //   } else {
  //     this.resultadoPesquisa.forEach(item => {
  //       this.pesquisasSelecionadas = this.pesquisasSelecionadas.filter(
  //         selectedItem => selectedItem.id !== item.id
  //       );
  //     });
  //   }
  // }

  // exportarPesquisaLote() {
  //   const menu = this.dialog.open(ModalExportarPesquisaLoteComponent, {
  //     width: '450px',
  //     panelClass: 'bg-color',
  //     maxHeight: '600px',
  //     data: this.pesquisasSelecionadas
  //   });
  // }

  // editarTag(value){
  //   let tags = value.tags
  //   let IdPesquisa = value.id
  //   console.log(tags)
  //   let dialogRef = this.dialog.open(ModalEdicaoTagComponent,{
  //     width: '450px',
  //     panelClass: 'bg-color',
  //     maxHeight: '600px',
  //     data: {
  //       tags: tags,
  //       clienteId: this.usuario.cliente.id,
  //       label: this.labelTag,
  //       idPesquisa: IdPesquisa
  //     }
  //   });
  //   dialogRef.afterClosed().subscribe(result => {
  //     if (result == true) {
  //      this.pesquisarClicked();
  //      this.getChips();
  //     }
  //   });
  // }

  // pesquisarClicked(page = 1) {

  //   this.formFiltro.page = page;
  //   this.ui.loading();

  //   if (page == 1)
  //     this.resultadoPesquisa = [];

  //   let request = Object.assign({}, this.formFiltro);
  //   if (request.status) request.status = [request.status];
  //   this.pesquisaService.listPesquisaCliente(request).subscribe(response => {
  //     this.formFiltroRenderizado = Object.assign({}, this.formFiltro);
  //     this.ui.loaded().subscribe(() => {
  //       this.resultadoPesquisa = this.resultadoPesquisa.concat(response.list);
  //       console.log(this.resultadoPesquisa.map(x => x.pesquisa.tags))
  //     })
  //   })

  //   // this.clearForm()

  // }

  // pesquisarClickedSemLoader(page = 1) {
  //   this.formFiltro.page = page;

  //   if (page == 1)
  //     this.resultadoPesquisa = [];

  //   let request = Object.assign({}, this.formFiltro);
  //   if (request.status) request.status = [request.status];
  //   this.pesquisaService.listPesquisaCliente(request).subscribe(response => {
  //     this.formFiltroRenderizado = Object.assign({}, this.formFiltro);
  //     this.resultadoPesquisa = this.resultadoPesquisa.concat(response.list);
  //   })

  //   // this.clearForm()

  // }


  // onScroll() {
  //   let page = this.formFiltro.page + 1;
  //   this.pesquisarClicked(page);

  // }

  // onScrollDown() {
  // }

  // onScrollUp() {
  //   let page = this.formFiltro.page - 1;
  //   if (page > 0) {
  //     this.pesquisarClicked(page);
  //   }
  // }

  // exportarClicked(o: { pesquisa: { id: any; }; }) {
  //   this.ui.loading();
  //   this.pesquisaService.exportar({ id: o.pesquisa.id }, "cadastrais").subscribe(data => {
  //     this.ui.loaded().subscribe(() => {
  //       var a = document.createElement("a");
  //       a.href = "data:application/pdf;base64," + data.arquivoConteudo;
  //       a.download = data.arquivoNome;
  //       a.click();
  //     });
  //   }, (err: any) => {
  //     this.ui.loaded().subscribe(() => {
  //       this.openSnackBarWarning('Atenção', err.error);
  //     })
  //   });
  // }

  // exportarLGPD(o) {
  //   this.ui.loading();
  //   this.pesquisaService.exportarLGPD({ id: o.id }, "cadastrais").subscribe(data => {
  //     this.ui.loaded().subscribe(() => {
  //       var a = document.createElement("a");
  //       a.href = "data:application/zip;base64," + data.arquivoConteudo;
  //       a.download = data.arquivoNome;
  //       a.click();
  //     });
  //   }, (err: any) => {
  //     this.ui.loaded().subscribe(() => {
  //       this.openSnackBarWarning('Atenção', err.error);
  //     })
  //   });
  // }

  // exportarReciboClicked(o: { pesquisa: { id: any; }; }) {
  //   this.ui.loading();
  //   this.pesquisaService.exportarRecibo({ id: o.pesquisa.id }, "cadastrais").subscribe(data => {
  //     this.ui.loaded().subscribe(() => {
  //       var a = document.createElement("a");
  //       a.href = "data:application/pdf;base64," + data.arquivoConteudo;
  //       a.download = data.arquivoNome;
  //       a.click();
  //     });
  //   }, (err: any) => {
  //     this.ui.loaded().subscribe(() => {
  //       this.openSnackBarWarning('Atenção', err.error);
  //     })
  //   });
  // }

  // exportarExcelClicked(o: { pesquisa: { id: any; }; }) {
  //   this.ui.loading();
  //   this.pesquisaService.exportarExcel({ id: o.pesquisa.id }, "cadastrais").subscribe(data => {
  //     this.ui.loaded().subscribe(() => {
  //       var a = document.createElement("a");
  //       a.href = "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," + data.arquivoConteudo;
  //       a.download = data.arquivoNome;
  //       a.click();
  //     });
  //   }, (err: any) => {
  //     this.ui.loaded().subscribe(() => {
  //       this.openSnackBarWarning('Atenção', err.error);
  //     })
  //   });
  // }

  // visualizarClicked(o: { pesquisa: { id: any; }; }) {
  //   this.toastr.warning("Em desenvolvimento", "Portal Credvalue")
  // }

  // confirmarPesquisaClicked() {
  //   this.isDisabled = true;

  //   //if(this.adicionaisToRequest == undefined || this.adicionaisToRequest.length == 0){
  //   this.calculaValorAdicionaParametro();

  //   if(this.valorAdicionalParametro == 0){
  //       if (this.formPesquisa.numero) {
  //       this.formPesquisa.numero = this.formPesquisa.numero.replaceAll('.', '').replaceAll('-', '').replaceAll('/', '');
  //     }
  //     this.ui.loading()
  //     this.utilService.resolverNome(this.formPesquisa, "cadastrais").subscribe(resultadoResolverNome => {
  //       if (!resultadoResolverNome || !resultadoResolverNome.nome) {
  //         this.ui.loaded().subscribe(() => {
  //           this.formPesquisa.nome = "";
  //           this.toastr.warning(Textos.NOME_NAO_ENCONTRADO, "Portal Credvalue")
  //           this.isDisabled = false;
  //         });
  //         return;
  //       }
  //       if (this.parametrosToRequest) {
  //         if(typeof this.formParametros === 'object' && Object.keys(this.formParametros).length > 0){
  //           this.parametrosToRequest = { ...this.parametrosToRequest, ...this.formParametros };
  //         }
  //         else{
  //           this.parametrosToRequest = {}
  //         }
  //         let objValorAdicional = {
  //           filtros: {},
  //           parametros: this.parametrosToRequest,
  //           fontesAdicionais: this.adicionaisToRequest.map(x => x.fonte)
  //         }
  //         console.log(this.parametrosToRequest)
  //         this.formPesquisa.valorAdicional = objValorAdicional;
  //         this.formPesquisa.nome = resultadoResolverNome.nome;
  //         this.formFiltro.nome = this.formPesquisa.nome;
  //         // this.formFiltro.idFonteGrupo = this.formPesquisa.idFonteGrupo;
  //         this.formFiltro.numero = this.formPesquisa.numero;
  //         this.formFiltro.valorAdicional = objValorAdicional
  //         this.formPesquisa.tags = this.addedItems;

  //         let requestCount = Object.assign({}, this.formFiltro);
  //         this.pesquisaService.getPesquisaRealizada(requestCount, "patrimonial").subscribe(response => {
  //           if (response.count == 0 || this.refazer || response.list.pesquisa.status == this.StatusPesquisa.Cancelada) {
  //             this.refazer = false;
  //             let request = Object.assign({}, this.formPesquisa);
  //             this.pesquisaService.cadastrarCliente(request).subscribe(data => {
  //               if (HttpStatusCode.Ok) {
  //                 this.ui.loaded().subscribe(() => {
  //                   this.openSnackBar('Sucesso', 'Pesquisa Realizada com Sucesso');
  //                   this.adicionarAba(request.numero, data, true);
  //                   this.getChips();
  //                   this.addedItems = [];
  //                   this.myControl.reset();
  //                   this.isDisabled = false
  //                 })
  //                 this.formPesquisa.valorAdicional = this.adicionaisToRequest
  //               }
  //               this.pesquisarClicked(1);
  //             });
  //           } else {
  //             this.ui.loaded().subscribe(() => {
  //               this.formPesquisa.nome = "";
  //               //this.toastr.warning(Textos.NOME_DUPLICADA, "Portal Credvalue")
  //               this.openDialog(response.list)
  //               this.isDisabled = false
  //             });
  //             return;
  //           }
  //         });
  //       }

  //       else {
  //         this.formPesquisa.nome = resultadoResolverNome.nome;
  //         this.formFiltro.nome = this.formPesquisa.nome;
  //         // this.formFiltro.idFonteGrupo = this.formPesquisa.idFonteGrupo;
  //         this.formFiltro.numero = this.formPesquisa.numero;
  //         this.formPesquisa.tags = this.addedItems;

  //         let requestCount = Object.assign({}, this.formFiltro);
  //         this.pesquisaService.getPesquisaRealizada(requestCount, "patrimonial").subscribe(response => {
  //           if (response.count == 0 || this.refazer || response.list.pesquisa.status == this.StatusPesquisa.Cancelada) {
  //             this.refazer = false;
  //             let request = Object.assign({}, this.formPesquisa);
  //             this.pesquisaService.cadastrarCliente(request).subscribe(data => {
  //               if (HttpStatusCode.Ok) {
  //                 this.ui.loaded().subscribe(() => {
  //                   this.openSnackBar('Sucesso', 'Pesquisa Realizada com Sucesso');
  //                   this.adicionarAba(request.numero, data, true);
  //                   this.getChips();
  //                   this.addedItems = [];
  //                   this.myControl.reset();
  //                   this.isDisabled = false
  //                 })
  //                 this.formPesquisa.valorAdicional = this.adicionaisToRequest
  //               }
  //               this.pesquisarClicked(1);
  //             });
  //           } else {
  //             this.ui.loaded().subscribe(() => {
  //               this.formPesquisa.nome = "";
  //               //this.toastr.warning(Textos.NOME_DUPLICADA, "Portal Credvalue")
  //               this.openDialog(response.list)
  //               this.isDisabled = false
  //             });
  //             return;
  //           }
  //         });
  //       }
  //     }, (err: any) => {
  //       this.ui.loaded().subscribe(() => {
  //         this.openSnackBarWarning('Atenção', err.error);
  //         this.isDisabled = false
  //       })
  //     });
  //   }
  //   //if (this.parametrosToRequest.length > 0) {
  //   if (this.valorAdicionalParametro > 0) {
  //     const dialog = this.dialog.open(ModalValoresAdicionaisComponent, {
  //       width: '550px',
  //       panelClass: 'bg-color',
  //       data: {valorAdicionalTotal: this.valorAdicionalParametro}
  //     });
  //     dialog.afterClosed().subscribe(result => {
  //       if (result == 'realizarPesquisa') {
  //         this.ui.loading();
  //         if (this.formPesquisa.numero) {
  //           this.formPesquisa.numero = this.formPesquisa.numero.replaceAll('.', '').replaceAll('-', '').replaceAll('/', '');
  //         }

  //         this.utilService.resolverNome(this.formPesquisa, "patrimonial").subscribe(resultadoResolverNome => {
  //           if (!resultadoResolverNome || !resultadoResolverNome.nome) {
  //             this.ui.loaded().subscribe(() => {
  //               this.formPesquisa.nome = "";
  //               this.toastr.warning(Textos.NOME_NAO_ENCONTRADO, "Portal Credvalue")
  //               this.isDisabled = false
  //             });
  //             return;
  //           }
  //           if (this.adicionaisToRequest) {
  //             console.log(this.parametrosToRequest)
  //             if(this.parametrosToRequest.length == 0){
  //               this.parametrosToRequest = {}
  //             }
  //             console.log(this.parametrosToRequest)
  //             let objValorAdicional = {
  //               filtros: {},
  //               parametros: this.parametrosToRequest,
  //               fontesAdicionais: this.adicionaisToRequest.map(x => x.fonte)
  //             }

  //             this.formPesquisa.valorAdicional = objValorAdicional;
  //             this.formPesquisa.nome = resultadoResolverNome.nome;
  //             this.formFiltro.nome = this.formPesquisa.nome;
  //             // this.formFiltro.idFonteGrupo = this.formPesquisa.idFonteGrupo;
  //             this.formFiltro.numero = this.formPesquisa.numero;
  //             this.formFiltro.valorAdicional = objValorAdicional;
  //             this.formPesquisa.tags = this.addedItems;

  //             let requestCount = Object.assign({}, this.formFiltro);
  //             this.pesquisaService.getPesquisaRealizada(requestCount, "patrimonial").subscribe(response => {
  //               if (response.count == 0 || this.refazer || response.list.pesquisa.status == this.StatusPesquisa.Cancelada) {
  //                 this.refazer = false;
  //                 let request = Object.assign({}, this.formPesquisa);
  //                 this.pesquisaService.cadastrarCliente(request).subscribe(data => {
  //                   if (HttpStatusCode.Ok) {
  //                     this.ui.loaded().subscribe(() => {
  //                       this.openSnackBar('Sucesso', 'Pesquisa Realizada com Sucesso');
  //                       this.adicionarAba(request.numero, data, true);
  //                       this.getChips();
  //                       this.addedItems = [];
  //                       this.myControl.reset();
  //                       this.isDisabled = false;
  //                     })
  //                     this.formPesquisa.valorAdicional = this.adicionaisToRequest
  //                   }
  //                   this.pesquisarClicked(1);
  //                 });
  //               } else {
  //                 this.ui.loaded().subscribe(() => {
  //                   this.formPesquisa.nome = "";
  //                   //this.toastr.warning(Textos.NOME_DUPLICADA, "Portal Credvalue")
  //                   this.openDialog(response.list)
  //                   this.isDisabled = false
  //                 });
  //                 return;
  //               }
  //             });
  //           }

  //           else {
  //             this.formPesquisa.nome = resultadoResolverNome.nome;
  //             this.formFiltro.nome = this.formPesquisa.nome;
  //             // this.formFiltro.idFonteGrupo = this.formPesquisa.idFonteGrupo;
  //             this.formFiltro.numero = this.formPesquisa.numero;
  //             this.formPesquisa.tags = this.addedItems;

  //             let requestCount = Object.assign({}, this.formFiltro);
  //             this.pesquisaService.getPesquisaRealizada(requestCount, "patrimonial").subscribe(response => {
  //               if (response.count == 0 || this.refazer || response.list.pesquisa.status == this.StatusPesquisa.Cancelada) {
  //                 this.refazer = false;
  //                 let request = Object.assign({}, this.formPesquisa);
  //                 this.pesquisaService.cadastrarCliente(request).subscribe(data => {
  //                   if (HttpStatusCode.Ok) {
  //                     this.ui.loaded().subscribe(() => {
  //                       this.openSnackBar('Sucesso', 'Pesquisa Realizada com Sucesso');
  //                       this.adicionarAba(request.numero, data, true);
  //                       this.getChips();
  //                       this.addedItems = [];
  //                       this.myControl.reset();
  //                       this.isDisabled = false
  //                     })
  //                     this.formPesquisa.valorAdicional = this.adicionaisToRequest
  //                   }
  //                   this.pesquisarClicked(1);
  //                 });
  //               } else {
  //                 this.ui.loaded().subscribe(() => {
  //                   this.formPesquisa.nome = "";
  //                   //this.toastr.warning(Textos.NOME_DUPLICADA, "Portal Credvalue")
  //                   this.openDialog(response.list)
  //                   this.isDisabled = false
  //                 });
  //                 return;
  //               }
  //             });
  //           }
  //         }, (err: any) => {
  //           this.ui.loaded().subscribe(() => {
  //             this.openSnackBarWarning('Atenção', err.error);
  //             this.isDisabled = false
  //           })
  //         });
  //       }
  //       if(result == 'cancelar'){
  //         this.formPesquisa.valorAdicional = this.adicionaisToRequest
  //       }
  //     });
  //   }
  //   this.isDisabled = false
  // }

  // calculaValorAdicionaParametro() {
  //   this.valorAdicionalParametro = 0;
  //   for (const checkbox of this.listaConfiguracoesParametros) {
  //     if (checkbox.selecionado) {
  //       let descricaoParametro = checkbox.descricao;
  //       for (const obj of this.listaConfiguracoesParametros) {
  //         if (obj.descricao == descricaoParametro) {
  //           let valor = obj.precoPJ
  //           this.valorAdicionalParametro += valor;
  //         }
  //       }
  //     }
  //   }
  // }

  // openDialog(pespAnterior): void {

  //   let dialogRef = this.dialog.open(PopPesqRealizadaComponent, {
  //     data: pespAnterior
  //   });

  //   dialogRef.afterClosed().subscribe(result => {
  //     if (result.data == true) {
  //       this.refazer = true;
  //       this.confirmarPesquisaClicked();
  //     }
  //   });
  // }

  // openSnackBar(titulo: string, mensagem: string) {
  //   this._snackBar.openFromComponent(MensageResponseComponent, {
  //     data: {
  //       titulo: titulo,
  //       mensagem: mensagem,
  //     },
  //     duration: 5000,
  //     horizontalPosition: 'right',
  //     verticalPosition: 'top'
  //   });
  // }

  // openSnackBarWarning(titulo: string, mensagem: string) {
  //   this._snackBar.openFromComponent(MensageErrorResponseComponent, {
  //     data: {
  //       titulo: titulo,
  //       mensagem: mensagem,
  //     },
  //     duration: 5000,
  //     horizontalPosition: 'right',
  //     verticalPosition: 'top'
  //   });
  // }

  // clearForm() {
  //   this.formFiltro.nome = '';
  //   this.formFiltro.numero = '';
  //   this.formFiltro.status = '';
  //   this.formFiltro.tag = '';
  //   this.campaignOne.get('start').setValue(null);
  //   this.campaignOne.get('end').setValue(null);
  //   this.campaignTwo.get('start').setValue(null);
  //   this.campaignTwo.get('end').setValue(null);
  // }

  // onFonteValue(event) {
  //   var grupoSelecionado = this.listaFonteGrupo.find(f => f.fonteGrupo.id == event.value);
  //   this.listaFontesAdicionais = grupoSelecionado
  //   this.listaConfiguracoesParametros = this.listaFontesAdicionais.fonteGrupo.listaFonteGrupoItem.filter(x => x.fonteConfiguracao.configuracaoParametros.length > 0 && x.fonteSelecionavel == false)
  //   this.listaConfiguracoesParametros = this.listaConfiguracoesParametros.map(x => x.fonteConfiguracao.configuracaoParametros[0])
    
  //   this.listaFontesAdicionais = grupoSelecionado.fonteGrupo.listaFonteGrupoItem.filter(x => x.fonteSelecionavel == true)
    
  //   this.listaFontesAdicionaisSemFiltro = this.listaFontesAdicionais.filter(x => x.fonteConfiguracao.categoria == 0)
  //   this.listaFontesAdicionais.sort((a, b) => (a.fonteConfiguracao.nome > b.fonteConfiguracao.nome) ? 1 : -1);
  //   if (grupoSelecionado.fonteGrupo.listaFonteGrupoItem.some(fonteitem => [Fonte.CertidaoDividaUniaoImovelRural].includes(fonteitem.fonte))) {
  //     this.formCadastroValorAdicional = true;
  //     this.formCadastroValorAdicionalLabel = this.tipoLabels.get(Fonte.CertidaoDividaUniaoImovelRural);
  //   } else {
  //     this.formCadastroValorAdicional = false;
  //   }
  // }

  // ajustaNome(nome: string, tamanho: any) {
  //   let nomeRetorno = nome;
  //   if (nome.length >= tamanho) {
  //     nomeRetorno = nome.substring(0, tamanho - 3) + '...';
  //   }
  //   return nomeRetorno;
  // }

  // desabilitaPesquisa() {
  //   if (this.formPesquisa.numero.length === 11 || this.formPesquisa.numero.length === 14) {
  //     return true
  //   }
  //   return false
  // }

  // verificaTipoDocumento() {
  //   let cnpj = 2
  //   let cpf = 1
  //   if (this.formPesquisa.numero.length == 11) {
  //     this.formPesquisa.tipo = cpf
  //   }
  //   else if (this.formPesquisa.numero.length == 14) {
  //     this.formPesquisa.tipo = cnpj
  //   }
  // }

  // adicionarAba(documento, idPesquisa, abaPesquisa) {
  //   const abaExistente = this.abas.find(aba => aba.id === idPesquisa);

  // if (abaExistente) {
  //   if (this.selectedIndex !== this.abas.indexOf(abaExistente)) {
  //     console.log(`A aba com idPesquisa ${idPesquisa} já existe.`);
  //     // Aqui você pode decidir se deseja destacar a aba existente ou realizar outra ação.
  //   }
  // } else {
  //   const novaAbaTitulo = `${documento}`;
  //   this.abas.push({ titulo: novaAbaTitulo, id: idPesquisa, novaPesquisa:abaPesquisa });
  //   this.selectedIndex = this.abas.length - 1;
  // }
  // }

  // fecharAba(index: number) {
  //   this.abas.splice(index, 1);
  // }

  // atualizarIndiceAbaSelecionada(event: any) {
  //   this.selectedIndex = this.tabGroup.selectedIndex;
  // }

}
