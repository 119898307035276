
export enum OrdemProduto {
    sinteseCadastral = 1,
    dadosCadastraisCompleto = 2,
    sinteseProcesso = 3,
    patrimonialCompleto = 4,
    sinteseCredito = 5,
    creditoCompleto = 6,
    midiasNegativas = 9,
    detech = 8,
    escrituraEProcuracoes = 7,    
    linhaTempoSocios = 10,
    esg = 11
    // CadastroExpulsoesAdminstracaoFederal = 11,
    // CadastroEntidadesSemFinsLucrativos = 12,
    // AutoInfracoesTrabalhistas = 13,
    // AcordosLeniencia = 14,
    // TrabalhoEscravo = 15,
    // IbamaAreasEmbargadas = 16,
    // CadastroEmpresasPunidas = 17,
    // DiarioOficial = 18,
    // CadastroEmpresasInidoneas = 19
}