<div class="header text-center d-flex justify-content-between">
    <div></div>
    <h2>Anexar Passivos</h2>
    <button mat-icon-button aria-label="close dialog" mat-dialog-close style="margin-top: -4px;">
        <mat-icon>close</mat-icon>
    </button>
</div>
<div class="container mt-5">

    <section class="d-flex justify-content-end flex-column align-items-end mt-3 buttons-anexo">
        <div class="mb-4">
            <p class="text-grey" id="text"> Baixe a Planilha em Excel de modelo para preenchimento <a matTooltip="Clique e baixe a planilha modelo" class="download-planilha" href="../../../../../assets/modelos-excel/Modelo-importacao-Passivos.xlsx" download>Download Planilha</a></p>
        </div>
        <div>
            <p class="text-grey d-flex align-items-center" id="text"> Adicione o arquivo no formato XLSX  
                <label *ngIf="!anexandoArquivo" class="gs-botaoAzulPadrao" matTooltip="Clique e anexe a planilha preenchida">
                    <input type="file" (change)="handleUpload($event)"  accept=".xlsx">
                    Anexar Arquivo
                </label>
                
                <label *ngIf="anexandoArquivo" class="gs-botaoAzulPadrao d-flex" matTooltip="Clique e anexe a planilha preenchida">
                    <input class="input">
                    <div class="loader dot-space">
                        <span class="loader__dot">.</span><span class="loader__dot">.</span><span class="loader__dot">.</span>
                    </div> Lendo Arquivo
                </label>
            </p>
        </div>
    </section>

    <section *ngIf="dataTable.length != 0" style="margin-top: -30px;">
        <div class="informacoesAtivos row">
            <div class=" col-6">
                <p class="text-grey info-ativos ">Acordo Judicial: <span>{{dataTable.AcordoJudicial}}</span></p>
                <p class="text-grey info-ativos ">Divida Fiscal: <span>{{dataTable.DividaFiscal}}</span></p>
                <p class="text-grey info-ativos ">Divida Trabalhista: <span>{{dataTable.DividaTrabalhista}}</span></p>
                <p class="text-grey info-ativos ">Outras Dividas: <span>{{dataTable.OutrasDividas}}</span></p>
            </div>
        </div>

      <div class="row" style="margin-top: -24px;">
        <div class="col d-flex justify-content-between totalItens">
            <p class="total">Total de Passivos: <span>{{dataTable.TotalAtivos}}  </span></p>
            <button class="gs-botaoAzulPadrao button-iniciar" (click)="incluirPassivos()">Incluir Passivos <mat-icon>search</mat-icon> </button>
        </div>
      </div>
    </section>
</div>