import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AuthService } from 'src/app/shared/services/http/auth.service';
import { UiService } from 'src/app/shared/services/ui.service';

@Component({
  selector: 'app-editar-foto-modal',
  templateUrl: './editar-foto-modal.component.html',
  styleUrls: ['./editar-foto-modal.component.scss']
})
export class EditarFotoModalComponent implements OnInit {

  @ViewChild('imageContainer') imageContainer: ElementRef<HTMLDivElement>;
  imageSrc: string;
  croppedImage: string;
  private isDragging = false;
  private dragStartX: number;
  private dragStartY: number;

  cropX = 50; // Posição X inicial do crop
  cropY = 50; // Posição Y inicial do crop
  cropWidth = 150; // Largura do crop
  cropHeight = 150; // Altura do crop
  zoom = 1; // Fator de zoom
  

  constructor(
    private authService: AuthService,
    public uiService: UiService,
    public dialogRef: MatDialogRef<EditarFotoModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
  }
  
  onFileChange(event: any) {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (e: any) => {
      this.imageSrc = e.target.result;
      this.updateCroppedImage(); // Atualiza a imagem cortada ao carregar a nova imagem
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  }

  startDrag(event: MouseEvent) {
    this.isDragging = true;
    this.dragStartX = event.clientX - this.cropX;
    this.dragStartY = event.clientY - this.cropY;

    document.addEventListener('mousemove', this.dragCropArea.bind(this));
    document.addEventListener('mouseup', this.stopDrag.bind(this));
  }

  dragCropArea(event: MouseEvent) {
    if (this.isDragging) {
      this.cropX = event.clientX - this.dragStartX;
      this.cropY = event.clientY - this.dragStartY;
      this.updateCropArea();
      this.updateCroppedImage(); // Atualiza a imagem cortada enquanto arrasta
    }
  }

  stopDrag() {
    this.isDragging = false;
    document.removeEventListener('mousemove', this.dragCropArea.bind(this));
    document.removeEventListener('mouseup', this.stopDrag.bind(this));
  }

  private updateCroppedImage() {
    if (!this.imageSrc) return;
    this.croppedImage = this.imageSrc;
}

private updateCropArea() {
  const containerRect = this.imageContainer.nativeElement.getBoundingClientRect();
  const scaledWidth = containerRect.width * this.zoom;
  const scaledHeight = containerRect.height * this.zoom;

  if (this.cropX < 0) this.cropX = 0;
  if (this.cropY < 0) this.cropY = 0;
  if (this.cropX + this.cropWidth > scaledWidth) this.cropX = scaledWidth - this.cropWidth;
  if (this.cropY + this.cropHeight > scaledHeight) this.cropY = scaledHeight - this.cropHeight;

  // Limitar a posição do crop para que não ultrapasse os limites da imagem
  this.cropX = Math.max(0, Math.min(this.cropX, scaledWidth - this.cropWidth));
  this.cropY = Math.max(0, Math.min(this.cropY, scaledHeight - this.cropHeight));
}

 onZoom(event: WheelEvent) {
  event.preventDefault();
  
  const zoomFactor = 0.1;
  const previousZoom = this.zoom;

  if (event.deltaY < 0) {
    this.zoom += zoomFactor;
  } else {
    if (this.zoom > 0.5) {
      this.zoom -= zoomFactor;
    }
  }

  // Ajustar posição do crop com base na mudança de zoom
  this.cropX = this.cropX * (this.zoom / previousZoom);
  this.cropY = this.cropY * (this.zoom / previousZoom);
  this.cropWidth = this.cropWidth * (this.zoom / previousZoom);
  this.cropHeight = this.cropHeight * (this.zoom / previousZoom);
  
  this.updateCroppedImage();
}

  saveImage() {
    if (!this.croppedImage) return;
  
    const blob = this.dataURLtoBlob(this.croppedImage);
    const file = new File([blob], 'imagem-cortada.png', { type: 'image/png' });
  
    const formData = new FormData();
    formData.append('file', file);
  
    // Chamar o serviço para enviar a imagem
    this.authService.changeUserPhoto(formData).subscribe(
      (response) => {
        this.dialogRef.close(true);
      },
      (error) => {
        this.uiService.error('Erro ao fazer upload')
      }
    );
  }

  private dataURLtoBlob(dataURL: string): Blob {
    const byteString = atob(dataURL.split(',')[1]);
    const mimeString = dataURL.split(',')[0].split(':')[1].split(';')[0];
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const uint8Array = new Uint8Array(arrayBuffer);
  
    for (let i = 0; i < byteString.length; i++) {
      uint8Array[i] = byteString.charCodeAt(i);
    }
  
    return new Blob([uint8Array], { type: mimeString });
  }

  closeDialog() {
    this.dialogRef.close();
  }

}
