import { Directive, HostListener, ElementRef } from '@angular/core';

@Directive({
  selector: '[appProcessoMask]'
})
export class ProcessoMaskDirective {

  constructor(private el: ElementRef) {}

  @HostListener('input', ['$event']) onInput(event: Event) {
    const input = event.target as HTMLInputElement;
    const valor = input.value.replace(/\D/g, ''); // Remove tudo que não é número

    const valorMascarado = this.aplicarMascara(valor);

    input.value = valorMascarado;
  }

  private aplicarMascara(valor: string): string {
    if (valor.length <= 7) {
      return valor;
    }

    const partes = [
      valor.slice(0, 7),
      valor.slice(7, 9),
      valor.slice(9, 13),
      valor.slice(13, 14),
      valor.slice(14, 16),
      valor.slice(16)
    ];
    return partes.filter(Boolean).join('-').replace(/\.\.+/g, '.');
  }
}