<div class="container">
  <div *ngIf="exibirDadosCadastrais"
    class="row d-flex justify-content-center border-grey align-items-center p-3 info-content">

    <div class="col p-4" *ngIf="aba.novaPesquisa && !isAnaliseCredito">
      <loader-only class="mt-3" *ngIf="isLoadingTelefones"></loader-only>
      <div *ngIf="!isLoadingTelefones">
        <h2 class="title-tab" *ngIf="infoCadastraisTelefone.tipoDocumento == 1">{{infoCadastraisTelefone.dadosCpf.nome}}
        </h2>
        <h2 class="title-tab" *ngIf="infoCadastraisTelefone.tipoDocumento == 2">
          {{infoCadastraisTelefone.dadosCnpj.razaoSocial}}</h2>
        <p class="info-paragraph" *ngIf="infoCadastraisTelefone.tipoDocumento == 1">CPF:
          <span>{{infoCadastraisTelefone.dadosCpf.cpf}}</span></p>
        <p class="info-paragraph" *ngIf="infoCadastraisTelefone.tipoDocumento == 2">CNPJ:
          <span>{{infoCadastraisTelefone.dadosCnpj.cnpj}}</span></p>

        <p class="info-paragraph" *ngIf="infoCadastraisTelefone.tipoDocumento == 1">Nascimento:
          <span>{{infoCadastraisTelefone.dadosCpf.dataNascimento | date: 'dd/MM/yyyy'}}</span></p>
        <p class="info-paragraph" *ngIf="infoCadastraisTelefone.tipoDocumento == 2">CNAE:
          <span>{{infoCadastraisTelefone.dadosCnpj.cnaeFiscal}} - {{infoCadastraisTelefone.dadosCnpj.cnaeNome}}</span>
        </p>

        <p class="info-paragraph" *ngIf="infoCadastraisTelefone.tipoDocumento == 1">Filiação:
          <span>{{infoCadastraisTelefone.dadosCpf.filiacao}}</span></p>
        <p class="info-paragraph" *ngIf="infoCadastraisTelefone.tipoDocumento == 2">Capital Social: <span
            class="capital-social">{{infoCadastraisTelefone.dadosCnpj.capitalSocial}}</span></p>

        <p class="info-paragraph" *ngIf="infoCadastraisTelefone.tipoDocumento == 1">Óbito:
          <span>{{infoCadastraisTelefone.dadosCpf.obitoIndicador}}</span></p>
        <p class="info-paragraph" *ngIf="infoCadastraisTelefone.tipoDocumento == 2">Data de inicio das atividades:
          <span>{{infoCadastraisTelefone.dadosCnpj.dataInicioAtividades}}</span></p>

        <p class="info-paragraph" *ngIf="infoCadastraisTelefone.tipoDocumento == 1">Situação:
          <span>{{infoCadastraisTelefone.dadosCpf.situacaoCpf}}</span></p>
        <p class="info-paragraph" *ngIf="infoCadastraisTelefone.tipoDocumento == 2">Situação:
          <span>{{infoCadastraisTelefone.dadosCnpj.situacaoCadastral}}</span></p>

      </div>
    </div>

    <div class="col p-4" *ngIf="!aba.novaPesquisa">
      <loader-only class="mt-3" *ngIf="isLoadingCadastrais"></loader-only>
      <div *ngIf="!isLoadingCadastrais">
        <h2 class="title-tab" *ngIf="documento.length == 11">{{infoCadastrais.nome}}</h2>
        <h2 class="title-tab" *ngIf="documento.length == 14">{{infoCadastrais.razaoSocial}}</h2>
        <p class="info-paragraph" *ngIf="documento.length == 11">CPF: <span>{{infoCadastrais.cpf}}</span></p>
        <p class="info-paragraph" *ngIf="documento.length == 14">CNPJ: <span>{{infoCadastrais.cnpj}}</span></p>

        <p class="info-paragraph" *ngIf="documento.length == 11">Nascimento: <span>{{infoCadastrais.dataNascimento |
            date: 'dd/MM/yyyy'}}</span></p>
        <p class="info-paragraph" *ngIf="documento.length == 14">CNAE: <span>{{infoCadastrais.cnaeFiscal}} -
            {{infoCadastrais.cnaeNome}}</span></p>

        <p class="info-paragraph" *ngIf="documento.length == 11">Filiação: <span>{{infoCadastrais.filiacao}}</span></p>
        <p class="info-paragraph" *ngIf="documento.length == 14">Capital Social:
          <span>{{infoCadastrais.capitalSocial}}</span></p>

        <p class="info-paragraph" *ngIf="documento.length == 11">Óbito: <span>{{infoCadastrais.obitoIndicador}}</span>
        </p>
        <p class="info-paragraph" *ngIf="documento.length == 14">Data de inicio das atividades:
          <span>{{infoCadastrais.dataInicioAtividades | date: 'dd/MM/yyyy'}}</span></p>

        <p class="info-paragraph" *ngIf="documento.length == 11">Situação: <span>{{infoCadastrais.situacaoCpf}}</span>
        </p>
        <p class="info-paragraph" *ngIf="documento.length == 14">Situação:
          <span>{{infoCadastrais.situacaoCadastral}}</span></p>

      </div>
    </div>

    <div class="col p-4" *ngIf="aba.novaPesquisa && isAnaliseCredito">
      <loader-only class="mt-3" *ngIf="isLoadingCadastrais"></loader-only>
      <div *ngIf="!isLoadingCadastrais">
        <h2 class="title-tab" *ngIf="documento.length == 11">{{infoCadastrais.nome}}</h2>
        <h2 class="title-tab" *ngIf="documento.length == 14">{{infoCadastrais.razaoSocial}}</h2>
        <p class="info-paragraph" *ngIf="documento.length == 11">CPF: <span>{{infoCadastrais.cpf}}</span></p>
        <p class="info-paragraph" *ngIf="documento.length == 14">CNPJ: <span>{{infoCadastrais.cnpj}}</span></p>

        <p class="info-paragraph" *ngIf="documento.length == 11">Nascimento: <span>{{infoCadastrais.dataNascimento |
            date: 'dd/MM/yyyy'}}</span></p>
        <p class="info-paragraph" *ngIf="documento.length == 14">CNAE: <span>{{infoCadastrais.cnaeFiscal}} -
            {{infoCadastrais.cnaeNome}}</span></p>

        <p class="info-paragraph" *ngIf="documento.length == 11">Filiação: <span>{{infoCadastrais.filiacao}}</span></p>
        <p class="info-paragraph" *ngIf="documento.length == 14">Capital Social:
          <span>{{infoCadastrais.capitalSocial}}</span></p>

        <p class="info-paragraph" *ngIf="documento.length == 11">Óbito: <span>{{infoCadastrais.obitoIndicador}}</span>
        </p>
        <p class="info-paragraph" *ngIf="documento.length == 14">Data de inicio das atividades:
          <span>{{infoCadastrais.dataInicioAtividades | date: 'dd/MM/yyyy'}}</span></p>

        <p class="info-paragraph" *ngIf="documento.length == 11">Situação: <span>{{infoCadastrais.situacaoCpf}}</span>
        </p>
        <p class="info-paragraph" *ngIf="documento.length == 14">Situação:
          <span>{{infoCadastrais.situacaoCadastral}}</span></p>

      </div>
    </div>
    <div class="col d-flex gaps">
      <div class="content-box">
        <div *ngIf="!aba.novaPesquisa">
          <h4 class="d-flex align-items-center">Telefones &nbsp; <mat-icon> phone</mat-icon></h4>
          <loader-only class="mt-3" *ngIf="isLoadingCadastrais"></loader-only>
          <div class="mt-3 box-scroll" *ngIf="!isLoadingCadastrais && infoCadastrais.telefones?.length > 0">
            <p *ngFor="let telefone of infoCadastrais?.telefones">{{telefone}}</p>
          </div>
          <!-- <br> -->
          <p *ngIf="!isLoadingCadastrais && infoCadastrais.telefones?.length == 0"> Não localizado</p>
        </div>

        <div *ngIf="aba.novaPesquisa && isAnaliseCredito">
          <h4 class="d-flex align-items-center">Telefones &nbsp; <mat-icon> phone</mat-icon></h4>
          <loader-only class="mt-3" *ngIf="isLoadingCadastrais"></loader-only>
          <div class="mt-3 box-scroll" *ngIf="!isLoadingCadastrais && infoCadastrais.telefones?.length > 0">
            <p *ngFor="let telefone of infoCadastrais?.telefone">{{telefone}}</p>
          </div>
          <!-- <br> -->
          <p *ngIf="!isLoadingCadastrais && infoCadastrais.telefones?.length == 0"> Não localizado</p>
        </div>

        <div *ngIf="aba.novaPesquisa && !isAnaliseCredito">
          <h4 class="d-flex align-items-center">Telefones &nbsp; <mat-icon> phone</mat-icon></h4>
          <loader-only class="mt-3" class="mt-3" *ngIf="isLoadingTelefones"></loader-only>
          <div class="mt-3 box-scroll" *ngIf="infoCadastraisTelefone.tipoDocumento == 2  && !isLoadingTelefones">
            <p *ngFor="let telefone of infoCadastraisTelefone.dadosCnpj.telefones">{{telefone}}</p>
            <p *ngIf=" infoCadastraisTelefone.dadosCnpj?.telefones?.length == 0"> Não localizado</p>
          </div>

          <div class="mt-3 box-scroll" *ngIf="infoCadastraisTelefone.tipoDocumento == 1  && !isLoadingTelefones">
            <p *ngFor="let telefone of infoCadastraisTelefone.dadosCpf.telefones">{{telefone}}</p>
            <p *ngIf=" infoCadastraisTelefone.dadosCpf?.telefones?.length == 0"> Não localizado</p>
          </div>
          <!-- <br> -->
        </div>
      </div>


      <div class="content-box">
        <!-- ==== credito===  -->
        <div *ngIf="!aba.novaPesquisa && isAnaliseCredito">
          <h4 class="d-flex align-items-center">E-mails &nbsp; <mat-icon> mail_outline</mat-icon></h4>
          <loader-only class="mt-3" *ngIf="isLoadingCadastrais"></loader-only>
          <div class="mt-3 box-scroll" *ngIf="!isLoadingCadastrais && infoCadastrais.cpf">
            <p *ngIf="infoCadastrais?.emails.length > 0">
              <span>xaxaxa</span>
              <span *ngFor="let email of infoCadastrais?.emails">{{ email }}</span>
            </p>
            <p *ngIf="infoCadastrais?.emails.length == 0">
              <span>Não Localizado</span>
            </p>
          </div>

          <div class="mt-3 box-scroll" *ngIf="!isLoadingCadastrais && infoCadastrais.cnpj">
            <p *ngIf="infoCadastrais?.emails.length > 0">
              <span *ngFor="let email of infoCadastrais?.emails">{{ email }}</span>
            </p>
            <p *ngIf="infoCadastrais?.emails.length == 0">
              <span>Não Localizado</span>
            </p>
          </div>
          <br>
        </div>

        <div *ngIf="aba.novaPesquisa && isAnaliseCredito">
          <h4 class="d-flex align-items-center">E-mails &nbsp; <mat-icon> mail_outline</mat-icon></h4>
          <loader-only class="mt-3" *ngIf="isLoadingCadastrais"></loader-only>
          <div class="mt-3 box-scroll" *ngIf="!isLoadingCadastrais && infoCadastrais.cpf">
            <p *ngIf="infoCadastrais?.emails.length > 0">
              <span *ngFor="let email of infoCadastrais?.emails">{{ email }}</span>
            </p>
            <p *ngIf="infoCadastrais?.emails.length == 0">
              <span>Não Localizado</span>
            </p>
          </div>

          <div class="mt-3 box-scroll" *ngIf="!isLoadingCadastrais && infoCadastrais.cnpj">
            <p *ngIf="infoCadastrais?.emails.length > 0">
              <span *ngFor="let email of infoCadastrais?.emails">{{ email }}</span>
            </p>
            <p *ngIf="infoCadastrais?.emails.length == 0">
              <span>Não Localizado</span>
            </p>
          </div>
          <br>
        </div>

        <!-- === diferente de credito ==== -->
        <div *ngIf="!aba.novaPesquisa && !isAnaliseCredito">
          <h4 class="d-flex align-items-center">E-mails &nbsp; <mat-icon> mail_outline</mat-icon></h4>
          <loader-only class="mt-3" *ngIf="isLoadingCadastrais"></loader-only>
          <div class="mt-3 box-scroll" *ngIf="!isLoadingCadastrais && infoCadastrais?.cpf">
            <p *ngFor="let email of infoCadastrais.emails">{{email ? email : 'Não Localizado'}}</p>
          </div>

          <div class="mt-3 box-scroll" *ngIf="!isLoadingCadastrais && infoCadastrais?.cnpj">
            <p>{{infoCadastrais.correioEletronico}}</p>
            <p *ngFor="let email of infoCadastrais.emails">{{email}}</p>
            <p *ngIf="infoCadastrais.emails.length == 0 && !infoCadastrais.correioEletronico">Não Localizado</p>
          </div>
          <br>
        </div>

        <div *ngIf="aba.novaPesquisa && !isAnaliseCredito">
          <h4 class="d-flex align-items-center">E-mails &nbsp; <mat-icon> mail_outline</mat-icon></h4>
          <loader-only class="mt-3" class="mt-3" *ngIf="isLoadingEmails"></loader-only>
          <div class="mt-3 box-scroll" *ngIf="infoCadastraisEmails.tipoDocumento == 2 && !isLoadingEmails">
            <p> {{infoCadastraisEmails.dadosCnpj.correioEletronico}}</p>
            <p *ngFor="let email of infoCadastraisEmails.dadosCnpj.emails">{{email}}</p>
            <p *ngIf="infoCadastraisEmails.dadosCnpj?.emails?.length == 0 && !infoCadastraisEmails.dadosCnpj.correioEletronico"> Não localizado</p>
          </div>
          
          <div class="mt-3 box-scroll" *ngIf="infoCadastraisEmails.tipoDocumento == 1 && !isLoadingEmails">
            <p *ngFor="let email of infoCadastraisEmails.dadosCpf?.emails"> {{email}}</p>
            <p *ngIf="infoCadastraisEmails.dadosCpf?.emails?.length == 0"> Não localizado</p>
          </div>
          <br>
        </div>
      </div>

      <!-- ================= endereços========================= -->

      <div class="content-box">
        <div *ngIf="!aba.novaPesquisa && !isAnaliseCredito">
          <h4 class="d-flex align-items-center">Endereços &nbsp; <mat-icon> place</mat-icon></h4>
          <loader-only class="mt-3" *ngIf="isLoadingCadastrais"></loader-only>
          <div class="box-scroll" *ngIf="infoCadastrais.cnpj && !isLoadingCadastrais">
            <p>{{infoCadastrais?.logradouro}} {{infoCadastrais?.numero}} {{infoCadastrais?.complemento}} {{infoCadastrais?.bairro}} - {{infoCadastrais?.uf}} </p>
            <div *ngFor="let endereco of infoCadastrais?.enderecos">
              <p>{{endereco?.logradouro}} {{endereco?.numero}} {{endereco?.complemento}} {{endereco?.bairro}} - {{endereco?.uf}}</p>
            </div>
            <p *ngIf="!isLoadingCadastrais && infoCadastrais?.cnpj && !infoCadastrais?.logradouro && infoCadastrais?.enderecos.length == 0"> Não localizado</p>
          </div>

          <div class="box-scroll" *ngIf="infoCadastrais.cpf && !isLoadingCadastrais">
            <div *ngFor="let endereco of infoCadastrais?.enderecos">
              <p>{{endereco?.logradouro}} {{endereco?.numero}} {{endereco?.complemento}} {{endereco?.bairro}} - {{endereco?.uf}}</p>
            </div>
            <p *ngIf="!isLoadingCadastrais && infoCadastrais?.cpf && infoCadastrais?.enderecos.length == 0">Não
              Localizado</p>
          </div>
        </div>

        <div *ngIf="aba.novaPesquisa && !isAnaliseCredito">
          <h4 class="d-flex align-items-center">Endereços &nbsp; <mat-icon> place</mat-icon></h4>
          <loader-only class="mt-3" class="mt-3" *ngIf="isLoadingEnderecos"></loader-only>
          <div class="box-scroll" *ngIf="infoCadastraisEnderecos.tipoDocumento == 2 && !isLoadingEnderecos">
            <p class="mt-3">{{infoCadastraisEnderecos.dadosCnpj?.logradouro}} {{infoCadastraisEnderecos.dadosCnpj?.numero}} {{infoCadastraisEnderecos.dadosCnpj?.complemento}} {{infoCadastraisEnderecos.dadosCnpj?.bairro}} - {{infoCadastraisEnderecos.dadosCnpj?.uf}}</p>
            <div *ngFor="let endereco of infoCadastraisEnderecos.dadosCnpj?.enderecos">
              <p>{{endereco?.logradouro}} {{endereco?.numero}} {{endereco?.complemento}} {{endereco?.bairro}} - {{endereco?.uf}}</p>
            </div>
            <p *ngIf="infoCadastraisEnderecos.dadosCnpj?.enderecos?.length == 0 && !infoCadastraisEnderecos.dadosCnpj?.logradouro"> Não localizado</p>
          </div>

          <div class="box-scroll" *ngIf="infoCadastraisEnderecos.tipoDocumento == 1 && !isLoadingEnderecos">
            <div *ngFor="let endereco of infoCadastraisEnderecos.dadosCpf?.enderecos">
              <p>{{endereco?.logradouro}} {{endereco?.numero}} {{endereco?.complemento}} {{endereco?.bairro}} - {{endereco?.uf}}</p>
            </div>
            <p *ngIf="!isLoadingCadastrais && infoCadastraisEnderecos.dadosCpf?.enderecos.length == 0">Não Localizado</p>
          </div>
        </div>

        <div *ngIf="aba.novaPesquisa && isAnaliseCredito">
          <h4 class="d-flex align-items-center">Endereços &nbsp; <mat-icon> place</mat-icon></h4>
          <loader-only class="mt-3" *ngIf="isLoadingCadastrais"></loader-only>
          <div class="box-scroll" *ngIf="infoCadastrais.cnpj && !isLoadingCadastrais">
            <p>{{infoCadastrais?.logradouro}}, {{infoCadastrais?.numero}} {{infoCadastrais?.complemento}} {{infoCadastrais?.bairro}} -  {{infoCadastrais?.uf}} </p>
            <p *ngIf="!isLoadingCadastrais && infoCadastrais?.cnpj && !infoCadastrais?.logradouro"> Não localizado</p>
          </div>

          <div class="box-scroll" *ngIf="infoCadastrais.cpf && !isLoadingCadastrais">
            <div *ngFor="let endereco of infoCadastrais.enderecos">
              <p>{{endereco?.logradouro}} {{endereco?.numero}} {{endereco?.complemento}} , {{endereco?.bairro}} - {{endereco?.uf}}</p>
            </div>
            <p *ngIf="!isLoadingCadastrais && infoCadastrais?.cpf && infoCadastrais?.enderecos.length == 0">Não Localizado</p>
          </div>
        </div>

        <div *ngIf="!aba.novaPesquisa && isAnaliseCredito">
          <h4 class="d-flex align-items-center">Endereços &nbsp; <mat-icon> place</mat-icon></h4>
          <loader-only class="mt-3" *ngIf="isLoadingCadastrais"></loader-only>
          <div class="box-scroll" *ngIf="infoCadastrais.cnpj && !isLoadingCadastrais">
            <p>{{infoCadastrais?.logradouro}}, {{infoCadastrais?.numero}} {{infoCadastrais?.complemento}} {{infoCadastrais?.bairro}} -  {{infoCadastrais?.uf}} </p>
            <p *ngIf="!isLoadingCadastrais && infoCadastrais?.cnpj && !infoCadastrais?.logradouro"> Não localizado</p>
          </div>

          <div class="box-scroll" *ngIf="infoCadastrais.cpf && !isLoadingCadastrais">
            <div *ngFor="let endereco of infoCadastrais.enderecos">
              <p>{{endereco?.logradouro}} {{endereco?.numero}} {{endereco?.complemento}} , {{endereco?.bairro}} - {{endereco?.uf}}</p>
            </div>
            <p *ngIf="!isLoadingCadastrais && infoCadastrais?.cpf && infoCadastrais?.enderecos.length == 0">Não Localizado</p>
          </div>
        </div>
      </div>

      <!-- ============================================== -->

      <div class="content-box">
        <h4 class="d-flex align-items-center">Mapa &nbsp; <mat-icon> map</mat-icon></h4>
        <div *ngIf="!aba.novaPesquisa">
          <loader-only class="mt-3" *ngIf="isLoadingCadastrais"></loader-only>
          <iframe *ngIf="!isLoadingCadastrais" id="localizacao" width="100%" frameborder="0" scrolling="no"
            marginheight="0" marginwidth="0" [src]="url" class="iframe-mapa"></iframe>
          <br>
        </div>

        <div *ngIf="aba.novaPesquisa && isAnaliseCredito">
          <loader-only class="mt-3" *ngIf="isLoadingCadastrais"></loader-only>
          <iframe *ngIf="!isLoadingCadastrais" id="localizacao" width="100%" frameborder="0" scrolling="no"
            marginheight="0" marginwidth="0" [src]="url" class="iframe-mapa"></iframe>
          <br>
        </div>

        <div *ngIf="aba.novaPesquisa && !isAnaliseCredito">
          <loader-only class="mt-3" *ngIf="isLoadingEnderecos"></loader-only>
          <iframe *ngIf="infoCadastraisEnderecos.tipoDocumento == 2 && !isLoadingEnderecos" id="localizacao"
            width="100%" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" [src]="url"
            class="iframe-mapa"></iframe>

          <iframe *ngIf="infoCadastraisEnderecos.tipoDocumento == 1 && !isLoadingEnderecos" id="localizacao"
            width="100%" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" [src]="url"
            class="iframe-mapa"></iframe>
          <br>
        </div>
      </div>
    </div>
  </div>

  <!-- <app-midias-negativas class="mt-3"></app-midias-negativas>-->

  <!-- <div class="title-content d-flex justify-content-between mt-5 align-items-center"
    *ngIf="isProcessoOuDossie && exibirSinteseProcesso">
    <div></div>
    <button mat-flat-button class="gs-botaoAzulPadrao m-3" (click)="openLinhaTempo()">Linha do Tempo
      <mat-icon>search</mat-icon></button>
  </div> -->


  <div class="border-grey" style="margin: 1.7rem -0.2rem;" *ngIf="isProcessoOuDossie && exibirSinteseProcesso">
    <div class="row d-flex justify-content-evenly mt-4 margin-processos">
      <div class="d-flex justify-content-end" *ngIf="exibeReprocessarSintese">
        <button mat-flat-button class="gs-botaoAzulPadrao m-3" (click)="getSinteseProcessos()">Reprocessar informações de
          processo <mat-icon>cached</mat-icon></button>
      </div>
      <h2 class="title-tab text-center">Informações Processuais</h2>
      <div class="border-grey processo-indisponivel text-center d-flex flex-column justify-content-center p-2 m-2 mb-5" *ngIf="exibeReprocessarSintese">
      <h3>Informações de Processo em andamento...</h3>
      <p>Clique em "Reprocessar informações de processo" para consultar o status</p>
    </div>
    <div class="col border-grey text-center p-2 m-2" [ngClass]="exibeReprocessarSintese ? 'opacity-min' : '' " *ngIf="!exibeReprocessarSintese">
        <p class="title-grey mt-2 mb-5">Processos Estaduais</p>
        <loader-only class="mt-3" *ngIf="isLoadingSintese"></loader-only>
        <div class="row d-flex flex-column" *ngIf="!isLoadingSintese">
          <div class="col">
            <div class="row d-flex content-grey">
              <div class="col-4 border-right">
                <h4 class="title-polo">Passivos</h4>
                <p class="count-polo">{{sintese.qtdEstaduaisPoloPassivo}}</p>
              </div>
              <div class="col-8 d-flex justify-content-center align-items-center">
                <p class="value-polo"> {{sintese.totalEstaduaisValorPoloPassivo | currency:'BRL'}}</p>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="row d-flex content-grey">
              <div class="col-4 border-right">
                <h4 class="title-polo">Ativos</h4>
                <p class="count-polo">{{sintese.qtdEstaduaisPoloAtivo}}</p>
              </div>
              <div class="col-8 d-flex justify-content-center align-items-center">
                <p class="value-polo"> {{sintese.totalEstaduaisValorPoloAtivo | currency:'BRL'}}</p>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="row d-flex content-grey">
              <div class="col-4 border-right">
                <h4 class="title-polo">Outros</h4>
                <p class="count-polo">{{sintese.qtdEstaduaisPoloNaoIdentificado}}</p>
              </div>
              <div class="col-8 d-flex justify-content-center align-items-center">
                <p class="value-polo"> {{sintese.totalEstaduaisValorPoloNaoIdentificado | currency:'BRL'}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col border-grey text-center p-2 m-2" [ngClass]="exibeReprocessarSintese ? 'opacity-min' : '' " *ngIf="!exibeReprocessarSintese">
        <p class="title-grey mt-2 mb-5">Processos Federais</p>
        <loader-only class="mt-3" *ngIf="isLoadingSintese"></loader-only>
        <div class="row d-flex flex-column" *ngIf="!isLoadingSintese">
          <div class="col">
            <div class="row d-flex content-grey">
              <div class="col-4 border-right">
                <h4 class="title-polo">Passivos</h4>
                <p class="count-polo">{{sintese.qtdFederalPoloPassivo}}</p>
              </div>
              <div class="col-8 d-flex justify-content-center align-items-center">
                <p class="value-polo"> {{sintese.totalFederalValorPoloPassivo | currency:'BRL'}}</p>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="row d-flex content-grey">
              <div class="col-4 border-right">
                <h4 class="title-polo">Ativos</h4>
                <p class="count-polo">{{sintese.qtdFederalPoloAtivo}}</p>
              </div>
              <div class="col-8 d-flex justify-content-center align-items-center">
                <p class="value-polo">{{sintese.totalFederalValorPoloAtivo | currency:'BRL'}}</p>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="row d-flex content-grey">
              <div class="col-4 border-right">
                <h4 class="title-polo">Outros</h4>
                <p class="count-polo">{{sintese.qtdFederalPoloNaoIdentificado}}</p>
              </div>
              <div class="col-8 d-flex justify-content-center align-items-center">
                <p class="value-polo">{{sintese.totalFederalValorPoloNaoIdentificado | currency:'BRL'}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col border-grey text-center p-2 m-2" [ngClass]="exibeReprocessarSintese ? 'opacity-min' : '' " *ngIf="!exibeReprocessarSintese">
        <p class="title-grey mt-2 mb-5">Processos Trabalhistas</p>
        <loader-only class="mt-3" *ngIf="isLoadingSintese"></loader-only>
        <div class="row d-flex flex-column" *ngIf="!isLoadingSintese">
          <div class="col">
            <div class="row d-flex content-grey">
              <div class="col-4 border-right">
                <h4 class="title-polo">Passivos</h4>
                <p class="count-polo">{{sintese.qtdTrabalhistasPoloPassivo}}</p>
              </div>
              <div class="col-8 d-flex justify-content-center align-items-center">
                <p class="value-polo"> {{sintese.totalTrabalhistasValorPoloPassivo | currency:'BRL'}}</p>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="row d-flex content-grey">
              <div class="col-4 border-right">
                <h4 class="title-polo">Ativos</h4>
                <p class="count-polo">{{sintese.qtdTrabalhistasPoloAtivo}}</p>
              </div>
              <div class="col-8 d-flex justify-content-center align-items-center">
                <p class="value-polo"> {{sintese.totalTrabalhistasValorPoloAtivo | currency:'BRL'}}</p>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="row d-flex content-grey">
              <div class="col-4 border-right">
                <h4 class="title-polo">Outros</h4>
                <p class="count-polo">{{sintese.qtdTrabalhistasPoloNaoIdentificado}}</p>
              </div>
              <div class="col-8 d-flex justify-content-center align-items-center">
                <p class="value-polo"> {{sintese.totalTrabalhistasValorPoloNaoIdentificado | currency:'BRL'}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <app-linha-tempo [ngClass]="exibeReprocessarSintese ? 'opacity-min' : '' " *ngIf="!exibeReprocessarSintese" [idPesquisa]="idPesquisa"></app-linha-tempo>
  </div>

  <app-analise-credito *ngIf="exibeAnaliseCredito && (isAnaliseCredito || isDossie)" [aba]="aba" class="mt-4"
    (exibeAnaliseCreditoChange)="atualizarExibeAnaliseCredito($event)"></app-analise-credito>

  <div role="button" class="d-flex justify-content-center  align-items-center border-grey-more mt-2 baixar-pdf"
    *ngIf="!isLoadingPdf" [style.background]="getCorPorcentagem(porcentagemPesquisa)" (click)="exportarClicked()">
    <p>Informações completas estão disponíveis em PDF</p>
    <mat-icon class="pdf-icon">save_alt</mat-icon>
  </div>

  <div class="d-flex justify-content-center  align-items-center border-grey-more mt-2 baixar-pdf" *ngIf="isLoadingPdf"
    [style.background]="getCorPorcentagem(porcentagemPesquisa)">
    <p>Informações completas estão disponíveis em PDF</p>
    <div class="d-flex align-items-center">
      <div class="loading">
      </div>
      <span class="percentual-pesquisa"
        *ngIf="porcentagemPesquisa < 100 && statusPesquisa < 4">{{porcentagemPesquisa}}%</span>
    </div>
  </div>
</div>
<div *ngIf="pesquisaExistente">
  <div class="mt-5" *ngIf="isCpf">
    <mat-accordion>
      <mat-expansion-panel class="py-2" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <h3 class="mb-0 d-flex align-items-center">
              <mat-icon> phone</mat-icon> &nbsp; TELEFONES
            </h3>
          </mat-panel-title>
  
        </mat-expansion-panel-header>
        <table class="table">
          <thead class="thead-light">
            <tr class="text-center">
              <th scope="col" class="thead-top-left p-2">Telefone</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngIf="objetoRelatorioCadastral?.telefones?.length > 0; else noDataTelef">
              <tr class="table-info text-center" *ngFor="let telefone of objetoRelatorioCadastral.telefones">
                <td>{{ telefone }}</td>
              </tr>
            </ng-container>
            <ng-template #noDataTelef>
              <tr>
                <td colspan="1" class="text-center">Não há telefones disponíveis</td>
              </tr>
            </ng-template>
          </tbody>
          
        </table>
      </mat-expansion-panel>
    </mat-accordion>
  
    <!--emails-->
    <mat-accordion>
      <mat-expansion-panel class="mt-3 py-2" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <h3 class="mb-0 d-flex align-items-center">
              <mat-icon> email</mat-icon> &nbsp;
              ENDEREÇOS ELETRÔNICO
            </h3>
          </mat-panel-title>
  
        </mat-expansion-panel-header>
        <table class="table">
          <thead class="thead-light">
            <tr class="text-center">
              <th scope="col" class="thead-top-only p-2">E-mail</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngIf="objetoRelatorioCadastral?.emails?.length > 0; else noDataMail">
              <tr class="table-info text-center" *ngFor="let email of objetoRelatorioCadastral.emails">
                <td>{{ email }}</td>
              </tr>
            </ng-container>
            <ng-template #noDataMail>
              <tr>
                <td colspan="1" class="text-center">Não há emails disponíveis</td>
              </tr>
            </ng-template>
          </tbody>
          
        </table>
      </mat-expansion-panel>
    </mat-accordion>
  
    <!--enderecos-->
    <mat-accordion>
      <mat-expansion-panel class="mt-3 py-2" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <h3 class="mb-0 d-flex align-items-center">
              <mat-icon>home</mat-icon>&nbsp;
              ENDEREÇOS
            </h3>
          </mat-panel-title>
  
        </mat-expansion-panel-header>
        <table class="table">
          <thead class="thead-light">
            <tr class="text-center">
              <th scope="col" class="thead-top-left p-2">Logradouro</th>
              <th scope="col" class="thead p-2">Numero</th>
              <th scope="col" class="thead p-2">Compl.</th>
              <th scope="col" class="thead p-2">Bairro</th>
              <th scope="col" class="thead p-2">Cidade</th>
              <th scope="col" class="thead p-2">UF</th>
              <th scope="col" class="thead-top-right p-2">CEP</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngIf="objetoRelatorioCadastral?.enderecos?.length > 0; else noDataEndere">
              <tr class="text-center table-info" *ngFor="let endereco of objetoRelatorioCadastral.enderecos">
                <td>{{ endereco?.logradouro }}</td>
                <td>{{ endereco?.numero }}</td>
                <td>{{ endereco?.complemento }}</td>
                <td>{{ endereco?.bairro }}</td>
                <td>{{ endereco?.cidade }}</td>
                <td>{{ endereco?.uf }}</td>
                <td>{{ endereco?.cep }}</td>
              </tr>
            </ng-container>
            <ng-template #noDataEndere>
              <tr>
                <td colspan="7" class="text-center">Não há endereços disponíveis</td>
              </tr>
            </ng-template>
          </tbody>
        </table>
      </mat-expansion-panel>
    </mat-accordion>
  
    <!-- <mat-accordion>
      <mat-expansion-panel class="mt-3 py-2" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <h3 class="mb-0 d-flex align-items-center">
              <mat-icon>place</mat-icon>&nbsp;
              LOCALIZAÇÃO
            </h3>
          </mat-panel-title>
  
        </mat-expansion-panel-header>
        <table class="table">
          <thead class="thead-light">
            <tr class="text-center">
              <th scope="col" class="thead-top-left p-2">Mapa</th>
              <th scope="col" class="thead-top-right p-2">Fachada</th>
            </tr>
          </thead>
          <tbody>
            <tr class="text-center table-info">
              <td class="col-6">
                <iframe id="localizacao" width="100%" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"
                  src="https://www.google.com/maps/embed/v1/place?q=$emilio+Ribas+cambui+campinas&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8"
                  class="iframe-mapa"></iframe>
              </td>
              <td class="col-6">
                <img
                  src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQK25fu0jhvg593G8sY9Dog94rpouNMWbU7ipxiGRK0B9KW9qCiT_tofSneyE2Ixs__1UE&usqp=CAU"
                  alt="">
              </td>
            </tr>
          </tbody>
        </table>
      </mat-expansion-panel>
    </mat-accordion> -->
  
  
    <!--pessoas vinculadas-->
    <mat-accordion>
      <mat-expansion-panel class="mt-3 py-2" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <h3 class="mb-0 d-flex align-items-center">
              <mat-icon>person</mat-icon>&nbsp;
              PESSOAS VINCULADAS
            </h3>
          </mat-panel-title>
  
        </mat-expansion-panel-header>
        <table class="table">
          <thead class="thead-light">
            <tr class="text-center">
              <th scope="col" class="thead-top-left p-2">Nome</th>
              <th scope="col" class="thead p-2">CPFCNPJ</th>
              <th scope="col" class="thead-top-right p-2">Vínculo</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngIf="objetoRelatorioCadastral?.pessoasRelacionadas?.length > 0; else noDataPessoasRe">
              <tr class="text-center table-info" *ngFor="let pessoasRelacionadas of objetoRelatorioCadastral.pessoasRelacionadas">
                <td>{{ pessoasRelacionadas?.nome }}</td>
                <td>{{ pessoasRelacionadas?.cpfCnpj }}</td>
                <td>{{ pessoasRelacionadas?.relacao }}</td>
              </tr>
            </ng-container>
            <ng-template #noDataPessoasRe>
              <tr>
                <td colspan="3" class="text-center">Não há pessoas relacionadas disponíveis</td>
              </tr>
            </ng-template>
          </tbody>
          
        </table>
      </mat-expansion-panel>
    </mat-accordion>
  
    <!--participacoes societarias-->
    <mat-accordion>
      <mat-expansion-panel class="mt-3 py-2" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <h3 class="mb-0 d-flex align-items-center">
              <mat-icon>work</mat-icon>&nbsp;
              PARTICIPAÇÕES SOCIETÁRIAS
            </h3>
          </mat-panel-title>
  
        </mat-expansion-panel-header>
        <table class="table">
          <thead class="thead-light">
            <tr class="text-center">
              <th scope="col" class="thead-top-left p-2">Nome</th>
              <th scope="col" class="thead p-2">CNPJ</th>
              <th scope="col" class="thead p-2">Data Entrada</th>
              <th scope="col" class="thead-top-right p-2">Qualificação</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngIf="objetoRelatorioCadastral?.participacaoEmpresas?.length > 0; else noDataParticipacaoSocietaria">
              <tr class="text-center table-info" *ngFor="let participacaoEmpresas of objetoRelatorioCadastral.participacaoEmpresas">
                <td>{{ participacaoEmpresas?.nome }}</td>
                <td>{{ participacaoEmpresas?.cpfCnpj }}</td>
                <td>{{ participacaoEmpresas?.dataEntrada | date: 'dd/MM/yyyy' }}</td>
                <td>{{ participacaoEmpresas?.qualificacao }}</td>
              </tr>
            </ng-container>
            <ng-template #noDataParticipacaoSocietaria>
              <tr>
                <td colspan="4" class="text-center">Não há participações em empresas disponíveis</td>
              </tr>
            </ng-template>
          </tbody>
        </table>
      </mat-expansion-panel>
    </mat-accordion>
  
    <!--negativos-->
    <!-- <mat-accordion>
      <mat-expansion-panel class="mt-3 py-2" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <h3 class="mb-0 d-flex align-items-center">
              FONTES COM RESULTADOS NEGATIVOS
            </h3>
          </mat-panel-title>
  
        </mat-expansion-panel-header>
        <table class="table">
          <thead class="thead-light">
            <tr class="text-center">
              <th scope="col" class="thead-top-only p-2">Fontes</th>
            </tr>
          </thead>
          <tbody>
            <tr class="table-info text-center">
              <td> luana.mariah@hotmail.com </td>
            </tr>
            <tr class="table-info text-center">
              <td> Nenhum Resultado </td>
            </tr>
          </tbody>
        </table>
      </mat-expansion-panel>
    </mat-accordion>
  
    falhas
    <mat-accordion>
      <mat-expansion-panel class="mt-3 py-2" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <h3 class="mb-0 d-flex align-items-center">
              FONTES QUE APRESENTARAM FALHAS NA CONSULTA
            </h3>
          </mat-panel-title>
  
        </mat-expansion-panel-header>
        <table class="table">
          <thead class="thead-light">
            <tr class="text-center">
              <th scope="col" class="thead-top-only p-2">Fontes</th>
            </tr>
            <tr class="table-info text-center">
              <td> Nenhum Resultado </td>
            </tr>
          </thead>
          <tbody>
            <tr class="table-info text-center">
              <td> luana.mariah@hotmail.com </td>
            </tr>
          </tbody>
        </table>
      </mat-expansion-panel>
    </mat-accordion>
  
    comarcas falhas
    <mat-accordion>
      <mat-expansion-panel class="mt-3 py-2" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <h3 class="mb-0 d-flex align-items-center">
              FONTES QUE APRESENTARAM FALHAS DE COMARCAS NA CONSULTAS
            </h3>
          </mat-panel-title>
  
        </mat-expansion-panel-header>
        <table class="table">
          <thead class="thead-light">
            <tr class="text-center">
              <th scope="col" class="thead-top-left p-2">Fontes</th>
              <th scope="col" class="thead-top-right p-2">Comarcas</th>
            </tr>
          </thead>
          <tbody>
            <tr class="table-info text-center">
              <td> luana.mariah@hotmail.com </td>
              <td> luana.mariah@hotmail.com </td>
            </tr>
            <tr class="table-info text-center">
              <td> Nenhum Resultado </td>
              <td> Nenhum Resultado </td>
            </tr>
          </tbody>
        </table>
      </mat-expansion-panel>
    </mat-accordion> -->
  </div>
  
  <div class="mt-5" *ngIf="isCnpj">
    <!--Socios-->
    <mat-accordion>
      <mat-expansion-panel class="mt-3 py-2" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <h3 class="mb-0 d-flex align-items-center">
              <mat-icon>person</mat-icon>&nbsp;
              SOCIOS - RECEITA FEDERAL (QSA)
            </h3>
          </mat-panel-title>
  
        </mat-expansion-panel-header>
        <table class="table">
          <thead class="thead-light">
            <tr class="text-center">
              <th scope="col" class="thead-top-left p-2">Nome</th>
              <th scope="col" class="thead-top-right p-2">Qualificação</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngIf="objetoRelatorioCadastral?.listaSocioRF?.length > 0; else noDataRF">
              <tr class="text-center table-info" *ngFor="let socioRF of objetoRelatorioCadastral.listaSocioRF">
                <td>{{ socioRF?.nome }}</td>
                <td>{{ socioRF?.qualificacao }}</td>
              </tr>
            </ng-container>
            <ng-template #noDataRF>
              <tr>
                <td colspan="2" class="text-center">Não há sócios disponíveis</td>
              </tr>
            </ng-template>
          </tbody>
        </table>
      </mat-expansion-panel>
    </mat-accordion>
  
    <mat-accordion>
      <mat-expansion-panel class="mt-3 py-2" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <h3 class="mb-0 d-flex align-items-center">
              <mat-icon>person</mat-icon>&nbsp;
              SOCIOS
            </h3>
          </mat-panel-title>
  
        </mat-expansion-panel-header>
        <table class="table">
          <thead class="thead-light">
            <tr class="text-center">
              <th scope="col" class="thead-top-left p-2">Nome</th>
              <th scope="col" class="thead p-2">CPF/CNPJ</th>
              <th scope="col" class="thead p-2">Data Entrada</th>
              <th scope="col" class="thead-top-right p-2">Qualificação</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngIf="objetoRelatorioCadastral?.listaSocio?.length > 0; else noDataSocios">
              <tr class="text-center table-info" *ngFor="let socio of objetoRelatorioCadastral.listaSocio">
                <td>{{ socio?.nome }}</td>
                <td>{{ socio?.cpfCnpj }}</td>
                <td>{{ socio?.dataEntradaSociedade }}</td>
                <td>{{ socio?.qualificacao }}</td>
              </tr>
            </ng-container>
            <ng-template #noDataSocios>
              <tr>
                <td colspan="4" class="text-center">Não há sócios disponíveis</td>
              </tr>
            </ng-template>
          </tbody>        
        </table>
      </mat-expansion-panel>
    </mat-accordion>
  
    <!-- telefones -->
    <mat-accordion>
      <mat-expansion-panel class="mt-3 py-2" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <h3 class="mb-0 d-flex align-items-center">
              <mat-icon> phone</mat-icon> &nbsp; TELEFONES
            </h3>
          </mat-panel-title>
  
        </mat-expansion-panel-header>
        <table class="table">
          <thead class="thead-light">
            <tr class="text-center">
              <th scope="col" class="thead-top-only p-2">Telefone</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngIf="objetoRelatorioCadastral?.telefones?.length > 0; else noDataTel">
              <tr class="table-info text-center" *ngFor="let telefone of objetoRelatorioCadastral.telefones">
                <td>{{ telefone }}</td>
              </tr>
            </ng-container>
            <ng-template #noDataTel>
              <tr>
                <td colspan="1" class="text-center">Não há telefones disponíveis</td>
              </tr>
            </ng-template>
          </tbody>
        </table>
      </mat-expansion-panel>
    </mat-accordion>
  
    <!--enderecos-->
    <mat-accordion>
      <mat-expansion-panel class="mt-3 py-2" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <h3 class="mb-0 d-flex align-items-center">
              <mat-icon>home</mat-icon>&nbsp;
              ENDEREÇOS
            </h3>
          </mat-panel-title>
  
        </mat-expansion-panel-header>
        <table class="table">
          <thead class="thead-light">
            <tr class="text-center">
              <th scope="col" class="thead-top-left p-2">Logradouro</th>
              <th scope="col" class="thead p-2">Numero</th>
              <th scope="col" class="thead p-2">Compl.</th>
              <th scope="col" class="thead p-2">Bairro</th>
              <th scope="col" class="thead p-2">Cidade</th>
              <th scope="col" class="thead p-2">UF</th>
              <th scope="col" class="thead-top-right p-2">CEP</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngIf="objetoRelatorioCadastral?.logradouro || objetoRelatorioCadastral?.enderecos?.length > 0; else noDataEnde">
              <tr class="text-center table-info" *ngIf="objetoRelatorioCadastral?.logradouro">
                <td>{{ objetoRelatorioCadastral?.logradouro }}</td>
                <td>{{ objetoRelatorioCadastral?.numero }}</td>
                <td>{{ objetoRelatorioCadastral?.complemento }}</td>
                <td>{{ objetoRelatorioCadastral?.bairro }}</td>
                <td>{{ objetoRelatorioCadastral?.cidade }}</td>
                <td>{{ objetoRelatorioCadastral?.uf }}</td>
                <td>{{ objetoRelatorioCadastral?.cep }}</td>
              </tr>

              <tr class="text-center table-info" *ngFor="let endereco of objetoRelatorioCadastral.enderecos">
                <td>{{ endereco?.logradouro }}</td>
                <td>{{ endereco?.numero }}</td>
                <td>{{ endereco?.complemento }}</td>
                <td>{{ endereco?.bairro }}</td>
                <td>{{ endereco?.cidade }}</td>
                <td>{{ endereco?.uf }}</td>
                <td>{{ endereco?.cep }}</td>
              </tr>
            </ng-container>
            <ng-template #noDataEnde>
              <tr>
                <td colspan="7" class="text-center">Não há endereços disponíveis</td>
              </tr>
            </ng-template>
          </tbody>
        </table>
      </mat-expansion-panel>
    </mat-accordion>
  
    <!--emails-->
    <mat-accordion>
      <mat-expansion-panel class="mt-3 py-2" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <h3 class="mb-0 d-flex align-items-center">
              <mat-icon> email</mat-icon> &nbsp;
              ENDEREÇOS ELETRÔNICO
            </h3>
          </mat-panel-title>
  
        </mat-expansion-panel-header>
        <table class="table">
          <thead class="thead-light">
            <tr class="text-center">
              <th scope="col" class="thead-top-only p-2">E-mail</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngIf="objetoRelatorioCadastral?.emails?.length > 0; else noDataEmail">
              <tr class="table-info text-center" *ngFor="let email of objetoRelatorioCadastral.emails">
                <td>{{ email }}</td>
              </tr>
            </ng-container>
            <ng-template #noDataEmail>
              <tr>
                <td colspan="1" class="text-center">Não há e-mails disponíveis</td>
              </tr>
            </ng-template>
          </tbody>        
        </table>
      </mat-expansion-panel>
    </mat-accordion>
  
    <!-- <mat-accordion>
      <mat-expansion-panel class="mt-3 py-2" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <h3 class="mb-0 d-flex align-items-center">
              <mat-icon>place</mat-icon>&nbsp;
              LOCALIZAÇÃO
            </h3>
          </mat-panel-title>
  
        </mat-expansion-panel-header>
        <table class="table">
          <thead class="thead-light">
            <tr class="text-center">
              <th scope="col" class="thead-top-left p-2">Mapa</th>
              <th scope="col" class="thead-top-right p-2">Fachada</th>
            </tr>
          </thead>
          <tbody>
            <tr class="text-center table-info">
              <td class="col-6">
                <iframe id="localizacao"
                width="100%" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://www.google.com/maps/embed/v1/place?q=$emilio+Ribas+cambui+campinas&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8"
                class="iframe-mapa"></iframe>
              </td>
              <td class="col-6">
                <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQK25fu0jhvg593G8sY9Dog94rpouNMWbU7ipxiGRK0B9KW9qCiT_tofSneyE2Ixs__1UE&usqp=CAU" alt="">
              </td>
            </tr>
          </tbody>
        </table>
      </mat-expansion-panel>
    </mat-accordion> -->
  
    <!--participacoes societarias-->
    <mat-accordion>
      <mat-expansion-panel class="mt-3 py-2" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <h3 class="mb-0 d-flex align-items-center">
              <mat-icon>work</mat-icon>&nbsp;
              PARTICIPAÇÕES SOCIETÁRIAS
            </h3>
          </mat-panel-title>
  
        </mat-expansion-panel-header>
        <table class="table">
          <thead class="thead-light">
            <tr class="text-center">
              <th scope="col" class="thead-top-left p-2">Nome</th>
              <th scope="col" class="thead p-2">CNPJ</th>
              <!-- <th scope="col" class="thead p-2">Data Entrada</th> -->
              <th scope="col" class="thead-top-right p-2">Qualificação</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngIf="objetoRelatorioCadastral?.listaParticipacaoSocietaria?.length > 0; else noDataParticipacao">
              <tr class="text-center table-info" *ngFor="let participacaoSocietaria of objetoRelatorioCadastral.listaParticipacaoSocietaria">
                <td>{{ participacaoSocietaria.nome }}</td>
                <td>{{ participacaoSocietaria.cnpj }}</td>
                <td>{{ participacaoSocietaria.tipo }}</td>
                <!-- Certifique-se de substituir 'nome', 'cnpj', 'tipo' pelos nomes corretos das propriedades -->
              </tr>
            </ng-container>
            <ng-template #noDataParticipacao>
              <tr>
                <td colspan="3" class="text-center">Não há dados disponíveis</td>
              </tr>
            </ng-template>
          </tbody>
        </table>
      </mat-expansion-panel>
    </mat-accordion>
  
    <!--negativos-->
    <!-- <mat-accordion>
      <mat-expansion-panel class="mt-3 py-2" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <h3 class="mb-0 d-flex align-items-center">
              FONTES COM RESULTADOS NEGATIVOS
            </h3>
          </mat-panel-title>
  
        </mat-expansion-panel-header>
        <table class="table">
          <thead class="thead-light">
            <tr class="text-center">
              <th scope="col" class="thead-top-only p-2">Fontes</th>
            </tr>
          </thead>
          <tbody>
            <tr class="table-info text-center">
              <td> luana.mariah@hotmail.com </td>
            </tr>
            <tr class="table-info text-center">
              <td> Nenhum Resultado </td>
            </tr>
          </tbody>
        </table>
      </mat-expansion-panel>
    </mat-accordion>
  
    falhas
    <mat-accordion>
      <mat-expansion-panel class="mt-3 py-2" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <h3 class="mb-0 d-flex align-items-center">
              FONTES QUE APRESENTARAM FALHAS NA CONSULTA
            </h3>
          </mat-panel-title>
  
        </mat-expansion-panel-header>
        <table class="table">
          <thead class="thead-light">
            <tr class="text-center">
              <th scope="col" class="thead-top-only p-2">Fontes</th>
            </tr>
            <tr class="table-info text-center">
              <td> Nenhum Resultado </td>
            </tr>
          </thead>
          <tbody>
            <tr class="table-info text-center">
              <td> luana.mariah@hotmail.com </td>
            </tr>
          </tbody>
        </table>
      </mat-expansion-panel>
    </mat-accordion>
  
    comarcas falhas
    <mat-accordion>
      <mat-expansion-panel class="mt-3 py-2" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <h3 class="mb-0 d-flex align-items-center">
              FONTES QUE APRESENTARAM FALHAS DE COMARCAS NA CONSULTAS
            </h3>
          </mat-panel-title>
  
        </mat-expansion-panel-header>
        <table class="table">
          <thead class="thead-light">
            <tr class="text-center">
              <th scope="col" class="thead-top-left p-2">Fontes</th>
              <th scope="col" class="thead-top-right p-2">Comarcas</th>
            </tr>
          </thead>
          <tbody>
            <tr class="table-info text-center">
              <td> luana.mariah@hotmail.com </td>
              <td> luana.mariah@hotmail.com </td>
            </tr>
            <tr class="table-info text-center">
              <td> Nenhum Resultado </td>
              <td> Nenhum Resultado </td>
            </tr>
          </tbody>
        </table>
      </mat-expansion-panel>
    </mat-accordion> -->
  </div>
</div>

<div *ngIf="isNovaPesquisa">
  <div class="mt-5" *ngIf="isCpf">
    <mat-accordion>
      <mat-expansion-panel class="py-2" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <h3 class="mb-0 d-flex align-items-center">
              <mat-icon> phone</mat-icon> &nbsp; TELEFONES
            </h3>
          </mat-panel-title>
  
        </mat-expansion-panel-header>
        <table class="table">
          <thead class="thead-light">
            <tr class="text-center">
              <th scope="col" class="thead-top-left p-2">Telefone</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngIf="objetoNovaPesquisa?.dadosCpf?.telefones.length > 0; else noDataTelef">
              <tr class="table-info text-center" *ngFor="let telefone of objetoNovaPesquisa?.dadosCpf?.telefones">
                <td>{{ telefone }}</td>
              </tr>
            </ng-container>
            <ng-template #noDataTelef>
              <tr>
                <td colspan="1" class="text-center">Não há telefones disponíveis</td>
              </tr>
            </ng-template>
          </tbody>
          
        </table>
      </mat-expansion-panel>
    </mat-accordion>
  
    <!--emails-->
    <mat-accordion>
      <mat-expansion-panel class="mt-3 py-2" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <h3 class="mb-0 d-flex align-items-center">
              <mat-icon> email</mat-icon> &nbsp;
              ENDEREÇOS ELETRÔNICO
            </h3>
          </mat-panel-title>
  
        </mat-expansion-panel-header>
        <table class="table">
          <thead class="thead-light">
            <tr class="text-center">
              <th scope="col" class="thead-top-only p-2">E-mail</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngIf="objetoNovaPesquisa?.dadosCpf?.emails?.length > 0; else noDataMail">
              <tr class="table-info text-center" *ngFor="let email of objetoNovaPesquisa?.dadosCpf?.emails">
                <td>{{ email }}</td>
              </tr>
            </ng-container>
            <ng-template #noDataMail>
              <tr>
                <td colspan="1" class="text-center">Não há emails disponíveis</td>
              </tr>
            </ng-template>
          </tbody>
          
        </table>
      </mat-expansion-panel>
    </mat-accordion>
  
    <!--enderecos-->
    <mat-accordion>
      <mat-expansion-panel class="mt-3 py-2" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <h3 class="mb-0 d-flex align-items-center">
              <mat-icon>home</mat-icon>&nbsp;
              ENDEREÇOS
            </h3>
          </mat-panel-title>
  
        </mat-expansion-panel-header>
        <table class="table">
          <thead class="thead-light">
            <tr class="text-center">
              <th scope="col" class="thead-top-left p-2">Logradouro</th>
              <th scope="col" class="thead p-2">Numero</th>
              <th scope="col" class="thead p-2">Compl.</th>
              <th scope="col" class="thead p-2">Bairro</th>
              <th scope="col" class="thead p-2">Cidade</th>
              <th scope="col" class="thead p-2">UF</th>
              <th scope="col" class="thead-top-right p-2">CEP</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngIf="objetoNovaPesquisa?.dadosCpf?.logradouro || objetoNovaPesquisa?.dadosCpf?.enderecos?.length > 0; else noDataEndere">
              <tr class="text-center table-info" *ngIf="objetoNovaPesquisa?.dadosCpf?.logradouro">
                <td>{{ objetoNovaPesquisa?.dadosCpf?.logradouro }}</td>
                <td>{{ objetoNovaPesquisa?.dadosCpf?.numero }}</td>
                <td>{{ objetoNovaPesquisa?.dadosCpf?.complemento }}</td>
                <td>{{ objetoNovaPesquisa?.dadosCpf?.bairro }}</td>
                <td>{{ objetoNovaPesquisa?.dadosCpf?.cidade }}</td>
                <td>{{ objetoNovaPesquisa?.dadosCpf?.uf }}</td>
                <td>{{ objetoNovaPesquisa?.dadosCpf?.cep }}</td>
              </tr>

              <tr class="text-center table-info" *ngFor="let endereco of objetoNovaPesquisa?.dadosCpf?.enderecos">
                <td>{{ endereco?.logradouro }}</td>
                <td>{{ endereco?.numero }}</td>
                <td>{{ endereco?.complemento }}</td>
                <td>{{ endereco?.bairro }}</td>
                <td>{{ endereco?.cidade }}</td>
                <td>{{ endereco?.uf }}</td>
                <td>{{ endereco?.cep }}</td>
              </tr>
            </ng-container>
            <ng-template #noDataEndere>
              <tr>
                <td colspan="7" class="text-center">Não há endereços disponíveis</td>
              </tr>
            </ng-template>
          </tbody>
        </table>
      </mat-expansion-panel>
    </mat-accordion>
  
    <!-- <mat-accordion>
      <mat-expansion-panel class="mt-3 py-2" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <h3 class="mb-0 d-flex align-items-center">
              <mat-icon>place</mat-icon>&nbsp;
              LOCALIZAÇÃO
            </h3>
          </mat-panel-title>
  
        </mat-expansion-panel-header>
        <table class="table">
          <thead class="thead-light">
            <tr class="text-center">
              <th scope="col" class="thead-top-left p-2">Mapa</th>
              <th scope="col" class="thead-top-right p-2">Fachada</th>
            </tr>
          </thead>
          <tbody>
            <tr class="text-center table-info">
              <td class="col-6">
                <iframe id="localizacao" width="100%" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"
                  src="https://www.google.com/maps/embed/v1/place?q=$emilio+Ribas+cambui+campinas&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8"
                  class="iframe-mapa"></iframe>
              </td>
              <td class="col-6">
                <img
                  src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQK25fu0jhvg593G8sY9Dog94rpouNMWbU7ipxiGRK0B9KW9qCiT_tofSneyE2Ixs__1UE&usqp=CAU"
                  alt="">
              </td>
            </tr>
          </tbody>
        </table>
      </mat-expansion-panel>
    </mat-accordion> -->
  
  
    <!--pessoas vinculadas-->
    <mat-accordion>
      <mat-expansion-panel class="mt-3 py-2" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <h3 class="mb-0 d-flex align-items-center">
              <mat-icon>person</mat-icon>&nbsp;
              PESSOAS VINCULADAS
            </h3>
          </mat-panel-title>
  
        </mat-expansion-panel-header>
        <table class="table">
          <thead class="thead-light">
            <tr class="text-center">
              <th scope="col" class="thead-top-left p-2">Nome</th>
              <th scope="col" class="thead p-2">CPFCNPJ</th>
              <th scope="col" class="thead-top-right p-2">Vínculo</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngIf="objetoNovaPesquisa?.dadosCpf?.pessoasRelacionadas?.length > 0; else noDataPessoasRe">
              <tr class="text-center table-info" *ngFor="let pessoasRelacionadas of objetoNovaPesquisa?.dadosCpf?.pessoasRelacionadas">
                <td>{{ pessoasRelacionadas?.nome }}</td>
                <td>{{ pessoasRelacionadas?.cpfCnpj }}</td>
                <td>{{ pessoasRelacionadas?.relacao }}</td>
              </tr>
            </ng-container>
            <ng-template #noDataPessoasRe>
              <tr>
                <td colspan="3" class="text-center">Não há pessoas relacionadas disponíveis</td>
              </tr>
            </ng-template>
          </tbody>
          
        </table>
      </mat-expansion-panel>
    </mat-accordion>
  
    <!--participacoes societarias-->
    <mat-accordion>
      <mat-expansion-panel class="mt-3 py-2" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <h3 class="mb-0 d-flex align-items-center">
              <mat-icon>work</mat-icon>&nbsp;
              PARTICIPAÇÕES SOCIETÁRIAS
            </h3>
          </mat-panel-title>
  
        </mat-expansion-panel-header>
        <table class="table">
          <thead class="thead-light">
            <tr class="text-center">
              <th scope="col" class="thead-top-left p-2">Nome</th>
              <th scope="col" class="thead p-2">CNPJ</th>
              <th scope="col" class="thead p-2">Data Entrada</th>
              <th scope="col" class="thead-top-right p-2">Qualificação</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngIf="objetoNovaPesquisa?.dadosCpf?.participacaoEmpresas?.length > 0; else noDataParticipacaoSocietaria">
              <tr class="text-center table-info" *ngFor="let participacaoEmpresas of objetoNovaPesquisa?.dadosCpf?.participacaoEmpresas">
                <td>{{ participacaoEmpresas?.nome }}</td>
                <td>{{ participacaoEmpresas?.cpfCnpj }}</td>
                <td>{{ participacaoEmpresas?.dataEntrada | date: 'dd/MM/yyyy' }}</td>
                <td>{{ participacaoEmpresas?.qualificacao }}</td>
              </tr>
            </ng-container>
            <ng-template #noDataParticipacaoSocietaria>
              <tr>
                <td colspan="4" class="text-center">Não há participações em empresas disponíveis</td>
              </tr>
            </ng-template>
          </tbody>
        </table>
      </mat-expansion-panel>
    </mat-accordion>
  
    <!--negativos-->
    <!-- <mat-accordion>
      <mat-expansion-panel class="mt-3 py-2" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <h3 class="mb-0 d-flex align-items-center">
              FONTES COM RESULTADOS NEGATIVOS
            </h3>
          </mat-panel-title>
  
        </mat-expansion-panel-header>
        <table class="table">
          <thead class="thead-light">
            <tr class="text-center">
              <th scope="col" class="thead-top-only p-2">Fontes</th>
            </tr>
          </thead>
          <tbody>
            <tr class="table-info text-center">
              <td> luana.mariah@hotmail.com </td>
            </tr>
            <tr class="table-info text-center">
              <td> Nenhum Resultado </td>
            </tr>
          </tbody>
        </table>
      </mat-expansion-panel>
    </mat-accordion>
  
    falhas
    <mat-accordion>
      <mat-expansion-panel class="mt-3 py-2" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <h3 class="mb-0 d-flex align-items-center">
              FONTES QUE APRESENTARAM FALHAS NA CONSULTA
            </h3>
          </mat-panel-title>
  
        </mat-expansion-panel-header>
        <table class="table">
          <thead class="thead-light">
            <tr class="text-center">
              <th scope="col" class="thead-top-only p-2">Fontes</th>
            </tr>
            <tr class="table-info text-center">
              <td> Nenhum Resultado </td>
            </tr>
          </thead>
          <tbody>
            <tr class="table-info text-center">
              <td> luana.mariah@hotmail.com </td>
            </tr>
          </tbody>
        </table>
      </mat-expansion-panel>
    </mat-accordion>
  
    comarcas falhas
    <mat-accordion>
      <mat-expansion-panel class="mt-3 py-2" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <h3 class="mb-0 d-flex align-items-center">
              FONTES QUE APRESENTARAM FALHAS DE COMARCAS NA CONSULTAS
            </h3>
          </mat-panel-title>
  
        </mat-expansion-panel-header>
        <table class="table">
          <thead class="thead-light">
            <tr class="text-center">
              <th scope="col" class="thead-top-left p-2">Fontes</th>
              <th scope="col" class="thead-top-right p-2">Comarcas</th>
            </tr>
          </thead>
          <tbody>
            <tr class="table-info text-center">
              <td> luana.mariah@hotmail.com </td>
              <td> luana.mariah@hotmail.com </td>
            </tr>
            <tr class="table-info text-center">
              <td> Nenhum Resultado </td>
              <td> Nenhum Resultado </td>
            </tr>
          </tbody>
        </table>
      </mat-expansion-panel>
    </mat-accordion> -->
  </div>
  
  <div class="mt-5" *ngIf="isCnpj">
    <!--Socios-->
    <mat-accordion>
      <mat-expansion-panel class="mt-3 py-2" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <h3 class="mb-0 d-flex align-items-center">
              <mat-icon>person</mat-icon>&nbsp;
              SOCIOS - RECEITA FEDERAL (QSA)
            </h3>
          </mat-panel-title>
  
        </mat-expansion-panel-header>
        <table class="table">
          <thead class="thead-light">
            <tr class="text-center">
              <th scope="col" class="thead-top-left p-2">Nome</th>
              <th scope="col" class="thead-top-right p-2">Qualificação</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngIf="objetoNovaPesquisa?.dadosCnpj?.listaSocioRF?.length > 0; else noDataRF">
              <tr class="text-center table-info" *ngFor="let socioRF of objetoNovaPesquisa?.dadosCnpj?.listaSocioRF">
                <td>{{ socioRF?.nome }}</td>
                <td>{{ socioRF?.qualificacao }}</td>
              </tr>
            </ng-container>
            <ng-template #noDataRF>
              <tr>
                <td colspan="2" class="text-center">Não há sócios disponíveis</td>
              </tr>
            </ng-template>
          </tbody>
        </table>
      </mat-expansion-panel>
    </mat-accordion>
  
    <mat-accordion>
      <mat-expansion-panel class="mt-3 py-2" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <h3 class="mb-0 d-flex align-items-center">
              <mat-icon>person</mat-icon>&nbsp;
              SOCIOS
            </h3>
          </mat-panel-title>
  
        </mat-expansion-panel-header>
        <table class="table">
          <thead class="thead-light">
            <tr class="text-center">
              <th scope="col" class="thead-top-left p-2">Nome</th>
              <th scope="col" class="thead p-2">CPF/CNPJ</th>
              <th scope="col" class="thead p-2">Data Entrada</th>
              <th scope="col" class="thead-top-right p-2">Qualificação</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngIf="objetoNovaPesquisa?.dadosCnpj?.listaSocio?.length > 0; else noDataSocios">
              <tr class="text-center table-info" *ngFor="let socio of objetoNovaPesquisa?.dadosCnpj?.listaSocio">
                <td>{{ socio?.nome }}</td>
                <td>{{ socio?.cpfCnpj }}</td>
                <td>{{ socio?.dataEntradaSociedade }}</td>
                <td>{{ socio?.qualificacao }}</td>
              </tr>
            </ng-container>
            <ng-template #noDataSocios>
              <tr>
                <td colspan="4" class="text-center">Não há sócios disponíveis</td>
              </tr>
            </ng-template>
          </tbody>        
        </table>
      </mat-expansion-panel>
    </mat-accordion>
  
    <!-- telefones -->
    <mat-accordion>
      <mat-expansion-panel class="mt-3 py-2" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <h3 class="mb-0 d-flex align-items-center">
              <mat-icon> phone</mat-icon> &nbsp; TELEFONES
            </h3>
          </mat-panel-title>
  
        </mat-expansion-panel-header>
        <table class="table">
          <thead class="thead-light">
            <tr class="text-center">
              <th scope="col" class="thead-top-only p-2">Telefone</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngIf="objetoNovaPesquisa?.dadosCnpj?.telefones?.length > 0; else noDataTel">
              <tr class="table-info text-center" *ngFor="let telefone of objetoNovaPesquisa?.dadosCnpj?.telefones">
                <td>{{ telefone }}</td>
              </tr>
            </ng-container>
            <ng-template #noDataTel>
              <tr>
                <td colspan="1" class="text-center">Não há telefones disponíveis</td>
              </tr>
            </ng-template>
          </tbody>
        </table>
      </mat-expansion-panel>
    </mat-accordion>
  
    <!--enderecos-->
    <mat-accordion>
      <mat-expansion-panel class="mt-3 py-2" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <h3 class="mb-0 d-flex align-items-center">
              <mat-icon>home</mat-icon>&nbsp;
              ENDEREÇOS
            </h3>
          </mat-panel-title>
  
        </mat-expansion-panel-header>
        <table class="table">
          <thead class="thead-light">
            <tr class="text-center">
              <th scope="col" class="thead-top-left p-2">Logradouro</th>
              <th scope="col" class="thead p-2">Numero</th>
              <th scope="col" class="thead p-2">Compl.</th>
              <th scope="col" class="thead p-2">Bairro</th>
              <th scope="col" class="thead p-2">Cidade</th>
              <th scope="col" class="thead p-2">UF</th>
              <th scope="col" class="thead-top-right p-2">CEP</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngIf="objetoNovaPesquisa?.dadosCnpj?.logradouro || objetoNovaPesquisa?.dadosCnpj?.enderecos?.length > 0; else noDataEnde">
              <tr class="text-center table-info" *ngIf="objetoNovaPesquisa?.dadosCnpj?.logradouro">
                <td>{{ objetoNovaPesquisa?.dadosCnpj?.logradouro }}</td>
                <td>{{ objetoNovaPesquisa?.dadosCnpj?.numero }}</td>
                <td>{{ objetoNovaPesquisa?.dadosCnpj?.complemento }}</td>
                <td>{{ objetoNovaPesquisa?.dadosCnpj?.bairro }}</td>
                <td>{{ objetoNovaPesquisa?.dadosCnpj?.cidade }}</td>
                <td>{{ objetoNovaPesquisa?.dadosCnpj?.uf }}</td>
                <td>{{ objetoNovaPesquisa?.dadosCnpj?.cep }}</td>
              </tr>
              <tr class="text-center table-info" *ngFor="let endereco of objetoNovaPesquisa?.dadosCnpj?.enderecos">
                <td>{{ endereco?.logradouro }}</td>
                <td>{{ endereco?.numero }}</td>
                <td>{{ endereco?.complemento }}</td>
                <td>{{ endereco?.bairro }}</td>
                <td>{{ endereco?.cidade }}</td>
                <td>{{ endereco?.uf }}</td>
                <td>{{ endereco?.cep }}</td>
              </tr>
            </ng-container>
            <ng-template #noDataEnde>
              <tr>
                <td colspan="7" class="text-center">Não há endereços disponíveis</td>
              </tr>
            </ng-template>
          </tbody>
        </table>
      </mat-expansion-panel>
    </mat-accordion>
  
    <!--emails-->
    <mat-accordion>
      <mat-expansion-panel class="mt-3 py-2" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <h3 class="mb-0 d-flex align-items-center">
              <mat-icon> email</mat-icon> &nbsp;
              ENDEREÇOS ELETRÔNICO
            </h3>
          </mat-panel-title>
  
        </mat-expansion-panel-header>
        <table class="table">
          <thead class="thead-light">
            <tr class="text-center">
              <th scope="col" class="thead-top-only p-2">E-mail</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngIf="objetoNovaPesquisa?.dadosCnpj?.emails?.length > 0; else noDataEmail">
              <tr class="table-info text-center" *ngFor="let email of objetoNovaPesquisa?.dadosCnpj?.emails">
                <td>{{ email }}</td>
              </tr>
            </ng-container>
            <ng-template #noDataEmail>
              <tr>
                <td colspan="1" class="text-center">Não há e-mails disponíveis</td>
              </tr>
            </ng-template>
          </tbody>        
        </table>
      </mat-expansion-panel>
    </mat-accordion>
  
    <!-- <mat-accordion>
      <mat-expansion-panel class="mt-3 py-2" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <h3 class="mb-0 d-flex align-items-center">
              <mat-icon>place</mat-icon>&nbsp;
              LOCALIZAÇÃO
            </h3>
          </mat-panel-title>
  
        </mat-expansion-panel-header>
        <table class="table">
          <thead class="thead-light">
            <tr class="text-center">
              <th scope="col" class="thead-top-left p-2">Mapa</th>
              <th scope="col" class="thead-top-right p-2">Fachada</th>
            </tr>
          </thead>
          <tbody>
            <tr class="text-center table-info">
              <td class="col-6">
                <iframe id="localizacao"
                width="100%" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://www.google.com/maps/embed/v1/place?q=$emilio+Ribas+cambui+campinas&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8"
                class="iframe-mapa"></iframe>
              </td>
              <td class="col-6">
                <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQK25fu0jhvg593G8sY9Dog94rpouNMWbU7ipxiGRK0B9KW9qCiT_tofSneyE2Ixs__1UE&usqp=CAU" alt="">
              </td>
            </tr>
          </tbody>
        </table>
      </mat-expansion-panel>
    </mat-accordion> -->
  
    <!--participacoes societarias-->
    <mat-accordion>
      <mat-expansion-panel class="mt-3 py-2" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <h3 class="mb-0 d-flex align-items-center">
              <mat-icon>work</mat-icon>&nbsp;
              PARTICIPAÇÕES SOCIETÁRIAS
            </h3>
          </mat-panel-title>
  
        </mat-expansion-panel-header>
        <table class="table">
          <thead class="thead-light">
            <tr class="text-center">
              <th scope="col" class="thead-top-left p-2">Nome</th>
              <th scope="col" class="thead p-2">CNPJ</th>
              <!-- <th scope="col" class="thead p-2">Data Entrada</th> -->
              <th scope="col" class="thead-top-right p-2">Qualificação</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngIf="objetoNovaPesquisa?.dadosCnpj?.listaParticipacaoSocietaria?.length > 0; else noDataParticipacao">
              <tr class="text-center table-info" *ngFor="let participacaoSocietaria of objetoNovaPesquisa?.dadosCnpj?.listaParticipacaoSocietaria">
                <td>{{ participacaoSocietaria.nome }}</td>
                <td>{{ participacaoSocietaria.cnpj }}</td>
                <td>{{ participacaoSocietaria.tipo }}</td>
                <!-- Certifique-se de substituir 'nome', 'cnpj', 'tipo' pelos nomes corretos das propriedades -->
              </tr>
            </ng-container>
            <ng-template #noDataParticipacao>
              <tr>
                <td colspan="3" class="text-center">Não há dados disponíveis</td>
              </tr>
            </ng-template>
          </tbody>
        </table>
      </mat-expansion-panel>
    </mat-accordion>
  
    <!--negativos-->
    <!-- <mat-accordion>
      <mat-expansion-panel class="mt-3 py-2" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <h3 class="mb-0 d-flex align-items-center">
              FONTES COM RESULTADOS NEGATIVOS
            </h3>
          </mat-panel-title>
  
        </mat-expansion-panel-header>
        <table class="table">
          <thead class="thead-light">
            <tr class="text-center">
              <th scope="col" class="thead-top-only p-2">Fontes</th>
            </tr>
          </thead>
          <tbody>
            <tr class="table-info text-center">
              <td> luana.mariah@hotmail.com </td>
            </tr>
            <tr class="table-info text-center">
              <td> Nenhum Resultado </td>
            </tr>
          </tbody>
        </table>
      </mat-expansion-panel>
    </mat-accordion>
  
    falhas
    <mat-accordion>
      <mat-expansion-panel class="mt-3 py-2" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <h3 class="mb-0 d-flex align-items-center">
              FONTES QUE APRESENTARAM FALHAS NA CONSULTA
            </h3>
          </mat-panel-title>
  
        </mat-expansion-panel-header>
        <table class="table">
          <thead class="thead-light">
            <tr class="text-center">
              <th scope="col" class="thead-top-only p-2">Fontes</th>
            </tr>
            <tr class="table-info text-center">
              <td> Nenhum Resultado </td>
            </tr>
          </thead>
          <tbody>
            <tr class="table-info text-center">
              <td> luana.mariah@hotmail.com </td>
            </tr>
          </tbody>
        </table>
      </mat-expansion-panel>
    </mat-accordion>
  
    comarcas falhas
    <mat-accordion>
      <mat-expansion-panel class="mt-3 py-2" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <h3 class="mb-0 d-flex align-items-center">
              FONTES QUE APRESENTARAM FALHAS DE COMARCAS NA CONSULTAS
            </h3>
          </mat-panel-title>
  
        </mat-expansion-panel-header>
        <table class="table">
          <thead class="thead-light">
            <tr class="text-center">
              <th scope="col" class="thead-top-left p-2">Fontes</th>
              <th scope="col" class="thead-top-right p-2">Comarcas</th>
            </tr>
          </thead>
          <tbody>
            <tr class="table-info text-center">
              <td> luana.mariah@hotmail.com </td>
              <td> luana.mariah@hotmail.com </td>
            </tr>
            <tr class="table-info text-center">
              <td> Nenhum Resultado </td>
              <td> Nenhum Resultado </td>
            </tr>
          </tbody>
        </table>
      </mat-expansion-panel>
    </mat-accordion> -->
  </div>
</div>
