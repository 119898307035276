<mat-accordion *ngIf="!erroNegativas || !erroVips">
  <mat-expansion-panel class="mt-3 py-2" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <h3 class=" title-cordion mb-0 d-flex align-items-center">
          <mat-icon>desktop_mac</mat-icon>&nbsp; MÍDIAS
        </h3>
      </mat-panel-title>

    </mat-expansion-panel-header>
    <div class="container mt-4" *ngIf="!erroNegativas || !erroVips">
      <div class="row content border-grey p-3">
        <div [ngClass]="erroVips ? 'col' : 'col-6'" class=" col mt-2" *ngIf="!erroNegativas">
          <loader-only *ngIf="loadingMidiasNegativas && !exibeReprocessarMidiasNegativas && !exibeMidiasNegativas"></loader-only>
          <div class="d-flex justify-content-end">
            <button *ngIf="exibeBotaoRefreshMidias" mat-flat-button class="gs-botaoAzulPadrao m-3"
              (click)="getMidiasNegativas(dadosPesquisa)">Refresh <mat-icon>cached
              </mat-icon></button>
          </div>
          <!-- <div class="border-grey m-3 d-flex justify-content-center align-items-center text-center">
            <p *ngIf="exibeReprocessarMidiasNegativas">{{dadosMidiasNegativas.mensagem}}</p>
          </div> -->
    
          <div class="border-grey processo-indisponivel text-center d-flex flex-column justify-content-center p-2 m-2 mb-5"
            *ngIf="exibeReprocessarMidiasNegativas && !exibeMidiasNegativas">
            <p *ngIf="erroNegativas">Ocorreu um erro, clique em refresh</p>
            <p>{{dadosMidiasNegativas?.mensagem}}</p>
          </div>      
            <div *ngIf="!loadingMidiasNegativas && exibeMidiasNegativas">
              <div class="info-title">
                <h3>Mídias Negativas</h3>
                <p>Fontes: <span class="title-span">Google</span></p>
                <p>Data da Pesquisa: <span class="title-span">{{dadosMidiasNegativas?.dataAtualizacao}}</span></p>
              </div>
              <div class="info-result">
                <h4 class="title-result text-center">Resultados Encontrados</h4>
                <div *ngFor=" let dado of dadosMidiasNegativas?.dados">
                  <p class="link-text title-result"> Palavra pesquisada: {{dado?.palavra}}</p>
                  <div *ngIf="dado?.conteudo?.length == 0">
                    <p>Nenhum resultado encontrado para a palavra {{dado?.palavra}}</p>
                  </div>
                  <div *ngFor="let conteudo of dado?.conteudo">
                    <p *ngIf="!conteudo?.linkUrl" class="link-text title-span">nenhum link encontrado para a pesquisa</p>  
                    <p *ngIf="conteudo?.linkUrl" class="link-result"><a class="truncated-link" href="{{conteudo?.linkUrl}}" target="_blank">{{conteudo?.linkUrl}}</a></p>
                    <p class="link-text title-span"> {{conteudo?.subText}}</p>
                  </div>
                  <br/>
                </div>
              </div>
            </div>
    
        </div>
    
        <div [ngClass]="erroNegativas ? 'col' : 'col-6'" class="mt-2" *ngIf="!erroVips">
          <loader-only *ngIf="loadingPessoasVips && !exibeReprocessarVips && !exibePessoasVips"></loader-only>
          <div class="d-flex justify-content-end">
            <button *ngIf="exibeBotaoRefreshVips" mat-flat-button class="gs-botaoAzulPadrao m-3"
              (click)="getPessoasVips(dadosPesquisa)">Refresh <mat-icon>cached
              </mat-icon></button>
          </div>
          <!-- <div class="border-grey m-3 d-flex justify-content-center align-items-center text-center">
            <p *ngIf="exibeReprocessarVips">{{dadosPessoasVips.mensagem}}</p>
          </div> -->
          <div class="border-grey processo-indisponivel text-center d-flex flex-column justify-content-center p-2 m-2 mb-5"
            *ngIf="exibeReprocessarVips && !exibePessoasVips">
            <p *ngIf="erroVips">Ocorreu um erro, clique em refresh</p>
            <p>{{dadosPessoasVips?.mensagem}}</p>
          </div>    
          <div *ngIf="!loadingPessoasVips && exibePessoasVips">
            <div class="info-title">
              <h3>Pessoas VIP's</h3>
              <p>Fontes: <span class="title-span">Google</span></p>
              <p>Data da Pesquisa: <span class="title-span">{{dadosPessoasVips?.dataAtualizacao}}</span></p>
            </div>
            <div class="info-result">
              <h4 class="title-result text-center">Resultados Encontrados</h4>
              <div *ngFor=" let dado of dadosPessoasVips?.dados">
                <p class="link-text title-result"> Palavra pesquisada: {{dado?.palavra}}</p>
                <div *ngIf="dado?.conteudo?.length == 0">
                  <p>Nenhum resultado encontrado para a palavra {{dado?.palavra}}</p>
                </div>
                <div *ngFor="let conteudo of dado?.conteudo">
                  <p *ngIf="!conteudo?.linkUrl" class="link-text title-span">nenhum link encontrado para a pesquisa</p>  
                  <p  class="link-result"><a class="truncated-link" href="{{conteudo?.linkUrl}}" target="_blank">{{conteudo?.linkUrl}}</a></p>
                  <p class="link-text title-span"> {{conteudo?.subText}}</p>
                </div>
                <br/>          
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-expansion-panel>
</mat-accordion>

