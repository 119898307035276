<div class=" d-flex flex-column justify-content-center">
    <div class="d-flex icon"><mat-icon class="cursor-pointer" mat-dialog-close>close</mat-icon></div>
    <div>
        <p class="">Para emitir a <b>Certidão Divida Uniao Imovel Rural - TCU</b>, será necessário informar o NIRF, caso contrário a mesma não será emitida.</p>
        <p class="text-center">Deseja continuar?</p>
    </div>
    <div class=" d-flex justify-content-between">
        <button class="btn btn-primary buttonBack" mat-dialog-close>Voltar</button>
        <button class="gs-botaoAzulPadrao buttonGo" value="1" (click)="emitirCertidao()"> Emitir assim mesmo</button>
    </div>
</div>

