import { HttpStatusCode } from '@angular/common/http';
import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Data, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { catchError, concatMap, from, map, mergeMap, of } from 'rxjs';
import { ModalAnexoComponent } from 'src/app/components/view/pesquisa-em-massa/modal-anexo/modal-anexo.component';
import { ProdutosEnum } from 'src/app/shared/enums/produtos.enum';
import { PesquisaService } from 'src/app/shared/services/http/pesquisa.service';
import { UiService } from 'src/app/shared/services/ui.service';
import * as XLSX from 'xlsx';
import { ModalValoresPesquisaComponent } from '../modal-valores-pesquisa/modal-valores-pesquisa.component';

@Component({
  selector: 'app-modal-pesquisa-lote',
  templateUrl: './modal-pesquisa-lote.component.html',
  styleUrls: ['./modal-pesquisa-lote.component.scss']
})
export class ModalPesquisaLoteComponent implements OnInit {

  anexandoArquivo = false
  produtoId;
  totalDocumentos;
  produtoReferencia;
  inputManual;
  excelBase64;
  tipoPesquisaLote;
  dataTable = [];
  payloadRequest = {};
  docsAdded;
  requestHeader;

  somaValores

  progresso: number = 0; // Progresso inicializado em 0%
  totalDocumentosContador: number = 0; // Inicializa como 0
  documentosProcessadosComSucessoContador: number = 0; // Inicializa como 0

  documentosProcessados;
  documentosProcessadosComSucesso
  objetoRequest;
  isLoading: boolean = false;

  constructor(
    private router: Router,
    private ui: UiService,
    private pesquisaService: PesquisaService,
    private cdr: ChangeDetectorRef,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<ModalAnexoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Data,
    private toast: ToastrService
  ) { }

  ngOnInit(): void {
    if(this.router.url == '/cadastrais'){this.requestHeader = 'cadastrais'  }
    if(this.router.url == '/processo'){  this.requestHeader = 'processos'  }
    if(this.router.url == '/patrimonial'){ this.requestHeader = 'patrimonial'  }
    if(this.router.url == '/credito'){  this.requestHeader = 'analise-credito'  }
    if(this.router.url == '/dossie'){ this.requestHeader = 'dossie-completo' }
    this.getProductParamter();
    console.log(this.data);
    this.processarDocumentos(this.data.objPesquisa, this.data.docs)
  }

  getProductParamter() {
    let route = this.router.url;
    if (route == '/cadastrais') {
      this.produtoId = ProdutosEnum.Cadastrais;
      this.produtoReferencia = "cadastrais";
    }
    if (route == '/credito') {
      this.produtoId = ProdutosEnum.Credito
      this.produtoReferencia = "analise-credito";
    }
    if (route == '/dossie') {
      this.produtoId = ProdutosEnum.Dossie
      this.produtoReferencia = "dossie-completo";
    }
    if (route == '/patrimonial') {
      this.produtoId = ProdutosEnum.Patrimonial
      this.produtoReferencia = "patrimonial";
    }

    if (route == '/processo') {
      this.produtoId = ProdutosEnum.Processo
      this.produtoReferencia = "processos";
    }
  }


  processarDocumentos(objPesquisa: any, docs: string[]): void {
    this.objetoRequest = [];
    this.totalDocumentosContador = docs.length; // Define o total de documentos
    this.documentosProcessados = docs.map(doc => ({
      numero: doc.replace(/\D/g, ''),
      nome: '',
      mensagem: 'Aguardando processamento'
    }));
  
    this.isLoading = true;
    const allRequests: any[] = []; // Armazena os objetos para o cálculo
    const apiRequests: any[] = []; // Armazena os objetos para enviar para a API
  
    let documentosProcessadosComSucesso = 0; // Contador para documentos processados com sucesso
  
    from(docs).pipe(
      mergeMap(numero => {
        const documentoLimpo = numero.replace(/\D/g, '');
        const objRequest = { ...objPesquisa, numero: documentoLimpo };
  
        const documentoAtual = this.documentosProcessados.find(doc => doc.numero === documentoLimpo);
        if (documentoAtual) {
          documentoAtual.mensagem = 'Processando...';
        }
  
        return this.pesquisaService.preCadastro(objRequest, this.requestHeader).pipe(
          map(response => {
            if (response.codigo === 10) {
              const requestObject = {
                idFonteGrupo: this.data.objPesquisa.idFonteGrupo,
                numero: documentoLimpo,
                produtoID: this.produtoId,
                notificacao: true,
                tags: this.data.addedItems,
                valorPesquisa: response.valorPesquisa,
                valorExcedido: response.valorExcedido,
                valorAdicional: response.valorPesquisaAdicional || 0,
                nome: response.nome || '',
                mensagem: response.mensagem,
                parametrosSelecionados: response.parametrosSelecionados || [],
                fontesAdicionaisSelecionadas: response.fontesAdicionaisSelecionadas || []
              };
  
              const apiRequestObject = {
                idFonteGrupo: this.data.objPesquisa.idFonteGrupo,
                numero: documentoLimpo,
                produtoID: this.produtoId,
                notificacao: true,
                tags: this.data.addedItems,
                valorPesquisa: response.valorPesquisa,
                valorExcedido: response.valorExcedido,
                valorAdicional: this.data.objPesquisa.valorAdicional,
                nome: response.nome || ''
              };
  
              allRequests.push(requestObject);
              apiRequests.push(apiRequestObject);
              documentosProcessadosComSucesso++; // Incrementa o contador de sucesso
              this.documentosProcessadosComSucessoContador++;
              this.atualizarProgresso(); 
  
              return requestObject;
            } else {
              if (documentoAtual) {
                documentoAtual.nome = response.nome || '';
                documentoAtual.mensagem = response.mensagem || 'Processamento falhou';
              }
              this.documentosProcessadosComSucessoContador++;
              this.atualizarProgresso(); 
              return null;
            }
          }),
          catchError(err => {
            console.error('Erro ao processar documento:', err.error);
            if (documentoAtual) {
              documentoAtual.mensagem =  err.error || 'Erro ao processar documento';
            }
            this.documentosProcessadosComSucessoContador++;
            this.atualizarProgresso();
            return of(null);
          })
        );
      }, 5)
    ).subscribe(
      requestObject => {
        if (requestObject) {
          const documentoAtual = this.documentosProcessados.find(doc => doc.numero === requestObject.numero);
          if (documentoAtual) {
            documentoAtual.nome = requestObject.nome;
            documentoAtual.mensagem = requestObject.mensagem;
          }
        }
      },
      err => {
        console.error('Erro global inesperado:', err);
      },
      () => {
        this.isLoading = false;
  
        if (allRequests.length > 0) {
          this.somaValores = this.calcularValoresSomados(allRequests);
          this.objetoRequest = apiRequests;
        } else {
          this.objetoRequest = [];
        }
  
        // Atualiza a contagem de documentos processados
        this.documentosProcessadosComSucesso = documentosProcessadosComSucesso;
        this.totalDocumentos = docs.length;
        this.documentosProcessadosComSucessoContador = this.documentosProcessadosComSucessoContador;
        this.totalDocumentosContador = docs.length;
        
  
        this.cdr.detectChanges();
      }
    );
  }
  
  enviarParaProcessamento() {
    if (this.somaValores.fontesAdicionaisSelecionadas.length > 0 || this.somaValores.parametrosSelecionados.length > 0) {
      const dialogRef = this.dialog.open(ModalValoresPesquisaComponent, {
        maxWidth: "750px",
      minWidth: "650px",
        data: {
          somaValores: this.somaValores,
          tipoPesquisa: 1,
          quantidadeDocs: this.documentosProcessadosComSucesso
        }
      });
  
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.enviarPesquisaParaProcessamento();
        }
      });
    } else {
      this.enviarPesquisaParaProcessamento();  
    }
  }
  
  atualizarProgresso() {
    if (this.totalDocumentosContador > 0) {
      this.progresso = Math.round((this.documentosProcessadosComSucessoContador / this.totalDocumentosContador) * 100);
      this.cdr.detectChanges(); // Detecta mudanças na visão
    }
  }

  enviarPesquisaParaProcessamento() {
    const processingSnackBar = this.snackBar.open('Processando documentos, por favor, aguarde...', '', {
      duration: undefined,
      horizontalPosition: 'right',
      verticalPosition: 'top',
      panelClass: ['processing-snackbar']
    });
    this.dialogRef.close();
    this.pesquisaService.cadastrarPesquisaLote(this.objetoRequest, this.requestHeader).subscribe(
      data => {
        if (HttpStatusCode.Ok) {
          this.ui.loaded().subscribe(() => {
            processingSnackBar.dismiss();
  
            this.snackBar.open('Documentos processados com sucesso. Consulte o histórico!', '', {
              duration: 3000,
              horizontalPosition: 'right',
              verticalPosition: 'top',
              panelClass: ['success-snackbar']
            });
            this.dialogRef.close();
            // Recarrega a página
            window.location.reload();  
          });
        }
      },
      (err: any) => {
        this.ui.loaded().subscribe(() => {
          processingSnackBar.dismiss();
  
          this.snackBar.open('Erro ao processar documentos: ' + err.error, '', {
            duration: 5000,
            horizontalPosition: 'right',
            verticalPosition: 'top',
            panelClass: ['error-snackbar']
          });
  
          this.ui.info(err.error);
        });
      }
    );     
  }
  calcularValoresSomados(requests: any[]): any {
    return requests.reduce((acc, curr) => {
      acc.valorPesquisa += curr.valorPesquisa || 0;
      acc.valorAdicional += typeof curr.valorAdicional === 'number' ? curr.valorAdicional : 0;
  
      if (curr.valorExcedido !== null) {
        acc.valorExcedido = acc.valorExcedido || curr.valorExcedido;
      }
  
      curr.parametrosSelecionados.forEach((parametro: any) => {
        let existingParam = acc.parametrosSelecionados.find((p: any) => p.idParametro === parametro.idParametro);
        if (existingParam) {
          existingParam.valor += parametro.valor || 0;
        } else {
          acc.parametrosSelecionados.push({ ...parametro });
        }
        acc.valorParametro += parametro.valor || 0;
      });
  
      curr.fontesAdicionaisSelecionadas.forEach((fonte: any) => {
        let existingFonte = acc.fontesAdicionaisSelecionadas.find((f: any) => f.fonte === fonte.fonte);
        if (existingFonte) {
          existingFonte.valor = (existingFonte.valor || 0) + (fonte.valor || 0);
        } else {
          acc.fontesAdicionaisSelecionadas.push({ ...fonte });
        }
      });
  
      return acc;
    }, {
      valorPesquisa: 0,
      valorAdicional: 0,
      valorExcedido: false,
      valorParametro: 0, 
      parametrosSelecionados: [],
      fontesAdicionaisSelecionadas: []
    });
  }
  
}
