import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, Input, OnInit, ViewChild, OnChanges } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { ActivatedRoute, NavigationEnd, Params, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { delay, filter, Observable } from 'rxjs';
import { NavService } from './components/template/nav/nav.service';
import { AuthService } from './shared/services/http/auth.service';
import { UiService } from './shared/services/ui.service';
import jwt_decode from "jwt-decode";
import { ModalTermosComponent } from './components/view/modal-termos/modal-termos.component';
import { UsuarioService } from './shared/services/http/usuario.service';
import { MatDialog } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';

@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'frontend';
  @ViewChild(MatSidenav)
  sidenav!: MatSidenav;
  currentRoute: string;
  usuario: any;
  access;
  tokenExpirado;
  exibeAviso = false;
  nomePacote;

  constructor(
    protected authService: AuthService,
    private uiService: UiService,
    private observer: BreakpointObserver,
    public router: Router,
    private activatedRoute: ActivatedRoute,
    private navService: NavService,
    private datePipe: DatePipe,
    private usuarioService: UsuarioService,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.verificaUsuarioTemToken();
    setTimeout(() => {
      this.openTermos();
    }, 2000);
    this.openPopupIfAfter20Hours();

  }

  verificaUsuarioTemToken() {
    if (localStorage.getItem('token')) {
      this.access = JSON.parse(localStorage.getItem('token'))
      this.access = this.access.access_token
      this.authService.getUsuarioAtual();
    }
  }

  verificaExpiresToken() {
    const tokenInfo = this.getDecodedAccessToken(this.access); // decode token
    const expire = tokenInfo.exp * 1000;
    this.tokenExpirado = new Date(expire)
  }


  getDecodedAccessToken(token: string): any {
    try {
      return jwt_decode(token);
    } catch (Error) {
      return null;
    }
  }

  ngAfterViewInit() {
    this.observer
      .observe(['(max-width: 800px)'])
      .pipe(delay(1), untilDestroyed(this))
      .subscribe((res) => {
        if (res.matches) {
          this.sidenav.mode = 'over';
          this.sidenav.close();
        } else {
          this.sidenav.mode = 'side';
          this.sidenav.open();
        }
      });

    this.router.events
      .pipe(
        untilDestroyed(this),
        filter((e) => e instanceof NavigationEnd)
      )
      .subscribe(() => {
        if (this.sidenav.mode === 'over') {
          this.sidenav.close();
        }
      });
  }


  get titleTest(): string {
    return this.navService.navData.title
  }

  verificaUrl() {
    if (this.router.url == '/dashboard' || this.router.url == '/cadastrais' || this.router.url == '/processo' || this.router.url == '/patrimonial' || this.router.url == '/credito' || this.router.url == '/dossie' || this.router.url == '/certidoes' || this.router.url == '/historico' || this.router.url == '/localizador' || this.router.url == '/precificador-credito' || this.router.url == '/cruzamento-dados') {
      if (this.tokenExpirado <= new Date()) {
        this.router.navigate(['/auth/login'])
      }
      return true
    }
    // else if (this.router.url == '/detech') {
    //   if (this.tokenExpirado <= new Date()) {
    //     this.router.navigate(['/auth/login'])
    //   }
    //   return false
    // }
  }

  openTermos() {
    console.log(this.router.url)
    if (this.router.url === '/auth/login' || this.router.url === '/auth/logout' || this.router.url === '/auth/recuperacao-senha') {
      return
    }
    else {
      this.usuarioService.getDashboard(this.authService.user.id).subscribe(data => {
        this.nomePacote = data.pacote.nomePacote
        if (this.authService.user.termosAceitos == "{\"TermoTestePlataforma\":false}" && this.nomePacote === 'TESTE') {
          this.dialog.open(ModalTermosComponent, {
            disableClose: true,
            width: '600px',
            height: '380px',
            data: {
              id: this.authService.user.id
            }
          });
        }
      })
    }
  }

  openPopupIfAfter20Hours(): void {
    const currentTime = new Date();
    const currentHour = currentTime.getHours();

    if (currentHour >= 20 || currentHour < 8) {
      this.exibeAviso = true;
    }
  }
}
