import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import * as ApexCharts from 'apexcharts';
import { DashboardService } from 'src/app/shared/services/dashboard/dashboard-service';
import { UiService } from 'src/app/shared/services/ui.service';

const today = new Date();
const month = today.getMonth();
const year = today.getFullYear();

@Component({
  selector: 'app-consultas-realizadas',
  templateUrl: './consultas-realizadas.component.html',
  styleUrls: ['./consultas-realizadas.component.scss']
})

export class ConsultasRealizadasComponent implements OnInit {

isLoading = false;
dadosDossies = [];
dadosCertidoes = [];
dadosProcessos = [];
value;
dados;
totalDossies;
totalPesquisasComProcesso;
totalPesquisasPessoaFisica;
totalPesquisasPessoaJuridica;
totalPesquisasSemProcesso;
totalProcessos;
totalCertidoes;
selected = '1';
consultasRealizadas: any = {};

// view: any[] = [700, 400];
// showXAxis = true;
//   showYAxis = true;
//   gradient = false;
//   showLegend = true;
//   showXAxisLabel = true;
//   xAxisLabel = 'Country';
//   showYAxisLabel = true;
//   yAxisLabel = 'Population';

//   colorScheme = {
//     domain: ['#5AA454']
//   };

campaignOne = new FormGroup({
  start: new FormControl(new Date(year, month, 13)),
  end: new FormControl(new Date(year, month, 16)),
});
campaignTwo = new FormGroup({
  start: new FormControl(new Date(year, month, 15)),
  end: new FormControl(new Date(year, month, 19)),
});
formFiltro: any = { page: 1};

  constructor(
    public dashboardService: DashboardService,
    private ui: UiService,
  ) { }

  ngOnInit(): void {
    this.formFiltro.cadastroDe = this.subtractMonths(new Date(), 1);
    this.formFiltro.cadastroAte = new Date((new Date()))
    this.getValueToCards()
  }

  subtractMonths(date, months) {
    date.setMonth(date.getMonth() - months);
  
    return date;
  }


  // getChart(){
  //   Object.assign(this,  this.value) 
  // }
  getValueToCards(){
    const obj = {
      IdCliente: 52,
      dataDe: this.formFiltro.cadastroDe,
      dataAte: this.formFiltro.cadastroAte
      // dataDe: '2023-01-01',
      // dataAte: '2024-01-01'
    }

    this.isLoading = true
    this.dados = []
    this.dashboardService.getVisaoProcessual(obj).subscribe(response => {
      this.ui.loaded().subscribe(() => {
        this.dados = response;
        this.totalDossies = response.totalDossies;
        this.totalCertidoes = response.totalCertidoes;
        this.totalPesquisasPessoaFisica = response.totalPesquisasPessoaFisica;
        this.totalPesquisasSemProcesso = response.totalPesquisasSemProcesso;
        this.totalPesquisasPessoaJuridica = response.totalPesquisasPessoaJuridica;
        this.totalProcessos = response.totalProcessos;
        this.dadosDossies = response.totalDossiesPorMes;
        this.dadosCertidoes = response.totalCertidoesPorMes;
        this.dadosProcessos = response.totalProcessosPorMes;
        this.totalPesquisasComProcesso = response.totalPesquisasComProcesso
        this.value = this.dadosDossies
        this.charts()
        this.getValue()
        setTimeout(() => {
          this.isLoading = false
      }, 800);
    });

    })
  }


  getValue(){
    if(this.selected == '1'){
      this.value = this.dadosDossies
      this.charts()
    }
    else if(this.selected == '2'){
      this.value =  this.dadosCertidoes
      this.charts()
    }
    else if(this.selected == '3'){
      this.value =  this.dadosProcessos
      this.charts()
    }
    return this.value
  }

    private charts(){
      var options = {
        chart: {
          width: "100%",
          height: 380,
          type: "bar",
          color: "#fff",
          events: {
            mounted: (chart) => {
              chart.windowResizeHandler();
            }
          },
          toolbar: {
            show: false
          }
        },
        plotOptions: {
          bar: {
            horizontal: false
          }
        },
        title: {
          text: '',
        },
        dataLabels: {
          enabled: true,
          style:{
            fontSize: '1rem'
          }
        },
        stroke: {
          width: 1,
          colors: ["#fff"]
        },
        series: [
          {
            data: this.value.map(x => x.total),
            name: ""
          },
      
        ],
        xaxis: {
          categories: this.value.map(x => x.nome),
          tickPlacement: "between"
        },
        yaxis: {
          show: true,
        },
        responsive: [
          {
            breakpoint: 1000,
            options: {
              plotOptions: {
                bar: {
                  horizontal: false
                }
              },
              legend: {
                position: "bottom"
              },
              dataLabels: {
                enabled: false,
              },
            }
          }
        ]
      };
  

    var chart = new ApexCharts(
      document.querySelector("#chartRelatorio"),
      options
    );
    
    chart.render();
    window.dispatchEvent(new Event('resize'))
  }

  renderCharts(){
    if(!this.dados){
      return 'd-none'
    }
    setTimeout(() => {
      return ''
     }, 200);
  }

}
