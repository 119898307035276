<div class="container">
  <form>
    <div class="row d-flex mt-2">
      <div class="col-4 d-flex flex-column">
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>Identificacao</mat-label>
          <input [(ngModel)]="form.descricao" name="descricao" matInput>
        </mat-form-field>

        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>Valor do Crédito Judicializado Atualizado</mat-label>
          <input [(ngModel)]="form.valorCreditoJudicializado" name="valorCreditoJudicializado" matInput currencyMask
            [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', align:' left' }">
        </mat-form-field>

        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>Valor total de Ativos</mat-label>
          <input [(ngModel)]="form.valorTotalAtivos" name="valorTotalAtivos" matInput currencyMask
            [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', align:' left' }">
        </mat-form-field>

        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>Valor total de Passivos</mat-label>
          <input [(ngModel)]="form.valorTotalPassivos" name="valorTotalPassivos" matInput currencyMask
            [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', align:' left' }">
        </mat-form-field>
      </div>

      <div class="col-4 d-flex flex-column">
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>Total das Custas</mat-label>
          <input [(ngModel)]="form.totalCustas" name="totalCustas" matInput currencyMask
            [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', align:' left' }">
        </mat-form-field>

        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>Total Atingível</mat-label>
          <input [(ngModel)]="form.totalAtingivel" name="totalAtingivel" matInput currencyMask
            [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', align:' left' }">
        </mat-form-field>

        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>Tempo de Recuperação</mat-label>
          <input [(ngModel)]="form.recuperacao" name="recuperacao" matInput>
        </mat-form-field>
      </div>

      <div class="col-4 d-flex flex-column">
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>Valor Final do Crédito Sugerido</mat-label>
          <input [(ngModel)]="form.valorFinalCredito" name="valorFinalCredito" matInput currencyMask
            [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', align:' left' }">
        </mat-form-field>

        <div class="col mt-2">
          <p class="text-danger">{{prescrito}}</p>
        </div>

        <div class="col-12 d-flex justify-content-end">
          <!-- <button mat-flat-button class="gs-botaoAzulPadrao m-3" (click)="calcular()">
              Calcular
            </button> -->
        </div>
      </div>
    </div>
  </form>
</div>
