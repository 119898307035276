<mat-accordion *ngIf="exibeCadastroSemFinsLucrativos">
    <mat-expansion-panel class="mt-3 py-2" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <h3 class=" title-cordion mb-0 d-flex align-items-center">
            <mat-icon class="detech-icon">label_important</mat-icon>&nbsp; Restrições para entidades privadas
          </h3>
        </mat-panel-title>
  
      </mat-expansion-panel-header>
      <table class="table" *ngIf="!isLoadingCadastroSemFinsLucrativos && !exibeMensagemCadastroSemFinsLucrativos">
        <thead class="thead-light">
          <tr class="text-center">
            <th scope="col" class="thead-top-left p-2">Número Convenio</th>
            <th scope="col" class="thead p-2">Orgão Concedente</th>
            <th scope="col" class="thead-top-right p-2">Motivo Impedimento</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngIf="dataCadastroSemFinsLucrativos.dados?.length > 0; else noDataCadastroSemFinsLucrativos">
            <tr class="text-center table-info" *ngFor="let dados of dataCadastroSemFinsLucrativos.dados">
              <td>{{dados?.numero_convenio}}</td>
              <td>{{dados?.orgao_concedente}}</td>
              <td>{{dados?.motivo_do_impedimento}}</td>
            </tr>
          </ng-container>
          <ng-template #noDataCadastroSemFinsLucrativos>
            <tr>
              <td colspan="3" class="text-center">Não há resultados disponíveis</td>
            </tr>
          </ng-template>
        </tbody>
      </table>
      <div class="d-flex justify-content-center text-center flex-column" *ngIf="!isLoadingCadastroSemFinsLucrativos && exibeMensagemCadastroSemFinsLucrativos">
        <div class="d-flex justify-content-end" *ngIf="exibeReprocessarCadastroSemFinsLucrativos">
          <button mat-flat-button class="gs-botaoAzulPadrao m-3" (click)="getCadastroSemFinsLucrativos(idPesquisa, 129)">Refresh <mat-icon>cached
          </mat-icon></button>
        </div>
        <div>
          <p>{{dataCadastroSemFinsLucrativos.mensagem}}</p>
        </div>
      </div>
       <loader-only *ngIf="isLoadingCadastroSemFinsLucrativos"></loader-only>
    </mat-expansion-panel>
  </mat-accordion>