<section class="background-radial-gradient overflow-hidden">
    <style>

        section {
            height: 100vh;
            vertical-align: middle;
            display: flex;
            /* align-content: center; */
            align-items: center;
        }

      .background-radial-gradient {
        background-image: url("../../../assets/img/fundoLogin.jpg");
        background-repeat: no-repeat;
        background-size: cover;
      }
  
      .bg-glass {
        background-color: #ffffff !important;
        border-radius: 23px;
        backdrop-filter: saturate(200%) blur(25px);
      }
    </style>
  
    <div class="container px-4 py-5 px-md-5 text-center text-lg-start align-middle">
      <div class="row gx-lg-5 align-items-center align-middle">
        <div class="col-lg-7 mb-5 mb-lg-0 text-center" style="z-index: 10">
            <img src="../../../assets/img/logo-credvalue-v2.svg" alt="logo credvalue" width="70%" class="ml-5">
          <h1 class="mb-5 mt-2 display-5 fw-bold ls-tight" style="color: hsl(218, 81%, 95%); font-weight: 400">
            <span style="color: hsl(225, 9%, 91%); font-weight: 400"> Pesquisas Jurídicas e Compliance</span>
          </h1>
        </div>
  
        <div class="col-lg-5 mb-5 mb-lg-0 position-relative">
          <div class="card bg-glass py-5">
            <router-outlet></router-outlet>
          </div>
        </div>
      </div>
    </div>
  </section>