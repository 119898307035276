import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-registradores-qualificada',
  templateUrl: './registradores-qualificada.component.html',
  styleUrls: ['./registradores-qualificada.component.scss']
})
export class RegistradoresQualificadaComponent implements OnInit {

  @Input() uf!: string; // UF (BA, GO, etc.)
  @Input() exibeRegistradores = false;
  @Input() isLoading = false;
  @Input() exibeMensagem = false;
  @Input() exibeReprocessar = false;
  @Input() idPesquisa!: number;
  @Input() statusPesquisa!: number;
  @Input() data: any; // Dados da API

  
  constructor() { }

  ngOnInit(): void {
    console.log(this.data);

    // console.log():
  }

  reprocessar(id: number, ufId: number) {
    console.log(`Reprocessando para ID ${id} e UF ${ufId}`);
    // Lógica do reprocessamento
  }

}
