import { UsuarioPerfil } from './../../enums/usuario-perfil.enum';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
const CSS_VERSION = 'v1.0.1';

@Injectable({
    providedIn: 'root'
})
export class UsuarioService {

    private baseUrl = environment.apiUrl + "/users";
    private localStorageKey = 'tabs-produtos'; // Chave do local storage para as abas

    constructor(public http: HttpClient) { }

    cadastrar(item: any): Observable<any> {
        return this.http.post(`${this.baseUrl}/cadastrar`, item);
    }

    atualizar(item: any): Observable<any> {
        return this.http.post(`${this.baseUrl}/atualizar`, item);
    }

    remover(item: any): Observable<any> {
        return this.http.post(`${this.baseUrl}/excluir`, { id: item.id });
    }

    alterarSenha(item: any): Observable<any> {
        return this.http.post(`${this.baseUrl}/mudar-password`, item);
    }

    iniciarRecuperacaoSenha(item: any): Observable<any> {
        return this.http.post(`${this.baseUrl}/iniciar-recuperacao-senha-portal`, item);
    }

    finalizarRecuperacaoSenha(item: any): Observable<any> {
        return this.http.post(`${this.baseUrl}/finalizar-recuperacao-senha`, item);
    }

    list(filter: any): Observable<any> {
        return this.http.post(`${this.baseUrl}/pesquisar`, filter);
    }

    listByPerfil(perfil: UsuarioPerfil): Observable<any> {
        return this.http.get(`${this.baseUrl}/listar/por-perfil?perfil=${perfil}`);
    }

    get(id: number): Observable<any> {
        return this.http.get(`${this.baseUrl}/${id}`);
    }

    getCurrent(): Observable<any> {
        return this.http.get(`${this.baseUrl}/current`);
    }

    getDashboard(id: number): Observable<any> {
        return this.http.get(`${this.baseUrl}/${id}/dashboardnovo`);
    }

    aceitarTermos(obj: any): Observable<any> {
        return this.http.put(`${this.baseUrl}/aceitar-termos`, obj);
    }


    private getLocalStorageKey(userId: number, productId: number): string {
        return `${this.localStorageKey}_${userId}_${productId}`;
    }

    getTabState(userId: number, productId: number): { titulo: string, id: number, novaPesquisa: boolean, identificador: any, dataNasc: any, dadosPesquisa: any, produtoId: any }[] {
        const key = this.getLocalStorageKey(userId, productId);
        const tabs = localStorage.getItem(key);
        const savedCssVersion = localStorage.getItem('cssVersion');
        if (savedCssVersion !== CSS_VERSION) {
            localStorage.setItem('cssVersion', CSS_VERSION);
            return []; // Força a recarga das abas
        }
        return tabs ? JSON.parse(tabs) : [];
    }

    setTabState(userId: number, productId: number, abas: { titulo: string, id: number, novaPesquisa: boolean, identificador: any, dataNasc: any, dadosPesquisa: any, produtoId: any }[]) {
        const key = this.getLocalStorageKey(userId, productId);
        localStorage.setItem(key, JSON.stringify(abas));
    }

    clearTabsForUser(userId: number, productId: number) {
        const key = this.getLocalStorageKey(userId, productId);
        localStorage.removeItem(key);
    }
}
