import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Data, Router } from '@angular/router';
import { Toast, ToastrService } from 'ngx-toastr';
import { ProdutosEnum } from 'src/app/shared/enums/produtos.enum';
import { PesquisaMassaService } from 'src/app/shared/services/http/pesquisa-massa.service';
import { PesquisaService } from 'src/app/shared/services/http/pesquisa.service';
import { UiService } from 'src/app/shared/services/ui.service';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-modal-anexo',
  templateUrl: './modal-anexo.component.html',
  styleUrls: ['./modal-anexo.component.scss'],
})
export class ModalAnexoComponent implements OnInit {

  anexandoArquivo = false
  produtoId;
  totalDocumentos;
  produtoReferencia;
  inputManual;
  excelBase64;
  tipoPesquisaLote;
  dataTable = [];
  payloadRequest = {};
  constructor(
    private router: Router,
    private ui: UiService,
    private pesquisaMassaService: PesquisaMassaService,
    public dialogRef: MatDialogRef<ModalAnexoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Data,
    private toast: ToastrService
  ) { }

  ngOnInit(): void {
    // this.tipoPesquisaLote = '1'
    this.getProductParamter()
  }

  getProductParamter(){
    let route = this.router.url;
    if(route == '/cadastrais'){
      this.produtoId = ProdutosEnum.Cadastrais;
      this.produtoReferencia = "cadastrais";
    }
    if(route == '/credito'){
      this.produtoId = ProdutosEnum.Credito
      this.produtoReferencia = "analise-credito";
    }
    if(route == '/dossie'){
      this.produtoId = ProdutosEnum.Dossie
      this.produtoReferencia = "dossie-completo";
    }
    if(route == '/patrimonial'){
      this.produtoId = ProdutosEnum.Patrimonial
      this.produtoReferencia = "patrimonial";
    }

    if(route == '/processo'){
      this.produtoId = ProdutosEnum.Processo
      this.produtoReferencia = "processos";
    }
  }

  handleUpload(event) {
    let name;
    let base64;
    this.anexandoArquivo =  true
    const file = event.target.files[0];
    const reader = new FileReader();
    const target: DataTransfer = <DataTransfer>(<unknown>event.target);

    if(target.files.length == 1){
      name = target.files[0].name;
      reader.readAsDataURL(file);
      reader.onload = () => {
        base64 = reader.result
        this.uploadFile(name, base64)
      };
    }
    if (event.target.files.length > 1) {
      this.toast.info("Somente 1 documento por vez")
      event.preventDefault();
      event.value = ""; // clear the older value 
    }
}

  uploadFile(name, base64){
    this.dataTable = [];
    this.payloadRequest = {
      ArquivoNome: name,
      ArquivoBase64: base64.split(',')[1],
      idFonteGrupo: this.data.idFonteGrupo
    }
    this.pesquisaMassaService.validarPlanilha(this.payloadRequest).subscribe(data => {
      this.dataTable = data
      this.totalDocumentos = data.linhas.filter(x => x.erroMensagem == null || x.erroMensagem == undefined)
      this.anexandoArquivo = false
    }, (err:any) => {
      this.ui.loaded().subscribe(() => {
        this.toast.info('Ocorreu um erro ao anexar o arquivo, tente novamente.')
        this.anexandoArquivo = false
      })
    });
    if(this.dataTable.length == 0){
      setTimeout(() => {
       this.anexandoArquivo = false
    }, 2000);
    }
  }

  pesquisarEmMassa(){
    this.ui.loading();
    this.pesquisaMassaService.pesquisarEmMassa(this.payloadRequest, this.produtoReferencia).subscribe(data => {
      this.ui.loaded().subscribe(()=> {
        this.dataTable = data
        this.anexandoArquivo = false
      })
      this.dialogRef.close();
      this.toast.success('Pesquisa Em Lote Realizada com Sucesso!')
    }, (err:any) => {
      this.ui.loaded().subscribe(() => {
        if(err.error != null || err.error != "")
          this.toast.info(err.error)
        
        else
          this.toast.info('Ocorreu um erro ao realizar a pesquisa, tente novamente.')
      })
    });
  }

  downloadModelo(){
    window.location.href='http://example.com/myuri/report?param=x'
  }

  gerarExcelManualmente() {
   // Remover a primeira linha em branco, se houver
   let inputText = this.inputManual.trim();
   if (inputText.startsWith('\n')) {
     inputText = inputText.substring(1);
   }
 
   // Dividir o texto considerando diferentes tipos de quebra de linha
   const lines = inputText.split(/\r\n|\n|\r/);
 
   // Adicionar o cabeçalho com o título 'Número do Documento' na primeira linha
   const data = [['Número do Documento'], ...lines.map(line => [line])];
 
   const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(data);
   const wb: XLSX.WorkBook = XLSX.utils.book_new();
   XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
 
   const wbout: ArrayBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
   const blob = new Blob([wbout], { type: 'application/octet-stream' });

    const fileName = 'planilhaTesteManual.xlsx'; // Defina o nome do arquivo aqui
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      this.excelBase64 = reader.result?.toString();
      this.uploadFile(fileName, this.excelBase64)
    };
  }
}
