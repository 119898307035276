<mat-accordion >
    <mat-expansion-panel class="mt-3 py-2" [expanded]="false" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <h3 class=" title-cordion mb-0 d-flex align-items-center">
            <mat-icon class="detech-icon">games</mat-icon>&nbsp; GRÁFICO DE PESSOAS RELACIONADAS
          </h3>
        </mat-panel-title>
  
      </mat-expansion-panel-header>
      <div class="d-flex">
        <div class="row col-10">
          <mat-card-content #ContainerRef class="d-flex justify-content-center content m-1">
            <ngx-graph  #grafico  [links]="links" [nodes]="nodes" [layout]="customLayout" id="element" [zoomLevel]="0.5" [panToNode$]="panToNode$">
        
        
              <ng-template #nodeTemplate let-node>
                <svg:g  x="1%"
                y="1%"
                width="100"
                height="100"
                ngx-tooltip
                [tooltipPlacement]="'top'"
                [tooltipType]="'tooltip'"
                [tooltipTitle]="node.situacao"
                [style]="transformNode(node)"  class="node nodesSelection"  (dblclick)="openMenu(node, $event)" (click)="selecionarNo($event, node)">
                <svg:circle [attr.r]="node.nodeWidth" [attr.cx]="
                  15" [attr.cy]="15" [attr.fill]="node.color" [attr.stroke]="node.stroke" [attr.stroke-dasharray]="node.dasharray" [attr.stroke-width]="node.strokeWidth"/>
                <image class="main-img" [attr.width.px]="node.nodeWidth" [attr.height.px]="491" [attr.xlink:href]="node.img"/>
        
                <svg:text dominant-baseline="middle"
                [attr.width]="node.dimension.width"
                [attr.x]="node.key == node.value ? -(node.dimension.width - 10) : 40"
                [attr.y.px]="node.nodeWidth">
                    {{node.label}}
                  </svg:text>
                  <svg:text dominant-baseline="middle"
                  class="node-selection"
                [attr.width]="node.dimension.width"
                [attr.x]="node.key == node.value ? -(node.dimension.width - 10) : 40"
                [attr.y]="20">
                    {{node.id}}
                  </svg:text>
                </svg:g>
              </ng-template>
        
              <ng-template #linkTemplate let-link>
                <svg:g class="edge">
                  <svg:path class="line" [attr.height]="50" stroke-width="1.5" stroke='#b8b8b8' marker-end="url(#arrow)">
                  </svg:path>
                  <svg:text class="edge-label" text-anchor="middle">
                    <textPath class="text-path" [attr.href]="'#' + link.id"  class="text-path"
                    [style.dominant-baseline]="link.dominantBaseline" startOffset="70%"
                    >
                      {{link.label}}
                    </textPath>
                  </svg:text>
                </svg:g>
              </ng-template>
        
            </ngx-graph>
        </mat-card-content>
        </div>
        <div class="col-2 d-flex justify-content-center flex-column align-items-center">
          <div class="box">
            <div class="header-box p-2 d-flex justify-content-center">
              <img src="../../../../../assets/img/LOGO-DETECH.svg" alt="Logo Detech" width="60%">
            </div>
            <div class="content-box">
              <ul>
                <li>Expansão de Sócios</li>
                <li>Pessoas Relacionadas</li>
                <li>Expansão de Filiais</li>
                <li>Informações Adicionais</li>
              </ul>
            </div>
          </div>
          <button mat-flat-button class="gs-botaoAzulPadrao m-3" (click)="iniciarDetech()"> Acessar Detech <mat-icon>exit_to_app</mat-icon></button>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
  