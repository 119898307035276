import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Data } from '@angular/router';
import { Subscription } from 'rxjs';
import { NotificacoesService } from 'src/app/shared/services/notificacoes/notificacoes.service';

@Component({
  selector: 'app-notificacoes',
  templateUrl: './notificacoes.component.html',
  styleUrls: ['./notificacoes.component.scss']
})
export class NotificacoesComponent implements OnInit, OnDestroy  {

  notificacoes;
  private subscription: Subscription;

  constructor(
    private notificacoesService: NotificacoesService,
    public dialogRef: MatDialogRef<NotificacoesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Data,
  ) { }

  ngOnInit(): void {
    this.listarNotificacoes(this.data);
    setInterval(() => this.listarNotificacoes(this.data), 50000);
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }

  listarNotificacoes(usuario) {
    const idUsuario = usuario.idUsuario
    const idCliente = usuario.idCliente
    this.notificacoesService.listar(idUsuario, idCliente).subscribe(res => {
      this.notificacoes = res;
      this.notificacoes = this.notificacoes.sort((a, b) => {
        if (a.lido === b.lido) {
          return 0;
        } else if (a.lido) {
          return 1;
        } else {
          return -1;
        }
      });
      this.verificaLeitura()
    })
  }

  marcarComoLido(notificacao) {
    const obj = {
      IdNotificacao: notificacao.idNotificacao,
      IdUsuario: notificacao.idUsuario,
      IdCliente: notificacao.idCliente,
      Titulo: notificacao.titulo,
      Mensagem: notificacao.mensagem,
      Lido: true,
      DataHora: notificacao.dataHora

    }
    this.notificacoesService.atualizar(obj).subscribe(res => {
      this.notificacoes = res
      this.listarNotificacoes(this.data);
      this.notificacoesService.callFunction();
    })
  }

  marcarTodosComoLido(){
    this.notificacoesService.marcarTodasComoLido(null).subscribe(res => {
      this.listarNotificacoes(this.data);
      this.notificacoesService.callFunction();
    })
  }

  verificaLeitura(){
    if(this.notificacoes?.find(x => x.lido == false)){
      return true
    }
  }
}
