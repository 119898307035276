import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, EnvironmentInjector, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { NavigationEnd, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Subscription, delay, filter } from 'rxjs';
import { AuthService } from 'src/app/shared/services/http/auth.service';
import { NavService } from '../nav/nav.service';
import { environment } from 'src/environments/environment.prod';
import { MatDialog } from '@angular/material/dialog';
import { NotificacoesComponent } from '../../view/notificacoes/notificacoes.component';
import { NotificacoesService } from 'src/app/shared/services/notificacoes/notificacoes.service';
import { CadastrarNotificacaoComponent } from '../../view/notificacoes/cadastrar-notificacao/cadastrar-notificacao.component';
import { EditarFotoModalComponent } from './editar-foto-modal/editar-foto-modal.component';

@UntilDestroy()
@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit, OnDestroy {
  @ViewChild(MatSidenav)
  sidenav!: MatSidenav;
  isProduction = false
  mostrarMenu: boolean = false;
  usuario: any = {};
  totalNotificacoes;
  showEditButton = false;

  idUsuario;
  idCliente;

  private subscription: Subscription;

  constructor(
    private observer: BreakpointObserver,
    private router: Router,
    private authService: AuthService,
    private navService: NavService,
    public dialog: MatDialog,
    private notificacoesService: NotificacoesService
  ) { }

  ngOnInit(): void {
    this.authService.callbackUsuario().subscribe(usuario => {
      if (!usuario) {
        return;
      }
      this.usuario = usuario;
      this.idUsuario = usuario.id;
      this.idCliente = usuario.cliente.id;
      this.listarNotificacoes(usuario)
      setInterval(() => this.listarNotificacoes(usuario), 50000);
      this.subscription = this.notificacoesService.functionCalled$.subscribe(() => {
        // Chama a função no componente Y
        this.listarNotificacoes(usuario)
      });
    });
  }

  getUsuarioInfo(){
    this.authService.getInfoUser().subscribe(usuario => {
      if(usuario){
        this.usuario = usuario
      }
    })
    
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }


  ngAfterViewInit() {
    this.observer
      .observe(['(max-width: 820px)'])
      .pipe(delay(1), untilDestroyed(this))
      .subscribe((res) => {
        if (res.matches) {
          this.sidenav.mode = 'over';
          this.sidenav.close();
        } else {
          this.sidenav.mode = 'side';
          this.sidenav.open();
        }
      });

    this.router.events
      .pipe(
        untilDestroyed(this),
        filter((e) => e instanceof NavigationEnd)
      )
      .subscribe(() => {
        if (this.sidenav.mode === 'over') {
          this.sidenav.close();
        }
      });
  }

  get title(): string {
    return this.navService.navData.title
  }

  verificaProducao() {
    if (environment.production == true) {
      return false
    }
    else {
      return true
    }
  }

  habilitaLocalizador() {
    return this.usuario.permissoes.includes("localizador-menu");
  }

  habilitaCadastrais() {
    return this.usuario.permissoes.includes("cadastrais-menu");
  }

  habilitaProcessos() {
    return this.usuario.permissoes.includes("processos-menu");
  }

  habilitaPatrimonios() {
    return this.usuario.permissoes.includes("patrimonial-menu");
  }

  habilitaAnaliseCreditos() {
    return this.usuario.permissoes.includes("analise-credito-menu");
  }

  habilitaDossies() {
    return this.usuario.permissoes.includes("dossie-completo-menu");
  }

  habilitaCertidoes() {
    return this.usuario.permissoes.includes("certidoes-menu");
  }

  habilitaHistoricos() {
    return this.usuario.permissoes.includes("historico-menu");
  }
  habilitaPrecificadorCredito() {
    return this.usuario.permissoes.includes("precificador-menu");
  }

  habilitaTeia() {
    return this.usuario.permissoes.includes("detech-menu");
  }

  habilitarCruzamento(){
    return this.usuario.permissoes.includes("cruzamento-menu");
  }

  habilitarCompliance(){
    return this.usuario.permissoes.includes("compliance-menu");
  }

  openNotificacoes() {
    const menu = this.dialog.open(NotificacoesComponent, {
      width: '20%',
      height: '100%',
      position: { right: '0px', top: '10px' },
      panelClass: 'custom-container',
      data: {
        idUsuario: this.idUsuario,
        idCliente: this.idCliente
      }
    });
  }


  listarNotificacoes(usuario) {
    const idUsuario = usuario.id
    const idCliente = usuario.cliente.id
    this.notificacoesService.listar(idUsuario, idCliente).subscribe(res => {
      this.totalNotificacoes = res.filter(x => x.lido == false)
    })
  }

  cadastrarNotificacao() {
    this.dialog.open(CadastrarNotificacaoComponent, {
      panelClass: 'custom-container',
      width: '600px',
      data: {
        idUsuario: this.idUsuario,
        idCliente: this.idCliente
      }
    });
  }

  goToDetech() {
    const url = `/detech`;
    window.open(url, '_blank');
  }

  openEditarFoto(){
    const dialogRef = this.dialog.open(EditarFotoModalComponent, {
      width: '800px',
      data: {} // Enviar dados, se necessário
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(result)
      if (result) {
        this.getUsuarioInfo()
        // this.usuario.usuarioFoto.image = result.replace('data:image/png;base64,', '');
      }
    });
  }

}
