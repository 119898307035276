import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, catchError, tap, throwError } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { ToastrService } from 'ngx-toastr';

@Injectable({
    providedIn: 'root'
})
export class LocalizadorService {


     private tabStates: { [key: string]: number[] } = {};
     private localStorageKey = 'tabs'; // Chave do local storage para as abas
    private baseUrl = environment.apiUrl + "/localizador/v1";

    constructor(public http: HttpClient, private toastr: ToastrService,) { }

    buscarPorNome(item: any, produto): Observable<any> {
        const httpParams = this.montaQueryParams(item)
        return this.http.get<any>(`${this.baseUrl}/nome`, {params: httpParams, headers: { "Produto": produto }});
    }

    buscarPorEmail(item:any, produto): Observable<any> {
        const httpParams = this.montaQueryParams(item)
        return this.http.get(`${this.baseUrl}/email`, {params: httpParams, headers: { "Produto": produto }});
    }

    buscarPorTelefone(item:any, produto): Observable<any> {
        const httpParams = this.montaQueryParams(item)
        return this.http.get(`${this.baseUrl}/telefone`, {params: httpParams, headers: { "Produto": produto }});
    }

    buscarPorPlaca(item:any, produto): Observable<any> {
      const httpParams = this.montaQueryParams(item)
      return this.http.get(`${this.baseUrl}/placa`, {params: httpParams, headers: { "Produto": produto }});
  }

    private montaQueryParams(params: any): HttpParams {
        let httpParams = new HttpParams();
    
        for (const key in params) {
          if (params.hasOwnProperty(key)) {
            httpParams = httpParams.append(key, params[key]);
          }
        }
    
        return httpParams;
      }

      getTabState(userId: number): { titulo: string, conteudo: any }[] {
        const key = this.getLocalStorageKey(userId);
        const tabs = localStorage.getItem(key);
        return tabs ? JSON.parse(tabs) : [];
      }
    
      setTabState(userId: number, abas: { titulo: string, conteudo: any }[]) {
        const key = this.getLocalStorageKey(userId);
        localStorage.setItem(key, JSON.stringify(abas));
      }
    
      clearTabsForUser(userId: number) {
        const key = this.getLocalStorageKey(userId);
        localStorage.removeItem(key);
      }
    
      private getLocalStorageKey(userId: number): string {
        return `${this.localStorageKey}_${userId}`;
      }
}
