import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ToastrService } from 'ngx-toastr';
import { MensageErrorResponseComponent } from 'src/app/components/view/mensage-error-response/mensage-error-response.component';
import { AuthService } from 'src/app/shared/services/http/auth.service';
import { PrecificadorService } from 'src/app/shared/services/precificador/precificador.service';
import { UiService } from 'src/app/shared/services/ui.service';

@Component({
  selector: 'app-step-one',
  templateUrl: './step-one.component.html',
  styleUrls: ['./step-one.component.scss']
})
export class StepOneComponent implements OnInit {

  @Input() precificadorId: number;

  @Output() changeNumber = new EventEmitter<any>();

  precificadorIdNovo = -1;

  isInvalid = false;
  form: FormGroup<any>;
  idUsuario;
  //idPrecificador = 0;
  dataHoje: Date = new Date();
  pipe = new DatePipe('pt-BR');

  constructor(
    private authService: AuthService,
    private precificadorService: PrecificadorService,
    private fb: FormBuilder,
    private toast: ToastrService,
    public _snackBar: MatSnackBar,
    private ui: UiService) { }

  ngOnInit(): void {

    console.log(this.precificadorService.precificadorIdPaivalue)
    console.log(this.precificadorService.precificadorIdPai)

    this.precificadorService.getPrecificadorIdPai().subscribe(idNovo => {
      console.log(idNovo)
      this.precificadorId = idNovo;
      this.getCreditosAtualizados(this.precificadorId)
    })

    this.form = this.fb.group({
      indice: [],
      identificacao: [],
      nomeTitulo: [],
      dataTitulo: [],
      datainadimplencia: [],
      valorTitulo: [],
      creditoAjuizado: [],
      valorAtualInadimplencia: [],
      jurosMulta: [],
      jurosMora: [],
      observacao: [],
      dividaAtualizada: [],
    });
    this.authService.callbackUsuario().subscribe(usuario => {
      if (!usuario) {
        return;
      }
      this.idUsuario = usuario.id;
    });
    //this.idPrecificador = this.precificadorIdFilho;
  }

  getCreditosAtualizados(idPrecificador) {
    this.precificadorService.creditosAtualizados(idPrecificador).subscribe(data => {
      console.log(data)
      if (data) {

        const dataTituloOriginal = data.dataOperacao
        const dataTituloFormatada = this.pipe.transform(dataTituloOriginal, 'yyyy-MM-dd');
        const datainadimplenciaOriginal = data.dataInadimplencia
        const datainadimplenciaFormatada = this.pipe.transform(datainadimplenciaOriginal, 'yyyy-MM-dd');
        this.form.get('identificacao').setValue(data.identificacao);
        this.form.get('nomeTitulo').setValue(data.titulo);
        this.form.get('dataTitulo').setValue(dataTituloFormatada);
        this.form.get('datainadimplencia').setValue(datainadimplenciaFormatada);

        // this.form.get('dataTitulo').setValue(this.datePipe.transform(this.form.get('dataTitulo); 'medium'));
        // this.form.get('datainadimplencia').setValue(this.datePipe.transform(this.form.get('datainadimplencia); 'medium'));
        this.form.get('valorTitulo').setValue(parseFloat(data.valorOperecao));
        this.form.get('valorAtualInadimplencia').setValue(parseFloat(data.valorInadimplencia));
        this.form.get('indice').setValue(data.indiceCorrecao.id.toString());
        this.form.get('creditoAjuizado').setValue(data.creditoAjuizado);
        //this.form.get('jurosMulta').setValue(parseFloat(data.jurosMulta));
        //this.form.get('jurosMora').setValue(parseFloat(data.jurosMora));
        this.form.get('jurosMulta').setValue(data.jurosMultaFormatado);
        this.form.get('jurosMora').setValue(data.jurosMoraFormatado);
        this.form.get('observacao').setValue(data.observacao);
        this.form.get('dividaAtualizada').setValue(parseFloat(data.valorAtualizado));
        this.precificadorId = data.id

        console.log(this.form.value)
      }
      else {
        console.log('vazio')
      }
    })
  }

  async cadastrarCreditosAtualizados() {
    const obj = {
      id: this.precificadorId,
      Identificacao: this.form.get('identificacao').value,
      Titulo: this.form.get('nomeTitulo').value,
      DataOperacao: this.form.get('dataTitulo').value,
      ValorOperacao: parseFloat(this.form.get('valorTitulo').value),
      DataInadimplencia: this.form.get('datainadimplencia').value,
      ValorInadimplencia: parseFloat(this.form.get('valorAtualInadimplencia').value),
      IndiceCorrecaoId: parseInt(this.form.get('indice').value),
      CreditoAjuizado: this.form.get('creditoAjuizado').value,
      JurosMulta: this.form.get('jurosMulta').value,
      JurosMora: this.form.get('jurosMora').value,
      Observacao: this.form.get('observacao').value,
      ValorAtualizado: parseFloat(this.form.get('dividaAtualizada').value),
      UsuarioId: this.idUsuario,

    }
    console.log(obj)
    var data = await this.precificadorService.cadastrarCreditosAtualizados(obj);
    console.log(data);
    data.subscribe(d => {
      console.log(d.id);
      this.precificadorId = d.id;
      this.precificadorService.onChangeNumber(d.id)
      console.log(this.precificadorId);
    });

    // await Promise.resolve(this.precificadorService.cadastrarCreditosAtualizados(obj).subscribe(data => {
    //   this.precificadorIdNovo = data.id;
    //   console.log(data.id);
    //   alert(data.id);
    // }))
    //console.log(this.precificadorIdNovo);
    //this.changeNumber.emit(this.precificadorIdNovo);
    //this.changeNumber.emit(23);
  }

  calcularDivida() {
    const valorInadimplencia = this.form.get('valorAtualInadimplencia');
    const dataInadimplencia = this.form.get('datainadimplencia');

    console.log(valorInadimplencia.value, dataInadimplencia.value)
    if (!valorInadimplencia.value || !valorInadimplencia.value && valorInadimplencia.touched) {
      valorInadimplencia.setErrors({ required: true }); // Define um erro customizado
    }
    if (!dataInadimplencia.value || !dataInadimplencia.value && dataInadimplencia.touched) {
      dataInadimplencia.setErrors({ required: true }); // Define um erro customizado
    }

    if (this.form.invalid) {
      this.isInvalid = true;
      setTimeout(() => {
        this.isInvalid = false;
      }, 4500);
      this.openSnackBarErro('Aviso', 'Preencha os campos obrigatorios');
      return
    }
    else {
      this.ui.loading();
      const obj = {
        ValorInadimplencia: parseFloat(this.form.get('valorAtualInadimplencia').value),
        DataInadimplencia: this.form.get('datainadimplencia').value,
        DataOperacao: this.pipe.transform(this.dataHoje, 'yyyy-MM-dd'),
        Mora: parseFloat(this.form.get('jurosMora').value.replace(',', '.')),
        Multa: parseFloat(this.form.get('jurosMulta').value.replace(',', '.')),
        Indice: parseInt(this.form.get('indice').value),
      }
      this.precificadorService.calcularDivida(obj).subscribe(data => {
        this.ui.loaded().subscribe(() => {
          console.log(data)
          if (data) {
            this.form.get('dividaAtualizada').setValue(data.valorCalculo)
          }
        })
      },
        (err: any) => {
          this.ui.loaded().subscribe(() => {
            this.toast.info("Não foi possivel realizar o calculo no momento. Tente novamente mais tarde");
          })
        }
      );
    }
  }

  openSnackBarErro(titulo: string, mensagem: string) {
    this._snackBar.openFromComponent(MensageErrorResponseComponent, {
      data: {
        titulo: titulo,
        mensagem: mensagem,
      },
      panelClass: 'error',
      duration: 5000,
      horizontalPosition: 'right',
      verticalPosition: 'top'
    });
  }
}

