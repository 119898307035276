<!-- <app-header></app-header>
<app-nav></app-nav>
<router-outlet></router-outlet> -->
<!-- <app-header></app-header> -->
<app-banner-aviso *ngIf="verificaUrl() && exibeAviso"></app-banner-aviso>
<app-sidenav *ngIf="verificaUrl()"></app-sidenav>
<router-outlet *ngIf="!verificaUrl()"></router-outlet>


<!-- ===SPINNER QUANDO O LOADING FOR ATIVADO====== -->
<ngx-spinner bdColor="rgb(220 220 220 / 70%)" size="large" color="#00ccff" secondaryColor="#0078BF" type="ball-grid-pulse" [fullScreen]="true"></ngx-spinner>