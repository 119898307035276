<mat-accordion *ngIf="exibeIbamaAreasEmbargadas">
    <mat-expansion-panel class="mt-3 py-2" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <h3 class=" title-cordion mb-0 d-flex align-items-center">
            <mat-icon class="detech-icon">label_important</mat-icon>&nbsp; Restrições e penalidades ambientais
          </h3>
        </mat-panel-title>
  
      </mat-expansion-panel-header>
      <table class="table" *ngIf="!isLoadingIbamaAreasEmbargadas && !exibeMensagemIbamaAreasEmbargadas">
        <thead class="thead-light">
          <tr class="text-center">
            <th scope="col" class="thead-top-left p-2">Número</th>
            <th scope="col" class="thead p-2">Bioma</th>
            <th scope="col" class="thead p-2">Localização</th>
            <th scope="col" class="thead p-2">UF</th>
            <th scope="col" class="thead p-2">Municipio</th>
            <th scope="col" class="thead-top-right p-2">Área(HA)</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngIf="dataIbamaAreasEmbargadas.dados?.length > 0; else noDataIbamaAreasEmbargadas">
            <tr class="text-center table-info" *ngFor="let dados of dataIbamaAreasEmbargadas.dados">
              <td>{{dados?.no_tad}}</td>
              <td>{{dados?.bioma}}</td>
              <td>{{dados?.localizacao_imovel}}</td>
              <td>{{dados?.uf_embargo}}</td>
              <td>{{dados?.municipio_embargo}}</td>
              <td>{{dados?.area}}</td>
            </tr>
          </ng-container>
          <ng-template #noDataIbamaAreasEmbargadas>
            <tr>
              <td colspan="6" class="text-center">Não há resultados disponíveis</td>
            </tr>
          </ng-template>
        </tbody>
      </table>
      <div class="d-flex justify-content-center text-center flex-column" *ngIf="!isLoadingIbamaAreasEmbargadas && exibeMensagemIbamaAreasEmbargadas">
        <div class="d-flex justify-content-end" *ngIf="exibeReprocessarIbamaAreasEmbargadas">
          <button mat-flat-button class="gs-botaoAzulPadrao m-3" (click)="getIbamaAreasEmbargadas(idPesquisa, 129)">Refresh <mat-icon>cached
          </mat-icon></button>
        </div>
        <div>
          <p>{{dataIbamaAreasEmbargadas.mensagem}}</p>
        </div>
      </div>
       <loader-only *ngIf="isLoadingIbamaAreasEmbargadas"></loader-only>
    </mat-expansion-panel>
  </mat-accordion>