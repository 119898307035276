<div class="form-ext">
  <mat-card class="pesq-info">
    <mat-card-header class="title-pesq">
      <img class="img-title-pesq" src="../../../assets/icones/lupa-azul.svg" alt="view">
      <p>Localize dados através de: Nome, telefone, endereço ou e-mail </p>
    </mat-card-header>
    <form class="novapesq-form">
      <div class="container desk-form">
        <div class="d-flex  justify-content-center mt-4">
          <button class="btn m-1"
            [ngClass]="formPesquisa.tipoParametro == 'nome' ? 'buttonParametroActive' : 'buttonParametroInative'"
            (click)="changeTipoParametro('nome')">Nome</button>
          <button class="btn m-1"
            [ngClass]="formPesquisa.tipoParametro == 'telefone' ? 'buttonParametroActive' : 'buttonParametroInative'"
            (click)="changeTipoParametro('telefone')">Telefone</button>
          <button class="btn m-1"
            [ngClass]="formPesquisa.tipoParametro == 'email' ? 'buttonParametroActive' : 'buttonParametroInative'"
            (click)="changeTipoParametro('email')">Email</button>
          <button class="btn m-1"
            [ngClass]="formPesquisa.tipoParametro == 'placa' ? 'buttonParametroActive' : 'buttonParametroInative'"
            (click)="changeTipoParametro('placa')">Placa Veiculo</button>
          <!--<mat-button-toggle-group [(ngModel)]="formPesquisa.tipoParametro" name="fontStyle" aria-label="Font Style">
            <mat-button-toggle value="nome">Nome</mat-button-toggle>
            <mat-button-toggle value="telefone">Telefone</mat-button-toggle>
            <mat-button-toggle value="email">Email</mat-button-toggle>
          </mat-button-toggle-group>-->
        </div>

        <div class="row d-flex justify-content-left align-items-center">
          <div class="col d-flex" *ngIf="formPesquisa.tipoParametro == 'nome'">
            <button class="btn m-1"
              [ngClass]="formPesquisa.tipoPessoa == 'pessoas' ? 'buttonParametroActive' : 'buttonParametroInative'"
              (click)="changeColorTypePerson('pessoas')">Pessoas</button>
            <button class="btn m-1"
              [ngClass]="formPesquisa.tipoPessoa == 'empresas' ? 'buttonParametroActive' : 'buttonParametroInative'"
              (click)="changeColorTypePerson('empresas')">Empresas</button>

            <!--<mat-button-toggle-group class="changeTipoPessoaGroup" [(ngModel)]="formPesquisa.tipoPessoa"
              name="fontStyle" aria-label="Font Style">
              <mat-button-toggle class="changeTipoPessoa" id="personType" value="pessoas"
                (click)="changeColorTypePerson()">Pessoas</mat-button-toggle>
              <mat-button-toggle class="changeTipoPessoa" value="empresas" (click)="changeColorTypePerson()">Empresas
              </mat-button-toggle>
            </mat-button-toggle-group>-->
          </div>

          <div class="col-8" [hidden]="formPesquisa.tipoParametro != 'nome'">
            <mat-form-field class="example-full-width" appearance="outline"
              *ngIf="formPesquisa.tipoPessoa == 'empresas'">
              <mat-label>*Razão Social</mat-label>
              <input matInput type="text" name="nome" [(ngModel)]="formPesquisa.nome">
            </mat-form-field>
            <mat-form-field class="example-full-width" appearance="outline"
              *ngIf="formPesquisa.tipoPessoa == 'pessoas'">
              <mat-label>*Nome e Sobrenome</mat-label>
              <input matInput type="text" name="nome" [(ngModel)]="formPesquisa.nome">
            </mat-form-field>
            <small class="text-danger aviso-nome" *ngIf="avisoObrigatorio">*Campo Obrigatorio</small>
            <small class="text-danger aviso-nome" *ngIf="nomeIncorreto">*Digite um nome e sobrenome corretamente</small>
          </div>

          <div class="col" [hidden]="formPesquisa.tipoParametro != 'nome'">
            <mat-slide-toggle *ngIf="formPesquisa.tipoPessoa == 'pessoas'" [checked]="formPesquisa.nomeExato"
              (change)="formPesquisa.nomeExato = $event.source.checked" name="nomeExato">Nome Exato</mat-slide-toggle>
            <mat-slide-toggle *ngIf="formPesquisa.tipoPessoa == 'empresas'" [checked]="formPesquisa.nomeExato"
              (change)="formPesquisa.nomeExato = $event.source.checked" name="nomeExato">Razão Social Exata
            </mat-slide-toggle>
          </div>

          <div class="col-4" [hidden]="formPesquisa.tipoParametro != 'telefone'">
            <mat-form-field class="example-full-width" appearance="outline">
              <mat-label>Digite o Telefone</mat-label>
              <input matInput type="tel" name="telefone" mask="(00) 00000-0000" [(ngModel)]="formPesquisa.telefone"
                (keydown)="verificarEnter($event, 'telefone')">
            </mat-form-field>
            <small class="text-danger aviso-nome" *ngIf="formPesquisa.telefone && !isTelefoneValid()">*O número de
              telefone informado não está em um formato válido.</small>
          </div>

          <div class="col-4" [hidden]="formPesquisa.tipoParametro != 'email'">
            <mat-form-field class="example-full-width" appearance="outline">
              <mat-label>Digite o Email</mat-label>
              <input matInput type="email" name="email" [(ngModel)]="formPesquisa.email"
                (keydown)="verificarEnter($event, 'email')">
            </mat-form-field>
            <small class="text-danger aviso-nome" *ngIf="formPesquisa.email && !isEmailValid()">*O email informado não
              está em um formato válido.</small>
          </div>

          <div class="col-4" [hidden]="formPesquisa.tipoParametro != 'placa'">
            <mat-form-field class="example-full-width" appearance="outline">
              <mat-label>Digite a Placa do Veiculo</mat-label>
              <input matInput type="text" name="placa" [(ngModel)]="formPesquisa.placa" (input)="transformarMaiusculas()"
              (keypress)="filtrarCaracteresEspeciais($event)" (keydown)="verificarEnter($event, 'placa')" maxlength="7">
            </mat-form-field>
            <small class="text-danger aviso-nome" *ngIf="formPesquisa.placa && !isPlacaValid()">*A placa informada não
              está em um formato válido.</small>
          </div>

          <div class="col justify-content-start d-flex" [hidden]="formPesquisa.tipoParametro == 'nome'">
            <button *ngIf="formPesquisa.tipoParametro == 'telefone'" mat-flat-button class="gs-botaoAzulPadrao m-3"
              (click)="buscarPorTelefone()" [disabled]="!formPesquisa.telefone || !isTelefoneValid()">Iniciar Pesquisa
              <mat-icon>search</mat-icon></button>

            <button *ngIf="formPesquisa.tipoParametro == 'email'" mat-flat-button class="gs-botaoAzulPadrao m-3"
              (click)="buscarPorEmail()" [disabled]="!formPesquisa.email || !isEmailValid()">Iniciar Pesquisa
              <mat-icon>search</mat-icon></button>

            <button *ngIf="formPesquisa.tipoParametro == 'placa'" mat-flat-button class="gs-botaoAzulPadrao m-3"
              (click)="openConfirmationDialogPlaca()" [disabled]="!formPesquisa.placa || !isPlacaValid()">Iniciar Pesquisa
              <mat-icon>search</mat-icon></button>
          </div>
        </div>
        <small *ngIf="formPesquisa.tipoPessoa == 'pessoas' && formPesquisa.tipoParametro == 'nome' ">Pesquisa por
          pessoas será limitado a 10 resultados</small>

        <div class="row" [hidden]="formPesquisa.tipoParametro != 'nome'">
          <div class="col-3" [hidden]="formPesquisa.tipoParametro != 'nome'">
            <mat-form-field class="example-full-width" appearance="outline">
              <mat-label>CEP ou Logradouro</mat-label>
              <input matInput type="text" name="cepLogradouro" [(ngModel)]="formPesquisa.cepLogradouro">
            </mat-form-field>
          </div>

          <div class="col-2">
            <mat-form-field class="example-full-width" appearance="outline">
              <mat-label>Complemento</mat-label>
              <input matInput type="text" name="complemento" [(ngModel)]="formPesquisa.complemento">
            </mat-form-field>
          </div>

          <div class="col-2">
            <mat-form-field class="example-full-width" appearance="outline">
              <mat-label>Bairro</mat-label>
              <input matInput type="text" name="bairro" [(ngModel)]="formPesquisa.bairro">
            </mat-form-field>
          </div>


          <div class="col-2">
            <mat-form-field class="example-full-width" appearance="outline">
              <mat-label>Cidade</mat-label>
              <input matInput type="text" name="cidade" [(ngModel)]="formPesquisa.cidade">
            </mat-form-field>
          </div>
          <div class="col-1">
            <mat-form-field class="example-full-width" appearance="outline">
              <mat-label>UF</mat-label>
              <input matInput type="text" maxlength="2" name="uf" [(ngModel)]="formPesquisa.uf">
            </mat-form-field>
          </div>
          <div class="col-2">
            <button mat-flat-button class="gs-botaoAzulPadrao m-3" [disabled]="!formPesquisa.nome"
              (click)="buscarInformacoes('nome')">Iniciar Pesquisa
              <mat-icon>search</mat-icon></button>
          </div>
        </div>
      </div>

      <!-- ============Mobile===================== -->

      <div class="container mobile-form">
        <div class="d-flex  justify-content-center mt-4">
          <button class="btn m-1"
            [ngClass]="formPesquisa.tipoParametro == 'nome' ? 'buttonParametroActive' : 'buttonParametroInative'"
            (click)="changeTipoParametro('nome')">Nome</button>
          <button class="btn m-1"
            [ngClass]="formPesquisa.tipoParametro == 'telefone' ? 'buttonParametroActive' : 'buttonParametroInative'"
            (click)="changeTipoParametro('telefone')">Telefone</button>
          <button class="btn m-1"
            [ngClass]="formPesquisa.tipoParametro == 'email' ? 'buttonParametroActive' : 'buttonParametroInative'"
            (click)="changeTipoParametro('email')">Email</button>
          <button class="btn m-1"
            [ngClass]="formPesquisa.tipoParametro == 'placa' ? 'buttonParametroActive' : 'buttonParametroInative'"
            (click)="changeTipoParametro('placa')">Placa Veiculo</button>
          <!--<mat-button-toggle-group [(ngModel)]="formPesquisa.tipoParametro" name="fontStyle" aria-label="Font Style">
            <mat-button-toggle checked="true" value="nome">Nome</mat-button-toggle>
            <mat-button-toggle value="telefone">Telefone</mat-button-toggle>
            <mat-button-toggle value="email">Email</mat-button-toggle>
          </mat-button-toggle-group>-->
        </div>

        <div class="row d-flex justify-content-center align-items-center">

          <div class="col mt-2 d-flex justify-content-center" *ngIf="formPesquisa.tipoParametro == 'nome'">
            <button class="btn m-1"
              [ngClass]="formPesquisa.tipoPessoa == 'pessoas' ? 'buttonParametroActive' : 'buttonParametroInative'"
              (click)="changeColorTypePerson('pessoas')">Pessoas</button>
            <button class="btn m-1"
              [ngClass]="formPesquisa.tipoPessoa == 'empresas' ? 'buttonParametroActive' : 'buttonParametroInative'"
              (click)="changeColorTypePerson('empresas')">Empresas</button>
            <!--<mat-button-toggle-group class="changeTipoPessoaGroup" [(ngModel)]="formPesquisa.tipoPessoa"
              name="fontStyle" aria-label="Font Style">
              <mat-button-toggle class="changeTipoPessoa" id="personType" value="pessoas"
                (click)="changeColorTypePerson()">Pessoas</mat-button-toggle>
              <mat-button-toggle class="changeTipoPessoa" value="empresas" (click)="changeColorTypePerson()">Empresas
              </mat-button-toggle>
            </mat-button-toggle-group>-->
          </div>

          <div class="col" [hidden]="formPesquisa.tipoParametro != 'nome'">
            <mat-form-field class="example-full-width" appearance="outline"
              *ngIf="formPesquisa.tipoPessoa == 'empresas'">
              <mat-label>*Razão Social</mat-label>
              <input matInput type="text" name="nome" [(ngModel)]="formPesquisa.nome">
            </mat-form-field>
            <mat-form-field class="example-full-width" appearance="outline"
              *ngIf="formPesquisa.tipoPessoa == 'pessoas'">
              <mat-label>*Nome e Sobrenome</mat-label>
              <input matInput type="text" name="nome" [(ngModel)]="formPesquisa.nome">
            </mat-form-field>
            <small class="text-danger aviso-nome" *ngIf="nomeIncorreto">*Digite um nome e sobrenome corretamente</small>
          </div>

          <div class="col" [hidden]="formPesquisa.tipoParametro != 'nome'">
            <mat-slide-toggle *ngIf="formPesquisa.tipoPessoa == 'pessoas'" [checked]="formPesquisa.nomeExato"
              (change)="formPesquisa.nomeExato = $event.source.checked" name="nomeExato">Nome Exato</mat-slide-toggle>
            <mat-slide-toggle *ngIf="formPesquisa.tipoPessoa == 'empresas'" [checked]="formPesquisa.nomeExato"
              (change)="formPesquisa.nomeExato = $event.source.checked" name="nomeExato">Razão Social Exata
            </mat-slide-toggle>
          </div>

          <div class="col" [hidden]="formPesquisa.tipoParametro != 'telefone'">
            <mat-form-field class="example-full-width" appearance="outline">
              <mat-label>Digite o Telefone</mat-label>
              <input matInput type="tel" name="telefone" mask="(00) 00000-0000" [(ngModel)]="formPesquisa.telefone"
                (keydown)="verificarEnter($event, 'telefone')">
            </mat-form-field>
            <small class="text-danger aviso-nome" *ngIf="formPesquisa.telefone && !isTelefoneValid()">*O número de
              telefone informado não está em um formato válido.</small>
          </div>

          <div class="col " [hidden]="formPesquisa.tipoParametro != 'email'">
            <mat-form-field class="example-full-width" appearance="outline">
              <mat-label>Digite o Email</mat-label>
              <input matInput type="email" name="email" [(ngModel)]="formPesquisa.email"
                (keydown)="verificarEnter($event, 'email')">
            </mat-form-field>
            <small class="text-danger aviso-nome" *ngIf="formPesquisa.email && !isEmailValid()">*O email informado não
              está em um formato válido.</small>
          </div>

          <div class="col " [hidden]="formPesquisa.tipoParametro != 'placa'">
            <mat-form-field class="example-full-width" appearance="outline">
              <mat-label>Digite a Placa do Veiculo</mat-label>
              <input matInput type="text" name="placa" [(ngModel)]="formPesquisa.placa" (input)="transformarMaiusculas()"
                (keypress)="filtrarCaracteresEspeciais($event)" (keydown)="verificarEnter($event, 'placa')" maxlength="7">
            </mat-form-field>
            <small class="text-danger aviso-nome" *ngIf="formPesquisa.placa && !isPlacaValid()">*A placa informada não
              está em um formato válido.</small>
          </div>

          <div class="col m-2" [hidden]="formPesquisa.tipoParametro == 'nome'">
            <button *ngIf="formPesquisa.tipoParametro == 'telefone'" mat-flat-button class="gs-botaoAzulPadrao m-3"
              (click)="buscarPorTelefone()" [disabled]="!formPesquisa.telefone || !isTelefoneValid()">Iniciar Pesquisa
              <mat-icon>search</mat-icon></button>

            <button *ngIf="formPesquisa.tipoParametro == 'email'" mat-flat-button class="gs-botaoAzulPadrao m-3"
              (click)="buscarPorEmail()" [disabled]="!formPesquisa.email || !isEmailValid()">Iniciar Pesquisa
              <mat-icon>search</mat-icon></button>

            <button *ngIf="formPesquisa.tipoParametro == 'placa'" mat-flat-button class="gs-botaoAzulPadrao m-3"
              (click)="openConfirmationDialogPlaca()" [disabled]="!formPesquisa.placa || !isPlacaValid()">Iniciar Pesquisa
              <mat-icon>search</mat-icon></button>
          </div>
        </div>

        <div class="row" [hidden]="formPesquisa.tipoParametro != 'nome'">
          <div class="col" [hidden]="formPesquisa.tipoParametro != 'nome'">
            <mat-form-field class="example-full-width" appearance="outline">
              <mat-label>CEP ou Logradouro</mat-label>
              <input matInput type="text" name="cepLogradouro" [(ngModel)]="formPesquisa.cepLogradouro">
            </mat-form-field>
          </div>

          <div class="col">
            <mat-form-field class="example-full-width" appearance="outline">
              <mat-label>Complemento</mat-label>
              <input matInput type="text" name="complemento" [(ngModel)]="formPesquisa.complemento">
            </mat-form-field>
          </div>

          <div class="col">
            <mat-form-field class="example-full-width" appearance="outline">
              <mat-label>Bairro</mat-label>
              <input matInput type="text" name="bairro" [(ngModel)]="formPesquisa.bairro">
            </mat-form-field>
          </div>


          <div class="col">
            <mat-form-field class="example-full-width" appearance="outline">
              <mat-label>Cidade</mat-label>
              <input matInput type="text" name="cidade" [(ngModel)]="formPesquisa.cidade">
            </mat-form-field>
          </div>
          <div class="col">
            <mat-form-field class="example-full-width" appearance="outline">
              <mat-label>UF</mat-label>
              <input matInput type="text" maxlength="2" name="uf" [(ngModel)]="formPesquisa.uf">
            </mat-form-field>
          </div>
          <div class="col">
            <button mat-flat-button class="gs-botaoAzulPadrao m-3" [disabled]="!formPesquisa.nome"
              (click)="buscarInformacoes('nome')">Iniciar Pesquisa
              <mat-icon>search</mat-icon></button>
          </div>
        </div>
      </div>
    </form>
  </mat-card>

  <div class="separador"></div>
  <mat-tab-group [selectedIndex]="selectedIndex" class="mt-4" *ngIf="abas.length >= 1">
    <mat-tab *ngFor="let aba of abas; let i = index" [label]="aba.titulo" class="mt-4" (selectedTabChange)="atualizarIndiceAbaSelecionada($event)">
      <ng-template mat-tab-label class="d-flex align-items-center">
        <div class="col">{{aba.titulo}}</div>
        <button  mat-icon-button aria-label="Fechar" (click)="fecharAba(i)">
          <mat-icon class="icon-close-tab">close</mat-icon>
        </button>
      </ng-template>
      <app-resultado-localizador [retornoInfo]="aba.conteudo" [formPesquisa]="formPesquisa"></app-resultado-localizador>
    </mat-tab>
  </mat-tab-group>
</div>
