import { ChangeDetectorRef, Component, ElementRef, HostListener, Inject, LOCALE_ID, OnInit, Renderer2, ViewChild, QueryList, ViewChildren, ViewEncapsulation, Input } from '@angular/core';
import { TeiaRelacionamentoService } from 'src/app/shared/services/teia-relacionamento/teia-relacionamento.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MenuTeiaRelacionamentoComponent } from './menu-teia-relacionamento/menu-teia-relacionamento.component';
import { UiService } from 'src/app/shared/services/ui.service';
import { ModalMinhasPesquisasComponent } from './menu-teia-relacionamento/acoes-menu/modal-minhas-pesquisas/modal-minhas-pesquisas.component';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { MenuTeiaItens } from './teia-relacionamento.enum';
import { Location } from '@angular/common';
import { Node, Edge, ClusterNode, DagreLayout, Graph, GraphComponent } from '@swimlane/ngx-graph';
import * as dagre from 'dagre';
import { id } from '@swimlane/ngx-charts';
import { Subject } from 'rxjs';
import { AuthService } from 'src/app/shared/services/http/auth.service';
import { formatDate } from '@angular/common';
import html2pdf from 'html2pdf.js';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { MensageResponseComponent } from 'src/app/components/view/mensage-response/mensage-response.component';
import { MensageErrorResponseComponent } from 'src/app/components/view/mensage-error-response/mensage-error-response.component';
import { JanelaSelecaoComponent } from 'src/app/components/view/janela-selecao/janela-selecao.component';
import { MatCheckbox } from '@angular/material/checkbox';
import { EventService } from 'src/app/shared/services/event.service';
import { ModalAdicionarGraficoComponent } from './menu/modal-adicionar-grafico/modal-adicionar-grafico.component';
import { NavService } from 'src/app/components/template/nav/nav.service';
import { HeaderService } from 'src/app/components/template/header/header.service';
import { DETECH_BASE64 } from 'src/assets/img/detech-base64';
import { ModalMenorIdadeComponent } from 'src/app/components/template/produto-pesquisa/modal-menor-idade/modal-menor-idade.component';
import { ModalNoCustomizadoComponent } from './menu-teia-relacionamento/acoes-menu/modal-no-customizado/modal-no-customizado.component';

@Component({
  selector: 'app-teia-relacionamento',
  templateUrl: './teia-relacionamento.component.html',
  styleUrls: ['./teia-relacionamento.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class TeiaRelacionamentoComponent implements OnInit {
  @ViewChild(JanelaSelecaoComponent) seletorComponent!: JanelaSelecaoComponent;
  @ViewChildren('nodeElements') nodeElements: QueryList<ElementRef>;
  @ViewChild('grafico') graficoElementRef!: ElementRef;
  @ViewChild('grafico') graficoRef: GraphComponent | undefined;
  @ViewChild('graficoSeletor') graficoSeletorRef: JanelaSelecaoComponent | undefined;
  dadosGrafico = [];
  nodes: any[] = [];
  links: any[] = [];
  tipoConexao: string;
  arraySelected = []
  nosSelecionados;
  selecionarNos = false;
  exibirDrawer = false;
  desabilitaPan = false
  menu: any;
  usuario;
  timeoutId;
  exibeToast = false
  statusBaixandoPdf = false;
  valorRecebido: string;
  valorRecebidoNoCustomizado
  situacaoCadastral;

  public teiaForm: FormGroup;
  public documento: FormControl;
  teiaMenuItens: typeof MenuTeiaItens = MenuTeiaItens;


  customLayout = new GraphLayout();
  panToNode$: Subject<String> = new Subject();

  setting = {
    orientation: 'LR',
    marginX: 50,
    marginY: 50,
    edgePadding: 100,
    rankPadding: 200,
    nodePadding: 50,
    multigraph: true,
    compound: true,
  };

  exportTeiaAltaResolucao = {
    margin: 1,
    filename: 'newfile.pdf',
    image: {
      type: 'svg',
      quality: '0.90',
    },
    html2canvas: {
      scale: 2,
    },
    jsPDF: {
      unit: 'in',
      format: 'A2',
      orientation: 'landscape',
    },
  };

  exportTeiaMediaResolucao = {
    margin: 1,
    filename: 'newfile.pdf',
    image: {
      type: 'svg',
      quality: '0.90',
    },
    html2canvas: {
      scale: 1.5,
    },
    jsPDF: {
      unit: 'in',
      format: 'A2',
      orientation: 'landscape',
    },
  };

  exportTeiaBaixaResolucao = {
    margin: 1,
    filename: 'newfile.pdf',
    image: {
      type: 'svg',
      quality: '0.90',
    },
    html2canvas: {
      scale: 1,
    },
    jsPDF: {
      unit: 'in',
      format: 'A2',
      orientation: 'landscape',
    },
  };


  constructor(
    public teiaRelacionamentoService: TeiaRelacionamentoService,
    private formBuilder: FormBuilder,
    public teiaRelacionamentoService2: TeiaRelacionamentoService,
    private renderer: Renderer2,
    public authService: AuthService,
    private ui: UiService,
    public dialog: MatDialog,
    public location: Location,
    public router: Router,
    private _snackBar: MatSnackBar,
    private eventService: EventService,
    private ref: ChangeDetectorRef,
    private route: ActivatedRoute,
    private navService: NavService,
    private headerService: HeaderService,
    @Inject(LOCALE_ID) private locale: string
  ) {
    navService.navData = {
      title: 'Patrimoniais'
    }
    headerService.headerData = {
      title: 'Patrimoniais'
    }
    this.documento = new FormControl('', [Validators.required, Validators.minLength(11), Validators.maxLength(14)])
    this.teiaForm = formBuilder.group({
      documento: this.documento
    });
    this.renderer.listen('window', 'click', (e: Event) => {
      const clickTarget = event.target as HTMLElement;
    });
    this.checkTimeOut();
    this.eventService.emitter.subscribe(item => {
      this.nodes = this.dadosGrafico.map(node => {
        if (node.key == item.key && node.value == item.value) {
          return item;
        }
        return node;
      });
      this.highChart()
    });
  }


  ngOnInit() {
    this.iniciarGraficoDestacado();
    this.authService.callbackUsuario().subscribe(usuario => {
      this.usuario = usuario;
    });

    setInterval(() => {
      this.autoSave();
    }, 5000);

    this.maxWidthLabel();
    this.seletorComponent.containerRef = this.graficoElementRef;
  }

  iniciarGraficoDestacado() {
    this.route.queryParams.subscribe(params => {
      if (params.hasOwnProperty('documento')) {
        // Parâmetro 'parametro1' está presente na URL
        const valorParametro = params['documento'];
        this.teiaForm.setValue({
          documento: valorParametro,
        });
        this.buscarDocumento()
      } else {
        return
      }
    });
  }

  iniciaDetechVisualizacaoTela(dadosVisaoTela) {
    if (dadosVisaoTela) {
      // Parâmetro 'parametro1' está presente na URL
      this.teiaForm.setValue({
        documento: dadosVisaoTela,
      });
      this.buscarDocumento()
    } else {
      return
    }
  }

  onSelecaoChange(selectedNodes: any[]) {
    selectedNodes = selectedNodes.map(str => str.trim());
    if (this.graficoRef) {
      const nos = this.graficoRef.graph.nodes.map(x => x.id);
      if (selectedNodes.some(item => nos.includes(item))) {
        this.nodes.forEach(objeto => {
          // Verifique se o ID do objeto está presente no array de strings
          if (selectedNodes.includes(objeto.id)) {
            // Modifique a propriedade "nome" conforme a lógica desejada
            objeto.nodeWidth = 22;
            objeto.color = "#fff";
            objeto.stroke = "#3094b4";
            objeto.dasharray = 6;
            objeto.strokeWidth = 3;
          }
        });
      } else {
      }
    }
  }

  // Método para receber o evento de seleção do componente janelaSelecao
  onJanelaSelecaoChange(selectedNodes: string[]) {
    this.onSelecaoChange(selectedNodes);
  }

  checkTimeOut() {
    this.timeoutId = setTimeout(
      () => this.avisoLogout(), 21600000
    );
  }

  @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if (event.key === "Escape") {
      if (this.dadosGrafico.length >= 1 && this.arraySelected.length >= 1) {

        this.nodes = this.nodes.map((strokeWidth) => ({
          ...strokeWidth,
          strokeWidth: '',
          nodeWidth: 10,
          color: '',
          stroke: '',
          dasharray: '',
        }))


      }
    }
  }

  teste(event: MouseEvent, data) {
    if (event.ctrlKey && this.dadosGrafico.length > 0) {
      this.desabilitaPan = true
      document.getElementById('element').style.pointerEvents = 'bounding-box'
    }
    else {
      this.desabilitaPan = false
      document.getElementById('element').style.pointerEvents = 'all'
    }
  }

  habilitarSelecao(event: MouseEvent) {
    if (event.ctrlKey && this.dadosGrafico.length > 0) {
      this.desabilitaPan = true
      document.getElementById('element').style.pointerEvents = 'none'
    }
  }


  avisoLogout() {
    setTimeout(() => {
      this.router.navigate(['/auth/login']);
    }, 7000);
    this.openSnackBar('Aviso', 'O Usuario sera deslogado por inatividade');
  }

  @HostListener('window:keydown')
  @HostListener('window:mousedown')
  checkUserActivity() {

    clearTimeout(this.timeoutId);

    this.checkTimeOut();
  }

  maxWidthLabel() {
    let label = document.getElementById("labelText").toString().substring(0, 10)
    return label
  }

  autoSave() {
    if (this.nodes.length > 0) {
      this.salvarSemLoading();
    }
    else {
      return
    }
  }

  desabilitaPesquisa() {
    const docForm = this.teiaForm.get('documento').value.replaceAll('.', '').replaceAll('-', '').replaceAll('/', '');
    if (docForm.length === 11 || docForm.length === 14) {
      return true
    }
    return false
  }

  exportAltaResolucao() {
    this.statusBaixandoPdf = true
    this.ui.loading();
    const usuario = this.usuario.nome;
    const docForm = this.teiaForm.get('documento').value.replaceAll('.', '').replaceAll('-', '').replaceAll('/', '');
    const date = formatDate(Date.now(), 'yyyy-MM-dd', this.locale)
    this.exportTeiaAltaResolucao.filename = `DeTech-${usuario}-${docForm}-${date}`;
    const pEl = document.getElementById('element');
    this.ui.loaded().subscribe(() => {
      html2pdf().from(pEl).set(this.exportTeiaAltaResolucao).save();
    })
    this.statusBaixandoPdf = false
  }

  exportMediaResolucao() {
    this.statusBaixandoPdf = true
    this.ui.loading();
    const usuario = this.usuario.nome;
    const docForm = this.teiaForm.get('documento').value.replaceAll('.', '').replaceAll('-', '').replaceAll('/', '');
    const date = formatDate(Date.now(), 'yyyy-MM-dd', this.locale)
    this.exportTeiaMediaResolucao.filename = `DeTech-${usuario}-${docForm}-${date}`;
    const pEl = document.getElementById('element');
    this.ui.loaded().subscribe(() => {
      html2pdf().from(pEl).set(this.exportTeiaMediaResolucao).save();
    })
    this.statusBaixandoPdf = false
  }

  exportBaixaResolucao() {
    this.statusBaixandoPdf = true
    this.ui.loading();
    const usuario = this.usuario.nome;
    const docForm = this.teiaForm.get('documento').value.replaceAll('.', '').replaceAll('-', '').replaceAll('/', '');
    const date = formatDate(Date.now(), 'yyyy-MM-dd', this.locale)
    this.exportTeiaBaixaResolucao.filename = `DeTech-${usuario}-${docForm}-${date}`;
    const pEl = document.getElementById('element');
    this.ui.loaded().subscribe(() => {
      html2pdf().from(pEl).set(this.exportTeiaBaixaResolucao).save();
    })
    this.statusBaixandoPdf = false
  }
  renderizaTeiaComImagem(data) {
    this.nosSelecionados = [];
    this.arraySelected = [];
    this.teiaRelacionamentoService.buscarDocumento(data).subscribe(data => {
      this.dadosGrafico = data
      this.highChart()

    })
  }



  select(id: any) {
    this.panToNode$.next(id);
  }


  buscarDocumento() {
    this.ui.loading();
    if (this.teiaForm.valid) {
      this.dadosGrafico = []
      const docForm = this.teiaForm.get('documento').value.replaceAll('.', '').replaceAll('-', '').replaceAll('/', '');
     let  objBuscarDoc = {
        docForm: docForm,
        tipoPesquisa: 1
      }
      this.teiaRelacionamentoService.buscarDocumento(this.teiaForm.get('documento').value).subscribe(data => {
        this.ui.loaded().subscribe(() => {
          this.dadosGrafico = data
          if (data.descricao) {
            this.openSnackBarErro('Status', data.descricao)
          }
          else {
            this.highChart();
            this.select(this.dadosGrafico[0].key);
            setTimeout(() => { this.highChart(); this.select(this.dadosGrafico[0].key); }, 10);
            this.exibirDrawer = true
          }
        })


      }, (err: any) => {
        if(err.error.codigo == 20 && err.error.descricao == "este cpf pertence a menor de idade, é necessária a data de aniversário para processar a solicitação"){
          this.ui.loaded().subscribe(() => {
            this.abrirModalMenorIdade(objBuscarDoc);
          })
        }
         if(err.error.codigo === 999){
          this.ui.loaded().subscribe(() => {
            this.openSnackBarErro('Atenção', 'ocorreu um erro ao realizar a consulta na base de dados');
          })
        }
        else{
          this.ui.loaded().subscribe(() => {
            this.openSnackBarErro('Atenção', err.error.descricao);
          })
        }
      });
    }
  }

  abrirModalMenorIdade(doc){
    const docForm = this.teiaForm.get('documento').value.replaceAll('.', '').replaceAll('-', '').replaceAll('/', '');
    const modalMenorIdade = this.dialog.open(ModalMenorIdadeComponent, {
      maxWidth: "470px",
      data: doc
    })
    modalMenorIdade.afterClosed().subscribe(result => {
      if (result && result.isNovoGraph) {
        this.dadosGrafico = result.data
        this.highChart();
        this.select(this.dadosGrafico[0].key);
        setTimeout(() => { this.highChart(); this.select(this.dadosGrafico[0].key); }, 10);
        this.exibirDrawer = true
      }
      if(result && result.isAddGraph){
        this.highChart()
        this.openSnackBar('Sucesso', 'Gráfico adicionado com Sucesso')
      }

      if(result && result.isExpandGraph){
        this.highChart()
        this.openSnackBar('Sucesso', 'Expansao Realizada com Sucesso')
      }
      // else {
      //   this.openSnackBarErro('Detech', 'Ocorreu um erro, tente novamente')
      // }
      this.renderizaTeiaComImagem(docForm)
    });
  }

  centralizar() {
    this.select(this.dadosGrafico[0].key);
    setTimeout(() => { this.highChart(); this.select(this.dadosGrafico[0].key); }, 10);
  }

  desbilitarPan(event: MatCheckbox) {
    if (event.checked) {
      this.desabilitaPan = true
      document.getElementById('element').style.pointerEvents = 'bounding-box'
    }
    else {
      this.desabilitaPan = false
      document.getElementById('element').style.pointerEvents = 'all'
    }
  }

  novaChamadaGraph(data) {
    this.ui.loading();
    const id = data.replaceAll('.', '').replaceAll('-', '').replaceAll('/', '');
    const docForm = this.teiaForm.get('documento').value.replaceAll('.', '').replaceAll('-', '').replaceAll('/', '');
    this.dadosGrafico = []
    var nodesComImagem = this.nodes.map(x => x)
    var nodes = this.nodes.map(x => { delete x.img; return x; })
    let objExpandir = {
      docForm: docForm,
      id: id,
      nodes: nodes,
      tipoPesquisa: 3
    }
    this.teiaRelacionamentoService.buscarDocumento2(docForm, id, nodes).subscribe(data => {
      this.ui.loaded().subscribe(() => {
        this.dadosGrafico = data
        if (data.descricao) {
          this.openSnackBarErro('Status', data.descricao)
          this.salvarNosGraphSemMensagem()
        }
        if (this.dadosGrafico && !data.descricao) {
          this.highChart()
          this.openSnackBar('Sucesso', 'Expansao Realizada com Sucesso')
        }
      })
    }, (err: any) => {
        if(err.error.codigo === 20 && err.error.descricao == "este cpf pertence a menor de idade, é necessária a data de aniversário para processar a solicitação"){
          this.ui.loaded().subscribe(() => {
          this.abrirModalMenorIdade(objExpandir)
          return
          })
        }
        if (err.error.descricao && err.error.codigo != 20) {
          this.ui.loaded().subscribe(() => {
          this.openSnackBarErro('Detech', err.error.descricao)
        })
        }
        this.renderizaTeiaComImagem(docForm)
    });
  }

  salvarNosGraph() {
    this.ui.loading();
    const docForm = this.teiaForm.get('documento').value.replaceAll('.', '').replaceAll('-', '').replaceAll('/', '');
    this.dadosGrafico = []
    this.teiaRelacionamentoService.salvarNos(docForm, this.nodes).subscribe(data => {
      this.ui.loaded().subscribe(() => {
        this.dadosGrafico = data
        if (data.descricao) {
          this.openSnackBarErro('Status', data.descricao)
          this.renderizaTeiaComImagem(docForm)
        }
        if (this.dadosGrafico && !data.descricao) {
          this.highChart()
          this.openSnackBar('Sucesso', 'Gráfico Salvo com Sucesso')
        }
      })
    });
  }

  salvarNosGraphSemMensagem() {
    this.ui.loading();
    const docForm = this.teiaForm.get('documento').value.replaceAll('.', '').replaceAll('-', '').replaceAll('/', '');
    this.dadosGrafico = []
    this.teiaRelacionamentoService.salvarNos(docForm, this.nodes).subscribe(data => {
      this.ui.loaded().subscribe(() => {
        this.dadosGrafico = data
        if (data.descricao) {
          this.openSnackBarErro('Status', data.descricao)
          this.renderizaTeiaComImagem(docForm)
        }
        if (this.dadosGrafico && !data.descricao) {
          this.highChart()
        }
      })
    });
  }

  salvarSemLoading() {
    const docForm = this.teiaForm.get('documento').value.replaceAll('.', '').replaceAll('-', '').replaceAll('/', '');
    this.dadosGrafico = []
    this.teiaRelacionamentoService.salvarNos(docForm, this.nodes).subscribe(data => {
      this.dadosGrafico = data
      if (data.descricao) {
        this.openSnackBarErro('Status', data.descricao)
        this.renderizaTeiaComImagem(docForm)
      }
      if (this.dadosGrafico && !data.descricao) {
        return
      }
    });
  }

  adicionarNoCustomizado(id){
    const menu = this.dialog.open(ModalNoCustomizadoComponent, {
      width: '500px',
      panelClass: 'bg-color',
      height:'300px'
    });
    menu.componentInstance.valorSelecionado.subscribe((valor: { documento: string, nome: string, descricao: string }) => {
      console.log(valor)
      if (valor.nome || valor.documento) {
        this.valorRecebidoNoCustomizado = valor;
        this.getAdicionarNoCustomizado(valor, id);
      }
    });
  }

  getAdicionarNoCustomizado(data, id) {
    this.ui.loading();
    const docForm = this.teiaForm.get('documento').value;
    this.dadosGrafico = []
    var nodes = this.nodes.map(x => { delete x.img; return x; })
    let objAddGraficoCustomizado = {
      CpfCnpjOrigemMapa: docForm,
      documentoRelacionado:id,
      novaRelacao: data ? (data.documento || data.nome) : undefined, 
      descricao:data.descricao,
      nos: nodes,
    }
    this.teiaRelacionamentoService.adicionarNoCustomizado(
      objAddGraficoCustomizado.CpfCnpjOrigemMapa,
      objAddGraficoCustomizado.documentoRelacionado,
      objAddGraficoCustomizado.novaRelacao,
      objAddGraficoCustomizado.descricao,
      objAddGraficoCustomizado.nos).subscribe(data => {
      this.ui.loaded().subscribe(() => {
        this.dadosGrafico = data?.graphs;
        if (data.descricao) {
          console.log('caiu 01')
          this.salvarNosGraphSemMensagem()
        }
        if (data.graphs) {
          console.log('caiu 02')
          this.highChart()
          this.openSnackBar('Sucesso', 'Gráfico adicionado com Sucesso')
        }
      })
    }, (err: any) => {
      if(err.error.codigo == 20 && err.error.descricao == "este cpf pertence a menor de idade, é necessária a data de aniversário para processar a solicitação"){
        this.ui.loaded().subscribe(() => {
         this.abrirModalMenorIdade(objAddGraficoCustomizado)
        })
      }
      else{
        this.ui.loaded().subscribe(() => {
          this.openSnackBarErro('Detech', err.error.descricao);
          this.renderizaTeiaComImagem(docForm)
        })
      }
    });
  }

  getGraphHide(data, exibirNo) {
    this.ui.loading();
    const id = data.replaceAll('.', '').replaceAll('-', '').replaceAll('/', '');
    const docForm = this.teiaForm.get('documento').value.replaceAll('.', '').replaceAll('-', '').replaceAll('/', '');
    this.dadosGrafico = []
    this.teiaRelacionamentoService.exibirOcultarNo(docForm, id, exibirNo).subscribe(data => {
      this.ui.loaded().subscribe(() => {
        this.dadosGrafico = data
        if (data.descricao) {
          this.openSnackBarErro('Status', data.descricao)
          this.renderizaTeiaComImagem(docForm)
        }
        if (this.dadosGrafico && !data.descricao) {
          this.highChart()
          this.openSnackBar('Sucesso', 'Requisição Realizada com Sucesso')
        }
      })
    });
  }

  exibirOcultarFiliais(data, exibirFiliais) {
    this.ui.loading();
    const id = data.replaceAll('.', '').replaceAll('-', '').replaceAll('/', '');
    const docForm = this.teiaForm.get('documento').value.replaceAll('.', '').replaceAll('-', '').replaceAll('/', '');
    this.dadosGrafico = []
    var nodes = this.nodes.map(x => { delete x.img; return x; })
    this.teiaRelacionamentoService.exibirOcultarFiliais(docForm, id, nodes, exibirFiliais).subscribe(data => {
      this.ui.loaded().subscribe(() => {
        this.dadosGrafico = data
        if (data.descricao) {
          this.openSnackBarErro('Status', data.descricao)
          this.renderizaTeiaComImagem(docForm)
        }
        if (this.dadosGrafico && !data.descricao) {
          this.highChart()
          this.openSnackBar('Sucesso', 'Requisição Realizada com Sucesso')
        }
      })
    });
  }

  exibirOcultarPessoasRelacionadas(data, exibir) {
    this.ui.loading();
    const id = data.replaceAll('.', '').replaceAll('-', '').replaceAll('/', '');
    const docForm = this.teiaForm.get('documento').value.replaceAll('.', '').replaceAll('-', '').replaceAll('/', '');
    this.dadosGrafico = []
    var nodes = this.nodes.map(x => { delete x.img; return x; })
    this.teiaRelacionamentoService.exibirOcultarPessoasRelacionadas(docForm, id, nodes, exibir).subscribe(data => {
      this.ui.loaded().subscribe(() => {
        this.dadosGrafico = data
        if (data.descricao) {
          this.openSnackBarErro('Status', data.descricao)
          this.renderizaTeiaComImagem(docForm)
        }
        if (this.dadosGrafico && !data.descricao) {
          this.highChart()
          this.openSnackBar('Sucesso', 'Requisição Realizada com Sucesso')
        }
      })
    });
  }

  openMinhasPesquisas() {
    const menu = this.dialog.open(ModalMinhasPesquisasComponent, {
      width: 'auto',
      panelClass: 'bg-color',
    });
  }

  selecionarNo(event: MouseEvent, data) {
    if (event.ctrlKey) {
      if (data.id.replaceAll('.', '').replaceAll('-', '').replaceAll('/', '') == this.teiaForm.get('documento').value) {
        this.openSnackBarErro('Detech', 'não é possivel selecionar o nó pesquisado.')
        return
      }
      if (this.arraySelected.includes(data)) {
        var indice = this.arraySelected.indexOf(data);
        this.arraySelected.splice(indice, 1);
        data.nodeWidth = 10;
        data.color = '#3094b4'
        data.stroke = 'transparent';
        data.dasharray = '';
        data.strokeWidth = '';
      }
      else {
        this.arraySelected.push(data)
        data.nodeWidth = 22;
        data.color = '#fff';
        data.stroke = '#3094b4';
        data.dasharray = 6;
        data.strokeWidth = 3;

      }
      this.nosSelecionados = this.arraySelected.map(x => x.id)
    }
  }

  selecionarTodosOsNos(event: MatCheckbox) {
    if (event.checked) {
      this.selecionarNos = true
      this.arraySelected.push(this.nodes)
      this.nodes = this.nodes.map((strokeWidth) => ({
        ...strokeWidth,
        strokeWidth: 3,
        nodeWidth: 22,
        color: '#fff',
        stroke: '#3094b4',
        dasharray: 6,
      }))
    }
    else {
      this.selecionarNos = false
      var indice = this.arraySelected.indexOf(this.nodes);
      this.arraySelected.splice(indice, 1);
      this.nodes = this.nodes.map((strokeWidth) => ({
        ...strokeWidth,
        strokeWidth: '',
        nodeWidth: 10,
        color: '#3094b4',
        stroke: 'transparent',
        dasharray: '',
      }))
    }
  }

  ocultarNosSelecionados() {
    const docForm = this.teiaForm.get('documento').value.replaceAll('.', '').replaceAll('-', '').replaceAll('/', '');
    const obj = {
      CpfCnpjOrigemMapa: this.teiaForm.get('documento').value,
      ListaCpfCnpj: this.nosSelecionados
    }
    this.teiaRelacionamentoService.exibirOcultarMultiplosNos(obj).subscribe(data => {
      this.ui.loaded().subscribe(() => {
        this.dadosGrafico = data;
        if (data.descricao) {
          this.openSnackBarErro('Status', data.descricao)
          this.renderizaTeiaComImagem(docForm)
        }
        if (this.dadosGrafico && !data.descricao) {
          this.highChart()
          this.openSnackBar('Sucesso', 'Requisição Realizada com Sucesso')
        }
      })
    });
  }


  openMenu(data, event: MouseEvent) {
    if(data.id.includes('*')){
      this.openSnackBarErro('Detech', 'Documentos incompletos não podem ser pesquisados.')
      return
    }
    if(!data.isDocument && (data.tipoExpansao =! 4)){
      this.openSnackBarErro('Detech', 'Documentos incompletos não podem ser pesquisados.')
      return
    }
    if (event.ctrlKey) {
      return
    }
    const id = data.id;
    const docForm = this.teiaForm.get('documento').value.replaceAll('.', '').replaceAll('-', '').replaceAll('/', '');
    const menu = this.dialog.open(MenuTeiaRelacionamentoComponent, {
      width: '330px',
      panelClass: 'bg-color',
      data: {
        id: id,
        docForm: docForm,
        filiaisOcultas: data.filiaisOcultas,
        nosOcultos: data.nosOcultos,
        noExpandido: data.noExpandido,
        noSemFiliais: data.noSemFiliais,
        noSemPessoasRelacionadas: data.noSemPessoasRelacionadas,
        pessoasRelacionadasOcultas: data.pessoasRelacionadasOcultas,
      }
    });
    menu.afterClosed().subscribe(result => {
      if (result == this.teiaMenuItens.expandirNos) {
        this.novaChamadaGraph(id)
      }
      else if (result == this.teiaMenuItens.exibirNoOculto) {
        this.getGraphHide(id, true)
      }
      else if (result == this.teiaMenuItens.ocultarNo) {
        this.getGraphHide(id, false)
      }
      else if (result == this.teiaMenuItens.exibirFiliais) {
        this.exibirOcultarFiliais(id, true)
      }
      else if (result == this.teiaMenuItens.ocultarFiliais) {
        this.exibirOcultarFiliais(id, false)
      }
      else if (result == this.teiaMenuItens.exibirPessoasRelacionadas) {
        this.exibirOcultarPessoasRelacionadas(id, true)
      }
      else if (result == this.teiaMenuItens.ocultarPessoasRelacionadas) {
        this.exibirOcultarPessoasRelacionadas(id, false)
      }
      else if (result == this.teiaMenuItens.informacoesAdicionais) {
        this.salvarSemLoading()
      }
      else if (result == this.teiaMenuItens.adicionarNoCustomizado){
       this.adicionarNoCustomizado(id)
      }
    })
    return id
  }

  highChart() {
    console.log('chamou')
    this.links = [];
    this.nodes = [];
    this.nosSelecionados = [];
    this.arraySelected = [];
    var dados = this.dadosGrafico.filter(d => d.exibirNo);
    dados.forEach((d, i) => {
      if (d.nome == "" || this.nodes.some(n => n.id == d.key)) return;
      var name = d.nome;
      name = name.substring(0, 26) + `...`
      var node: any = { 
        id: d.key, 
        key: d.key, 
        value: d.value, 
        label: name, 
        filiaisOcultas: d.filiaisOcultas, 
        nosOcultos: d.nosOcultos, 
        noExpandido: d.noExpandido, 
        isDocument: d.isDocument, 
        noSemFiliais: d.noSemFiliais, 
        color: d.cor, 
        situacao: d.situacaoCadastral, 
        exibirNo: d.exibirNo, 
        position: d.position,
        img: d.tipoExpansao === 4 
    ? (d.tipoCpfCnpj === 2 ? DETECH_BASE64.imgJuridicoCustomizado : DETECH_BASE64.imgFisicaCustomizado)
    : (d.tipoCpfCnpj === 1 && d.ativo === true ? DETECH_BASE64.imgFisicaAtiva
        : d.tipoCpfCnpj === 1 && d.ativo == null ? DETECH_BASE64.imgFisica // Cobre null, undefined e ausência da propriedade
        : d.tipoCpfCnpj === 1 && d.ativo === false ? DETECH_BASE64.imgFisicaInativa
        : d.tipoCpfCnpj === 2 && d.ativo === true ? DETECH_BASE64.imgJuridicaAtiva
        : d.tipoCpfCnpj === 2 && d.ativo === false ? DETECH_BASE64.imgJuridicaInativa
        : DETECH_BASE64.imgJuridico), 
        nodeWidth: 10,
        pessoasRelacionadasOcultas: d.pessoasRelacionadasOcultas, 
        noSemPessoasRelacionadas: d.noSemPessoasRelacionadas, 
        stroke: '', 
        dasharray: '', 
        strokeWidth: '', 
      };
      this.nodes.push(node)
    });

    dados.forEach((d, i) => {
      if (d.key == d.value || d.nome == "" || this.links.some(l => l.source == d.value && l.target == d.key)) return;
    
      // Verifica se tipoVinculo deve ser considerado
      var tipoVinculo = d.tipoVinculo !== '-' ? d.tipoVinculo : '';
    
      // Define o rótulo baseado nas regras
      var labelParts = d.descricaoNo ? [tipoVinculo, d.descricaoNo].filter(Boolean).join(', ') : tipoVinculo;
    
      // Se ambos forem vazios, labelParts deve ser ""
      if (!tipoVinculo && !d.descricaoNo) labelParts = '';
    
      var edge = { id: 'a' + i.toString(), source: d.value, target: d.key, label: labelParts };
      this.links.push(edge);
    });    
  }


  backRoute() {
    this.ui.loading();
    this.ui.loaded().subscribe(() => {
      this.router.navigate(['/dashboard']).then(() => {
        window.location.reload();
      });
    })
  }

  transformNode(node) {
    var value = node.dimension.width / 2.4 * 1.1;

    return `transform: translate(${value}px,10px)`
  }

  openSnackBar(titulo: string, mensagem: string) {
    this._snackBar.openFromComponent(MensageResponseComponent, {
      data: {
        titulo: titulo,
        mensagem: mensagem,
      },
      duration: 5000,
      horizontalPosition: 'right',
      verticalPosition: 'bottom'
    });
  }

  openSnackBarErro(titulo: string, mensagem: string) {
    this._snackBar.openFromComponent(MensageErrorResponseComponent, {
      data: {
        titulo: titulo,
        mensagem: mensagem,
      },
      panelClass: 'error',
      duration: 5000,
      horizontalPosition: 'right',
      verticalPosition: 'bottom'
    });
  }

  adicionarGrafico() {
    const menu = this.dialog.open(ModalAdicionarGraficoComponent, {
      width: 'auto',
      panelClass: 'bg-color',
    });
    menu.componentInstance.valorSelecionado.subscribe((valor: string) => {
      if (valor !== null) {
        // Chamar a API apenas se o valor não for nulo
        this.valorRecebido = valor;
        this.getAdicionarGrafico(valor)
      }
    });
  }

  toggleSubMenu(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    const target = event.target as HTMLElement;
    const parent = target.closest('.dropdown-submenu');
    if (parent) {
      parent.classList.toggle('show');
    }
  }

  getAdicionarGrafico(data) {
    this.ui.loading();
    const id = data.replaceAll('.', '').replaceAll('-', '').replaceAll('/', '');
    const docForm = this.teiaForm.get('documento').value.replaceAll('.', '').replaceAll('-', '').replaceAll('/', '');
    this.dadosGrafico = []
    var nodesComImagem = this.nodes.map(x => x)
    var nodes = this.nodes.map(x => { delete x.img; return x; })
    let objAddGrafico = {
      doc: docForm,
      id: id,
      nodes: nodes,
      tipoPesquisa: 2
    }
    this.teiaRelacionamentoService.adicionarGrafico(docForm, id, nodes).subscribe(data => {
      this.ui.loaded().subscribe(() => {
        this.dadosGrafico = data;
        if (data.descricao) {
          this.openSnackBarErro('Status', data.descricao)
          this.salvarNosGraphSemMensagem()
        }
        if (this.dadosGrafico && !data.descricao) {
          this.highChart()
          this.openSnackBar('Sucesso', 'Gráfico adicionado com Sucesso')
        }
      })
    }, (err: any) => {
      if(err.error.codigo == 20 && err.error.descricao == "este cpf pertence a menor de idade, é necessária a data de aniversário para processar a solicitação"){
        this.ui.loaded().subscribe(() => {
         this.abrirModalMenorIdade(objAddGrafico)
        })
      }
      else{
        this.ui.loaded().subscribe(() => {
          this.openSnackBarErro('Detech', err.error.descricao);
          this.renderizaTeiaComImagem(docForm)
        })
      }
    });
  }

  exportarCadastrais() {
    const docForm = this.teiaForm.get('documento').value.replaceAll('.', '').replaceAll('-', '').replaceAll('/', '');
    this.ui.loading();
    this.teiaRelacionamentoService.visualizarRelatorio(docForm).subscribe(data => {
      this.ui.loaded().subscribe(() => {
        const newWindow = window.open('relatorio-dados-cadastrais-detech', '_blank');
        newWindow.document.write(data);
      });
    }, (err: any) => {
      this.ui.loaded().subscribe(() => {
        this.openSnackBarErro('Detech', 'Ocorreu um erro, tente novamente.')
      })
    });
  }

  destacarDetech() {
    // Parâmetros para a chamada da API, se necessário
    const parametrosDaApi = {
      documento: this.teiaForm.get('documento').value
    };
    const parametrosVazio = {
      aba: this.teiaForm.get('documento').value
    };
    // Gerar a URL da rota com os parâmetros da API
    if (this.teiaForm.get('documento').value) {
      const url = this.router.serializeUrl(
        this.router.createUrlTree(['/detech'], { queryParams: parametrosDaApi })
      );
      window.open(url, '_blank');
    }
    else {
      const url = this.router.serializeUrl(
        this.router.createUrlTree(['/detech'], { queryParams: parametrosVazio })
      );
      window.open(url, '_blank');
    }
    // Abrir a URL em uma nova aba
  }

  zerarPesquisa(){
    const docForm = this.teiaForm.get('documento').value.replaceAll('.', '').replaceAll('-', '').replaceAll('/', '');
    this.ui.loading();
    this.teiaRelacionamentoService.zerarPesquisa(docForm).subscribe(data => {
      this.ui.loaded().subscribe(() => {
        this.buscarDocumento()
      });
    }, (err: any) => {
      this.ui.loaded().subscribe(() => {
        this.openSnackBarErro('Detech', 'Ocorreu um erro, tente novamente.')
      })
    });
  }


}


export class GraphLayout extends DagreLayout {
  override run(graph: Graph): Graph {
    this.createDagreGraph(graph);
    dagre.layout(this.dagreGraph);

    graph.edgeLabels = this.dagreGraph._edgeLabels;

    for (const dagreNodeId in this.dagreGraph._nodes) {


      const dagreNode = this.dagreGraph._nodes[dagreNodeId];
      if (dagreNode == undefined) continue;
      const node = graph.nodes.find((n) => n.id == dagreNode.id);
      node!.position = {
        x: dagreNode.position != null && dagreNode.position.x != 0 ? dagreNode.position.x : dagreNode.x,
        y: dagreNode.position != null && dagreNode.position.y != 0 ? dagreNode.position.y : dagreNode.y,
      };
      node!.dimension = {
        width: dagreNode.width,
        height: dagreNode.height,
      };
    }

    for (const edge of graph.edges) {
      this.updateEdge(graph, edge);
    }

    const settings = Object.assign({}, this.defaultSettings, this.settings);

    this.dagreNodes = graph.nodes.map(n => {
      const node: any = Object.assign({}, n);
      node.width = n.dimension.width;
      node.height = n.dimension.height;
      node.x = n.position.x;
      node.y = n.position.y;
      return node;
    });

    this.dagreEdges = graph.edges.map(l => {
      const newLink: any = Object.assign({}, l);
      if (!newLink.id) {
        newLink.id = id();
      }
      return newLink;
    });

    for (const node of this.dagreNodes) {
      if (!node.width) {
        node.width = 20;
      }
      if (!node.height) {
        node.height = 30;
      }

      // update dagre
      this.dagreGraph.setNode(node.id, node);
    }

    // update dagre
    for (const edge of this.dagreEdges) {
      if (settings.multigraph) {
        this.dagreGraph.setEdge(edge.source, edge.target, edge, edge.id);
      } else {
        this.dagreGraph.setEdge(edge.source, edge.target);
      }
    }


    return graph;
  }

  override updateEdge(graph: Graph, edge: Edge): Graph {
    const sourceNode = graph.nodes.find(n => n.id === edge.source);
    const targetNode = graph.nodes.find(n => n.id === edge.target);

    // determine new arrow position
    const dir = sourceNode?.position?.y <= targetNode.position.y ? -1 : 1;
    const startingPoint = {
      x: sourceNode?.position?.x,
      y: sourceNode?.position?.y
    };
    const endingPoint = {
      x: targetNode?.position?.x,
      y: targetNode?.position?.y
    };

    // generate new points
    edge.points = [startingPoint, endingPoint];
    return graph;
  }

  override createDagreGraph(graph: Graph): any {
    const settings = Object.assign({}, this.defaultSettings, this.settings);
    this.dagreGraph = new dagre.graphlib.Graph({ compound: settings.compound, multigraph: settings.multigraph });

    this.dagreGraph.setGraph({
      rankdir: settings.orientation,
      marginx: settings.marginX,
      marginy: settings.marginY,
      edgesep: settings.edgePadding,
      ranksep: settings.rankPadding,
      nodesep: settings.nodePadding,
      align: settings.align,
      acyclicer: settings.acyclicer,
      ranker: settings.ranker,
      multigraph: settings.multigraph,
      compound: settings.compound
    });

    // Default to assigning a new object as a label for each new edge.
    this.dagreGraph.setDefaultEdgeLabel(() => {
      return {
        /* empty */
      };
    });

    this.dagreNodes = graph.nodes.map(n => {
      const node: any = Object.assign({}, n);
      node.width = n.dimension.width;
      node.height = n.dimension.height;
      node.x = n.position.x;
      node.y = n.position.y;
      return node;
    });

    this.dagreEdges = graph.edges.map(l => {
      const newLink: any = Object.assign({}, l);
      if (!newLink.id) {
        newLink.id = id();
      }
      return newLink;
    });

    for (const node of this.dagreNodes) {
      if (!node.width) {
        node.width = 20;
      }
      if (!node.height) {
        node.height = 30;
      }

      // update dagre
      this.dagreGraph.setNode(node.id, node);
    }

    // update dagre
    for (const edge of this.dagreEdges) {
      if (settings.multigraph) {
        this.dagreGraph.setEdge(edge.source, edge.target, edge, edge.id);
      } else {
        this.dagreGraph.setEdge(edge.source, edge.target);
      }
    }

    this.dagreGraph.setn

    return this.dagreGraph;
  }
}

