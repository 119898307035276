
export enum OrdemProduto {
    sinteseCadastral = 1,
    dadosCadastraisCompleto = 2,
    sinteseProcesso = 3,
    patrimonialCompleto = 4,
    sinteseCredito = 5,
    creditoCompleto = 6,
    midiasNegativas = 9,
    detech = 8,
    escrituraEProcuracoes = 7    
}