<div class="form-ext">
    <div mat-dialog-title class="dialog-title">
        <button mat-icon-button aria-label="close dialog" mat-dialog-close>
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <mat-card class="pesq-re mt-3">
      <div class="header-pesq">
        <div class="title-pesq">
          <img class="img-title-pesq" src="../../../assets/icones/lupa-azul.svg" alt="view">
          <p>Histórico de Pesquisas</p>
        </div>
        <div class="container">
          <form class="novapesq-form">
          <div class="container">
            <div class="row d-flex justify-content-center align-items-center">
              <div class="col-md">
                <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label>CPF/CNPJ</mat-label>
                  <input matInput [(ngModel)]="formFiltro.numero" name="numero" (keyup.enter)="pesquisarClicked()">
              </mat-form-field>
              </div>
              <div class="col-md">
                <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label>Nome</mat-label>
                  <input matInput [(ngModel)]="formFiltro.nome" name="nome" (keyup.enter)="pesquisarClicked()">
                </mat-form-field>
              </div>
              <div class="col-md col-mail">
                <mat-form-field class="example-form-field" appearance="outline">
                  <mat-label>Periodo</mat-label>
                  <mat-date-range-input
                    [formGroup]="campaignOne"
                    [rangePicker]="campaignOnePicker"
                    [comparisonStart]="campaignTwo.value.start"
                    [comparisonEnd]="campaignTwo.value.end">
                    <input matStartDate placeholder="DD/MM/AAAA" formControlName="start">
                    <input matEndDate placeholder="DD/MM/AAAA" formControlName="end">
                  </mat-date-range-input>
                  <mat-datepicker-toggle matSuffix [for]="campaignOnePicker"></mat-datepicker-toggle>
                  <mat-date-range-picker #campaignOnePicker></mat-date-range-picker>
                </mat-form-field>
                <button mat-flat-button  class="gs-botaoAzulPadrao m-3" (click)="pesquisarClicked()">Filtrar <mat-icon>search</mat-icon></button>
              </div>
            </div>
          </div>  
          </form>
        </div>  
      </div>
  
      <div class="separador"></div>
      <div class="resultado">
        <div class="pesq-table" infiniteScroll
          [infiniteScrollDistance]="2"
          [infiniteScrollThrottle]="50"
          (scrolled)="onScroll()"
          [infiniteScrollUpDistance]="1.5"
          [scrollWindow]="false">
  
          <div class="outer-wrapper">
            <div style="text-align: center;"*ngIf="resultadoPesquisa.length == 0">
              Nenhuma pesquisa encontrada.
            </div>
            <div class="table-wrapper" *ngIf="resultadoPesquisa.length != 0">
              <table class="table-grid">
                <thead class="thead-grid">
                    <th class="th-grid">CPF/CNPJ</th>
                    <th class="th-grid">Nome</th>
                    <th class="th-grid">Data</th>
                    <th class="th-grid">Ações</th>
                </thead>
                <tbody class="tbody-grid">
                    <tr *ngFor="let o of resultadoPesquisa" class="tr-grid">
                        <td class="td-grid">
                          <div class="border-column">{{o.documento}}</div>
                        </td>
                        
                        <td class="td-grid">
                          <div class="border-column">
                          {{o.nome}}
                          </div>
                        </td>
                        <td class="td-grid">
                          <div class="border-column">
                            {{o.data}}
                          </div>
                        </td>
                        <td class="td-grid">
                          <div class="border-column">
                            
                          </div>
                        </td>
                    </tr>
                </tbody>
            </table>
          </div>
        </div>
        </div>
      </div>
    </mat-card>
  </div>
  