import { NavService } from './../../components/template/nav/nav.service';
import { HeaderService } from './../../components/template/header/header.service';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { PesquisaService } from '../../shared/services/http/pesquisa.service';
import { StatusPesquisa } from '../../shared/enums/status-pesquisa.enum';
import { StatusRegistro } from '../../shared/enums/status-registro.enum';
import { TipoPesquisa } from '../../shared/enums/tipo-pesquisa.enum';
import { UsuarioService } from '../../shared/services/http/usuario.service';
import { UiService } from '../../shared/services/ui.service';
import { ConfiguracaoService } from '../../shared/services/http/configuracao.service';
import { FonteGrupoService } from '../../shared/services/http/fonte-grupo.service';
import { UserStore } from '../../@core/stores/user.store';
import { User } from '../../@core/interfaces/common/users';
import { ToastrService } from 'ngx-toastr';
import { forkJoin } from 'rxjs';
import { AuthService } from 'src/app/shared/services/http/auth.service';

const today = new Date();
const month = today.getMonth();
const year = today.getFullYear();

export interface PeriodicElement {
  id: number;
  relatorio: string;
  cpf: string;
  nome: string;
  emissao: string;
  horario: string;
  status: string;
  progresso: string;
}

@Component({
  selector: 'app-historico',
  templateUrl: './historico.component.html',
  styleUrls: ['./historico.component.scss']
})
export class HistoricoComponent implements OnInit {

  StatusRegistro = StatusRegistro;
  StatusPesquisa = StatusPesquisa;
  TipoPesquisa = TipoPesquisa;

  labelTag: String = 'Tag';
  tagIds = []


  formFiltro: any = { page: 1, pageSize: 50, perfil: "", status: "" };
  formFiltroRenderizado: any = this.formFiltro;

  resultadoPesquisa : any = [];
  usuario: any;
  configuracao : any = {};
  listaFonteGrupo = [];

  campaignOne = new FormGroup({
    start: new FormControl(new Date(13, month, year)),
    end: new FormControl(new Date(16, month, year)),
  });
  campaignTwo = new FormGroup({
    start: new FormControl(new Date(15, month, year)),
    end: new FormControl(new Date(19, month, year)),
  });

  constructor(
    private pesquisaService: PesquisaService,
    private usuarioService: UsuarioService,
    private ui: UiService,
    private toastr: ToastrService,
    private authService: AuthService,
    private configuracaoService : ConfiguracaoService,
    private fonteGrupoService : FonteGrupoService,
    private userStore: UserStore,
    private headerService: HeaderService,
    private navService: NavService) {
      navService.navData = {
        title:'Historico'
      }
      headerService.headerData = {
        title:'Historico'
      }
    }

  ngOnInit(): void {

    this.ui.loading();

    let requests = [
      this.configuracaoService.get(),
      this.fonteGrupoService.listAtivo()
    ];

    forkJoin(requests).subscribe(data => {
        this.configuracao = data[0];
        this.listaFonteGrupo = data[2];
        this.pesquisarClicked();
    });
    this.getUsuario();
    this.getChips()
  }

  getUsuario() {
    this.authService.callbackUsuario().subscribe(usuario => {
      if (!usuario) {
        return;
      }
      if (usuario) {
        this.usuario = usuario;
        if(usuario.cliente.descricaoTag && usuario.cliente.descricaoTag.trim().length > 0){
          this.labelTag = usuario.cliente.descricaoTag;
        }
      }
    })
  }

  getChips() {
    this.ui.loading()
    this.pesquisaService.getTagID(this.usuario.cliente.id).subscribe(response => {
      this.ui.loaded().subscribe(() => {
        this.tagIds = response
      })
    }, (err:any) => {
      this.ui.loaded().subscribe(() => {
          this.toastr.error('Ocorreu um erro, tente novamente.')
      })
    });
  }

  pesquisarClicked(page = 1) {
    this.formFiltro.page = page;
    this.ui.loading();
    console.log(this.formFiltro);

    if(page == 1)
      this.resultadoPesquisa = [];

    let request = Object.assign({}, this.formFiltro);
    if(request.status) request.status = [request.status];
    this.pesquisaService.listPesquisaCliente(request).subscribe(response => {
        this.formFiltroRenderizado = Object.assign({}, this.formFiltro);
        this.ui.loaded().subscribe(() => {
          this.resultadoPesquisa = this.resultadoPesquisa.concat(response.list);
        });
    });
  }

  clearForm() {
    this.formFiltro.nome = '';
    this.formFiltro.numero = '';
    this.formFiltro.status = '';
  }

  onScroll() {
    let page = this.formFiltro.page+ 1;
    console.log('scrolled!!'+ page);
    this.pesquisarClicked(page);

  }

  onScrollDown() {
    console.log('scrolled down!!');
  }

  onScrollUp() {
    let page = this.formFiltro.page-1;
    if (page > 0) {
      this.pesquisarClicked(page);
    }
    console.log('scrolled up!!'+page);
  }

  exportarClicked(o: { pesquisa: { id: any; }; }) {
    this.ui.loading();
    this.pesquisaService.exportar({ id: o.pesquisa.id }, "historico").subscribe(data => {
        this.ui.loaded().subscribe(() => {
            var a = document.createElement("a");
            a.href = "data:application/pdf;base64," + data.arquivoConteudo;
            a.download = data.arquivoNome;
            a.click();
        });
    });
  }

  exportarReciboClicked(o: { pesquisa: { id: any; }; }) {
      this.ui.loading();
      this.pesquisaService.exportarRecibo({ id: o.pesquisa.id }, "historico").subscribe(data => {
          this.ui.loaded().subscribe(() => {
              var a = document.createElement("a");
              a.href = "data:application/pdf;base64," + data.arquivoConteudo;
              a.download = data.arquivoNome;
              a.click();
          });
      });
  }

  exportarExcelClicked(o: { pesquisa: { id: any; }; }) {
      this.ui.loading();
      this.pesquisaService.exportarExcel({ id: o.pesquisa.id }, "historico").subscribe(data => {
          this.ui.loaded().subscribe(() => {
              var a = document.createElement("a");
              a.href = "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," + data.arquivoConteudo;
              a.download = data.arquivoNome;
              a.click();
          });
      });
  }

  visualizarClicked(o: { pesquisa: { id: any; }; }) {
    this.toastr.warning("Em desenvolvimento", "Portal Credvalue")
  }
  ajustaNome(nome : string, tamanho: any) {
    let nomeRetorno = nome;
    if (nome.length >= tamanho) {
      nomeRetorno = nome.substring(0,tamanho-3)+'...';
    }
    return nomeRetorno;
  }

}
