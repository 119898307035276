<mat-accordion>
    <mat-expansion-panel class="mt-3 py-2" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <h3 class="title-cordion mb-0 d-flex align-items-center">
            <mat-icon class="detech-icon">spa</mat-icon>&nbsp; AMBIENTAL
          </h3>
        </mat-panel-title>
      </mat-expansion-panel-header>
      
      <!-- Conteúdo dentro do corpo do painel -->
      <div>
        <app-ibama-areas-embargadas [idPesquisa]="idPesquisa" [aba]="aba"></app-ibama-areas-embargadas>
      </div>
    </mat-expansion-panel>
  </mat-accordion>

  <mat-accordion>
    <mat-expansion-panel class="mt-3 py-2" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <h3 class="title-cordion mb-0 d-flex align-items-center">
            <mat-icon class="detech-icon">public</mat-icon>&nbsp; SOCIAL
          </h3>
        </mat-panel-title>
      </mat-expansion-panel-header>
      
      <!-- Conteúdo dentro do corpo do painel -->
      <div>
        <app-auto-infracoes-trabalhistas [idPesquisa]="idPesquisa" [aba]="aba"></app-auto-infracoes-trabalhistas>
        <app-trabalho-escravo [idPesquisa]="idPesquisa" [aba]="aba"></app-trabalho-escravo>
        <app-diario-oficial [idPesquisa]="idPesquisa" [aba]="aba"></app-diario-oficial>
      </div>
    </mat-expansion-panel>
  </mat-accordion>

  
  <mat-accordion>
    <mat-expansion-panel class="mt-3 py-2" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <h3 class="title-cordion mb-0 d-flex align-items-center">
            <mat-icon class="detech-icon">location_city</mat-icon>&nbsp; GOVERNANÇA
          </h3>
        </mat-panel-title>
      </mat-expansion-panel-header>
      
      <!-- Conteúdo dentro do corpo do painel -->
      <div>
        <app-cadastro-expulsoes-adminstracao-federal [idPesquisa]="idPesquisa" [aba]="aba"></app-cadastro-expulsoes-adminstracao-federal>
        <app-cadastro-entidades-sem-fins-lucrativos [idPesquisa]="idPesquisa" [aba]="aba"></app-cadastro-entidades-sem-fins-lucrativos>
        <app-acordos-leniencia [idPesquisa]="idPesquisa" [aba]="aba"></app-acordos-leniencia>
        <app-cadastro-empresas-punidas [idPesquisa]="idPesquisa" [aba]="aba"></app-cadastro-empresas-punidas>
        <app-cadastro-empresas-inidoneas [idPesquisa]="idPesquisa" [aba]="aba"></app-cadastro-empresas-inidoneas>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
  