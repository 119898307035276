import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { takeWhile, timer } from 'rxjs';
import { PesquisaService } from 'src/app/shared/services/http/pesquisa.service';
import { UiService } from 'src/app/shared/services/ui.service';

@Component({
  selector: 'sintese-credito',
  templateUrl: './sintese-credito.component.html',
  styleUrls: ['./sintese-credito.component.scss']
})
export class SinteseCreditoComponent implements OnInit {

  @Output() exibeAnaliseCreditoChange = new EventEmitter<boolean>();
  @Input() aba: any;
  idPesquisa;
  sinteseCredito;
  isCnpj = false;
  isCpf = false;
  isLoading = false;
  exibeReprocessar = false;
  probabilidadePf;

  constructor(
    private pesquisaService: PesquisaService,
    private ui: UiService,
    public toastr: ToastrService
  ) { }

  ngOnInit(): void {
    if (this.aba.id.id) {
      this.idPesquisa = this.aba.id.id
    }
    else {
      this.idPesquisa = this.aba.id
    }
    this.getSinteseCredito();
  }

  getSinteseCredito(){
    this.isLoading = true;
    let attempts = 0;

    const subscription = timer(0, 10000).pipe(
      takeWhile(() => attempts < 5) // Limita a 5 tentativas
    ).subscribe(() => {
      this.pesquisaService.getSinteseCredito(this.idPesquisa).subscribe(
        response => {
          if (response) {
            this.sinteseCredito = response;
            if(this.sinteseCredito.scorePj){
              this.isCnpj = true;
              this.sinteseCredito = this.sinteseCredito;
            }
            if(this.sinteseCredito.score){
              this.isCpf = true;
              this.sinteseCredito = this.sinteseCredito;
              this.probabilidadePf = this.sinteseCredito.score[0].probabilidade / 100;
            }
            this.isLoading = false;
            subscription.unsubscribe(); // Interrompe o intervalo após uma resposta bem-sucedida
          }
        },
        error => {
          attempts++;
          if(error.status == 501){
            this.isLoading = false;
            this.exibeAnaliseCreditoChange.emit(false);
            subscription.unsubscribe();
          }

          if (attempts >= 5) {
            this.isLoading = false;
            subscription.unsubscribe(); // Interrompe o intervalo após 5 tentativas
            this.toastr.warning(`Erro ao obter as informações do pesquisado.`);
            this.exibeReprocessar = true;
          }
        }
      );
    });
  }

}
