import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class EventService {
  public emitter: EventEmitter<any | null>;
  constructor() {
    this.emitter = new EventEmitter<any>(null);
  }
  broadcastMessage(message: any): void {
    this.emitter.emit(message);
  }
}