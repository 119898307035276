import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-mapa-brasil',
  templateUrl: './mapa-brasil.component.html',
  styleUrls: ['./mapa-brasil.component.scss']
})
export class MapaBrasilComponent implements OnInit {

  @ViewChild('teste') mapContainer: ElementRef;
  exibeInformacoes = false;
  textoTeste;

  obj = {
    AC: {
      quantidade: 1
    },
    SP: {
      quantidade: 1
    },
    MS: {
      quantidade: 1
    },
    ES: {
      quantidade: 1
    },
    CE: {
      quantidade: 1
    },
    AM: {
      quantidade: 1
    },
    PA: {
      quantidade: 1
    },
    RS: {
      quantidade: 1
    }
  }

  items = [
    { label: 'Total Processos', value: 33493 },
    { label: 'Total Patrimonios', value: 32 },
    { label: 'Total Pessoas Relacionadas', value: 2 }
  ];

  constructor() {}

  ngOnInit(): void {
    const mapElement: HTMLElement = this.mapContainer.nativeElement;

    // Adicione eventos de mouse para exibir informações ao passar o mouse sobre os estados
    const states = mapElement.querySelectorAll('path');
    states.forEach(state => {
      state.addEventListener('mouseover', () => {
        // Exibir informações do estado
        console.log('UF:', state.getAttribute('data-uf'));
        console.log('Número:', state.getAttribute('data-numero'));
      });
    });
  }

  onMouseOver(stateId: string): void {
    const stateElement = document.getElementById(stateId);
    if (stateElement) {
      stateElement.classList.add('hovered');

      const stateName = stateElement.getAttribute('title');
      const svg = stateElement.closest('svg');
      const textElement = document.createElementNS('http://www.w3.org/2000/svg', 'text');
      textElement.textContent = stateName;
      textElement.setAttribute('x', '50%');
      textElement.setAttribute('y', '50%');
      textElement.setAttribute('text-anchor', 'middle');
      textElement.setAttribute('dominant-baseline', 'central');
      textElement.setAttribute('fill', '#000');
      textElement.setAttribute('font-size', '12');
      textElement.setAttribute('font-weight', 'bold');
      svg.appendChild(textElement);
    }
  }

  onMouseOut(stateId: string): void {
    const stateElement = document.getElementById(stateId);
    if (stateElement) {
      stateElement.classList.remove('hovered');

      const svg = stateElement.closest('svg');
      const textElement = svg.querySelector('text');
      if (textElement) {
        svg.removeChild(textElement);
      }
    }
  }

  exibirInformacoes(stateId: string): void {
    this.exibeInformacoes = true
    this.textoTeste = stateId
    // Adicione o código para lidar com o clique 
    
  }

  fecharInformacoes(): void {
    this.exibeInformacoes = false;
  }

  handleClick(event: MouseEvent) {
    const target = event.target as SVGPathElement;
    if (target.tagName === 'path') {
      const id = target.id;
      if (this.obj[id]) {
        this.exibirInformacoes(id)
      }
    }
  }
}
