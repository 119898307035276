<aside class="d-flex justify-content-end">
    <mat-icon class="pointer" mat-dialog-close>close</mat-icon>
</aside>
<div class="content mt-1">
  <table>
    <tr> <th>LOCALIZADOR</th> </tr>
      <td>
        <b>PESQUISA POR NOME E ENDEREÇO, TELEFONE E E-MAIL</b><br>
        <ul>
          <li>Nome Completo</li>
          <li>Documento</li>
          <li>Data de Nascimento</li>
          <li>Nome da Mãe</li>
          <li>Cidade</li>
          <li>UF</li>
          <li>Gerar Pesquisa: (informações cadastrais; processos; patrimonial; crédito e dossiê completo).</li>
        </ul>
        <br><br>
        <b>PESQUISA POR RAZÃO SOCIAL E ENDEREÇO, TELEFONE E E-MAIL</b><br>
        <ul>
          <li>Nome da Empresa</li>
          <li>Data da Abertura</li>
          <li>Nome do Sócio</li>
          <li>Cidade</li>
          <li>UF</li>
          <li>Gerar Pesquisa: (informações cadastrais; processos; patrimonial; crédito e dossiê completo).</li>
        </ul>
      </td>
  </table>
  <table>
    <tr> <th>DETECH</th> </tr>
      <td>
        <b>DETECH</b><br>
        <ul>
            <li>Nome do pesquisado</li>
            <li>Documento do pesquisado</li>
            <li>  Informações Adicionais:
                <ul class="subItem">
                    <li>Situação Cadastral</li>
                    <li>Endereço</li>
                    <li>CEP</li>
                    <li>Cidade</li>
                    <li>UF</li>
                    <li>Telefone</li>
                    <li>E-mail</li>
                    <li>Mapa de localização</li>
                </ul>
            </li>
            <li>Exibir ou ocultar Empresas relacionadas ao pesquisado</li>
            <li>Exibir ou ocultar Pessoas relacionadas ao pesquisado</li>
            <li>Exibir ou ocultar Filiais</li>
            <li>Gerar Pesquisa: (informações cadastrais; processos; patrimonial; crédito e dossiê completo)</li>
        </ul>
      </td>
  </table>
  <table>
    <tr> <th>INFORMAÇÕES CADASTRAIS</th> </tr>
      <td>
        <b>PESSOA FÍSICA:</b>
        <ul>
          <li>Nome</li>
          <li>CPF</li>
          <li>Situação do CPF</li>
          <li>Data nascimento</li>
          <li>Telefone</li>
          <li>Endereço</li>
          <li>E-mail</li>
          <li>Pessoas relacionadas (parentes, sócios, colegas)</li>
          <li>Participações Societárias.</li>
        </ul>
        <br>
        <b>PESSOA JURÍDICA:</b>
        <ul>
          <li>Razão social</li>
          <li>CNPJ da empresa</li>
          <li>Telefone</li>
          <li>Cargo</li>
          <li>Endereço com inscrição estadual</li>
          <li>Data abertura</li>
          <li>Natureza</li>
          <li>CNAE Fiscal</li>
          <li>Capital Social</li>
          <li>Participações Societárias</li>
        </ul>
      </td>
  </table>
  <table>
    <tr> <th>PESQUISAS DE PROCESSOS</th> </tr>
      <td>
        <b>PESQUISAS DE PROCESSOS</b><br><br>
        <ul>
            <li>Processos Administrativos (data; Número Processo):
                <ul class="subItem">
                    <li> Tribunal de Contas da União (TCU)</li>
                    <li>Conselho Administrativo de Defesa Econômica (CADE)</li>
                    <li>Agência Nacional de Telecomunicações (ANATEL)</li>
                    <li>Agência Nacional de Vigilância Sanitária (ANVISA)</li>
                    <li>Instituto Nacional da Propriedade Industrial (INPI)</li>
                    <li>Superintendência de Seguros Privados (SUSEP)</li>
                    <li>Instituto Brasileiro do Meio Ambiente e dos Recursos Naturais Renováveis (IBAMA)</li>
                    <li>Agência Nacional do Petróleo,Gás Natural e Biocombustíveis (ANP)</li>
                    <li>Agência Nacional de Transportes Terrestres (ANTT)</li>
                    <li>Instituto Nacional de Colonização e Reforma Agrária (INCRA)</li>
                    <li>Agência Nacional de Saúde Suplementar (ANS)</li>
                    <li>Agência Nacional de Energia Elétrica (ANEEL)</li>
                    <li>Agência Nacional de Águas (ANA)</li>
                    <li>Agência Nacional de Aviação Civil (ANAC)</li>
                    <li>Agência Nacional de Mineração (ANM)</li>
                    <li>Agência Nacional de Assistência Técnica e Extensão Rural (ANATER)</li>
                    <li>Ministério Público Federal (MPF)</li>
                </ul>
            </li>
            <br>
            <li> Processos Estaduais (tribunal; Processo; Polo Ativo/Passivo; etc.)</li>
            <li>Processos Federais (tribunal; Processo; Polo Ativo/Passivo; etc.)</li>
            <li> Antecedentes Criminais (arquivo)</li>
            <li>Ibama - Certificado de Regularidade (situação; Registro; Data da consulta; data emissão etc.)</li>
            <li>Certidões TCU (arquivo)</li>
            <li>Protestos (UF; comarca; cartório; quantidade; valor total)</li>
            <li>Sanções Nacionais (arquivo)</li>
        </ul>
       </td>
  </table>
  <table>
    <tr> <th>PESQUISA PATRIMONIAL</th> </tr>
      <td>
        <b>PESQUISA PATRIMONIAL</b><br>
        <ul>
            <li>Imóveis urbanos (SP; ES; MA; DF; RJ; SC; PR; RO; MS - custo adicional)</li>
            <li>Imóveis rurais (Brasil)</li>
            <li>Marcas</li>
            <li>Patentes</li>
            <li>Domínios (documento; titular; responsável; contato do titular; data criação; data alteração; provedor; quantidade de domínios)</li>
            <li> Atividade controlada:
                <ul class="subItem">
                    <li>Habilitação Importação e Exportação (situação; data; modalidade e operações autorizadas)</li>
                    <li>BNDES (nome; valor contratado; situação; data; produto; instituição financeira; município e UF)</li>
                    <li>CVM (denominação; CNPJ; data do registro; código CVM; situação; data da situação; categoria de registro e data início da categoria)</li>
                    <li>FGTS (regularidade)</li>
                    <li>Energia (proprietário; CEG; usina e participação)</li>
                    <li>Anvisa (número; situação; expediente; assunto petição; situação da petição; descrição da petição e SEI)</li>
                    <li>Seguro rural (nome; seguradora; proposta; apólice; município; UF; Atividade e Status)</li>
                </ul>
            </li>
            <li>Offshore (Argentina; Irlanda; Florida; Reino Unido; Panama e New York)</li>
            <li>Imóveis Orlando / Miami</li>
            <li>GTA (trânsito animal)</li>
            <li>Veículos</li>
            <li>Aeronaves</li>
            <li>Embarcações</li>
            <li>Minérios (requerimento; fase atual; nome titular; municípios; substâncias; tipo de uso e situação)</li>
            <li>Dívida ativa (ECAC)</li>
            <li> Atos Públicos:
                <ul class="subItem">
                    <li>Mato Grosso (tipo; data do ato; cartório; CNS e número)</li>
                    <li>Amazonas (local; cartório e descrição)</li>
                    <li>São Paulo (Documento, Tipo do Ato, Data do Ato, UF, Município, Cartório, CNS, Folha, Livro)</li>
                    <li>Sistema de Divórcios e Inventários - Nacional (nome; documento; identidade; cartório; município; UF; CNS; livro; folha; data do ato e tipo do ato)</li>
                    <li>Sistema de Registros Públicos de Títulos e documentos (nome; número de registro; nome do cartório e local do cartório)</li>
                    <li>Goiás Diário Oficial - Editais imobiliários (nome; publicação e data da edição)</li>
                </ul>
            </li>
        </ul>
      </td>
  </table>
  <table>
    <tr> <th>DOSSIÊ COMPLETO</th> <tr>
      <td>
        <b>DOSSIÊ COMPLETO</b><br><br>

        <b>PESSOA FÍSICA:</b>
        <ul>
          <li>Nome</li>
          <li>CPF</li>
          <li>Situação do CPF</li>
          <li>Data nascimento</li>
          <li>Telefone</li>
          <li>Endereço</li>
          <li>E-mail</li>
          <li>Pessoas relacionadas (parentes, sócios, colegas)</li>
          <li>Participações Societárias.</li>
        </ul>
        <br>
        <b>PESSOA JURÍDICA:</b>
        <ul>
          <li>Razão social</li>
          <li>CNPJ da empresa</li>
          <li>Telefone</li>
          <li>Cargo</li>
          <li>Endereço com inscrição estadual</li>
          <li>Data abertura</li>
          <li>Natureza</li>
          <li>CNAE Fiscal</li>
          <li>Capital Social</li>
          <li>Participações Societárias</li>
        </ul>
        <br>
        <b>PESQUISAS DE PROCESSOS</b><br><br>
        <ul>
            <li>Processos Administrativos (data; Número Processo):
                <ul class="subItem">
                    <li> Tribunal de Contas da União (TCU)</li>
                    <li>Conselho Administrativo de Defesa Econômica (CADE)</li>
                    <li>Agência Nacional de Telecomunicações (ANATEL)</li>
                    <li>Agência Nacional de Vigilância Sanitária (ANVISA)</li>
                    <li>Instituto Nacional da Propriedade Industrial (INPI)</li>
                    <li>Superintendência de Seguros Privados (SUSEP)</li>
                    <li>Instituto Brasileiro do Meio Ambiente e dos Recursos Naturais Renováveis (IBAMA)</li>
                    <li>Agência Nacional do Petróleo,Gás Natural e Biocombustíveis (ANP)</li>
                    <li>Agência Nacional de Transportes Terrestres (ANTT)</li>
                    <li>Instituto Nacional de Colonização e Reforma Agrária (INCRA)</li>
                    <li>Agência Nacional de Saúde Suplementar (ANS)</li>
                    <li>Agência Nacional de Energia Elétrica (ANEEL)</li>
                    <li>Agência Nacional de Águas (ANA)</li>
                    <li>Agência Nacional de Aviação Civil (ANAC)</li>
                    <li>Agência Nacional de Mineração (ANM)</li>
                    <li>Agência Nacional de Assistência Técnica e Extensão Rural (ANATER)</li>
                    <li>Ministério Público Federal (MPF)</li>
                </ul>
            </li>
            <br>
            <li> Processos Estaduais (tribunal; Processo; Polo Ativo/Passivo; etc.)</li>
            <li>Processos Federais (tribunal; Processo; Polo Ativo/Passivo; etc.)</li>
            <li> Antecedentes Criminais (arquivo)</li>
            <li>Ibama - Certificado de Regularidade (situação; Registro; Data da consulta; data emissão etc.)</li>
            <li>Certidões TCU (arquivo)</li>
            <li>Protestos (UF; comarca; cartório; quantidade; valor total)</li>
            <li>Sanções Nacionais (arquivo)</li>
        </ul>
        <br>
        <b>PESQUISA PATRIMONIAL</b><br>
        <ul>
            <li>Imóveis urbanos (SP; ES; MA; DF; RJ; SC; PR; RO; MS - custo adicional)</li>
            <li>Imóveis rurais (Brasil)</li>
            <li>Marcas</li>
            <li>Patentes</li>
            <li>Domínios (documento; titular; responsável; contato do titular; data criação; data alteração; provedor; quantidade de domínios)</li>
            <li> Atividade controlada:
                <ul class="subItem">
                    <li>Habilitação Importação e Exportação (situação; data; modalidade e operações autorizadas)</li>
                    <li>BNDES (nome; valor contratado; situação; data; produto; instituição financeira; município e UF)</li>
                    <li>CVM (denominação; CNPJ; data do registro; código CVM; situação; data da situação; categoria de registro e data início da categoria)</li>
                    <li>FGTS (regularidade)</li>
                    <li>Energia (proprietário; CEG; usina e participação)</li>
                    <li>Anvisa (número; situação; expediente; assunto petição; situação da petição; descrição da petição e SEI)</li>
                    <li>Seguro rural (nome; seguradora; proposta; apólice; município; UF; Atividade e Status)</li>
                </ul>
            </li>
            <li>Offshore (Argentina; Irlanda; Florida; Reino Unido; Panama e New York)</li>
            <li>Imóveis Orlando / Miami</li>
            <li>GTA (trânsito animal)</li>
            <li>Veículos</li>
            <li>Aeronaves</li>
            <li>Embarcações</li>
            <li>Minérios (requerimento; fase atual; nome titular; municípios; substâncias; tipo de uso e situação)</li>
            <li>Dívida ativa (ECAC)</li>
            <li> Atos Públicos:
                <ul class="subItem">
                    <li>Mato Grosso (tipo; data do ato; cartório; CNS e número)</li>
                    <li>Amazonas (local; cartório e descrição)</li>
                    <li>São Paulo (Documento, Tipo do Ato, Data do Ato, UF, Município, Cartório, CNS, Folha, Livro)</li>
                    <li>Sistema de Divórcios e Inventários - Nacional (nome; documento; identidade; cartório; município; UF; CNS; livro; folha; data do ato e tipo do ato)</li>
                    <li>Sistema de Registros Públicos de Títulos e documentos (nome; número de registro; nome do cartório e local do cartório)</li>
                    <li>Goiás Diário Oficial - Editais imobiliários (nome; publicação e data da edição)</li>
                </ul>
            </li>
        </ul>
      </td>
  </table>
  <table>
    <tr> <th>ANÁLISE DE CRÉDITO</th> </tr>
      <td>
        <b>PESQUISA POR CPF:</b><br>
        <ul>
            <li>Status do Consumidor</li>
            <li>Score</li>
            <li>Painel de Controle (registro de débito; ações cíveis; consultas anteriores; cheque sustados motivo 21 devoluções informadas pelo usuário; protestos; participação em empresas e recuperações e falências)</li>
            <li> Dados Cadastrais (nome; CPF; nome da mãe; situação do CPF; data de atualização; região de origem do CPF; data de nascimento; sexo; grau de instrução; quantidade de dependentes; estado civil e título de eleitor)</li>
            <li>Telefones</li>
            <li>Localização (endereço; número; complemento; bairro; cidade; UF e CEP)</li>
            <li>Dívidas (consultas anteriores; registros de débitos; ações cíveis; recuperações e falências; participações em empresas; cheques sustados motivo 21; cheques devolvidos informados pelo usuário e protesto)</li>
            <li>Registro de Comprometimento (pagamento pontual; pagamento atrasado; compromisso; comprometimento futuro; crédito obtido; demais informações e acontecimentos importantes)</li>
            <li>Consultas Realizadas (consultas; consultas mês e últimas consultas)</li>
          </ul>
        <br><br>
        <b>PESQUISA POR CNPJ:</b><br>
        <ul>
            <li>Status do Consumidor</li>
            <li>Score</li>
            <li>Painel de Controle (pendência e restrições financeiras; cheque sem fundos; cheque sustados motivo 21; cheque devolvidos informados pelo usuário e protesto)</li>
            <li>Dados Societários (razão social; nome fantasia; CNPJ; situação cadastral; data da situação CNPJ; data da fundação; nire; inscrição estadual; natureza jurídica e CNAE fiscal / nome);</li>
            <li>Sócios (nome; CPF/CNPJ; situação; data entrada; participação e assina)</li>
            <li>Administradores</li>
            <li>Endereços (endereço; número; complemento; bairro; cidade; UF; CEP)</li>
            <li>Dívidas (pendência e restrições financeiras; cheque sem fundos; cheque sustados motivo 21; cheque devolvidos informados pelo usuário e protesto)</li>
            <li>Registro de Comprometimento (pagamento pontual; pagamento atrasado; compromisso; comprometimento futuro; crédito obtido; demais informações e acontecimentos importantes)</li>
            <li>Consultas Realizadas (consultas; consultas mês e últimas consultas)</li>
        </ul>
      </td>
  </table>
  <table>
    <tr> <th>CERTIDÕES</th> </tr>
      <td>
        <b>CERTIDÕES</b><br><br>
        <ul>
            <li>Certidão Cartão CNPJ</li>
            <li>Certidão Contratação Deficiente</li>
            <li>Antecedentes Criminais</li>
            <li>Certidão Improbidade Administrativa</li>
            <li>Certidão Financeira Débitos Patrimoniais Responsável</li>
            <li>Certidão Situação Imóvel</li>
            <li>Certidão Contas Julgadas Irregulares - TCU</li>
            <li>Certidão Processo Tribunal Contas União</li>
            <li>Certidão Licitantes Inidôneos - TCU</li>
            <li>Certidão Dívida União Pessoa Física</li>
            <li>Certidão Situação Cadastral FGTS</li>
            <li>Certidão Negativa Débito Trabalhista</li>
            <li>Certidão Ministério Público Federal</li>
            <li>Certidão Financeira Débitos Atual Responsável Imóvel</li>
            <li>Certidão Inteiro Teor Imóvel</li>
            <li>Certidão Imóvel União - SPU</li>
            <li>Certidão Contas Julgadas Irregulares Com Implicação Eleitoral</li>
            <li>Certidão Negativa De Inabilitados -TCU</li>
            <li>Certidão Nada Consta Embargos - IBAMA</li>
            <li>Certidão Dívida União Pessoa Jurídica</li>
        </ul>
      </td>
  </table>
</div>
