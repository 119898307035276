import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatSelect } from '@angular/material/select';
import { debounceTime, distinctUntilChanged, map, Observable, startWith, switchMap } from 'rxjs';
import { PesquisaService } from 'src/app/shared/services/http/pesquisa.service';
import { UiService } from 'src/app/shared/services/ui.service';


@Component({
  selector: 'app-cruzamanto-filtro',
  templateUrl: './cruzamanto-filtro.component.html',
  styleUrls: ['./cruzamanto-filtro.component.scss']
})
export class CruzamantoFiltroComponent implements OnInit {

  @Input() dadosAba: any
  @Output() dadosCruzadosEvent = new EventEmitter<void>();
  cruzamentoId
  segundoNivel = false

  offsetEmails = 1;
  limitEmails = 40;
  offsetTelefones = 1;
  limitTelefones = 40;
  offsetEnderecos = 1;
  limitEnderecos = 40;
  offsetProcessos = 1;
  limitProcessos = 40;
  offSetNomes = 1;
  limitNomes = 40

  // Listas de seleção
  emailsListados: string[] = [];
  telefonesListados: string[] = [];
  processosListados: string[] = [];
  enderecosListados: string[] = [];
  nomesListados: string[] = [];

  // Filtros
  emailFilter = new FormControl('');
  telefoneFilter = new FormControl('');
  processoFilter = new FormControl('');
  enderecoFilter = new FormControl('');
  nomeFilter = new FormControl('')

  // Itens selecionados
  selectedEmails: string[] = [];
  selectedTelefones: string[] = [];
  selectedProcessos: string[] = [];
  selectedEnderecos: string[] = [];
  selectedNomes: string[] = [];

  // Itens filtrados
  filteredEmails: string[] = [];
  filteredTelefones: string[] = [];
  filteredProcessos: string[] = [];
  filteredEnderecos: string[] = [];
  filteredNomes: string[] = [];

  allSelectedEmails = false;
  allSelectedProcessos = false;
  allSelectedEnderecos = false;
  allSelectedTelefones = false;
  allSelectedNomes = false;


  constructor(
    private pesquisaService: PesquisaService,
    private ui: UiService

  ) {
  }

  ngOnInit(): void {
    console.log(this.dadosAba)
    this.cruzamentoId = this.dadosAba?.idpesquisa.id
    this.segundoNivel = this.dadosAba?.idpesquisa.segundoNivelFiltro
    this.getEmails();
    this.getTelefones();
    this.getProcessos();
    this.getEnderecos();
    this.getNomes();
    this.getFiltrosPreenchidos()

    // Filtros dinâmicos
    this.emailFilter.valueChanges.pipe(
      debounceTime(300), // Espera 300ms após a digitação parar antes de enviar a requisição
      distinctUntilChanged(), // Evita requisições repetidas com o mesmo valor
      switchMap(value => this.pesquisaService.getEmailsListagem(this.cruzamentoId, value, 1, this.limitEmails, this.segundoNivel)) // Substitui a busca anterior pela nova
    ).subscribe(data => {
      this.filteredEmails = data.itens;
      console.log(this.filteredEmails)
    });

    this.telefoneFilter.valueChanges.pipe(
      debounceTime(300), // Espera 300ms após a digitação parar antes de enviar a requisição
      distinctUntilChanged(), // Evita requisições repetidas com o mesmo valor
      switchMap(value => this.pesquisaService.getTelefonesListagem(this.cruzamentoId, value, 1, this.limitTelefones, this.segundoNivel)) // Substitui a busca anterior pela nova
    ).subscribe(data => {
      this.filteredTelefones = data.itens;
    });

    this.enderecoFilter.valueChanges.pipe(
      debounceTime(300), // Espera 300ms após a digitação parar antes de enviar a requisição
      distinctUntilChanged(), // Evita requisições repetidas com o mesmo valor
      switchMap(value => this.pesquisaService.getEnderecosListagem(this.cruzamentoId, value, 1, this.limitEnderecos, this.segundoNivel)) // Substitui a busca anterior pela nova
    ).subscribe(data => {
      this.filteredEnderecos = data.itens;
    });

    this.processoFilter.valueChanges.pipe(
      debounceTime(300), // Espera 300ms após a digitação parar antes de enviar a requisição
      distinctUntilChanged(), // Evita requisições repetidas com o mesmo valor
      switchMap(value => this.pesquisaService.getProcessosListagem(this.cruzamentoId, value, 1, this.limitProcessos, this.segundoNivel)) // Substitui a busca anterior pela nova
    ).subscribe(data => {
      this.filteredProcessos = data.itens;
    });

    this.nomeFilter.valueChanges.pipe(
      debounceTime(300), // Espera 300ms após a digitação parar antes de enviar a requisição
      distinctUntilChanged(), // Evita requisições repetidas com o mesmo valor
      switchMap(value => this.pesquisaService.getNomesListagem(this.cruzamentoId, value, 1, this.limitNomes, this.segundoNivel)) // Substitui a busca anterior pela nova
    ).subscribe(data => {
      this.filteredNomes = data.itens;
    });
    // this.emailFilter.valueChanges.subscribe(value => this.filter('email', value));
    // this.telefoneFilter.valueChanges.subscribe(value => this.filter('telefone', value));
    // this.processoFilter.valueChanges.subscribe(value => this.filter('processo', value));
    // this.enderecoFilter.valueChanges.subscribe(value => this.filter('endereco', value));
    // this.nomeFilter.valueChanges.subscribe(value => this.filter('nome', value))
  }

  getEmails() {
    this.pesquisaService.getEmailsListagem(this.cruzamentoId, this.emailFilter.value || '', this.offsetEmails, this.limitEmails, this.segundoNivel).subscribe(data => {
      if (data.itens.length > 0) {
        this.filteredEmails = this.filteredEmails.concat(data.itens);
        this.offsetEmails += 1; // Incrementa o offset de 1 em 1 (página)
      }
    });
  }
  
  getTelefones() {
    this.pesquisaService.getTelefonesListagem(this.cruzamentoId, this.telefoneFilter.value || '', this.offsetTelefones, this.limitTelefones, this.segundoNivel).subscribe(data => {
      if (data.itens.length > 0) {
        this.filteredTelefones = this.filteredTelefones.concat(data.itens);
        this.offsetTelefones += 1; // Incrementa o offset de 1 em 1 (página)
      }
    });
  }
  
  getProcessos() {
    this.pesquisaService.getProcessosListagem(this.cruzamentoId, this.processoFilter.value || '', this.offsetProcessos, this.limitProcessos, this.segundoNivel).subscribe(data => {
      if (data.itens.length > 0) {
        this.filteredProcessos = this.filteredProcessos.concat(data.itens);
        this.offsetProcessos += 1; // Incrementa o offset de 1 em 1 (página)
      }
    });
  }
  
  getEnderecos() {
    this.pesquisaService.getEnderecosListagem(this.cruzamentoId, this.enderecoFilter.value || '', this.offsetEnderecos, this.limitEnderecos, this.segundoNivel).subscribe(data => {
      if (data.itens.length > 0) {
        this.filteredEnderecos = this.filteredEnderecos.concat(data.itens);
        this.offsetEnderecos += 1; // Incrementa o offset de 1 em 1 (página)
      }
    });
  }
  
  getNomes() {
    this.pesquisaService.getNomesListagem(this.cruzamentoId, this.nomeFilter.value || '', this.offSetNomes, this.limitNomes, this.segundoNivel).subscribe(data => {
      if (data.itens.length > 0) {
        this.filteredNomes = this.filteredNomes.concat(data.itens);
        this.offSetNomes += 1; // Incrementa o offset de 1 em 1 (página)
      }
    });
  }
  
  

  filter(type: string, value: string) {
    const filterValue = value.toLowerCase();
    switch (type) {
      case 'email':
        this.filteredEmails = this.emailsListados.filter(email => email.toLowerCase().includes(filterValue));
        break;
      case 'telefone':
        this.filteredTelefones = this.telefonesListados.filter(telefone => telefone.toLowerCase().includes(filterValue));
        break;
      case 'processo':
        this.filteredProcessos = this.processosListados.filter(processo => processo.toLowerCase().includes(filterValue));
        break;
      case 'endereco':
        this.filteredEnderecos = this.enderecosListados.filter(endereco => endereco.toLowerCase().includes(filterValue));
        break;
      case 'nome':
        this.filteredNomes = this.nomesListados.filter(nome => nome.toLowerCase().includes(filterValue));
        break;
    }
  }

  isSelected(item: string, type: string): boolean {
    switch (type) {
      case 'email':
        return this.selectedEmails.includes(item);
      case 'telefone':
        return this.selectedTelefones.includes(item);
      case 'processo':
        return this.selectedProcessos.includes(item);
      case 'endereco':
        return this.selectedEnderecos.includes(item);
      case 'nome':
        return this.selectedNomes.includes(item);
      default:
        return false;
    }
  }

  onSelect(value: string, type: string) {
    switch (type) {
      case 'email':
        if (!this.selectedEmails.includes(value)) {
          this.selectedEmails = [...this.selectedEmails, value]; // Atualizando a referência do array
        }
        break;
      case 'telefone':
        if (!this.selectedTelefones.includes(value)) {
          this.selectedTelefones = [...this.selectedTelefones, value]; // Atualizando a referência do array
        }
        break;
      case 'processo':
        if (!this.selectedProcessos.includes(value)) {
          this.selectedProcessos = [...this.selectedProcessos, value]; // Atualizando a referência do array
        }
        break;
      case 'endereco':
        if (!this.selectedEnderecos.includes(value)) {
          this.selectedEnderecos = [...this.selectedEnderecos, value]; // Atualizando a referência do array
        }
      case 'nome':
        if (!this.selectedNomes.includes(value)) {
          this.selectedNomes = [...this.selectedNomes, value]; // Atualizando a referência do array
        }
        break;
    }
  }

  removeChip(chip: string, type: string) {
    switch (type) {
      case 'email':
        this.selectedEmails = this.selectedEmails.filter(email => email !== chip); // Atualizando a referência do array
        break;
      case 'telefone':
        this.selectedTelefones = this.selectedTelefones.filter(telefone => telefone !== chip); // Atualizando a referência do array
        break;
      case 'processo':
        this.selectedProcessos = this.selectedProcessos.filter(processo => processo !== chip); // Atualizando a referência do array
        break;
      case 'endereco':
        this.selectedEnderecos = this.selectedEnderecos.filter(endereco => endereco !== chip); // Atualizando a referência do array
        break;
      case 'nome':
        this.selectedNomes = this.selectedNomes.filter(nome => nome !== chip); // Atualizando a referência do array
        break;
    }
  }

  toggleSelection(value: string, type: string) {
    switch (type) {
      case 'email':
        if (this.selectedEmails.includes(value)) {
          this.removeChip(value, 'email');
        } else {
          this.onSelect(value, 'email');
        }
        break;
      case 'telefone':
        if (this.selectedTelefones.includes(value)) {
          this.removeChip(value, 'telefone');
        } else {
          this.onSelect(value, 'telefone');
        }
        break;
      case 'processo':
        if (this.selectedProcessos.includes(value)) {
          this.removeChip(value, 'processo');
        } else {
          this.onSelect(value, 'processo');
        }
        break;
      case 'endereco':
        if (this.selectedEnderecos.includes(value)) {
          this.removeChip(value, 'endereco');
        } else {
          this.onSelect(value, 'endereco');
        }
        break;
      case 'nome':
        if (this.selectedNomes.includes(value)) {
          this.removeChip(value, 'nome');
        } else {
          this.onSelect(value, 'nome');
        }
        break;
    }
  }

  getFiltrosPreenchidos() {
    this.pesquisaService.getFiltrosPreenchidos(this.dadosAba?.idpesquisa?.id).subscribe(data => {

      // Preenchendo os filtros a partir dos dados retornados pela API
      this.selectedEmails = data.filtrosAplicadosEmails || [];  // Mantém vazio se não houver filtros aplicados
      this.selectedEnderecos = data.filtrosAplicadosEnderecos || [];
      this.selectedProcessos = data.filtrosAplicadosProcessos || [];
      this.selectedTelefones = data.filtrosAplicadosTelefones || [];
      this.selectedNomes = data.filtrosAplicadosNomes || [];

      this.filtrar()
    });
  }



  clearAll() {
    this.selectedEmails = [];
    this.selectedTelefones = [];
    this.selectedProcessos = [];
    this.selectedEnderecos = [];
    this.selectedNomes = []
    this.segundoNivel = false;
  }

  filtrar() {
    // Cria um array de filtros, incluindo apenas os valores selecionados
    const filtros: any[] = [];
    const filtroNomes: any[] = [];

    // Verifica se a lista de emails selecionados não está vazia e adiciona ao array de filtros
    if (this.selectedEmails?.length) {
      filtros.push(...this.selectedEmails);
    }

    // Verifica se a lista de telefones selecionados não está vazia e adiciona ao array de filtros
    if (this.selectedTelefones?.length) {
      filtros.push(...this.selectedTelefones);
    }

    // Verifica se a lista de endereços selecionados não está vazia e adiciona ao array de filtros
    if (this.selectedEnderecos?.length) {
      filtros.push(...this.selectedEnderecos);
    }

    // Verifica se a lista de processos selecionados não está vazia e adiciona ao array de filtros
    if (this.selectedProcessos?.length) {
      filtros.push(...this.selectedProcessos);
    }

    // Verifica se a lista de nomes selecionados não está vazia e adiciona ao array de filtroNomes
    if (this.selectedNomes?.length) {
      filtroNomes.push(...this.selectedNomes);
    }

    // Monta o objeto com os filtros
    const objFiltros = {
      id: this.dadosAba?.idpesquisa?.id,
      filtros: filtros,
      filtrarNomes: filtroNomes,
      removerDados: false,
      segundoNivel: this.segundoNivel
    };

    this.ui.loading();
    // Chama o serviço para cadastrar os filtros
    this.pesquisaService.cadastrarFiltros(objFiltros).subscribe(data => {

      this.ui.loaded().subscribe(() => {
        // Atraso na chamada para obter dados cruzados
        setTimeout(() => {
          this.dadosCruzadosEvent.emit();
        }, 100);
      })
    });
  }

  // Adiciona ou remove o item da seleção
  onCheckboxChange(event: MatCheckboxChange, item: string, type: string): void {
    if (event.checked) {
      // Adiciona o item ao array correspondente
      switch (type) {
        case 'email':
          this.selectedEmails.push(item);
          break;
        case 'telefone':
          this.selectedTelefones.push(item);
          break;
        case 'processo':
          this.selectedProcessos.push(item);
          break;
        case 'endereco':
          this.selectedEnderecos.push(item);
          break;
        case 'nome':
          this.selectedNomes.push(item);
          break;
      }
    } else {
      // Remove o item do array correspondente
      switch (type) {
        case 'email':
          const emailIndex = this.selectedEmails.indexOf(item);
          if (emailIndex >= 0) {
            this.selectedEmails.splice(emailIndex, 1);
          }
          break;
        case 'telefone':
          const telefoneIndex = this.selectedTelefones.indexOf(item);
          if (telefoneIndex >= 0) {
            this.selectedTelefones.splice(telefoneIndex, 1);
          }
          break;
        case 'processo':
          const processoIndex = this.selectedProcessos.indexOf(item);
          if (processoIndex >= 0) {
            this.selectedProcessos.splice(processoIndex, 1);
          }
          break;
        case 'endereco':
          const enderecoIndex = this.selectedEnderecos.indexOf(item);
          if (enderecoIndex >= 0) {
            this.selectedEnderecos.splice(enderecoIndex, 1);
          }
          break;
        case 'nome':
          const nomeIndex = this.selectedNomes.indexOf(item);
          if (nomeIndex >= 0) {
            this.selectedNomes.splice(nomeIndex, 1);
          }
          break;
      }
    }
  }

  onScrollEmails(event: any) {
    const matSelect = event.target;
    if (matSelect.scrollTop + matSelect.clientHeight >= matSelect.scrollHeight) {
      this.offsetEmails += this.limitEmails; // Aumenta o offset
      this.getEmails(); // Carrega mais emails
    }
  }

  onScrollTelefones(event: any) {
    const matSelect = event.target;
    if (matSelect.scrollTop + matSelect.clientHeight >= matSelect.scrollHeight) {
      this.offsetTelefones += this.limitTelefones; // Aumenta o offset
      this.getTelefones(); // Carrega mais emails
    }
  }

  onScrollProcessos(event: any) {
    const matSelect = event.target;
    if (matSelect.scrollTop + matSelect.clientHeight >= matSelect.scrollHeight) {
      this.offsetProcessos += this.limitProcessos; // Aumenta o offset
      this.getProcessos(); // Carrega mais emails
    }
  }

  onScrollEnderecos(event: any) {
    const matSelect = event.target;
    if (matSelect.scrollTop + matSelect.clientHeight >= matSelect.scrollHeight) {
      this.offsetEnderecos += this.limitEnderecos; // Aumenta o offset
      this.getEnderecos(); // Carrega mais emails
    }
  }

  onScrollNomes(event: any) {
    console.log('scroll')
    const matSelect = event.target;
    if (matSelect.scrollTop + matSelect.clientHeight >= matSelect.scrollHeight) {
      this.offSetNomes += this.limitNomes; // Aumenta o offset
      this.getNomes(); // Carrega mais emails
    }
  }

  onOpenedChange(opened: boolean, type: string, matSelect: MatSelect) {
    if (opened) {
      setTimeout(() => {
        const panel = matSelect.panel.nativeElement;
        panel.addEventListener('scroll', (event) => this.onScroll(event, type));
      });
    }
  }

  // Função que lida com o scroll em qualquer select
  onScroll(event: any, type: string) {
    const panel = event.target;
    if (panel.scrollTop + panel.clientHeight >= panel.scrollHeight) {
      if (type === 'emails') {
        this.getEmails();
      } 
      if (type === 'telefones') {
        this.getTelefones();
      }
      if (type === 'processos') {
        this.getProcessos();
      }
      if (type === 'enderecos') {
        this.getEnderecos();
      }
      if (type === 'nomes') {
        console.log('caiu nesse scroll')
        this.getNomes();
      }
    }
  }

  toggleSelectAll(event: any, type: string) {
    if (type === 'email') {
      if (event.checked) {
        this.selectedEmails = [...this.filteredEmails];
        this.allSelectedEmails = true;
      } else {
        this.selectedEmails = [];
        this.allSelectedEmails = false;
      }
    } else if (type === 'telefones') {
      if (event.checked) {
        this.selectedTelefones = [...this.filteredTelefones];
        this.allSelectedTelefones = true;
      } else {
        this.selectedTelefones = [];
        this.allSelectedTelefones = false;
      }
    } else if (type === 'processos') {
      if (event.checked) {
        this.selectedProcessos = [...this.filteredProcessos];
        this.allSelectedProcessos = true;
      } else {
        this.selectedProcessos = [];
        this.allSelectedProcessos = false;
      }
    } else if (type === 'enderecos') {
      if (event.checked) {
        this.selectedEnderecos = [...this.filteredEnderecos];
        this.allSelectedEnderecos = true;
      } else {
        this.selectedEnderecos = [];
        this.allSelectedEnderecos = false;
      }
    } else if (type === 'nomes') {
      if (event.checked) {
        this.selectedNomes = [...this.filteredNomes];
        this.allSelectedNomes = true;
      } else {
        this.selectedNomes = [];
        this.allSelectedNomes = false;
      }
    }
  }

}