<!-- <div class="text-center header d-flex justify-content-between align-items-center">
    <div></div>
    <h1>Linha do Tempo de Processos - {{data.documento}}</h1>
    <button mat-icon-button aria-label="close dialog" mat-dialog-close>
        <mat-icon>close</mat-icon>
    </button>
</div> -->
<div class="container border-grey m-4">
    <h2 class="title-tab text-center">Linha do Tempo de Processos</h2>

    <div class="d-flex justify-content-center" *ngIf="!isLoadingGrafico">
        <button class="m-3 p-2" [ngClass]="tipoParametro == 'quantidade' ? 'buttonParametroActive' : 'buttonParametroInative'" (click)="changeTipoParametro('quantidade')">Por Quantidade</button>
        <button class="m-3 p-2" [ngClass]="tipoParametro == 'valor' ? 'buttonParametroActive' : 'buttonParametroInative'" (click)="changeTipoParametro('valor')">Por Valor</button>
        <button class="m-3 p-2" [ngClass]="tipoParametro == 'linha' ? 'buttonParametroActive' : 'buttonParametroInative'" (click)="changeTipoParametro('linha')">Gráfico em Linha</button>
    </div>

    <loader-only class="mt-3" *ngIf="isLoadingGrafico"></loader-only>
    <!-- ====== por quantidade ===== -->
    <div class="d-flex mb-5 content-chart" [ngClass]="{'centered': chartData?.length <= 8}" *ngIf="chartData?.length >= 1 && tipoParametro == 'quantidade' && !isLoadingGrafico">
        <div class="axis-y axis-y-fixed">
            <div *ngFor="let value of chartYValues" class="tick">{{ value }}</div>
            <div></div>
        </div>
        <div *ngFor="let yearData of chartData" class="year-chart text-center">
            <div class="chart">
                <div *ngFor="let item of yearData.data" class="bar" [style.height.%]="item.value === 0 ? 0 : ((item.value / chartMaxValue) * 80) + 10" [matTooltip]="getTooltipContentQuantidade(item)" matTooltipClass="test">
                    <!-- <span>{{ item.label }}</span> [matTooltip]="getTooltipContent(item)" matTooltipClass="test" -->
                </div>
            </div>
            <hr class="line-graph"> 
            <h2 class="title-ano pt-3">{{ yearData.year }}</h2>
        </div>
    </div>

    <!-- ========= por valor ====== -->
    <div class="d-flex mb-5 content-chart" [ngClass]="{'centered': chartData?.length <= 8}" *ngIf="chartData?.length >= 1 && tipoParametro == 'valor' && !isLoadingGrafico">
        <div class="axis-y axis-y-fixed">
            <div *ngFor="let value of chartYValues" class="tick">{{ formatarValor(value) }}</div>
            <div></div>
        </div>
        <div *ngFor="let yearData of chartData" class="year-chart text-center">
            <div class="chart">
                <div *ngFor="let item of yearData.data" class="bar" [style.height.%]="item.valor === 0 ? 0 : ((item.valor / chartMaxValue) * 80) + 10" [matTooltip]="getTooltipContentValor(item)" matTooltipClass="test">
                    <!-- <span>{{ item.label }}</span> [matTooltip]="getTooltipContent(item)" matTooltipClass="test" -->
                </div>
            </div>
            <hr class="line-graph"> 
            <h2 class="title-ano pt-3">{{ yearData.year }}</h2>
        </div>
    </div>

    <app-grafico-tipo-linha [dados]="dadosGrafico" *ngIf="chartData?.length >= 1 && tipoParametro == 'linha' && !isLoadingGrafico"></app-grafico-tipo-linha>
    
    
    <div class="d-flex justify-content-center align-items-center" *ngIf="chartData?.length == 0" style="margin-top: 60px; margin-bottom: 40px;">
        <p>Nenhum Processo Encontrado</p>
    
    </div>
    
    <div class="row d-flex justify-content-center" *ngIf="!isLoadingGrafico" >
        <div class="col-2 d-flex justify-content-center align-items-flex-start">
            <div class="box-ativo"></div>
            <p class="m-polo">Polo Ativo</p>
        </div>
    
        <div class="col-2 d-flex justify-content-center align-items-flex-start">
            <div class="box-passivo"></div>
            <p class="m-polo">Polo Passivo</p>
        </div>
    
        <div class="col-2 d-flex justify-content-center align-items-flex-start">
            <div class="box-outros"></div>
            <p class="m-polo">Outros</p>
        </div>
    </div>
</div>