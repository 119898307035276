<div class="div-ext">
  <div class="cabecalho">
    <p class="title">Pesquisa realizada</p>
  </div>
  <br>
  <div *ngIf="data.pesquisa.status == StatusPesquisa.Finalizada">
    <b><p>Uma pesquisa para o mesmo CPF/CNPJ ({{ data.pesquisa.numero}}) e produto ({{data.pesquisa.fonteGrupo.nome }}) foi realizada no dia {{ data.pesquisa.dataCadastro | date: 'dd/MM/yyyy'}}. Tem certeza de que deseja emitir uma nova?</p></b>
    <br /> <br/>
    <button class="pagar" mat-button (click)="exportarClicked(data)">PDF</button>
    &nbsp;&nbsp;&nbsp;&nbsp;
    <button class="pagar" mat-button (click)="exportarExcelClicked(data)">Excel</button>
    <br /> <br/>
  </div>
  <div *ngIf="data.pesquisa.status != StatusPesquisa.Finalizada && data.pesquisa.status != StatusPesquisa.Cancelada">
      <b><p>Já existe uma pesquisa <u>em andamento</u> para o CPF/CNPJ {{ data.pesquisa.numero}} cadastrada em {{ data.pesquisa.dataCadastro | date: 'dd/MM/yyyy'}} para o produto {{data.pesquisa.fonteGrupo.nome }}. Aguarde a finalização por favor!</p></b>
  </div>

  <div class="botao">
    <mat-dialog-actions align="end">
      <div *ngIf="data.pesquisa.status == StatusPesquisa.Finalizada">
      <button class="pagar" mat-button (click)="cadastrarPesquisa()" cdkFocusInitial color="accent">SIM</button>
      <button class="cancelar" mat-button mat-dialog-close>NÃO</button>
    </div>
    <div *ngIf="data.pesquisa.status != StatusPesquisa.Finalizada">
      <button class="cancelar" mat-button mat-dialog-close>OK</button>
    </div>
    </mat-dialog-actions>
  </div>
</div>
