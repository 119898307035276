import { HttpStatusCode } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ToastrService } from 'ngx-toastr';
import { MensageResponseComponent } from 'src/app/components/view/mensage-response/mensage-response.component';
import { ProdutosEnum } from 'src/app/shared/enums/produtos.enum';
import { FonteGrupoService } from 'src/app/shared/services/http/fonte-grupo.service';
import { PesquisaService } from 'src/app/shared/services/http/pesquisa.service';
import { UtilService } from 'src/app/shared/services/http/util.service';
import { UiService } from 'src/app/shared/services/ui.service';
import { Textos } from 'src/app/shared/textos';
import { ModalGrupoPesquisaComponent } from '../../teia-relacionamento/menu-teia-relacionamento/acoes-menu/modal-grupo-pesquisa/modal-grupo-pesquisa.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-resultado-localizador',
  templateUrl: './resultado-localizador.component.html',
  styleUrls: ['./resultado-localizador.component.scss']
})
export class ResultadoLocalizadorComponent implements OnInit {

  @Input() retornoInfo: any
  @Input() formPesquisa: any
  paramAtivos;
  nome;

  constructor(
    private fonteGrupoService: FonteGrupoService,
    private ui: UiService,
    private pesquisaService: PesquisaService,
    private toastr: ToastrService,
    private utilService: UtilService,
    private _snackBar: MatSnackBar,
    public dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.retornoInfo = this.retornoInfo.dados
    console.log(this.retornoInfo);
    console.log(this.formPesquisa)
  }

  verificaSeTemProdutoVinculado(produtoEnum, doc) {
    console.log(produtoEnum)
    this.fonteGrupoService.listAtivoProduto(produtoEnum).subscribe(response => {
      this.paramAtivos = response
      console.log(this.paramAtivos.length)
      if (this.paramAtivos.length == 1) {
        this.realizarPesquisa(produtoEnum, this.paramAtivos[0].fonteGrupo.id, doc,)
      }
      if (this.paramAtivos.length > 1) {
        this.openGrupoPesquisa(response, produtoEnum, doc)
      }
      else {
        this.toastr.warning('Usuário sem vinculos de pesquisa, entre em contato com o suporte', 'CredValue')
      }
    }, (err: any) => {
      this.ui.loaded().subscribe(() => {
        return
      })
    });
  }

  realizarPesquisa(tipoPesquisa, idPesquisa: number, documento) {
    this.ui.loading()
    var documento = documento
    // verifica o tipo do documento 
    let tipoDocumento = documento.replaceAll('.', '').replaceAll('-', '').replaceAll('/', '');
    if (tipoDocumento.length == 11) {
      tipoDocumento = 1
    }
    if (tipoDocumento.length == 14) {
      tipoDocumento = 2
    }

    const request = {
      nome: '',
      notificacao: true,
      numero: documento.replaceAll('.', '').replaceAll('-', '').replaceAll('/', ''),
      tipo: tipoDocumento
    }

    this.utilService.resolverNome(request, 'localizador').subscribe(resultadoResolverNome => {
      if (!resultadoResolverNome || !resultadoResolverNome.nome) {
        this.ui.loaded().subscribe(() => {
          this.nome = "";
          this.toastr.warning(Textos.NOME_NAO_ENCONTRADO, "Portal Credvalue")
        });
        return;
      }
      const requestPesquisa = {
        idFonteGrupo: idPesquisa,
        nome: resultadoResolverNome.nome,
        notificacao: true,
        numero: documento.replaceAll('.', '').replaceAll('-', '').replaceAll('/', ''),
        tipo: tipoDocumento
      }
      this.pesquisaService.cadastrarCliente(requestPesquisa).subscribe(data => {
        if (HttpStatusCode.Ok) {
          this.ui.loaded().subscribe(() => {
            if (tipoPesquisa == ProdutosEnum.Cadastrais) {
              this.openSnackBar('Sucesso', 'Pesquisa Cadastral Lançada com Sucesso');
            }
            if (tipoPesquisa == ProdutosEnum.Processo) {
              this.openSnackBar('Sucesso', 'Pesquisa de Processos Lançada com Sucesso');
            }
            if (tipoPesquisa == ProdutosEnum.Patrimonial) {
              this.openSnackBar('Sucesso', 'Pesquisa Patrimonial Lançada com Sucesso');
            }
            if (tipoPesquisa == ProdutosEnum.Credito) {
              this.openSnackBar('Sucesso', 'Pesquisa de Crédito Lançada com Sucesso');
            }
            if (tipoPesquisa == ProdutosEnum.Dossie) {
              this.openSnackBar('Sucesso', 'Pesquisa de Dossiê Completo Lançada com Sucesso');
            }
          })
        }
      });
    }, (err: any) => {
      this.ui.loaded().subscribe(() => {
        this.toastr.info('Ocorreu um erro ao realizar a pesquisa, tente novamente.')
      })
    });
  }

  openSnackBar(titulo: string, mensagem: string) {
    this._snackBar.openFromComponent(MensageResponseComponent, {
      data: {
        titulo: titulo,
        mensagem: mensagem,
      },
      duration: 5000,
      horizontalPosition: 'right',
      verticalPosition: 'top'
    });
  }

  openGrupoPesquisa(grupos, produtoEnum, doc) {
    const dialog = this.dialog.open(ModalGrupoPesquisaComponent, {
      width: '280px',
      data: {
        idProduto: produtoEnum,
        grupos: grupos,
        doc: doc,
        header: "localizador"
      }
    });
  }


}
