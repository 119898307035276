import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Data, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ProdutosEnum } from 'src/app/shared/enums/produtos.enum';
import { PesquisaMassaService } from 'src/app/shared/services/http/pesquisa-massa.service';
import { PrecificadorService } from 'src/app/shared/services/precificador/precificador.service';
import { UiService } from 'src/app/shared/services/ui.service';

@Component({
  selector: 'app-anexar-ativos',
  templateUrl: './anexar-ativos.component.html',
  styleUrls: ['./anexar-ativos.component.scss']
})
export class AnexarAtivosComponent implements OnInit {

  anexandoArquivo = false
  produtoId;
  totalDocumentos;
  produtoReferencia;
  dataTable = [];
  payloadRequest = {};
  constructor(
    private router: Router,
    private ui: UiService,
    private precificadorService: PrecificadorService,
    public dialogRef: MatDialogRef<AnexarAtivosComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Data,
    private toast: ToastrService
  ) { }

  ngOnInit(): void {
  }

  handleUpload(event) {
    let name;
    let base64;
    this.anexandoArquivo =  true
    const file = event.target.files[0];
    const reader = new FileReader();
    const target: DataTransfer = <DataTransfer>(<unknown>event.target);

    if(target.files.length == 1){
      name = target.files[0].name;
      reader.readAsDataURL(file);
      reader.onload = () => {
        base64 = reader.result
        this.uploadFile(name, base64)
      };
    }
    if (event.target.files.length > 1) {
      this.toast.info("Somente 1 documento por vez")
      event.preventDefault();
      event.value = ""; // clear the older value 
    }
}

  uploadFile(name, base64){
    this.dataTable = [];
    this.payloadRequest = {
      nomeArquivo: name,
      arquivoExcel: base64.split(',')[1],
    }
    this.precificadorService.validarPlanilhaAtivos(this.payloadRequest).subscribe(data => {
      this.dataTable = data
      console.log(this.dataTable)
      this.anexandoArquivo = false
    }, (err:any) => {
      this.ui.loaded().subscribe(() => {
        this.toast.info('Ocorreu um erro ao anexar o arquivo, tente novamente.')
        this.anexandoArquivo = false
      })
    });
    if(this.dataTable.length == 0){
      setTimeout(() => {
       this.anexandoArquivo = false
    }, 2000);
    }
  }

  incluirAtivos(){
    this.dialogRef.close(this.dataTable);
  }

}
