<div *ngIf="pesquisaExistente">
    <div class="mt-5" *ngIf="isCpf">
      <mat-accordion>
        <mat-expansion-panel class="py-2" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <h3 class="mb-0 d-flex align-items-center">
                <mat-icon> phone</mat-icon> &nbsp; TELEFONES
              </h3>
            </mat-panel-title>
    
          </mat-expansion-panel-header>
          <table class="table">
            <thead class="thead-light">
              <tr class="text-center">
                <th scope="col" class="thead-top-left p-2">Telefone</th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngIf="objetoRelatorioCadastral?.telefones?.length > 0; else noDataTelef">
                <tr class="table-info text-center" *ngFor="let telefone of objetoRelatorioCadastral.telefones">
                  <td>{{ telefone }}</td>
                </tr>
              </ng-container>
              <ng-template #noDataTelef>
                <tr>
                  <td colspan="1" class="text-center">Não há telefones disponíveis</td>
                </tr>
              </ng-template>
            </tbody>
            
          </table>
        </mat-expansion-panel>
      </mat-accordion>
    
      <!--emails-->
      <mat-accordion>
        <mat-expansion-panel class="mt-3 py-2" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <h3 class="mb-0 d-flex align-items-center">
                <mat-icon> email</mat-icon> &nbsp;
                ENDEREÇOS ELETRÔNICO
              </h3>
            </mat-panel-title>
    
          </mat-expansion-panel-header>
          <table class="table">
            <thead class="thead-light">
              <tr class="text-center">
                <th scope="col" class="thead-top-only p-2">E-mail</th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngIf="objetoRelatorioCadastral?.emails?.length > 0; else noDataMail">
                <tr class="table-info text-center" *ngFor="let email of objetoRelatorioCadastral.emails">
                  <td>{{ email }}</td>
                </tr>
              </ng-container>
              <ng-template #noDataMail>
                <tr>
                  <td colspan="1" class="text-center">Não há emails disponíveis</td>
                </tr>
              </ng-template>
            </tbody>
            
          </table>
        </mat-expansion-panel>
      </mat-accordion>
    
      <!--enderecos-->
      <mat-accordion>
        <mat-expansion-panel class="mt-3 py-2" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <h3 class="mb-0 d-flex align-items-center">
                <mat-icon>home</mat-icon>&nbsp;
                ENDEREÇOS
              </h3>
            </mat-panel-title>
    
          </mat-expansion-panel-header>
          <table class="table">
            <thead class="thead-light">
              <tr class="text-center">
                <th scope="col" class="thead-top-left p-2">Logradouro</th>
                <th scope="col" class="thead p-2">Numero</th>
                <th scope="col" class="thead p-2">Compl.</th>
                <th scope="col" class="thead p-2">Bairro</th>
                <th scope="col" class="thead p-2">Cidade</th>
                <th scope="col" class="thead p-2">UF</th>
                <th scope="col" class="thead-top-right p-2">CEP</th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngIf="objetoRelatorioCadastral?.enderecos?.length > 0; else noDataEndere">
                <tr class="text-center table-info" *ngFor="let endereco of objetoRelatorioCadastral.enderecos">
                  <td>{{ endereco?.logradouro }}</td>
                  <td>{{ endereco?.numero }}</td>
                  <td>{{ endereco?.complemento }}</td>
                  <td>{{ endereco?.bairro }}</td>
                  <td>{{ endereco?.cidade }}</td>
                  <td>{{ endereco?.uf }}</td>
                  <td>{{ endereco?.cep }}</td>
                </tr>
              </ng-container>
              <ng-template #noDataEndere>
                <tr>
                  <td colspan="7" class="text-center">Não há endereços disponíveis</td>
                </tr>
              </ng-template>
            </tbody>
          </table>
        </mat-expansion-panel>
      </mat-accordion>
    
      <!-- <mat-accordion>
        <mat-expansion-panel class="mt-3 py-2" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <h3 class="mb-0 d-flex align-items-center">
                <mat-icon>place</mat-icon>&nbsp;
                LOCALIZAÇÃO
              </h3>
            </mat-panel-title>
    
          </mat-expansion-panel-header>
          <table class="table">
            <thead class="thead-light">
              <tr class="text-center">
                <th scope="col" class="thead-top-left p-2">Mapa</th>
                <th scope="col" class="thead-top-right p-2">Fachada</th>
              </tr>
            </thead>
            <tbody>
              <tr class="text-center table-info">
                <td class="col-6">
                  <iframe id="localizacao" width="100%" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"
                    src="https://www.google.com/maps/embed/v1/place?q=$emilio+Ribas+cambui+campinas&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8"
                    class="iframe-mapa"></iframe>
                </td>
                <td class="col-6">
                  <img
                    src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQK25fu0jhvg593G8sY9Dog94rpouNMWbU7ipxiGRK0B9KW9qCiT_tofSneyE2Ixs__1UE&usqp=CAU"
                    alt="">
                </td>
              </tr>
            </tbody>
          </table>
        </mat-expansion-panel>
      </mat-accordion> -->
    
    
      <!--pessoas vinculadas-->
      <mat-accordion>
        <mat-expansion-panel class="mt-3 py-2" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <h3 class="mb-0 d-flex align-items-center">
                <mat-icon>person</mat-icon>&nbsp;
                PESSOAS VINCULADAS
              </h3>
            </mat-panel-title>
    
          </mat-expansion-panel-header>
          <table class="table">
            <thead class="thead-light">
              <tr class="text-center">
                <th scope="col" class="thead-top-left p-2">Nome</th>
                <th scope="col" class="thead p-2">CPFCNPJ</th>
                <th scope="col" class="thead-top-right p-2">Vínculo</th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngIf="objetoRelatorioCadastral?.pessoasRelacionadas?.length > 0; else noDataPessoasRe">
                <tr class="text-center table-info" *ngFor="let pessoasRelacionadas of objetoRelatorioCadastral.pessoasRelacionadas">
                  <td>{{ pessoasRelacionadas?.nome }}</td>
                  <td>{{ pessoasRelacionadas?.cpfCnpj }}</td>
                  <td>{{ pessoasRelacionadas?.relacao }}</td>
                </tr>
              </ng-container>
              <ng-template #noDataPessoasRe>
                <tr>
                  <td colspan="3" class="text-center">Não há pessoas relacionadas disponíveis</td>
                </tr>
              </ng-template>
            </tbody>
            
          </table>
        </mat-expansion-panel>
      </mat-accordion>
    
      <!--participacoes societarias-->
      <mat-accordion>
        <mat-expansion-panel class="mt-3 py-2" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <h3 class="mb-0 d-flex align-items-center">
                <mat-icon>work</mat-icon>&nbsp;
                PARTICIPAÇÕES SOCIETÁRIAS
              </h3>
            </mat-panel-title>
    
          </mat-expansion-panel-header>
          <table class="table">
            <thead class="thead-light">
              <tr class="text-center">
                <th scope="col" class="thead-top-left p-2">Nome</th>
                <th scope="col" class="thead p-2">CNPJ</th>
                <th scope="col" class="thead p-2">Data Entrada</th>
                <th scope="col" class="thead p-2">Qualificação</th>
                <th scope="col" class="thead-top-right p-2">Ações</th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngIf="objetoRelatorioCadastral?.participacaoEmpresas?.length > 0; else noDataParticipacaoSocietaria">
                <tr class="text-center table-info" *ngFor="let participacaoEmpresas of objetoRelatorioCadastral.participacaoEmpresas">
                  <td>{{ participacaoEmpresas?.nome }}</td>
                  <td>{{ participacaoEmpresas?.cpfCnpj }}</td>
                  <td>{{ participacaoEmpresas?.dataEntrada | date: 'dd/MM/yyyy' }}</td>
                  <td>{{ participacaoEmpresas?.qualificacao }}</td>
                  <td>
                    <button class="gs-botaoAzulPadrao btn" (click)="verificaSeTemProdutoVinculado(1, participacaoEmpresas?.cpfCnpj, 'cadastrais')">gerar pesquisa</button>
                  </td>
                </tr>
              </ng-container>
              <ng-template #noDataParticipacaoSocietaria>
                <tr>
                  <td colspan="6" class="text-center">Não há participações em empresas disponíveis</td>
                </tr>
              </ng-template>
            </tbody>
          </table>
        </mat-expansion-panel>
      </mat-accordion>
    
      <!--negativos-->
      <!-- <mat-accordion>
        <mat-expansion-panel class="mt-3 py-2" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <h3 class="mb-0 d-flex align-items-center">
                FONTES COM RESULTADOS NEGATIVOS
              </h3>
            </mat-panel-title>
    
          </mat-expansion-panel-header>
          <table class="table">
            <thead class="thead-light">
              <tr class="text-center">
                <th scope="col" class="thead-top-only p-2">Fontes</th>
              </tr>
            </thead>
            <tbody>
              <tr class="table-info text-center">
                <td> luana.mariah@hotmail.com </td>
              </tr>
              <tr class="table-info text-center">
                <td> Nenhum Resultado </td>
              </tr>
            </tbody>
          </table>
        </mat-expansion-panel>
      </mat-accordion>
    
      falhas
      <mat-accordion>
        <mat-expansion-panel class="mt-3 py-2" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <h3 class="mb-0 d-flex align-items-center">
                FONTES QUE APRESENTARAM FALHAS NA CONSULTA
              </h3>
            </mat-panel-title>
    
          </mat-expansion-panel-header>
          <table class="table">
            <thead class="thead-light">
              <tr class="text-center">
                <th scope="col" class="thead-top-only p-2">Fontes</th>
              </tr>
              <tr class="table-info text-center">
                <td> Nenhum Resultado </td>
              </tr>
            </thead>
            <tbody>
              <tr class="table-info text-center">
                <td> luana.mariah@hotmail.com </td>
              </tr>
            </tbody>
          </table>
        </mat-expansion-panel>
      </mat-accordion>
    
      comarcas falhas
      <mat-accordion>
        <mat-expansion-panel class="mt-3 py-2" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <h3 class="mb-0 d-flex align-items-center">
                FONTES QUE APRESENTARAM FALHAS DE COMARCAS NA CONSULTAS
              </h3>
            </mat-panel-title>
    
          </mat-expansion-panel-header>
          <table class="table">
            <thead class="thead-light">
              <tr class="text-center">
                <th scope="col" class="thead-top-left p-2">Fontes</th>
                <th scope="col" class="thead-top-right p-2">Comarcas</th>
              </tr>
            </thead>
            <tbody>
              <tr class="table-info text-center">
                <td> luana.mariah@hotmail.com </td>
                <td> luana.mariah@hotmail.com </td>
              </tr>
              <tr class="table-info text-center">
                <td> Nenhum Resultado </td>
                <td> Nenhum Resultado </td>
              </tr>
            </tbody>
          </table>
        </mat-expansion-panel>
      </mat-accordion> -->
    </div>
    
    <div class="mt-5" *ngIf="isCnpj">
      <!--Socios-->
      <mat-accordion>
        <mat-expansion-panel class="mt-3 py-2" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <h3 class="mb-0 d-flex align-items-center">
                <mat-icon>person</mat-icon>&nbsp;
                SOCIOS - RECEITA FEDERAL (QSA)
              </h3>
            </mat-panel-title>
    
          </mat-expansion-panel-header>
          <table class="table">
            <thead class="thead-light">
              <tr class="text-center">
                <th scope="col" class="thead-top-left p-2">Nome</th>
                <th scope="col" class="thead-top-right p-2">Qualificação</th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngIf="objetoRelatorioCadastral?.listaSocioRF?.length > 0; else noDataRF">
                <tr class="text-center table-info" *ngFor="let socioRF of objetoRelatorioCadastral.listaSocioRF">
                  <td>{{ socioRF?.nome }}</td>
                  <td>{{ socioRF?.qualificacao }}</td>
                </tr>
              </ng-container>
              <ng-template #noDataRF>
                <tr>
                  <td colspan="2" class="text-center">Não há sócios disponíveis</td>
                </tr>
              </ng-template>
            </tbody>
          </table>
        </mat-expansion-panel>
      </mat-accordion>
    
      <mat-accordion>
        <mat-expansion-panel class="mt-3 py-2" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <h3 class="mb-0 d-flex align-items-center">
                <mat-icon>person</mat-icon>&nbsp;
                SOCIOS
              </h3>
            </mat-panel-title>
    
          </mat-expansion-panel-header>
          <table class="table">
            <thead class="thead-light">
              <tr class="text-center">
                <th scope="col" class="thead-top-left p-2">Nome</th>
                <th scope="col" class="thead p-2">CPF/CNPJ</th>
                <th scope="col" class="thead p-2">Data Entrada</th>
                <th scope="col" class="thead-top-right p-2">Qualificação</th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngIf="objetoRelatorioCadastral?.listaSocio?.length > 0; else noDataSocios">
                <tr class="text-center table-info" *ngFor="let socio of objetoRelatorioCadastral.listaSocio">
                  <td>{{ socio?.nome }}</td>
                  <td>{{ socio?.cpfCnpj }}</td>
                  <td>{{ socio?.dataEntradaSociedade }}</td>
                  <td>{{ socio?.qualificacao }}</td>
                </tr>
              </ng-container>
              <ng-template #noDataSocios>
                <tr>
                  <td colspan="4" class="text-center">Não há sócios disponíveis</td>
                </tr>
              </ng-template>
            </tbody>        
          </table>
        </mat-expansion-panel>
      </mat-accordion>
    
      <!-- telefones -->
      <mat-accordion>
        <mat-expansion-panel class="mt-3 py-2" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <h3 class="mb-0 d-flex align-items-center">
                <mat-icon> phone</mat-icon> &nbsp; TELEFONES
              </h3>
            </mat-panel-title>
    
          </mat-expansion-panel-header>
          <table class="table">
            <thead class="thead-light">
              <tr class="text-center">
                <th scope="col" class="thead-top-only p-2">Telefone</th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngIf="objetoRelatorioCadastral?.telefones?.length > 0; else noDataTel">
                <tr class="table-info text-center" *ngFor="let telefone of objetoRelatorioCadastral.telefones">
                  <td>{{ telefone }}</td>
                </tr>
              </ng-container>
              <ng-template #noDataTel>
                <tr>
                  <td colspan="1" class="text-center">Não há telefones disponíveis</td>
                </tr>
              </ng-template>
            </tbody>
          </table>
        </mat-expansion-panel>
      </mat-accordion>
    
      <!--enderecos-->
      <mat-accordion>
        <mat-expansion-panel class="mt-3 py-2" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <h3 class="mb-0 d-flex align-items-center">
                <mat-icon>home</mat-icon>&nbsp;
                ENDEREÇOS
              </h3>
            </mat-panel-title>
    
          </mat-expansion-panel-header>
          <table class="table">
            <thead class="thead-light">
              <tr class="text-center">
                <th scope="col" class="thead-top-left p-2">Logradouro</th>
                <th scope="col" class="thead p-2">Numero</th>
                <th scope="col" class="thead p-2">Compl.</th>
                <th scope="col" class="thead p-2">Bairro</th>
                <th scope="col" class="thead p-2">Cidade</th>
                <th scope="col" class="thead p-2">UF</th>
                <th scope="col" class="thead-top-right p-2">CEP</th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngIf="objetoRelatorioCadastral?.logradouro || objetoRelatorioCadastral?.enderecos?.length > 0; else noDataEnde">
                <tr class="text-center table-info" *ngIf="objetoRelatorioCadastral?.logradouro">
                  <td>{{ objetoRelatorioCadastral?.logradouro }}</td>
                  <td>{{ objetoRelatorioCadastral?.numero }}</td>
                  <td>{{ objetoRelatorioCadastral?.complemento }}</td>
                  <td>{{ objetoRelatorioCadastral?.bairro }}</td>
                  <td>{{ objetoRelatorioCadastral?.cidade }}</td>
                  <td>{{ objetoRelatorioCadastral?.uf }}</td>
                  <td>{{ objetoRelatorioCadastral?.cep }}</td>
                </tr>
  
                <tr class="text-center table-info" *ngFor="let endereco of objetoRelatorioCadastral.enderecos">
                  <td>{{ endereco?.logradouro }}</td>
                  <td>{{ endereco?.numero }}</td>
                  <td>{{ endereco?.complemento }}</td>
                  <td>{{ endereco?.bairro }}</td>
                  <td>{{ endereco?.cidade }}</td>
                  <td>{{ endereco?.uf }}</td>
                  <td>{{ endereco?.cep }}</td>
                </tr>
              </ng-container>
              <ng-template #noDataEnde>
                <tr>
                  <td colspan="7" class="text-center">Não há endereços disponíveis</td>
                </tr>
              </ng-template>
            </tbody>
          </table>
        </mat-expansion-panel>
      </mat-accordion>
    
      <!--emails-->
      <mat-accordion>
        <mat-expansion-panel class="mt-3 py-2" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <h3 class="mb-0 d-flex align-items-center">
                <mat-icon> email</mat-icon> &nbsp;
                ENDEREÇOS ELETRÔNICO
              </h3>
            </mat-panel-title>
    
          </mat-expansion-panel-header>
          <table class="table">
            <thead class="thead-light">
              <tr class="text-center">
                <th scope="col" class="thead-top-only p-2">E-mail</th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngIf="objetoRelatorioCadastral?.emails?.length > 0; else noDataEmail">
                <tr class="table-info text-center" *ngFor="let email of objetoRelatorioCadastral.emails">
                  <td>{{ email }}</td>
                </tr>
              </ng-container>
              <ng-template #noDataEmail>
                <tr>
                  <td colspan="1" class="text-center">Não há e-mails disponíveis</td>
                </tr>
              </ng-template>
            </tbody>        
          </table>
        </mat-expansion-panel>
      </mat-accordion>
    
      <!-- <mat-accordion>
        <mat-expansion-panel class="mt-3 py-2" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <h3 class="mb-0 d-flex align-items-center">
                <mat-icon>place</mat-icon>&nbsp;
                LOCALIZAÇÃO
              </h3>
            </mat-panel-title>
    
          </mat-expansion-panel-header>
          <table class="table">
            <thead class="thead-light">
              <tr class="text-center">
                <th scope="col" class="thead-top-left p-2">Mapa</th>
                <th scope="col" class="thead-top-right p-2">Fachada</th>
              </tr>
            </thead>
            <tbody>
              <tr class="text-center table-info">
                <td class="col-6">
                  <iframe id="localizacao"
                  width="100%" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://www.google.com/maps/embed/v1/place?q=$emilio+Ribas+cambui+campinas&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8"
                  class="iframe-mapa"></iframe>
                </td>
                <td class="col-6">
                  <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQK25fu0jhvg593G8sY9Dog94rpouNMWbU7ipxiGRK0B9KW9qCiT_tofSneyE2Ixs__1UE&usqp=CAU" alt="">
                </td>
              </tr>
            </tbody>
          </table>
        </mat-expansion-panel>
      </mat-accordion> -->
    
      <!--participacoes societarias-->
      <mat-accordion>
        <mat-expansion-panel class="mt-3 py-2" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <h3 class="mb-0 d-flex align-items-center">
                <mat-icon>work</mat-icon>&nbsp;
                PARTICIPAÇÕES SOCIETÁRIAS
              </h3>
            </mat-panel-title>
    
          </mat-expansion-panel-header>
          <table class="table">
            <thead class="thead-light">
              <tr class="text-center">
                <th scope="col" class="thead-top-left p-2">Nome</th>
                <th scope="col" class="thead p-2">CNPJ</th>
                <!-- <th scope="col" class="thead p-2">Data Entrada</th> -->
                <th scope="col" class="thead-top-right p-2">Qualificação</th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngIf="objetoRelatorioCadastral?.listaParticipacaoSocietaria?.length > 0; else noDataParticipacao">
                <tr class="text-center table-info" *ngFor="let participacaoSocietaria of objetoRelatorioCadastral.listaParticipacaoSocietaria">
                  <td>{{ participacaoSocietaria.nome }}</td>
                  <td>{{ participacaoSocietaria.cnpj }}</td>
                  <td>{{ participacaoSocietaria.tipo }}</td>
                  <!-- Certifique-se de substituir 'nome', 'cnpj', 'tipo' pelos nomes corretos das propriedades -->
                </tr>
              </ng-container>
              <ng-template #noDataParticipacao>
                <tr>
                  <td colspan="3" class="text-center">Não há dados disponíveis</td>
                </tr>
              </ng-template>
            </tbody>
          </table>
        </mat-expansion-panel>
      </mat-accordion>
    
      <!--negativos-->
      <!-- <mat-accordion>
        <mat-expansion-panel class="mt-3 py-2" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <h3 class="mb-0 d-flex align-items-center">
                FONTES COM RESULTADOS NEGATIVOS
              </h3>
            </mat-panel-title>
    
          </mat-expansion-panel-header>
          <table class="table">
            <thead class="thead-light">
              <tr class="text-center">
                <th scope="col" class="thead-top-only p-2">Fontes</th>
              </tr>
            </thead>
            <tbody>
              <tr class="table-info text-center">
                <td> luana.mariah@hotmail.com </td>
              </tr>
              <tr class="table-info text-center">
                <td> Nenhum Resultado </td>
              </tr>
            </tbody>
          </table>
        </mat-expansion-panel>
      </mat-accordion>
    
      falhas
      <mat-accordion>
        <mat-expansion-panel class="mt-3 py-2" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <h3 class="mb-0 d-flex align-items-center">
                FONTES QUE APRESENTARAM FALHAS NA CONSULTA
              </h3>
            </mat-panel-title>
    
          </mat-expansion-panel-header>
          <table class="table">
            <thead class="thead-light">
              <tr class="text-center">
                <th scope="col" class="thead-top-only p-2">Fontes</th>
              </tr>
              <tr class="table-info text-center">
                <td> Nenhum Resultado </td>
              </tr>
            </thead>
            <tbody>
              <tr class="table-info text-center">
                <td> luana.mariah@hotmail.com </td>
              </tr>
            </tbody>
          </table>
        </mat-expansion-panel>
      </mat-accordion>
    
      comarcas falhas
      <mat-accordion>
        <mat-expansion-panel class="mt-3 py-2" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <h3 class="mb-0 d-flex align-items-center">
                FONTES QUE APRESENTARAM FALHAS DE COMARCAS NA CONSULTAS
              </h3>
            </mat-panel-title>
    
          </mat-expansion-panel-header>
          <table class="table">
            <thead class="thead-light">
              <tr class="text-center">
                <th scope="col" class="thead-top-left p-2">Fontes</th>
                <th scope="col" class="thead-top-right p-2">Comarcas</th>
              </tr>
            </thead>
            <tbody>
              <tr class="table-info text-center">
                <td> luana.mariah@hotmail.com </td>
                <td> luana.mariah@hotmail.com </td>
              </tr>
              <tr class="table-info text-center">
                <td> Nenhum Resultado </td>
                <td> Nenhum Resultado </td>
              </tr>
            </tbody>
          </table>
        </mat-expansion-panel>
      </mat-accordion> -->
    </div>
  </div>
  
  <div *ngIf="isNovaPesquisa">
    <div class="mt-5" *ngIf="isCpf">
      <mat-accordion>
        <mat-expansion-panel class="py-2" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <h3 class="mb-0 d-flex align-items-center">
                <mat-icon> phone</mat-icon> &nbsp; TELEFONES
              </h3>
            </mat-panel-title>
    
          </mat-expansion-panel-header>
          <table class="table">
            <thead class="thead-light">
              <tr class="text-center">
                <th scope="col" class="thead-top-left p-2">Telefone</th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngIf="objetoNovaPesquisa?.dadosCpf?.telefones.length > 0; else noDataTelef">
                <tr class="table-info text-center" *ngFor="let telefone of objetoNovaPesquisa?.dadosCpf?.telefones">
                  <td>{{ telefone }}</td>
                </tr>
              </ng-container>
              <ng-template #noDataTelef>
                <tr>
                  <td colspan="1" class="text-center">Não há telefones disponíveis</td>
                </tr>
              </ng-template>
            </tbody>
            
          </table>
        </mat-expansion-panel>
      </mat-accordion>
    
      <!--emails-->
      <mat-accordion>
        <mat-expansion-panel class="mt-3 py-2" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <h3 class="mb-0 d-flex align-items-center">
                <mat-icon> email</mat-icon> &nbsp;
                ENDEREÇOS ELETRÔNICO
              </h3>
            </mat-panel-title>
    
          </mat-expansion-panel-header>
          <table class="table">
            <thead class="thead-light">
              <tr class="text-center">
                <th scope="col" class="thead-top-only p-2">E-mail</th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngIf="objetoNovaPesquisa?.dadosCpf?.emails?.length > 0; else noDataMail">
                <tr class="table-info text-center" *ngFor="let email of objetoNovaPesquisa?.dadosCpf?.emails">
                  <td>{{ email }}</td>
                </tr>
              </ng-container>
              <ng-template #noDataMail>
                <tr>
                  <td colspan="1" class="text-center">Não há emails disponíveis</td>
                </tr>
              </ng-template>
            </tbody>
            
          </table>
        </mat-expansion-panel>
      </mat-accordion>
    
      <!--enderecos-->
      <mat-accordion>
        <mat-expansion-panel class="mt-3 py-2" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <h3 class="mb-0 d-flex align-items-center">
                <mat-icon>home</mat-icon>&nbsp;
                ENDEREÇOS
              </h3>
            </mat-panel-title>
    
          </mat-expansion-panel-header>
          <table class="table">
            <thead class="thead-light">
              <tr class="text-center">
                <th scope="col" class="thead-top-left p-2">Logradouro</th>
                <th scope="col" class="thead p-2">Numero</th>
                <th scope="col" class="thead p-2">Compl.</th>
                <th scope="col" class="thead p-2">Bairro</th>
                <th scope="col" class="thead p-2">Cidade</th>
                <th scope="col" class="thead p-2">UF</th>
                <th scope="col" class="thead-top-right p-2">CEP</th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngIf="objetoNovaPesquisa?.dadosCpf?.logradouro || objetoNovaPesquisa?.dadosCpf?.enderecos?.length > 0; else noDataEndere">
                <tr class="text-center table-info" *ngIf="objetoNovaPesquisa?.dadosCpf?.logradouro">
                  <td>{{ objetoNovaPesquisa?.dadosCpf?.logradouro }}</td>
                  <td>{{ objetoNovaPesquisa?.dadosCpf?.numero }}</td>
                  <td>{{ objetoNovaPesquisa?.dadosCpf?.complemento }}</td>
                  <td>{{ objetoNovaPesquisa?.dadosCpf?.bairro }}</td>
                  <td>{{ objetoNovaPesquisa?.dadosCpf?.cidade }}</td>
                  <td>{{ objetoNovaPesquisa?.dadosCpf?.uf }}</td>
                  <td>{{ objetoNovaPesquisa?.dadosCpf?.cep }}</td>
                </tr>
  
                <tr class="text-center table-info" *ngFor="let endereco of objetoNovaPesquisa?.dadosCpf?.enderecos">
                  <td>{{ endereco?.logradouro }}</td>
                  <td>{{ endereco?.numero }}</td>
                  <td>{{ endereco?.complemento }}</td>
                  <td>{{ endereco?.bairro }}</td>
                  <td>{{ endereco?.cidade }}</td>
                  <td>{{ endereco?.uf }}</td>
                  <td>{{ endereco?.cep }}</td>
                </tr>
              </ng-container>
              <ng-template #noDataEndere>
                <tr>
                  <td colspan="7" class="text-center">Não há endereços disponíveis</td>
                </tr>
              </ng-template>
            </tbody>
          </table>
        </mat-expansion-panel>
      </mat-accordion>
    
      <!-- <mat-accordion>
        <mat-expansion-panel class="mt-3 py-2" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <h3 class="mb-0 d-flex align-items-center">
                <mat-icon>place</mat-icon>&nbsp;
                LOCALIZAÇÃO
              </h3>
            </mat-panel-title>
    
          </mat-expansion-panel-header>
          <table class="table">
            <thead class="thead-light">
              <tr class="text-center">
                <th scope="col" class="thead-top-left p-2">Mapa</th>
                <th scope="col" class="thead-top-right p-2">Fachada</th>
              </tr>
            </thead>
            <tbody>
              <tr class="text-center table-info">
                <td class="col-6">
                  <iframe id="localizacao" width="100%" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"
                    src="https://www.google.com/maps/embed/v1/place?q=$emilio+Ribas+cambui+campinas&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8"
                    class="iframe-mapa"></iframe>
                </td>
                <td class="col-6">
                  <img
                    src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQK25fu0jhvg593G8sY9Dog94rpouNMWbU7ipxiGRK0B9KW9qCiT_tofSneyE2Ixs__1UE&usqp=CAU"
                    alt="">
                </td>
              </tr>
            </tbody>
          </table>
        </mat-expansion-panel>
      </mat-accordion> -->
    
    
      <!--pessoas vinculadas-->
      <mat-accordion>
        <mat-expansion-panel class="mt-3 py-2" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <h3 class="mb-0 d-flex align-items-center">
                <mat-icon>person</mat-icon>&nbsp;
                PESSOAS VINCULADAS
              </h3>
            </mat-panel-title>
    
          </mat-expansion-panel-header>
          <table class="table">
            <thead class="thead-light">
              <tr class="text-center">
                <th scope="col" class="thead-top-left p-2">Nome</th>
                <th scope="col" class="thead p-2">CPFCNPJ</th>
                <th scope="col" class="thead-top-right p-2">Vínculo</th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngIf="objetoNovaPesquisa?.dadosCpf?.pessoasRelacionadas?.length > 0; else noDataPessoasRe">
                <tr class="text-center table-info" *ngFor="let pessoasRelacionadas of objetoNovaPesquisa?.dadosCpf?.pessoasRelacionadas">
                  <td>{{ pessoasRelacionadas?.nome }}</td>
                  <td>{{ pessoasRelacionadas?.cpfCnpj }}</td>
                  <td>{{ pessoasRelacionadas?.relacao }}</td>
                </tr>
              </ng-container>
              <ng-template #noDataPessoasRe>
                <tr>
                  <td colspan="3" class="text-center">Não há pessoas relacionadas disponíveis</td>
                </tr>
              </ng-template>
            </tbody>
            
          </table>
        </mat-expansion-panel>
      </mat-accordion>
    
      <!--participacoes societarias-->
      <mat-accordion>
        <mat-expansion-panel class="mt-3 py-2" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <h3 class="mb-0 d-flex align-items-center">
                <mat-icon>work</mat-icon>&nbsp;
                PARTICIPAÇÕES SOCIETÁRIAS
              </h3>
            </mat-panel-title>
    
          </mat-expansion-panel-header>
          <table class="table">
            <thead class="thead-light">
              <tr class="text-center">
                <th scope="col" class="thead-top-left p-2">Nome</th>
                <th scope="col" class="thead p-2">CNPJ</th>
                <th scope="col" class="thead p-2">Data Entrada</th>
                <th scope="col" class="thead-top-right p-2">Qualificação</th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngIf="objetoNovaPesquisa?.dadosCpf?.participacaoEmpresas?.length > 0; else noDataParticipacaoSocietaria">
                <tr class="text-center table-info" *ngFor="let participacaoEmpresas of objetoNovaPesquisa?.dadosCpf?.participacaoEmpresas">
                  <td>{{ participacaoEmpresas?.nome }}</td>
                  <td>{{ participacaoEmpresas?.cpfCnpj }}</td>
                  <td>{{ participacaoEmpresas?.dataEntrada | date: 'dd/MM/yyyy' }}</td>
                  <td>{{ participacaoEmpresas?.qualificacao }}</td>
                </tr>
              </ng-container>
              <ng-template #noDataParticipacaoSocietaria>
                <tr>
                  <td colspan="4" class="text-center">Não há participações em empresas disponíveis</td>
                </tr>
              </ng-template>
            </tbody>
          </table>
        </mat-expansion-panel>
      </mat-accordion>
    
      <!--negativos-->
      <!-- <mat-accordion>
        <mat-expansion-panel class="mt-3 py-2" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <h3 class="mb-0 d-flex align-items-center">
                FONTES COM RESULTADOS NEGATIVOS
              </h3>
            </mat-panel-title>
    
          </mat-expansion-panel-header>
          <table class="table">
            <thead class="thead-light">
              <tr class="text-center">
                <th scope="col" class="thead-top-only p-2">Fontes</th>
              </tr>
            </thead>
            <tbody>
              <tr class="table-info text-center">
                <td> luana.mariah@hotmail.com </td>
              </tr>
              <tr class="table-info text-center">
                <td> Nenhum Resultado </td>
              </tr>
            </tbody>
          </table>
        </mat-expansion-panel>
      </mat-accordion>
    
      falhas
      <mat-accordion>
        <mat-expansion-panel class="mt-3 py-2" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <h3 class="mb-0 d-flex align-items-center">
                FONTES QUE APRESENTARAM FALHAS NA CONSULTA
              </h3>
            </mat-panel-title>
    
          </mat-expansion-panel-header>
          <table class="table">
            <thead class="thead-light">
              <tr class="text-center">
                <th scope="col" class="thead-top-only p-2">Fontes</th>
              </tr>
              <tr class="table-info text-center">
                <td> Nenhum Resultado </td>
              </tr>
            </thead>
            <tbody>
              <tr class="table-info text-center">
                <td> luana.mariah@hotmail.com </td>
              </tr>
            </tbody>
          </table>
        </mat-expansion-panel>
      </mat-accordion>
    
      comarcas falhas
      <mat-accordion>
        <mat-expansion-panel class="mt-3 py-2" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <h3 class="mb-0 d-flex align-items-center">
                FONTES QUE APRESENTARAM FALHAS DE COMARCAS NA CONSULTAS
              </h3>
            </mat-panel-title>
    
          </mat-expansion-panel-header>
          <table class="table">
            <thead class="thead-light">
              <tr class="text-center">
                <th scope="col" class="thead-top-left p-2">Fontes</th>
                <th scope="col" class="thead-top-right p-2">Comarcas</th>
              </tr>
            </thead>
            <tbody>
              <tr class="table-info text-center">
                <td> luana.mariah@hotmail.com </td>
                <td> luana.mariah@hotmail.com </td>
              </tr>
              <tr class="table-info text-center">
                <td> Nenhum Resultado </td>
                <td> Nenhum Resultado </td>
              </tr>
            </tbody>
          </table>
        </mat-expansion-panel>
      </mat-accordion> -->
    </div>
    
    <div class="mt-5" *ngIf="isCnpj">
      <!--Socios-->
      <mat-accordion>
        <mat-expansion-panel class="mt-3 py-2" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <h3 class="mb-0 d-flex align-items-center">
                <mat-icon>person</mat-icon>&nbsp;
                SOCIOS - RECEITA FEDERAL (QSA)
              </h3>
            </mat-panel-title>
    
          </mat-expansion-panel-header>
          <table class="table">
            <thead class="thead-light">
              <tr class="text-center">
                <th scope="col" class="thead-top-left p-2">Nome</th>
                <th scope="col" class="thead-top-right p-2">Qualificação</th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngIf="objetoNovaPesquisa?.dadosCnpj?.listaSocioRF?.length > 0; else noDataRF">
                <tr class="text-center table-info" *ngFor="let socioRF of objetoNovaPesquisa?.dadosCnpj?.listaSocioRF">
                  <td>{{ socioRF?.nome }}</td>
                  <td>{{ socioRF?.qualificacao }}</td>
                </tr>
              </ng-container>
              <ng-template #noDataRF>
                <tr>
                  <td colspan="2" class="text-center">Não há sócios disponíveis</td>
                </tr>
              </ng-template>
            </tbody>
          </table>
        </mat-expansion-panel>
      </mat-accordion>
    
      <mat-accordion>
        <mat-expansion-panel class="mt-3 py-2" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <h3 class="mb-0 d-flex align-items-center">
                <mat-icon>person</mat-icon>&nbsp;
                SOCIOS
              </h3>
            </mat-panel-title>
    
          </mat-expansion-panel-header>
          <table class="table">
            <thead class="thead-light">
              <tr class="text-center">
                <th scope="col" class="thead-top-left p-2">Nome</th>
                <th scope="col" class="thead p-2">CPF/CNPJ</th>
                <th scope="col" class="thead p-2">Data Entrada</th>
                <th scope="col" class="thead-top-right p-2">Qualificação</th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngIf="objetoNovaPesquisa?.dadosCnpj?.listaSocio?.length > 0; else noDataSocios">
                <tr class="text-center table-info" *ngFor="let socio of objetoNovaPesquisa?.dadosCnpj?.listaSocio">
                  <td>{{ socio?.nome }}</td>
                  <td>{{ socio?.cpfCnpj }}</td>
                  <td>{{ socio?.dataEntradaSociedade }}</td>
                  <td>{{ socio?.qualificacao }}</td>
                </tr>
              </ng-container>
              <ng-template #noDataSocios>
                <tr>
                  <td colspan="4" class="text-center">Não há sócios disponíveis</td>
                </tr>
              </ng-template>
            </tbody>        
          </table>
        </mat-expansion-panel>
      </mat-accordion>
    
      <!-- telefones -->
      <mat-accordion>
        <mat-expansion-panel class="mt-3 py-2" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <h3 class="mb-0 d-flex align-items-center">
                <mat-icon> phone</mat-icon> &nbsp; TELEFONES
              </h3>
            </mat-panel-title>
    
          </mat-expansion-panel-header>
          <table class="table">
            <thead class="thead-light">
              <tr class="text-center">
                <th scope="col" class="thead-top-only p-2">Telefone</th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngIf="objetoNovaPesquisa?.dadosCnpj?.telefones?.length > 0; else noDataTel">
                <tr class="table-info text-center" *ngFor="let telefone of objetoNovaPesquisa?.dadosCnpj?.telefones">
                  <td>{{ telefone }}</td>
                </tr>
              </ng-container>
              <ng-template #noDataTel>
                <tr>
                  <td colspan="1" class="text-center">Não há telefones disponíveis</td>
                </tr>
              </ng-template>
            </tbody>
          </table>
        </mat-expansion-panel>
      </mat-accordion>
    
      <!--enderecos-->
      <mat-accordion>
        <mat-expansion-panel class="mt-3 py-2" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <h3 class="mb-0 d-flex align-items-center">
                <mat-icon>home</mat-icon>&nbsp;
                ENDEREÇOS
              </h3>
            </mat-panel-title>
    
          </mat-expansion-panel-header>
          <table class="table">
            <thead class="thead-light">
              <tr class="text-center">
                <th scope="col" class="thead-top-left p-2">Logradouro</th>
                <th scope="col" class="thead p-2">Numero</th>
                <th scope="col" class="thead p-2">Compl.</th>
                <th scope="col" class="thead p-2">Bairro</th>
                <th scope="col" class="thead p-2">Cidade</th>
                <th scope="col" class="thead p-2">UF</th>
                <th scope="col" class="thead-top-right p-2">CEP</th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngIf="objetoNovaPesquisa?.dadosCnpj?.logradouro || objetoNovaPesquisa?.dadosCnpj?.enderecos?.length > 0; else noDataEnde">
                <tr class="text-center table-info" *ngIf="objetoNovaPesquisa?.dadosCnpj?.logradouro">
                  <td>{{ objetoNovaPesquisa?.dadosCnpj?.logradouro }}</td>
                  <td>{{ objetoNovaPesquisa?.dadosCnpj?.numero }}</td>
                  <td>{{ objetoNovaPesquisa?.dadosCnpj?.complemento }}</td>
                  <td>{{ objetoNovaPesquisa?.dadosCnpj?.bairro }}</td>
                  <td>{{ objetoNovaPesquisa?.dadosCnpj?.cidade }}</td>
                  <td>{{ objetoNovaPesquisa?.dadosCnpj?.uf }}</td>
                  <td>{{ objetoNovaPesquisa?.dadosCnpj?.cep }}</td>
                </tr>
                <tr class="text-center table-info" *ngFor="let endereco of objetoNovaPesquisa?.dadosCnpj?.enderecos">
                  <td>{{ endereco?.logradouro }}</td>
                  <td>{{ endereco?.numero }}</td>
                  <td>{{ endereco?.complemento }}</td>
                  <td>{{ endereco?.bairro }}</td>
                  <td>{{ endereco?.cidade }}</td>
                  <td>{{ endereco?.uf }}</td>
                  <td>{{ endereco?.cep }}</td>
                </tr>
              </ng-container>
              <ng-template #noDataEnde>
                <tr>
                  <td colspan="7" class="text-center">Não há endereços disponíveis</td>
                </tr>
              </ng-template>
            </tbody>
          </table>
        </mat-expansion-panel>
      </mat-accordion>
    
      <!--emails-->
      <mat-accordion>
        <mat-expansion-panel class="mt-3 py-2" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <h3 class="mb-0 d-flex align-items-center">
                <mat-icon> email</mat-icon> &nbsp;
                ENDEREÇOS ELETRÔNICO
              </h3>
            </mat-panel-title>
    
          </mat-expansion-panel-header>
          <table class="table">
            <thead class="thead-light">
              <tr class="text-center">
                <th scope="col" class="thead-top-only p-2">E-mail</th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngIf="objetoNovaPesquisa?.dadosCnpj?.emails?.length > 0; else noDataEmail">
                <tr class="table-info text-center" *ngFor="let email of objetoNovaPesquisa?.dadosCnpj?.emails">
                  <td>{{ email }}</td>
                </tr>
              </ng-container>
              <ng-template #noDataEmail>
                <tr>
                  <td colspan="1" class="text-center">Não há e-mails disponíveis</td>
                </tr>
              </ng-template>
            </tbody>        
          </table>
        </mat-expansion-panel>
      </mat-accordion>
    
      <!-- <mat-accordion>
        <mat-expansion-panel class="mt-3 py-2" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <h3 class="mb-0 d-flex align-items-center">
                <mat-icon>place</mat-icon>&nbsp;
                LOCALIZAÇÃO
              </h3>
            </mat-panel-title>
    
          </mat-expansion-panel-header>
          <table class="table">
            <thead class="thead-light">
              <tr class="text-center">
                <th scope="col" class="thead-top-left p-2">Mapa</th>
                <th scope="col" class="thead-top-right p-2">Fachada</th>
              </tr>
            </thead>
            <tbody>
              <tr class="text-center table-info">
                <td class="col-6">
                  <iframe id="localizacao"
                  width="100%" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://www.google.com/maps/embed/v1/place?q=$emilio+Ribas+cambui+campinas&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8"
                  class="iframe-mapa"></iframe>
                </td>
                <td class="col-6">
                  <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQK25fu0jhvg593G8sY9Dog94rpouNMWbU7ipxiGRK0B9KW9qCiT_tofSneyE2Ixs__1UE&usqp=CAU" alt="">
                </td>
              </tr>
            </tbody>
          </table>
        </mat-expansion-panel>
      </mat-accordion> -->
    
      <!--participacoes societarias-->
      <mat-accordion>
        <mat-expansion-panel class="mt-3 py-2" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <h3 class="mb-0 d-flex align-items-center">
                <mat-icon>work</mat-icon>&nbsp;
                PARTICIPAÇÕES SOCIETÁRIAS
              </h3>
            </mat-panel-title>
    
          </mat-expansion-panel-header>
          <table class="table">
            <thead class="thead-light">
              <tr class="text-center">
                <th scope="col" class="thead-top-left p-2">Nome</th>
                <th scope="col" class="thead p-2">CNPJ</th>
                <!-- <th scope="col" class="thead p-2">Data Entrada</th> -->
                <th scope="col" class="thead-top-right p-2">Qualificação</th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngIf="objetoNovaPesquisa?.dadosCnpj?.listaParticipacaoSocietaria?.length > 0; else noDataParticipacao">
                <tr class="text-center table-info" *ngFor="let participacaoSocietaria of objetoNovaPesquisa?.dadosCnpj?.listaParticipacaoSocietaria">
                  <td>{{ participacaoSocietaria.nome }}</td>
                  <td>{{ participacaoSocietaria.cnpj }}</td>
                  <td>{{ participacaoSocietaria.tipo }}</td>
                  <!-- Certifique-se de substituir 'nome', 'cnpj', 'tipo' pelos nomes corretos das propriedades -->
                </tr>
              </ng-container>
              <ng-template #noDataParticipacao>
                <tr>
                  <td colspan="3" class="text-center">Não há dados disponíveis</td>
                </tr>
              </ng-template>
            </tbody>
          </table>
        </mat-expansion-panel>
      </mat-accordion>
    
      <!--negativos-->
      <!-- <mat-accordion>
        <mat-expansion-panel class="mt-3 py-2" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <h3 class="mb-0 d-flex align-items-center">
                FONTES COM RESULTADOS NEGATIVOS
              </h3>
            </mat-panel-title>
    
          </mat-expansion-panel-header>
          <table class="table">
            <thead class="thead-light">
              <tr class="text-center">
                <th scope="col" class="thead-top-only p-2">Fontes</th>
              </tr>
            </thead>
            <tbody>
              <tr class="table-info text-center">
                <td> luana.mariah@hotmail.com </td>
              </tr>
              <tr class="table-info text-center">
                <td> Nenhum Resultado </td>
              </tr>
            </tbody>
          </table>
        </mat-expansion-panel>
      </mat-accordion>
    
      falhas
      <mat-accordion>
        <mat-expansion-panel class="mt-3 py-2" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <h3 class="mb-0 d-flex align-items-center">
                FONTES QUE APRESENTARAM FALHAS NA CONSULTA
              </h3>
            </mat-panel-title>
    
          </mat-expansion-panel-header>
          <table class="table">
            <thead class="thead-light">
              <tr class="text-center">
                <th scope="col" class="thead-top-only p-2">Fontes</th>
              </tr>
              <tr class="table-info text-center">
                <td> Nenhum Resultado </td>
              </tr>
            </thead>
            <tbody>
              <tr class="table-info text-center">
                <td> luana.mariah@hotmail.com </td>
              </tr>
            </tbody>
          </table>
        </mat-expansion-panel>
      </mat-accordion>
    
      comarcas falhas
      <mat-accordion>
        <mat-expansion-panel class="mt-3 py-2" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <h3 class="mb-0 d-flex align-items-center">
                FONTES QUE APRESENTARAM FALHAS DE COMARCAS NA CONSULTAS
              </h3>
            </mat-panel-title>
    
          </mat-expansion-panel-header>
          <table class="table">
            <thead class="thead-light">
              <tr class="text-center">
                <th scope="col" class="thead-top-left p-2">Fontes</th>
                <th scope="col" class="thead-top-right p-2">Comarcas</th>
              </tr>
            </thead>
            <tbody>
              <tr class="table-info text-center">
                <td> luana.mariah@hotmail.com </td>
                <td> luana.mariah@hotmail.com </td>
              </tr>
              <tr class="table-info text-center">
                <td> Nenhum Resultado </td>
                <td> Nenhum Resultado </td>
              </tr>
            </tbody>
          </table>
        </mat-expansion-panel>
      </mat-accordion> -->
    </div>
  </div>
  