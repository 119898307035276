import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Data, Router } from '@angular/router';

@Component({
  selector: 'app-modal-valores-pesquisa',
  templateUrl: './modal-valores-pesquisa.component.html',
  styleUrls: ['./modal-valores-pesquisa.component.scss']
})
export class ModalValoresPesquisaComponent implements OnInit {

  valorTotalParametros;
  produto;
  isLote = false;

  parametrosAvulsos = [];

  constructor(
    private route: Router,
    public dialogRef: MatDialogRef<ModalValoresPesquisaComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Data,
  ) { }

  ngOnInit(): void {
    console.log(this.data)
    if (this.route.url == '/dossie') {
      this.produto = 'Dossiê Completo'
    }
    if (this.route.url == '/credito') {
      this.produto = 'Analise de Crédito'
    }
    if (this.route.url == '/cadastrais') {
      this.produto = 'Cadastrais'
    }
    if (this.route.url == '/patrimonial') {
      this.produto = 'Patrimonial'
    }

    if(this.data.tipoPesquisa == 1){
      this.isLote = true;
      // this.verificaSeTemMGLote();
      this.verificaSeTemParametrosLote()
    }
    if(!this.data.tipoPesquisa){
      this.verificaSeTemMG();
      this.verificaSeTemParametros()
    }
  }

  verificaSeTemMG(){
    const fonteMG = this.data.dados.fontesAdicionaisSelecionadas.find(x => x.fonte === 218);

    if(fonteMG){
      const novoParametro = {
        mensagem: fonteMG.descricao,
        valor: fonteMG.valor,
        fonte: fonteMG.fonte
      };
      this.data.dados.fontesAdicionaisSelecionadas = this.data.dados.fontesAdicionaisSelecionadas.filter(adicional => adicional.fonte !== 218);
      // this.data.dados.parametrosSelecionados.push(novoParametro);
    }
  }

  verificaSeTemParametros(){
    const faturamento = this.data.dados.parametrosSelecionados.find(x => x.idParametro === "FATURAMENTO");
    const segundoNivel = this.data.dados.parametrosSelecionados.find(x => x.idParametro === "NIVEL2");
    if(faturamento){
      this.data.dados.parametrosSelecionados = this.data.dados.parametrosSelecionados.filter(x => x.idParametro !== 'FATURAMENTO');
      this.parametrosAvulsos.push(faturamento);
    }
    if(segundoNivel){
      this.data.dados.parametrosSelecionados = this.data.dados.parametrosSelecionados.filter(x => x.idParametro !== 'NIVEL2');
      this.parametrosAvulsos.push(segundoNivel);
    }
  }

  exibirMensagemQualificada(): boolean {
    return this.data?.adicionais?.fontesAdicionais.includes(218);
  }

  exibirMensagemQualificadaLote(): boolean {
    return this.data?.somaValores?.fontesAdicionaisSelecionadas.map(x => x.fonte).includes(218);
  }

  verificaSeTemParametrosLote(){
    const faturamento = this.data.somaValores.parametrosSelecionados.find(x => x.idParametro === "FATURAMENTO");
    const segundoNivel = this.data.somaValores.parametrosSelecionados.find(x => x.idParametro === "NIVEL2");
    if(faturamento){
      this.data.somaValores.parametrosSelecionados = this.data.somaValores.parametrosSelecionados.filter(x => x.idParametro !== 'FATURAMENTO');
      this.parametrosAvulsos.push(faturamento);
    }
    if(segundoNivel){
      this.data.somaValores.parametrosSelecionados = this.data.somaValores.parametrosSelecionados.filter(x => x.idParametro !== 'NIVEL2');
      this.parametrosAvulsos.push(segundoNivel);
    }
  }

  verificaSeTemMGLote(){
    const fonteMG = this.data.somaValores.fontesAdicionaisSelecionadas.find(x => x.fonte === 218);

    if(fonteMG){
      const novoParametro = {
        mensagem: fonteMG.descricao,
        valor: fonteMG.valor,
        fonte: fonteMG.fonte
      };
      this.data.somaValores.fontesAdicionaisSelecionadas = this.data.somaValores.fontesAdicionaisSelecionadas.filter(adicional => adicional.fonte !== 218);
      // this.data.dados.parametrosSelecionados.push(novoParametro);
    }
  }

  // calculaTotalParametros(dados){
  //   let valores = dados.map(x => x.selecionados)
  //   this.valorTotalParametros = valores.reduce((acc, curr) => acc + curr, 0);
  // }

  realizarPesquisa(): void {
    this.dialogRef.close('realizarPesquisa');
  }

  fecharModal(): void {
    this.dialogRef.close();
  }

}

