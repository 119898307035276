import { Component, OnInit } from '@angular/core';
import { NotificacoesService } from 'src/app/shared/services/notificacoes/notificacoes.service';
import { UiService } from 'src/app/shared/services/ui.service';
import { MensageErrorResponseComponent } from '../../mensage-error-response/mensage-error-response.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MensageResponseComponent } from '../../mensage-response/mensage-response.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-cadastrar-notificacao',
  templateUrl: './cadastrar-notificacao.component.html',
  styleUrls: ['./cadastrar-notificacao.component.scss']
})
export class CadastrarNotificacaoComponent implements OnInit {

  formNotificacao: any = {};
  horaLocal: string;
  private subscription: Subscription;
  
  constructor(
    private ui: UiService,
    private _snackBar: MatSnackBar,
    private notificacoesService: NotificacoesService
  ) { }

  ngOnInit(): void {
    this.atualizarHora();
    setInterval(() => this.atualizarHora(), 1000);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  atualizarHora() {
    const dataAtual = new Date();
    const horas = dataAtual.getHours().toString().padStart(2, '0');
    const minutos = dataAtual.getMinutes().toString().padStart(2, '0');
    const segundos = dataAtual.getSeconds().toString().padStart(2, '0');
    this.horaLocal = `${horas}:${minutos}:${segundos}`;
  }

  enviarNotificacao() {
    this.ui.loading();
    const obj = {
      IdUsuario: 0,
      IdCliente: 0,
      Titulo: this.formNotificacao.titulo,
      Mensagem: this.formNotificacao.mensagem,
      Lido: false,
      DataHora: this.horaLocal
    }
    this.notificacoesService.cadastrarNotificacao(obj).subscribe(res => {
      this.ui.loaded().subscribe(() => {
        this.openSnackBar('Sucesso', 'Notificacao criada com sucesso');
        this.notificacoesService.callFunction();
      });
    }, (err: any) => {
      this.ui.loaded().subscribe(() => {
        this.openSnackBarWarning('Atenção', err.error);
      })
    });
  }

  openSnackBar(titulo: string, mensagem: string) {
    this._snackBar.openFromComponent(MensageResponseComponent, {
      data: {
        titulo: titulo,
        mensagem: mensagem,
      },
      duration: 5000,
      horizontalPosition: 'right',
      verticalPosition: 'top'
    });
  }

  openSnackBarWarning(titulo: string, mensagem: string) {
    this._snackBar.openFromComponent(MensageErrorResponseComponent, {
      data: {
        titulo: titulo,
        mensagem: mensagem,
      },
      duration: 5000,
      horizontalPosition: 'right',
      verticalPosition: 'top'
    });
  }

}
