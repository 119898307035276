import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ToastrService } from 'ngx-toastr';
import { FonteGrupoService } from 'src/app/shared/services/http/fonte-grupo.service';
import { UtilService } from 'src/app/shared/services/http/util.service';
import { UiService } from 'src/app/shared/services/ui.service';
import { ModalGrupoPesquisaComponent } from '../teia-relacionamento/menu-teia-relacionamento/acoes-menu/modal-grupo-pesquisa/modal-grupo-pesquisa.component';
import { Textos } from 'src/app/shared/textos';
import { ProdutosEnum } from 'src/app/shared/enums/produtos.enum';
import { HttpStatusCode } from '@angular/common/http';
import { MensageResponseComponent } from 'src/app/components/view/mensage-response/mensage-response.component';
import { PesquisaService } from 'src/app/shared/services/http/pesquisa.service';
import { MatDialog } from '@angular/material/dialog';
import { CheckboxControlValueAccessor } from '@angular/forms';
import { LocalizadorService } from 'src/app/shared/services/localizador/localizador.service';
import { NavService } from 'src/app/components/template/nav/nav.service';
import { ElementRef } from '@angular/core';
import { HeaderService } from 'src/app/components/template/header/header.service';
import { parse } from 'json5';
import { ModalConfirmacaoComponent } from 'src/app/components/view/modal-confirmacao/modal-confirmacao.component';
import { MatTabGroup } from '@angular/material/tabs';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/shared/services/http/auth.service';

interface Aba {
  titulo: string;
  conteudo: any; // Conteúdo específico da aba
}

@Component({
  selector: 'app-encontrar-doc',
  templateUrl: './encontrar-doc.component.html',
  styleUrls: ['./encontrar-doc.component.scss'],
})
export class EncontrarDocComponent implements OnInit {
  formPesquisa: any = {
    tipoParametro: 'nome'
  };
  formFiltro: any = {};
  formPesquisaInterna: any = {};
  paramAtivos;
  nome;
  isPerson = true;
  isCompany = false;
  retornoInfo = [];
  resultadoAdicionais = [];
  avisoObrigatorio = false;
  nomeIncorreto = false;
  dados;
  tipoPesquisa =
    [
      {
        nome: 'Informacoes Cadastrais',
        id: 1
      },
      {
        nome: 'Processos',
        id: 2
      },
      {
        nome: 'Patrimonial',
        id: 3
      },
      {
        nome: 'Analise de Credito',
        id: 4
      },
    ]

  abas: Aba[] = [];
  selectedIndex = 0;
  @ViewChild(MatTabGroup) tabGroup: MatTabGroup;
  tabIndexes: number[] = [];
  usuario;

  constructor(
    public dialog: MatDialog,
    private ui: UiService,
    private toastr: ToastrService,
    private utilService: UtilService,
    private _snackBar: MatSnackBar,
    private fonteGrupoService: FonteGrupoService,
    private pesquisaService: PesquisaService,
    private route: ActivatedRoute,
    private authService: AuthService,
    private navService: NavService,
    private headerService: HeaderService,
    private localizadorService: LocalizadorService,
  ) {
    navService.navData = { title: 'Localizador' }
    headerService.headerData = { title: 'Localizador' }
  }

  ngOnInit(): void {
    this.getUsuario();
    this.iniciaFormulario();

    let dadosAdicionais = 'teste'
    this.abas = this.localizadorService.getTabState(this.usuario.id);
    if(this.abas.map(x => x.conteudo.length > 0)){
      let dados = this.abas.map(x => x.conteudo);
      this.retornoInfo = dados.find(x => x.dados).dados;
      console.log(this.retornoInfo)
    }
  }

  getUsuario(){
    this.authService.callbackUsuario().subscribe(usuario => {
      if(!usuario) {
        return;
      }
      this.usuario = usuario;
      console.log(usuario)
    });
  }

  changeTipoParametro(value) {
    if (value == 'nome') {
      this.formPesquisa.tipoParametro = 'nome';
    }

    if (value == 'email') {
      this.formPesquisa.tipoParametro = 'email';
    }

    if (value == 'telefone') {
      this.formPesquisa.tipoParametro = 'telefone';
    }

    if (value == 'placa') {
      this.formPesquisa.tipoParametro = 'placa';
    }
  }

  changeColorTypePerson(value) {
    if (value == 'pessoas') {
      this.formPesquisa.tipoPessoa = 'pessoas';
      this.formPesquisa.nome = '';
    }
    if (value == 'empresas') {
      this.formPesquisa.tipoPessoa = 'empresas';
      this.formPesquisa.nome = '';
    }
  }

  verificarEnter(event: KeyboardEvent, value) {
    if (event.key === 'Enter') {
      if (this.isEmailValid() && value == 'email') {
        this.buscarPorEmail()
        event.preventDefault(); // Impede que a tecla Enter faça algo
      }
      if (this.isTelefoneValid() && value == 'telefone') {
        this.buscarPorTelefone()
        event.preventDefault(); // Impede que a tecla Enter faça algo
      }
      if (this.isPlacaValid() && value == 'placa') {
        this.openConfirmationDialogPlaca()
        event.preventDefault(); // Impede que a tecla Enter faça algo
      }
      else {
        event.preventDefault(); // Impede que a tecla Enter faça algo
      }
    }
  }

  iniciaFormulario() {
    this.formPesquisa.tipoPessoa = 'pessoas';
    this.formPesquisa.tipoParametro = 'nome';
    this.formPesquisa.cepLogradouro = '';
    this.formPesquisa.complemento = '';
    this.formPesquisa.bairro = '';
    this.formPesquisa.uf = '';
    this.formPesquisa.cidade = '';
    this.formPesquisa.nomeExato = false
  }

  transformarMaiusculas() {
    this.formPesquisa.placa = this.formPesquisa.placa.toUpperCase();
  }

  filtrarCaracteresEspeciais(event: any) {
    const pattern = /^[a-zA-Z0-9 ]*$/; // Permite letras, números e espaços
    const inputChar = String.fromCharCode(event.charCode);

    if (!pattern.test(inputChar)) {
      event.preventDefault(); // Bloqueia a entrada do caractere
    }
  }

  buscarInformacoes(value) {
    if (this.formPesquisa.nome == '' || this.formPesquisa.nome == null) {
      this.avisoObrigatorio = true;
      return
    }
    if (value == 'nome') {
      this.buscarPorNome()
    }
  }

  buscarPorNome() {
    const partesNome = this.formPesquisa.nome.split(' ');
    if (partesNome.length < 2) {
      this.nomeIncorreto = true
      setTimeout(() => {
        this.nomeIncorreto = false
      }, 4000);
      return
    }
    this.ui.loading();
    this.retornoInfo = [];


    const obj = {
      buscarPor: this.formPesquisa.tipoPessoa,
      nome: this.formPesquisa.nome,
      nomeExato: this.formPesquisa.nomeExato,
      cepLogradouro: this.formPesquisa.cepLogradouro,
      complemento: this.formPesquisa.complemento,
      bairro: this.formPesquisa.bairro,
      cidade: this.formPesquisa.cidade,
      uf: this.formPesquisa.uf.toUpperCase()
    }

    if (obj.buscarPor == 'pessoas') {
      this.isPerson = true;
      this.isCompany = false
    }
    else {
      this.isPerson = false;
      this.isCompany = true
    }
    this.localizadorService.buscarPorNome(obj, 'localizador').subscribe(data => {
      this.ui.loaded().subscribe(() => {
        this.retornoInfo = data.dados;
        this.adicionarAba(obj.nome, this.retornoInfo)
        if (data.dados.length == 0) {
          this.toastr.warning('Ocorreu um erro ao realizar a pesquisa, tente novamente.')
        }
      });
    }, (err: any) => {
      this.ui.loaded().subscribe(() => {
        if (err.error) {
          if (err.error.descricao) {
            this.toastr.warning(err.error.descricao)
          }
          else {
            this.toastr.warning(err.error);
          }
        }
        else {
          this.toastr.warning('Ocorreu um erro ao realizar a pesquisa, tente novamente.')
        }
      })
    });
  }

  isEmailValid(): boolean {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailPattern.test(this.formPesquisa.email);
  }

  isTelefoneValid(): boolean {
    const telefonePattern = /^[0-9]{10,11}$/; // Padrão para números de telefone no Brasil
    return telefonePattern.test(this.formPesquisa.telefone);
  }

  isPlacaValid(): boolean {
    const placaNovoFormatoRegex = /^[A-Z]{3}[0-9]{1}[A-Z]{1}[0-9]{2}$/;
    const placaAntigoFormatoRegex = /^[A-Z]{3}[0-9]{4}$/;
    const placa = this.formPesquisa.placa;
    return placaNovoFormatoRegex.test(placa) || placaAntigoFormatoRegex.test(placa);
  }

  buscarPorPlaca() {
    this.ui.loading();
    this.retornoInfo = [];
    const obj = {
      buscarPor: '',
      placa: this.formPesquisa.placa,
    }
    this.localizadorService.buscarPorPlaca(obj, 'localizador').subscribe(data => {
      this.ui.loaded().subscribe(() => {
        this.retornoInfo = data.dados;
        let adicionais = data.dados[0].resultadoAdicionais
        this.resultadoAdicionais = JSON.parse(adicionais)
        this.retornoInfo[0].resultadoAdicionais = this.resultadoAdicionais;
        console.log(this.retornoInfo)
        this.adicionarAba(obj.placa, this.retornoInfo);
        if (data.dados.length == 0) {
          this.toastr.warning('Ocorreu um erro ao realizar a pesquisa, tente novamente.')
        }
      });
    }, (err: any) => {
      this.ui.loaded().subscribe(() => {
        if (err.error) {
          if (err.error.descricao) {
            this.toastr.warning(err.error.descricao)
          }
          else {
            this.toastr.warning(err.error);
          }
        }
        else {
          this.toastr.warning('Ocorreu um erro ao realizar a pesquisa, tente novamente.')
        }
      })
    });
  }

  openConfirmationDialogPlaca(): void {
    const dados = {
      title: '',
      message: 'A pesquisa por placa vai consumir 10 reais dos seus créditos. Deseja continuar?',
      cancelText: 'Cancelar',
      confirmText: 'Continuar'
    }
    const dialogRef = this.dialog.open(ModalConfirmacaoComponent, {
      width: '500px',
      data: dados
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.buscarPorPlaca()
      }
    });
  }

  buscarPorEmail() {
    this.ui.loading();
    this.retornoInfo = [];
    const obj = {
      buscarPor: '',
      email: this.formPesquisa.email,
    }
    this.localizadorService.buscarPorEmail(obj, 'localizador').subscribe(data => {
      this.ui.loaded().subscribe(() => {
        this.retornoInfo = data.dados;
        this.adicionarAba(obj.email, this.retornoInfo);
        if (data.dados.length == 0) {
          this.toastr.warning('Ocorreu um erro ao realizar a pesquisa, tente novamente.')
        }
      });
    }, (err: any) => {
      this.ui.loaded().subscribe(() => {
        if (err.error) {
          if (err.error.descricao) {
            this.toastr.warning(err.error.descricao)
          }
          else {
            this.toastr.warning(err.error);
          }
        }
        else {
          this.toastr.warning('Ocorreu um erro ao realizar a pesquisa, tente novamente.')
        }
      })
    });
  }

  buscarPorTelefone() {
    this.ui.loading();
    this.retornoInfo = [];
    const obj = {
      buscarPor: '',
      telefone: this.formPesquisa.telefone,
    }
    this.localizadorService.buscarPorTelefone(obj, 'localizador').subscribe(data => {
      this.ui.loaded().subscribe(() => {
        this.retornoInfo = data.dados;
        this.adicionarAba(obj.telefone, this.retornoInfo);
        if (data.dados.length == 0) {
          this.toastr.warning('Ocorreu um erro ao realizar a pesquisa, tente novamente.')
        }
      });
    }, (err: any) => {
      this.ui.loaded().subscribe(() => {
        if (err.error) {
          if (err.error.descricao) {
            this.toastr.warning(err.error.descricao)
          }
          else {
            this.toastr.warning(err.error);
          }
        }
        else {
          this.toastr.warning('Ocorreu um erro ao realizar a pesquisa, tente novamente.')
        }
      })
    });
  }


  openGrupoPesquisa(grupos, produtoEnum, doc) {
    const dialog = this.dialog.open(ModalGrupoPesquisaComponent, {
      width: '280px',
      data: {
        idProduto: produtoEnum,
        grupos: grupos,
        doc: doc,
        header: "localizador"
      }
    });
  }

  verificaSeTemProdutoVinculado(produtoEnum, doc) {
    console.log(produtoEnum)
    this.fonteGrupoService.listAtivoProduto(produtoEnum).subscribe(response => {
      this.paramAtivos = response
      console.log(this.paramAtivos.length)
      if (this.paramAtivos.length == 1) {
        this.realizarPesquisa(produtoEnum, this.paramAtivos[0].fonteGrupo.id, doc,)
      }
      if (this.paramAtivos.length > 1) {
        this.openGrupoPesquisa(response, produtoEnum, doc)
      }
      else {
        this.toastr.warning('Usuário sem vinculos de pesquisa, entre em contato com o suporte', 'CredValue')
      }
    }, (err: any) => {
      this.ui.loaded().subscribe(() => {
        return
      })
    });
  }

  filtrar() {
    this.retornoInfo = this.retornoInfo.filter(item => {
      return item.nomeMaeSocio.includes(this.formFiltro.nomeMaeSocio) &&
        item.cidade.includes(this.formFiltro.cidade) &&
        item.uf.includes(this.formFiltro.uf) &&
        (this.formFiltro.dataNascAbertura === '' || item.dataNascAbertura === this.formFiltro.dataNascAbertura);
    });
  }

  limparFiltro() {
    this.formFiltro.nomeMaeSocio = '';
    this.formFiltro.cidade = '';
    this.formFiltro.uf = '';
    this.formFiltro.dataNascAbertura = ''
  }

  realizarPesquisa(tipoPesquisa, idPesquisa: number, documento) {
    this.ui.loading()
    var documento = documento
    // verifica o tipo do documento 
    let tipoDocumento = documento.replaceAll('.', '').replaceAll('-', '').replaceAll('/', '');
    if (tipoDocumento.length == 11) {
      tipoDocumento = 1
    }
    if (tipoDocumento.length == 14) {
      tipoDocumento = 2
    }

    const request = {
      nome: '',
      notificacao: true,
      numero: documento.replaceAll('.', '').replaceAll('-', '').replaceAll('/', ''),
      tipo: tipoDocumento
    }

    this.utilService.resolverNome(request, 'localizador').subscribe(resultadoResolverNome => {
      if (!resultadoResolverNome || !resultadoResolverNome.nome) {
        this.ui.loaded().subscribe(() => {
          this.nome = "";
          this.toastr.warning(Textos.NOME_NAO_ENCONTRADO, "Portal Credvalue")
        });
        return;
      }
      const requestPesquisa = {
        idFonteGrupo: idPesquisa,
        nome: resultadoResolverNome.nome,
        notificacao: true,
        numero: documento.replaceAll('.', '').replaceAll('-', '').replaceAll('/', ''),
        tipo: tipoDocumento
      }
      this.pesquisaService.cadastrarCliente(requestPesquisa).subscribe(data => {
        if (HttpStatusCode.Ok) {
          this.ui.loaded().subscribe(() => {
            if (tipoPesquisa == ProdutosEnum.Cadastrais) {
              this.openSnackBar('Sucesso', 'Pesquisa Cadastral Lançada com Sucesso');
            }
            if (tipoPesquisa == ProdutosEnum.Processo) {
              this.openSnackBar('Sucesso', 'Pesquisa de Processos Lançada com Sucesso');
            }
            if (tipoPesquisa == ProdutosEnum.Patrimonial) {
              this.openSnackBar('Sucesso', 'Pesquisa Patrimonial Lançada com Sucesso');
            }
            if (tipoPesquisa == ProdutosEnum.Credito) {
              this.openSnackBar('Sucesso', 'Pesquisa de Crédito Lançada com Sucesso');
            }
            if (tipoPesquisa == ProdutosEnum.Dossie) {
              this.openSnackBar('Sucesso', 'Pesquisa de Dossiê Completo Lançada com Sucesso');
            }
          })
        }
      });
    }, (err: any) => {
      this.ui.loaded().subscribe(() => {
        this.toastr.info('Ocorreu um erro ao realizar a pesquisa, tente novamente.')
      })
    });
  }

  openSnackBar(titulo: string, mensagem: string) {
    this._snackBar.openFromComponent(MensageResponseComponent, {
      data: {
        titulo: titulo,
        mensagem: mensagem,
      },
      duration: 5000,
      horizontalPosition: 'right',
      verticalPosition: 'top'
    });
  }

  adicionarAba(documento, dados) {
    const abaExistente = this.abas.find(aba => aba.titulo === documento);
    if (abaExistente) {
      if (this.selectedIndex !== this.abas.indexOf(abaExistente)) {
        // Aqui você pode decidir se deseja destacar a aba existente ou realizar outra ação.
      }
    } else {
      const conteudoAba = {
        cpfCnpj: documento,
        dados: dados
      };
      const novaAba: Aba = { titulo: `${documento}`, conteudo: { ...conteudoAba } }; // Usar o spread operator para criar uma cópia do objeto
      this.abas.push(novaAba);
      this.localizadorService.setTabState(this.usuario.id, this.abas);
      this.selectedIndex = this.abas.length - 1;
    }
  }
  
  fecharAba(index: number) {
    this.abas.splice(index, 1);
    this.localizadorService.setTabState(this.usuario.id, this.abas);
  }

  atualizarIndiceAbaSelecionada(event: any) {
    this.selectedIndex = this.tabGroup.selectedIndex;
  }
}

