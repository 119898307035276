import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthModule } from './@auth/auth.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule  } from '@angular/forms';
import { OverlayModule } from '@angular/cdk/overlay';
import { ToastrModule } from 'ngx-toastr';
import { NgxMaskModule, IConfig } from 'ngx-mask'

import localePt from '@angular/common/locales/pt';
import { registerLocaleData, DecimalPipe, DatePipe } from '@angular/common';
import { SharedModule } from './shared/shared.module';
import { ComponentsModule } from './components/components.module';
import { ViewsModule } from './views/views.module';
import { PrecificadorModule } from './views/precificador-credito/precificador-credto.module';
import { CurrencyMaskModule } from "ng2-currency-mask";
import { RouterModule } from '@angular/router';
registerLocaleData(localePt, 'pt-BR');

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    //shared modulos
    SharedModule, 
    //modulo componentes
    ComponentsModule,
    //modulo das telas
    PrecificadorModule,
    CurrencyMaskModule,
    ViewsModule,
    BrowserModule,
    AppRoutingModule,
    AuthModule.forRoot(),
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    OverlayModule,
    ReactiveFormsModule,
    ToastrModule.forRoot(), // ToastrModule added
    NgxMaskModule.forRoot(),
    RouterModule.forRoot([])
    
  ],
  providers: [{ provide: LOCALE_ID, useValue: 'pt-BR' },DecimalPipe, DatePipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
