import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class UiService {

    constructor(private spinner: NgxSpinnerService) { }

    success(message: string, life: number = 3000) {
        //this.toastrService.success(`${message}`, 'Sucesso');
        // this.messageService.add( { severity:'success', summary:'Sucesso', detail: message });
    }

    warning(message: string) {
        //this.toastrService.warning(`${message}`, 'Atenção');
        // this.messageService.add({ severity: 'warn', summary: 'Aviso', detail: message });
    }

    info(message: string) {
        //this.toastrService.info(`${message}`, 'Atenção');
        // this.messageService.add( { severity:'info', summary:'Informação', detail: message });
    }

    error(message: string, sticky: boolean = true) {
        //this.toastrService.danger(`${message}`, 'Atenção');
        // this.messageService.add( { severity:'error', summary:'Erro', detail: message, sticky: sticky, life: 5000 });
    }

    confirm(message: string, confirmCallback:any) {
        let dialog:any;

        let config = {
            titulo: "Confirmação",
            mensagem: message,
            simCallback: () => { 
                if(dialog) {
                    dialog.close();
                }
                confirmCallback();                
            },
            naoCallback: () => { 
                if(dialog) {
                    dialog.close();
                }
            }
        };
        //dialog = this.dialogService.open(ConfirmacaoDialogComponent, { context: config });
    }

    loading() {
        this.spinner.show();
    }

    loaded(message?:any) {
        return Observable.create((observer: { next: () => void; complete: () => void; }) => {
            setTimeout(() => {
                if (message) {
                    /*document.querySelector(".la-ball-fussion").classList.add("fade-out");

                    setTimeout(() => {
                        document.querySelector(".la-ball-fussion").textContent = null;
                        document.getElementById("spinnerMessage").textContent = message;
                        document.getElementById("spinnerMessage").classList.add("fade-in");
                    }, 800);

                    setTimeout(() => {
                        this.spinner.hide();
                        observer.next();
                        observer.complete();
                        document.getElementById("spinnerMessage").textContent = null;
                        document.getElementById("spinnerMessage").classList.remove("fade-in");
                    }, 3000);*/
                } else {
                    this.spinner.hide();
                    observer.next();
                    observer.complete();
                }
            }, 1500);
        });
    }

}
