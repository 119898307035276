<mat-accordion *ngIf="exibeDiarioOficial">
    <mat-expansion-panel class="mt-3 py-2" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <h3 class=" title-cordion mb-0 d-flex align-items-center">
            <mat-icon class="detech-icon">label_important</mat-icon>&nbsp;  Investigação por condições degradantes
          </h3>
        </mat-panel-title>
  
      </mat-expansion-panel-header>
      <table class="table" *ngIf="!isLoadingDiarioOficial && !exibeMensagemDiarioOficial">
        <thead class="thead-light">
          <tr class="text-center">
            <th scope="col" class="thead-top-left p-2">Titulo</th>
            <th scope="col" class="thead p-2">Data Publicação</th>
            <th scope="col" class="thead-top-right p-2">Descrição</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngIf="dataDiarioOficial.dados?.length > 0; else noDataDiarioOficial">
            <tr class="text-center table-info" *ngFor="let dados of dataDiarioOficial.dados">
              <td>{{dados?.title}}</td>
              <td>{{dados?.pubDate}}</td>
              <td>{{dados?.hierarchyStr}}</td>
            </tr>
          </ng-container>
          <ng-template #noDataDiarioOficial>
            <tr>
              <td colspan="3" class="text-center">Não há resultados disponíveis</td>
            </tr>
          </ng-template>
        </tbody>
      </table>
      <div class="d-flex justify-content-center text-center flex-column" *ngIf="!isLoadingDiarioOficial && exibeMensagemDiarioOficial">
        <div class="d-flex justify-content-end" *ngIf="exibeReprocessarDiarioOficial">
          <button mat-flat-button class="gs-botaoAzulPadrao m-3" (click)="getDiarioOficial(idPesquisa, 129)">Refresh <mat-icon>cached
          </mat-icon></button>
        </div>
        <div>
          <p>{{dataDiarioOficial.mensagem}}</p>
        </div>
      </div>
       <loader-only *ngIf="isLoadingDiarioOficial"></loader-only>
    </mat-expansion-panel>
  </mat-accordion>