<div class="header">
  <h2>Editar Imagem</h2>
</div>


<div class="cropper-container d-flex justify-content-center flex-column mt-5">
  <div class="image-container" #imageContainer (wheel)="onZoom($event)">
    <img [src]="imageSrc" *ngIf="imageSrc"  />
    <!-- <div
      class="crop-area"
      *ngIf="imageSrc"
      [ngStyle]="{
        width: cropWidth + 'px',
        height: cropHeight + 'px',
        left: cropX + 'px',
        top: cropY + 'px'
      }"
      (mousedown)="startDrag($event)"
    ></div> -->
  </div>
  <input type="file" (change)="onFileChange($event)" accept="image/*" />

  <!-- <img [src]="croppedImage" *ngIf="croppedImage" class="cropped-image" /> -->
</div>

<div class="buttons d-flex justify-content-end mt-2">
  <button mat-button (click)="closeDialog()">Cancelar</button>
  <button mat-raised-button class="gs-botaoAzulPadrao" (click)="saveImage()">Salvar</button>
</div>