import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Data } from '@angular/router';
import { TeiaRelacionamentoService } from 'src/app/shared/services/teia-relacionamento/teia-relacionamento.service';
import { UiService } from 'src/app/shared/services/ui.service';

const today = new Date();
const month = today.getMonth();
const year = today.getFullYear();

@Component({
  selector: 'app-modal-minhas-pesquisas',
  templateUrl: './modal-minhas-pesquisas.component.html',
  styleUrls: ['./modal-minhas-pesquisas.component.scss']
})
export class ModalMinhasPesquisasComponent implements OnInit {

  campaignOne = new FormGroup({
    start: new FormControl(new Date(year, month, 13)),
    end: new FormControl(new Date(year, month, 16)),
  });
  campaignTwo = new FormGroup({
    start: new FormControl(new Date(year, month, 15)),
    end: new FormControl(new Date(year, month, 19)),
  });
  formFiltro: any = { page: 1, pageSize: 50, perfil: ""};
  formFiltroRenderizado: any = this.formFiltro;
  resultadoPesquisa = [];
  
  constructor(
    private ui: UiService,
    private teiaRelacionamentoService: TeiaRelacionamentoService,
  ) { }

  ngOnInit(): void {
    this.pesquisarClicked()
  }

  onScroll() {
    let page = this.formFiltro.page+ 1;
    console.log('scrolled!!'+ page);
    this.pesquisarClicked(page);

  }

  onScrollDown() {
    console.log('scrolled down!!');
  }

  onScrollUp() {
    let page = this.formFiltro.page-1;
    if (page > 0) {
      this.pesquisarClicked(page);
    }
    console.log('scrolled up!!'+page);
  }

  pesquisarClicked(page = 1) {
    this.formFiltro.page = page;
    this.ui.loading();
    console.log(this.formFiltro);

    if(page == 1)
      this.resultadoPesquisa = [];

    let request = Object.assign({}, this.formFiltro);
    this.teiaRelacionamentoService.listarPesquisasTeia(request).subscribe(response => {
        this.formFiltroRenderizado = Object.assign({}, this.formFiltro);
        this.ui.loaded().subscribe(() => {
          this.resultadoPesquisa = response
          console.log(this.resultadoPesquisa)
        });
    });
  }

}
