import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    user;
    
    private baseUrl = environment.apiUrl + "/auth";

    private userData = new BehaviorSubject<any>(undefined);

    mostrarMenuEmitter = new EventEmitter<boolean>()

    constructor(public http: HttpClient) { }

    login(comando:any): Observable<any> {
        return this.http.post(`${this.baseUrl}/login-cliente`, comando);
    }

    getUsuarioAtual() {
        this.http.get(`${environment.apiUrl}/users/current`).subscribe(usuario => {
            this.userData.next(usuario);
            this.user = usuario
        });
    }

    callbackUsuario(): Observable<any> {
        return this.userData.asObservable();
    }

    atualizaInfoUserAtual(user:any): Observable<any>  {
        return this.http.post(`${this.baseUrl}/atualiza-usuario`, user);
    }
    getInfoUser(): Observable<any> {
        return this.http.get(`${environment.apiUrl}/users/current`);
    }
}
