<form class="pesq-form">
  <mat-form-field appearance="outline" class="input-pesq">
      <mat-label>Tipo de Documento</mat-label>
      <mat-select >
        <mat-option value="option1">CPF</mat-option>
        <mat-option value="option2">CNPJ</mat-option>
      </mat-select>
  </mat-form-field>
  <mat-form-field class="example-full-width input-pesq" appearance="outline">
      <mat-label>Digite o Numero do Documento</mat-label>
      <input matInput>
  </mat-form-field>
  <mat-form-field class="example-full-width input-pesq" appearance="outline">
      <mat-label>Enviar por E-mail?</mat-label>
      <input matInput>
  </mat-form-field>
  <button mat-raised-button color="primary" class="input-pesq-button">
      Nova Pesquisa
      <i class="material-icons search" >
          search
      </i>
  </button>
</form>
