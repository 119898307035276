import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-modal-no-customizado',
  templateUrl: './modal-no-customizado.component.html',
  styleUrls: ['./modal-no-customizado.component.scss'],
})
export class ModalNoCustomizadoComponent implements OnInit {
  @Output() valorSelecionado = new EventEmitter<{
    documento: string;
    nome: string;
    descricao: string;
  }>();

  form: FormGroup;

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<ModalNoCustomizadoComponent>
  ) {
    this.form = this.fb.group({
      tipoEscolha: ['cpf', Validators.required],
      documento: [''],
      nome: [''],
      descricao: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    this.form.get('tipoEscolha')?.valueChanges.subscribe((tipoEscolha) => {
      if (tipoEscolha === 'cpf') {
        this.form.get('documento')?.setValidators([Validators.required]);
        this.form.get('nome')?.clearValidators();
      } else if (tipoEscolha === 'nome') {
        this.form.get('nome')?.setValidators([Validators.required]);
        this.form.get('documento')?.clearValidators();
      }
      this.form.get('documento')?.updateValueAndValidity();
      this.form.get('nome')?.updateValueAndValidity();
    });
  }

  onSubmit(): void {
    if (this.form.valid) {
      const { documento, nome, descricao } = this.form.value;
      this.valorSelecionado.emit({ documento, nome, descricao });
      this.dialogRef.close();
    } else {
      this.form.markAllAsTouched();
    }
  }
}
