import { Component, ComponentFactoryResolver, EventEmitter, OnInit, Output, ViewChild, ViewContainerRef } from '@angular/core';
import { StepOneComponent } from './step-one/step-one.component';
import { StepTwoComponent } from './step-two/step-two.component';
import { StepThreeComponent } from './step-three/step-three.component';
import { StepFourComponent } from './step-four/step-four.component';
import { Subject } from 'rxjs';
import { PrecificadorService } from 'src/app/shared/services/precificador/precificador.service';
import { ToastrService } from 'ngx-toastr';
import { UiService } from 'src/app/shared/services/ui.service';
import { PrecificadorListagemComponent } from '../precificador-listagem/precificador-listagem.component';
import { PrecificadorCreditoComponent } from '../precificador-credito.component';

@Component({
  selector: 'app-precificador-wizard',
  templateUrl: './precificador-wizard.component.html',
  styleUrls: ['./precificador-wizard.component.scss']
})
export class PrecificadorWizardComponent implements OnInit {

  @ViewChild(PrecificadorListagemComponent) precificadorListagemComponent: PrecificadorListagemComponent;
  @ViewChild(StepOneComponent) stepOneComponent: StepOneComponent;
  @ViewChild(StepTwoComponent) StepTwoComponent: StepTwoComponent;
  @ViewChild(StepThreeComponent) StepThreeComponent: StepThreeComponent;
  @ViewChild(StepFourComponent) StepFourComponent: StepFourComponent;

  currentStep = 1;
  idPrecificador = 0;

  // precificadorIdPai = 0;

  constructor(
    private precificadorService: PrecificadorService,
    private toast: ToastrService,
    private ui: UiService,
    private resolver: ComponentFactoryResolver,
    private vcr: ViewContainerRef) { }

  ngOnInit(): void {
  }

  async nextStep() {
    if (this.currentStep < 5) {
      if (this.currentStep == 1) {
        await this.stepOneComponent.cadastrarCreditosAtualizados();

      }
      if (this.currentStep == 2) {
        this.StepTwoComponent.cadastrarAtivos();
      }
      if (this.currentStep == 3) {
        this.StepThreeComponent.cadastrarPassivos();
      }
      if (this.currentStep == 4) {
        this.StepFourComponent.cadastrarTempoRecuperacao();
      }
      this.currentStep++;
    }
  }

  async nextStepClickNumber(number) {
    if (number == 1) {
      await this.stepOneComponent.cadastrarCreditosAtualizados();

    }
    if (number == 2) {
      this.StepTwoComponent.cadastrarAtivos();
    }
    if (number == 3) {
      this.StepThreeComponent.cadastrarPassivos();
    }
    if (number == 4) {
      this.StepFourComponent.cadastrarTempoRecuperacao();
    }
    this.currentStep == number;
  }

  getValorAtualizado(){
    
    this.StepThreeComponent.cadastrarPassivosAtualizados()
  }


  prevStep() {
    if (this.currentStep > 1) {
      this.currentStep--;
    }
  }

  backToListagem() {
    location.reload()
  }

  visualizarRelatorio() {
    this.ui.loading();
    this.precificadorService.visualizarRelatorio(this.precificadorService.precificadorIdPaivalue).subscribe(data => {
      this.ui.loaded().subscribe(() => {
        // var a = document.createElement("a");
        // a.href = "data:application/pdf;base64," + data.arquivoConteudo;
        // a.download = data.arquivoNome;
        // a.click();
        // console.log(data)
        const newWindow = window.open('', '_blank');
          newWindow.document.write(data);
      })
    }, (err:any) => {
      this.ui.loaded().subscribe(() => {
        this.toast.info('Ocorreu um erro, tente novamente.')
      })
    });
  }

  gerarPdf(){
    this.ui.loading();
    this.precificadorService.gerarPDF(this.precificadorService.precificadorIdPaivalue).subscribe(data => {
      this.ui.loaded().subscribe(() => {
        var a = document.createElement("a");
        a.href = "data:application/pdf;base64," + data.arquivoConteudo;
        a.download = data.arquivoNome;
        a.click();
      })
    }, (err:any) => {
      this.ui.loaded().subscribe(() => {
        this.toast.info('Ocorreu um erro, tente novamente.')
      })
    });
  }

  async goToStep(step){
    this.currentStep = step

  }

}

