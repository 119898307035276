import { UiService } from '../shared/services/ui.service';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(private router: Router, private ui: UiService, private toastr: ToastrService,) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const token = localStorage.getItem('token');
        let requestFinal = req.clone();
        if (token) {
            requestFinal = req.clone({
                headers: req.headers.set('Authorization', `Bearer ${JSON.parse(token).access_token}`)
            });
        }

        return next.handle(requestFinal)
            .pipe(catchError((error: HttpErrorResponse) => {
                console.error(error);
                if (error.status === 401) {
                    this.router.navigate(['auth/login']);
                }

                if (error.status === 422) {                 
                    this.ui.loaded().subscribe(() => {                        
                        //this.toastrService.danger(`${error.error.message}`, 'Atenção');  
                        this.toastr.warning(`${error.error.message}`, "Portal Credvalue")                                                      
                    });                    
                    return EMPTY;
                }                
                                
                return throwError(error);
            }));
    }
}
