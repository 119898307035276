<mat-accordion *ngIf="exibeEscrituraEProcuracoes">
    <mat-expansion-panel class="mt-3 py-2" [expanded]="true" (opened)="panelOpenState = true"
        (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
            <mat-panel-title>
                <h3 class=" title-cordion mb-0 d-flex align-items-center">
                    <mat-icon class="detech-icon">location_city</mat-icon>&nbsp; Escritura e Procurações
                </h3>
            </mat-panel-title>

        </mat-expansion-panel-header>
        <table class="table" *ngIf="!isLoadingEscrituraEProcuracoes && !exibeMensagemEscrituraEProcuracoes"
            style=" width: 100%; border-collapse: collapse; table-layout: fixed;">
            <thead class="thead-light">
                <tr class="text-center"
                    *ngIf="dataEscrituraEProcuracoes?.quantidadeDias !== null && statusPesquisa > 0">
                    <th scope="col" colspan="7" class="thead-top-left p-2 "> Resultados podem retornar em até 7 dias
                    </th>

                    <th scope="col" colspan="2" class="thead p-2"
                        *ngIf="dataEscrituraEProcuracoes.quantidadeDias <= 7 && statusPesquisa === 4">
                        Sua pesquisa demorou {{ dataEscrituraEProcuracoes.quantidadeDias }} dia(s)
                    </th>

                    <th scope="col" colspan="2" class="thead p-2"
                        *ngIf="dataEscrituraEProcuracoes.quantidadeDias > 7 && statusPesquisa === 4">
                        Sua pesquisa demorou mais de 7 dias
                    </th>

                    <th scope="col" colspan="2" class="thead p-2"
                        *ngIf="dataEscrituraEProcuracoes.quantidadeDias <= 7 && statusPesquisa !== 4">
                        Sua pesquisa está no {{ dataEscrituraEProcuracoes.quantidadeDias }} dia
                    </th>

                    <th scope="col" colspan="2" class="thead p-2"
                        *ngIf="dataEscrituraEProcuracoes.quantidadeDias > 7 && statusPesquisa !== 4">
                        Sua pesquisa está demorando mais de 7 dias
                    </th>

                    <th scope="col" colspan="2" class="thead-top-right p-2">
                        <div class="d-flex align-items-end largura-fixa " style="justify-content: space-evenly;">
                            <div class="circle"
                                [ngClass]="{ 'circle-active': dataEscrituraEProcuracoes.quantidadeDias >= 1, 'circle-disabled': dataEscrituraEProcuracoes.quantidadeDias < 1 }">
                                1</div>
                            <div class="circle"
                                [ngClass]="{ 'circle-active': dataEscrituraEProcuracoes.quantidadeDias >= 2, 'circle-disabled': dataEscrituraEProcuracoes.quantidadeDias < 2 }">
                                2</div>
                            <div class="circle"
                                [ngClass]="{ 'circle-active': dataEscrituraEProcuracoes.quantidadeDias >= 3, 'circle-disabled': dataEscrituraEProcuracoes.quantidadeDias < 3 }">
                                3</div>
                            <div class="circle"
                                [ngClass]="{ 'circle-active': dataEscrituraEProcuracoes.quantidadeDias >= 4, 'circle-disabled': dataEscrituraEProcuracoes.quantidadeDias < 4 }">
                                4</div>
                            <div class="circle"
                                [ngClass]="{ 'circle-active': dataEscrituraEProcuracoes.quantidadeDias >= 5, 'circle-disabled': dataEscrituraEProcuracoes.quantidadeDias < 5 }">
                                5</div>
                            <div class="circle"
                                [ngClass]="{ 'circle-active': dataEscrituraEProcuracoes.quantidadeDias >= 6, 'circle-disabled': dataEscrituraEProcuracoes.quantidadeDias < 6 }">
                                6</div>
                            <div class="circle"
                                [ngClass]="{ 'circle-active': dataEscrituraEProcuracoes.quantidadeDias >= 7, 'circle-disabled': dataEscrituraEProcuracoes.quantidadeDias < 7 }">
                                7</div>
                        </div>
                    </th>
                </tr>

                <tr *ngIf="statusPesquisa.value === null">
                    <th scope="col" colspan="11" class="thead-top-only p-2">
                        <div class="loader dot-space mt-2">
                            <span>carregando</span>
                            <span class="loader__dot">.</span><span class="loader__dot">.</span><span
                                class="loader__dot">.</span>
                        </div>
                    </th>
                </tr>
            </thead>
            <tbody class="custom-tbody">
                <ng-container
                    *ngIf="dataEscrituraEProcuracoes.dados.results?.length > 0; else noDataEscrituraEProcuracoes">
                    <div class="text-center table-info scrollable-rows">
                        <tr class="text-center table-info">
                            <td colspan="1"><b>Ato</b></td>
                            <td colspan="1"><b>Natureza</b></td>
                            <td colspan="1"><b>Data</b></td>
                            <td colspan="1"><b>Livro</b></td>
                            <td colspan="1"><b>Folha</b></td>
                            <td colspan="4"><b>Partes</b></td>
                            <td colspan="2"><b>Cartório</b></td>
                            <td colspan="1"><b>Comarca/UF</b></td>
                        </tr>

                        <tr class="text-center table-info" *ngFor="let x of dataEscrituraEProcuracoes.dados.results">
                            <td colspan="1">{{x?.regTipoAto}}</td>
                            <td colspan="1">{{x?.regNaturezaAto}}</td>
                            <td colspan="1">{{x?.regDataAto}}</td>
                            <td colspan="1">{{x?.regLivro}}</td>
                            <td colspan="1">{{x?.regFolha}}</td>
                            <td colspan="4">
                                <div *ngFor="let parte of x.partes">
                                    {{parte.rpaNome}} : {{parte.rpaDocumento}} : {{parte.rpaQualidade}}
                                </div>
                            </td>
                            <td colspan="2">{{x?.regCartorio}}</td>
                            <td colspan="1">{{x?.regComarca}}</td>
                        </tr>
                    </div>
                    <tr class="text-center table-info">
                        <td colspan="11"><b>Atenção:</b> Pesquisas com mais de 50 atos podem conter mais atos..</td>
                    </tr>
                </ng-container>
                <ng-template #noDataEscrituraEProcuracoes>
                    <tr>
                        <td colspan="11" class="text-center">Não há resultados disponíveis</td>
                    </tr>
                </ng-template>
            </tbody>
        </table>

        <div class="d-flex justify-content-center text-center flex-column"
            *ngIf="!isLoadingEscrituraEProcuracoes && exibeEscrituraEProcuracoes">
            <div class="d-flex justify-content-end" *ngIf="exibeReprocessarEscrituraEProcuracoes">
                <button mat-flat-button class="gs-botaoAzulPadrao m-3"
                    (click)="getEscrituraEProcuracoes(idPesquisa, 230)">Refresh <mat-icon>cached
                    </mat-icon></button>
            </div>
            <div>
                <p> <b>Data previsão Entrega:</b> {{dataEscrituraEProcuracoes.dataPrevEntrega}} - <b>Status Pedido:</b>
                    {{dataEscrituraEProcuracoes.mensagemRetornoFonte}}</p>
                <!-- <p>{{dataEscrituraEProcuracoes.mensagem}}</p> -->
            </div>
        </div>
        <loader-only *ngIf="isLoadingEscrituraEProcuracoes"></loader-only>
    </mat-expansion-panel>
</mat-accordion>