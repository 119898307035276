<div class="container">
  <div *ngIf="exibirDadosCadastrais"
    class="row d-flex justify-content-center border-grey align-items-center p-3 info-content">

    <div class="col p-4" *ngIf="aba.novaPesquisa && !isAnaliseCredito">
      <loader-only class="mt-3" *ngIf="isLoadingTelefones"></loader-only>
      <div *ngIf="!isLoadingTelefones">
        <h2 class="title-tab" *ngIf="infoCadastraisTelefone.tipoDocumento == 1">{{infoCadastraisTelefone.dadosCpf.nome}}
        </h2>
        <h2 class="title-tab" *ngIf="infoCadastraisTelefone.tipoDocumento == 2">
          {{infoCadastraisTelefone.dadosCnpj.razaoSocial}}</h2>
        <p class="info-paragraph" *ngIf="infoCadastraisTelefone.tipoDocumento == 1">CPF:
          <span>{{infoCadastraisTelefone.dadosCpf.cpf}}</span></p>
        <p class="info-paragraph" *ngIf="infoCadastraisTelefone.tipoDocumento == 2">CNPJ:
          <span>{{infoCadastraisTelefone.dadosCnpj.cnpj}}</span></p>

        <p class="info-paragraph" *ngIf="infoCadastraisTelefone.tipoDocumento == 1">Nascimento:
          <span>{{infoCadastraisTelefone.dadosCpf.dataNascimento | date: 'dd/MM/yyyy'}}</span></p>
        <p class="info-paragraph" *ngIf="infoCadastraisTelefone.tipoDocumento == 2">CNAE:
          <span>{{infoCadastraisTelefone.dadosCnpj.cnaeFiscal}} - {{infoCadastraisTelefone.dadosCnpj.cnaeNome}}</span>
        </p>

        <p class="info-paragraph" *ngIf="infoCadastraisTelefone.tipoDocumento == 1">Filiação:
          <span>{{infoCadastraisTelefone.dadosCpf.filiacao}}</span></p>
        <p class="info-paragraph" *ngIf="infoCadastraisTelefone.tipoDocumento == 2">Capital Social: <span
            class="capital-social">{{infoCadastraisTelefone.dadosCnpj.capitalSocial}}</span></p>

        <p class="info-paragraph" *ngIf="infoCadastraisTelefone.tipoDocumento == 1">Óbito:
          <span>{{infoCadastraisTelefone.dadosCpf.obitoIndicador}}</span></p>
        <p class="info-paragraph" *ngIf="infoCadastraisTelefone.tipoDocumento == 2">Data de inicio das atividades:
          <span>{{infoCadastraisTelefone.dadosCnpj.dataInicioAtividades}}</span></p>

        <p class="info-paragraph" *ngIf="infoCadastraisTelefone.tipoDocumento == 1">Situação:
          <span>{{infoCadastraisTelefone.dadosCpf.situacaoCpf}}</span></p>
        <p class="info-paragraph" *ngIf="infoCadastraisTelefone.tipoDocumento == 2">Situação:
          <span>{{infoCadastraisTelefone.dadosCnpj.situacaoCadastral}}</span></p>

      </div>
    </div>

    <div class="col p-4" *ngIf="!aba.novaPesquisa">
      <loader-only class="mt-3" *ngIf="isLoadingCadastrais"></loader-only>
      <div *ngIf="!isLoadingCadastrais">
        <h2 class="title-tab" *ngIf="documento.length == 11">{{infoCadastrais.nome}}</h2>
        <h2 class="title-tab" *ngIf="documento.length == 14">{{infoCadastrais.razaoSocial}}</h2>
        <p class="info-paragraph" *ngIf="documento.length == 11">CPF: <span>{{infoCadastrais.cpf}}</span></p>
        <p class="info-paragraph" *ngIf="documento.length == 14">CNPJ: <span>{{infoCadastrais.cnpj}}</span></p>

        <p class="info-paragraph" *ngIf="documento.length == 11">Nascimento: <span>{{infoCadastrais.dataNascimento |
            date: 'dd/MM/yyyy'}}</span></p>
        <p class="info-paragraph" *ngIf="documento.length == 14">CNAE: <span>{{infoCadastrais.cnaeFiscal}} -
            {{infoCadastrais.cnaeNome}}</span></p>

        <p class="info-paragraph" *ngIf="documento.length == 11">Filiação: <span>{{infoCadastrais.filiacao}}</span></p>
        <p class="info-paragraph" *ngIf="documento.length == 14">Capital Social:
          <span>{{infoCadastrais.capitalSocial}}</span></p>

        <p class="info-paragraph" *ngIf="documento.length == 11">Óbito: <span>{{infoCadastrais.obitoIndicador}}</span>
        </p>
        <p class="info-paragraph" *ngIf="documento.length == 14">Data de inicio das atividades:
          <span>{{infoCadastrais.dataInicioAtividades | date: 'dd/MM/yyyy'}}</span></p>

        <p class="info-paragraph" *ngIf="documento.length == 11">Situação: <span>{{infoCadastrais.situacaoCpf}}</span>
        </p>
        <p class="info-paragraph" *ngIf="documento.length == 14">Situação:
          <span>{{infoCadastrais.situacaoCadastral}}</span></p>

      </div>
    </div>

    <div class="col p-4" *ngIf="aba.novaPesquisa && isAnaliseCredito">
      <loader-only class="mt-3" *ngIf="isLoadingCadastrais"></loader-only>
      <div *ngIf="!isLoadingCadastrais">
        <h2 class="title-tab" *ngIf="documento.length == 11">{{infoCadastrais.nome}}</h2>
        <h2 class="title-tab" *ngIf="documento.length == 14">{{infoCadastrais.razaoSocial}}</h2>
        <p class="info-paragraph" *ngIf="documento.length == 11">CPF: <span>{{infoCadastrais.cpf}}</span></p>
        <p class="info-paragraph" *ngIf="documento.length == 14">CNPJ: <span>{{infoCadastrais.cnpj}}</span></p>

        <p class="info-paragraph" *ngIf="documento.length == 11">Nascimento: <span>{{infoCadastrais.dataNascimento |
            date: 'dd/MM/yyyy'}}</span></p>
        <p class="info-paragraph" *ngIf="documento.length == 14">CNAE: <span>{{infoCadastrais.cnaeFiscal}} -
            {{infoCadastrais.cnaeNome}}</span></p>

        <p class="info-paragraph" *ngIf="documento.length == 11">Filiação: <span>{{infoCadastrais.filiacao}}</span></p>
        <p class="info-paragraph" *ngIf="documento.length == 14">Capital Social:
          <span>{{infoCadastrais.capitalSocial}}</span></p>

        <p class="info-paragraph" *ngIf="documento.length == 11">Óbito: <span>{{infoCadastrais.obitoIndicador}}</span>
        </p>
        <p class="info-paragraph" *ngIf="documento.length == 14">Data de inicio das atividades:
          <span>{{infoCadastrais.dataInicioAtividades | date: 'dd/MM/yyyy'}}</span></p>

        <p class="info-paragraph" *ngIf="documento.length == 11">Situação: <span>{{infoCadastrais.situacaoCpf}}</span>
        </p>
        <p class="info-paragraph" *ngIf="documento.length == 14">Situação:
          <span>{{infoCadastrais.situacaoCadastral}}</span></p>

      </div>
    </div>
    <div class="col d-flex gaps">
      <div class="content-box">
        <div *ngIf="!aba.novaPesquisa">
          <h4 class="d-flex align-items-center">Telefones &nbsp; <mat-icon> phone</mat-icon>
          </h4>
          <loader-only class="mt-3" *ngIf="isLoadingCadastrais"></loader-only>
          <div class="mt-3 box-scroll" *ngIf="!isLoadingCadastrais && infoCadastrais.telefones?.length > 0">
            <p *ngFor="let telefone of infoCadastrais?.telefones">{{telefone}}</p>
          </div>
          <!-- <br> -->
          <p *ngIf="!isLoadingCadastrais && infoCadastrais.telefones?.length == 0"> Não localizado</p>
        </div>

        <div *ngIf="aba.novaPesquisa && isAnaliseCredito">
          <h4 class="d-flex align-items-center">Telefones &nbsp; <mat-icon> phone</mat-icon>
          </h4>
          <loader-only class="mt-3" *ngIf="isLoadingCadastrais"></loader-only>
          <div class="mt-3 box-scroll" *ngIf="!isLoadingCadastrais && infoCadastrais.telefones?.length > 0">
            <p *ngFor="let telefone of infoCadastrais?.telefone">{{telefone}}</p>
          </div>
          <!-- <br> -->
          <p *ngIf="!isLoadingCadastrais && infoCadastrais.telefones?.length == 0"> Não localizado</p>
        </div>

        <div *ngIf="aba.novaPesquisa && !isAnaliseCredito">
          <h4 class="d-flex align-items-center">Telefones &nbsp; <mat-icon> phone</mat-icon>
          </h4>
          <loader-only class="mt-3" class="mt-3" *ngIf="isLoadingTelefones"></loader-only>
          <div class="mt-3 box-scroll" *ngIf="infoCadastraisTelefone.tipoDocumento == 2  && !isLoadingTelefones">
            <p *ngFor="let telefone of infoCadastraisTelefone.dadosCnpj.telefones">{{telefone}}</p>
            <p *ngIf=" infoCadastraisTelefone.dadosCnpj?.telefones?.length == 0"> Não localizado</p>
          </div>

          <div class="mt-3 box-scroll" *ngIf="infoCadastraisTelefone.tipoDocumento == 1  && !isLoadingTelefones">
            <p *ngFor="let telefone of infoCadastraisTelefone.dadosCpf.telefones">{{telefone}}</p>
            <p *ngIf=" infoCadastraisTelefone.dadosCpf?.telefones?.length == 0"> Não localizado</p>
          </div>
          <!-- <br> -->
        </div>
      </div>


      <div class="content-box">
        <!-- ==== credito===  -->
        <div *ngIf="!aba.novaPesquisa && isAnaliseCredito">
          <h4 class="d-flex align-items-center">E-mails &nbsp; <mat-icon> mail_outline</mat-icon>
          </h4>
          <loader-only class="mt-3" *ngIf="isLoadingCadastrais"></loader-only>
          <div class="mt-3 box-scroll" *ngIf="!isLoadingCadastrais && infoCadastrais.cpf">
            <p *ngIf="infoCadastrais?.emails?.length > 0">
              <span *ngFor="let email of infoCadastrais?.emails">{{ email }}</span>
            </p>
            <p *ngIf="infoCadastrais?.emails?.length == 0 || !infoCadastrais?.emails">
              <span>Não Localizado</span>
            </p>
          </div>

          <div class="mt-3 box-scroll" *ngIf="!isLoadingCadastrais && infoCadastrais.cnpj">
            <p *ngIf="infoCadastrais?.emails?.length > 0">
              <span *ngFor="let email of infoCadastrais?.emails">{{ email }}</span>
            </p>
            <p *ngIf="infoCadastrais?.emails?.length == 0">
              <span>Não Localizado</span>
            </p>
          </div>
          <br>
        </div>

        <div *ngIf="aba.novaPesquisa && isAnaliseCredito">
          <h4 class="d-flex align-items-center">E-mails &nbsp; <mat-icon> mail_outline</mat-icon>
          </h4>
          <loader-only class="mt-3" *ngIf="isLoadingCadastrais"></loader-only>
          <div class="mt-3 box-scroll" *ngIf="!isLoadingCadastrais && infoCadastrais.cpf">
            <p *ngIf="infoCadastrais?.emails?.length > 0">
              <span *ngFor="let email of infoCadastrais?.emails">{{ email }}</span>
            </p>
            <p *ngIf="!infoCadastrais?.emails || infoCadastrais?.emails.length == 0">
              <span>Não Localizado</span>
            </p>
          </div>

          <div class="mt-3 box-scroll" *ngIf="!isLoadingCadastrais && infoCadastrais.cnpj">
            <p *ngIf="infoCadastrais?.emails?.length > 0">
              <span *ngFor="let email of infoCadastrais?.emails">{{ email }}</span>
            </p>
            <p *ngIf="infoCadastrais?.emails?.length == 0">
              <span>Não Localizado</span>
            </p>
          </div>
          <br>
        </div>

        <!-- === diferente de credito ==== -->
        <div *ngIf="!aba.novaPesquisa && !isAnaliseCredito">
          <h4 class="d-flex align-items-center">E-mails &nbsp; <mat-icon> mail_outline</mat-icon>
          </h4>
          <loader-only class="mt-3" *ngIf="isLoadingCadastrais"></loader-only>
          <div class="mt-3 box-scroll" *ngIf="!isLoadingCadastrais && infoCadastrais?.cpf">
            <p *ngFor="let email of infoCadastrais.emails">{{email ? email : 'Não Localizado'}}</p>
          </div>

          <div class="mt-3 box-scroll" *ngIf="!isLoadingCadastrais && infoCadastrais?.cnpj">
            <p>{{infoCadastrais.correioEletronico}}</p>
            <p *ngFor="let email of infoCadastrais.emails">{{email}}</p>
            <p *ngIf="infoCadastrais?.emails.length == 0 && !infoCadastrais.correioEletronico">Não Localizado</p>
          </div>
          <br>
        </div>

        <div *ngIf="aba.novaPesquisa && !isAnaliseCredito">
          <h4 class="d-flex align-items-center">E-mails &nbsp; <mat-icon> mail_outline</mat-icon>
          </h4>
          <loader-only class="mt-3" class="mt-3" *ngIf="isLoadingEmails"></loader-only>
          <div class="mt-3 box-scroll" *ngIf="infoCadastraisEmails.tipoDocumento == 2 && !isLoadingEmails">
            <p> {{infoCadastraisEmails.dadosCnpj.correioEletronico}}</p>
            <p *ngFor="let email of infoCadastraisEmails.dadosCnpj.emails">{{email}}</p>
            <p
              *ngIf="infoCadastraisEmails.dadosCnpj?.emails?.length == 0 && !infoCadastraisEmails.dadosCnpj.correioEletronico">
              Não localizado</p>
          </div>

          <div class="mt-3 box-scroll" *ngIf="infoCadastraisEmails.tipoDocumento == 1 && !isLoadingEmails">
            <p *ngFor="let email of infoCadastraisEmails.dadosCpf?.emails"> {{email}}</p>
            <p *ngIf="infoCadastraisEmails.dadosCpf?.emails?.length == 0"> Não localizado</p>
          </div>
          <br>
        </div>
      </div>

      <!-- ================= endereços========================= -->

      <div class="content-box">
        <div *ngIf="!aba.novaPesquisa && !isAnaliseCredito">
          <h4 class="d-flex align-items-center">Endereços &nbsp; <mat-icon> place</mat-icon>
          </h4>
          <loader-only class="mt-3" *ngIf="isLoadingCadastrais"></loader-only>
          <div class="box-scroll" *ngIf="infoCadastrais.cnpj && !isLoadingCadastrais">
            <p>{{infoCadastrais?.logradouro}} {{infoCadastrais?.numero}} {{infoCadastrais?.complemento}}
              {{infoCadastrais?.bairro}} - {{infoCadastrais?.uf}} </p>
            <div *ngFor="let endereco of infoCadastrais?.enderecos">
              <p>{{endereco?.logradouro}} {{endereco?.numero}} {{endereco?.complemento}} {{endereco?.bairro}} -
                {{endereco?.uf}}</p>
            </div>
            <p
              *ngIf="!isLoadingCadastrais && infoCadastrais?.cnpj && !infoCadastrais?.logradouro && infoCadastrais?.enderecos.length == 0">
              Não localizado</p>
          </div>

          <div class="box-scroll" *ngIf="infoCadastrais.cpf && !isLoadingCadastrais">
            <div *ngFor="let endereco of infoCadastrais?.enderecos">
              <p>{{endereco?.logradouro}} {{endereco?.numero}} {{endereco?.complemento}} {{endereco?.bairro}} -
                {{endereco?.uf}}</p>
            </div>
            <p *ngIf="!isLoadingCadastrais && infoCadastrais?.cpf && infoCadastrais?.enderecos.length == 0">Não
              Localizado</p>
          </div>
        </div>

        <div *ngIf="aba.novaPesquisa && !isAnaliseCredito">
          <h4 class="d-flex align-items-center">Endereços &nbsp; <mat-icon> place</mat-icon>
          </h4>
          <loader-only class="mt-3" class="mt-3" *ngIf="isLoadingEnderecos"></loader-only>
          <div class="box-scroll" *ngIf="infoCadastraisEnderecos.tipoDocumento == 2 && !isLoadingEnderecos">
            <p class="mt-3">{{infoCadastraisEnderecos.dadosCnpj?.logradouro}}
              {{infoCadastraisEnderecos.dadosCnpj?.numero}} {{infoCadastraisEnderecos.dadosCnpj?.complemento}}
              {{infoCadastraisEnderecos.dadosCnpj?.bairro}} - {{infoCadastraisEnderecos.dadosCnpj?.uf}}</p>
            <div *ngFor="let endereco of infoCadastraisEnderecos.dadosCnpj?.enderecos">
              <p>{{endereco?.logradouro}} {{endereco?.numero}} {{endereco?.complemento}} {{endereco?.bairro}} -
                {{endereco?.uf}}</p>
            </div>
            <p
              *ngIf="infoCadastraisEnderecos.dadosCnpj?.enderecos?.length == 0 && !infoCadastraisEnderecos.dadosCnpj?.logradouro">
              Não localizado</p>
          </div>

          <div class="box-scroll" *ngIf="infoCadastraisEnderecos.tipoDocumento == 1 && !isLoadingEnderecos">
            <div *ngFor="let endereco of infoCadastraisEnderecos.dadosCpf?.enderecos">
              <p>{{endereco?.logradouro}} {{endereco?.numero}} {{endereco?.complemento}} {{endereco?.bairro}} -
                {{endereco?.uf}}</p>
            </div>
            <p *ngIf="!isLoadingCadastrais && infoCadastraisEnderecos.dadosCpf?.enderecos.length == 0">Não Localizado
            </p>
          </div>
        </div>

        <div *ngIf="aba.novaPesquisa && isAnaliseCredito">
          <h4 class="d-flex align-items-center">Endereços &nbsp; <mat-icon> place</mat-icon>
          </h4>
          <loader-only class="mt-3" *ngIf="isLoadingCadastrais"></loader-only>
          <div class="box-scroll" *ngIf="infoCadastrais.cnpj && !isLoadingCadastrais">
            <p>{{infoCadastrais?.logradouro}}, {{infoCadastrais?.numero}} {{infoCadastrais?.complemento}}
              {{infoCadastrais?.bairro}} - {{infoCadastrais?.uf}} </p>
            <p *ngIf="!isLoadingCadastrais && infoCadastrais?.cnpj && !infoCadastrais?.logradouro"> Não localizado</p>
          </div>

          <div class="box-scroll" *ngIf="infoCadastrais.cpf && !isLoadingCadastrais">
            <div *ngFor="let endereco of infoCadastrais.enderecos">
              <p>{{endereco?.logradouro}} {{endereco?.numero}} {{endereco?.complemento}} , {{endereco?.bairro}} -
                {{endereco?.uf}}</p>
            </div>
            <p *ngIf="!isLoadingCadastrais && infoCadastrais?.cpf && infoCadastrais?.enderecos.length == 0">Não
              Localizado</p>
          </div>
        </div>

        <div *ngIf="!aba.novaPesquisa && isAnaliseCredito">
          <h4 class="d-flex align-items-center">Endereços &nbsp; <mat-icon> place</mat-icon>
          </h4>
          <loader-only class="mt-3" *ngIf="isLoadingCadastrais"></loader-only>
          <div class="box-scroll" *ngIf="infoCadastrais.cnpj && !isLoadingCadastrais">
            <p>{{infoCadastrais?.logradouro}}, {{infoCadastrais?.numero}} {{infoCadastrais?.complemento}}
              {{infoCadastrais?.bairro}} - {{infoCadastrais?.uf}} </p>
            <p *ngIf="!isLoadingCadastrais && infoCadastrais?.cnpj && !infoCadastrais?.logradouro"> Não localizado</p>
          </div>

          <div class="box-scroll" *ngIf="infoCadastrais.cpf && !isLoadingCadastrais">
            <div *ngFor="let endereco of infoCadastrais.enderecos">
              <p>{{endereco?.logradouro}} {{endereco?.numero}} {{endereco?.complemento}} , {{endereco?.bairro}} -
                {{endereco?.uf}}</p>
            </div>
            <p *ngIf="!isLoadingCadastrais && infoCadastrais?.cpf && infoCadastrais?.enderecos.length == 0">Não
              Localizado</p>
          </div>
        </div>
      </div>

      <!-- ============================================== -->

      <div class="content-box">
        <h4 class="d-flex align-items-center">Mapa &nbsp; <mat-icon> map</mat-icon>
        </h4>
        <div *ngIf="!aba.novaPesquisa">
          <loader-only class="mt-3" *ngIf="isLoadingCadastrais"></loader-only>
          <iframe *ngIf="!isLoadingCadastrais" id="localizacao" width="100%" frameborder="0" scrolling="no"
            marginheight="0" marginwidth="0" [src]="url" class="iframe-mapa"></iframe>
          <br>
        </div>

        <div *ngIf="aba.novaPesquisa && isAnaliseCredito">
          <loader-only class="mt-3" *ngIf="isLoadingCadastrais"></loader-only>
          <iframe *ngIf="!isLoadingCadastrais" id="localizacao" width="100%" frameborder="0" scrolling="no"
            marginheight="0" marginwidth="0" [src]="url" class="iframe-mapa"></iframe>
          <br>
        </div>

        <div *ngIf="aba.novaPesquisa && !isAnaliseCredito">
          <loader-only class="mt-3" *ngIf="isLoadingEnderecos"></loader-only>
          <iframe *ngIf="infoCadastraisEnderecos.tipoDocumento == 2 && !isLoadingEnderecos" id="localizacao"
            width="100%" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" [src]="url"
            class="iframe-mapa"></iframe>

          <iframe *ngIf="infoCadastraisEnderecos.tipoDocumento == 1 && !isLoadingEnderecos" id="localizacao"
            width="100%" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" [src]="url"
            class="iframe-mapa"></iframe>
          <br>
        </div>
      </div>
    </div>

    
  </div>

  <app-midias-negativas class="mt-3" *ngIf="exibeMidiasNegativas" [dadosPesquisa]="idPesquisa"></app-midias-negativas>
  <!--<app-midias-negativas class="mt-3" [dadosPesquisa]="idPesquisa" *ngif="isDossie"></app-midias-negativas>  -->

  <!-- <div class="title-content d-flex justify-content-between mt-5 align-items-center"
    *ngIf="isProcessoOuDossie && exibirSinteseProcesso">
    <div></div>
    <button mat-flat-button class="gs-botaoAzulPadrao m-3" (click)="openLinhaTempo()">Linha do Tempo
      <mat-icon>search</mat-icon></button>
  </div> -->


  <div class="border-grey" style="margin: 1.7rem -0.2rem;" *ngIf="isProcessoOuDossie && exibirSinteseProcesso">
    <div class="row d-flex justify-content-evenly mt-4 margin-processos">
      <div class="d-flex justify-content-end" *ngIf="exibeReprocessarSintese">
        <button *ngIf="exibeBotaoRefresh" mat-flat-button class="gs-botaoAzulPadrao m-3"
          (click)="getProcessosStatus()">Refresh <mat-icon>cached
          </mat-icon></button>
      </div>
      <h2 class="title-tab text-center">Informações Processuais</h2>
      <div class="border-grey processo-indisponivel text-center d-flex flex-column justify-content-center p-2 m-2 mb-5"
        *ngIf="exibeReprocessarSintese">
        <p>{{objProcessos.mensagem}}</p>
      </div>

      <div class="d-flex justify-content-end align-items-center"*ngIf="objProcessos.dataAtualizacao && !exibeReprocessarSintese">
        <span>Data de Atualização: {{objProcessos.dataAtualizacao}} &nbsp;</span>
          <app-loader-mini *ngIf="objProcessos.dataAtualizacao && objProcessos.codigo != 1000"></app-loader-mini>
      </div>

      <div class="d-flex justify-content-end align-items-center" *ngIf="exibeMensagemAtualizacaoProcesso && objProcessos.codigo != 1000">
        <p class="p-grey loader dot-space">{{objProcessos.mensagem}} <span class="loader__dot">.</span><span class="loader__dot">.</span><span
          class="loader__dot">.</span></p>
        <button *ngIf="exibeBotaoRefresh" mat-flat-button class="gs-botaoAzulPadrao m-3"
        (click)="getProcessosStatus()">Refresh <mat-icon>cached
        </mat-icon></button>
      </div>
      <div class="col border-grey text-center p-2 m-2" [ngClass]="exibeReprocessarSintese ? 'opacity-min' : '' "
        *ngIf="!exibeReprocessarSintese">
        <p class="title-grey mt-2 mb-5">Processos Estaduais</p>
        <loader-only class="mt-3" *ngIf="isLoadingSintese"></loader-only>
        <div class="row d-flex flex-column" *ngIf="!isLoadingSintese">
          <div class="col">
            <div class="row d-flex content-grey">
              <div class="col-4 border-right">
                <h4 class="title-polo">Passivos</h4>
                <p class="count-polo">{{objProcessos.dados.sinteseProcessos.qtdEstaduaisPoloPassivo}}</p>
              </div>
              <div class="col-8 d-flex justify-content-center align-items-center">
                <p class="value-polo">
                  {{objProcessos.dados.sinteseProcessos.totalEstaduaisValorPoloPassivo | currency:'BRL'}}</p>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="row d-flex content-grey">
              <div class="col-4 border-right">
                <h4 class="title-polo">Ativos</h4>
                <p class="count-polo">{{objProcessos.dados.sinteseProcessos.qtdEstaduaisPoloAtivo}}</p>
              </div>
              <div class="col-8 d-flex justify-content-center align-items-center">
                <p class="value-polo">
                  {{objProcessos.dados.sinteseProcessos.totalEstaduaisValorPoloAtivo | currency:'BRL'}}</p>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="row d-flex content-grey">
              <div class="col-4 border-right">
                <h4 class="title-polo">Outros</h4>
                <p class="count-polo">{{objProcessos.dados.sinteseProcessos.qtdEstaduaisPoloNaoIdentificado}}</p>
              </div>
              <div class="col-8 d-flex justify-content-center align-items-center">
                <p class="value-polo">
                  {{objProcessos.dados.sinteseProcessos.totalEstaduaisValorPoloNaoIdentificado | currency:'BRL'}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col border-grey text-center p-2 m-2" [ngClass]="exibeReprocessarSintese ? 'opacity-min' : '' "
        *ngIf="!exibeReprocessarSintese">
        <p class="title-grey mt-2 mb-5">Processos Federais</p>
        <loader-only class="mt-3" *ngIf="isLoadingSintese"></loader-only>
        <div class="row d-flex flex-column" *ngIf="!isLoadingSintese">
          <div class="col">
            <div class="row d-flex content-grey">
              <div class="col-4 border-right">
                <h4 class="title-polo">Passivos</h4>
                <p class="count-polo">{{objProcessos.dados.sinteseProcessos.qtdFederalPoloPassivo}}</p>
              </div>
              <div class="col-8 d-flex justify-content-center align-items-center">
                <p class="value-polo">
                  {{objProcessos.dados.sinteseProcessos.totalFederalValorPoloPassivo | currency:'BRL'}}</p>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="row d-flex content-grey">
              <div class="col-4 border-right">
                <h4 class="title-polo">Ativos</h4>
                <p class="count-polo">{{objProcessos.dados.sinteseProcessos.qtdFederalPoloAtivo}}</p>
              </div>
              <div class="col-8 d-flex justify-content-center align-items-center">
                <p class="value-polo">
                  {{objProcessos.dados.sinteseProcessos.totalFederalValorPoloAtivo | currency:'BRL'}}</p>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="row d-flex content-grey">
              <div class="col-4 border-right">
                <h4 class="title-polo">Outros</h4>
                <p class="count-polo">{{objProcessos.dados.sinteseProcessos.qtdFederalPoloNaoIdentificado}}</p>
              </div>
              <div class="col-8 d-flex justify-content-center align-items-center">
                <p class="value-polo">
                  {{objProcessos.dados.sinteseProcessos.totalFederalValorPoloNaoIdentificado | currency:'BRL'}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col border-grey text-center p-2 m-2" [ngClass]="exibeReprocessarSintese ? 'opacity-min' : '' "
        *ngIf="!exibeReprocessarSintese">
        <p class="title-grey mt-2 mb-5">Processos Trabalhistas</p>
        <loader-only class="mt-3" *ngIf="isLoadingSintese"></loader-only>
        <div class="row d-flex flex-column" *ngIf="!isLoadingSintese">
          <div class="col">
            <div class="row d-flex content-grey">
              <div class="col-4 border-right">
                <h4 class="title-polo">Passivos</h4>
                <p class="count-polo">{{objProcessos.dados.sinteseProcessos.qtdTrabalhistasPoloPassivo}}</p>
              </div>
              <div class="col-8 d-flex justify-content-center align-items-center">
                <p class="value-polo">
                  {{objProcessos.dados.sinteseProcessos.totalTrabalhistasValorPoloPassivo | currency:'BRL'}}</p>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="row d-flex content-grey">
              <div class="col-4 border-right">
                <h4 class="title-polo">Ativos</h4>
                <p class="count-polo">{{objProcessos.dados.sinteseProcessos.qtdTrabalhistasPoloAtivo}}</p>
              </div>
              <div class="col-8 d-flex justify-content-center align-items-center">
                <p class="value-polo">
                  {{objProcessos.dados.sinteseProcessos.totalTrabalhistasValorPoloAtivo | currency:'BRL'}}</p>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="row d-flex content-grey">
              <div class="col-4 border-right">
                <h4 class="title-polo">Outros</h4>
                <p class="count-polo">{{objProcessos.dados.sinteseProcessos.qtdTrabalhistasPoloNaoIdentificado}}</p>
              </div>
              <div class="col-8 d-flex justify-content-center align-items-center">
                <p class="value-polo">
                  {{objProcessos.dados.sinteseProcessos.totalTrabalhistasValorPoloNaoIdentificado | currency:'BRL'}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <app-linha-tempo [ngClass]="exibeReprocessarSintese ? 'opacity-min' : '' "
      *ngIf="exibeLinhaTempo && !exibeReprocessarSintese" [dadosSintese]="dadosSintese"></app-linha-tempo>
  </div>

  <app-analise-credito *ngIf="exibeAnaliseCredito && (isAnaliseCredito || isDossie)" [aba]="aba" class="mt-4"
    (exibeAnaliseCreditoChange)="atualizarExibeAnaliseCredito($event)"></app-analise-credito>

  
    <app-detech-em-tela [doc]="documento"></app-detech-em-tela>

    <div *ngIf="exibeCadastrais && !aba.novaPesquisa">
      <loader-only class="mt-3" *ngIf="isLoadingCadastrais"></loader-only>
      <app-dados-cadastrais *ngIf="!isLoadingCadastrais" [objetoRelatorioCadastral]="infoCadastrais">
      </app-dados-cadastrais>
    </div>

    <div *ngIf="exibeCadastrais && aba.novaPesquisa">
      <loader-only class="mt-3" *ngIf="isLoadingCadastraisGeral"></loader-only>
      <app-dados-cadastrais *ngIf="!isLoadingCadastraisGeral" [objetoNovaPesquisa]="infoCadastraisGeral">
      </app-dados-cadastrais>
    </div>
  

  <div role="button" class="d-flex justify-content-center  align-items-center border-grey-more mt-4 baixar-pdf"
    *ngIf="!isLoadingPdf" [style.background]="getCorPorcentagem(porcentagemPesquisa)" (click)="exportarClicked()">
    <p>Informações completas estão disponíveis em PDF</p>
    <mat-icon class="pdf-icon">save_alt</mat-icon>
  </div>

  <div class="d-flex justify-content-center  align-items-center border-grey-more mt-4 baixar-pdf" *ngIf="isLoadingPdf"
    [style.background]="getCorPorcentagem(porcentagemPesquisa)">
    <p>Informações completas estão disponíveis em PDF</p>
    <div class="d-flex align-items-center">
      <div class="loading">
      </div>
      <span class="percentual-pesquisa"
        *ngIf="porcentagemPesquisa < 100 && statusPesquisa < 4">{{porcentagemPesquisa}}%</span>
    </div>
  </div>
</div>
