<div class="div-ext">
  <div class="cabecalho">
    <p class="text-title">Incluir Crédito</p>
  </div>
  <br>
  <form [formGroup]="formNovoCredito">
      <div class="row">
        <div class="col-8 col-auto">
          <mat-form-field appearance="outline">
            <mat-label>Forma de Pagamento</mat-label>
            <mat-select (selectionChange)="formaPagamentoChanged($event)" formControlName="formapagamento" name="formapagamento" >
              <mat-option value="1">Pix</mat-option>
              <mat-option value="2">Boleto</mat-option>
              <mat-option value="3">Cartão de Crédito</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-4 col-auto">
          <mat-form-field appearance="outline">
            <mat-label>Valor</mat-label>
            <input matInput formControlName="valor" name="valor">
          </mat-form-field>
        </div>
      </div>
      <mat-divider class="mt-4 mb-4 divider"></mat-divider>
      <div class="row" *ngIf="formaPagamento == '3'">
        <div class="col-8 col-auto">
          <mat-form-field appearance="outline">
            <mat-label>Número</mat-label>
            <input matInput formControlName="numerocartao" name="numerocartao" (keyup)="atualizaBandeira($event)" autocomplete="off" >
          </mat-form-field>
        </div>
        <div class="col-4 col-auto">
          <mat-form-field appearance="outline">
            <mat-label>Bandeira</mat-label>
            <input matInput readonly [value]="bandeiraCartao" name="bandeiraCartao">
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-6 col-auto">
          <mat-form-field appearance="outline">
            <mat-label>Nome Títular</mat-label>
            <input matInput formControlName="nometitularcartao" name="nometitularcartao" autocomplete="off" >
          </mat-form-field>
        </div>
        <div class="col-3 col-auto">
          <mat-form-field appearance="outline">
            <mat-label>Validade</mat-label>
            <input matInput formControlName="validadecartao" name="validadecartao" mask="00/0000" autocomplete="off">
          </mat-form-field>
        </div>
        <div class="col-3 col-auto">
          <mat-form-field appearance="outline">
            <mat-label>Código Segurança</mat-label>
            <input matInput formControlName="codigosegurancacartao" name="codigosegurancacartao" mask="0000" autocomplete="off">
          </mat-form-field>
        </div>
      </div>
      <mat-divider class="mt-4 mb-4 divider"></mat-divider>
      <div class="row">
        <div class="col-8 col-auto">
          <mat-form-field appearance="outline">
            <mat-label>Rua</mat-label>
            <input matInput formControlName="rua" name="rua" autocomplete="off">
          </mat-form-field>
        </div>
        <div class="col-4 col-auto">
          <mat-form-field appearance="outline">
            <mat-label>Número</mat-label>
            <input matInput formControlName="numero" name="numero" autocomplete="off">
          </mat-form-field>
        </div>
      </div>
      
      <div class="row">
        <div class="col col-auto">
          <mat-form-field appearance="outline">
            <mat-label>CEP</mat-label>
            <input matInput formControlName="cep" name="cep" mask="00000000" (blur)="pesquisaCep($event.target.value);" autocomplete="off">
          </mat-form-field>
        </div>
        <div class="col col-auto">
          <mat-form-field appearance="outline">
            <mat-label>Bairro</mat-label>
            <input matInput formControlName="bairro" name="bairro" autocomplete="off">
          </mat-form-field>
        </div>
        <div class="col col-auto">
          <mat-form-field appearance="outline">
            <mat-label>Município</mat-label>
            <input matInput formControlName="municipio" name="municipio" autocomplete="off">
          </mat-form-field>
        </div>
        <div class="col col-auto">
          <mat-form-field appearance="outline">
            <mat-label>UF</mat-label>
            <input matInput formControlName="uf" name="uf" autocomplete="off">
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col col-auto">
          <mat-form-field appearance="outline">
            <mat-label>Complemento</mat-label>
            <input matInput formControlName="complemento" name="complemento" autocomplete="off">
          </mat-form-field>
        </div>
    </div>
    <!-- <div class="campos-pag">
      <mat-form-field appearance="outline" class="input-real pag">
        <mat-label>Forma de Pagamento</mat-label>

        <mat-select (selectionChange)="formaPagamentoChanged($event)" formControlName="formapagamento" name="formapagamento" >
          <mat-option value="1">Pix</mat-option>
          <mat-option value="2">Boleto</mat-option>
          <mat-option value="3">Cartão de Crédito</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="example-full-width input-real valor" appearance="outline">
        <mat-label>Valor</mat-label>
        <input matInput formControlName="valor" name="valor">
      </mat-form-field>
    </div>
    <div *ngIf="formaPagamento == '3'">
      <div class="campos-dados" >
        <div class="col">
          <mat-form-field class="example-full-width input-real num" appearance="outline">
            <mat-label>Número</mat-label>
            <input matInput formControlName="numerocartao" name="numerocartao" (keyup)="atualizaBandeira($event)" autocomplete="off" >
          </mat-form-field>
          <mat-form-field class="example-full-width input-real ban" appearance="outline">
            <mat-label>Bandeira</mat-label>
            <input matInput readonly [value]="bandeiraCartao" name="bandeiraCartao">
          </mat-form-field>
        </div>
        <div class="line2">
          <mat-form-field class="example-full-width input-real tit" appearance="outline">
            <mat-label>Nome Títular</mat-label>
            <input matInput formControlName="nometitularcartao" name="nometitularcartao" autocomplete="off" >
          </mat-form-field>
          <mat-form-field class="example-full-width input-real val" appearance="outline">
            <mat-label>Validade</mat-label>
            <input matInput formControlName="validadecartao" name="validadecartao" mask="00/0000" autocomplete="off">
          </mat-form-field>
          <mat-form-field class="example-full-width input-real seg" appearance="outline">
            <mat-label>Código Segurança</mat-label>
            <input matInput formControlName="codigosegurancacartao" name="codigosegurancacartao" mask="0000" autocomplete="off">
          </mat-form-field>
        </div>
      </div>

      <div class="campos-end">
        <div class="line1">
          <mat-form-field class="example-full-width input-real rua" appearance="outline">
            <mat-label>Rua</mat-label>
            <input matInput formControlName="rua" name="rua" autocomplete="off">
          </mat-form-field>
          <mat-form-field class="example-full-width input-real numero" appearance="outline">
            <mat-label>Número</mat-label>
            <input matInput formControlName="numero" name="numero" autocomplete="off">
          </mat-form-field>
        </div>
        <div class="line2">
          <mat-form-field class="example-full-width input-real cep" appearance="outline">
            <mat-label>CEP</mat-label>
            <input matInput formControlName="cep" name="cep" mask="00000000" (blur)="pesquisaCep($event.target.value);" autocomplete="off">
          </mat-form-field>
          <mat-form-field class="example-full-width input-real bairro" appearance="outline">
            <mat-label>Bairro</mat-label>
            <input matInput formControlName="bairro" name="bairro" autocomplete="off">
          </mat-form-field>
          <mat-form-field class="example-full-width input-real municipio" appearance="outline">
            <mat-label>Município</mat-label>
            <input matInput formControlName="municipio" name="municipio" autocomplete="off">
          </mat-form-field>
          <mat-form-field class="example-full-width input-real uf" appearance="outline">
            <mat-label>UF</mat-label>
            <input matInput formControlName="uf" name="uf" autocomplete="off">
          </mat-form-field>
        </div>
        <div class="line3">
          <mat-form-field class="example-full-width input-real complemento" appearance="outline">
            <mat-label>Complemento</mat-label>
            <input matInput formControlName="complemento" name="complemento" autocomplete="off">
          </mat-form-field>
        </div>
      </div>
    </div> -->
    <div class="botao">
      <mat-dialog-actions align="end">
        <button class="pagar" mat-button (click)="confirmarNovoCreditoClicked()" cdkFocusInitial color="accent">Pagar</button>
        <button class="cancelar" mat-button mat-dialog-close>Cancelar</button>
      </mat-dialog-actions>
    </div>
  </form>
</div>
