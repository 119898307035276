import { Component, Input, OnInit } from '@angular/core';
import { takeWhile, timer } from 'rxjs';
import { PesquisaService } from 'src/app/shared/services/http/pesquisa.service';

@Component({
  selector: 'app-esg',
  templateUrl: './esg.component.html',
  styleUrls: ['./esg.component.scss']
})
export class EsgComponent implements OnInit {
  @Input() idPesquisa: number;
  @Input() aba: any;

  panelOpenState = false


  constructor(
      private pesquisaService: PesquisaService
  ) { }

  ngOnInit(): void {
  }

}
