import { Component, Input, OnInit } from '@angular/core';
import { LinhaTempoComponent } from './linha-tempo/linha-tempo.component';
import { MatDialog } from '@angular/material/dialog';
import { UiService } from 'src/app/shared/services/ui.service';
import { ToastrService } from 'ngx-toastr';
import { PesquisaService } from 'src/app/shared/services/http/pesquisa.service';
import { takeWhile, timer } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'app-visao-processo',
  templateUrl: './visao-processo.component.html',
  styleUrls: ['./visao-processo.component.scss']
})
export class VisaoProcessoComponent implements OnInit {

  @Input() aba: any
  idPesquisa;
  isLoadingCadastrais = false;


  isLoadingTelefones = false;
  isLoadingEnderecos = false;
  isLoadingEmails = false;
  isLoadingCadastraisGeral = false;
  exibeMidiasNegativas = false;

  isLoadingSintese = false
  objProcessos = [];
  infoCadastrais = [];
  documento;
  enderecoUrl;
  enderecoUrlCompleto;
  objetoEndereco = {
    endereco: '',
    numero: '',
    bairro: '',
    cidade: '',
    uf: ''
  };
  url;
  enderecoCompleto;

  isProcessoOuDossie = false;
  isAnaliseCredito = false;
  isDossie = false;
  exibeCadastrais = false;

  infoCadastraisTelefone: any = []
  infoCadastraisEmails: any = [];
  infoCadastraisEnderecos: any = []
  infoCadastraisGeral = []

  objetoRelatorioCadastral;

  exibirDadosCadastrais = true;
  exibirSinteseProcesso = true;
  exibirSinteseCredito = true;
  exibeAnaliseCredito = true;
  sinteseEmProcessamento = false;
  exibeLinhaTempo = false
  exibeReprocessarSintese = false;
  exibeMensagemAtualizacaoProcesso = false;
  exibeBotaoRefresh = false;

  isLoadingPdf = true;
  porcentagemPesquisa;
  statusPesquisa;
  dadosSintese = []



  constructor(
    public dialog: MatDialog,
    private ui: UiService,
    private toastr: ToastrService,
    private pesquisaService: PesquisaService,
    public sanitizer: DomSanitizer,
    public router: Router,
  ) { }

  ngOnInit(): void {
    this.documento = this.aba.titulo.replaceAll('.', '').replaceAll('-', '').replaceAll('/', '');
    if (this.aba.id.id) {
      this.idPesquisa = this.aba.id.id
    }
    else {
      this.idPesquisa = this.aba.id
    }
    this.verificaStatusPesquisa(this.idPesquisa);
    // if (this.aba.novaPesquisa && this.router.url != '/credito') {
    //   this.consultaDadosCadastraisEmail(this.documento);
    //   this.consultaDadosCadastraisEndereco(this.documento);
    //   this.consultaDadosCadastraisTelefone(this.documento);
    //   this.consultaDadosCadastraisGeral(this.documento);
    //   this.exibeCadastrais = true
    // }
    if (!this.aba.novaPesquisa && this.router.url != '/credito') {
      this.getInfoCadastrais();
      this.exibeCadastrais = true;
    }

    if (this.router.url == '/processo' || this.router.url == '/dossie') {
      this.getProcessosStatus();
      this.isProcessoOuDossie = true;
    }

    if (this.router.url == '/dossie' || this.router.url == '/cadastrais') {
      this.exibeMidiasNegativas = true;
    }

    if (this.router.url == '/dossie') {
      this.isDossie = true;
    }

    if (this.aba.novaPesquisa && this.router.url == '/cadastrais') {
      this.exibeCadastrais = true;
      // this.consultaDadosCadastraisGeral(this.documento)
    }

    if (!this.aba.novaPesquisa && this.router.url == '/cadastrais') {
      this.exibeCadastrais = true;
      this.getInfoCadastrais();
    }

    // ===== credito ========
    if (this.aba.novaPesquisa && this.router.url == '/credito') {
      this.isAnaliseCredito = true
      this.getinfoCadastraisCredito(this.idPesquisa);
    }

    if (!this.aba.novaPesquisa && this.router.url == '/credito') {
      this.isAnaliseCredito = true
      this.getinfoCadastraisCredito(this.idPesquisa);
    }
  }

  getInfoCadastrais() {
    this.isLoadingCadastrais = true;
    let attempts = 0;

    const subscription = timer(0, 10000).pipe(
      takeWhile(() => attempts < 5) // Limita a 5 tentativas
    ).subscribe(() => {
      this.pesquisaService.getDadosCadastraisPesquisa(this.idPesquisa).subscribe(
        response => {
          if (response) {
            this.infoCadastrais = response;
            this.objetoRelatorioCadastral = this.infoCadastrais
            if (response.cpf) {
              this.objetoEndereco.endereco = response.enderecos[0]?.logradouro;
              this.objetoEndereco.numero = response.enderecos[0]?.numero;
              this.objetoEndereco.bairro = response.enderecos[0]?.bairro;
              this.objetoEndereco.uf = response.enderecos[0]?.uf;
            }
            if (response.cnpj) {
              this.objetoEndereco.endereco = response?.logradouro;
              this.objetoEndereco.numero = response?.numero;
              this.objetoEndereco.bairro = response?.bairro;
              this.objetoEndereco.uf = response?.uf;
            }
            var enderecoCompleto = `${this.objetoEndereco.endereco + '+' + this.objetoEndereco.numero + '+' + this.objetoEndereco.bairro + '+' + this.objetoEndereco.cidade + '+' + this.objetoEndereco.uf}`;
            this.montaurlEndereco(enderecoCompleto)

            this.isLoadingCadastrais = false;
            subscription.unsubscribe(); // Interrompe o intervalo após uma resposta bem-sucedida
          }
        },
        error => {
          attempts++;

          if (error.status == 501) {
            this.isLoadingCadastrais = false;
            subscription.unsubscribe(); // Interrompe o intervalo após 5 tentativas
            this.exibirDadosCadastrais = false;
          }

          if (attempts >= 5) {
            this.isLoadingCadastrais = true;
            subscription.unsubscribe(); // Interrompe o intervalo após 5 tentativas
            this.toastr.warning(`Erro ao obter as informações cadastrais do pesquisado.`);
          }
        }
      );
    });
  }

  // getSinteseProcessos() {
  //   this.isLoadingSintese = true;
  //   let attempts = 0;

  //   const subscription = this.pesquisaService.getSinteseProcesso(this.idPesquisa).subscribe(
  //     response => {
  //       this.sintese = response;
  //       this.isLoadingSintese = false;
  //       subscription.unsubscribe(); // Interrompe a subscrição após uma resposta bem-sucedida
  //     },
  //     error => {
  //       if (error.status === 400 && error.error === "Pesquisa em andamento...") {
  //         attempts++;
  //         if (attempts < 5) {
  //           // Se o erro for 501 e o número de tentativas for menor que 5, tenta novamente
  //           // this.toastr.warning(`Tentativa ${attempts} de obter a síntese do processo`);
  //           this.getSinteseProcessos(); // Chama a função novamente
  //         } else {
  //           // Se o número de tentativas exceder 5, exibe uma mensagem de erro
  //           this.toastr.warning(`Erro ao obter a síntese do processo`);
  //           this.isLoadingSintese = false;
  //           this.exibeReprocessarSintese = true;
  //         }
  //       } else {
  //         // Se o erro não for 400, exibe uma mensagem de erro genérica
  //         this.toastr.warning(`Erro ao obter a síntese do processo`);
  //         this.isLoadingSintese = false;
  //         this.exibeReprocessarSintese = true;
  //       }
  //     }
  //   );
  // }

  getSinteseProcessos(attempts: number = 0) {
    if (attempts >= 5) {
      this.toastr.warning(`Erro ao obter a síntese do processo após 5 tentativas`);
      this.isLoadingSintese = false;
      this.exibeReprocessarSintese = true;
      return;
    }

    this.isLoadingSintese = true;
    this.exibeReprocessarSintese = false;

    this.pesquisaService.getSinteseProcesso(this.idPesquisa).subscribe(
      response => {
        if (response == null || response == '') {
          this.isLoadingSintese = false;
          this.exibirSinteseProcesso = false;
        }
        else {
          this.objProcessos = response;
          this.isLoadingSintese = false;
        }
      },
      error => {
        if (error.status === 400 && error.error === "Pesquisa em andamento...") {
          // this.toastr.warning(`Tentativa ${attempts + 1} de obter a síntese do processo`);
          setTimeout(() => {
            this.getSinteseProcessos(attempts + 1); // Chama a função novamente com a próxima tentativa
          }, 6000);
        } else {
          this.toastr.warning(`Erro ao obter a síntese do processo`);
          this.isLoadingSintese = false;
          this.exibeReprocessarSintese = true;
        }
      }
    );
  }


  getProcessosStatus() {
    this.isLoadingSintese = true;
    this.exibeReprocessarSintese = false;
    this.exibeBotaoRefresh = false;
    this.sinteseEmProcessamento = false
    let tentativas = 0;

    const tryGetProcessosStatus = () => {
      this.pesquisaService.getSinteseProcesso(this.idPesquisa).subscribe(
        (response) => {
          this.ui.loaded().subscribe(() => {
            console.log(response);
            if (response.codigo === 1000) {
              this.objProcessos = response;
              this.isLoadingSintese = false;
              this.dadosSintese = response.dados;
              this.exibeReprocessarSintese = false
              this.exibeLinhaTempo = true;
            }

            if (response.codigo === 2000 && tentativas <= 4) {
              this.objProcessos = response;
              this.isLoadingSintese = false;
              this.dadosSintese = response.dados;
              this.exibeLinhaTempo = true;
              this.exibeMensagemAtualizacaoProcesso = true;
              setTimeout(() => {
                tentativas++;
                tryGetProcessosStatus();
              }, 15000);

              if (tentativas === 4) {
                this.exibeMensagemAtualizacaoProcesso = true;
                this.exibeBotaoRefresh = true
              }
            }

            if (response.codigo === 4000) {
              this.objProcessos = response;
              this.isLoadingSintese = false;
              this.exibeReprocessarSintese = true;
            }

            if (response.codigo === 3000 && tentativas <= 10) {
              this.objProcessos = response;
              this.exibeReprocessarSintese = true;
              this.sinteseEmProcessamento = true
              setTimeout(() => {
                tentativas++;
                tryGetProcessosStatus();
              }, 6000);

              // Verificar o número máximo de tentativas para exibir o reprocessamento
              if (tentativas === 10) {
                this.exibeReprocessarSintese = true;
                this.exibeBotaoRefresh = true
              }
            }
          });
        },
        (error) => {
          if (error.status === 501) {
            this.exibeReprocessarSintese = true;
          }
        }
      );
    };

    tryGetProcessosStatus();
  }



  openLinhaTempo() {
    const menu = this.dialog.open(LinhaTempoComponent, {
      width: '90%',
      panelClass: 'bg-color',
      height: '90%',
      data: {
        idPesquisa: this.idPesquisa,
        documento: this.documento
      }
    });
  }

  montaurlEndereco(enderecourl) {
    this.enderecoUrl = enderecourl
    this.enderecoUrl = this.enderecoUrl.replaceAll(' ', '+')
    this.enderecoUrlCompleto = `https://www.google.com/maps/embed/v1/place?q=${this.enderecoUrl}&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8`;
    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.enderecoUrlCompleto)
  }

  // consultaDadosCadastraisEmail(documento) {
  //   this.isLoadingEmails = true;
  //   this.pesquisaService.getConsultaCadastralEmail(documento).subscribe(response => {
  //     this.ui.loaded().subscribe(() => {
  //       this.infoCadastraisEmails = response;
  //       this.isLoadingEmails = false;
  //     });
  //   });
  // }

  // consultaDadosCadastraisTelefone(documento) {
  //   this.isLoadingTelefones = true;
  //   this.pesquisaService.getConsultaCadastralTelefone(documento).subscribe(response => {
  //     this.ui.loaded().subscribe(() => {
  //       this.infoCadastraisTelefone = response;
  //       this.isLoadingTelefones = false;
  //     });
  //   });
  // }

  // consultaDadosCadastraisGeral(documento) {
  //   this.isLoadingCadastraisGeral = true;
  //   this.pesquisaService.getConsultaCadastralGeral(documento).subscribe(response => {
  //     this.ui.loaded().subscribe(() => {
  //       this.infoCadastraisGeral = response;
  //       this.isLoadingCadastraisGeral = false;
  //     });
  //   });
  // }

  // consultaDadosCadastraisEndereco(documento) {
  //   this.isLoadingEnderecos = true;
  //   this.pesquisaService.getConsultaCadastralEndereco(documento).subscribe(response => {
  //     this.ui.loaded().subscribe(() => {
  //       this.infoCadastraisEnderecos = response;
  //       this.isLoadingEnderecos = false;
  //       if (this.infoCadastraisEnderecos.tipoDocumento == 2) {
  //         this.objetoEndereco.endereco = this.infoCadastraisEnderecos.dadosCnpj.logradouro;
  //         this.objetoEndereco.numero = this.infoCadastraisEnderecos.dadosCnpj.numero;
  //         this.objetoEndereco.bairro = this.infoCadastraisEnderecos.dadosCnpj.bairro;
  //         this.objetoEndereco.uf = this.infoCadastraisEnderecos.dadosCnpj.uf;
  //       }

  //       if (this.infoCadastraisEnderecos.tipoDocumento == 1) {
  //         this.objetoEndereco.endereco = this.infoCadastraisEnderecos.dadosCpf?.enderecos[0].logradouro;
  //         this.objetoEndereco.numero = this.infoCadastraisEnderecos.dadosCpf?.enderecos[0].numero;
  //         this.objetoEndereco.bairro = this.infoCadastraisEnderecos.dadosCpf?.enderecos[0].bairro;
  //         this.objetoEndereco.uf = this.infoCadastraisEnderecos.dadosCpf?.enderecos[0].uf;
  //       }
  //       var enderecoCompleto = `${this.objetoEndereco.endereco + '+' + this.objetoEndereco.numero + '+' + this.objetoEndereco.bairro + '+' + this.objetoEndereco.cidade + '+' + this.objetoEndereco.uf}`;
  //       this.montaurlEndereco(enderecoCompleto)
  //     });
  //   });
  // }

  getinfoCadastraisCredito(idPesquisa) {
    idPesquisa = this.idPesquisa;
    this.isLoadingCadastrais = true;
    let attempts = 0;

    const subscription = timer(0, 10000).pipe(
      takeWhile(() => attempts < 5) // Limita a 5 tentativas
    ).subscribe(() => {
      this.pesquisaService.getCadastralAnaliseCredito(idPesquisa).subscribe(
        response => {
          if (response) {
            this.isLoadingCadastrais = false;
            if (response.dadosCpf.cpf) {
              this.infoCadastrais = response.dadosCpf;
              this.objetoEndereco.endereco = response.dadosCpf?.enderecos[0]?.logradouro;
              this.objetoEndereco.numero = response.dadosCpf?.enderecos[0]?.numero;
              this.objetoEndereco.bairro = response.dadosCpf?.enderecos[0]?.bairro;
              this.objetoEndereco.uf = response.dadosCpf?.enderecos[0]?.uf;
            }
            if (response.dadosCnpj.cnpj) {
              this.infoCadastrais = response.dadosCnpj;
              this.objetoEndereco.endereco = response.dadosCnpj.logradouro;
              this.objetoEndereco.numero = response.dadosCnpj.numero;
              this.objetoEndereco.bairro = response.dadosCnpj.bairro;
              this.objetoEndereco.uf = response.dadosCnpj.uf;
            }
            var enderecoCompleto = `${this.objetoEndereco.endereco + '+' + this.objetoEndereco.numero + '+' + this.objetoEndereco.bairro + '+' + this.objetoEndereco.cidade + '+' + this.objetoEndereco.uf}`;
            this.montaurlEndereco(enderecoCompleto)

            subscription.unsubscribe(); // Interrompe o intervalo após uma resposta bem-sucedida
          }
        },
        error => {
          attempts++;
          if (error.status == 501) {
            this.isLoadingCadastrais = false;
            subscription.unsubscribe(); // Interrompe o intervalo após 5 tentativas
            this.exibirDadosCadastrais = false;
          }

          if (attempts >= 5) {
            // this.isLoadingCadastrais = true;
            subscription.unsubscribe(); // Interrompe o intervalo após 5 tentativas
            this.toastr.warning(`Erro ao obter as informações cadastrais do pesquisado.`);
            this.isLoadingCadastrais = false;
          }
        }
      );
    });
  }

  getinfoCadastraisCreditoNovaPesquisa(numero) {
    this.isLoadingCadastrais = true;
    this.pesquisaService.getCadastralCreditoNovaPesquisa(numero).subscribe(
      response => {
        if (response) {
          this.isLoadingCadastrais = false;
          if (response.dadosCpf.cpf) {
            this.infoCadastrais = response.dadosCpf;
            this.objetoEndereco.endereco = response.dadosCpf?.enderecos[0]?.logradouro;
            this.objetoEndereco.numero = response.dadosCpf?.enderecos[0]?.numero;
            this.objetoEndereco.bairro = response.dadosCpf?.enderecos[0]?.bairro;
            this.objetoEndereco.uf = response.dadosCpf?.enderecos[0]?.uf;
          }
          if (response.dadosCnpj.cnpj) {
            this.infoCadastrais = response.dadosCnpj;
            this.objetoEndereco.endereco = response.dadosCnpj.logradouro;
            this.objetoEndereco.numero = response.dadosCnpj.numero;
            this.objetoEndereco.bairro = response.dadosCnpj.bairro;
            this.objetoEndereco.uf = response.dadosCnpj.uf;
          }
          var enderecoCompleto = `${this.objetoEndereco.endereco + '+' + this.objetoEndereco.numero + '+' + this.objetoEndereco?.bairro + '+' + this.objetoEndereco.cidade + '+' + this.objetoEndereco.uf}`;
          this.montaurlEndereco(enderecoCompleto)

          // subscription.unsubscribe(); // Interrompe o intervalo após uma resposta bem-sucedida
        }
      },
      error => {
        if (error.status == 501) {
          this.isLoadingCadastrais = false;
          this.exibirDadosCadastrais = false;
        }
        this.toastr.warning(`Erro ao obter as informações cadastrais do pesquisado.`);
      }
    );
  }

  atualizarExibeAnaliseCredito(valor: boolean) {
    this.exibeAnaliseCredito = valor;
  }


  exportarClicked() {
    this.ui.loading();
    this.pesquisaService.exportar({ id: this.idPesquisa }, "processos").subscribe(data => {
      this.ui.loaded().subscribe(() => {
        let a = document.createElement("a");
        a.href = "data:application/pdf;base64," + data.arquivoConteudo;
        a.download = data.arquivoNome;
        a.click();
      });
    }, (err: any) => {
      this.ui.loaded().subscribe(() => {
        // this.openSnackBarWarning('Atenção', err.error);
      })
    });
  }

  async verificaStatusPesquisa(idPesquisa) {
    await this.atualizarStatusPesquisa(idPesquisa); // Chamada inicial à API
    this.verificarNovoStatus(idPesquisa);
  }

  async verificarNovoStatus(idPesquisa) {
    setTimeout(async () => {
      await this.atualizarStatusPesquisa(idPesquisa);
      if (this.statusPesquisa !== 4) {
        this.verificarNovoStatus(idPesquisa); // Chama novamente se o status não for 4
      } else {
        this.isLoadingPdf = false;
      }
    }, 10000);
  }

  async atualizarStatusPesquisa(idPesquisa) {
    try {
      const response = await this.pesquisaService.getStatusPesquisa(idPesquisa).toPromise();
      this.porcentagemPesquisa = response.procentagem;
      this.statusPesquisa = response.status;

      // Verifica se o status já é 4 e encerra o loop
      if (this.statusPesquisa === 4) {
        this.isLoadingPdf = false;
      }
    } catch (error) {
      this.isLoadingPdf = true;
      console.error('Erro ao obter status da pesquisa:', error);
    }
  }


  getCorPorcentagem(porcentagem: number): string {
    const corDefault = '#c5c5c5'; // Azul
    const corPercentual = '#00ccffdd'; // Verde

    if (porcentagem === undefined || isNaN(porcentagem)) {
      return corDefault;
    }

    const cor1Percentual = porcentagem;
    const cor2Percentual = 100 - porcentagem;
    return `linear-gradient(to right, ${corPercentual} ${cor1Percentual}%, ${corDefault} ${cor2Percentual}%)`;
  }


}

