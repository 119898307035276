<mat-card class="pesq-info" *ngIf="!ocultarListagem">
  <mat-card-header class="title-pesq">
    <img class="img-title-pesq" src="../../../assets/icones/lupa-azul.svg" alt="view">
    <p>Precificador de Credito</p>
  </mat-card-header>

  
  <div class="d-flex justify-content-end">
    <button mat-flat-button class="gs-botaoAzulPadrao m-3" (click)="iniciarPrecificacao()">Iniciar Precificação</button>
  </div>
</mat-card>

<mat-card class="pesq-info-content" [ngClass]="!ocultarListagem ? 'listagem' : ''">
    <mat-tab-group [selectedIndex]="selected.value" (selectedIndexChange)="selected.setValue($event)">
        <mat-tab label="Listagem" *ngIf="!ocultarListagem">
          <app-precificador-listagem></app-precificador-listagem>
        </mat-tab>

        <mat-tab label="Precificador" *ngIf="abrirPrecificador">
            <app-precificador-wizard></app-precificador-wizard>
          </mat-tab>
      </mat-tab-group>
</mat-card>