import { Component, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';



@Component({
  selector: 'ngx-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss'],
})
export class NgxAuthComponent implements OnDestroy {

//   <div class="layout">
//   <div class="layout-column bg-login">
//     <div class="card">
//       <div class="card-header">
//         <!--<img src="/assets/images/credvalue-portal-tarja.png" class="login-top"/>
//         <img src="/assets/images/iconesTT.png" class="icons-top"/>-->
//           <br/>
//           <!--<span class="text-top">PESQUISA PATRIMONIAL E AN&Aacute;LISE DE CR&Eacute;DITO</span>-->
//       </div>
//       <div class="card-body">
//         <div class="auth-block">
//           <router-outlet></router-outlet>
//         </div>
//       </div>
//     </div>
//   </div>
// </div>
  private alive = true;

  subscription: any;

  authenticated: boolean = false;
  token: string = '';

  // showcase of how to use the onAuthenticationChange method
  constructor(protected location: Location) {

    // this.subscription = auth.onAuthenticationChange()
    //   .pipe(takeWhile(() => this.alive))
    //   .subscribe((authenticated: boolean) => {
    //     this.authenticated = authenticated;
    //   });
  }

  back() {
    this.location.back();
    return false;
  }

  ngOnDestroy(): void {
    this.alive = false;
  }
}
