import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { DashboardService } from 'src/app/shared/services/dashboard/dashboard-service';
import { UiService } from 'src/app/shared/services/ui.service';

const today = new Date();
const month = today.getMonth();
const year = today.getFullYear();

@Component({
  selector: 'app-relatorio-uso',
  templateUrl: './relatorio-uso.component.html',
  styleUrls: ['./relatorio-uso.component.scss']
})
export class RelatorioUsoComponent implements OnInit {
  @Input() numeroIdCliente : any;
  
  campaignOne = new FormGroup({
    start: new FormControl(new Date(year, month, 13)),
    end: new FormControl(new Date(year, month, 16)),
  });
  campaignTwo = new FormGroup({
    start: new FormControl(new Date(year, month, 15)),
    end: new FormControl(new Date(year, month, 19)),
  });
  formFiltro: any = { page: 1 };
  isLoading = false;
  constructor(
    private dashboardService: DashboardService,
    private ui: UiService

  ) { }

  ngOnInit(): void {
    this.formFiltro.cadastroDe = this.subtractMonths(new Date(), 1);
    this.formFiltro.cadastroAte = new Date((new Date()))
  }

  subtractMonths(date, months) {
    date.setMonth(date.getMonth() - months);

    return date;
  }

  gerarRelatorio() {
    const obj = {
      IdCliente: this.numeroIdCliente,
      cadastroDe: this.formFiltro.cadastroDe,
      cadastroAte: this.formFiltro.cadastroAte
    }
    this.isLoading = true
    this.dashboardService.exportarRelatorioPesquisasClientes(obj).subscribe(data => {
      this.isLoading = false
      var a = document.createElement("a");
      a.href = "data:application/pdf;base64," + data.arquivoConteudo;
      a.download = data.arquivoNome;
      a.click();
      this.ui.success('Relatorio Gerado com Sucesso!') 
    });
  }

}
