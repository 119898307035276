import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, catchError, tap, throwError } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { FormaPagamento } from '../../enums/forma-pagamento.enum';
import { ToastrService } from 'ngx-toastr';

@Injectable({
    providedIn: 'root'
})
export class PesquisaService {

    private baseUrl = environment.apiUrl + "/pesquisa";
    private tagsUrl = environment.apiUrl + "/pesquisa-tags";
    private cruzamntoUrl = environment.apiUrl + "/cruzamento-dados"

    constructor(public http: HttpClient, private toastr: ToastrService,) { }

    cadastrarCliente(item:any): Observable<any> {
        return this.http.post(`${this.baseUrl}/cadastrar-cliente`, item);
    }

    atualizar(item:any): Observable<any> {
        return this.http.post(`${this.baseUrl}/atualizar`, item);
    }

    exportar(item:any, produto: string): Observable<any> {

        return this.http.post(`${this.baseUrl}/exportar-relatorio-cliente`, item, { headers: { "Produto": produto }});
    }

    exportarLGPD(item:any, produto: string): Observable<any> {

        return this.http.post(`${this.baseUrl}/exportar-relatorio-interno`, item, { headers: { "Produto": produto }});
    }

    exportarRecibo(item:any, produto: string): Observable<any> {
        return this.http.post(`${this.baseUrl}/exportar-relatorio-recibo-cliente`, item, { headers: { "Produto": produto }});
    }

    exportarExcel(item:any, produto: string): Observable<any> {
        return this.http.post(`${this.baseUrl}/exportar-relatorio-excel-cliente`, item, { headers: { "Produto": produto }});
    }

    exportarExcelLote(item:any): Observable<any> {
        return this.http.post(`${this.baseUrl}/exportar`, item);
    }

    listPesquisaCliente(filter: any): Observable<any> {
        return this.http.post(`${this.baseUrl}/pesquisar/cliente`, filter);
    }

    getPesquisaRealizada(filter: any, produto: string): Observable<any> {
        return this.http.post(`${this.baseUrl}/pesquisar/realizada`, filter, { headers: { "Produto": produto }});
    }

    getCruzamentoRealizado(filter: any, produto: string): Observable<any> {
        return this.http.post(`${this.cruzamntoUrl}/pesquisar/realizadas`, filter, { headers: { "Produto": produto }});
    }

    get(id: number): Observable<any> {
        return this.http.get(`${this.baseUrl}/${id}`);
    }

    getStatusDetalhado(id: number): Observable<any> {
        return this.http.get(`${this.baseUrl}/${id}/status-detalhado`);
    }

    cadastrarTagId(item): Observable<any> {
        return this.http.post(`${this.tagsUrl}/cadastra-tag`, item);
    }
    
    getTagID(id): Observable<any> {
        return this.http.get(`${this.tagsUrl}/busca-tags?clienteId=${id}`);
    }

    excluirTag(id): Observable<any> {
        return this.http.delete(`${this.tagsUrl}/excluir-tag?clienteTagId=${id}`);
    }

    editarTag(item): Observable<any> {
        return this.http.post(`${this.tagsUrl}/incluir-tag-pesquisa`, item);
    }

    getDadosCadastraisPesquisa(idPesquisa): Observable<any> {
        return this.http.get(`${environment.apiUrl}/dados-cadastrais/Buscar?Id=${idPesquisa}`);
    }

    getSinteseProcesso(idPesquisa): Observable<any> {
        return this.http.get(`${environment.apiUrl}/sintese-processos?Id=${idPesquisa}`);
    }

    getSinteseProcessoPorAno(idPesquisa): Observable<any> {
        return this.http.get(`${environment.apiUrl}/sintese-processos/por-ano?Id=${idPesquisa}`);
    }

    getConsultaCadastralEmail(documento, dataNasc) : Observable<any>{
        return this.http.get(`${environment.apiUrl}/dados-cadastrais/Consultar?Numero=${documento}&MapearEmail=true&dataNascimento=${dataNasc}`);
    }

    getConsultaCadastralTelefone(documento, dataNasc) : Observable<any>{
        return this.http.get(`${environment.apiUrl}/dados-cadastrais/Consultar?Numero=${documento}&MapearTelefone=true&dataNascimento=${dataNasc}`);
    }

    getConsultaCadastralEndereco(documento, dataNasc) : Observable<any>{
        return this.http.get(`${environment.apiUrl}/dados-cadastrais/Consultar?Numero=${documento}&MapearEndereco=true&dataNascimento=${dataNasc}`);
    }

    getConsultaCadastralGeral(documento, dataNasc) : Observable<any>{
        return this.http.get(`${environment.apiUrl}/dados-cadastrais/Consultar?Numero=${documento}&MapearEmail=true&MapearEndereco=true&MapearTelefone=true&dataNascimento=${dataNasc}`);
    }

    getSinteseCredito(idPesquisa) : Observable<any>{
        return this.http.get(`${environment.apiUrl}/analise-credito/sintese?Id=${idPesquisa}`);
    }

    getCadastralCreditoNovaPesquisa(documento) : Observable<any>{
        return this.http.get(`${environment.apiUrl}/analise-credito/consultar?Numero=${documento}`); 
    }

    getCadastralAnaliseCredito(idPesquisa): Observable<any>{
        return this.http.get(`${environment.apiUrl}/analise-credito/buscar?Id=${idPesquisa}`);
    }

    getStatusPesquisa(idPesquisa): Observable<any>{
        return this.http.get(`${this.baseUrl}/porcentagem-pesquisa?idPesquisa=${idPesquisa}`);
    }

    getMidiasNegativas(idPesquisa): Observable<any>{
        return this.http.get(`${environment.apiUrl}/midia-social/midias-negativas?id=${idPesquisa}`);
    }

    getPessoasVips(idPesquisa): Observable<any>{
        return this.http.get(`${environment.apiUrl}/midia-social/pessoas-vips?id=${idPesquisa}`);
    }

    preCadastro(item:any): Observable<any> {
        return this.http.post(`${this.baseUrl}/pre-cadastro`, item);
    }

    cadastrarClienteV2(item:any): Observable<any> {
        return this.http.post(`${this.baseUrl}/cadastrar-clienteV2`, item);
    }

    cadastrarPesquisaLote(item:any): Observable<any> {
        return this.http.post(`${environment.apiUrl}/pesquisas-em-massa/cadastrar`, item);
    }

    getAnaliseCreditoCompleto(idPesquisa): Observable<any>{
        return this.http.get(`${environment.apiUrl}/analise-credito/dados?Id=${idPesquisa}`);
    }

    getVeiculos(idPesquisa): Observable<any>{
        return this.http.get(`${environment.apiUrl}/patrimonial/veiculos?Id=${idPesquisa}`);
    }

    getRegistradoresQualificada(idPesquisa:number, fonte:any): Observable<any>{
        return this.http.get(`${environment.apiUrl}/patrimonial/registradoresQf?Id=${idPesquisa}&Fonte=${fonte}`);
    }

    getEscrituraEProcuracoes(idPesquisa:number, fonte:any): Observable<any>{
        return this.http.get(`${environment.apiUrl}/documentos/escritura-procuracoes?Id=${idPesquisa}&Fonte=${fonte}`);
    }

    getRegistradores(idPesquisa:number, fonte:any): Observable<any>{
        return this.http.get(`${environment.apiUrl}/patrimonial/registradores?Id=${idPesquisa}&Fonte=${fonte}`);
    }

    getMinerios(idPesquisa): Observable<any>{
        return this.http.get(`${environment.apiUrl}/patrimonial/minerios?Id=${idPesquisa}`);
    }

    getImovelRequerimento(idPesquisa): Observable<any>{
        return this.http.get(`${environment.apiUrl}/patrimonial/imovel-requerimento?Id=${idPesquisa}`);
    }

    getImovelParcela(idPesquisa): Observable<any>{
        return this.http.get(`${environment.apiUrl}/patrimonial/imovel-parcela?Id=${idPesquisa}`);
    }

    getImovelSerpro(idPesquisa): Observable<any>{
        return this.http.get(`${environment.apiUrl}/patrimonial/imovel-serpro?Id=${idPesquisa}`);
    }

    getAeronave(idPesquisa): Observable<any>{
        return this.http.get(`${environment.apiUrl}/patrimonial/aeronave?Id=${idPesquisa}`);
    }

    getEmbarcacoes(idPesquisa): Observable<any>{
        return this.http.get(`${environment.apiUrl}/patrimonial/embarcacoes?Id=${idPesquisa}`);
    }

    getSafra(idPesquisa): Observable<any>{
        return this.http.get(`${environment.apiUrl}/patrimonial/safra?Id=${idPesquisa}`);
    }

    getImovelUniao(idPesquisa): Observable<any>{
        return this.http.get(`${environment.apiUrl}/patrimonial/imovel-uniao?Id=${idPesquisa}`);
    }

    // filtro processos

    getProcessoTipoAcao(idCliente): Observable<any>{
        return this.http.get(`${this.baseUrl}/processos-tipo-acao?Id=${idCliente}`);
    }

    getProcessoStatus(idCliente): Observable<any>{
        return this.http.get(`${this.baseUrl}/processos-status?Id=${idCliente}`);
    }

    cadastrarCruzamentoDados(item:any): Observable<any> {
        return this.http.post(`${environment.apiUrl}/cruzamento-dados/cadastrar`, item);
    }

    listaCruzamentoDados(filter: any): Observable<any> {
        return this.http.post(`${environment.apiUrl}/cruzamento-dados/pesquisar/cruzamento`, filter);
    }

    getTelefonesCruzamento(idPesquisa): Observable<any>{
        return this.http.get(`${environment.apiUrl}/cruzamento-dados/listar-telefones?Id=${idPesquisa}`);
    }

    getEmailsCruzamento(idPesquisa): Observable<any>{
        return this.http.get(`${environment.apiUrl}/cruzamento-dados/listar-emails?Id=${idPesquisa}`);
    }

    getEnderecosCruzamento(idPesquisa): Observable<any>{
        return this.http.get(`${environment.apiUrl}/cruzamento-dados/listar-enderecos?Id=${idPesquisa}`);
    }

    getProcessosCruzamento(idPesquisa): Observable<any>{
        return this.http.get(`${environment.apiUrl}/cruzamento-dados/listar-processos?Id=${idPesquisa}`);
    }

    getInfoCruzamento(idPesquisa): Observable<any>{
        return this.http.get(`${environment.apiUrl}/cruzamento-dados?Id=${idPesquisa}`);
    }

    getDadosCruzados(idPesquisa: number, limit: number, offset: number): Observable<any> {
        return this.http.get(`${environment.apiUrl}/cruzamento-dados/cruzamentos/${idPesquisa}/?Limit=${limit}&OffSet=${offset}`);
    }    
      
    getDadosCruzadosOrigem(idPesquisa: number, limit: number, offset: number): Observable<any> {
        return this.http.get(`${environment.apiUrl}/cruzamento-dados/cruzamentos/${idPesquisa}/origens?Limit=${limit}&OffSet=${offset}`);
      } 

    getEmailsListagem(idPesquisa, filtro, offset, limit, segNivel): Observable<any>{
        return this.http.get(`${environment.apiUrl}/cruzamento-dados/listar-emails?Id=${idPesquisa}&termoBusca=${filtro}&OffSet=${offset}&Limit=${limit}&segundonivel=${segNivel}`);
    }

    getEnderecosListagem(idPesquisa, filtro, offset, limit, segNivel): Observable<any>{
        return this.http.get(`${environment.apiUrl}/cruzamento-dados/listar-enderecos?Id=${idPesquisa}&termoBusca=${filtro}&OffSet=${offset}&Limit=${limit}&segundonivel=${segNivel}`);
    }

    getTelefonesListagem(idPesquisa, filtro, offset, limit, segNivel): Observable<any>{
        return this.http.get(`${environment.apiUrl}/cruzamento-dados/listar-telefones?Id=${idPesquisa}&termoBusca=${filtro}&OffSet=${offset}&Limit=${limit}&segundonivel=${segNivel}`);
    }

    getProcessosListagem(idPesquisa, filtro, offset, limit, segNivel): Observable<any>{
        return this.http.get(`${environment.apiUrl}/cruzamento-dados/listar-processos?Id=${idPesquisa}&termoBusca=${filtro}&OffSet=${offset}&Limit=${limit}&segundonivel=${segNivel}`);
    }

    getNomesListagem(idPesquisa, filtro, offset, limit, segNivel): Observable<any>{
        return this.http.get(`${environment.apiUrl}/cruzamento-dados/listar-nomes?Id=${idPesquisa}&termoBusca=${filtro}&OffSet=${offset}&Limit=${limit}&segundonivel=${segNivel}`);
    }

    cadastrarFiltros(item:any): Observable<any> {
        return this.http.post(`${environment.apiUrl}/cruzamento-dados/cadastrar-filtros`, item);
    }

    getFiltrosPreenchidos(idPesquisa): Observable<any>{
        return this.http.get(`${environment.apiUrl}/cruzamento-dados?Id=${idPesquisa}`);
    }
}

