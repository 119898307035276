<div class="main">
  <mat-card class="container-teia mat-elevation-z8 teia">
    <mat-card-header class="header-teia">
      <div class="container">
        <div class="row">
          <div class=" div col-2">
            <div class="logo-box line-height box">
              <img (click)="backRoute()" src="../assets/img/LOGO-DETECH.svg" alt="Cred value"
                class="logo-header img-fluid cursor-pointer">
            </div>
          </div>
          <div class="div col-3">
            <div class="box">
              <h1 class="title-menu"> Gráfico de Pessoas Relacionadas</h1>
            </div>
          </div>
          <div class="div col-4">
            <div class="box">
              <form [formGroup]="teiaForm">
                <mat-form-field class="example-full-width teia-form" appearance="outline">
                  <input matInput mask="CPF_CNPJ" formControlName="documento" name="documento"
                    placeholder="Digite o CPF ou CNPJ" (keyup.enter)="buscarDocumento()">
                </mat-form-field>
              </form>
              <button mat-flat-button [disabled]="!desabilitaPesquisa()" (click)="buscarDocumento()"
                class="gs-botaoAzulPadrao iniciar">Iniciar
                <mat-icon>search</mat-icon></button>
            </div>
          </div>
          <div class="col-2 div-none">
            <div class="box box-button">
              <div class="btn-group">
                <button type="button" class="btn gs-botaoAzulPadrao sair dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  Menu
                </button>
                <div class="dropdown-menu">
                  <div class="d-flex flex-column justify-content-center username text-center">
                    <p class="userLogged">opções da teia</p>
                  </div>
                  <div class="dropdown-submenu">
                    <button class="dropdown-item dropdown-toggle" [disabled]="dadosGrafico.length == 0" (click)="toggleSubMenu($event)">Salvar PDF</button>
                    <div class="dropdown-menu">
                      <a class="dropdown-item" (click)="exportBaixaResolucao()">Baixa resolução</a>
                      <a class="dropdown-item" (click)="exportMediaResolucao()">Média resolução</a>
                      <a class="dropdown-item" (click)="exportAltaResolucao()">Alta resolução</a>
                    </div>
                  </div>
                  <button class="dropdown-item" [disabled]="arraySelected.length == 0 || arraySelected.length == 1" (click)="ocultarNosSelecionados()">Ocultar Nós Selecionados</button>
                  <button class="dropdown-item" [disabled]="dadosGrafico.length == 0" (click)="zerarPesquisa()">Limpar Pesquisa</button>
                  <button class="dropdown-item" [disabled]="true">Organização automática de nós</button>
                  <button class="dropdown-item" [disabled]="true">Camada Sócios</button>
                  <button class="dropdown-item" [disabled]="true">Camada Parentes</button>
                  <button class="dropdown-item" [disabled]="true">Camada Laranjas</button>
                  <button class="dropdown-item" [disabled]="dadosGrafico.length == 0" (click)="adicionarGrafico()">Adicionar mais um nó</button>
                  <button class="dropdown-item" [disabled]="dadosGrafico.length == 0" (click)="exportarCadastrais()">Exportar dados cadastrais</button>
                  <button class="dropdown-item" (click)="destacarDetech()">Destacar Detech</button>
                </div>
              </div>
              
              
              <!-- <button mat-flat-button class="gs-botaoAzulPadrao sair" [disabled]="dadosGrafico.length == 0"
                (click)="salvarNosGraph()">Salvar</button> -->
              <!-- <button mat-flat-button (click)="backRoute()" class="gs-botaoAzulPadrao sair">Sair</button> -->
            </div>
          </div>
          <div class=" div col-1 user">
            <div class="box d-flex justify-content-center flex-column align-items-center">
              <img src="../../../assets/img/icone-usuario.svg" alt="icone usuário" width="20px">
              <h2 class="title-menu username-header"> {{usuario?.nome}}</h2>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="d-flex justify-content-center">
        <div id="menuteste" #menuteste class="d-flex justify-content-center"></div>
      </div> -->
      <!-- <app-mensage-response style="float: right;"></app-mensage-response> -->
    </mat-card-header>
    <mat-card-content #ContainerRef class="d-flex justify-content-center content m-1">
      <!-- <iframe src="http://bsullins.github.io/got-images/" height="800px" width="80%" title="Iframe Example"></iframe> -->
      <mat-drawer-container class="example-container">
        <!-- <mat-drawer *ngIf="exibirDrawer" mode="side" opened class="sidebar d-flex flex-column p-3">
        <div class="d-flex flex-column">
          <div class="d-flex flex-column">
            <mat-checkbox name="panOnZoom" (change)="selecionarTodosOsNos($event)">{{selecionarNos == true ? 'Desselecionar Todos os Nos' : 'Selecionar Todos os Nos'}}</mat-checkbox>
            <button class="btn gs-botaoAzulPadrao m-1 ">Ocultar Nos Selecionados</button>
          </div>
          <hr>
          <div class="d-flex flex-column">
            <mat-checkbox name="panOnZoom" (change)="desbilitarPan($event)">Habilitar Janela de Selecao</mat-checkbox>
          </div>
          <button (click)="centralizar()" class="btn gs-botaoAzulPadrao m-1">Centralizar Grafico</button>
        </div>
      </mat-drawer> -->
        <!-- <app-janela-selecao #graficoSeletor [movimentoEnabled]="desabilitaPan" (selecaoChange)="onJanelaSelecaoChange($event)"> -->
        <ngx-graph #grafico [view]="[ContainerRef.offsetWidth, ContainerRef.offsetHeight]" [links]="links"
          [nodes]="nodes" [layout]="customLayout" id="element" [zoomLevel]="0.5" [panToNode$]="panToNode$">


          <ng-template #nodeTemplate let-node>
            <svg:g x="1%" y="1%" width="100" height="100" ngx-tooltip [tooltipPlacement]="'top'"
              [tooltipType]="'tooltip'" [tooltipTitle]="node.situacao" [style]="transformNode(node)"
              class="node nodesSelection" (dblclick)="openMenu(node, $event)" (click)="selecionarNo($event, node)">
              <svg:circle [attr.r]="node.nodeWidth" [attr.cx]="
              15" [attr.cy]="15" [attr.fill]="node.color" [attr.stroke]="node.stroke"
                [attr.stroke-dasharray]="node.dasharray" [attr.stroke-width]="node.strokeWidth" />
              <image class="main-img" [attr.width.px]="node.nodeWidth" [attr.height.px]="node.nodeWidth"
                [attr.xlink:href]="node.img" />

              <svg:text dominant-baseline="middle" [attr.width]="node.dimension.width"
                [attr.x]="node.key == node.value ? -(node.dimension.width - 10) : 40" [attr.y.px]="node.nodeWidth">
                {{node.label}}
              </svg:text>
              <svg:text dominant-baseline="middle" class="node-selection" [attr.width]="node.dimension.width"
                [attr.x]="node.key == node.value ? -(node.dimension.width - 10) : 40" [attr.y]="20">
                {{ (node?.isDocument) ? node.id : 'Sem documento' }}
              </svg:text>
            </svg:g>
          </ng-template>

          <ng-template #linkTemplate let-link>
            <svg:g class="edge">
              <svg:path class="line" [attr.height]="50" stroke-width="1.5" stroke='#b8b8b8' marker-end="url(#arrow)">
              </svg:path>
              <svg:text class="edge-label" text-anchor="middle">
                <textPath class="text-path" [attr.href]="'#' + link.id" class="text-path"
                  [style.dominant-baseline]="link.dominantBaseline" startOffset="70%">
                  {{link.label}}
                </textPath>
              </svg:text>
            </svg:g>
          </ng-template>

        </ngx-graph>
        <!-- </app-janela-selecao> -->
      </mat-drawer-container>
    </mat-card-content>
  </mat-card>

  <div class="d-flex flex-column justify-content-center d-none aviso">
    <div class="d-flex justify-content-center flex-column align-items-center centralizar">
      <img src="../../../assets/img/logo-detech-cinza.svg" alt="logo Detech">
      <div class="box-aviso text-center p-5 mt-3">
        <p>Aplicação não disponivel para dispositivos mobile e tablets.</p>
      </div>
      <button mat-flat-button class="gs-botaoAzulPadrao" (click)="backRoute()">
        Voltar ao Portal
      </button>
    </div>
  </div>
</div>
