import { Component, Input, OnInit } from '@angular/core';
import { takeWhile, timer } from 'rxjs';
import { PesquisaService } from 'src/app/shared/services/http/pesquisa.service';

@Component({
  selector: 'app-trabalho-escravo',
  templateUrl: './trabalho-escravo.component.html',
  styleUrls: ['./trabalho-escravo.component.scss']
})
export class TrabalhoEscravoComponent implements OnInit {
  @Input() idPesquisa: number;
  @Input() aba: any;


  dataTrabalhoEscravo;
  isLoadingTrabalhoEscravo = false;
  exibeTrabalhoEscravo = false;
  exibeMensagemTrabalhoEscravo =  false;
  exibeReprocessarTrabalhoEscravo = false;

  panelOpenState = false


  constructor(
      private pesquisaService: PesquisaService
  ) { }

  ngOnInit(): void {
     this.getTrabalhoEscravo(this.idPesquisa)
  }

  getTrabalhoEscravo(idPesquisa) {
    this.isLoadingTrabalhoEscravo = true;
    this.exibeMensagemTrabalhoEscravo = false;
    this.exibeReprocessarTrabalhoEscravo = false;
    let attempts = 0;
  
    const subscription = timer(0, 15000).pipe(
      takeWhile(() => attempts < 5) // Limita a 5 tentativas
    ).subscribe(() => {
      this.pesquisaService.getTrabalhoEscravo(idPesquisa).subscribe(
        response => {
          this.dataTrabalhoEscravo = response;
          switch (response.codigo) {
            case 4000:
              this.exibeTrabalhoEscravo = false;
              this.isLoadingTrabalhoEscravo = false;
              subscription.unsubscribe();
              break;
            case 1000:
              this.exibeTrabalhoEscravo = true;
              this.isLoadingTrabalhoEscravo = false;
              this.exibeMensagemTrabalhoEscravo = false;
              subscription.unsubscribe();
              break;
            case 2000:
                this.exibeTrabalhoEscravo = true;
                this.isLoadingTrabalhoEscravo = false;
                subscription.unsubscribe();
                break;
            case 3001:
              this.exibeTrabalhoEscravo = true;
              this.isLoadingTrabalhoEscravo = false;
              this.exibeMensagemTrabalhoEscravo = true;
              subscription.unsubscribe();
              break;
            case 3000:
              this.exibeTrabalhoEscravo = true;
              this.isLoadingTrabalhoEscravo = false;
              this.exibeMensagemTrabalhoEscravo = true;
              attempts++;
              if (attempts >= 5) {
                this.exibeReprocessarTrabalhoEscravo = true;
                subscription.unsubscribe();
              }
              break;
          }
        },
        error => {
          attempts++;
          if (attempts >= 5) {
            this.exibeTrabalhoEscravo = false;
            this.isLoadingTrabalhoEscravo = false;
            subscription.unsubscribe(); // Interrompe o intervalo após 5 tentativas
          }
        }
      );
    });
  }  
}
