<div class="container">
    <form>
        <div class="col-md d-flex justify-content-center flex-column">
            <mat-form-field appearance="outline">
              <mat-label>Selecione o Produto</mat-label>
              <mat-select [(ngModel)]="selectedFonte" name="idFonteGrupo" (ngModelChange)="grupoFonteSelection()" >
                <mat-option *ngFor="let u of grupoFontes"  [value]="u.fonteGrupo.id">{{ u.fonteGrupo.nome }}</mat-option>
              </mat-select>
            </mat-form-field>

            <div class="parametros-content mt-2">
                <ng-container *ngFor="let u of listaConfiguracoesParametros">
                    <mat-checkbox *ngIf="u.tipoFormatacao == 'boolean' && u.idParametro == 'FATURAMENTO'"
                     [value]="u" [checked]="u.selecionado" (change)="selecionarParametros($event, u)"
                     [disabled]="tipoDocumento != u.tipoDocumento">
                        {{ u.descricao }}
                    </mat-checkbox>
                </ng-container>
            </div>
        </div>
        <footer>
            <div class="footer-buttons">
                <button class="btn btn-primary btn-cancel" mat-dialog-close>Cancelar</button>
                <button class="btn btn-primary btn-go" (click)='confirmarPesquisa()'>Realizar Pesquisa</button>
            </div>
        </footer>
    </form>
</div>