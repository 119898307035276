<div class="text-center">
    <div class="header d-flex justify-content-between">
        <div></div>
        <h3 class="mt-1">Adicionar nó</h3>
        <mat-icon aria-label="close dialog" mat-dialog-close>close</mat-icon>
    </div>
    <div>

    </div>
    <div class="d flex justify-content-center">
        <mat-form-field class="example-full-width teia-form" appearance="outline">
            <input matInput mask="CPF_CNPJ" [(ngModel)]="documento" placeholder="Digite o CPF ou CNPJ">
        </mat-form-field>

        <button mat-flat-button (click)="getNovoRelacionamento(documento, true)"
            class="gs-botaoAzulPadrao iniciar">Adicionar
            <mat-icon>search</mat-icon>
        </button>
    </div>
</div>