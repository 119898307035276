import { HttpStatusCode } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Data } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MensageResponseComponent } from 'src/app/components/view/mensage-response/mensage-response.component';
import { ProdutosEnum } from 'src/app/shared/enums/produtos.enum';
import { PesquisaService } from 'src/app/shared/services/http/pesquisa.service';
import { UtilService } from 'src/app/shared/services/http/util.service';
import { UiService } from 'src/app/shared/services/ui.service';
import { Textos } from 'src/app/shared/textos';

@Component({
  selector: 'app-modal-grupo-pesquisa',
  templateUrl: './modal-grupo-pesquisa.component.html',
  styleUrls: ['./modal-grupo-pesquisa.component.scss']
})
export class ModalGrupoPesquisaComponent implements OnInit {
  grupoFontes;
  idFonteGrupo;
  selectedFonte;
  nome;
  listaConfiguracoesParametros
  parametrosToRequest = {}
  objValorAdicional
  parametrosSelecionados = [];
  tipoDocumento;
  parametrosAdicionaisToRequest: any = {};
  formParametros = [];

  constructor(
    private ui: UiService,
    private toastr: ToastrService,
    private utilService: UtilService,
    private pesquisaService: PesquisaService,
    private _snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<ModalGrupoPesquisaComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Data,
  ) { }

  ngOnInit(): void {
    this.verificaTipoDocumento()
    this.grupoFontes = this.data.grupos
    // console.log(this.grupoFontes)
    this.listaConfiguracoesParametros = this.grupoFontes.map(x => x.fonteGrupo?.listaFonteGrupoItem);

   this.listaConfiguracoesParametros = this.listaConfiguracoesParametros[0].map(x => x.fonteConfiguracao?.configuracaoParametros)
    this.listaConfiguracoesParametros = this.listaConfiguracoesParametros[0].filter(x => x.selecionavel ==  true)
    console.log(this.listaConfiguracoesParametros)

   this.selectedFonte = this.data.grupos[0].fonteGrupo.id
  }

  grupoFonteSelection(){
    this.idFonteGrupo = this.selectedFonte
    console.log(this.selectedFonte)
  }

   // Método para selecionar ou desmarcar parâmetros adicionais
   selecionarParametros(event: MatCheckboxChange, value: any) {
    value.selecionado = event.checked;
    const objSelecionado = { [value.descricao]: event.checked };
    if (event.checked) {
      this.parametrosAdicionaisToRequest = { ...this.parametrosAdicionaisToRequest, ...objSelecionado };
      this.parametrosSelecionados.push(value);
    } else {
      const { [value.descricao]: omit, ...rest } = this.parametrosAdicionaisToRequest;
      this.parametrosAdicionaisToRequest = { ...rest };
      const indice = this.parametrosSelecionados.indexOf(value);
      if (indice > -1) {
        this.parametrosSelecionados.splice(indice, 1);
      }
    }
  }

  confirmarPesquisa(){
    if (this.parametrosSelecionados.length > 0) {
      this.iniciarPesquisaComParametrosOuAdicionais();
    }

    if(this.parametrosSelecionados.length == 0){
      this.realizarPesquisa()
    }
  }

  getParametrosToRequest() {
    return {
      ...this.parametrosAdicionaisToRequest
    };
  }

  iniciarPesquisaComParametrosOuAdicionais(){
    if (typeof this.formParametros === 'object' && Object.keys(this.formParametros).length > 0 || this.parametrosToRequest) {
      this.parametrosToRequest = { ...this.parametrosToRequest, ...this.formParametros };
    } else if (!this.parametrosToRequest) {
      this.parametrosToRequest = {};
    }
    this.parametrosToRequest = this.getParametrosToRequest()

    this.objValorAdicional = {
      filtros: {},
      parametros: this.parametrosToRequest,
      fontesAdicionais: []
    };
    
    this.realizarPesquisa()
  }

  realizarPesquisa(){
    this.ui.loading()
       // verifica o tipo do documento 
    let tipoDocumento = this.data.doc.replaceAll('.','').replaceAll('-','').replaceAll('/','');
    if(tipoDocumento.length == 11){
      tipoDocumento = 1
    }
    if(tipoDocumento.length == 14){
      tipoDocumento = 2
    }
    
    const request = {
      nome: '',
      notificacao: true,
      numero: this.data.doc.replaceAll('.','').replaceAll('-','').replaceAll('/',''),
      tipo: tipoDocumento,
      valorAdicional: this.objValorAdicional
    }

    this.utilService.resolverNome(request, this.data.header).subscribe(resultadoResolverNome => {
      if(!resultadoResolverNome || !resultadoResolverNome.nome) {
          this.ui.loaded().subscribe(() => {
              this.nome = "";
              this.toastr.warning(Textos.NOME_NAO_ENCONTRADO, "Portal Credvalue");
              this.dialogRef.close()
          });
          return;
      }
       const requestPesquisa = {
        idFonteGrupo: this.selectedFonte,
        nome: resultadoResolverNome.nome,
        notificacao: true,
        numero: this.data.doc.replaceAll('.','').replaceAll('-','').replaceAll('/',''),
        tipo: tipoDocumento,
        valorAdicional: this.objValorAdicional
      }
       this.pesquisaService.cadastrarClienteV2(requestPesquisa, this.data.header).subscribe(data => {
        if (HttpStatusCode.Ok) {
          this.ui.loaded().subscribe(() => {
            if(this.data.idProduto == ProdutosEnum.Cadastrais){
              this.openSnackBar('Sucesso', 'Pesquisa Cadastral Lançada com Sucesso');
            }
            if(this.data.idProduto == ProdutosEnum.Processo){
              this.openSnackBar('Sucesso', 'Pesquisa de Processos Lançada com Sucesso');
            }
            if(this.data.idProduto == ProdutosEnum.Patrimonial){
              this.openSnackBar('Sucesso', 'Pesquisa Patrimonial Lançada com Sucesso');
            }
            if(this.data.idProduto == ProdutosEnum.Credito){
              this.openSnackBar('Sucesso', 'Pesquisa de Crédito Lançada com Sucesso');
            }
            if(this.data.idProduto == ProdutosEnum.Dossie){
              this.openSnackBar('Sucesso', 'Pesquisa de Dossiê Completo Lançada com Sucesso');
            }
            this.dialogRef.close()
          })
        }
      }, (err:any) => {
        this.ui.loaded().subscribe(() => {
          if(err.error){
            err.error = err.error.replace('One or more errors occurred.', '').replace('(', '').replace(')', '');
            this.toastr.info(err.error)
          }
          else{
            this.toastr.info('Ocorreu um erro ao realizar a pesquisa.')
          }
          this.dialogRef.close()
        })
      })
    });
  }

  openSnackBar(titulo: string, mensagem: string) {
    this._snackBar.openFromComponent(MensageResponseComponent, {
       data: {
         titulo: titulo,
         mensagem: mensagem,
       },
       duration: 5000,
       horizontalPosition: 'right',
       verticalPosition: 'top'
    });
   }

   verificaTipoDocumento() {
    let cnpj = 2
    let cpf = 1
    let novoTipoDocumento = null; // ou qualquer valor inicial que represente um tipo desconhecido
    if (this.data.doc.replaceAll('.','').replaceAll('-','').replaceAll('/','').length == 11) {
      novoTipoDocumento = cpf;
    }
    else if (this.data.doc.replaceAll('.','').replaceAll('-','').replaceAll('/','').length == 14) {
      novoTipoDocumento = cnpj;
    }
    if (novoTipoDocumento !== null && novoTipoDocumento !== this.tipoDocumento) {
      // Se o tipo de documento mudou, desmarca todos os checkboxes
    }

    this.tipoDocumento = novoTipoDocumento;
  }

}
