import { NgModule }              from '@angular/core';
import { RouterModule, Routes }  from '@angular/router';
import { AuthGuard } from './@auth/auth.guard';
import { PatrimonialComponent }  from './views/patrimonial/patrimonial.component';
import { ProcessoComponent }     from './views/processo/processo.component';
import { DashboardComponent }    from './views/dashBoard/dashboard.component';
import { CadastraisComponent }   from './views/cadastrais/cadastrais.component';
import { CreditoComponent } from './views/credito/credito.component';
import { DossieComponent } from './views/dossie/dossie.component';
import { CertidoesComponent } from './views/certidoes/certidoes.component';
import { HistoricoComponent } from './views/historico/historico.component';
import { TableResultComponent } from './components/view/table-result/table-result.component';
import { NotFoundComponent } from './views/not-found/not-found.component';
import { TeiaRelacionamentoComponent } from './views/teia-relacionamento/teia-relacionamento.component';
import { EncontrarDocComponent } from './views/encontrar-doc/encontrar-doc.component';
import { PrecificadorCreditoComponent } from './views/precificador-credito/precificador-credito.component';
import { CruzamentoDadosComponent } from './views/cruzamento-dados/cruzamento-dados.component';

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('./@auth/auth.module')
      .then(m => m.AuthModule),
  },
  {
    path: '',
    loadChildren: () => import('./@auth/auth.module')
      .then(m => m.AuthModule),
  },
  {
    path: "dashboard",
    component: DashboardComponent,
    canActivate: [AuthGuard]
    
  },
  {
    path: "cadastrais",
    component: CadastraisComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "processo",
    component: ProcessoComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "patrimonial",
    component: PatrimonialComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "credito",
    component: CreditoComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "dossie",
    component: DossieComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "cruzamento-dados",
    component: CruzamentoDadosComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "certidoes",
    component: CertidoesComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "historico",
    component: HistoricoComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "detech",
    component: TeiaRelacionamentoComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "localizador",
    component: EncontrarDocComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "precificador-credito",
    component: PrecificadorCreditoComponent,
    canActivate: [AuthGuard]
  },
  {
    path: '**', pathMatch: 'full', 
    component: NotFoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
