<div class="content-modalmenu">
    <div mat-dialog-title class="dialog-title">
        <h4>Documento: {{ data?.id?.length <= 18 ? data.id : 'sem documento' }}<mat-icon class="icon-title" (click)="copiar(data?.id)" matTooltip="Copiar">file_copy</mat-icon></h4>
        <button mat-icon-button aria-label="close dialog" mat-dialog-close>
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <ul>
        <li class="border-bottom2" (click)="expandirVinculos(data)" *ngIf="data.id.length <= 18"> Expandir vínculos societários </li>
        <li class="border-bottom2" (click)="openInformacoesAdicionais()" *ngIf="data.id.length <= 18"> Informações Adicionais </li> 
        <li class="border-bottom2"  (click)="expandirFiliais()" *ngIf="data.id.length <= 18"> Exibir Filiais</li> 
        <li class="border-bottom2" (click)="ocultarFiliaisNo()" *ngIf="data.id.length <= 18"> Ocultar Filiais</li> 
        <li class="border-bottom2"  (click)="ocultarElementos()"> Ocultar Nó</li> 
        <li class="border-bottom2"  (click)="adicionarNoCustomizado()" *ngIf="data.id.length <= 18"> Adicionar nó customizado </li> 
        <!--<li class="border-bottom2"  (click)="exibirElementos()"> Exibir nós ocultos</li> -->
        <li class="border-bottom2"  (click)="expandirPessoasRelacionadas()" *ngIf="data.id.length == 14"> Exibir Pessoas Relacionadas</li> 
        <li class="border-bottom2"  (click)="ocultarPessoasRelacionadasNo()" *ngIf="data.id.length == 14">Ocultar Pessoas Relacionadas </li> 
    </ul>
    
    <div mat-dialog-title class="dialog-title pt-1 pb-1" *ngIf="data.id.length <= 18">
        <h4>Pesquisas</h4>
    </div>
    <ul *ngIf="data.id.length <= 18">
        <li class="border-bottom2" (click)="verificaSeTemProdutoVinculado(1, 'cadastrais')"> Pesquisa de dados cadastrais </li>
        <li class="border-bottom2" (click)="verificaSeTemProdutoVinculado(2, 'processos')">Pesquisa de processos</li>
        <li class="border-bottom2" (click)="verificaSeTemProdutoVinculado(3, 'patrimonial')">Pesquisa patrimonial</li>
        <li class="border-bottom2" (click)="verificaSeTemProdutoVinculado(4, 'analise-credito')"> Score de crédito </li>
        <li class="border-bottom2" (click)="verificaSeTemProdutoVinculado(5, 'dossie-completo')"> Dossiê completo</li>
    </ul>    
</div>