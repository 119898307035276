import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Data } from '@angular/router';

@Component({
  selector: 'app-modal-dados',
  templateUrl: './modal-dados.component.html',
  styleUrls: ['./modal-dados.component.scss']
})
export class ModalDadosComponent implements OnInit {

  processosAtivosEmGrupos;
  processosPassivosEmGrupos; // Nova variável para os processos passivos

  constructor(
    public dialogRef: MatDialogRef<ModalDadosComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Data,
  ) { }

  ngOnInit(): void {
    console.log(this.data);

    // Processos Ativos
    if (this.data.tipoPesquisa === 4 && this.data.dados?.processos?.processosAtivo?.length > 0) {
      this.processosAtivosEmGrupos = this.chunkArray(this.data.dados.processos.processosAtivo, 3);
    }

    // Processos Passivos
    if (this.data.tipoPesquisa === 4 && this.data.dados?.processos?.processosPassivo?.length > 0) {
      this.processosPassivosEmGrupos = this.chunkArray(this.data.dados.processos.processosPassivo, 3);
    }
  }

  chunkArray(myArray: any[], chunk_size: number) {
    let index = 0;
    const arrayLength = myArray.length;
    const tempArray = [];

    for (index = 0; index < arrayLength; index += chunk_size) {
      const chunk = myArray.slice(index, index + chunk_size);
      tempArray.push(chunk);
    }

    return tempArray;
  }

}
