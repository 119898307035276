<mat-accordion *ngIf="exibeTrabalhoEscravo">
    <mat-expansion-panel class="mt-3 py-2" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <h3 class=" title-cordion mb-0 d-flex align-items-center">
            <mat-icon class="detech-icon">label_important</mat-icon>&nbsp; Restrição por trabalho análogo à escravidão
          </h3>
        </mat-panel-title>
  
      </mat-expansion-panel-header>
      <table class="table" *ngIf="!isLoadingTrabalhoEscravo && !exibeMensagemTrabalhoEscravo">
        <thead class="thead-light">
          <tr class="text-center">
            <th scope="col" class="thead-top-left p-2">Empregador</th>
            <th scope="col" class="thead p-2">Cpf / Cnpj</th>
            <th scope="col" class="thead p-2">UF</th>
            <th scope="col" class="thead p-2">Ano</th>
            <th scope="col" class="thead p-2">Decisão de Procedência</th>
            <th scope="col" class="thead-top-right p-2">Trabalhadores</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngIf="dataTrabalhoEscravo.dados?.length > 0; else noDataTrabalhoEscravo">
            <tr class="text-center table-info" *ngFor="let dados of dataTrabalhoEscravo.dados">
              <td>{{dados?.empregador}}</td>
              <td>{{dados?.cnpj_cpf}}</td>
              <td>{{dados?.uf}}</td>
              <td>{{dados?.ano_da_acao_fiscal}}</td>
              <td>{{dados?.decisao_administrativa_de_procedencia}}</td>
              <td>{{dados?.trabalhadores_envolvidos}}</td>
            </tr>
          </ng-container>
          <ng-template #noDataTrabalhoEscravo>
            <tr>
              <td colspan="6" class="text-center">Não há resultados disponíveis</td>
            </tr>
          </ng-template>
        </tbody>
      </table>
      <div class="d-flex justify-content-center text-center flex-column" *ngIf="!isLoadingTrabalhoEscravo && exibeMensagemTrabalhoEscravo">
        <div class="d-flex justify-content-end" *ngIf="exibeReprocessarTrabalhoEscravo">
          <button mat-flat-button class="gs-botaoAzulPadrao m-3" (click)="getTrabalhoEscravo(idPesquisa, 129)">Refresh <mat-icon>cached
          </mat-icon></button>
        </div>
        <div>
          <p>{{dataTrabalhoEscravo.mensagem}}</p>
        </div>
      </div>
       <loader-only *ngIf="isLoadingTrabalhoEscravo"></loader-only>
    </mat-expansion-panel>
  </mat-accordion>