import { Component, OnInit, Inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Data } from '@angular/router';
import { ModalConfirmacaoComponent } from '../modal-confirmacao/modal-confirmacao.component';
import { UiService } from 'src/app/shared/services/ui.service';
import { PesquisaService } from 'src/app/shared/services/http/pesquisa.service';
import { Toast, ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-modal-edicao-tag',
  templateUrl: './modal-edicao-tag.component.html',
  styleUrls: ['./modal-edicao-tag.component.scss']
})
export class ModalEdicaoTagComponent implements OnInit {

  clienteId;
  pesquisaId;
  myControl = new FormControl();
  tagIds = [];
  addedItems = [];
  filteredTags = []
  autoCompletePanel: any;
  labelTag: String = 'Tag';

  constructor(
    private ui: UiService,
    private pesquisaService: PesquisaService,
    public dialogRef: MatDialogRef<ModalEdicaoTagComponent>,
    public dialog: MatDialog,
    private toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: Data,
  ) { }

  ngOnInit(): void {
    console.log(this.data)
    this.filteredTags = this.data.tags.map(objeto => {
      return {
        tag: objeto.clienteTag.tag,
        tagId: objeto.clienteTag.id
      };
    });
    this.addedItems = this.filteredTags
    this.clienteId = this.data.clienteId
    this.pesquisaId = this.data.idPesquisa
    this.getChips();
  }

  addChip(value) {
    if (!this.addedItems.includes(value)) {
      this.addedItems.push(value);
      this.tagIds = this.tagIds.filter(i => i.tagId != value.tagId);
    }
    this.myControl.reset();
  }

  removeChip(item): void {
    const index = this.addedItems.indexOf(item);
    if (index !== -1) {
      this.addedItems.splice(index, 1);
      this.tagIds.push(item);
    }
  }

  enviarChips(value) {
    this.ui.loading();
    const obj = {
      clienteId: this.clienteId,
      tags: [{
        tagId: 0,
        tag: value
      }]
    }
    this.pesquisaService.cadastrarTagId(obj).subscribe(response => {
      this.ui.loaded().subscribe(() => {
        this.addedItems.push(response[0])
        // this.getChips()
      })
    }, (err:any) => {
      this.ui.loaded().subscribe(() => {
          this.toastr.error('Ocorreu um erro, tente novamente.')
      })
    });
  }

  onEnter(event: KeyboardEvent): void {
    if (document.activeElement === event.target) {
      event.preventDefault();

      const value = this.myControl.value.trim();
      const valueAdded = this.addedItems.map(x => x.tag)
      const valueGetted = this.tagIds.map(x => x.tag)
      if (value !== '' && !valueAdded.includes(value) && !valueGetted.includes(value)) {
        this.enviarChips(value)
      }

      this.myControl.reset();
    }
  }

  getChips() {
    this.ui.loading()
    this.pesquisaService.getTagID(this.clienteId).subscribe(response => {
      this.ui.loaded().subscribe(() => {
        this.tagIds = response.filter(objPrincipal =>
          !this.addedItems.some(objFiltrar => objFiltrar.tagId === objPrincipal.tagId)
        );
        console.log(this.tagIds);
        console.log(this.addedItems)
      })
    }, (err:any) => {
      this.ui.loaded().subscribe(() => {
          this.toastr.error('Ocorreu um erro, tente novamente.')
      })
    });
  }

  deletarTagExistente(value) {
    const dados = {
      title: '',
      message: 'Deseja realmente excluir esse item?',
      cancelText: 'Cancelar',
      confirmText: 'excluir'
    }
    const dialogRef = this.dialog.open(ModalConfirmacaoComponent, {
      maxWidth: "470px",
      data: dados
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult == true) {
        this.ui.loading()
        this.pesquisaService.excluirTag(value.tagId).subscribe(response => {
          this.ui.loaded().subscribe(() => {
            // this.tagIds = response
            this.toastr.info('Tag excluída com sucesso.', 'CredValue');
            this.tagIds = this.tagIds.filter(i => i.tagId != value.tagId);
            // this.getChips()
          })
        }, (err:any) => {
          this.ui.loaded().subscribe(() => {
              this.toastr.error('Ocorreu um erro, tente novamente.')
          })
        });
      }
    });

  }

  atualizarChips() {
    this.ui.loading();
    const obj = {
      ClienteId: this.clienteId,
      PesquisaId: this.pesquisaId,
      tags: this.addedItems
    }
    console.log(obj)
    this.pesquisaService.editarTag(obj).subscribe(response => {
      this.ui.loaded().subscribe(() => {
        this.dialogRef.close(true)
      })
    }, (err:any) => {
      this.ui.loaded().subscribe(() => {
          //this.toastr.error('Ocorreu um erro, tente novamente.');
          this.dialogRef.close(true);
      })
    });
  }

  enviarTagsRefazer() {
    const obj = {
      tags: this.addedItems
    }
    this.dialogRef.close(obj)
  }


}
