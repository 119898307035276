import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class DashboardService {

    private baseUrl = environment.apiUrl;

    constructor(public http: HttpClient) { }

    getVisaoProcessual(data:any): Observable<any> {
        return this.http.post(`${this.baseUrl}/dashboard-juridico`, data);
    }

    downloadAnexo(item): Observable<any> {
        return this.http.post(`${this.baseUrl}/cliente/download-anexo`, item);
    }
    anexos(id: number): Observable<any> {
        //return this.http.get(`${this.baseUrl}/cliente/anexos/${id}`);
        return this.http.get(`${this.baseUrl}/cliente/${id}/anexos`);
    }

    exportarRelatorioPesquisasClientes(item): Observable<any> {
        return this.http.post(`${this.baseUrl}/relatorios/exportar-relatorio-pesquisas-clientes`, item);
    }
}
