
export enum StatusPesquisa {
    Rascunho = 1,
    // AguardandoProcessamento = 2,
    ResolucaoAutomatica = 3,
    Finalizada = 4,
    ResolucaoManual = 5,
    Cancelada = 6,
    FinalizadaParcial = 7    

}