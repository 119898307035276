<mat-card class="pesq-re mt-3">
    <div class="title-pesq">
      <img class="img-title-pesq" src="../../../assets/icones/lupa-azul.svg" alt="view">
      <p>Resultados Encontrados</p>
    </div>

    <div id="accordionExample" style="overflow: scroll; height: 50vh;">
      <div class="accordion-item" *ngFor="let dados of retornoInfo; let i=index" style="margin: 10px 0;">
        <div class="container" *ngIf="retornoInfo.length >= 1">
          <div class="row m-1">
            <div class="d-flex justify-content-between">
              <div class="col d-flex align-items-center">
                <img class="avatar mat-elevation-z8" src="../assets/img/iconePessoaLocalizador.svg"
                  *ngIf="dados.cpfCnpj.length == 14" />
                <img class="avatar mat-elevation-z8" src="../assets/img/iconeEmpresaLocalizador.svg"
                  *ngIf="dados.cpfCnpj.length == 18" />
                <h3 class="nome-table">{{dados?.nomeRazao}}</h3>
              </div>
              <div class="dropdown">
                <button mat-flat-button class="gs-btn-vazado m-3 dropdown-toggle" type="button" id="dropdownMenuButton"
                  data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <mat-icon>search</mat-icon>
                  Gerar Pesquisa
                </button>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <a class="dropdown-item cursor-pointer"
                    (click)="verificaSeTemProdutoVinculado(1, dados.cpfCnpj)">Informações Cadastrais</a>
                  <a class="dropdown-item cursor-pointer"
                    (click)="verificaSeTemProdutoVinculado(2, dados.cpfCnpj)">Pesquisa de Processos</a>
                  <a class="dropdown-item cursor-pointer"
                    (click)="verificaSeTemProdutoVinculado(3, dados.cpfCnpj)">Pesquisa Patrimonial</a>
                  <a class="dropdown-item cursor-pointer"
                    (click)="verificaSeTemProdutoVinculado(4, dados.cpfCnpj)">Analise de Credito</a>
                  <a class="dropdown-item cursor-pointer"
                    (click)="verificaSeTemProdutoVinculado(5, dados.cpfCnpj)">Dossiê Completo</a>
                </div>
              </div>
            </div>
            <div class="col-12 d-flex mt-4">
              <div class="col-7">
                <p><b>Documento:</b> {{dados?.cpfCnpj}}</p>
                <p *ngIf="dados.cpfCnpj.length == 14 && dados.nomeMaeSocio != null"><b>Dta de Nascimento:</b> {{dados?.dataNascAbertura}}</p>
                <p *ngIf="formPesquisa.tipoParametro  == 'placa'"><b>Dta Ultima Atualizacao:</b> {{dados?.resultadoAdicionais?.movimentacao?.ultimaAtualizacao}}</p>
                <p *ngIf="dados.cpfCnpj.length == 18 && dados.nomeMaeSocio != null"><b>Dta de Abertura:</b> {{dados?.dataNascAbertura}}</p>
                <p *ngIf="dados.cpfCnpj.length == 14 && dados.nomeMaeSocio != null"><b>Nome da Mãe:</b> {{dados?.nomeMaeSocio}}</p>
                <p *ngIf="dados.cpfCnpj.length == 18 && dados.nomeMaeSocio != null"><b>Nome do Sócio:</b> {{dados?.nomeMaeSocio}}</p>

              </div>

              <div class="col-4">
                <p><b>Cidade:</b> {{dados?.cidade}}</p>
                <p><b>UF:</b> {{dados?.uf}}</p>
              </div>
            </div>

            <div class="col-12 d-flex mt-4" *ngIf="formPesquisa.tipoParametro  == 'placa'">
              <div class="col-7">
                <p><b>Placa:</b> {{dados?.resultadoAdicionais?.identificadores?.placa}}</p>
                <p><b>Chassi:</b> {{dados?.resultadoAdicionais?.identificadores?.chassi}}</p>
                <p><b>Renavam:</b> {{dados?.resultadoAdicionais?.identificadores?.renavam}}</p>
                <p><b>Numero Motor:</b> {{dados?.resultadoAdicionais?.identificadores?.numeroMotor}}</p>
                <p><b>Proprietario Anterior:</b> {{dados?.resultadoAdicionais?.movimentacao?.proprietarioAnterior}}</p>
                <p><b>Data Licenciamento:</b> {{dados?.resultadoAdicionais?.movimentacao?.dataLicenciamento}}</p>

              </div>

              <div class="col-4">
                <p><b>Situação:</b> {{dados.resultadoAdicionais?.movimentacao?.situacao}}</p>
                <p><b>Marca Modelo:</b> {{dados.resultadoAdicionais?.descricao?.marcaModelo}}</p>
                <p><b>Ano Fabricacao:</b> {{dados.resultadoAdicionais?.descricao?.anoFabricacao}}</p>
                <p><b>Cor:</b> {{dados?.resultadoAdicionais?.descricao?.cor}}</p>
                <!-- <p><b>Cor:</b> {{dados.resultadoAdicionais.descricao.cor}}</p> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <p class="d-flex justify-content-center align-items-center mt-5" *ngIf="retornoInfo.length == 0">Nenhum Resultado
        Encontrado</p>
    </div>
  </mat-card>