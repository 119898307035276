<div class="modal-header">
    <h4 class="modal-title">Essa ação terá um custo</h4>
</div>
<div class="modal-footer footer">
    <button value="1" type="button" class="btn btn-danger" mat-dialog-close>
        cancelar
    </button>
    <button  value="1" (click)="getNewTeia()" type="button" class="btn btn-success">
      confirmar
    </button>
</div>
