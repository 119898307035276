import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Data, Router } from '@angular/router';
import { PesquisaMenorDetech } from 'src/app/shared/enums/pesquisa-menor-detech.enum';
import { TeiaRelacionamentoService } from 'src/app/shared/services/teia-relacionamento/teia-relacionamento.service';
import { UiService } from 'src/app/shared/services/ui.service';

@Component({
  selector: 'app-modal-menor-idade',
  templateUrl: './modal-menor-idade.component.html',
  styleUrls: ['./modal-menor-idade.component.scss']
})
export class ModalMenorIdadeComponent implements OnInit {

  produto;
  dataNascimento;
  isDetech = false;
  exibirMensagemDataErrada = false;

  constructor(
    private route: Router,
    private activatedRoute: ActivatedRoute,
    public dialogRef: MatDialogRef<ModalMenorIdadeComponent>,
    private datePipe: DatePipe,
    private ui: UiService,
    private teiaRelacionamentoService: TeiaRelacionamentoService,
    @Inject(MAT_DIALOG_DATA) public data: Data,
  ) { }

  ngOnInit(): void {

    console.log(this.data)
    const url = this.route.url;
    if (url.includes('detech')) {
      this.produto = 'Detech';
      this.isDetech = true;
    }
    if (this.route.url == '/dossie') {
      this.produto = 'Dossiê Completo'
    }
    if (this.route.url == '/credito') {
      this.produto = 'Analise de Crédito'
    }
    if (this.route.url == '/cadastrais') {
      this.produto = 'Cadastrais'
    }
    if (this.route.url == '/patrimonial') {
      this.produto = 'Patrimonial'
    }
  }

  enviarDataNascimento() {
    if (this.dataNascimento) {
      let dataObj = new Date(this.dataNascimento.replace(/-/g, '/'));
      let dataFormatada = this.datePipe.transform(dataObj, 'yyyy/MM/dd');
      console.log(dataFormatada)
      if (this.validarFormatoData(dataFormatada)) {
        this.formatarParametro(dataFormatada);
        let objetoDataNasc = {
          dataNasc: dataFormatada,
          paramNasc: this.data.objetoAdicional
        }
        this.dialogRef.close(objetoDataNasc);
      }
    }
  }

  enviarDataNascimentoDetech(enumTipoPesquisa) {
    if (this.dataNascimento) {
      let dataObj = new Date(this.dataNascimento.replace(/-/g, '/'));
      let dataFormatada = this.datePipe.transform(dataObj, 'yyyy/MM/dd');
      console.log(dataFormatada)
      if (this.validarFormatoData(dataFormatada)) {

        if (enumTipoPesquisa == PesquisaMenorDetech.novaPesquisa) {
          this.buscarDocumento(this.data, dataFormatada)
        }
        if (enumTipoPesquisa == PesquisaMenorDetech.AdicionarNo) {
          this.adicionarNovoGraph(this.data, dataFormatada)
        }
        if (enumTipoPesquisa == PesquisaMenorDetech.ExpandirNo) {
          this.expandirNo(this.data, dataFormatada)
        }
        if (enumTipoPesquisa == PesquisaMenorDetech.InformacoesAdicionais) {
          this.InformacoesAdicionaisComponent(this.data, dataFormatada)
        }
      }
    }
  }

  buscarDocumento(documento, dataNascimento) {
    this.ui.loading();
    this.exibirMensagemDataErrada = false;
    let dadosGrafico
    this.teiaRelacionamentoService.buscarDocumentoMenorIdade(documento.docForm, dataNascimento).subscribe(data => {
      this.ui.loaded().subscribe(() => {
        dadosGrafico = {
          data: data,
          isNovoGraph: true
        }
        if (data.descricao) {
          this.ui.info(data.descricao)
        }
        else {
          this.dialogRef.close(dadosGrafico);
        }
      })


    }, (err: any) => {
      if (err.error.codigo == 21 && err.error.descricao == "data de aniversário informada não pertence ao menor de idade, é necessária a data de aniversário para processar a solicitação") {
        this.ui.loaded().subscribe(() => {
          this.exibirMensagemDataErrada = true
        })
      }
      else {
        this.ui.loaded().subscribe(() => {
          console.log(err)
          this.ui.info(err.error.descricao);
          this.dialogRef.close(dadosGrafico);
        })
      }
    });
  }

  adicionarNovoGraph(obj, dataNascimento) {
    this.exibirMensagemDataErrada = false;
    this.ui.loading();
    this.teiaRelacionamentoService.adicionarGraficoMenorIdade(obj.doc, obj.id, obj.nodes, dataNascimento).subscribe(data => {
      this.ui.loaded().subscribe(() => {
        let dadosGrafico = {
          data: data,
          isAddGraph: true
        }
        if (data.descricao) {
          this.ui.info(data.descricao)
          // this.salvarNosGraphSemMensagem()
        }
        if (dadosGrafico && !data.descricao) {
          this.dialogRef.close(dadosGrafico);
        }
      })
    },
      (err: any) => {
        if (err.error.codigo == 21 && err.error.descricao == "data de aniversário informada não pertence ao menor de idade, é necessária a data de aniversário para processar a solicitação") {
          this.ui.loaded().subscribe(() => {
            this.exibirMensagemDataErrada = true
          })
        }
      })
  }

  expandirNo(obj, dataNasc) {
    this.ui.loading();
    this.exibirMensagemDataErrada = false;
    this.teiaRelacionamentoService.buscarDocumento2MenorIdade(obj.docForm, obj.id, obj.nodes, dataNasc).subscribe(data => {
      this.ui.loaded().subscribe(() => {
        let dadosGrafico = {
          data: data,
          isExpandGraph: true
        }
        if (data.descricao) {
          this.ui.info(data.descricao)
        }
        if (dadosGrafico && !data.descricao) {
          this.dialogRef.close(dadosGrafico)
        }
      })
    }, (err: any) => {
      this.ui.loaded().subscribe(() => {
        if (err.error.codigo == 21 && err.error.descricao == "data de aniversário informada não pertence ao menor de idade, é necessária a data de aniversário para processar a solicitação") {
          this.ui.loaded().subscribe(() => {
            this.exibirMensagemDataErrada = true
          })
        }
        if (err.error.descricao) {
          this.ui.info(err.error.descricao)
        }
        else {
          this.ui.info('Ocorreu um erro, tente novamente')
        }
      })
    });
  }

  InformacoesAdicionaisComponent(obj, dataNac) {
    this.ui.loading();
    this.teiaRelacionamentoService.detalharNoMenorIdade(obj.doc, obj.id, dataNac).subscribe(response => {
      this.ui.loaded().subscribe(() => {
        let dadosAdicionais = {
          data: response,
          isAdicionais: true
        }
        if (response.descricao) {
          this.dialogRef.close()
          this.ui.info(response.descricao);
          return

        }
        else {
          this.dialogRef.close(dadosAdicionais)
        }
      });
    }, (err: any) => {
      this.ui.loaded().subscribe(() => {
        if (err.error.codigo == 21 && err.error.descricao == 'data de aniversário informada não pertence ao menor de idade, é necessária a data de aniversário para processar a solicitação') {
          this.ui.loaded().subscribe(() => {
            this.exibirMensagemDataErrada = true
          })
        }
      })
    });
  }

  cancelar() {
    this.dialogRef.close();
  }

  validarFormatoData(data: string): boolean {
    const regex = /^\d{4}\/\d{2}\/\d{2}$/;
    return regex.test(data);
  }

  formatarParametro(data) {
    this.data.objetoAdicional.parametros['DataNascimento'] = data.toString();
  }

}
