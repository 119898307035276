import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ModalAnexoComponent } from './modal-anexo/modal-anexo.component';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-pesquisa-em-massa',
  templateUrl: './pesquisa-em-massa.component.html',
  styleUrls: ['./pesquisa-em-massa.component.scss']
})
export class PesquisaEmMassaComponent implements OnInit {

  @Input() fonteGrupo : any;
  constructor(
    public dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    console.log(this.fonteGrupo)
  }

  openModalAnexo() {
    const menu = this.dialog.open(ModalAnexoComponent, {
      width: '750px',
      panelClass: 'bg-color',
      maxHeight: '600px',
      data: this.fonteGrupo
    });
  }


}
