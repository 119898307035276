<div class="border-grey" style="margin: 1.7rem -0.2rem;">
  <div class="row d-flex justify-content-evenly mt-4 margin-processos">
    <div class="d-flex justify-content-end" *ngIf="exibeReprocessarSintese">
      <button *ngIf="exibeBotaoRefresh" mat-flat-button class="gs-botaoAzulPadrao m-3"
        (click)="getProcessosStatus()">Refresh <mat-icon>cached
        </mat-icon></button>
    </div>
    <h2 class="title-tab text-center">Resumo de Processos</h2>
    <div class="border-grey processo-indisponivel text-center d-flex flex-column justify-content-center p-2 m-2 mb-5"
      *ngIf="exibeReprocessarSintese">
      <p>{{objProcessos.mensagem}}</p>
      <loader-only *ngIf="sinteseEmProcessamento"></loader-only>
    </div>
    <div class="d-flex justify-content-end align-items-center" *ngIf="exibeMensagemAtualizacaoProcesso">
      <p class="p-grey loader dot-space">{{objProcessos.mensagem}} <span class="loader__dot">.</span><span class="loader__dot">.</span><span
        class="loader__dot">.</span></p>
      <button *ngIf="exibeBotaoRefresh" mat-flat-button class="gs-botaoAzulPadrao m-3"
      (click)="getProcessosStatus()">Refresh <mat-icon>cached
      </mat-icon></button>
    </div>
    <div class="col border-grey text-center p-2 m-2" [ngClass]="exibeReprocessarSintese ? 'opacity-min' : '' "
      *ngIf="!exibeReprocessarSintese">
      <p class="title-grey mt-2 mb-5">Processos Estaduais</p>
      <loader-only class="mt-3" *ngIf="isLoadingSintese"></loader-only>
      <div class="row d-flex flex-column" *ngIf="!isLoadingSintese">
        <div class="col">
          <div class="row d-flex content-grey">
            <div class="col-4 border-right">
              <h4 class="title-polo">Passivos</h4>
              <p class="count-polo">{{objProcessos.dados.sinteseProcessos.qtdEstaduaisPoloPassivo}}</p>
            </div>
            <div class="col-8 d-flex justify-content-center align-items-center">
              <p class="value-polo">
                {{objProcessos.dados.sinteseProcessos.totalEstaduaisValorPoloPassivo | currency:'BRL'}}</p>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="row d-flex content-grey">
            <div class="col-4 border-right">
              <h4 class="title-polo">Ativos</h4>
              <p class="count-polo">{{objProcessos.dados.sinteseProcessos.qtdEstaduaisPoloAtivo}}</p>
            </div>
            <div class="col-8 d-flex justify-content-center align-items-center">
              <p class="value-polo">
                {{objProcessos.dados.sinteseProcessos.totalEstaduaisValorPoloAtivo | currency:'BRL'}}</p>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="row d-flex content-grey">
            <div class="col-4 border-right">
              <h4 class="title-polo">Outros</h4>
              <p class="count-polo">{{objProcessos.dados.sinteseProcessos.qtdEstaduaisPoloNaoIdentificado}}</p>
            </div>
            <div class="col-8 d-flex justify-content-center align-items-center">
              <p class="value-polo">
                {{objProcessos.dados.sinteseProcessos.totalEstaduaisValorPoloNaoIdentificado | currency:'BRL'}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col border-grey text-center p-2 m-2" [ngClass]="exibeReprocessarSintese ? 'opacity-min' : '' "
      *ngIf="!exibeReprocessarSintese">
      <p class="title-grey mt-2 mb-5">Processos Federais</p>
      <loader-only class="mt-3" *ngIf="isLoadingSintese"></loader-only>
      <div class="row d-flex flex-column" *ngIf="!isLoadingSintese">
        <div class="col">
          <div class="row d-flex content-grey">
            <div class="col-4 border-right">
              <h4 class="title-polo">Passivos</h4>
              <p class="count-polo">{{objProcessos.dados.sinteseProcessos.qtdFederalPoloPassivo}}</p>
            </div>
            <div class="col-8 d-flex justify-content-center align-items-center">
              <p class="value-polo">
                {{objProcessos.dados.sinteseProcessos.totalFederalValorPoloPassivo | currency:'BRL'}}</p>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="row d-flex content-grey">
            <div class="col-4 border-right">
              <h4 class="title-polo">Ativos</h4>
              <p class="count-polo">{{objProcessos.dados.sinteseProcessos.qtdFederalPoloAtivo}}</p>
            </div>
            <div class="col-8 d-flex justify-content-center align-items-center">
              <p class="value-polo">
                {{objProcessos.dados.sinteseProcessos.totalFederalValorPoloAtivo | currency:'BRL'}}</p>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="row d-flex content-grey">
            <div class="col-4 border-right">
              <h4 class="title-polo">Outros</h4>
              <p class="count-polo">{{objProcessos.dados.sinteseProcessos.qtdFederalPoloNaoIdentificado}}</p>
            </div>
            <div class="col-8 d-flex justify-content-center align-items-center">
              <p class="value-polo">
                {{objProcessos.dados.sinteseProcessos.totalFederalValorPoloNaoIdentificado | currency:'BRL'}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col border-grey text-center p-2 m-2" [ngClass]="exibeReprocessarSintese ? 'opacity-min' : '' "
      *ngIf="!exibeReprocessarSintese">
      <p class="title-grey mt-2 mb-5">Processos Trabalhistas</p>
      <loader-only class="mt-3" *ngIf="isLoadingSintese"></loader-only>
      <div class="row d-flex flex-column" *ngIf="!isLoadingSintese">
        <div class="col">
          <div class="row d-flex content-grey">
            <div class="col-4 border-right">
              <h4 class="title-polo">Passivos</h4>
              <p class="count-polo">{{objProcessos.dados.sinteseProcessos.qtdTrabalhistasPoloPassivo}}</p>
            </div>
            <div class="col-8 d-flex justify-content-center align-items-center">
              <p class="value-polo">
                {{objProcessos.dados.sinteseProcessos.totalTrabalhistasValorPoloPassivo | currency:'BRL'}}</p>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="row d-flex content-grey">
            <div class="col-4 border-right">
              <h4 class="title-polo">Ativos</h4>
              <p class="count-polo">{{objProcessos.dados.sinteseProcessos.qtdTrabalhistasPoloAtivo}}</p>
            </div>
            <div class="col-8 d-flex justify-content-center align-items-center">
              <p class="value-polo">
                {{objProcessos.dados.sinteseProcessos.totalTrabalhistasValorPoloAtivo | currency:'BRL'}}</p>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="row d-flex content-grey">
            <div class="col-4 border-right">
              <h4 class="title-polo">Outros</h4>
              <p class="count-polo">{{objProcessos.dados.sinteseProcessos.qtdTrabalhistasPoloNaoIdentificado}}</p>
            </div>
            <div class="col-8 d-flex justify-content-center align-items-center">
              <p class="value-polo">
                {{objProcessos.dados.sinteseProcessos.totalTrabalhistasValorPoloNaoIdentificado | currency:'BRL'}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="border-grey  p-2 m-3">
    <app-linha-tempo [ngClass]="exibeReprocessarSintese ? 'opacity-min' : '' "
    *ngIf="exibeLinhaTempo && !exibeReprocessarSintese" [dadosSintese]="dadosSintese"></app-linha-tempo>
  </div>
</div>