import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { HeaderService } from 'src/app/components/template/header/header.service';
import { NavService } from 'src/app/components/template/nav/nav.service';
import { PrecificadorService } from 'src/app/shared/services/precificador/precificador.service';

@Component({
  selector: 'app-precificador-credito',
  templateUrl: './precificador-credito.component.html',
  styleUrls: ['./precificador-credito.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PrecificadorCreditoComponent implements OnInit {
  abrirPrecificador = false;
  ocultarListagem = false;
  selected = new FormControl(0);

  constructor(
    private headerService: HeaderService,
    private navService: NavService,
    private precificadorService: PrecificadorService,
  ) {
    navService.navData = {
      title:'Precificador'
    }
    headerService.headerData = {
      title:'Precificador'
    }
   }

  ngOnInit(): void {
    this.precificadorService.getAbrirPrecificador().subscribe(idNovo => {
      this.iniciarPrecificacao()
    })
  }

  iniciarPrecificacao() {
    this.abrirPrecificador = true;
    this.ocultarListagem = true;

  }

  removeTab(index: number) {
  }

}
