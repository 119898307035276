import { Component, Input, OnInit } from '@angular/core';
import { takeWhile, timer } from 'rxjs';
import { PesquisaService } from 'src/app/shared/services/http/pesquisa.service';

@Component({
  selector: 'app-ibama-areas-embargadas',
  templateUrl: './ibama-areas-embargadas.component.html',
  styleUrls: ['./ibama-areas-embargadas.component.scss']
})
export class IbamaAreasEmbargadasComponent implements OnInit {
  @Input() idPesquisa: number;
  @Input() aba: any;


  dataIbamaAreasEmbargadas;
  isLoadingIbamaAreasEmbargadas = false;
  exibeIbamaAreasEmbargadas = false;
  exibeMensagemIbamaAreasEmbargadas =  false;
  exibeReprocessarIbamaAreasEmbargadas = false;

  panelOpenState = false


  constructor(
      private pesquisaService: PesquisaService
  ) { }

  ngOnInit(): void {
     this.getIbamaAreasEmbargadas(this.idPesquisa)
  }

  getIbamaAreasEmbargadas(idPesquisa) {
    this.isLoadingIbamaAreasEmbargadas = true;
    this.exibeMensagemIbamaAreasEmbargadas = false;
    this.exibeReprocessarIbamaAreasEmbargadas = false;
    let attempts = 0;
  
    const subscription = timer(0, 15000).pipe(
      takeWhile(() => attempts < 5) // Limita a 5 tentativas
    ).subscribe(() => {
      this.pesquisaService.getIbamaAreasEmbargadas(idPesquisa).subscribe(
        response => {
          this.dataIbamaAreasEmbargadas = response;
          switch (response.codigo) {
            case 4000:
              this.exibeIbamaAreasEmbargadas = false;
              this.isLoadingIbamaAreasEmbargadas = false;
              subscription.unsubscribe();
              break;
            case 1000:
              this.exibeIbamaAreasEmbargadas = true;
              this.isLoadingIbamaAreasEmbargadas = false;
              this.exibeMensagemIbamaAreasEmbargadas = false;
              subscription.unsubscribe();
              break;
            case 2000:
                this.exibeIbamaAreasEmbargadas = true;
                this.isLoadingIbamaAreasEmbargadas = false;
                subscription.unsubscribe();
                break;
            case 3001:
              this.exibeIbamaAreasEmbargadas = true;
              this.isLoadingIbamaAreasEmbargadas = false;
              this.exibeMensagemIbamaAreasEmbargadas = true;
              subscription.unsubscribe();
              break;
            case 3000:
              this.exibeIbamaAreasEmbargadas = true;
              this.isLoadingIbamaAreasEmbargadas = false;
              this.exibeMensagemIbamaAreasEmbargadas = true;
              attempts++;
              if (attempts >= 5) {
                this.exibeReprocessarIbamaAreasEmbargadas = true;
                subscription.unsubscribe();
              }
              break;
          }
        },
        error => {
          attempts++;
          if (attempts >= 5) {
            this.exibeIbamaAreasEmbargadas = false;
            this.isLoadingIbamaAreasEmbargadas = false;
            subscription.unsubscribe(); // Interrompe o intervalo após 5 tentativas
          }
        }
      );
    });
  }  
}

