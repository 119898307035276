import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UsuarioService } from '../../../shared/services/http/usuario.service';
import { UiService } from '../../../shared/services/ui.service';

@Component({
  selector: 'ngx-request-password-page',
  styleUrls: ['./recuperacao-senha.page.scss'],
  templateUrl: './recuperacao-senha.page.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RecuperacaoSenhaPage implements OnInit {

  errors: string[] = [];
  messages: string[] = [];
  user: any = {};
  requestPasswordForm!: FormGroup;

  constructor(
        protected usuarioService: UsuarioService,
        protected fb: FormBuilder,
        protected loading: UiService,
        protected router: Router) { }

  get email() { return this.requestPasswordForm.get('email'); }

  ngOnInit(): void {   
    this.requestPasswordForm = this.fb.group({
      email: this.fb.control('', [Validators.required, Validators.email]),
    });
  }

  requestPass(): void {
    this.user = this.requestPasswordForm.value;
    this.errors = this.messages = [];

    let okFn = (data:any) => {
        this.loading.loaded().subscribe(() => this.router.navigate(['auth/login']));        
    };
    let nokFn = (data:any) => {
        
    };
    this.loading.loading();
    this.usuarioService.iniciarRecuperacaoSenha(this.user).subscribe(okFn, nokFn);
  }
}
