<div class="text-center">
    <div class="header d-flex justify-content-between">
      <div></div>
      <h3 class="mt-1">Adicionar nó customizado</h3>
      <mat-icon aria-label="close dialog" mat-dialog-close>close</mat-icon>
    </div>
    <div class="d-flex flex-column justify-content-center align-items-center">
      <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="d-flex flex-column justify-content-center">
          <!-- Adicionando o mat-radio-group -->
          <mat-radio-group formControlName="tipoEscolha" class="mb-3">
            <mat-radio-button value="cpf" class="me-3">Documento</mat-radio-button>
            <mat-radio-button value="nome">Nome</mat-radio-button>
          </mat-radio-group>
    
          <!-- Campo CPF/CNPJ -->
          <mat-form-field
            class="example-full-width teia-form"
            appearance="outline"
            *ngIf="form.get('tipoEscolha')?.value === 'cpf'"
          >
            <input
              matInput
              mask="CPF_CNPJ"
              formControlName="documento"
              placeholder="Digite o CPF ou CNPJ"
            />
            <mat-error *ngIf="form.get('documento')?.hasError('required')">
              documento é obrigatório.
            </mat-error>
          </mat-form-field>
    
          <!-- Campo Nome -->
          <mat-form-field
            class="example-full-width teia-form"
            appearance="outline"
            *ngIf="form.get('tipoEscolha')?.value === 'nome'"
          >
            <input
              matInput
              formControlName="nome"
              placeholder="Digite o Nome"
            />
            <mat-error *ngIf="form.get('nome')?.hasError('required')">
              Nome é obrigatório.
            </mat-error>
          </mat-form-field>
    
          <!-- Campo Descrição -->
          <mat-form-field class="example-full-width teia-form" appearance="outline">
            <input
              matInput
              formControlName="descricao"
              placeholder="Digite a descrição"
            />
            <mat-error *ngIf="form.get('descricao')?.hasError('required')">
              Descrição é obrigatória.
            </mat-error>
          </mat-form-field>
        </div>
        <div class="text-center mt-3">
          <button
            mat-flat-button
            color="primary"
            type="submit"
            [disabled]="form.invalid"
          >
            Adicionar
            <mat-icon>search</mat-icon>
          </button>
        </div>
      </form>
    </div>
  </div>
  