import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject, catchError, tap, throwError } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { ToastrService } from 'ngx-toastr';

@Injectable({
    providedIn: 'root'
})
export class PrecificadorService {

    //precificadorIdService : number;

    private baseUrl = environment.apiUrl + "/precificador/v1";
    private baseUrl2 = environment.apiUrl + "/divida-judicializada";
    precificadorIdPai = new BehaviorSubject<number>(0)
    precificadorIdPaivalue: number;

    abrirPrecificadorComId = new Subject<boolean>()

    constructor(public http: HttpClient, private toastr: ToastrService,) { }

    onChangeNumber(novoId: number) {
        this.precificadorIdPaivalue = novoId;
        this.precificadorIdPai.next(novoId);
    }

    abrirPrecificador(abrir: boolean) {
        this.abrirPrecificadorComId.next(abrir);
    }

    getPrecificadorIdPai() {
        return this.precificadorIdPai.asObservable();
    }
    getAbrirPrecificador() {
        return this.abrirPrecificadorComId.asObservable();
    }

    cadastrarCreditosAtualizados(item): Observable<any> {
        return this.http.put(`${this.baseUrl}/creditos-atualizados`, item);
    }

    creditosAtualizados(id): Observable<any> {
        return this.http.get(`${this.baseUrl}/creditos-atualizados?precificadorId=${id}`);
    }

    cadastrarAtivos(item): Observable<any> {
        return this.http.put(`${this.baseUrl}/ativos`, item);
    }

    ativos(id): Observable<any> {
        return this.http.get(`${this.baseUrl}/ativos?precificadorId=${id}`);
    }
    
    cadastrarPassivos(item): Observable<any> {
        return this.http.put(`${this.baseUrl}/passivos`, item);
    }
    
    passivos(id): Observable<any> {
        return this.http.get(`${this.baseUrl}/passivos?precificadorId=${id}`);
    }

    cadastrarTempoRecuperacao(item): Observable<any> {
        return this.http.put(`${this.baseUrl}/tempo-recuperacao`, item);
    }
    
    tempoRecuperacao(id): Observable<any> {
        return this.http.get(`${this.baseUrl}/tempo-recuperacao?precificadorId=${id}`);
    }
    
    listarPrecificaoesRealizadas(item): Observable<any> {
        return this.http.post(`${this.baseUrl}/pesquisa-creditos-atualizados`, item);
    }
    
    calcularDivida(item): Observable<any> {
        return this.http.post(`${this.baseUrl2}/atualizacao-divida`, item);
    }

    visualizarRelatorio(id): Observable<any> {
        return this.http.get(`${this.baseUrl}/relatorio-html?PrecificadorId=${id}`, { responseType: 'text' });
    }

    gerarPDF(id): Observable<any> {
        return this.http.get(`${this.baseUrl}/relatorios/${id}`);
    }


    validarPlanilhaAtivos(file: any): Observable<any> {
        return this.http.post(`${this.baseUrl}/importar/ativos`, file);
    }

    validarPlanilhaPassivos(file: any): Observable<any> {
        return this.http.post(`${this.baseUrl}/importar/passivos`, file);
    }
    


    
}
