

<div class="card-body px-4 py-5 px-md-5">
  <h2>Digite seu E-mail e clique em recuperar senha</h2>
  <form [formGroup]="requestPasswordForm">
    <!-- 2 column grid layout with text inputs for the first and last names -->

    <!-- Email input -->
    <div class="form-outline mb-4 text-left">
        <label class="form-label" for="form3Example3">E-mail</label>
      <input type="email" id="form3Example3" class="form-control" formControlName="email" autofocus />
    </div>

    <div class="row d-flex justify-content-center">
        <div class="col-md-6 mb-4">
            <div class="form-outline">
              <button type="submit" class="btn btn-primary btn-block mb-4 btn-entrar pt-2 pb-2" (click)="requestPass()" [disabled]="!requestPasswordForm.valid">
                Recuperar Senha
              </button>
            </div>
          </div>
          <div class="col-md-6 mb-4">
            <div class="form-outline">
              <button type="submit" class="btn btn-primary btn-block mb-4 btn-entrar pt-2 pb-2" routerLink="../login">
                Fazer Login
              </button>
            </div>
          </div>
      </div>
  </form>
</div>
