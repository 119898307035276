import { ChangeDetectionStrategy, Component, HostListener, ChangeDetectorRef, Input, OnInit, ViewChild } from '@angular/core';
import { MensageResponseComponent } from 'src/app/components/view/mensage-response/mensage-response.component';
import { PopPesqRealizadaComponent } from 'src/app/components/view/poppesqrealizada/poppesqrealizada.component';
import { HttpStatusCode } from '@angular/common/http';
import { Textos } from 'src/app/shared/textos';
import { ModalEdicaoTagComponent } from 'src/app/components/view/modal-edicao-tag/modal-edicao-tag.component';
import { ModalExportarPesquisaLoteComponent } from 'src/app/components/view/modal-exportar-pesquisa-lote/modal-exportar-pesquisa-lote.component';
import { MatCheckbox, MatCheckboxChange } from '@angular/material/checkbox';
import { ModalConfirmacaoComponent } from 'src/app/components/view/modal-confirmacao/modal-confirmacao.component';
import { Observable, forkJoin, map, startWith } from 'rxjs';
import { FormControl, FormGroup } from '@angular/forms';
import { MatTabGroup } from '@angular/material/tabs';
import { ConfiguracaoService } from 'src/app/shared/services/http/configuracao.service';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from 'src/app/shared/services/http/auth.service';
import { HeaderService } from 'src/app/components/template/header/header.service';
import { FonteGrupoService } from 'src/app/shared/services/http/fonte-grupo.service';
import { ToastrService } from 'ngx-toastr';
import { UiService } from 'src/app/shared/services/ui.service';
import { PesquisaService } from 'src/app/shared/services/http/pesquisa.service';
import { UtilService } from 'src/app/shared/services/http/util.service';
import { NavService } from 'src/app/components/template/nav/nav.service';
import { Fonte } from 'src/app/shared/enums/fonte.enum';
import { StatusRegistro } from 'src/app/shared/enums/status-registro.enum';
import { StatusPesquisa } from 'src/app/shared/enums/status-pesquisa.enum';
import { TipoPesquisa } from 'src/app/shared/enums/tipo-pesquisa.enum';
import { MensageErrorResponseComponent } from 'src/app/components/view/mensage-error-response/mensage-error-response.component';
import { CategoriaFonteEnum } from 'src/app/shared/enums/categoria-fonte.enum';
import { MatOption } from '@angular/material/core';
import { ModalValoresPesquisaComponent } from 'src/app/components/template/produto-pesquisa/modal-valores-pesquisa/modal-valores-pesquisa.component';
import { ModalMenorIdadeComponent } from 'src/app/components/template/produto-pesquisa/modal-menor-idade/modal-menor-idade.component';
import { UsuarioService } from 'src/app/shared/services/http/usuario.service';
import { Z } from '@angular/cdk/keycodes';
import { Router } from '@angular/router';
import { MatSelect } from '@angular/material/select';
import { ModalPesquisaLoteComponent } from 'src/app/components/template/produto-pesquisa/modal-pesquisa-lote/modal-pesquisa-lote.component';
import { ModalValidacaoCruzamentoComponent } from './modal-validacao-cruzamento/modal-validacao-cruzamento.component';
import { CruzamentoEnum } from 'src/app/shared/enums/statusCruzamento.enum';

const today = new Date();
const month = today.getMonth();
const year = today.getFullYear();

@Component({
  selector: 'app-cruzamento-dados',
  templateUrl: './cruzamento-dados.component.html',
  styleUrls: ['./cruzamento-dados.component.scss']
})
export class CruzamentoDadosComponent implements OnInit {


  // ===== definicao do produto ====
  idProduto = 5;
  formFiltro: any = { page: 1, pageSize: 50 };

  StatusRegistro = StatusRegistro;
  StatusPesquisa = StatusPesquisa;
  TipoPesquisa = TipoPesquisa;
  pesquisasSelecionadas = [];
  formCadastroValorAdicional: boolean = false;
  formCadastroValorAdicionalLabel: string = "";
  Fonte = Fonte;
  tipoLabels: Map<Fonte, string> = new Map<Fonte, string>();

  formPesquisa: any = {};
  formFiltroRenderizado: any = this.formFiltro;

  refazer: boolean = false;
  resultadoPesquisa: any = [];
  usuario: any;
  configuracao: any = {};
  listaFonteGrupo: any = [];
  isSuzano = false;
  exibeToast = false
  selected: any;
  isDisabled = false;

  dataNasc;
  listaFontesAdicionaisProcesso = [];
  listaFontesAdicionaisPatrimonial = [];
  // listaFontesAdicionaisSemFiltro = [];

  listaConfiguracoesParametros = [];
  parametrosSelecionados = []
  valoresMensagemParametros = []
  valoresMensagemAdicionais = []
  listaFontesAdicionais;
  parametrosToRequest: any = [];
  checked = false;
  adicionaisToRequest = [];
  formParametros = [];
  cidadesSelecionadas = [];
  parametrosCidadesToRequest: any = {};
  parametrosAdicionaisToRequest: any = {};
  objValorAdicional;
  resultadoResolverNome;
  tipoDocumento;
  countPesquisaExistente;

  objetoRetornoPesquisa;

  filterValue: string = '';
    filteredTags!: Observable<any[]>;


  objMenorIdade = [];

  valorCidades: number = 0;
  valorEstados: number = 0;

  myControl = new FormControl();
  tagIds = [];
  addedItems = [];
  autoCompletePanel: any;
  labelTag: String = 'Tag';

  filterCidade: string = '';
  filteredCidades: any[] = [];

  docControl = new FormControl();
  docsAdded = [];
  docsIds = [];
  // autoCompletePanel: any;

  campaignOne = new FormGroup({
    start: new FormControl(new Date(year, month, 13)),
    end: new FormControl(new Date(year, month, 16)),
  });
  campaignTwo = new FormGroup({
    start: new FormControl(new Date(year, month, 15)),
    end: new FormControl(new Date(year, month, 19)),
  });
  @ViewChild('allSelected') private allSelected: MatOption;
  @ViewChild('selectPanel') selectPanel: MatSelect;


  valorAdicionalFonte: number = 0;
  valorAdicionalParametro: number = 0;
  abas: { titulo: string, idpesquisa}[] = [{ titulo: 'Histórico', idpesquisa: null}];
  selectedIndex = 0;
  @ViewChild(MatTabGroup) tabGroup: MatTabGroup;
  panelOpenState = false;

  isDragging = false;
  startX = 0;
  startY = 0;
  initialLeft = 0;
  initialTop = 0;

  mensagemQualificada = '';

  isCadastrais = false;
  isProcessos = false;
  isPatrimonial = false;
  isAnaliseCredito = false;
  isDossie = false;
  requestHeader: string = 'processos'

  tipoAcao = [];
  processoStatus = [];


  statusMap = {
    [CruzamentoEnum.Aguardando]: 'Aguardando',
    [CruzamentoEnum.ProcessandoCruzamento]: 'Processando Cruzamento',
    [CruzamentoEnum.CruzamentoFinalizado]: 'Cruzamento Finalizado',
    [CruzamentoEnum.PesquisaCancelada]: 'Pesquisa Cancelada',
    [CruzamentoEnum.ProcessarCruzamento]: 'Em fila para processar'
  };


  constructor(
    private pesquisaService: PesquisaService,
    private utilService: UtilService,
    private ui: UiService,
    private toastr: ToastrService,
    private usuarioService: UsuarioService,
    private configuracaoService: ConfiguracaoService,
    private fonteGrupoService: FonteGrupoService,
    private headerService: HeaderService,
    private navService: NavService,
    private authService: AuthService,
    private _snackBar: MatSnackBar,
    public router: Router,
    public dialog: MatDialog) {
    navService.navData = {
      title: 'Dossie'
    }
    headerService.headerData = {
      title: 'Dossie'
    }
  }

  ngOnInit(): void {
    this.getUsuario();
    this.formPesquisa.numero = "";
    this.formPesquisa.segundoNivel = false
    this.formPesquisa.valorAdicional = [];
    this.ui.loading();

    let requests = [
      this.configuracaoService.get(),
      this.fonteGrupoService.listAtivoProduto(this.idProduto)
    ];

    forkJoin(requests).subscribe(data => {
      if (data && data[1].length == 0) {
        
      }
      this.defineParametroseFontesSelecionaveis(this.listaFonteGrupo[0]);
      this.getChipsSemLoader();
      this.pesquisarClickedSemLoader(1);
    });
    this.filteredTags = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => typeof value === 'string' ? this._filter(value) : this._filter(value?.tag))
    );
    this.tipoLabels.set(Fonte.CertidaoDividaUniaoImovelRural, "NIRF");

    this.myControl.valueChanges.subscribe(() => {
      if (this.myControl.value) {
        this.docControl.disable();
      } else {
        // Ao limpar o identificador, habilitar o campo de documentos e resetar completamente o campo identificador
        this.docControl.enable();
        this.resetIdentificador();
      }
    });

    setInterval(() => {
      let page = this.formFiltro.page;
      this.pesquisarClickedSemLoader(page);
    }, 1000 * 60)
  }

  defineParametroseFontesSelecionaveis(data) {
    // === define o valor de fontes adicionais que estao vincular ao id do grupo de fontes 
    this.listaFontesAdicionais = data;
  }

  resetIdentificador() {
    this.myControl.reset(); // Limpa completamente o identificador
  }

  private _filter(value: string): any[] {
    const filterValue = value ? value.toLowerCase() : '';
    return this.tagIds.filter(option => option.tag.toLowerCase().includes(filterValue));
  }



  // private _filterTags(value): any[] {
  //   console.log(value)
  //   const filterValue = value.toLowerCase();
  //   return this.tagIds.filter(tag => tag.tag.toLowerCase().includes(filterValue));
  // }

  atribuiIdentificador(value){
    if(value){
      this.formPesquisa.identificador = value;
    }
  }

  displayFn(option: any): string {
    return option ? option.tag : '';
  }

  getUsuario() {
    this.authService.callbackUsuario().subscribe(usuario => {
      if (!usuario) {
        return;
      }
      if (usuario) {
        this.usuario = usuario;
        if (usuario.cliente.descricaoTag && usuario.cliente.descricaoTag.trim().length > 0) {
          this.labelTag = usuario.cliente.descricaoTag;
        }
      }
      if (usuario.cliente.id == 52) {
        this.isSuzano = true
      }
    })
  }

  addChip(value) {
    this.myControl.reset();
    if (!this.addedItems.find(item => item.tag === value.tag)) {
      this.addedItems.push(value);
    }
    this.tagIds = this.tagIds.filter(item => item.tag !== value.tag);
    this.filteredTags = this.filteredTags.pipe(
      map(tags => tags.filter(tag => tag.tagId !== value.tagId))
    );
    this.myControl.reset();
  }


  getChips() {
    this.ui.loading()
    this.pesquisaService.getTagID(this.usuario.cliente.id).subscribe(response => {
      this.ui.loaded().subscribe(() => {
        this.tagIds = response
      })
    }, (err: any) => {
      this.ui.loaded().subscribe(() => {
        this.toastr.error('Ocorreu um erro, tente novamente.')
      })
    });
  }

  getChipsSemLoader() {
    // this.ui.loading()
    this.pesquisaService.getTagID(this.usuario.cliente.id).subscribe(response => {
      this.ui.loaded().subscribe(() => {
        this.tagIds = response
      })
    }, (err: any) => {
      this.ui.loaded().subscribe(() => {
        this.toastr.error('Ocorreu um erro, tente novamente.')
      })
    });
  }


  deletarTagExistente(value) {
    const dados = {
      title: '',
      message: 'Deseja realmente excluir esse item?',
      cancelText: 'Cancelar',
      confirmText: 'excluir'
    }
    const dialogRef = this.dialog.open(ModalConfirmacaoComponent, {
      maxWidth: "470px",
      data: dados
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult == true) {
        this.ui.loading()
        this.pesquisaService.excluirTag(value.tagId).subscribe(response => {
          this.ui.loaded().subscribe(() => {
            // this.tagIds = response
            this.toastr.info('Tag excluída com sucesso.', 'CredValue');
            this.tagIds = this.tagIds.filter(i => i.tagId != value.tagId);
            this.filteredTags = this.filteredTags.pipe(
              map(tags => tags.filter(tag => tag.tagId != value.tagId))
            );
            this.getChips()
          })
        }, (err: any) => {
          this.ui.loaded().subscribe(() => {
            this.toastr.error('Ocorreu um erro, tente novamente.')
          })
        });
      }
    });

  }

  toggleItemSelection(pesquisa) {
    if (this.isItemSelected(pesquisa)) {
      this.pesquisasSelecionadas = this.pesquisasSelecionadas.filter(
        (selectedItem) => selectedItem.id !== pesquisa.id
      );
    } else {
      this.pesquisasSelecionadas.push(pesquisa);
    }
  }

  // Verifica se o item está selecionado
  isItemSelected(item: any): boolean {
    return this.pesquisasSelecionadas.some((selectedItem) => selectedItem.id === item.id);
  }

  selecionarTodosItens(isSelected: MatCheckbox) {
    if (isSelected.checked) {
      this.resultadoPesquisa.forEach(item => {
        if (!this.isItemSelected(item)) {
          this.pesquisasSelecionadas.push(item);
        }
      });
    } else {
      this.resultadoPesquisa.forEach(item => {
        this.pesquisasSelecionadas = this.pesquisasSelecionadas.filter(
          selectedItem => selectedItem.id !== item.id
        );
      });
    }
  }

  exportarExcelLote() {
    const arrayPesquisasSelecionadas = {
      ListaPesquisa: this.pesquisasSelecionadas.map(x => x.id)
    };

    // Exibir o SnackBar de carregamento
    const snackBarRef = this._snackBar.openFromComponent(MensageErrorResponseComponent, {
      data: {
        titulo: 'Exportando...',
        mensagem: 'Aguarde, estamos processando a sua solicitação',
      },
      duration: undefined,
      horizontalPosition: 'right',
      verticalPosition: 'top'
    });

    // Tempo mínimo para exibir o SnackBar (em milissegundos)
    const tempoMinimo = 2000;

    // Marca de tempo de início da exportação
    const inicio = Date.now();

    this.pesquisaService.exportarExcelLote(arrayPesquisasSelecionadas).subscribe({
      next: (data) => {
        // Baixar o arquivo
        var a = document.createElement("a");
        a.href = "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," + data.arquivoConteudo;
        a.download = data.arquivoNome;
        a.click();
      },
      error: (err: any) => {
        // Mostrar mensagem de erro
        this.openSnackBarWarning('Atenção: ', err.error);
      },
      complete: () => {
        // Calcular o tempo restante para completar os 2 segundos
        const tempoDecorrido = Date.now() - inicio;
        const tempoRestante = Math.max(tempoMinimo - tempoDecorrido, 0);

        // Aguardar o tempo restante antes de fechar o SnackBar
        setTimeout(() => snackBarRef.dismiss(), tempoRestante);
      }
    });
  }

  exportarPesquisaLote() {
    console.log(this.pesquisasSelecionadas.map(x => x.id))
    const menu = this.dialog.open(ModalExportarPesquisaLoteComponent, {
      width: '450px',
      panelClass: 'bg-color',
      maxHeight: '600px',
      data: this.pesquisasSelecionadas
    });
  }

  exportarLGPD(o) {
    this.ui.loading();
    this.pesquisaService.exportarLGPD({ id: o.id }, this.requestHeader).subscribe(data => {
      this.ui.loaded().subscribe(() => {
        var a = document.createElement("a");
        a.href = "data:application/zip;base64," + data.arquivoConteudo;
        a.download = data.arquivoNome;
        a.click();
      });
    }, (err: any) => {
      this.ui.loaded().subscribe(() => {
        this.openSnackBarWarning('Atenção', err.error);
      })
    });
  }

  pesquisarClicked(page = 1) {
    this.formFiltro.page = page;
    this.ui.loading();

    if (page == 1)
      this.resultadoPesquisa = [];

    let request = Object.assign({}, this.formFiltro);
    if (request.status) request.status = [request.status];
    this.pesquisaService.listaCruzamentoDados(request).subscribe(response => {
      this.formFiltroRenderizado = Object.assign({}, this.formFiltro);
      this.ui.loaded().subscribe(() => {
        // this.resultadoPesquisa = this.listCruzamentoDadosMock;
        // console.log(this.resultadoPesquisa)
        this.resultadoPesquisa = response;
      });
    });
  }

  pesquisarClickedSemLoader(page = 1) {
    this.formFiltro.page = page;

    if (page == 1)
      this.resultadoPesquisa = [];

    let request = Object.assign({}, this.formFiltro);
    if (request.status) request.status = [request.status];
    this.pesquisaService.listaCruzamentoDados(request).subscribe(response => {
      this.formFiltroRenderizado = Object.assign({}, this.formFiltro);
      // this.resultadoPesquisa = this.listCruzamentoDadosMock;
      this.resultadoPesquisa = response;
    });
  }

  editarTag(value, isRefazer) {
    let tags = value.tags
    let IdPesquisa = value.id
    let dialogRef = this.dialog.open(ModalEdicaoTagComponent, {
      width: '450px',
      panelClass: 'bg-color',
      maxHeight: '600px',
      data: {
        tags: tags,
        clienteId: this.usuario.cliente.id,
        label: this.labelTag,
        idPesquisa: IdPesquisa,
        refazer: isRefazer
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == true) {
        this.pesquisarClicked();
        this.getChips();
      }
    });
  }

  exportarClicked(o: { pesquisa: { id: any; }; }) {
    this.ui.loading();
    this.pesquisaService.exportar({ id: o.pesquisa.id }, this.requestHeader).subscribe(data => {
      this.ui.loaded().subscribe(() => {
        var a = document.createElement("a");
        a.href = "data:application/pdf;base64," + data.arquivoConteudo;
        a.download = data.arquivoNome;
        a.click();
      });
    }, (err: any) => {
      this.ui.loaded().subscribe(() => {
        this.openSnackBarWarning('Atenção', err.error);
      })
    });
  }

  exportarReciboClicked(o: { pesquisa: { id: any; }; }) {
    this.ui.loading();
    this.pesquisaService.exportarRecibo({ id: o.pesquisa.id }, this.requestHeader).subscribe(data => {
      this.ui.loaded().subscribe(() => {
        var a = document.createElement("a");
        a.href = "data:application/pdf;base64," + data.arquivoConteudo;
        a.download = data.arquivoNome;
        a.click();
      });
    }, (err: any) => {
      this.ui.loaded().subscribe(() => {
        this.openSnackBarWarning('Atenção', err.error);
      })
    });
  }

  exportarExcelClicked(o: { pesquisa: { id: any; }; }) {
    this.ui.loading();
    this.pesquisaService.exportarExcel({ id: o.pesquisa.id }, this.requestHeader).subscribe(data => {
      this.ui.loaded().subscribe(() => {
        var a = document.createElement("a");
        a.href = "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," + data.arquivoConteudo;
        a.download = data.arquivoNome;
        a.click();
      });
    }, (err: any) => {
      this.ui.loaded().subscribe(() => {
        this.openSnackBarWarning('Atenção', err.error);
      })
    });
  }

  visualizarClicked(o: { pesquisa: { id: any; }; }) {
    this.toastr.warning("Em desenvolvimento", "Portal Credvalue")
  }

  async verificaPesquisaExistente(): Promise<boolean> {
    this.formPesquisa.numero = this.docsAdded[0].replaceAll('.', '').replaceAll('-', '').replaceAll('/', '');
    this.verificaTipoDocumento();
    try {
      const response = await this.pesquisaService.getPesquisaRealizada(this.formPesquisa, this.requestHeader).toPromise();
      this.countPesquisaExistente = response;

      if (this.refazer || this.countPesquisaExistente.list?.pesquisa?.status === this.StatusPesquisa.Cancelada) {
        this.refazer = false;
        return false;
      }

      if (this.countPesquisaExistente.count > 0) {
        this.openDialog(this.countPesquisaExistente.list);
        return true;
      }

      if (this.countPesquisaExistente.count === 0) {
        // console.log('Nenhuma pesquisa existente encontrada.');
        return false;
      }

      return false;
    } catch (err) {
      this.ui.loaded().subscribe(() => {
        if (err.error) {
          this.openSnackBarWarning('Atenção', err.error);
        } else {
          this.openSnackBarWarning('Atenção', 'Ocorreu um erro inesperado.');
        }
        this.isDisabled = false;
      });
      return false;
    }
  }


  aplicarMascaraDocumento(numero: string): string {
    if (numero.length === 11) {
      // CPF: 000.000.000-00
      return `${numero.substr(0, 3)}.${numero.substr(3, 3)}.${numero.substr(6, 3)}-${numero.substr(9, 2)}`;
    } else if (numero.length === 14) {
      // CNPJ: 00.000.000/0000-00
      return `${numero.substr(0, 2)}.${numero.substr(2, 3)}.${numero.substr(5, 3)}/${numero.substr(8, 4)}-${numero.substr(12, 2)}`;
    } else {
      return numero; // Sem máscara para outros tamanhos
    }
  }


  enviarPesquisaParaProcessamento(dados) {
    this.ui.loading();

    // if (this.objMenorIdade.length > 0) {
    //   this.objValorAdicional = this.objMenorIdade;
    // }

    let objEnvio = {
      identificador: this.formPesquisa.identificador,
      origemDados: [{
        numero: this.formPesquisa.numero
      }
      ],
      produtoID: this.idProduto,
      segundoNivel: this.formPesquisa.segundoNivel,
      tags: this.addedItems,
      valorAdicional: this.objValorAdicional
    }

    let requestCount = Object.assign({}, objEnvio);
    this.refazer = false;
    let request = Object.assign({}, requestCount);
    this.pesquisaService.cadastrarCruzamentoDados(request).subscribe(data => {
      if (HttpStatusCode.Ok) {
        this.ui.loaded().subscribe(() => {
          this.openSnackBar('Sucesso', 'Pesquisa Realizada com Sucesso');
          this.getChips();
          this.addedItems = [];
          this.myControl.reset();
          this.isDisabled = false
        })
      }
      this.pesquisarClicked(1);
    }, (err: any) => {
      this.ui.loaded().subscribe(() => {
        this.openSnackBarWarning('Atenção', err.error);
        this.isDisabled = false
      })
    });
    this.isDisabled = false;
  }

  openDialog(pespAnterior): void {

    let dialogRef = this.dialog.open(PopPesqRealizadaComponent, {
      data: pespAnterior
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.data == true) {
        this.refazer = true;
        this.confirmarPesquisa();
      }
    });
  }

  openSnackBar(titulo: string, mensagem: string) {
    this._snackBar.openFromComponent(MensageResponseComponent, {
      data: {
        titulo: titulo,
        mensagem: mensagem,
      },
      duration: 5000,
      horizontalPosition: 'right',
      verticalPosition: 'top'
    });
  }

  openSnackBarWarning(titulo: string, mensagem: string) {
    this._snackBar.openFromComponent(MensageErrorResponseComponent, {
      data: {
        titulo: titulo,
        mensagem: mensagem,
      },
      duration: 5000,
      horizontalPosition: 'right',
      verticalPosition: 'top'
    });
  }

  clearForm() {
    this.formFiltro.nome = '';
    this.formFiltro.numero = '';
    this.formFiltro.status = '';
    this.formFiltro.identificacao = '';
    this.campaignOne.get('start').setValue(null);
    this.campaignOne.get('end').setValue(null);
    this.campaignTwo.get('start').setValue(null);
    this.campaignTwo.get('end').setValue(null);

    this.formFiltro.tipoAcao = '';
    this.formFiltro.operadorValor = '';
    this.formFiltro.autorReu = '';
    this.formFiltro.statusProcesso = '';
    if (this.isDossie || this.isProcessos) {
      this.formFiltro.valor = '';
    }
  }


  onFonteValue(event) {
    var grupoSelecionado = this.listaFonteGrupo.find(f => f.fonteGrupo.id == event.value);
    this.defineParametroseFontesSelecionaveis(grupoSelecionado);
    if (grupoSelecionado.fonteGrupo.listaFonteGrupoItem.some(fonteitem => [Fonte.CertidaoDividaUniaoImovelRural].includes(fonteitem.fonte))) {
      this.formCadastroValorAdicional = true;
      this.formCadastroValorAdicionalLabel = this.tipoLabels.get(Fonte.CertidaoDividaUniaoImovelRural);
    } else {
      this.formCadastroValorAdicional = false;
    }
  }
  ajustaNome(nome: string, tamanho: any) {
    let nomeRetorno = nome;
    if (nome.length >= tamanho) {
      nomeRetorno = nome.substring(0, tamanho - 3) + '...';
    }
    return nomeRetorno;
  }

  disableEnterKey(event: KeyboardEvent) {
    event.preventDefault(); // Previne o comportamento padrão de enviar o formulário ou executar o click
  }

  desabilitaPesquisa() {
    if (this.docsAdded.length >= 1) {
      return true
    }
    // if(this.docsAdded.length > 0){
    //   return true
    // }
    return false
  }

  verificaTipoDocumento() {
    let cnpj = 2
    let cpf = 1
    let novoTipoDocumento = null; // ou qualquer valor inicial que represente um tipo desconhecido
    if (this.formPesquisa.numero.length == 11) {
      this.formPesquisa.tipo = cpf
      novoTipoDocumento = cpf;
    }
    else if (this.formPesquisa.numero.length == 14) {
      this.formPesquisa.tipo = cnpj
      novoTipoDocumento = cnpj;
    }
    if (novoTipoDocumento !== null && novoTipoDocumento !== this.tipoDocumento) {
      // Se o tipo de documento mudou, desmarca todos os checkboxes
    }

    this.tipoDocumento = novoTipoDocumento;
  }

  adicionarAba(pesquisa) {
    const abaExistente = this.abas.find(aba => aba.titulo === pesquisa.identificacao);

    if (abaExistente) {
      // Se a aba já existe, destaque-a ou faça qualquer outra ação necessária
      this.selectedIndex = this.abas.indexOf(abaExistente);
    } else {
      this.abas.push({ titulo: pesquisa.identificacao, idpesquisa: pesquisa});
      // this.usuarioService.setTabState(this.usuario.id, this.idProduto, this.abas);
      this.selectedIndex = this.abas.length - 1;
    }
  }

  fecharAba(index: number) {
    this.abas.splice(index, 1);
    // this.usuarioService.setTabState(this.usuario.id, this.idProduto, this.abas);
  }

  atualizarIndiceAbaSelecionada(event: any) {
    this.selectedIndex = this.tabGroup.selectedIndex;
  }



  enviarParametroMenorIdade(mensagem) {
    const modalMenorIdade = this.dialog.open(ModalMenorIdadeComponent, {
      maxWidth: "470px",
      data: {
        objetoAdicional: this.objValorAdicional,
        mensagem: mensagem
      }
    })
    modalMenorIdade.afterClosed().subscribe(result => {
      if (result) {
        this.objMenorIdade = result.paramNasc;
        this.dataNasc = result.dataNasc;
        this.formPesquisa = { ...this.formPesquisa, dataNascimento: result.dataNasc }
        this.validaCodigoRetorno()
      }
      else {
        this.isDisabled = false;
        this.refazer = false
      }
    });
  }

  async confirmarPesquisa() {
    if (this.docsAdded.length == 0 && !this.formPesquisa.identificador) {
      this.openSnackBarWarning('Informação obrigatória!', 'Favor informar um CPF/CNPJ ou um Identificador para iniciar a pesquisa.');
      return;
    }
  
    this.formPesquisa.identificador = this.formPesquisa.identificador;
    this.formPesquisa.numero = this.docsAdded
    this.formPesquisa.tag = this.addedItems
    this.IniciarPesquisaSemAdicionais()
  }

  IniciarPesquisaSemAdicionais() {
    const objetoVazio = {
      filtros: {},
      parametros: {},
      fontesAdicionais: []
    }
    this.objValorAdicional = objetoVazio;
    // this.enviarPesquisaParaProcessamento()

    // this.enviarPesquisaParaProcessamento(this.objValorAdicional)
    this.validaCodigoRetorno()
  }


  validaCodigoRetorno() {
    this.ui.loading()
    let obj = {
      valorAdicional: this.objValorAdicional
    };
    let objRequest = { ...this.formPesquisa, ...obj }

    if (this.docsAdded.length > 0 || this.formPesquisa.identificador) {
      this.ui.loaded().subscribe(() => {
        this.abrirModalPreValidacaoCruzamento(objRequest);
      });
      return
    }
  }


  // =============== REFAZER PESQUISA A PARTIR DE UMA EXISTENTE ======================

  async refazerPesquisaExistente(data) {
    const valorAdicionalRefazer = JSON.parse(data.valorAdicional);

    // Chame a nova função editarTagAsync e aguarde sua conclusão
    try {
      await this.editarTagAsync(data);
      let objRefazer = {
        idFonteGrupo: data.fonteGrupo.id,
        numero: data.numero,
        tipo: data.tipo,
        nome: data.nome,
        tags: this.addedItems,
        segundoNivel: this.formPesquisa.segundoNivel,
        valorAdicional: valorAdicionalRefazer
      };
      // Chame validaCodigoRetornoRefazer após a conclusão de editarTagAsync
      this.validaCodigoRetornoRefazer(objRefazer);
    } catch (error) {
      console.error('Erro ao editar a tag:', error);
      // Lide com o erro conforme necessário
    }
  }



  validaCodigoRetornoRefazer(objetoPesquisaRefazer) {
    this.ui.loading()
    this.pesquisaService.preCadastro2(objetoPesquisaRefazer).subscribe(response => {
      if (HttpStatusCode.Ok) {
        this.ui.loaded().subscribe(() => {
          this.objetoRetornoPesquisa = response;
          if (this.objetoRetornoPesquisa.codigo === 20 || this.objetoRetornoPesquisa.codigo === 21) {
            this.enviarParametroMenorIdade(response.mensagem);
            return
          }
          if (this.objetoRetornoPesquisa.valorPesquisaAdicional > 0 || this.objetoRetornoPesquisa.valorPesquisaParametro > 0) {
            this.exibeModalValorRefazer(this.objetoRetornoPesquisa, objetoPesquisaRefazer);
            return
          }

          if (this.objetoRetornoPesquisa.codigo == 10) {
            this.enviarPesquisaParaProcessamentoRefazer(this.objetoRetornoPesquisa, objetoPesquisaRefazer)
          }
        })
      }
    }, (err: any) => {
      this.ui.loaded().subscribe(() => {
        this.openSnackBarWarning('Atenção', err.error);
        this.isDisabled = false
      })
    }
    )
  }

  editarTagAsync(value): Promise<any> {
    return new Promise((resolve, reject) => {
      let tags = value.tags;
      let dialogRef = this.dialog.open(ModalEdicaoTagComponent, {
        width: '450px',
        panelClass: 'bg-color',
        maxHeight: '600px',
        data: {
          tags: tags,
          clienteId: this.usuario.cliente.id,
          label: this.labelTag,
          idPesquisa: null,
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result !== undefined && result !== null) {
          this.addedItems = result.tags ? result.tags : [];
          resolve(result); // Resolva a Promise aqui
        } else {
          reject(result);
        }
      });
    });
  }



  enviarPesquisaParaProcessamentoRefazer(dados, objPesquisado) {
    this.ui.loading();

    if (this.objMenorIdade.length > 0) {
      this.objValorAdicional = this.objMenorIdade;
    }

    let objEnvio = {
      idFonteGrupo: objPesquisado.idFonteGrupo,
      numero: objPesquisado.numero.replaceAll('.', '').replaceAll('-', '').replaceAll('/', ''),
      produtoID: this.idProduto,
      tipo: objPesquisado.tipo,
      nome: dados.nome,
      notificacao: true,
      tags: this.addedItems,
      valorPesquisa: dados.valorPesquisa,
      valorExcedido: dados.valorExcedido,
      valorAdicional: objPesquisado.valorAdicional
    }


    this.refazer = false;
    let request = Object.assign({}, objEnvio);
    let documentoMascarado = '';
    if (request.numero.length === 11) {
      documentoMascarado = `${request.numero.substr(0, 3)}.${request.numero.substr(3, 3)}.${request.numero.substr(6, 3)}-${request.numero.substr(9, 2)}`;
    } else if (request.numero.length === 14) {
      documentoMascarado = `${request.numero.substr(0, 2)}.${request.numero.substr(2, 3)}.${request.numero.substr(5, 3)}/${request.numero.substr(8, 4)}-${request.numero.substr(12, 2)}`;
    }
    this.pesquisaService.cadastrarClienteV2(request, this.requestHeader).subscribe(data => {
      if (HttpStatusCode.Ok) {
        this.ui.loaded().subscribe(() => {
          this.openSnackBar('Sucesso', 'Pesquisa Realizada com Sucesso');
          this.getChips();
          this.addedItems = [];
          this.myControl.reset();
          this.isDisabled = false
          this.dataNasc = null
        })
      }
      this.pesquisarClicked(1);
    }, (err: any) => {
      this.ui.loaded().subscribe(() => {
        this.openSnackBarWarning('Atenção', err.error);
        this.isDisabled = false
      })
    });
    this.isDisabled = false;
    this.objMenorIdade = []
  }

  exibeModalValorRefazer(data, objEnvio) {
    const modalValor = this.dialog.open(ModalValoresPesquisaComponent, {
      maxWidth: "750px",
      minWidth: "650px",
      data: {
        adicionais: this.objValorAdicional,
        dados: data
      }
    })
      .afterClosed().subscribe(result => {
        if (result) {
          this.enviarPesquisaParaProcessamentoRefazer(data, objEnvio)
        }
        else {
          this.IniciarPesquisaSemAdicionais
        }
      });
  }

  onSelectOpenedChanged(isOpened: boolean) {
    if (isOpened) {
      setTimeout(() => {
        this.selectPanel.panel.nativeElement.addEventListener('click', this.stopClickPropagation, true);
      });
    } else {
      this.selectPanel.panel.nativeElement.removeEventListener('click', this.stopClickPropagation, true);
    }
  }

  stopClickPropagation(event: MouseEvent) {
    event.stopPropagation();
  }

  onEnterDoc(): void {
    event.preventDefault();
    const input = event.target as HTMLInputElement;
    const value = input.value.trim();
    if (value.length == 0) return;
    if (value.length == 14 || value.length == 18) {
      if (this.docsAdded.includes(value)) {
        this.openSnackBarWarning('Atenção', 'Documento já adicionado.');
      } else {
        this.docsAdded.push(value);
        input.value = '';
        this.docControl.setValue('');
      }
    } else {
      this.openSnackBarWarning('Atenção', 'Documento inválido. Por favor, insira um CPF ou CNPJ válido.');
    }
  }

  clearChips() {
    this.docsAdded = [];
  }

  removeChipDoc(doc: string): void {
    const index = this.docsAdded.indexOf(doc);
    if (index >= 0) {
      this.docsAdded.splice(index, 1);
    }
  }

  abrirModalPreValidacaoCruzamento(objPesquisa) {
    const modal = this.dialog.open(ModalValidacaoCruzamentoComponent, {
      minWidth: "800px",
      data: {
        docs: this.docsAdded,
        objPesquisa: objPesquisa,
        addedItems: this.addedItems
      }
    })
    this.formPesquisa.identificador = ""
    modal.afterClosed().subscribe(result => {
      console.log(this.formPesquisa)
      if (result == true) {
        this.pesquisarClicked();
      }
    });
  }

  onPasteDoc(event: ClipboardEvent): void {
    const clipboardData = event.clipboardData;
    const pastedText = clipboardData?.getData('text');
  
    if (pastedText) {
      const documentos = pastedText.split(/[\s,]+/); // Separa por espaços ou vírgulas
      documentos.forEach(doc => {
        const documento = doc.trim(); // Remove espaços em branco
  
        if (this.isValidCPFOrCNPJ(documento)) {
          const documentoFormatado = this.aplicarMascaraDocumentoColado(documento);
          this.addDocument(documentoFormatado);
        }
      });
    }
  
    event.preventDefault(); // Previne a colagem padrão
    this.docControl.reset(); // Reseta o campo de input após a colagem
  }
  

  isValidCPFOrCNPJ(documento: string): boolean {
    // Remove todos os caracteres não numéricos
    const doc = documento.replace(/\D/g, ''); // Mantém apenas números
  
    if (doc.length === 11) {
      return this.isValidCPF(doc); // Valida CPF
    } else if (doc.length === 14) { 
      return this.isValidCNPJ(doc); // Valida CNPJ
    }
  
    return false; // Documento inválido
  }


  isValidCPF(cpf: string): boolean {
    if (cpf.length !== 11 || /^(\d)\1+$/.test(cpf)) return false;
  
    let soma = 0;
    for (let i = 0; i < 9; i++) {
      soma += parseInt(cpf[i]) * (10 - i);
    }
    let resto = (soma * 10) % 11;
    if (resto === 10 || resto === 11) resto = 0;
    if (resto !== parseInt(cpf[9])) return false;
  
    soma = 0;
    for (let i = 0; i < 10; i++) {
      soma += parseInt(cpf[i]) * (11 - i);
    }
    resto = (soma * 10) % 11;
    if (resto === 10 || resto === 11) resto = 0;
  
    return resto === parseInt(cpf[10]);
  }
  
  isValidCNPJ(cnpj: string): boolean {
    if (cnpj.length !== 14) return false;
  
    const weights = [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];
    let soma = 0;
  
    for (let i = 0; i < 12; i++) {
      soma += parseInt(cnpj[i]) * weights[i + 1];
    }
    let resto = soma % 11;
    if (resto < 2) resto = 0;
    else resto = 11 - resto;
    if (resto !== parseInt(cnpj[12])) return false;
  
    soma = 0;
    for (let i = 0; i < 13; i++) {
      soma += parseInt(cnpj[i]) * weights[i];
    }
    resto = soma % 11;
    if (resto < 2) resto = 0;
    else resto = 11 - resto;
  
    return resto === parseInt(cnpj[13]);
  }

    // Função para aplicar a máscara de CPF ou CNPJ
    aplicarMascaraDocumentoColado(doc: string): string {
      const apenasNumeros = doc.replace(/\D/g, '');
    
      if (apenasNumeros.length === 11) {
        // Aplicar máscara CPF
        return apenasNumeros.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
      } else if (apenasNumeros.length === 14) {
        // Aplicar máscara CNPJ
        return apenasNumeros.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
      }
    
      // Retorna o valor original se não for CPF ou CNPJ
      return doc;
    }

  addDocument(document: string): void {
    if (document && !this.docsAdded.includes(document)) {
      this.docsAdded.push(document);
    }
  }

  getStatusText(status: number): string {
    return this.statusMap[status] || 'Desconhecido';
  }
}
