<div class="text-center p-2 header">Solicitação de pesquisa {{produto}}</div>
<div class="mt-5">
    <div>
        <p class="textConfirm">As fontes selecionadas serão deduzidas do seu pacote mensal</p>
        <div class="d-flex justify-content-between">
            <p class="textConfirm2">Total  <span class="valorAzul">R${{valorTotal.toFixed(2)}}</span></p>
            <p class="textConfirm2">Deseja continuar?</p>
        </div>
    </div>
    <div class="d-flex justify-content-between mt-2">
        <button class="btn btn-primary btn-cancel" mat-dialog-close (click)="fecharModal()">Cancelar</button>
        <button class="btn btn-primary btn-go" (click)="realizarPesquisa()">Confirmar</button>
    </div>
</div>