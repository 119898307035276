import { HttpStatusCode } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { ProdutosEnum } from 'src/app/shared/enums/produtos.enum';
import { FonteGrupoService } from 'src/app/shared/services/http/fonte-grupo.service';
import { PesquisaService } from 'src/app/shared/services/http/pesquisa.service';
import { UtilService } from 'src/app/shared/services/http/util.service';
import { UiService } from 'src/app/shared/services/ui.service';
import { Textos } from 'src/app/shared/textos';
import { ModalGrupoPesquisaComponent } from 'src/app/views/teia-relacionamento/menu-teia-relacionamento/acoes-menu/modal-grupo-pesquisa/modal-grupo-pesquisa.component';
import { MensageResponseComponent } from '../../../mensage-response/mensage-response.component';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-dados-cadastrais-completos',
  templateUrl: './dados-cadastrais-completos.component.html',
  styleUrls: ['./dados-cadastrais-completos.component.scss']
})
export class DadosCadastraisCompletosComponent implements OnInit {

  panelOpenState = false;

  isCpf = false;
  isCnpj = false;
  @Input() objetoRelatorioCadastral: any;
  @Input() objetoNovaPesquisa: any
  @Input() doc: any;
  paramAtivos;
  nome

  isNovaPesquisa = false;
  pesquisaExistente = false;
  
  constructor(
    public dialog: MatDialog,
    private fonteGrupoService: FonteGrupoService,
    private ui: UiService,
    private toastr: ToastrService,
    private utilService: UtilService,
    private pesquisaService: PesquisaService,
    private _snackBar: MatSnackBar,
  ) { }

  ngOnInit(): void {
    if (this.objetoNovaPesquisa && this.objetoNovaPesquisa.tipoDocumento) {
      this.isNovaPesquisa = true;
    
      if (this.objetoNovaPesquisa.tipoDocumento === 2) {
        this.isCnpj = true;
      }
      if (this.objetoNovaPesquisa.tipoDocumento === 1) {
        this.isCpf = true;
      }
    }
    
    if (this.objetoRelatorioCadastral && (this.objetoRelatorioCadastral.cnpj || this.objetoRelatorioCadastral.cpf)) {
      this.pesquisaExistente = true;
      if (this.objetoRelatorioCadastral.cnpj) {
        this.isCnpj = true;
      }
      if (this.objetoRelatorioCadastral.cpf) {
        this.isCpf = true;
      }
    }
  }

  verificaSeTemProdutoVinculado(produtoEnum, doc, requestHeader){
    this.fonteGrupoService.listAtivoProduto(produtoEnum).subscribe(response => {
      this.paramAtivos = response
       if(this.paramAtivos.length >= 1){
        this.openGrupoPesquisa(response, produtoEnum, doc, requestHeader)
       }
      if(this.paramAtivos.length == 0){
        this.toastr.warning('Usuário sem vinculos de pesquisa, entre em contato com o suporte', 'Detech')
       }
     }, (err:any) => {
      this.ui.loaded().subscribe(() => {
        return
      })
    });
  }

  openGrupoPesquisa(grupos, produtoEnum, doc, requestHeader){
    const dialog =  this.dialog.open(ModalGrupoPesquisaComponent, {
      width: '380px',
      data: {
        idProduto: produtoEnum,
        grupos: grupos,
        doc: doc,
        header: requestHeader
      }
    });
  }

  realizarPesquisa(tipoPesquisa ,idPesquisa: number, doc){
    this.ui.loading()
       // verifica o tipo do documento 
    let tipoDocumento = doc.replaceAll('.','').replaceAll('-','').replaceAll('/','');
    if(tipoDocumento.length == 11){
      tipoDocumento = 1
    }
    if(tipoDocumento.length == 14){
      tipoDocumento = 2
    }
    
    const request = {
      nome: '',
      notificacao: true,
      numero: doc.replaceAll('.','').replaceAll('-','').replaceAll('/',''),
      tipo: tipoDocumento
    }

    this.utilService.resolverNome(request, "").subscribe(resultadoResolverNome => {
      if(!resultadoResolverNome || !resultadoResolverNome.nome) {
          this.ui.loaded().subscribe(() => {
              this.nome = "";
              this.toastr.warning(Textos.NOME_NAO_ENCONTRADO, "Portal Credvalue")
          });
          return;
      }
       const requestPesquisa = {
        idFonteGrupo: idPesquisa,
        nome: resultadoResolverNome.nome,
        notificacao: true,
        numero: doc.replaceAll('.','').replaceAll('-','').replaceAll('/',''),
        tipo: tipoDocumento
      }
       this.pesquisaService.cadastrarCliente(requestPesquisa).subscribe(data => {
        if (HttpStatusCode.Ok) {
          this.ui.loaded().subscribe(() => {
            if(tipoPesquisa == ProdutosEnum.Cadastrais){
              this.openSnackBar('Sucesso', 'Pesquisa Cadastral Lançada com Sucesso');
            }
            if(tipoPesquisa == ProdutosEnum.Processo){
              this.openSnackBar('Sucesso', 'Pesquisa de Processos Lançada com Sucesso');
            }
            if(tipoPesquisa == ProdutosEnum.Patrimonial){
              this.openSnackBar('Sucesso', 'Pesquisa Patrimonial Lançada com Sucesso');
            }
            if(tipoPesquisa == ProdutosEnum.Credito){
              this.openSnackBar('Sucesso', 'Pesquisa de Crédito Lançada com Sucesso');
            }
            if(tipoPesquisa == ProdutosEnum.Dossie){
              this.openSnackBar('Sucesso', 'Pesquisa de Dossiê Completo Lançada com Sucesso');
            }
          })
        }
      });
    }, (err:any) => {
      this.ui.loaded().subscribe(() => {
        this.toastr.info('Ocorreu um erro ao realizar a pesquisa, tente novamente.')
      })
    });
  }

  openSnackBar(titulo: string, mensagem: string) {
    this._snackBar.openFromComponent(MensageResponseComponent, {
       data: {
         titulo: titulo,
         mensagem: mensagem,
       },
       duration: 5000,
       horizontalPosition: 'right',
       verticalPosition: 'top'
    });
   }

   formatarData(data: string | null | undefined): string {
    if (!data || data.startsWith('0001-01-01')) {
      return '-';
    }
    return new Date(data).toLocaleDateString('pt-BR');
  }
}