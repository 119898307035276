import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { PesquisaService } from '../../shared/services/http/pesquisa.service';
import { StatusRegistro } from '../../shared/enums/status-registro.enum';
import { TipoPesquisa } from '../../shared/enums/tipo-pesquisa.enum';
import { UiService } from '../../shared/services/ui.service';
import { ConfiguracaoService } from '../../shared/services/http/configuracao.service';
import { FonteGrupoService } from '../../shared/services/http/fonte-grupo.service';
import { UtilService } from '../../shared/services/http/util.service';
import { ToastrService } from 'ngx-toastr';
import { Observable, forkJoin, map, startWith } from 'rxjs';
import { Textos } from '../../shared/textos';
import { Fonte } from '../../shared/enums/fonte.enum';
import { MatDialog } from '@angular/material/dialog';
import { PopPesqRealizadaComponent } from './../../components/view/poppesqrealizada/poppesqrealizada.component';
import { ActivatedRoute, Router } from '@angular/router';
import { StatusPesquisa } from 'src/app/shared/enums/status-pesquisa.enum';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { HttpStatusCode } from '@angular/common/http';
import { ModalConfirmacaoNirfComponent } from './modal-confirmacao-nirf/modal-confirmacao-nirf.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MensageResponseComponent } from 'src/app/components/view/mensage-response/mensage-response.component';
import { ProdutosEnum } from 'src/app/shared/enums/produtos.enum';
import { MensageErrorResponseComponent } from 'src/app/components/view/mensage-error-response/mensage-error-response.component';
import { ModalConfirmacaoComponent } from 'src/app/components/view/modal-confirmacao/modal-confirmacao.component';
import { AuthService } from 'src/app/shared/services/http/auth.service';
import { ModalEdicaoTagComponent } from 'src/app/components/view/modal-edicao-tag/modal-edicao-tag.component';

const today = new Date();
const month = today.getMonth();
const year = today.getFullYear();

@Component({
  selector: 'app-certidoes',
  templateUrl: './certidoes.component.html',
  styleUrls: ['./certidoes.component.scss']
})

export class CertidoesComponent implements OnInit {

  formCadastro: FormGroup;
  formCadastroNome: boolean = false;
  Fonte = Fonte;

  exibirFormFiltros: boolean = false;
  formFiltros: any[] = [];

  exibirFormParametros: boolean = false;
  formParametros: any[] = [];
  isChecked: boolean = false
  emailChecked = true;
  itemChecked = true;
  allChecked = true;
  totalItens = []
  filtros = [];
  listaFonteGrupo = [];
  listaFontesAdicionais = [];
  allComplete: boolean = false;
  exibeToast = false;
  temParametro = true

  myControl = new FormControl();
  tagIds = [];
  addedItems = [];
  autoCompletePanel: any;
  labelTag: String = 'Tag';
  filteredTags: Observable<any[]>;
  usuario;
  isSuzano = false

  campaignOne = new FormGroup({
    start: new FormControl(new Date(year, month, 13)),
    end: new FormControl(new Date(year, month, 16)),
  });
  campaignTwo = new FormGroup({
    start: new FormControl(new Date(year, month, 15)),
    end: new FormControl(new Date(year, month, 19)),
  });

  formFiltro: any = { page: 1, pageSize: 50, perfil: "", status: "", produtoID: 6 };
  formFiltroRenderizado: any = this.formFiltro;
  resultadoPesquisa: any = [];
  resultadoTablePesquisa = []
  StatusPesquisa = StatusPesquisa;
  disabled = false;

  constructor(
    private router: Router,
    protected fb: FormBuilder,
    private route: ActivatedRoute,
    private pesquisaService: PesquisaService,
    private utilService: UtilService,
    private toastrService: ToastrService,
    private ui: UiService,
    public dialog: MatDialog,
    private toastr: ToastrService,
    private authService: AuthService,
    private _snackBar: MatSnackBar,
    private fonteGrupoService: FonteGrupoService) { }

  ngOnInit(): void {
    this.getUsuario()
    this.formCadastro = this.fb.group({
      id: this.fb.control('', []),
      tipo: this.fb.control('', []),
      numero: this.fb.control('', Validators.required),
      nome: this.fb.control(''),
      notificacao: this.fb.control(true),
      valorAdicional: this.fb.control('', []),
      tipoFiltro: this.fb.control('', []),
      idFonteGrupo: this.fb.control('', Validators.required),
      fontesSelecionaveis: this.fb.array([
        this.fb.group({
          selecionado: [{ value: false }]
        })], Validators.required),
      selecionarTodasFontesOpcionais: this.fb.control(true, []),
    });
    this.pesquisarClicked()
    this.route.params.subscribe(params => {
      this.fonteGrupoService.listAtivoProduto(ProdutosEnum.Certidoes).subscribe(data => {
       this.listaFonteGrupo = data
        if (!params['id']) {
          this.formCadastro.patchValue({ idFonteGrupo: this.listaFonteGrupo[0].fonteGrupo.id });
          this.onFonteValue(this.listaFonteGrupo[0].fonteGrupo.id);
        }
      });

      if (!params['id']) {
        this.ui.loaded().subscribe();
        return;
      }

      this.ui.loading();
      this.pesquisaService.get(params['id']).subscribe(u => {
        this.ui.loaded().subscribe(() => {
          u.tipo = u.tipo.toString();
          u.idFonteGrupo = u.fonteGrupo.id;
          this.formCadastro.patchValue(u);
          this.numeroChanged(this.formCadastro.value.tipo);
        });
      });
    });
    this.getChips();
    this.filteredTags = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filterTags(value))
    );
  }

  editarTag(value){
    let tags = value.tags
    let IdPesquisa = value.id
    let dialogRef = this.dialog.open(ModalEdicaoTagComponent,{
      width: '450px',
      panelClass: 'bg-color',
      maxHeight: '600px',
      data: {
        tags: tags,
        clienteId: this.usuario.cliente.id,
        label: this.labelTag,
        idPesquisa: IdPesquisa
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == true) {
       this.pesquisarClicked();
       this.getChips();
      }
    });
  }

  private _filterTags(value: string): any[] {
    console.log(value)
    const filterValue = value.toLowerCase();
    return this.tagIds.filter(tag => tag.tag.toLowerCase().includes(filterValue));
  }

  getUsuario(){
    this.authService.callbackUsuario().subscribe(usuario => {
      if(!usuario) {
        return;
      }
      if (usuario) {
        this.usuario = usuario;
        if(usuario.cliente.descricaoTag && usuario.cliente.descricaoTag.trim().length > 0){
          this.labelTag = usuario.cliente.descricaoTag;
        }
      }
      if(usuario.cliente.id == 52){
        this.isSuzano = true
      }
    })
  }

  addChip(value) {
    this.myControl.reset();
    if (!this.addedItems.find(item => item.tag === value.tag)) {
      this.addedItems.push(value);
    }
    this.tagIds = this.tagIds.filter(item => item.tag !== value.tag);
    this.filteredTags = this.filteredTags.pipe(
      map(tags => tags.filter(tag => tag.tagId !== value.tagId))
    );
    this.myControl.reset();
  }

  removeChip(item): void {
    const index = this.addedItems.indexOf(item);
  if (index !== -1) {
    this.addedItems.splice(index, 1);
    this.tagIds.push(item);  // Adiciona o item de volta ao array tagIds
    this.filteredTags = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filterTags(value))
    );
  }
  }

  enviarChips(value) {
    this.ui.loading();
    const obj = {
      clienteId: this.usuario.cliente.id,
      tags: [{
        tagId: 0,
        tag: value
      }]
    }
    this.pesquisaService.cadastrarTagId(obj).subscribe(response => {
      this.ui.loaded().subscribe(() => {
        this.addedItems.push(response[0])
        this.filteredTags = this.myControl.valueChanges.pipe(
          startWith(''),
          map(value => this._filterTags(value))
        );
        this.myControl.reset();
      })
    }, (err:any) => {
      this.ui.loaded().subscribe(() => {
          this.toastr.error('Ocorreu um erro, tente novamente.')
      })
    });
  }

  onEnter(event: KeyboardEvent): void {
    if (document.activeElement === event.target) {
      event.preventDefault();

      const value = this.myControl.value.trim();
      const valueAdded = this.addedItems.map(x => x.tag)
      const valueGetted = this.tagIds.map(x => x.tag)
      if (value !== '' && !valueAdded.includes(value) && valueGetted.includes(value)) {
        let tagEnter = this.tagIds.filter(x => x.tag == value);
        this.addChip(tagEnter[0])
      }
       if (value !== '' && !valueAdded.includes(value) && !valueGetted.includes(value)) {
        this.enviarChips(value)
      }

      this.myControl.reset();
    }
  }
  onInputBlur(): void {
    const value = this.myControl.value.trim();
      const valueAdded = this.addedItems.map(x => x.tag)
      const valueGetted = this.tagIds.map(x => x.tag)
      if (value !== '' && !valueAdded.includes(value) && !valueGetted.includes(value)) {
        this.enviarChips(value)
      }

      this.myControl.reset();
  }

  getChips() {
    this.ui.loading()
    this.pesquisaService.getTagID(this.usuario.cliente.id).subscribe(response => {
      this.ui.loaded().subscribe(() => {
        this.tagIds = response
      })
    }, (err:any) => {
      this.ui.loaded().subscribe(() => {
          this.toastr.error('Ocorreu um erro, tente novamente.')
      })
    });
  }

  deletarTagExistente(value) {
    const dados = {
      title: '',
      message: 'Deseja realmente excluir esse item?',
      cancelText: 'Cancelar',
      confirmText: 'excluir'
    }
    const dialogRef = this.dialog.open(ModalConfirmacaoComponent, {
      maxWidth: "470px",
      data: dados
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult == true) {
        this.ui.loading()
        this.pesquisaService.excluirTag(value.tagId).subscribe(response => {
          this.ui.loaded().subscribe(() => {
            // this.tagIds = response
            this.toastr.info('Tag excluída com sucesso.', 'CredValue');
            this.tagIds = this.tagIds.filter(i => i.tagId != value.tagId);
            this.filteredTags = this.filteredTags.pipe(
              map(tags => tags.filter(tag => tag.tagId != value.tagId))
            );
            this.getChips()
          })
        }, (err:any) => {
          this.ui.loaded().subscribe(() => {
              this.toastr.error('Ocorreu um erro, tente novamente.')
          })
        });
      }
    });

  }

  onScroll() {
    let page = this.formFiltro.page + 1;
    this.pesquisarClicked(page);

  }

  onScrollUp() {
    let page = this.formFiltro.page - 1;
    if (page > 0) {
      this.pesquisarClicked(page);
    }
  }

  exportarClicked(o: { pesquisa: { id: any; }; }) {
    this.ui.loading();
    this.pesquisaService.exportar({ id: o.pesquisa.id }, "certidoes").subscribe(data => {
      this.ui.loaded().subscribe(() => {
        var a = document.createElement("a");
        a.href = "data:application/pdf;base64," + data.arquivoConteudo;
        a.download = data.arquivoNome;
        a.click();
      });
    }, (err:any) => {
      this.ui.loaded().subscribe(() => {
        this.openSnackBarWarning('Atenção', err.error);
        console.log(err.error);
      })
    });
  }

  pdfNaoConcluido() {
    this.toastr.info("A PESQUISA AINDA NAO FOI FINALIZADA", "Portal Credvalue")
  }

  exportarReciboClicked(o: { pesquisa: { id: any; }; }) {
    this.ui.loading();
    this.pesquisaService.exportarRecibo({ id: o.pesquisa.id }, "certidoes").subscribe(data => {
      this.ui.loaded().subscribe(() => {
        var a = document.createElement("a");
        a.href = "data:application/pdf;base64," + data.arquivoConteudo;
        a.download = data.arquivoNome;
        a.click();
      });
    }, (err:any) => {
      this.ui.loaded().subscribe(() => {
        this.openSnackBarWarning('Atenção', err.error);
        console.log(err.error);
      })
    });
  }

  exportarExcelClicked(o: { pesquisa: { id: any; }; }) {
    this.ui.loading();
    this.pesquisaService.exportarExcel({ id: o.pesquisa.id }, "certidoes").subscribe(data => {
      this.ui.loaded().subscribe(() => {
        var a = document.createElement("a");
        a.href = "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," + data.arquivoConteudo;
        a.download = data.arquivoNome;
        a.click();
      });
    }, (err:any) => {
      this.ui.loaded().subscribe(() => {
        this.openSnackBarWarning('Atenção', err.error);
        console.log(err.error);
      })
    });
  }

  // visualizarClicked(o: { pesquisa: { id: any; }; }) {
  //   this.toastr.warning("Em desenvolvimento", "Portal Credvalue")
  // }

  pesquisarClicked(page = 1) {

    this.formFiltro.page = page;
    this.ui.loading();

    if (page == 1)
      this.resultadoPesquisa = [];

    let request = Object.assign({}, this.formFiltro);
    if (request.status) request.status = [request.status];
    this.pesquisaService.listPesquisaCliente(request).subscribe(response => {
      this.formFiltroRenderizado = Object.assign({}, this.formFiltro);
      this.ui.loaded().subscribe(() => {
        this.resultadoPesquisa = this.resultadoPesquisa.concat(response.list);
        this.resultadoTablePesquisa = this.resultadoPesquisa.map(x => x.pesquisa)
      })
    })
  }

  clearForm() {
    this.formFiltro.nome = '';
    this.formFiltro.numero = '';
    this.formFiltro.status = '';
    this.formFiltro.tag = '';
    this.campaignOne.get('start').setValue(null);
    this.campaignOne.get('end').setValue(null);
    this.campaignTwo.get('start').setValue(null);
    this.campaignTwo.get('end').setValue(null);
  }

  ajustaNome(nome: string, tamanho: any) {
    let nomeRetorno = nome;
    if (nome.length >= tamanho) {
      nomeRetorno = nome.substring(0, tamanho - 3) + '...';
    }
    return nomeRetorno;
  }

  checkEmail(event) {
    if (event.checked) {
      this.emailChecked = true
      this.formCadastro.get('notificacao').setValue(true)
    }
    else {
      this.emailChecked = false
      this.formCadastro.get('notificacao').setValue(false)
    }
  }

  disableAddFiltro() {
    let param = (this.formFiltros).map(x => x.key)
    if (!param) {
      return true
    }
    else {
      return false
    }
  }



  adicionarFiltro() {
    var filtro = [];
    this.formFiltros.forEach(param => {
      filtro.push({ key: param.key, value: this.formCadastro.get(param.key).value });
      this.formCadastro.get(param.key).setValue([]);
    });
    this.filtros.push(filtro)
  }

  limparFiltros() {
    this.formFiltros.forEach(param => {
      this.formCadastro.get(param.key).reset();
    });
  }

  numeroChanged(event) {
    this.formCadastro.get("numero").clearValidators();
    if (event == TipoPesquisa.CPF) {
      this.formCadastro.get("numero").setValidators([Validators.required, Validators.pattern(/^\d{11}$/)]);
    } else {
      this.formCadastro.get("numero").setValidators([Validators.required, Validators.pattern(/^\d{14}$/)]);
    }
  }

  cadastrarClicked() {
    let executarRequisicao = () => {
      Object.keys(this.formCadastro.controls).forEach(f => this.formCadastro.get(f).markAsDirty({ onlySelf: true }));
      if (!this.formCadastro.valid) {
        this.ui.loaded().subscribe();
        return;
      }

      this.ui.loading();
      let request = this.formCadastro.value;

      request.valorAdicional = { filtros: {}, parametros: {} };

      this.formParametros.forEach(param => {
        request.valorAdicional.parametros[param.key] = this.formCadastro.get(param.key).value;
        this.formCadastro.removeControl(param.key)
      });


      this.filtros.forEach(value => {
        request.valorAdicional.filtros[this.filtros.indexOf(value).toString()] = value;
      });

      request.valorAdicional.fontesAdicionais = this.formCadastro.value.fontesSelecionaveis.map(f => f.fonte.value.fonte);
      this.formCadastro.removeControl("fontesSelecionaveis")
      this.formCadastro.removeControl("selecionarTodasFontesOpcionais")

      this.formFiltros.forEach(param => {
        this.formCadastro.removeControl(param.key)
      });

      if (this.formCadastro.value.id) {
        this.pesquisaService.atualizar(request).subscribe(response => {
          this.router.navigate(['pages/pesquisas']);
        });
      } else {
        this.pesquisaService.cadastrarCliente(request).subscribe(response => {
          this.router.navigate(['pages/pesquisas']);
        });
      }
    };


    if (!this.formCadastroNome) {
      this.ui.loading();
      this.utilService.resolverNome(this.formCadastro.value, "certidoes").subscribe(resultadoResolverNome => {
        if (!resultadoResolverNome || !resultadoResolverNome.nome) {
          this.ui.loaded().subscribe(() => {
            this.formCadastro.patchValue({ nome: "" });
            this.formCadastro.get('nome').markAsDirty({ onlySelf: true });
            this.formCadastroNome = true;
            this.ui.warning(Textos.NOME_NAO_ENCONTRADO);
          });
          return;
        }
        this.formCadastro.patchValue(resultadoResolverNome);
        executarRequisicao();
      }, (err:any) => {
        this.ui.loaded().subscribe(() => {
          this.openSnackBarWarning('Atenção', err.error);
          console.log(err.error);
        })
      });
    } else {
      executarRequisicao();
    }
  }

  cadastrar() {
    console.log(this.formCadastro.value.fontesSelecionaveis)
    let requestComParametro = this.formCadastro.get('fontesSelecionaveis').value.find(x => x.fonte.value.fonteConfiguracao.configuracoesAdicionais)
    let numNirf = this.formCadastro.value.NIRF
    let imovelRural = this.formCadastro.get('fontesSelecionaveis').value.find(x => x.fonte.value.fonte == Fonte.CertidaoDividaUniaoImovelRural)
    if (requestComParametro && !numNirf && imovelRural) {
      this.openConfirmacaoSemNirf()
    }
    else {
      this.cadastrarIniciarAndamentoClicked()
    }
  }

  cadastrarIniciarAndamentoClicked() {
    var filtroValido = !this.formFiltros.some(formFiltro =>
      formFiltro.required) || this.formFiltros.some(formFiltro =>
        formFiltro.required && this.filtros.some(filtro => filtro.some(item => item.key == formFiltro.key)))

    if (!filtroValido) {
      this.ui.error("Filtros obrigátorios não preenchidos");
      return;
    }

    let executarRequisicao = () => {
      Object.keys(this.formCadastro.controls).forEach(f => this.formCadastro.get(f).markAsDirty({ onlySelf: true }));
      if (!this.formCadastro.valid) {
        this.ui.loaded().subscribe();
        return;
      }

      this.ui.loading();
      let request = this.formCadastro.value;
      request.valorAdicional = { filtros: {}, parametros: {} };

      this.formParametros.forEach(param => {
        request.valorAdicional.parametros[param.key] = this.formCadastro.get(param.key).value;
        this.formCadastro.removeControl(param.key)
      });


      this.filtros.forEach(value => {
        request.valorAdicional.filtros[this.filtros.indexOf(value).toString()] = value;
      });

      request.valorAdicional.fontesAdicionais = this.formCadastro.value.fontesSelecionaveis.map(f => f.fonte.value.fonte);
      this.formCadastro.removeControl("fontesSelecionaveis")
      this.formCadastro.removeControl("selecionarTodasFontesOpcionais")

      this.formFiltros.forEach(param => {
        this.formCadastro.removeControl(param.key)
      });

      if (this.formCadastro.value.id) {
        this.pesquisaService.atualizar(request).subscribe(response => {

          this.ui.loaded().subscribe(() => {
          });

        });
      } else {
        this.pesquisaService.cadastrarCliente(request).subscribe(response => {
          if (HttpStatusCode.Ok) {
            this.ui.loaded().subscribe(() => {
              this.openSnackBar('Sucesso', 'Pesquisa Realizada com Sucesso');
              this.ngOnInit()
            });
          }

        });
      }
    };


    if (!this.formCadastroNome) {
      this.ui.loading();
      this.utilService.resolverNome(this.formCadastro.value, "certidoes").subscribe(resultadoResolverNome => {
        if (!resultadoResolverNome || !resultadoResolverNome.nome) {
          this.ui.loaded().subscribe(() => {
            this.formCadastro.patchValue({ nome: "" });
            this.formCadastro.get('nome').markAsDirty({ onlySelf: true });
            this.formCadastroNome = true;
            this.ui.warning(Textos.NOME_NAO_ENCONTRADO);
          });
          return;
        }
        this.formCadastro.patchValue(resultadoResolverNome);
        executarRequisicao();
      }, (err:any) => {
        this.ui.loaded().subscribe(() => {
          this.openSnackBarWarning('Atenção', err.error);
          console.log(err.error);
        })
      });
    } else {
      executarRequisicao();
    }
  }

  openSnackBar(titulo: string, mensagem: string) {
    this._snackBar.openFromComponent(MensageResponseComponent, {
      data: {
        titulo: titulo,
        mensagem: mensagem,
      },
      duration: 5000,
      horizontalPosition: 'right',
      verticalPosition: 'top'
    });
  }

  openSnackBarWarning(titulo: string, mensagem: string) {
    this._snackBar.openFromComponent(MensageErrorResponseComponent, {
      data: {
        titulo: titulo,
        mensagem: mensagem,
      },
      duration: 5000,
      horizontalPosition: 'right',
      verticalPosition: 'top'
    });
  }

  openConfirmacaoSemNirf() {
    const menu = this.dialog.open(ModalConfirmacaoNirfComponent, {
      width: '600px',
      panelClass: 'bg-color',
      data: 1
    });
    menu.afterClosed().subscribe(result => {
      if (menu.componentInstance.enviar == true) {
        this.cadastrarIniciarAndamentoClicked()
      }
    })
  }

  cancelarClicked() {
    this.router.navigate(['pages/pesquisas']);
  }

  cancelarInputNomeClicked() {
    this.formCadastroNome = false;
  }

  onFonteValue(value) {
    value = this.formCadastro.get('idFonteGrupo').value
    var grupoSelecionado = this.listaFonteGrupo.find(x => x.fonteGrupo.id == value)
    this.formFiltros = [];
    this.formParametros = [];
    this.filtros = [];
    this.listaFontesAdicionais = [];
    let chkArray = <FormArray>this.formCadastro.get('fontesSelecionaveis');
    chkArray.clear()

    grupoSelecionado.fonteGrupo.listaFonteGrupoItem.forEach(fonteItem => {
      if (!fonteItem.fonteSelecionavel)
        return;

      if (!this.listaFontesAdicionais.some(x => x.fonteConfiguracao.nome == fonteItem.fonteConfiguracao.nome)) {
        chkArray.push(this.fb.group({
          selecionado: [{ value: true }],
          fonte: [
            {
              value: fonteItem,
            }
          ],
        }))
        this.listaFontesAdicionais.push(fonteItem);
        this.listaFontesAdicionais.sort((a,b) => a.fonteConfiguracao.nome > b.fonteConfiguracao.nome ? 1 : -1)
      }
    });

    // if (this.listaFontesAdicionais.length > 0) {
    //     this.ui.ok("Existem fontes opcionais selecionadas para este pacote que podem custar um valor adicional. Verique abaixo os valores de cada item e o valor total. Você pode desmarcar a qualquer momento o que não for do seu interesse", () => {});
    // }

    this.exibirFormFiltros = grupoSelecionado.fonteGrupo.listaFonteGrupoItem.some(fonteitem => {
      var configuracoesAdicionais = fonteitem.fonteConfiguracao.configuracoesAdicionais;

      if (configuracoesAdicionais == null || configuracoesAdicionais == "")
        return false;

      configuracoesAdicionais = JSON.parse(configuracoesAdicionais);

      return configuracoesAdicionais.filtros != null;
    });

    if (this.exibirFormFiltros) {
      grupoSelecionado.fonteGrupo.listaFonteGrupoItem.forEach(fonteitem => {
        var configuracoesAdicionais = fonteitem.fonteConfiguracao.configuracoesAdicionais;

        if (configuracoesAdicionais == null || configuracoesAdicionais == "")
          return;

        configuracoesAdicionais = JSON.parse(configuracoesAdicionais);

        var temFiltros = configuracoesAdicionais.filtros != null;

        if (temFiltros) {
          this.exibirFormFiltros = true;
          Object.keys(configuracoesAdicionais.filtros).forEach((key) => {
            var form: any = {};
            if (this.formFiltros.some(x => x.key == key)) {
              form = this.formFiltros.find(x => x.key == key);
              if (form.valor != null && Array.isArray(form.valor) && !form.valor.includes(configuracoesAdicionais.filtros[key].valor))
                form.valor.push(configuracoesAdicionais.filtros[key].valor)
              return;
            }

            form = { valor: [configuracoesAdicionais.filtros[key].valor], key: key, title: configuracoesAdicionais.filtros[key].title, required: configuracoesAdicionais.filtros[key].required };

            this.formFiltros.push(form)

            this.formCadastro.addControl(key, this.fb.control([], []),)
          })
        }
      });
    }

    this.exibirFormParametros = grupoSelecionado.fonteGrupo.listaFonteGrupoItem.some(fonteitem => {
      var configuracoesAdicionais = fonteitem.fonteConfiguracao.configuracoesAdicionais;

      if (configuracoesAdicionais == null || configuracoesAdicionais == "")
        return false;

      configuracoesAdicionais = JSON.parse(configuracoesAdicionais)

      return configuracoesAdicionais.parametros != null;

    });

    grupoSelecionado.fonteGrupo.listaFonteGrupoItem.forEach(fonteitem => {
      var configuracoesAdicionais = fonteitem.fonteConfiguracao.configuracoesAdicionais;

      if (configuracoesAdicionais == null || configuracoesAdicionais == "")
        return;

      configuracoesAdicionais = JSON.parse(configuracoesAdicionais)

      var temParametros = configuracoesAdicionais.parametros != null;
      if (temParametros) {
        Object.keys(configuracoesAdicionais.parametros).forEach((key) => {
          var form: any = { key: key, title: configuracoesAdicionais.parametros[key].title };
          this.formParametros.push(form);

          var validators = configuracoesAdicionais.parametros[key].required ? [Validators.required] : []

          this.formCadastro.addControl(key, this.fb.control('', validators))
        })
      }
    })
    this.totalItens = chkArray.value
  }

  excluirClicked(item) {
    const index = this.filtros.indexOf(item);
    this.filtros.splice(index, 1);
    this.ui.confirm(Textos.CONFIRMACAO_EXCLUSAO, () => {
      const index = this.filtros.indexOf(item);
      if (index > -1) {
        this.filtros.splice(index, 1);
      }
    });
  }

  updateChkbxArray(fonteItem, evento) {
    const chkArray = <FormArray>this.formCadastro.get('fontesSelecionaveis');
    if (evento.checked) {
      chkArray.push(this.fb.group({
        selecionado: [{ value: true }],
        fonte: [
          {
            value: fonteItem,
          }
        ],
      }));
    }
    else {
      let idx = chkArray.controls.findIndex(x => x.value == fonteItem);
      chkArray.removeAt(idx);
      this.allChecked = false
      this.formCadastro.get('selecionarTodasFontesOpcionais').setValue(false);
    }
  }

  toogleSelecionarTodos(isChecked) {
    if (isChecked.checked) {
      this.limpaArrayCheck()
      this.adicionaTodosCheck()
      this.itemChecked = false
      setTimeout(() => {
        this.itemChecked = true;
        if (this.listaFontesAdicionais.map(x => x.fonteConfiguracao).find(x => x.configuracoesAdicionais)) {
          this.temParametro = true
        }
      }, 100)
    }
    else {
      this.limpaArrayCheck()
    }
  }

  adicionaTodosCheck() {
    const chkArray = <FormArray>this.formCadastro.get('fontesSelecionaveis');
    return this.listaFontesAdicionais.forEach(fonteItem => {
      chkArray.push(this.fb.group({
        selecionado: [{ value: true }],
        fonte: [
          {
            value: fonteItem,
          }
        ],
      }));
      this.itemChecked = true;
      if (this.listaFontesAdicionais.map(x => x.fonteConfiguracao).find(x => x.configuracoesAdicionais)) {
        this.temParametro = true
      }
    })
  }

  limpaArrayCheck() {
    const chkArray = <FormArray>this.formCadastro.get('fontesSelecionaveis');
    while (chkArray.length > 0) {
      let idx = chkArray.controls.findIndex(x => x.value.selecionado);
      chkArray.removeAt(idx);
    }
    this.itemChecked = false
    this.temParametro = false
  }

  verificaCheckbox() {
    let chkArray = <FormArray>this.formCadastro.get('fontesSelecionaveis');
    if (chkArray.length == this.totalItens.length) {
      this, this.limpaArrayCheck()
      this.adicionaTodosCheck()
      this.formCadastro.get('selecionarTodasFontesOpcionais').setValue(true)
    }
  }


  valorTotalFontesSelecionadas() {
    return this.formCadastro.value.fontesSelecionaveis.reduce((acc, f) => acc + (f.fonte.value.fonteConfiguracao.preco ?? 0), 0)
  }

  verificaTipoDocumento() {
    let cnpj = 2
    let cpf = 1
    let tipoDocForm = this.formCadastro.get('tipo').value
    let numeroForm = this.formCadastro.get('numero').value
    if (numeroForm.length == 11) {
      this.formCadastro.get('tipo').setValue(cpf)
    }
    else if (numeroForm.length == 14) {
      this.formCadastro.get('tipo').setValue(cnpj)
    }
    console.log(this.formCadastro)
  }
}