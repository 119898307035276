import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { PrecificadorService } from 'src/app/shared/services/precificador/precificador.service';
import { AnexarAtivosComponent } from './anexar-ativos/anexar-ativos.component';
import { ModalConfirmacaoComponent } from 'src/app/components/view/modal-confirmacao/modal-confirmacao.component';
import { CityUFService } from 'src/app/shared/services/precificador/UfByProcesso.service';


@Component({
  selector: 'app-step-two',
  templateUrl: './step-two.component.html',
  styleUrls: ['./step-two.component.scss']
})
export class StepTwoComponent implements OnInit {

  precificadorId: number;

  formAtivos = [];
  pipe = new DatePipe('pt-BR');

  forms: FormGroup[] = [];
  dadosDaAPI: any[]

  //precificadorId = 0;

  constructor(
    private precificadorService: PrecificadorService,
    private fb: FormBuilder,
    private cityUFService: CityUFService,
    private dialog: MatDialog) { }

  ngOnChanges(change) {
    console.log(change);
  }

  ngOnInit(): void {
    this.precificadorService.getPrecificadorIdPai().subscribe(idNovo => {
      this.precificadorId = idNovo;
      this.getAtivos(idNovo)
    })
  }

  addForm() {
    const form = this.fb.group({
      tipoId: [],
      numeroMatricula: [],
      descricao: [],
      area: [],
      cidade: [],
      uf: [],
      dataAvaliacao: [],
      valorAtivo: [],
      id: [0],
      placa: [],
      renavam: [],
      marcaModelo: [],
      anoFabricacao: [],
      numRegistro: [],
      nomeEmpresa: [],
      nomeSocio: [],
      faturamentoAnual: [],
      qtdMesesConsiderar: [],
      numProcesso: [],
      tipoProcesso: [],
      comarca: [],
    });
    form.get('numProcesso').valueChanges.subscribe(value => {
      if (value && value.length >= 21) {
        const uf = value.slice(18, 20);
        form.get('uf').setValue(this.cityUFService.getUFCode(uf));
      } else {
        form.get('uf').setValue('');
      }
    });
    this.forms.push(form)
  }

  cadastrarAtivos() {
    const formItens = this.forms.map(control => control.value);

    const requisicaoAtivos = formItens.map(item => {
      return {
        Id: item.id,
        TipoId: item.tipoId,
        DataAvaliacao: item.dataAvaliacao,
        ValorAvaliacao: item.valorAtivo,
        Conteudo: this.retornaConteudo(item)
      };
    });
    const obj = {
      PrecificadorId: this.precificadorId,
      ObservacaoAtivo: '',
      RequisicaoAtivos: requisicaoAtivos
    };

    console.log(obj);
    this.precificadorService.cadastrarAtivos(obj).subscribe(data => {
    })
  }

  getAtivos(idPrecificador) {
    this.precificadorService.ativos(idPrecificador).subscribe(data => {
      this.dadosDaAPI = data
      if (this.dadosDaAPI && this.dadosDaAPI.length > 0) {

        this.dadosDaAPI.forEach(dado => {
          if (this.forms.some(f => f.value.id == dado.id)) return;
          dado.conteudo = JSON.parse(dado.conteudo);
          const dataAvaliacao = dado.dataAvaliacao
          const dataAvaliacaoFormatada = this.pipe.transform(dataAvaliacao, 'yyyy-MM-dd');
          const form = this.fb.group({
            tipoId: [dado.tipoAtivo.id.toString()],
            numeroMatricula: [dado.conteudo.numeroMatricula],
            descricao: [dado.conteudo.descricao],
            area: [dado.conteudo.area],
            cidade: [dado.conteudo.cidade],
            uf: [dado.conteudo.uf],
            dataAvaliacao: [dataAvaliacaoFormatada],
            valorAtivo: [dado.valorAtivo],
            id: [dado.id],
            placa: [dado.conteudo.placa],
            renavam: [dado.conteudo.renavam],
            marcaModelo: [dado.conteudo.marcaModelo],
            anoFabricacao: [dado.conteudo.anoFabricacao],
            numRegistro: [dado.conteudo.numeroRegistro],
            nomeEmpresa: [dado.conteudo.nomeEmpresa],
            nomeSocio: [dado.conteudo.nomeSocio],
            faturamentoAnual: [dado.conteudo.faturamentoAnual],
            qtdMesesConsiderar: [dado.conteudo.qtdMesesConsiderar],
            numProcesso: [dado.conteudo.numProcesso],
            tipoProcesso: [dado.conteudo.tipoProcesso],
            comarca: [dado.conteudo.comarca],
          });

          this.forms.push(form);
        });
      }
    })
  }

  teste() {
    console.log(this.formAtivos.map(x => x.value))
  }

  retornaConteudo(item) {
    const formImoveis = {
      numeroMatricula: item.numeroMatricula,
      descricao: item.descricao,
      area: item.area,
      cidade: item.cidade,
      uf: item.uf
    };
    const formVeiculos = {
      placa: item.placa,
      renavam: item.renavam,
      marcaModelo: item.marcaModelo,
      anoFabricacao: item.anoFabricacao,
      cidade: item.cidade,
      uf: item.uf
    };
    const formAviao = {
      numeroRegistro: item.numRegistro,
      marcaModelo: item.marcaModelo,
      anoFabricacao: item.anoFabricacao,
      cidade: item.cidade,
      uf: item.uf
    };

    const formMaquinario = {
      marcaModelo: item.marcaModelo,
      anoFabricacao: item.anoFabricacao,
      cidade: item.cidade,
      uf: item.uf
    };
    const formFaturamento = {
      nomeEmpresa: item.nomeEmpresa,
      nomeSocio: item.nomeSocio,
      faturamentoAnual: item.faturamentoAnual,
      qtdMesesConsiderar: item.qtdMesesConsiderar,
      cidade: item.cidade,
      uf: item.uf
    };
    const formProcessuais = {
      numProcesso: item.numProcesso,
      tipoProcesso: item.tipoProcesso,
      comarca: item.comarca,
      uf: item.uf
    };
    const formOutros = {
      descricao: item.descricao,
      cidade: item.cidade,
      uf: item.uf
    };


    if (item.tipoId == 1) {
      return JSON.stringify(formImoveis)
    }
    if (item.tipoId == 2) {
      return JSON.stringify(formVeiculos)
    }
    if (item.tipoId == 3 || item.tipoId == 4) {
      return JSON.stringify(formAviao)
    }
    if (item.tipoId == 5) {
      return JSON.stringify(formMaquinario)
    }
    if (item.tipoId == 6) {
      return JSON.stringify(formFaturamento)
    }
    if (item.tipoId == 7) {
      return JSON.stringify(formProcessuais)
    }
    if (item.tipoId == 8) {
      return JSON.stringify(formOutros)
    }
  }

  removeForm(index: number) {
    this.forms.splice(index, 1);
  }

  openModalAnexo() {
    const menu = this.dialog.open(AnexarAtivosComponent, {
      width: '750px',
      panelClass: 'bg-color',
      height: 'auto',
      // data: this.fonteGrupo
    });
    menu.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        const ativosAnexados = dialogResult.Ativos
        ativosAnexados.forEach(dado => {
          if (this.forms.some(f => f.value.id == dado.id)) return;
          dado.Conteudo = JSON.parse(dado.Conteudo);
          const dataAvaliacao = dado.DataAvaliacao
          const dataAvaliacaoFormatada = this.pipe.transform(dataAvaliacao, 'yyyy-MM-dd');
          const form = this.fb.group({
            tipoId: [dado.TipoAtivo.Id.toString()],
            numeroMatricula: [dado.Conteudo.NumeroMatricula],
            descricao: [dado.Conteudo.Descricao],
            area: [dado.Conteudo.Area],
            cidade: [dado.Conteudo.Cidade],
            uf: [dado.Conteudo.Uf],
            dataAvaliacao: [dataAvaliacaoFormatada],
            valorAtivo: [dado.ValorAtivo],
            id: [dado.Id],
            placa: [dado.Conteudo.Placa],
            renavam: [dado.Conteudo.Renavam],
            marcaModelo: [dado.Conteudo.MarcaModelo],
            anoFabricacao: [dado.Conteudo.AnoFabricacao],
            numRegistro: [dado.Conteudo.NumeroRegistro],
            nomeEmpresa: [dado.Conteudo.NomeEmpresa],
            nomeSocio: [dado.Conteudo.NomeSocio],
            faturamentoAnual: [dado.Conteudo.FaturamentoAnual],
            qtdMesesConsiderar: [dado.Conteudo.QtdMesesConsiderar],
            numProcesso: [dado.Conteudo.NumProcesso],
            tipoProcesso: [dado.Conteudo.TipoProcesso],
            comarca: [dado.Conteudo.Comarca],
          });

          this.forms.push(form);
        });
      }
    });
  }

  deletarTodosAtivos() {
    const dados = {
      title: '',
      message: 'Deseja realmente deletar todos os ativos da lista?',
      cancelText: 'Cancelar',
      confirmText: 'Deletar'
    }
    const dialogRef = this.dialog.open(ModalConfirmacaoComponent, {
      maxWidth: "470px",
      data: dados
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult == true) {
        this.forms = []
      }
    });
  }
}


