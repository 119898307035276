<div class="content">
    <div class="header d-flex justify-content-between">
        <h3>Notificações</h3>
        <button mat-icon-button aria-label="close dialog" mat-dialog-close>
            <mat-icon>close</mat-icon>
        </button>
    </div>

    <div class="content-notifications">
        <!-- ====== marcar como lido ======== -->
        <div class="d-flex justify-content-end" *ngIf="verificaLeitura()">
            <div class="btn-group">
                <button type="button" class="button-dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <mat-icon>more_horiz</mat-icon>
                </button>
                <div class="dropdown-menu">
                  <a class="dropdown-item" (click)="marcarTodosComoLido()">Marcar todas como lido</a>
                </div>
            </div>
        </div>
        <div class="d-flex justify-content-center p-5" *ngIf="notificacoes?.length == 0">
            <p> Nenhuma Notificação</p>
        </div>
        <div *ngFor="let notification of notificacoes">
            <div [ngClass]="!notification.lido ? 'notification-naoLido' : 'notification'">
                <h5>{{notification.titulo}}</h5>
                <p>{{notification.mensagem}}</p>
                <div class="check-lido d-flex justify-content-end">
                    <p *ngIf="!notification.lido" (click)="marcarComoLido(notification)">Marcar como lido</p>
                </div>
            </div>
        </div>
    </div>
</div>
