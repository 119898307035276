<div class="row d-flex align-items-center">
    <div class="col-md-4 mb-2">
      <!-- Emails -->
      <div class="chip-container" *ngIf="selectedEmails?.length > 0">
        <mat-chip-list class="d-flex flex-row">
          <mat-chip *ngFor="let chip of selectedEmails" [removable]="true" (removed)="removeChip(chip, 'email')">
            {{ chip }}
            <button matChipRemove>×</button>
          </mat-chip>
        </mat-chip-list>
      </div>
      <mat-form-field class="example-chip-list w-100" appearance="outline">
        <mat-label>Emails</mat-label>
        <input
          matInput
          [formControl]="emailFilter"
          placeholder="Filtrar Emails"
          (focus)="emailSelect.open()"
          (click)="emailSelect.open()"
        />
        <mat-select #emailSelect>
          <mat-option>
            <mat-checkbox
              [checked]="allSelectedEmails"
              (change)="toggleSelectAll($event, 'email')"
            >
              Selecionar todos
            </mat-checkbox>
          </mat-option>
          <mat-option *ngFor="let email of filteredEmails">
            <mat-checkbox
              [checked]="isSelected(email, 'email')"
              (change)="onCheckboxChange($event, email, 'email')"
            >
              {{ email }}
            </mat-checkbox>
          </mat-option>
        </mat-select>
      </mat-form-field>
      
      
    </div>
  
    <div class="col-md-4 mb-2">
      <!-- Telefones -->
      <div class="chip-container" *ngIf="selectedTelefones?.length > 0">
        <mat-chip-list>
            <mat-chip *ngFor="let chip of selectedTelefones" [removable]="true" (removed)="removeChip(chip, 'telefone')">
              {{ chip }}
              <button matChipRemove>×</button>
            </mat-chip>
          </mat-chip-list>
      </div>
      <mat-form-field class="example-chip-list w-100" appearance="outline">
        <mat-label>Telefones</mat-label>
        <input
          matInput
          [formControl]="telefoneFilter"
          #telefoneFilterInput
          placeholder="Filtrar Telefones"
          (focus)="telefoneSelect.open()"
          (click)="telefoneSelect.open()"
        />
        
        <mat-select #telefoneSelect >

          <mat-option>
            <mat-checkbox
              [checked]="allSelectedTelefones"
              (change)="toggleSelectAll($event, 'telefones')"
            >
              Selecionar todos
            </mat-checkbox>
          </mat-option>

          <mat-option *ngFor="let telefone of filteredTelefones">
            <mat-checkbox
              [checked]="isSelected(telefone, 'telefone')"
              (change)="onCheckboxChange($event, telefone, 'telefone')"
            >
              {{ telefone }}
            </mat-checkbox>
          </mat-option>
        </mat-select>
      </mat-form-field>           
    </div>
  
    <div class="col-md-4 mb-2">
      <!-- Processos -->
      <div class="chip-container" *ngIf="selectedProcessos?.length > 0">
        <mat-chip-list>
            <mat-chip *ngFor="let chip of selectedProcessos" [removable]="true" (removed)="removeChip(chip, 'processo')">
              {{ chip }}
              <button matChipRemove>×</button>
            </mat-chip>
        </mat-chip-list>
      </div>
      <mat-form-field class="example-chip-list w-100" appearance="outline">
        <mat-label>Processos</mat-label>
        <input
          matInput
          [formControl]="processoFilter"
          #processoFilterInput
          placeholder="Filtrar Processos"
          (focus)="processoSelect.open()"
          (click)="processoSelect.open()"
        />
        
        <mat-select #processoSelect >

          <mat-option>
            <mat-checkbox
              [checked]="allSelectedProcessos"
              (change)="toggleSelectAll($event, 'processos')"
            >
              Selecionar todos
            </mat-checkbox>
          </mat-option>

          <mat-option *ngFor="let processo of filteredProcessos">
            <mat-checkbox
              [checked]="isSelected(processo, 'processo')"
              (change)="onCheckboxChange($event, processo, 'processo')"
            >
              {{ processo }}
            </mat-checkbox>
          </mat-option>
        </mat-select>
      </mat-form-field>      
    </div>
  
    <div class="col-md-4 mb-2">
      <!-- Endereços -->
      <div class="chip-container" *ngIf="selectedEnderecos?.length > 0">
        <mat-chip-list>
            <mat-chip *ngFor="let chip of selectedEnderecos" [removable]="true" (removed)="removeChip(chip, 'endereco')">
              {{ chip }}
              <button matChipRemove>×</button>
            </mat-chip>
          </mat-chip-list>
      </div>
      <mat-form-field class="example-chip-list w-100" appearance="outline">
        <mat-label>Endereços</mat-label>
        <input
          matInput
          [formControl]="enderecoFilter"
          #enderecoFilterInput
          placeholder="Filtrar Endereços"
          (focus)="enderecoSelect.open()"
          (click)="enderecoSelect.open()"
        />
        
        <mat-select #enderecoSelect  >

          <mat-option>
            <mat-checkbox
              [checked]="allSelectedEnderecos"
              (change)="toggleSelectAll($event, 'enderecos')"
            >
              Selecionar todos
            </mat-checkbox>
          </mat-option>

          <mat-option *ngFor="let endereco of filteredEnderecos">
            <mat-checkbox
              [checked]="isSelected(endereco, 'endereco')"
              (change)="onCheckboxChange($event, endereco, 'endereco')"
            >
              {{ endereco }}
            </mat-checkbox>
          </mat-option>
        </mat-select>
      </mat-form-field>           
    </div>
  
    <div class="col-md-4 mb-2">
      <!-- Nomes -->
      <div class="chip-container" *ngIf="selectedNomes?.length > 0">
        <mat-chip-list>
            <mat-chip *ngFor="let chip of selectedNomes" [removable]="true" (removed)="removeChip(chip, 'nome')">
              {{ chip }}
              <button matChipRemove>×</button>
            </mat-chip>
          </mat-chip-list>
      </div>
      <mat-form-field class="example-chip-list w-100" appearance="outline">
        <mat-label>Nomes</mat-label>
        <input
          matInput
          [formControl]="nomeFilter"
          #nomeFilterInput
          placeholder="Filtrar Nomes"
          (focus)="nomeSelect.open()"
          (click)="nomeSelect.open()"
        />
        
        <mat-select #nomeSelect >

          <mat-option>
            <mat-checkbox
              [checked]="allSelectedNomes"
              (change)="toggleSelectAll($event, 'nomes')"
            >
              Selecionar todos
            </mat-checkbox>
          </mat-option>

          <mat-option *ngFor="let nome of filteredNomes">
            <mat-checkbox
              [checked]="isSelected(nome, 'nome')"
              (change)="onCheckboxChange($event, nome, 'nome')"
            >
              {{ nome }}
            </mat-checkbox>
          </mat-option>
        </mat-select>
      </mat-form-field>            
    </div>
  
    <div class="col-md-4">
      <mat-slide-toggle [(ngModel)]="segundoNivel" (change)="filtrar()" name="segundoNivel">
        Segundo Nível
      </mat-slide-toggle>
    </div>
  </div>
  
  <div class="row mt-3">
    <div class="col d-flex justify-content-end">
      <button mat-flat-button class="gs-botaoAzulPadrao m-3" (click)="filtrar()">Filtrar
        <mat-icon>search</mat-icon>
      </button>
      <button mat-flat-button class="gs-botaoAzulPadrao m-3" (click)="clearAll()">Restaurar Filtros</button>
    </div>
  </div>
  