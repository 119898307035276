import { UiService } from '../../../shared/services/ui.service';
import { AuthService } from '../../../shared/services/http/auth.service';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ModalTermosComponent } from 'src/app/components/view/modal-termos/modal-termos.component';
import { UsuarioService } from 'src/app/shared/services/http/usuario.service';
import { cwd } from 'process';
import { CssSelector } from '@angular/compiler';

@Component({
  selector: 'ngx-login',
  templateUrl: './login.page.html',
  styleUrls: ['./login.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class LoginPage implements OnInit {

  errors: string[] = [];
  messages: string[] = [];
  user: any = {};
  loginForm!: FormGroup;
  alive: boolean = true;
  mostrarMenu: boolean = false;
  nomePacote;

  windowPath: string;

  get email() { return this.loginForm.get('email'); }
  get password() { return this.loginForm.get('password'); }

  constructor(
    protected authService: AuthService,
    protected cd: ChangeDetectorRef,
    private fb: FormBuilder,
    protected router: Router,
    private usuarioService: UsuarioService,
    private toastr: ToastrService,
    public dialog: MatDialog) { }

  ngOnInit(): void {
    this.loginForm = this.fb.group({
      email: this.fb.control('', [Validators.required]),
      password: this.fb.control('', [Validators.required]),
      rememberMe: this.fb.control(false),
    });
  }

  login(): void {
    if (!this.loginForm.valid) return;
    this.user = this.loginForm.value;
    this.errors = [];
    this.messages = [];

    let okFn = (data: any) => {
      localStorage.setItem('token', JSON.stringify(data.token));
      this.authService.getUsuarioAtual();
      this.router.navigate(['/dashboard']);
      setTimeout(() => {
        this.openTermos()
      }, 1700);
    };

    let nokFn = (error: any) => {
      this.authService.mostrarMenuEmitter.emit(false);
      const errorMessage = error?.error;
      if(errorMessage && errorMessage.status != 401){
        this.toastr.warning(errorMessage, "Portal Credvalue");
      }
      if(error.status == 401){
        this.toastr.warning( "Usuário e/ou senha inválidos.", "Portal Credvalue");
      }
    };

    this.authService.login(this.user).subscribe(okFn, nokFn);
  }

  openTermos(): void {
    this.usuarioService.getDashboard(this.authService.user.id).subscribe(data => {
      this.nomePacote = data.pacote.nomePacote
      if (this.authService.user.termosAceitos == "{\"TermoTestePlataforma\":false}" && this.nomePacote === 'TESTE') {
        this.dialog.open(ModalTermosComponent, {
          disableClose: true,
          width: '600px',
          data: {
            id: this.authService.user.id
          }
        });
      }
    })
  }
}
