

  <mat-accordion>
    <mat-expansion-panel class="mt-3 py-2" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <h3 class="mb-0 d-flex align-items-center">
            <mat-icon>work</mat-icon>&nbsp;
            LINHA DO TEMPO SÓCIOS
          </h3>
        </mat-panel-title>

      </mat-expansion-panel-header>
      <div class="timeline ml-5" *ngIf="!exibeMensagemErro">
        <div *ngFor="let entry of timeLine; let i = index">
          <div class="container mt-5" [ngClass]="{ left: i%2!==0, right:i%2==0}">
            <h2 class="ano-timeline">{{ entry.ano }}</h2> <!-- Ano primeiro -->
            <div class="content">
              <div *ngFor="let detalhe of entry.detalhes">
                <p><b>Nome: </b>{{detalhe.nome}}</p>
                <p *ngIf="detalhe.cpfCnpj?.length === 11">
                  <b>Documento: </b> 
                  {{ detalhe.cpfCnpj | slice:0:3 }}.{{ detalhe.cpfCnpj | slice:3:6 }}.{{ detalhe.cpfCnpj | slice:6:9 }}-{{ detalhe.cpfCnpj | slice:9:11 }}
                </p>
                
                <p *ngIf="detalhe.cpfCnpj?.length === 14">
                  <b>Documento: </b> 
                  {{ detalhe.cpfCnpj | slice:0:2 }}.{{ detalhe.cpfCnpj | slice:2:5 }}.{{ detalhe.cpfCnpj | slice:5:8 }}/{{ detalhe.cpfCnpj | slice:8:12 }}-{{ detalhe.cpfCnpj | slice:12:14 }}
                </p>
                <p><b>Dta Entrada: </b>{{detalhe.dataEntradaSociedade | date: 'dd/MM/yyyy'}}</p>
                <p><b>Qualificação: </b>{{detalhe.qualificacao}}</p>
                <hr>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      

      <div class="d-flex justify-content-center" *ngIf="exibeMensagemErro">
        <p>Ocorreu um erro ao pesquisar a linha do tempo de sócios</p>
      </div>
      
    </mat-expansion-panel>
  </mat-accordion>