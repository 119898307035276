import { Component, Input, OnInit } from '@angular/core';
import * as ApexCharts from 'apexcharts';

@Component({
  selector: 'app-grafico-tipo-linha',
  templateUrl: './grafico-tipo-linha.component.html',
  styleUrls: ['./grafico-tipo-linha.component.scss']
})
export class GraficoTipoLinhaComponent{

  @Input() dados: any;
  objetoDados: any;

  constructor() { }

  ngOnChanges() {
    if (this.dados) {
      this.objetoDados = this.processarDados(this.dados);
    }
  }

  processarDados(dadosApi): any {
    const objetoFinal = {
      anos: [],
      poloAtivo: [],
      poloPassivo: [],
      outros: []
    };

    dadosApi.forEach(dado => {
      // Verifica se o ano já existe no objeto final, se não existir, adiciona-o
      if (!objetoFinal.anos.includes(dado.ano)) {
        objetoFinal.anos.push(dado.ano);
      }
  
      // Adiciona as quantidades de polo ativo, polo passivo e outros no objeto final
      const index = objetoFinal.anos.indexOf(dado.ano);
      objetoFinal.poloAtivo[index] = objetoFinal.poloAtivo[index] || { quantidade: 0, valor: 0 };
      objetoFinal.poloAtivo[index].quantidade += dado.ocorrenciasPoloAtivo;
      objetoFinal.poloAtivo[index].valor += dado.valorPoloAtivo;
  
      objetoFinal.poloPassivo[index] = objetoFinal.poloPassivo[index] || { quantidade: 0, valor: 0 };
      objetoFinal.poloPassivo[index].quantidade += dado.ocorrenciasPoloPassivo;
      objetoFinal.poloPassivo[index].valor += dado.valorPoloPassivo;
  
      objetoFinal.outros[index] = objetoFinal.outros[index] || { quantidade: 0, valor: 0 };
      objetoFinal.outros[index].quantidade += dado.ocorrenciasPoloNaoIdentificado;
      objetoFinal.outros[index].valor += dado.valorPoloNaoIdentificado;
    });

    this.chartLinePorValor(objetoFinal)
  
    return objetoFinal;
  }

  private chartLinePorValor(objeto) {
    console.log(objeto);
    var options = {
      chart: {
        height: 400,
        type: "line",
        stacked: false,
        toolbar: {
          show: true,
          export: {
            png: {
              filename: 'Linha do Tempo de Processos',
            }
          },
        },
      },
      dataLabels: {
        enabled: true
      },
      colors: ["#41bfe4", "#03354b", "#cccccc"],
      series: [
        {
          name: "Polo Ativo",
          data: objeto.poloAtivo.map(item => item.quantidade)
        },
        {
          name: "Polo Passivo",
          data: objeto.poloPassivo.map(item => item.quantidade)
        },
        {
          name: "Outros",
          data: objeto.outros.map(item => item.quantidade)
        }
      ],
      stroke: {
        width: [4, 4, 4]
      },
      plotOptions: {
        bar: {
          columnWidth: "100%"
        }
      },
      xaxis: {
        categories: objeto.anos
      },
      tooltip: {
        shared: false,
        intersect: true,
        x: {
          show: false
        }
      },
      legend: {
        horizontalAlign: "left",
        offsetX: 40
      }
    };
  
    var chart = new ApexCharts(document.querySelector("#chart-line"), options);
  
    chart.render();
  }
}
