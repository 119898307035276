<div class="container">
  
  <form [formGroup]="form">
    <div class="row d-flex mt-2">
      <div class="col-4 d-flex flex-column">
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>Identificação</mat-label>
          <input formControlName="identificacao" name="identificacao" matInput>
        </mat-form-field>
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>Nome do Título</mat-label>
          <input formControlName="nomeTitulo" name="nomeTitulo" matInput>
        </mat-form-field>

        <mat-form-field class="example-form-field" appearance="outline">
          <mat-label>Data da Operação</mat-label>
          <input matInput type="date" formControlName="dataTitulo" name="dataTitulo">
        </mat-form-field>

        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>Valor da Operação</mat-label>
          <input formControlName="valorTitulo" name="valorTitulo" matInput currencyMask [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', align:' left' }">
        </mat-form-field>

        <mat-form-field class="example-form-field" appearance="outline">
          <mat-label>Credito Ajuizado?</mat-label>
          <mat-select formControlName="creditoAjuizado" name="creditoAjuizado">
            <mat-option [value]="true">Sim</mat-option>
            <mat-option [value]="false">Não</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="col-4 d-flex flex-column">

        <mat-form-field class="example-form-field" appearance="outline">
          <mat-label>Data da Inadimplência</mat-label>
          <input matInput type="date" formControlName="datainadimplencia" name="datainadimplencia">
        </mat-form-field>
        <mat-hint class="text-danger" *ngIf="isInvalid">Campo Obrigatorio</mat-hint>

        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>Valor da Inadimplência</mat-label>
          <input formControlName="valorAtualInadimplencia" name="valorAtualInadimplencia" matInput currencyMask [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', align:' left' }">
        </mat-form-field>
        <mat-hint class="text-danger" *ngIf="isInvalid">Campo Obrigatorio</mat-hint>

        <mat-form-field appearance="outline">
          <mat-label>Selecione o Índice para atualização</mat-label>
          <mat-select formControlName="indice" name="indice">
            <mat-option value="1">Nenhum</mat-option>
            <mat-option value="3">IGP-M</mat-option>
            <mat-option value="7">INCC</mat-option>
            <mat-option value="8">INPC </mat-option>
            <mat-option value="15">IPCA </mat-option>
            <mat-option value="16">IPCA-15</mat-option>
          </mat-select>
        </mat-form-field>

        <!-- <mat-form-field class="example-form-field" appearance="outline">
                <mat-label>Data Atual</mat-label>
                <input matInput type="date" formControlName="dataAtual" name="dataTitulo">
              </mat-form-field> -->


        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>Multa</mat-label>
          <span matSuffix>%</span>
          <input appCurrencyMask formControlName="jurosMulta" name="jurosMulta" matInput>
        </mat-form-field>

        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>Juros de Mora</mat-label>
          <span matSuffix>%</span>
          <input appCurrencyMask formControlName="jurosMora" name="jurosMora" matInput>
        </mat-form-field>
      </div>

      <div class="col-4 d-flex flex-column">
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>Dívida Atualizada</mat-label>
          <input formControlName="dividaAtualizada" name="dividaAtualizada"  matInput currencyMask [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', align:' left' }">
        </mat-form-field>

        <div class="col-12 d-flex justify-content-end">
          <button mat-flat-button class="gs-botaoAzulPadrao m-3" (click)="calcularDivida()">
            Calcular
          </button>
        </div>
      </div>

      <div class="col-12"> 
        <mat-form-field class="example-full-width" appearance="outline">
            <mat-label>Observacao</mat-label>
            <textarea formControlName="observacao" name="observacao" matInput></textarea>
        </mat-form-field>
      </div>
    </div>
  </form>
</div>
