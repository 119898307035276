import { NavService } from './../../components/template/nav/nav.service';
import { HeaderService } from './../../components/template/header/header.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { PesquisaService } from '../../shared/services/http/pesquisa.service';
import { StatusPesquisa } from '../../shared/enums/status-pesquisa.enum';
import { StatusRegistro } from '../../shared/enums/status-registro.enum';
import { TipoPesquisa } from '../../shared/enums/tipo-pesquisa.enum';
import { UsuarioService } from '../../shared/services/http/usuario.service';
import { UiService } from '../../shared/services/ui.service';
import { ConfiguracaoService } from '../../shared/services/http/configuracao.service';
import { FonteGrupoService } from '../../shared/services/http/fonte-grupo.service';
import { UtilService } from '../../shared/services/http/util.service';
import { ToastrService } from 'ngx-toastr';
import { Observable, forkJoin, map, startWith } from 'rxjs';
import { Textos } from '../../shared/textos';
import { Fonte } from '../../shared/enums/fonte.enum';
import { MatDialog } from '@angular/material/dialog';
import { PopPesqRealizadaComponent } from './../../components/view/poppesqrealizada/poppesqrealizada.component';
import { HttpStatusCode } from '@angular/common/http';
import { MensageResponseComponent } from 'src/app/components/view/mensage-response/mensage-response.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ProdutosEnum } from 'src/app/shared/enums/produtos.enum';
import { MensageErrorResponseComponent } from 'src/app/components/view/mensage-error-response/mensage-error-response.component';
import { AuthService } from 'src/app/shared/services/http/auth.service';
import { ModalValoresAdicionaisComponent } from './modal-valores-adicionais/modal-valores-adicionais.component';
import { MatCheckbox } from '@angular/material/checkbox';
import { ModalExportarPesquisaLoteComponent } from 'src/app/components/view/modal-exportar-pesquisa-lote/modal-exportar-pesquisa-lote.component';
import { ModalConfirmacaoComponent } from 'src/app/components/view/modal-confirmacao/modal-confirmacao.component';
import { ModalEdicaoTagComponent } from 'src/app/components/view/modal-edicao-tag/modal-edicao-tag.component';
import { MatTabGroup } from '@angular/material/tabs';

const today = new Date();
const month = today.getMonth();
const year = today.getFullYear();

export interface PeriodicElement {
  id: number;
  relatorio: string;
  cpf: string;
  nome: string;
  emissao: string;
  horario: string;
  status: string;
  progresso: string;
}

@Component({
  selector: 'app-patrimonial',
  templateUrl: './patrimonial.component.html',
  styleUrls: ['./patrimonial.component.scss']
})
export class PatrimonialComponent implements OnInit {

  StatusRegistro = StatusRegistro;
  StatusPesquisa = StatusPesquisa;
  TipoPesquisa = TipoPesquisa;

  formCadastroValorAdicional: boolean = false;
  formCadastroValorAdicionalLabel: string = "";
  Fonte = Fonte;
  tipoLabels: Map<Fonte, string> = new Map<Fonte, string>();

  formPesquisa: any = {};
  formFiltro: any = { page: 1, pageSize: 100, perfil: "", status: "", produtoID: 3 };
  formFiltroRenderizado: any = this.formFiltro;

  refazer: boolean = false;
  resultadoPesquisa: any = [];
  usuario: any;
  configuracao: any = {};
  listaFonteGrupo: any = [];
  pesquisasSelecionadas = [];
  exibeToast = false;
  listaFontesAdicionais;
  adicionaisToRequest = [];
  defaultSelectedValue;
  obrigatoriedadeAdicionais = false;
  isSuzano = false;
  isDisabled = false
  myControl = new FormControl();
  tagIds = [];
  addedItems = [];
  autoCompletePanel: any;
  labelTag: String = 'Tag';
  filteredTags: Observable<any[]>;

  campaignOne = new FormGroup({
    start: new FormControl(new Date(year, month, 13)),
    end: new FormControl(new Date(year, month, 16)),
  });
  campaignTwo = new FormGroup({
    start: new FormControl(new Date(year, month, 15)),
    end: new FormControl(new Date(year, month, 19)),
  });

  abas: { titulo: string, id: number, novaPesquisa:boolean }[] = [{ titulo: 'Histórico', id: null, novaPesquisa: false}];
  selectedIndex = 0;
  @ViewChild(MatTabGroup) tabGroup: MatTabGroup;

  constructor(
    private pesquisaService: PesquisaService,
    private utilService: UtilService,
    private ui: UiService,
    private toastr: ToastrService,
    private configuracaoService: ConfiguracaoService,
    private fonteGrupoService: FonteGrupoService,
    private headerService: HeaderService,
    private navService: NavService,
    private authService: AuthService,
    private _snackBar: MatSnackBar,
    public dialog: MatDialog) {
    navService.navData = {
      title: 'Patrimoniais'
    }
    headerService.headerData = {
      title: 'Patrimoniais'
    }
  }

  ngOnInit(): void {
  //   this.getUsuario();
  //   this.formPesquisa.numero = "";
  //   this.formPesquisa.notificacao = true;
  //   this.formPesquisa.valorAdicional = [];
  //   this.ui.loading();

  //   let requests = [
  //     this.configuracaoService.get(),
  //     this.fonteGrupoService.listAtivoProduto(ProdutosEnum.Patrimonial)
  //   ];

  //   forkJoin(requests).subscribe(data => {
  //     if(data && data[1].length == 0){
  //       this.ui.loaded().subscribe(() =>{
  //         this.toastr.warning('Nenhum grupo de fontes vinculado ao pacote do usuário, entre em contato com o suporte.');
  //         this.pesquisarClickedSemLoader(1);
  //       })
  //     }

  //     this.configuracao = data[0];
  //     this.listaFonteGrupo = data[1];
  //     this.listaFontesAdicionais = data[1];

  //     this.formPesquisa.idFonteGrupo = this.listaFonteGrupo[0]?.fonteGrupo?.id;
  //     this.listaFontesAdicionais = this.listaFontesAdicionais.find(x => x.fonteGrupo.id == this.formPesquisa.idFonteGrupo)
  //     this.listaFontesAdicionais = this.listaFontesAdicionais?.fonteGrupo.listaFonteGrupoItem.filter(x => x.fonteSelecionavel == true)
  //     this.listaFontesAdicionais.sort((a,b) => (a.fonteConfiguracao.nome > b.fonteConfiguracao.nome) ? 1 :-1);
  //     var patrimoniosSP = this.listaFontesAdicionais.filter(x => x.fonte == 91)
  //     if (patrimoniosSP) {
  //       this.defaultSelectedValue = patrimoniosSP
  //     }
  //     this.getChips();
  //     this.pesquisarClickedSemLoader(1);
  //     this.filteredTags = this.myControl.valueChanges.pipe(
  //       startWith(''),
  //       map(value => this._filterTags(value))
  //     );
  //   });

  //   this.tipoLabels.set(Fonte.CertidaoDividaUniaoImovelRural, "NIRF");

  //   setInterval(() => {
  //     let page = this.formFiltro.page;
  //     this.pesquisarClickedSemLoader(page);
  //   }, 1000 * 60)
  // }

  // private _filterTags(value: string): any[] {
  //   const filterValue = value.toLowerCase();
  //   return this.tagIds.filter(tag => tag.tag.toLowerCase().includes(filterValue));
  }

  // getUsuario() {
  //   this.authService.callbackUsuario().subscribe(usuario => {
  //     if (!usuario) {
  //       return;
  //     }
  //     if (usuario) {
  //       this.usuario = usuario;
  //       if(usuario.cliente.descricaoTag && usuario.cliente.descricaoTag.trim().length > 0){
  //         this.labelTag = usuario.cliente.descricaoTag;
  //       }
  //     }
  //     if (usuario.cliente.id == 52) {
  //       this.isSuzano = true
  //     }
  //   })
  // }

  // addChip(value) {
  //   this.myControl.reset();
  //   if (!this.addedItems.find(item => item.tag === value.tag)) {
  //     this.addedItems.push(value);
  //   }
  //   this.tagIds = this.tagIds.filter(item => item.tag !== value.tag);
  //   this.filteredTags = this.filteredTags.pipe(
  //     map(tags => tags.filter(tag => tag.tagId !== value.tagId))
  //   );
  //   this.myControl.reset();
  // }

  // removeChip(item): void {
  //   const index = this.addedItems.indexOf(item);
  // if (index !== -1) {
  //   this.addedItems.splice(index, 1);
  //   this.tagIds.push(item);  // Adiciona o item de volta ao array tagIds
  //   this.filteredTags = this.myControl.valueChanges.pipe(
  //     startWith(''),
  //     map(value => this._filterTags(value))
  //   );
  // }
  // }

  // enviarChips(value) {
  //   this.ui.loading();
  //   const obj = {
  //     clienteId: this.usuario.cliente.id,
  //     tags: [{
  //       tagId: 0,
  //       tag: value
  //     }]
  //   }
  //   this.pesquisaService.cadastrarTagId(obj).subscribe(response => {
  //     this.ui.loaded().subscribe(() => {
  //       this.addedItems.push(response[0])
  //       this.filteredTags = this.myControl.valueChanges.pipe(
  //         startWith(''),
  //         map(value => this._filterTags(value))
  //       );
  //       this.myControl.reset();
  //     })
  //   }, (err:any) => {
  //     this.ui.loaded().subscribe(() => {
  //         this.toastr.error('Ocorreu um erro, tente novamente.')
  //     })
  //   });
  // }

  // onEnter(event: KeyboardEvent): void {
  //   if (document.activeElement === event.target) {
  //     event.preventDefault();

  //     const value = this.myControl.value.trim();
  //     const valueAdded = this.addedItems.map(x => x.tag)
  //     const valueGetted = this.tagIds.map(x => x.tag)
  //     if (value !== '' && !valueAdded.includes(value) && valueGetted.includes(value)) {
  //       let tagEnter = this.tagIds.filter(x => x.tag == value);
  //       this.addChip(tagEnter[0])
  //      }
  //      if (value !== '' && !valueAdded.includes(value) && !valueGetted.includes(value)) {
  //       this.enviarChips(value)
  //     }

  //     this.myControl.reset();
  //   }
  // }

  // onInputBlur(): void {
  //   const value = this.myControl.value.trim();
  //     const valueAdded = this.addedItems.map(x => x.tag)
  //     const valueGetted = this.tagIds.map(x => x.tag)
  //     if (value !== '' && !valueAdded.includes(value) && !valueGetted.includes(value)) {
  //       this.enviarChips(value)
  //     }

  //     this.myControl.reset();
  // }

  // getChips() {
  //   this.ui.loading()
  //   this.pesquisaService.getTagID(this.usuario.cliente.id).subscribe(response => {
  //     this.ui.loaded().subscribe(() => {
  //       this.tagIds = response
  //     })
  //   }, (err:any) => {
  //     this.ui.loaded().subscribe(() => {
  //         this.toastr.error('Ocorreu um erro, tente novamente.')
  //     })
  //   });
  // }

  // deletarTagExistente(value) {
  //   const dados = {
  //     title: '',
  //     message: 'Deseja realmente excluir esse item?',
  //     cancelText: 'Cancelar',
  //     confirmText: 'excluir'
  //   }
  //   const dialogRef = this.dialog.open(ModalConfirmacaoComponent, {
  //     maxWidth: "470px",
  //     data: dados
  //   });

  //   dialogRef.afterClosed().subscribe(dialogResult => {
  //     if (dialogResult == true) {
  //       this.ui.loading()
  //       this.pesquisaService.excluirTag(value.tagId).subscribe(response => {
  //         this.ui.loaded().subscribe(() => {
  //           // this.tagIds = response
  //           this.toastr.info('Tag excluída com sucesso.', 'CredValue');
  //           this.tagIds = this.tagIds.filter(i => i.tagId != value.tagId);
  //           this.filteredTags = this.filteredTags.pipe(
  //             map(tags => tags.filter(tag => tag.tagId != value.tagId))
  //           );
  //           this.getChips()
  //         })
  //       }, (err:any) => {
  //         this.ui.loaded().subscribe(() => {
  //             this.toastr.error('Ocorreu um erro, tente novamente.')
  //         })
  //       });
  //     }
  //   });

  // }

  // toggleItemSelection(pesquisa) {
  //   if (this.isItemSelected(pesquisa)) {
  //     this.pesquisasSelecionadas = this.pesquisasSelecionadas.filter(
  //       (selectedItem) => selectedItem.id !== pesquisa.id
  //     );
  //   } else {
  //     this.pesquisasSelecionadas.push(pesquisa);
  //   }

  //   console.log(this.pesquisasSelecionadas)
  // }

  //  // Verifica se o item está selecionado
  //  isItemSelected(item: any): boolean {
  //   return this.pesquisasSelecionadas.some((selectedItem) => selectedItem.id === item.id);
  // }

  // selecionarTodosItens(isSelected: MatCheckbox) {
  //   if (isSelected.checked) {
  //     this.resultadoPesquisa.forEach(item => {
  //       if (!this.isItemSelected(item)) {
  //         this.pesquisasSelecionadas.push(item);
  //       }
  //     });
  //   } else {
  //     this.resultadoPesquisa.forEach(item => {
  //       this.pesquisasSelecionadas = this.pesquisasSelecionadas.filter(
  //         selectedItem => selectedItem.id !== item.id
  //       );
  //     });
  //   }
  // }
  
  // exportarPesquisaLote(){
  //   const menu = this.dialog.open(ModalExportarPesquisaLoteComponent, {
  //     width: '450px',
  //     panelClass: 'bg-color',
  //     maxHeight: '600px',
  //     data: this.pesquisasSelecionadas
  //   });
  // }

  // exportarLGPD(o) {
  //   this.ui.loading();
  //   this.pesquisaService.exportarLGPD({ id: o.id }, "patrimonial").subscribe(data => {
  //     this.ui.loaded().subscribe(() => {
  //       var a = document.createElement("a");
  //       a.href = "data:application/zip;base64," + data.arquivoConteudo;
  //       a.download = data.arquivoNome;
  //       a.click();
  //     });
  //   }, (err: any) => {
  //     this.ui.loaded().subscribe(() => {
  //       this.openSnackBarWarning('Atenção', err.error);
  //     })
  //   });
  // }

  // pesquisarClicked(page = 1) {
  //   this.formFiltro.page = page;
  //   this.ui.loading();
  //   console.log(this.formFiltro);

  //   if (page == 1)
  //     this.resultadoPesquisa = [];

  //   let request = Object.assign({}, this.formFiltro);
  //   if (request.status) request.status = [request.status];
  //   this.pesquisaService.listPesquisaCliente(request).subscribe(response => {
  //     this.formFiltroRenderizado = Object.assign({}, this.formFiltro);
  //     this.ui.loaded().subscribe(() => {
  //       this.resultadoPesquisa = this.resultadoPesquisa.concat(response.list);
  //     });
  //   });
  // }

  // pesquisarClickedSemLoader(page = 1) {
  //   this.formFiltro.page = page;

  //   if (page == 1)
  //     this.resultadoPesquisa = [];

  //   let request = Object.assign({}, this.formFiltro);
  //   if (request.status) request.status = [request.status];
  //   this.pesquisaService.listPesquisaCliente(request).subscribe(response => {
  //     this.formFiltroRenderizado = Object.assign({}, this.formFiltro);
  //     this.resultadoPesquisa = this.resultadoPesquisa.concat(response.list);
  //   });
  // }

  // onScroll() {
  //   let page = this.formFiltro.page + 1;
  //   console.log('scrolled!!' + page);
  //   this.pesquisarClicked(page);

  // }

  // onScrollDown() {
  //   console.log('scrolled down!!');
  // }

  // onScrollUp() {
  //   let page = this.formFiltro.page - 1;
  //   if (page > 0) {
  //     this.pesquisarClicked(page);
  //   }
  //   console.log('scrolled up!!' + page);
  // }

  // exportarClicked(o: { pesquisa: { id: any; }; }) {
  //   this.ui.loading();
  //   this.pesquisaService.exportar({ id: o.pesquisa.id }, "patrimonial").subscribe(data => {
  //     this.ui.loaded().subscribe(() => {
  //       var a = document.createElement("a");
  //       a.href = "data:application/pdf;base64," + data.arquivoConteudo;
  //       a.download = data.arquivoNome;
  //       a.click();
  //     });
  //   }, (err: any) => {
  //     this.ui.loaded().subscribe(() => {
  //       this.openSnackBarWarning('Atenção', err.error);
  //     })
  //   });
  // }

  // exportarReciboClicked(o: { pesquisa: { id: any; }; }) {
  //   this.ui.loading();
  //   this.pesquisaService.exportarRecibo({ id: o.pesquisa.id }, "patrimonial").subscribe(data => {
  //     this.ui.loaded().subscribe(() => {
  //       var a = document.createElement("a");
  //       a.href = "data:application/pdf;base64," + data.arquivoConteudo;
  //       a.download = data.arquivoNome;
  //       a.click();
  //     });
  //   }, (err: any) => {
  //     this.ui.loaded().subscribe(() => {
  //       this.openSnackBar('Atenção', err.error);
  //     })
  //   });
  // }

  // exportarExcelClicked(o: { pesquisa: { id: any; }; }) {
  //   this.ui.loading();
  //   this.pesquisaService.exportarExcel({ id: o.pesquisa.id }, "patrimonial").subscribe(data => {
  //     this.ui.loaded().subscribe(() => {
  //       var a = document.createElement("a");
  //       a.href = "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," + data.arquivoConteudo;
  //       a.download = data.arquivoNome;
  //       a.click();
  //     });
  //   }, (err: any) => {
  //     this.ui.loaded().subscribe(() => {
  //       this.openSnackBarWarning('Atenção', err.error);
  //     })
  //   });
  // }

  // visualizarClicked(o: { pesquisa: { id: any; }; }) {
  //   this.toastr.warning("Em desenvolvimento", "Portal Credvalue")
  // }

  // selecionarEstado(event: MatCheckbox, value) {
  //   if (event.checked) {
  //     this.formPesquisa.valorAdicional.push(value)
  //     console.log(this.formPesquisa.valorAdicional)
  //   }
  //   else {
  //     var indice = this.formPesquisa.valorAdicional.indexOf(value);
  //     this.formPesquisa.valorAdicional.splice(indice, 1);
  //     console.log(this.formPesquisa.valorAdicional)
  //   }
  //   this.adicionaisToRequest = this.formPesquisa.valorAdicional
  // }

  // changeClient(value) {
  //   this.adicionaisToRequest = value
  //   console.log(value)
  // }

  // confirmarPesquisaClicked() {
  // this.isDisabled = true; 
  //   if(this.adicionaisToRequest == undefined || this.adicionaisToRequest.length == 0){
  //     if (this.formPesquisa.numero) {
  //       this.formPesquisa.numero = this.formPesquisa.numero.replaceAll('.', '').replaceAll('-', '').replaceAll('/', '');
  //     }
  //     this.ui.loading()
  //     this.utilService.resolverNome(this.formPesquisa, "patrimonial").subscribe(resultadoResolverNome => {
  //       if (!resultadoResolverNome || !resultadoResolverNome.nome) {
  //         this.ui.loaded().subscribe(() => {
  //           this.formPesquisa.nome = "";
  //           this.toastr.warning(Textos.NOME_NAO_ENCONTRADO, "Portal Credvalue")
  //           this.isDisabled = false;

  //         });
  //         return;
  //       }
  //       if (this.adicionaisToRequest) {
  //         let objValorAdicional = {
  //           filtros: {},
  //           parametros: {},
  //           fontesAdicionais: this.adicionaisToRequest.map(x => x.fonte)
  //         }

  //         this.formPesquisa.valorAdicional = objValorAdicional;
  //         this.formPesquisa.nome = resultadoResolverNome.nome;
  //         this.formFiltro.nome = this.formPesquisa.nome;
  //         // this.formFiltro.idFonteGrupo = this.formPesquisa.idFonteGrupo;
  //         this.formFiltro.numero = this.formPesquisa.numero;
  //         this.formFiltro.valorAdicional = objValorAdicional
  //         this.formPesquisa.tags = this.addedItems;

  //         let requestCount = Object.assign({}, this.formFiltro);
  //         console.log(requestCount)
  //         this.pesquisaService.getPesquisaRealizada(requestCount, "patrimonial").subscribe(response => {
  //           if (response.count == 0 || this.refazer || response.list.pesquisa.status == this.StatusPesquisa.Cancelada) {
  //             this.refazer = false;
  //             let request = Object.assign({}, this.formPesquisa);
  //             console.log(request)
  //             this.pesquisaService.cadastrarCliente(request).subscribe(data => {
  //               if (HttpStatusCode.Ok) {
  //                 this.ui.loaded().subscribe(() => {
  //                   this.openSnackBar('Sucesso', 'Pesquisa Realizada com Sucesso');
  //                   this.adicionarAba(request.numero, data, true);
  //                   this.getChips();
  //                   this.addedItems = [];
  //                   this.myControl.reset();
  //                   this.isDisabled = false;
  //                 })
  //                 this.formPesquisa.valorAdicional = this.adicionaisToRequest
  //               }
  //               this.pesquisarClicked(1);
  //             });
  //           } else {
  //             this.ui.loaded().subscribe(() => {
  //               this.formPesquisa.nome = "";
  //               //this.toastr.warning(Textos.NOME_DUPLICADA, "Portal Credvalue")
  //               this.openDialog(response.list)
  //               this.isDisabled = false;
  //             });
  //             return;
  //           }
  //         });
  //       }

  //       else {
  //         this.formPesquisa.nome = resultadoResolverNome.nome;
  //         this.formFiltro.nome = this.formPesquisa.nome;
  //         // this.formFiltro.idFonteGrupo = this.formPesquisa.idFonteGrupo;
  //         this.formFiltro.numero = this.formPesquisa.numero;
  //         this.formPesquisa.tags = this.addedItems;

  //         let requestCount = Object.assign({}, this.formFiltro);
  //         console.log(requestCount)
  //         this.pesquisaService.getPesquisaRealizada(requestCount, "patrimonial").subscribe(response => {
  //           if (response.count == 0 || this.refazer || response.list.pesquisa.status == this.StatusPesquisa.Cancelada) {
  //             this.refazer = false;
  //             let request = Object.assign({}, this.formPesquisa);
  //             console.log(request)
  //             this.pesquisaService.cadastrarCliente(request).subscribe(data => {
  //               if (HttpStatusCode.Ok) {
  //                 this.ui.loaded().subscribe(() => {
  //                   this.openSnackBar('Sucesso', 'Pesquisa Realizada com Sucesso');
  //                   this.adicionarAba(request.numero, data, true);
  //                   this.getChips();
  //                   this.addedItems = [];
  //                   this.myControl.reset();
  //                   this.isDisabled = false;
  //                 })
  //                 this.formPesquisa.valorAdicional = this.adicionaisToRequest
  //               }
  //               this.pesquisarClicked(1);
  //             });
  //           } else {
  //             this.ui.loaded().subscribe(() => {
  //               this.formPesquisa.nome = "";
  //               //this.toastr.warning(Textos.NOME_DUPLICADA, "Portal Credvalue")
  //               this.openDialog(response.list)
  //              console.log(this.formPesquisa.valorAdicional);
  //              this.isDisabled = false;
  //             });
  //             return;
  //           }
  //         });
  //       }
  //     }, (err: any) => {
  //       this.ui.loaded().subscribe(() => {
  //         this.openSnackBarWarning('Atenção', err.error);
  //         this.isDisabled = false;
  //       })
  //     });
  //   }
  //   if (this.adicionaisToRequest.length > 0) {
  //     console.log(this.adicionaisToRequest)
  //     const dialog = this.dialog.open(ModalValoresAdicionaisComponent, {
  //       width: '550px',
  //       panelClass: 'bg-color',
  //       data: this.adicionaisToRequest
  //     });
  //     dialog.afterClosed().subscribe(result => {
  //       if (result == 'realizarPesquisa') {
  //         this.ui.loading();
  //         if (this.formPesquisa.numero) {
  //           this.formPesquisa.numero = this.formPesquisa.numero.replaceAll('.', '').replaceAll('-', '').replaceAll('/', '');
  //         }

  //         this.utilService.resolverNome(this.formPesquisa, "patrimonial").subscribe(resultadoResolverNome => {
  //           if (!resultadoResolverNome || !resultadoResolverNome.nome) {
  //             this.ui.loaded().subscribe(() => {
  //               this.formPesquisa.nome = "";
  //               this.toastr.warning(Textos.NOME_NAO_ENCONTRADO, "Portal Credvalue");
  //               this.isDisabled = false;
  //             });
  //             return;
  //           }
  //           if (this.adicionaisToRequest) {
  //             let objValorAdicional = {
  //               filtros: {},
  //               parametros: {},
  //               fontesAdicionais: this.adicionaisToRequest.map(x => x.fonte)
  //             }

  //             this.formPesquisa.valorAdicional = objValorAdicional;
  //             this.formPesquisa.nome = resultadoResolverNome.nome;
  //             this.formFiltro.nome = this.formPesquisa.nome;
  //             // this.formFiltro.idFonteGrupo = this.formPesquisa.idFonteGrupo;
  //             this.formFiltro.numero = this.formPesquisa.numero;
  //             this.formFiltro.valorAdicional = objValorAdicional;
  //             this.formPesquisa.tags = this.addedItems;

  //             let requestCount = Object.assign({}, this.formFiltro);
  //             console.log(requestCount)
  //             this.pesquisaService.getPesquisaRealizada(requestCount, "patrimonial").subscribe(response => {
  //               if (response.count == 0 || this.refazer || response.list.pesquisa.status == this.StatusPesquisa.Cancelada) {
  //                 this.refazer = false;
  //                 let request = Object.assign({}, this.formPesquisa);
  //                 console.log(request)
  //                 this.pesquisaService.cadastrarCliente(request).subscribe(data => {
  //                   if (HttpStatusCode.Ok) {
  //                     this.ui.loaded().subscribe(() => {
  //                       this.openSnackBar('Sucesso', 'Pesquisa Realizada com Sucesso');
  //                       this.adicionarAba(request.numero, data, true);
  //                       this.getChips();
  //                       this.addedItems = [];
  //                       this.myControl.reset();
  //                       this.isDisabled = false;
  //                     })
  //                     this.formPesquisa.valorAdicional = this.adicionaisToRequest
  //                   }
  //                   this.pesquisarClicked(1);
  //                 });
  //               } else {
  //                 this.ui.loaded().subscribe(() => {
  //                   this.formPesquisa.nome = "";
  //                   //this.toastr.warning(Textos.NOME_DUPLICADA, "Portal Credvalue")
  //                   this.openDialog(response.list);
  //                   this.isDisabled = false;
  //                 });
  //                 return;
  //               }
  //             });
  //           }

  //           else {
  //             this.formPesquisa.nome = resultadoResolverNome.nome;
  //             this.formFiltro.nome = this.formPesquisa.nome;
  //             // this.formFiltro.idFonteGrupo = this.formPesquisa.idFonteGrupo;
  //             this.formFiltro.numero = this.formPesquisa.numero;
  //             this.formPesquisa.tags = this.addedItems;

  //             let requestCount = Object.assign({}, this.formFiltro);
  //             console.log(requestCount)
  //             this.pesquisaService.getPesquisaRealizada(requestCount, "patrimonial").subscribe(response => {
  //               if (response.count == 0 || this.refazer || response.list.pesquisa.status == this.StatusPesquisa.Cancelada) {
  //                 this.refazer = false;
  //                 let request = Object.assign({}, this.formPesquisa);
  //                 console.log(request)
  //                 this.pesquisaService.cadastrarCliente(request).subscribe(data => {
  //                   if (HttpStatusCode.Ok) {
  //                     this.ui.loaded().subscribe(() => {
  //                       this.openSnackBar('Sucesso', 'Pesquisa Realizada com Sucesso');
  //                       this.adicionarAba(request.numero, data, true);
  //                       this.getChips();
  //                       this.addedItems = [];
  //                       this.myControl.reset();
  //                       this.isDisabled = false;
  //                     })
  //                     this.formPesquisa.valorAdicional = this.adicionaisToRequest
  //                   }
  //                   this.pesquisarClicked(1);
  //                 });
  //               } else {
  //                 this.ui.loaded().subscribe(() => {
  //                   this.formPesquisa.nome = "";
  //                   //this.toastr.warning(Textos.NOME_DUPLICADA, "Portal Credvalue")
  //                   this.openDialog(response.list)
  //                  console.log(this.formPesquisa.valorAdicional);
  //                  this.isDisabled = false;
  //                 });
  //                 return;
  //               }
  //             });
  //           }
  //         }, (err: any) => {
  //           this.ui.loaded().subscribe(() => {
  //             this.openSnackBarWarning('Atenção', err.error);
  //             this.isDisabled = false;
  //           })
  //         });
  //       }
  //       if(result == 'cancelar'){
  //         this.formPesquisa.valorAdicional = this.adicionaisToRequest;
  //         this.isDisabled = false;
  //       }
  //     });
  //   }
  //   this.isDisabled = false;
  // }

  // clearForm() {
  //   this.formFiltro.nome = '';
  //   this.formFiltro.numero = '';
  //   this.formFiltro.status = '';
  //   this.formFiltro.tag = '';
  //   this.campaignOne.get('start').setValue(null);
  //   this.campaignOne.get('end').setValue(null);
  //   this.campaignTwo.get('start').setValue(null);
  //   this.campaignTwo.get('end').setValue(null);
  // }

  // editarTag(value){
  //   let tags = value.tags
  //   let IdPesquisa = value.id
  //   console.log(tags)
  //   let dialogRef = this.dialog.open(ModalEdicaoTagComponent,{
  //     width: '450px',
  //     panelClass: 'bg-color',
  //     maxHeight: '600px',
  //     data: {
  //       tags: tags,
  //       clienteId: this.usuario.cliente.id,
  //       label: this.labelTag,
  //       idPesquisa: IdPesquisa
  //     }
  //   });
  //   dialogRef.afterClosed().subscribe(result => {
  //     if (result == true) {
  //      this.pesquisarClicked();
  //      this.getChips();
  //     }
  //   });
  // }

  // openDialog(pespAnterior): void {

  //   console.log(pespAnterior);
  //   let dialogRef = this.dialog.open(PopPesqRealizadaComponent, {
  //     data: pespAnterior
  //   });

  //   dialogRef.afterClosed().subscribe(result => {
  //     if (result.data == true) {
  //       this.refazer = true;
  //       this.confirmarPesquisaClicked();
  //     }

  //     if(result == ''){
  //       this.formPesquisa.valorAdicional = this.adicionaisToRequest
  //     }
      
  //   });
  // }

  // openSnackBar(titulo: string, mensagem: string) {
  //   this._snackBar.openFromComponent(MensageResponseComponent, {
  //     data: {
  //       titulo: titulo,
  //       mensagem: mensagem,
  //     },
  //     duration: 5000,
  //     horizontalPosition: 'right',
  //     verticalPosition: 'top'
  //   });
  // }

  // openSnackBarWarning(titulo: string, mensagem: string) {
  //   this._snackBar.openFromComponent(MensageErrorResponseComponent, {
  //     data: {
  //       titulo: titulo,
  //       mensagem: mensagem,
  //     },
  //     duration: 5000,
  //     horizontalPosition: 'right',
  //     verticalPosition: 'top'
  //   });
  // }

  // onFonteValue(event) {
  //   var grupoSelecionado = this.listaFonteGrupo.find(f => f.fonteGrupo.id == event.value);
  //   this.listaFontesAdicionais = grupoSelecionado.fonteGrupo.listaFonteGrupoItem.filter(x => x.fonteSelecionavel == true)
  //   //aqui verifica se tem a fonte 91 no grupo de fontes, ou seja se tem a fonte de SP e deixa ela selecionada
  //   var patrimoniosSP = this.listaFontesAdicionais.filter(x => x.fonte == 91)
  //   if (patrimoniosSP) {
  //     this.defaultSelectedValue = patrimoniosSP
  //   }
  //   if (grupoSelecionado.fonteGrupo.listaFonteGrupoItem.some(fonteitem => [Fonte.CertidaoDividaUniaoImovelRural].includes(fonteitem.fonte))) {
  //     this.formCadastroValorAdicional = true;
  //     this.formCadastroValorAdicionalLabel = this.tipoLabels.get(Fonte.CertidaoDividaUniaoImovelRural);
  //   } else {
  //     this.formCadastroValorAdicional = false;
  //   }
  // }
  // ajustaNome(nome: string, tamanho: any) {
  //   let nomeRetorno = nome;
  //   if (nome.length >= tamanho) {
  //     nomeRetorno = nome.substring(0, tamanho - 3) + '...';
  //   }
  //   return nomeRetorno;
  // }

  // desabilitaPesquisa() {
  //   if (this.formPesquisa.numero.length === 11 || this.formPesquisa.numero.length === 14) {
  //     return true
  //   }
  //   return false
  // }

  // verificaTipoDocumento() {
  //   let cnpj = 2
  //   let cpf = 1
  //   if (this.formPesquisa.numero.length == 11) {
  //     this.formPesquisa.tipo = cpf
  //   }
  //   else if (this.formPesquisa.numero.length == 14) {
  //     this.formPesquisa.tipo = cnpj
  //   }
  //   console.log(this.formPesquisa)
  // }

  // adicionarAba(documento, idPesquisa, abaPesquisa) {
  //   const abaExistente = this.abas.find(aba => aba.id === idPesquisa);

  // if (abaExistente) {
  //   if (this.selectedIndex !== this.abas.indexOf(abaExistente)) {
  //     console.log(`A aba com idPesquisa ${idPesquisa} já existe.`);
  //     // Aqui você pode decidir se deseja destacar a aba existente ou realizar outra ação.
  //   }
  // } else {
  //   const novaAbaTitulo = `${documento}`;
  //   this.abas.push({ titulo: novaAbaTitulo, id: idPesquisa, novaPesquisa:abaPesquisa });
  //   this.selectedIndex = this.abas.length - 1;
  // }
  // }

  // fecharAba(index: number) {
  //   this.abas.splice(index, 1);
  // }

  // atualizarIndiceAbaSelecionada(event: any) {
  //   this.selectedIndex = this.tabGroup.selectedIndex;
  // }

}
