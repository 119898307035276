<!-- <form class="div-ext" [formGroup]="resetPasswordForm" aria-labelledby="title">
  <div class="input">
    <div class="form-control-group">
      <label for="input-password">SENHA:</label>
      <input formControlName="password" id="input-password"  type="password" class="first" />
    </div>

    <div class="form-control-group">
      <label for="input-re-password">CONFIRMAÇÃO DE  SENHA:</label>
      <input formControlName="confirmPassword" id="input-re-password"  type="password" />
      <p class="error-message caption status-danger" *ngIf="password != null && confirmPassword != null && password.value != confirmPassword.value && confirmPassword.dirty">
        Senhas não conferem!
      </p>
    </div>
  </div>

  <button [disabled]="!password" (click)="resetPass()" status="success">
    Alterar Senha
  </button>
  <section class="sign-in-or-up" aria-label="Sign in or sign up">
    <p><a class="text-link" routerLink="../login">Login</a></p>
  </section>
</form> -->


<div class="card-body px-4 py-5 px-md-5">
  <form [formGroup]="resetPasswordForm" (ngSubmit)="resetPass()">
    <!-- Nova Senha input -->
    <div class="form-outline mb-4 text-left">
      <label class="form-label" for="form3Example3">Nova Senha</label>
      <input type="password" id="form3Example3" class="form-control" formControlName="password" autofocus />
      <p class="error-message caption status-danger mt-2 text-danger"
        *ngIf="password.invalid && (password.dirty || password.touched)">
        <ng-container *ngIf="password.errors?.required">
          A senha é obrigatória.
        </ng-container>
        <ng-container *ngIf="password.errors?.minlength">
          A senha deve ter no mínimo 8 caracteres.
        </ng-container>
        <ng-container *ngIf="password.errors?.senhaInvalida">
          A senha deve conter maiúsculas, minúsculas, números e caracteres especiais.
        </ng-container>
      </p>
    </div>

    <!-- Confirmar Nova Senha input -->
    <div class="form-outline mb-4 text-left">
      <label class="form-label" for="form3Example4">Confirme a Nova Senha</label>
      <input type="password" id="form3Example4" class="form-control" formControlName="confirmPassword" />
      <p class="error-message caption status-danger mt-2 text-danger"
        *ngIf="confirmPassword.invalid && (confirmPassword.dirty || confirmPassword.touched)">
        <ng-container *ngIf="confirmPassword.errors?.required">
          Confirmação de senha é obrigatória.
        </ng-container>
        <ng-container *ngIf="confirmPassword.value && confirmPassword.value !== password.value">
          As senhas não conferem!
        </ng-container>
      </p>
    </div>

    <!-- Botões de Submit -->
    <div class="row d-flex justify-content-center">
      <div class="col-md-6 mb-4">
        <div class="form-outline">
          <button type="submit" [disabled]="!resetPasswordForm.valid || password.value !== confirmPassword.value"
            class="btn btn-primary btn-block mb-4 btn-entrar pt-2 pb-2">
            Alterar Senha
          </button>
        </div>
      </div>
      <div class="col-md-6 mb-4">
        <div class="form-outline">
          <button type="button" class="btn btn-primary btn-block mb-4 btn-entrar pt-2 pb-2" (click)="backRoute()">
            Cancelar
          </button>
        </div>
      </div>
    </div>
  </form>

</div>
