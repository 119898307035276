import { ChangeDetectionStrategy, Component, HostListener, ChangeDetectorRef, Input, OnInit, ViewChild } from '@angular/core';
import { MensageResponseComponent } from '../../view/mensage-response/mensage-response.component';
import { PopPesqRealizadaComponent } from '../../view/poppesqrealizada/poppesqrealizada.component';
import { HttpStatusCode } from '@angular/common/http';
import { Textos } from 'src/app/shared/textos';
import { ModalEdicaoTagComponent } from '../../view/modal-edicao-tag/modal-edicao-tag.component';
import { ModalExportarPesquisaLoteComponent } from '../../view/modal-exportar-pesquisa-lote/modal-exportar-pesquisa-lote.component';
import { MatCheckbox, MatCheckboxChange } from '@angular/material/checkbox';
import { ModalConfirmacaoComponent } from '../../view/modal-confirmacao/modal-confirmacao.component';
import { Observable, forkJoin, map, startWith } from 'rxjs';
import { FormControl, FormGroup } from '@angular/forms';
import { MatTabGroup } from '@angular/material/tabs';
import { ConfiguracaoService } from 'src/app/shared/services/http/configuracao.service';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from 'src/app/shared/services/http/auth.service';
import { HeaderService } from '../header/header.service';
import { FonteGrupoService } from 'src/app/shared/services/http/fonte-grupo.service';
import { ToastrService } from 'ngx-toastr';
import { UiService } from 'src/app/shared/services/ui.service';
import { PesquisaService } from 'src/app/shared/services/http/pesquisa.service';
import { UtilService } from 'src/app/shared/services/http/util.service';
import { NavService } from '../nav/nav.service';
import { Fonte } from 'src/app/shared/enums/fonte.enum';
import { StatusRegistro } from 'src/app/shared/enums/status-registro.enum';
import { StatusPesquisa } from 'src/app/shared/enums/status-pesquisa.enum';
import { TipoPesquisa } from 'src/app/shared/enums/tipo-pesquisa.enum';
import { MensageErrorResponseComponent } from '../../view/mensage-error-response/mensage-error-response.component';
import { CategoriaFonteEnum } from 'src/app/shared/enums/categoria-fonte.enum';
import { MatOption } from '@angular/material/core';
import { ModalValoresPesquisaComponent } from './modal-valores-pesquisa/modal-valores-pesquisa.component';
import { ModalMenorIdadeComponent } from './modal-menor-idade/modal-menor-idade.component';
import { UsuarioService } from 'src/app/shared/services/http/usuario.service';
import { Z } from '@angular/cdk/keycodes';
import { Router } from '@angular/router';
import { MatSelect } from '@angular/material/select';
import { ModalPesquisaLoteComponent } from './modal-pesquisa-lote/modal-pesquisa-lote.component';

const today = new Date();
const month = today.getMonth();
const year = today.getFullYear();

@Component({
  selector: 'app-produto-pesquisa',
  templateUrl: './produto-pesquisa.component.html',
  styleUrls: ['./produto-pesquisa.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class ProdutoPesquisaComponent implements OnInit {

  textosProdutos = [
    { id: 1, titulo: 'Pesquisa de Informações Cadastrais' },
    { id: 2, titulo: 'Pesquisa de Processos' },
    { id: 3, titulo: 'Pesquisa Patrimonial' },
    { id: 4, titulo: 'Análise de Crédito' },
    { id: 5, titulo: 'Dossiê Completo' },
  ]

  // ===== definicao do produto ====
  @Input() idProduto: number;
  formFiltro: any = { page: 1, pageSize: 50, perfil: "", status: "", produtoID: 0 };

  StatusRegistro = StatusRegistro;
  StatusPesquisa = StatusPesquisa;
  TipoPesquisa = TipoPesquisa;
  pesquisasSelecionadas = [];
  formCadastroValorAdicional: boolean = false;
  formCadastroValorAdicionalLabel: string = "";
  Fonte = Fonte;
  tipoLabels: Map<Fonte, string> = new Map<Fonte, string>();

  formPesquisa: any = {};
  formFiltroRenderizado: any = this.formFiltro;

  refazer: boolean = false;
  resultadoPesquisa: any = [];
  usuario: any;
  configuracao: any = {};
  listaFonteGrupo: any = [];
  isSuzano = false;
  exibeToast = false
  selected: any;
  isDisabled = false;

  dataNasc;
  listaFontesAdicionaisProcesso = [];
  listaFontesAdicionaisPatrimonial = [];
  // listaFontesAdicionaisSemFiltro = [];

  listaConfiguracoesParametros = [];
  parametrosSelecionados = []
  valoresMensagemParametros = []
  valoresMensagemAdicionais = []
  listaFontesAdicionais;
  parametrosToRequest: any = [];
  checked = false;
  adicionaisToRequest = [];
  formParametros = [];
  cidadesSelecionadas = [];
  parametrosCidadesToRequest: any = {};
  parametrosAdicionaisToRequest: any = {};
  objValorAdicional;
  resultadoResolverNome;
  tipoDocumento;
  countPesquisaExistente;

  objetoRetornoPesquisa;

  objMenorIdade = [];

  valorCidades: number = 0;
  valorEstados: number = 0;

  myControl = new FormControl();
  tagIds = [];
  addedItems = [];
  autoCompletePanel: any;
  labelTag: String = 'Tag';
  filteredTags: Observable<any[]>;

  filterCidade: string = '';
  filteredCidades: any[] = [];

  docControl = new FormControl();
  docsAdded = [];
  docsIds = [];
  // autoCompletePanel: any;

  campaignOne = new FormGroup({
    start: new FormControl(new Date(year, month, 13)),
    end: new FormControl(new Date(year, month, 16)),
  });
  campaignTwo = new FormGroup({
    start: new FormControl(new Date(year, month, 15)),
    end: new FormControl(new Date(year, month, 19)),
  });
  @ViewChild('allSelected') private allSelected: MatOption;
  @ViewChild('selectPanel') selectPanel: MatSelect;


  valorAdicionalFonte: number = 0;
  valorAdicionalParametro: number = 0;
  abas: { titulo: string, id: number, novaPesquisa: boolean, identificador: any, dataNasc: any, dadosPesquisa: any }[] = [{ titulo: 'Histórico', id: null, novaPesquisa: false, identificador: null, dataNasc: null, dadosPesquisa: null }];
  selectedIndex = 0;
  @ViewChild(MatTabGroup) tabGroup: MatTabGroup;
  panelOpenState = false;

  isDragging = false;
  startX = 0;
  startY = 0;
  initialLeft = 0;
  initialTop = 0;

  mensagemQualificada = '';

  isCadastrais = false;
  isProcessos = false;
  isPatrimonial = false;
  isAnaliseCredito = false;
  isDossie = false;
  requestHeader: string = ''

  tipoAcao = [];
  processoStatus = [];


  constructor(
    private pesquisaService: PesquisaService,
    private utilService: UtilService,
    private ui: UiService,
    private toastr: ToastrService,
    private usuarioService: UsuarioService,
    private configuracaoService: ConfiguracaoService,
    private fonteGrupoService: FonteGrupoService,
    private headerService: HeaderService,
    private navService: NavService,
    private authService: AuthService,
    private _snackBar: MatSnackBar,
    public router: Router,
    public dialog: MatDialog) {
    navService.navData = {
      title: 'Dossie'
    }
    headerService.headerData = {
      title: 'Dossie'
    }
  }

  ngOnInit(): void {
    if(this.router.url == '/cadastrais'){ this.isCadastrais = true; this.requestHeader = 'cadastrais'  }
    if(this.router.url == '/processo'){ this.isProcessos = true; this.requestHeader = 'processos'  }
    if(this.router.url == '/patrimonial'){ this.isPatrimonial = true; this.requestHeader = 'patrimonial'  }
    if(this.router.url == '/credito'){ this.isAnaliseCredito = true; this.requestHeader = 'analise-credito'  }
    if(this.router.url == '/dossie'){ this.isDossie = true; this.requestHeader = 'dossie-completo' }
    this.formFiltro.produtoID = this.idProduto;
    this.getTituloById(this.idProduto);
    this.getUsuario();
    this.formPesquisa.numero = "";
    this.formPesquisa.notificacao = true
    this.formPesquisa.valorAdicional = [];
    this.ui.loading();

    let requests = [
      this.configuracaoService.get(),
      this.fonteGrupoService.listAtivoProduto(this.idProduto)
    ];

    forkJoin(requests).subscribe(data => {
      if (data && data[1].length == 0) {
        this.ui.loaded().subscribe(() => {
          this.toastr.warning('Nenhum grupo de fontes vinculado ao pacote do usuário, entre em contato com o suporte.');
          this.pesquisarClickedSemLoader(1);
        })
      }
      this.getChipsSemLoader();
      this.configuracao = data[0];
      this.listaFonteGrupo = data[1];
      this.formPesquisa.idFonteGrupo = this.listaFonteGrupo[0]?.fonteGrupo?.id;
      this.defineParametroseFontesSelecionaveis(this.listaFonteGrupo[0]);
      this.pesquisarClickedSemLoader(1);
      this.filteredTags = this.myControl.valueChanges.pipe(
        startWith(''),
        map(value => this._filterTags(value))
      );
    });
    this.tipoLabels.set(Fonte.CertidaoDividaUniaoImovelRural, "NIRF");

    // Adicione aqui a lógica para inicializar as abas
    let abasAbertas = this.usuarioService.getTabState(this.usuario.id, this.idProduto);
    // this.cdr.markForCheck();
    if (abasAbertas.length > 0) {
      // Filtrar a aba de histórico de pesquisas
      this.abas = abasAbertas.filter(aba => aba.identificador !== null);
    }
    // Adicionar a aba de histórico de pesquisas se ainda não estiver presente
    if (!this.abas.find(aba => aba.identificador === null)) {
      this.abas.unshift({ titulo: 'Histórico', id: null, novaPesquisa: false, identificador: null, dataNasc: null, dadosPesquisa: null });
    }
    if (this.abas.length > 0) {
      // Se existem abas, obter o título da primeira aba e fazer qualquer outra inicialização necessária
      const tituloPrimeiraAba = this.abas[0].titulo;
      // Adicione aqui qualquer outra lógica relacionada às abas
    }

    if(this.isDossie || this.isProcessos){
      this.getProcessoTipoAcao();
      this.getProcessoStatus();
      this.formFiltro.valor = '';
    }

    setInterval(() => {
      let page = this.formFiltro.page;
      this.pesquisarClickedSemLoader(page);
    }, 1000 * 60)
  }

  defineParametroseFontesSelecionaveis(data) {
    // === define o valor de fontes adicionais que estao vincular ao id do grupo de fontes 
    this.listaFontesAdicionais = data;

    // === define o configuracaoParametros baseado no lista fronte grupo 
    this.listaConfiguracoesParametros = this.listaFontesAdicionais.fonteGrupo.listaFonteGrupoItem.filter(x => x.fonteConfiguracao.configuracaoParametros.length > 0 && x.fonteSelecionavel == false)
    this.listaConfiguracoesParametros = this.listaConfiguracoesParametros.flatMap(x => x.fonteConfiguracao.configuracaoParametros)

    // == define o lista fonte grupo baseado nas fontes que sao selecionaveis 
    this.listaFontesAdicionais = this.listaFontesAdicionais.fonteGrupo.listaFonteGrupoItem.filter(x => x.fonteSelecionavel == true);

    // == define as fontes selecionaveis por produto baseado na categoria 
    this.listaFontesAdicionaisProcesso = this.listaFontesAdicionais.filter(x => x.fonteConfiguracao.categoria == CategoriaFonteEnum.Processo);
    this.listaFontesAdicionaisPatrimonial = this.listaFontesAdicionais.filter(x => x.fonteConfiguracao.categoria == CategoriaFonteEnum.Patrimonial);

    // == todas as outras que nao possuem categoria mas sao selecionaveis
    this.listaFontesAdicionais = this.listaFontesAdicionais.filter(x => x.fonteConfiguracao.categoria == 0)

    // == ordenando as fontes em ordem alfabetica
    this.listaFontesAdicionais.sort((a, b) => (a.fonteConfiguracao.nome > b.fonteConfiguracao.nome) ? 1 : -1);
  }

  getTituloById(id: number): string {
    const produto = this.textosProdutos.find(item => item.id === id);
    return produto ? produto.titulo : '';
  }

  getProcessoTipoAcao(){
    this.pesquisaService.getProcessoTipoAcao(this.usuario.cliente.id).subscribe(data => {
       this.tipoAcao = data
    });
  }

  getProcessoStatus(){
    this.pesquisaService.getProcessoStatus(this.usuario.cliente.id).subscribe(data => {
       this.processoStatus = data;
    });
  }

  selecionarFonteAdicional(event: MatCheckboxChange, value: any) {
    if (event.checked) {
      this.formPesquisa.valorAdicional.push(value);
    } else {
      const indice = this.formPesquisa.valorAdicional.indexOf(value);
      if (indice > -1) {
        this.formPesquisa.valorAdicional.splice(indice, 1);
      }
      this.desmarcarParametros(value);
    }

    if (value.fonteConfiguracao.configuracaoParametros.length > 0 && event.checked && value.fonteConfiguracao.configuracaoParametros.some(x => x.cidade === false || x.cidade === undefined)) {
      this.listaConfiguracoesParametros.push(value.fonteConfiguracao.configuracaoParametros[0]);
    } else if (value.fonteConfiguracao.configuracaoParametros.length > 0 && !event.checked) {
      this.desmarcarParametros(value);
      const indiceParam = this.listaConfiguracoesParametros.indexOf(value.fonteConfiguracao.configuracaoParametros[0]);
      if (indiceParam > -1) {
        this.listaConfiguracoesParametros.splice(indiceParam, 1);
      }
    }

    this.adicionaisToRequest = this.formPesquisa.valorAdicional;
    this.valorEstados = this.formPesquisa.valorAdicional.reduce((total, item) => {
      // Verifica se `item.fonteConfiguracao.preco` existe, caso contrário, usa 0
      const preco = item.fonteConfiguracao && item.fonteConfiguracao.preco ? item.fonteConfiguracao.preco : 0;
      return total + preco;
    }, 0);
  }

  // Método para desmarcar os parâmetros de uma fonte específica
  desmarcarParametros(estado: any) {
    if (estado && estado.fonteConfiguracao && estado.fonte) {
      estado.fonteConfiguracao.configuracaoParametros.forEach((parametro: any) => {
        parametro.selecionado = false;
      });
      this.cidadesSelecionadas = this.cidadesSelecionadas.filter(param => param.fonte !== estado.fonte);
      this.atualizarParametrosToRequest();
    }
  }

  // Método para atualizar os parâmetros das cidades
  atualizarParametrosToRequest() {
    this.parametrosCidadesToRequest = {};
    this.cidadesSelecionadas.forEach(obj => {
      const chave = `${obj.fonte}`;
      if (!this.parametrosCidadesToRequest[chave]) {
        this.parametrosCidadesToRequest[chave] = [];
      }
      if (!this.parametrosCidadesToRequest[chave].includes(obj.descricao)) {
        this.parametrosCidadesToRequest[chave].push(obj.descricao);
      }
    });
  }

  // Método para selecionar ou desmarcar parâmetros adicionais
  selecionarParametros(event: MatCheckboxChange, value: any) {
    value.selecionado = event.checked;
    const objSelecionado = { [value.descricao]: event.checked };
    if (event.checked) {
      this.parametrosAdicionaisToRequest = { ...this.parametrosAdicionaisToRequest, ...objSelecionado };
      this.parametrosSelecionados.push(value);
    } else {
      const { [value.descricao]: omit, ...rest } = this.parametrosAdicionaisToRequest;
      this.parametrosAdicionaisToRequest = { ...rest };
      const indice = this.parametrosSelecionados.indexOf(value);
      if (indice > -1) {
        this.parametrosSelecionados.splice(indice, 1);
      }
    }
  }

  // Método para selecionar ou desmarcar parâmetros de cidades
  selecionarParametrosCidades(event: MatCheckboxChange, value: any) {
    value.selecionado = event.checked;
    if (event.checked) {
      if (!this.cidadesSelecionadas.some(param => param.descricao === value.descricao)) {
        this.cidadesSelecionadas.push(value);
      }
    } else {
      this.cidadesSelecionadas = this.cidadesSelecionadas.filter(param => param.descricao !== value.descricao);
    }
    this.atualizarParametrosToRequest();
    this.valorCidades = this.cidadesSelecionadas.reduce((total, item) => {
      return total + item.precoPJ;
    }, 0);
    // console.log(this.cidadesSelecionadas)
  }

  selecionarTodasCidades(event: MatCheckbox){
    let cidadesParametros = this.formPesquisa.valorAdicional.map(x => x.fonteConfiguracao.configuracaoParametros);
    if(event.checked){
      this.cidadesSelecionadas = cidadesParametros[0].filter(x => x.cidade == true);
      this.cidadesSelecionadas.forEach(fonte => {
        fonte.selecionado = true;
      });
      this.valorCidades = this.cidadesSelecionadas.reduce((total, item) => {
        return total + item.precoPJ;
      }, 0);
    }

    if(!event.checked){
      this.cidadesSelecionadas.forEach(fonte => {
        fonte.selecionado = false;
      });
      this.cidadesSelecionadas = [];
      this.valorCidades = this.cidadesSelecionadas.reduce((total, item) => {
        return total + item.precoPJ;
      }, 0);
    }
    this.atualizarParametrosToRequest()
  }

  // Método para combinar os dois grupos de parâmetros ao enviar
  getParametrosToRequest() {
    return {
      ...this.parametrosAdicionaisToRequest,
      ...this.parametrosCidadesToRequest
    };
  }

  // Chame este método quando precisar dos parâmetros finais para envio
  enviarParametros() {
    const parametrosFinais = this.getParametrosToRequest();
    // console.log(parametrosFinais);
    // Aqui você pode prosseguir com o envio dos parâmetros finais
  }

  hasValorAdicionalComCidade() {
    if (this.formPesquisa.valorAdicional.some(x => x.fonteConfiguracao.configuracaoParametros.some(x => x.cidade))) {
      return true
    }
  }

  hasValorAdicionalComCidadeEstado(estado) {
    if (estado.fonteConfiguracao.configuracaoParametros.some(x => x.cidade)) {
      this.filteredCidades = [...estado.fonteConfiguracao.configuracaoParametros];
      this.applyFilter(this.filteredCidades); 
      return true
    }
  }

  private _filterTags(value: string): any[] {
    const filterValue = value.toLowerCase();
    return this.tagIds.filter(tag => tag.tag.toLowerCase().includes(filterValue));
  }

  getUsuario() {
    this.authService.callbackUsuario().subscribe(usuario => {
      if (!usuario) {
        return;
      }
      if (usuario) {
        this.usuario = usuario;
        if (usuario.cliente.descricaoTag && usuario.cliente.descricaoTag.trim().length > 0) {
          this.labelTag = usuario.cliente.descricaoTag;
        }
      }
      if (usuario.cliente.id == 52) {
        this.isSuzano = true
      }
    })
  }

  addChip(value) {
    this.myControl.reset();
    if (!this.addedItems.find(item => item.tag === value.tag)) {
      this.addedItems.push(value);
    }
    this.tagIds = this.tagIds.filter(item => item.tag !== value.tag);
    this.filteredTags = this.filteredTags.pipe(
      map(tags => tags.filter(tag => tag.tagId !== value.tagId))
    );
    this.myControl.reset();
  }

  removeChip(item): void {
    const index = this.addedItems.indexOf(item);
    if (index !== -1) {
      this.addedItems.splice(index, 1);
      this.tagIds.push(item);  // Adiciona o item de volta ao array tagIds
      this.filteredTags = this.myControl.valueChanges.pipe(
        startWith(''),
        map(value => this._filterTags(value))
      );
    }
  }

  onInputBlur(): void {
    const value = this.myControl.value.trim();
    const valueAdded = this.addedItems.map(x => x.tag)
    const valueGetted = this.tagIds.map(x => x.tag)
    if (value !== '' && !valueAdded.includes(value) && !valueGetted.includes(value)) {
      this.enviarChips(value)
    }

    this.myControl.reset();
  }

  enviarChips(value) {
    this.ui.loading();
    const obj = {
      clienteId: this.usuario.cliente.id,
      tags: [{
        tagId: 0,
        tag: value
      }]
    }
    this.pesquisaService.cadastrarTagId(obj).subscribe(response => {
      this.ui.loaded().subscribe(() => {
        this.addedItems.push(response[0])
        this.filteredTags = this.myControl.valueChanges.pipe(
          startWith(''),
          map(value => this._filterTags(value))
        );
        this.myControl.reset();
      })
    }, (err: any) => {
      this.ui.loaded().subscribe(() => {
        this.toastr.error('Ocorreu um erro, tente novamente.')
      })
    });
  }

  onEnter(event: KeyboardEvent): void {
    if (document.activeElement === event.target) {
      event.preventDefault();

      const value = this.myControl.value.trim();
      const valueAdded = this.addedItems.map(x => x.tag)
      const valueGetted = this.tagIds.map(x => x.tag)
      if (value !== '' && !valueAdded.includes(value) && valueGetted.includes(value)) {
        let tagEnter = this.tagIds.filter(x => x.tag == value);
        this.addChip(tagEnter[0])
      }
      if (value !== '' && !valueAdded.includes(value) && !valueGetted.includes(value)) {
        this.enviarChips(value)
      }

      this.myControl.reset();
    }
  }

  getChips() {
    this.ui.loading()
    this.pesquisaService.getTagID(this.usuario.cliente.id).subscribe(response => {
      this.ui.loaded().subscribe(() => {
        this.tagIds = response
      })
    }, (err: any) => {
      this.ui.loaded().subscribe(() => {
        this.toastr.error('Ocorreu um erro, tente novamente.')
      })
    });
  }

  getChipsSemLoader() {
    // this.ui.loading()
    this.pesquisaService.getTagID(this.usuario.cliente.id).subscribe(response => {
      this.ui.loaded().subscribe(() => {
        this.tagIds = response
      })
    }, (err: any) => {
      this.ui.loaded().subscribe(() => {
        this.toastr.error('Ocorreu um erro, tente novamente.')
      })
    });
  }


  deletarTagExistente(value) {
    const dados = {
      title: '',
      message: 'Deseja realmente excluir esse item?',
      cancelText: 'Cancelar',
      confirmText: 'excluir'
    }
    const dialogRef = this.dialog.open(ModalConfirmacaoComponent, {
      maxWidth: "470px",
      data: dados
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult == true) {
        this.ui.loading()
        this.pesquisaService.excluirTag(value.tagId).subscribe(response => {
          this.ui.loaded().subscribe(() => {
            // this.tagIds = response
            this.toastr.info('Tag excluída com sucesso.', 'CredValue');
            this.tagIds = this.tagIds.filter(i => i.tagId != value.tagId);
            this.filteredTags = this.filteredTags.pipe(
              map(tags => tags.filter(tag => tag.tagId != value.tagId))
            );
            this.getChips()
          })
        }, (err: any) => {
          this.ui.loaded().subscribe(() => {
            this.toastr.error('Ocorreu um erro, tente novamente.')
          })
        });
      }
    });

  }

  toggleItemSelection(pesquisa) {
    if (this.isItemSelected(pesquisa)) {
      this.pesquisasSelecionadas = this.pesquisasSelecionadas.filter(
        (selectedItem) => selectedItem.id !== pesquisa.id
      );
    } else {
      this.pesquisasSelecionadas.push(pesquisa);
    }
  }

  // Verifica se o item está selecionado
  isItemSelected(item: any): boolean {
    return this.pesquisasSelecionadas.some((selectedItem) => selectedItem.id === item.id);
  }

  selecionarTodosItens(isSelected: MatCheckbox) {
    if (isSelected.checked) {
      this.resultadoPesquisa.forEach(item => {
        if (!this.isItemSelected(item)) {
          this.pesquisasSelecionadas.push(item);
        }
      });
    } else {
      this.resultadoPesquisa.forEach(item => {
        this.pesquisasSelecionadas = this.pesquisasSelecionadas.filter(
          selectedItem => selectedItem.id !== item.id
        );
      });
    }
  }

  exportarExcelLote() {
    if(this.pesquisasSelecionadas.length == 0){
       this.openSnackBarWarning('Exportar', 'É necessário selecionar pelo menos uma pesquisa para exportar');
      return
    }
    const arrayPesquisasSelecionadas = {
      ListaPesquisa: this.pesquisasSelecionadas.map(x => x.id)
    };
  
    // Exibir o SnackBar de carregamento
    const snackBarRef = this._snackBar.openFromComponent(MensageErrorResponseComponent, {
      data: {
        titulo: 'Exportando...',
        mensagem: 'Aguarde, estamos processando a sua solicitação',
      },
      duration: undefined,
      horizontalPosition: 'right',
      verticalPosition: 'top'
    });
  
    // Tempo mínimo para exibir o SnackBar (em milissegundos)
    const tempoMinimo = 2000;
  
    // Marca de tempo de início da exportação
    const inicio = Date.now();
  
    this.pesquisaService.exportarExcelLote(arrayPesquisasSelecionadas).subscribe({
      next: (data) => {
        // Baixar o arquivo
        var a = document.createElement("a");
        a.href = "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," + data.arquivoConteudo;
        a.download = data.arquivoNome;
        a.click();
      },
      error: (err: any) => {
        // Mostrar mensagem de erro
        this.openSnackBarWarning('Atenção: ', err.error);
      },
      complete: () => {
        // Calcular o tempo restante para completar os 2 segundos
        const tempoDecorrido = Date.now() - inicio;
        const tempoRestante = Math.max(tempoMinimo - tempoDecorrido, 0);
  
        // Aguardar o tempo restante antes de fechar o SnackBar
        setTimeout(() => snackBarRef.dismiss(), tempoRestante);
      }
    });
  }

  exportarPesquisaLote() {
    if(this.pesquisasSelecionadas.length == 0){
      this.openSnackBarWarning('Exportar','É necessário selecionar pelo menos uma pesquisa para exportar');
      return
    }
    console.log(this.pesquisasSelecionadas.map(x => x.id))
    const menu = this.dialog.open(ModalExportarPesquisaLoteComponent, {
      width: '450px',
      panelClass: 'bg-color',
      maxHeight: '600px',
      data: this.pesquisasSelecionadas
    });
  }

  exportarLGPD(o) {
    this.ui.loading();
    this.pesquisaService.exportarLGPD({ id: o.id }, this.requestHeader).subscribe(data => {
      this.ui.loaded().subscribe(() => {
        var a = document.createElement("a");
        a.href = "data:application/zip;base64," + data.arquivoConteudo;
        a.download = data.arquivoNome;
        a.click();
      });
    }, (err: any) => {
      this.ui.loaded().subscribe(() => {
        this.openSnackBarWarning('Atenção', err.error);
      })
    });
  }

  pesquisarClicked(page = 1) {
    if(this.formFiltro.valor === 0){
      this.formFiltro.valor = ''
    }
    this.formPesquisa.valorAdicional = this.adicionaisToRequest
    this.formFiltro.page = page;
    this.ui.loading();

    if (page == 1)
      this.resultadoPesquisa = [];

    let request = Object.assign({}, this.formFiltro);
    if (request.status) request.status = [request.status];
    this.pesquisaService.listPesquisaCliente(request).subscribe(response => {
      this.formFiltroRenderizado = Object.assign({}, this.formFiltro);
      this.ui.loaded().subscribe(() => {
        this.verificaPesquisaQualificada(response);
        this.resultadoPesquisa = this.resultadoPesquisa.concat(response.list);
      });
    });

    this.tipoLabels.set(Fonte.CertidaoDividaUniaoImovelRural, "NIRF");
  }

  pesquisarClickedSemLoader(page = 1) {
    this.formFiltro.page = page;
    if(this.formFiltro.valor === 0){
      this.formFiltro.valor = ''
    }

    if (page == 1)
      this.resultadoPesquisa = [];

    let request = Object.assign({}, this.formFiltro);
    if (request.status) request.status = [request.status];
    this.pesquisaService.listPesquisaCliente(request).subscribe(response => {
      this.formFiltroRenderizado = Object.assign({}, this.formFiltro);
      this.verificaPesquisaQualificada(response);
      this.resultadoPesquisa = this.resultadoPesquisa.concat(response.list);
    });

    this.tipoLabels.set(Fonte.CertidaoDividaUniaoImovelRural, "NIRF");
  }

  onScroll() {
    let page = this.formFiltro.page + 1;
    this.pesquisarClicked(page);

  }

  editarTag(value, isRefazer) {
    let tags = value.tags
    let IdPesquisa = value.id
    let dialogRef = this.dialog.open(ModalEdicaoTagComponent, {
      width: '450px',
      panelClass: 'bg-color',
      maxHeight: '600px',
      data: {
        tags: tags,
        clienteId: this.usuario.cliente.id,
        label: this.labelTag,
        idPesquisa: IdPesquisa,
        refazer: isRefazer
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == true) {
        this.pesquisarClicked();
        this.getChips();
      }
    });
  }

  onScrollDown() {
  }

  onScrollUp() {
    let page = this.formFiltro.page - 1;
    if (page > 0) {
      this.pesquisarClicked(page);
    }
  }

  exportarClicked(o: { pesquisa: { id: any; }; }) {
    this.ui.loading();
    this.pesquisaService.exportar({ id: o.pesquisa.id }, this.requestHeader).subscribe(data => {
      this.ui.loaded().subscribe(() => {
        var a = document.createElement("a");
        a.href = "data:application/pdf;base64," + data.arquivoConteudo;
        a.download = data.arquivoNome;
        a.click();
      });
    }, (err: any) => {
      this.ui.loaded().subscribe(() => {
        this.openSnackBarWarning('Atenção', err.error);
      })
    });
  }

  exportarReciboClicked(o: { pesquisa: { id: any; }; }) {
    this.ui.loading();
    this.pesquisaService.exportarRecibo({ id: o.pesquisa.id },this.requestHeader).subscribe(data => {
      this.ui.loaded().subscribe(() => {
        var a = document.createElement("a");
        a.href = "data:application/pdf;base64," + data.arquivoConteudo;
        a.download = data.arquivoNome;
        a.click();
      });
    }, (err: any) => {
      this.ui.loaded().subscribe(() => {
        this.openSnackBarWarning('Atenção', err.error);
      })
    });
  }

  exportarExcelClicked(o: { pesquisa: { id: any; }; }) {
    this.ui.loading();
    this.pesquisaService.exportarExcel({ id: o.pesquisa.id }, this.requestHeader).subscribe(data => {
      this.ui.loaded().subscribe(() => {
        var a = document.createElement("a");
        a.href = "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," + data.arquivoConteudo;
        a.download = data.arquivoNome;
        a.click();
      });
    }, (err: any) => {
      this.ui.loaded().subscribe(() => {
        this.openSnackBarWarning('Atenção', err.error);
      })
    });
  }

  visualizarClicked(o: { pesquisa: { id: any; }; }) {
    this.toastr.warning("Em desenvolvimento", "Portal Credvalue")
  }

  async verificaPesquisaExistente(): Promise<boolean> {
    this.formPesquisa.numero = this.docsAdded[0].replaceAll('.', '').replaceAll('-', '').replaceAll('/', '');
    this.verificaTipoDocumento();
    try {
      const response = await this.pesquisaService.getPesquisaRealizada(this.formPesquisa, this.requestHeader).toPromise();
      this.countPesquisaExistente = response;
  
      if (this.refazer || this.countPesquisaExistente.list?.pesquisa?.status === this.StatusPesquisa.Cancelada) {
        this.refazer = false;
        return false; 
      }
  
      if (this.countPesquisaExistente.count > 0) {
        this.openDialog(this.countPesquisaExistente.list);
        return true;
      }
      
      if (this.countPesquisaExistente.count === 0) {
        // console.log('Nenhuma pesquisa existente encontrada.');
        return false;
      }
  
      return false; 
    } catch (err) {
      this.ui.loaded().subscribe(() => {
        if (err.error) {
          this.openSnackBarWarning('Atenção', err.error);
        } else {
          this.openSnackBarWarning('Atenção', 'Ocorreu um erro inesperado.');
        }
        this.isDisabled = false;
      });
      return false;
    }
  }
  

  async confirmarPesquisaClicked() {
    // this.enviarParametros()
    this.ui.loading()
    this.isDisabled = true;
    this.formPesquisa.numero = this.formPesquisa.numero.replaceAll('.', '').replaceAll('-', '').replaceAll('/', '');
    this.formPesquisa.nome = ""

    await this.verificaPesquisaExistente();

    if (this.countPesquisaExistente.count == 0 || this.refazer || this.countPesquisaExistente.list.pesquisa.status == this.StatusPesquisa.Cancelada) {
      this.utilService.resolverNome(this.formPesquisa, this.requestHeader).subscribe(resultadoResolverNome => {
        if (!resultadoResolverNome || !resultadoResolverNome.nome) {
          this.ui.loaded().subscribe(() => {
            this.formPesquisa.nome = "";
            this.toastr.warning(Textos.NOME_NAO_ENCONTRADO, "Portal Credvalue")
            this.isDisabled = false;
          });
          return;
        }
        if (this.adicionaisToRequest.length > 0 || this.parametrosSelecionados.length > 0) {
          this.resultadoResolverNome = resultadoResolverNome.nome;
          this.ui.loaded().subscribe(() => { });
          this.iniciarPesquisaComParametrosOuAdicionais();
        }

        if (this.adicionaisToRequest.length == 0 && this.parametrosSelecionados.length == 0) {
          this.resultadoResolverNome = resultadoResolverNome.nome
          this.IniciarPesquisaSemAdicionais()
        }
      }, (err: any) => {
        this.ui.loaded().subscribe(() => {
          this.openSnackBarWarning('Atenção', err.error);
          this.isDisabled = false
        })
      });
    }
    else {
      this.ui.loaded().subscribe(() => {
        this.formPesquisa.nome = "";
        this.openDialog(this.countPesquisaExistente.list)
        this.isDisabled = false
      });
      return;
    }
    this.isDisabled = false
  }

  async iniciarPesquisaComParametrosOuAdicionais() {
    // this.ui.loaded()
    // Seu código para iniciar a pesquisa com parâmetros ou adicionais
    // this.somarValorFontesAdicionais();
    // this.montaValorMensagemParametros();

    let fontesAdicionais;
    if (Array.isArray(this.adicionaisToRequest) && this.adicionaisToRequest.length > 0) {
      fontesAdicionais = this.adicionaisToRequest.map(x => x.fonte);
    } else {
      fontesAdicionais = [];
    }

    if (typeof this.formParametros === 'object' && Object.keys(this.formParametros).length > 0 || this.parametrosToRequest) {
      this.parametrosToRequest = { ...this.parametrosToRequest, ...this.formParametros };
    } else if (!this.parametrosToRequest) {
      this.parametrosToRequest = {};
    }
    this.parametrosToRequest = this.getParametrosToRequest()

    this.objValorAdicional = {
      filtros: {},
      parametros: this.parametrosToRequest,
      fontesAdicionais: fontesAdicionais
    };

    this.validaCodigoRetorno()


    // if (this.valoresMensagemAdicionais[0]?.valorTotal > 0 || this.valoresMensagemParametros[0]?.valorInformado > 0) {
    //   const dialog = this.dialog.open(ModalValoresPesquisaComponent, {
    //     width: '550px',
    //     panelClass: 'bg-color',
    //     data: {
    //       dadosAdicionais: this.valoresMensagemAdicionais,
    //       dadosParametros: this.valoresMensagemParametros
    //     }
    //   });

    //   dialog.afterClosed().subscribe(result => {
    //     if (result == 'realizarPesquisa') {
    //       this.enviarPesquisaParaProcessamento(); // Chamada movida para dentro do bloco if
    //     }
    //     if (result == 'cancelar') {
    //       this.IniciarPesquisaSemAdicionais()
    //     }
    //   });
    // }
  }

  aplicarMascaraDocumento(numero: string): string {
    if (numero.length === 11) {
      // CPF: 000.000.000-00
      return `${numero.substr(0, 3)}.${numero.substr(3, 3)}.${numero.substr(6, 3)}-${numero.substr(9, 2)}`;
    } else if (numero.length === 14) {
      // CNPJ: 00.000.000/0000-00
      return `${numero.substr(0, 2)}.${numero.substr(2, 3)}.${numero.substr(5, 3)}/${numero.substr(8, 4)}-${numero.substr(12, 2)}`;
    } else {
      return numero; // Sem máscara para outros tamanhos
    }
  }


  enviarPesquisaParaProcessamento(dados) {
    this.ui.loading();

    if (this.objMenorIdade.length > 0) {
      this.objValorAdicional = this.objMenorIdade;
    }

    let objEnvio = {
      idFonteGrupo: this.formPesquisa.idFonteGrupo,
      numero: this.formPesquisa.numero,
      produtoID: this.idProduto,
      tipo: this.formPesquisa.tipo,
      nome: dados.nome,
      notificacao: true,
      tags: this.addedItems,
      valorPesquisa: dados.valorPesquisa,
      valorExcedido: dados.valorExcedido,
      valorAdicional: this.objValorAdicional
    }

    let requestCount = Object.assign({}, objEnvio);
    this.refazer = false;
    let request = Object.assign({}, requestCount);
    let documentoMascarado = '';
    if (request.numero.length === 11) {
      documentoMascarado = `${request.numero.substr(0, 3)}.${request.numero.substr(3, 3)}.${request.numero.substr(6, 3)}-${request.numero.substr(9, 2)}`;
    } else if (request.numero.length === 14) {
      documentoMascarado = `${request.numero.substr(0, 2)}.${request.numero.substr(2, 3)}.${request.numero.substr(5, 3)}/${request.numero.substr(8, 4)}-${request.numero.substr(12, 2)}`;
    }
    this.pesquisaService.cadastrarClienteV2(request).subscribe(data => {
      if (HttpStatusCode.Ok) {
        this.ui.loaded().subscribe(() => {
          this.openSnackBar('Sucesso', 'Pesquisa Realizada com Sucesso');
          this.adicionarAba(documentoMascarado, data, true, this.idProduto, this.dataNasc, request);
          this.getChips();
          this.addedItems = [];
          this.myControl.reset();
          this.isDisabled = false
          this.dataNasc = null
        })
      }
      this.pesquisarClicked(1);
    }, (err: any) => {
      this.ui.loaded().subscribe(() => {
        this.openSnackBarWarning('Atenção', err.error);
        this.isDisabled = false
      })
    });
    this.isDisabled = false;
    this.objMenorIdade = []
  }

  IniciarPesquisaSemAdicionais() {
    const objetoVazio = {
      filtros: {},
      parametros: {},
      fontesAdicionais: []
    }
    this.objValorAdicional = objetoVazio;
    // this.enviarPesquisaParaProcessamento()

    this.validaCodigoRetorno()
  }

  montaValorMensagemParametros() {
    this.valoresMensagemParametros = this.parametrosSelecionados.map(objeto => ({
      mensagemUsuario: objeto.mensagemUsuario,
      valorInformado: parseFloat(objeto.valorInformado)
    }));
  }

  somarValorFontesAdicionais() {
    let valores = this.adicionaisToRequest.map(x => x.fonteConfiguracao.preco)
    let soma = valores.reduce((acc, curr) => acc + curr, 0);
    this.valoresMensagemAdicionais.push({
      mensagem: 'As fontes selecionadas serão deduzidas do seu pacote mensal',
      valorTotal: soma
    })
  }

  openDialog(pespAnterior): void {

    let dialogRef = this.dialog.open(PopPesqRealizadaComponent, {
      data: pespAnterior
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.data == true) {
        this.refazer = true;
        this.confirmarPesquisa();
      }
    });
  }

  openSnackBar(titulo: string, mensagem: string) {
    this._snackBar.openFromComponent(MensageResponseComponent, {
      data: {
        titulo: titulo,
        mensagem: mensagem,
      },
      duration: 5000,
      horizontalPosition: 'right',
      verticalPosition: 'top'
    });
  }

  openSnackBarWarning(titulo: string, mensagem: string) {
    this._snackBar.openFromComponent(MensageErrorResponseComponent, {
      data: {
        titulo: titulo,
        mensagem: mensagem,
      },
      duration: 5000,
      horizontalPosition: 'right',
      verticalPosition: 'top'
    });
  }

  clearForm() {
    this.formFiltro.nome = '';
    this.formFiltro.numero = '';
    this.formFiltro.status = '';
    this.formFiltro.tag = '';
    this.campaignOne.get('start').setValue(null);
    this.campaignOne.get('end').setValue(null);
    this.campaignTwo.get('start').setValue(null);
    this.campaignTwo.get('end').setValue(null);

    this.formFiltro.tipoAcao = '';
    this.formFiltro.operadorValor = '';
    this.formFiltro.autorReu = '';
    this.formFiltro.statusProcesso = '';
    if(this.isDossie || this.isProcessos){
      this.formFiltro.valor = '';
    }
  }


  onFonteValue(event) {
    var grupoSelecionado = this.listaFonteGrupo.find(f => f.fonteGrupo.id == event.value);
    this.defineParametroseFontesSelecionaveis(grupoSelecionado);
    if (grupoSelecionado.fonteGrupo.listaFonteGrupoItem.some(fonteitem => [Fonte.CertidaoDividaUniaoImovelRural].includes(fonteitem.fonte))) {
      this.formCadastroValorAdicional = true;
      this.formCadastroValorAdicionalLabel = this.tipoLabels.get(Fonte.CertidaoDividaUniaoImovelRural);
    } else {
      this.formCadastroValorAdicional = false;
    }
  }
  ajustaNome(nome: string, tamanho: any) {
    let nomeRetorno = nome;
    if (nome.length >= tamanho) {
      nomeRetorno = nome.substring(0, tamanho - 3) + '...';
    }
    return nomeRetorno;
  }

  disableEnterKey(event: KeyboardEvent) {
    event.preventDefault(); // Previne o comportamento padrão de enviar o formulário ou executar o click
  }

  desabilitaPesquisa() {
    if (this.docsAdded.length >= 1) {
      return true
    }
    // if(this.docsAdded.length > 0){
    //   return true
    // }
    return false
  }

  verificaTipoDocumento() {
    console.log('caiu aqui')
    console.log(this.formPesquisa.numero)
    console.log(this.docsAdded)
    let cnpj = 2
    let cpf = 1
    let novoTipoDocumento = null; // ou qualquer valor inicial que represente um tipo desconhecido
    if (this.formPesquisa.numero.length == 11) {
      this.formPesquisa.tipo = cpf
      novoTipoDocumento = cpf;
    }
    else if (this.formPesquisa.numero.length == 14) {
      this.formPesquisa.tipo = cnpj
      novoTipoDocumento = cnpj;
    }
    if (novoTipoDocumento !== null && novoTipoDocumento !== this.tipoDocumento) {
      // Se o tipo de documento mudou, desmarca todos os checkboxes
    }

    this.tipoDocumento = novoTipoDocumento;
  }

  verificaTipoDocumentoParametros(tipoDocumento: number): boolean {
    let tipoDocumentoArray = null;
    const CNPJ = 2;
    const CPF = 1;
  
    // Verifica primeiro se existe algum CNPJ
    if (this.docsAdded.some(doc => doc.replace(/[\.\-\/]/g, '').length === 14)) {
      tipoDocumentoArray = CNPJ;
    }
    // Se não houver CNPJ, verifica se existe CPF
    else if (this.docsAdded.some(doc => doc.replace(/[\.\-\/]/g, '').length === 11)) {
      tipoDocumentoArray = CPF;
    }
  
    // Retorna true se o tipoDocumento corresponder ao tipo encontrado no array
    return tipoDocumentoArray === tipoDocumento;
  }
  

  adicionarAba(documento, idPesquisa, abaPesquisa, identificador, dataNasc, dadosPesquisa) {
    if (identificador === null) {
      // A aba de histórico de pesquisas não deve ser adicionada
      return;
    }

    const abaExistente = this.abas.find(aba => aba.titulo === documento);

    if (abaExistente) {
      // Se a aba já existe, destaque-a ou faça qualquer outra ação necessária
      this.selectedIndex = this.abas.indexOf(abaExistente);
    } else {
      this.abas.push({ titulo: documento, id: idPesquisa, novaPesquisa: abaPesquisa, identificador: identificador, dataNasc: dataNasc, dadosPesquisa: dadosPesquisa });
      this.usuarioService.setTabState(this.usuario.id, this.idProduto, this.abas);
      this.selectedIndex = this.abas.length - 1;
    }
  }

  fecharAba(index: number) {
    this.abas.splice(index, 1);
    this.usuarioService.setTabState(this.usuario.id, this.idProduto, this.abas);
  }

  atualizarIndiceAbaSelecionada(event: any) {
    this.selectedIndex = this.tabGroup.selectedIndex;
  }


  verificaPesquisaQualificada(retorno) {
    this.mensagemQualificada = retorno?.mensagemAviso;
  }

  exibeModalValor(data) {
    const modalValor = this.dialog.open(ModalValoresPesquisaComponent, {
      maxWidth: "600px",
      minWidth: "500px",
      data: {
        adicionais: this.objValorAdicional,
        dados: data
      }
    })
      .afterClosed().subscribe(result => {
        if (result) {
          this.enviarPesquisaParaProcessamento(this.objetoRetornoPesquisa)
        }
        else {
          this.IniciarPesquisaSemAdicionais
        }
      });
  }

  enviarParametroMenorIdade(mensagem) {
    const modalMenorIdade = this.dialog.open(ModalMenorIdadeComponent, {
      maxWidth: "470px",
      data: {
        objetoAdicional: this.objValorAdicional,
        mensagem: mensagem
      }
    })
    modalMenorIdade.afterClosed().subscribe(result => {
      if (result) {
        this.objMenorIdade = result.paramNasc;
        this.dataNasc = result.dataNasc;
        this.formPesquisa = { ...this.formPesquisa, dataNascimento: result.dataNasc }
        this.validaCodigoRetorno()
      }
      else {
        this.isDisabled = false;
        this.refazer = false
      }
    });
  }

  validaCodigoRetorno() {
    this.ui.loading()
    let obj = {
      valorAdicional: this.objValorAdicional
    };
    let objRequest = { ...this.formPesquisa, ...obj }

    if(this.docsAdded.length > 1){
      this.ui.loaded().subscribe(() => {
      this.abrirModalPesquisaLote(objRequest);
      });
      return
    }

    this.pesquisaService.preCadastro(objRequest).subscribe(response => {
      if (HttpStatusCode.Ok) {
        this.ui.loaded().subscribe(() => {
          this.objetoRetornoPesquisa = response;
          if (this.objetoRetornoPesquisa.codigo === 20 || this.objetoRetornoPesquisa.codigo === 21) {
            this.enviarParametroMenorIdade(response.mensagem);
            return
          }
          if (this.objetoRetornoPesquisa.valorPesquisaAdicional > 0 || this.objetoRetornoPesquisa.valorPesquisaParametro > 0) {
            this.exibeModalValor(this.objetoRetornoPesquisa);
            return
          }

          if (this.objetoRetornoPesquisa.codigo == 10) {
            this.enviarPesquisaParaProcessamento(this.objetoRetornoPesquisa)
          }
        })
      }
    }, (err: any) => {
      this.ui.loaded().subscribe(() => {
        this.openSnackBarWarning('Atenção', err.error);
        this.isDisabled = false
      })
    }
    )
  }

  async confirmarPesquisa() {

    if(!this.desabilitaPesquisa()){
      this.openSnackBarWarning('Documento não informado!','Favor informar um CPF/CNPJ para iniciar a pesquisa.');
      return
    }

    if(this.adicionaisToRequest.some(x => x.fonte === 218 || x.fonte === 224 || x.fonte === 225) && this.cidadesSelecionadas.length == 0){
      // this.ui.info('A pesquisa qualificada precisa que ao menos uma cidade seja selecionada')
      this.openSnackBarWarning('Atenção', 'A pesquisa qualificada precisa que ao menos uma cidade seja selecionada');
      return
    }
    // Aguarda a verificação da pesquisa existente e verifica se o diálogo foi aberto
    if(this.docsAdded.length == 1){
      const dialogOpened = await this.verificaPesquisaExistente();
      if (dialogOpened) {
        return;
      }
    }
  
  
    // Verifica se há parâmetros adicionais ou selecionados
    if (this.adicionaisToRequest.length > 0 || this.parametrosSelecionados.length > 0) {
      this.iniciarPesquisaComParametrosOuAdicionais();
    }
  
    // Caso não haja parâmetros adicionais ou selecionados
    if (this.adicionaisToRequest.length === 0 && this.parametrosSelecionados.length === 0) {
      this.IniciarPesquisaSemAdicionais();
    }
  }


  // =============== REFAZER PESQUISA A PARTIR DE UMA EXISTENTE ======================

  async refazerPesquisaExistente(data) {
    const valorAdicionalRefazer = JSON.parse(data.valorAdicional);

    // Chame a nova função editarTagAsync e aguarde sua conclusão
    try {
        await this.editarTagAsync(data);
        let objRefazer = {
            idFonteGrupo: data.fonteGrupo.id,
            numero: data.numero,
            tipo: data.tipo,
            nome: data.nome,
            tags: this.addedItems,
            notificacao: true,
            valorAdicional: valorAdicionalRefazer
        };
        // Chame validaCodigoRetornoRefazer após a conclusão de editarTagAsync
        this.validaCodigoRetornoRefazer(objRefazer);
    } catch (error) {
        console.error('Erro ao editar a tag:', error);
        // Lide com o erro conforme necessário
    }
}



  validaCodigoRetornoRefazer(objetoPesquisaRefazer) {
    this.ui.loading()
    this.pesquisaService.preCadastro(objetoPesquisaRefazer).subscribe(response => {
      if (HttpStatusCode.Ok) {
        this.ui.loaded().subscribe(() => {
          this.objetoRetornoPesquisa = response;
          if (this.objetoRetornoPesquisa.codigo === 20 || this.objetoRetornoPesquisa.codigo === 21) {
            this.enviarParametroMenorIdade(response.mensagem);
            return
          }
          if (this.objetoRetornoPesquisa.valorPesquisaAdicional > 0 || this.objetoRetornoPesquisa.valorPesquisaParametro > 0) {
            this.exibeModalValorRefazer(this.objetoRetornoPesquisa, objetoPesquisaRefazer);
            return
          }

          if (this.objetoRetornoPesquisa.codigo == 10) {
            this.enviarPesquisaParaProcessamentoRefazer(this.objetoRetornoPesquisa, objetoPesquisaRefazer)
          }
        })
      }
    }, (err: any) => {
      this.ui.loaded().subscribe(() => {
        this.openSnackBarWarning('Atenção', err.error);
        this.isDisabled = false
      })
    }
    )
  }

  editarTagAsync(value): Promise<any> {
    return new Promise((resolve, reject) => {
        let tags = value.tags;
        let dialogRef = this.dialog.open(ModalEdicaoTagComponent, {
            width: '450px',
            panelClass: 'bg-color',
            maxHeight: '600px',
            data: {
                tags: tags,
                clienteId: this.usuario.cliente.id,
                label: this.labelTag,
                idPesquisa: null,
            }
        });

        dialogRef.afterClosed().subscribe(result => {
          if (result !== undefined && result !== null) {
              this.addedItems = result.tags ? result.tags : [];
              resolve(result); // Resolva a Promise aqui
          } else {
              reject(result);
          }
      });
    });
}



  enviarPesquisaParaProcessamentoRefazer(dados, objPesquisado) {
    this.ui.loading();

    if (this.objMenorIdade.length > 0) {
      this.objValorAdicional = this.objMenorIdade;
    }

    let objEnvio = {
      idFonteGrupo: objPesquisado.idFonteGrupo,
      numero: objPesquisado.numero.replaceAll('.', '').replaceAll('-', '').replaceAll('/', ''),
      produtoID: this.idProduto,
      tipo: objPesquisado.tipo,
      nome: dados.nome,
      notificacao: true,
      tags: this.addedItems,
      valorPesquisa: dados.valorPesquisa,
      valorExcedido: dados.valorExcedido,
      valorAdicional: objPesquisado.valorAdicional
    }


    this.refazer = false;
    let request = Object.assign({}, objEnvio);
    let documentoMascarado = '';
    if (request.numero.length === 11) {
      documentoMascarado = `${request.numero.substr(0, 3)}.${request.numero.substr(3, 3)}.${request.numero.substr(6, 3)}-${request.numero.substr(9, 2)}`;
    } else if (request.numero.length === 14) {
      documentoMascarado = `${request.numero.substr(0, 2)}.${request.numero.substr(2, 3)}.${request.numero.substr(5, 3)}/${request.numero.substr(8, 4)}-${request.numero.substr(12, 2)}`;
    }
    this.pesquisaService.cadastrarClienteV2(request).subscribe(data => {
      if (HttpStatusCode.Ok) {
        this.ui.loaded().subscribe(() => {
          this.openSnackBar('Sucesso', 'Pesquisa Realizada com Sucesso');
          this.adicionarAba(documentoMascarado, data, true, this.idProduto, this.dataNasc, request);
          this.getChips();
          this.addedItems = [];
          this.myControl.reset();
          this.isDisabled = false
          this.dataNasc = null
        })
      }
      this.pesquisarClicked(1);
    }, (err: any) => {
      this.ui.loaded().subscribe(() => {
        this.openSnackBarWarning('Atenção', err.error);
        this.isDisabled = false
      })
    });
    this.isDisabled = false;
    this.objMenorIdade = []
  }

  exibeModalValorRefazer(data, objEnvio) {
    const modalValor = this.dialog.open(ModalValoresPesquisaComponent, {
      maxWidth: "600px",
      minWidth: "500px",
      data: {
        adicionais: this.objValorAdicional,
        dados: data
      }
    })
      .afterClosed().subscribe(result => {
        if (result) {
          this.enviarPesquisaParaProcessamentoRefazer(data, objEnvio)
        }
        else {
          this.IniciarPesquisaSemAdicionais
        }
      });
  }

  onSelectOpenedChanged(isOpened: boolean) {
    if (isOpened) {
      setTimeout(() => {
        this.selectPanel.panel.nativeElement.addEventListener('click', this.stopClickPropagation, true);
      });
    } else {
      this.selectPanel.panel.nativeElement.removeEventListener('click', this.stopClickPropagation, true);
    }
  }

  stopClickPropagation(event: MouseEvent) {
    event.stopPropagation();
  }

  applyFilter(cidade: any[]) {
    if (this.filterCidade) {
      this.filteredCidades = cidade.filter(parametro => 
        parametro.descricao.toLowerCase().includes(this.filterCidade.toLowerCase()),
        // console.log(this.filteredCidades)
      );
    } else {
      this.filteredCidades = [...cidade];
    }
  }

  onEnterDoc(): void {
    event.preventDefault(); 
    const input = event.target as HTMLInputElement;
    const value = input.value.trim();
    if (value.length == 0) return;
    if (value.length == 14 || value.length == 18) {
      if (this.docsAdded.includes(value)) {
         this.openSnackBarWarning('Atenção','Documento já adicionado.');
      } else {
        this.docsAdded.push(value);
        input.value = '';
        this.docControl.setValue('');
      }
    } else {
      this.openSnackBarWarning('Atenção','Documento inválido. Por favor, insira um CPF ou CNPJ válido.');
    }
  }

  clearChips() {
    this.docsAdded = [];
  }

  removeChipDoc(doc: string): void {
    const index = this.docsAdded.indexOf(doc);
    if (index >= 0) {
      this.docsAdded.splice(index, 1);
    }
  }

  abrirModalPesquisaLote(objPesquisa){
    const modalLote = this.dialog.open(ModalPesquisaLoteComponent, {
      minWidth: "600px",
      data: {
        docs: this.docsAdded,
        objPesquisa: objPesquisa,
        addedItems: this.addedItems
      }
    })
  }

  onPasteDoc(event: ClipboardEvent): void {
    const clipboardData = event.clipboardData;
    const pastedText = clipboardData?.getData('text');
  
    if (pastedText) {
      const documentos = pastedText.split(/[\s,]+/); // Separa por espaços ou vírgulas
      documentos.forEach(doc => {
        const documento = doc.trim();
        if (documento) {
          const documentoFormatado = this.aplicarMascaraDocumentoColado(documento);
          this.addDocument(documentoFormatado);
        }
      });
    }
  
    event.preventDefault(); // Previne a colagem padrão
    this.docControl.reset(); // Reseta o campo de input após a colagem
  }
  
  // Função para aplicar a máscara de CPF ou CNPJ
  aplicarMascaraDocumentoColado(doc: string): string {
    const apenasNumeros = doc.replace(/\D/g, '');
  
    if (apenasNumeros.length === 11) {
      // Aplicar máscara CPF
      return apenasNumeros.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
    } else if (apenasNumeros.length === 14) {
      // Aplicar máscara CNPJ
      return apenasNumeros.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
    }
  
    // Retorna o valor original se não for CPF ou CNPJ
    return doc;
  }
  

  addDocument(document: string): void {
    if (document && !this.docsAdded.includes(document)) {
      this.docsAdded.push(document);
    }
  }
}

