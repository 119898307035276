  <a href="">
    <img class="img-tab-1 img-tab-res" src="../../../assets/icones/TELA.svg" onmouseover="this.src='../../../assets/icones/VIEW_A.jpeg'" onmouseout="this.src='../../../assets/icones/TELA.svg'" alt="view">
  </a>
  <a href="">
    <img class="img-tab-2 img-tab-res" src="../../../assets/icones/PDF.svg" onmouseover="this.src='../../../assets/icones/PDF_A.jpeg'" onmouseout="this.src='../../../assets/icones/PDF.svg'" alt="PDF">
  </a>
  <a href="">
    <img class="img-tab-3 img-tab-res" src="../../../assets/icones/EXCELL.svg" onmouseover="this.src='../../../assets/icones/EXCEL_A.jpeg'" onmouseout="this.src='../../../assets/icones/EXCELL.svg'" alt="Excel">
  </a>
  <a href="">
    <img class="img-tab-4 img-tab-res" src="../../../assets/icones/RECIBO.svg" onmouseover="this.src='../../../assets/icones/RECIBO_A.jpeg'" onmouseout="this.src='../../../assets/icones/RECIBO.svg'" alt="Recibo">
  </a>
