export enum MenuTeiaItens{
    expandirNos =  1,
    exibirNoOculto = 2,
    ocultarNo = 3,
    exibirFiliais = 4,
    ocultarFiliais = 5,
    informacoesAdicionais = 6,
    exibirPessoasRelacionadas = 7,
    ocultarPessoasRelacionadas = 8,
    adicionarNoCustomizado = 9
}