<div class="container">
    <form>
        <div class="col-md d-flex justify-content-center">
            <mat-form-field appearance="outline">
              <mat-label>Selecione o Produto</mat-label>
              <mat-select [(ngModel)]="selectedFonte" name="idFonteGrupo" (ngModelChange)="grupoFonteSelection()" >
                <mat-option *ngFor="let u of grupoFontes"  [value]="u.fonteGrupo.id">{{ u.fonteGrupo.nome }}</mat-option>
              </mat-select>
            </mat-form-field>
        </div>
        <footer>
            <div class="footer-buttons">
                <button class="btn btn-primary btn-cancel" mat-dialog-close>Cancelar</button>
                <button class="btn btn-primary btn-go" (click)='realizarPesquisa()'>Realizar Pesquisa</button>
            </div>
        </footer>
    </form>
</div>