import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { PrecificadorService } from 'src/app/shared/services/precificador/precificador.service';
import { AnexarPassivosComponent } from './anexar-passivos/anexar-passivos.component';
import { MatDialog } from '@angular/material/dialog';
import { ModalConfirmacaoComponent } from 'src/app/components/view/modal-confirmacao/modal-confirmacao.component';
import { CityUFService } from 'src/app/shared/services/precificador/UfByProcesso.service';
import { MensageErrorResponseComponent } from 'src/app/components/view/mensage-error-response/mensage-error-response.component';
import { MatSnackBar } from '@angular/material/snack-bar';

const today = new Date();
const month = today.getMonth();
const year = today.getFullYear();

@Component({
  selector: 'app-step-three',
  templateUrl: './step-three.component.html',
  styleUrls: ['./step-three.component.scss']
})
export class StepThreeComponent implements OnInit {

  @Input() precificadorId: number;
  valorAlterado = false;

  forms: FormGroup[] = [];
  dadosDaAPI: any[]
  pipe = new DatePipe('pt-BR');
  formPassivos = [];
  //precificadorId = 0;

  campaignOne = new FormGroup({
    start: new FormControl(new Date(13, month, year)),
    end: new FormControl(new Date(16, month, year)),
  });
  campaignTwo = new FormGroup({
    start: new FormControl(new Date(15, month, year)),
    end: new FormControl(new Date(19, month, year)),
  });

  constructor(
    private precificadorService: PrecificadorService,
    private _snackBar: MatSnackBar,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private cityUFService: CityUFService
  ) { }


  ngOnInit() {
    this.precificadorId = this.precificadorService.precificadorIdPaivalue;
    this.getPassivos()
  }


  addForm() {
    const form = this.fb.group({
      tipoId: [],
      orgaoResponsavel: [],
      descricao: [],
      cidade: [],
      uf: [],
      id: [0],
      numProcesso: [],
      faseProcesso: [],
      partesEnvolvidas: [],
      valorDebito: [],
      valorAtualizado: [],
      comarca: [],
      tipoProcesso: [],
      dataDebito: [],
    });
    form.get('numProcesso').valueChanges.subscribe(value => {
      if (value && value.length >= 21) {
        const uf = value.slice(18, 20);
        form.get('uf').setValue(this.cityUFService.getUFCode(uf));
      } else {
        form.get('uf').setValue('');
      }
    });
    form.get('valorDebito').valueChanges.subscribe((value) => {
      this.valorAlterado = true; // Seta a flag para indicar alteração
    });
  
    form.get('valorDebito').valueChanges.subscribe((value) => {
      this.onBlurFunction(); // Chama onBlurFunction ao perder o foco
    });
    this.forms.push(form)
  }

  cadastrarPassivos() {
    const formItens = this.forms.map(control => control.value);

    const requisicaoPassivos = formItens.map(item => {
      return {
        Id: item.id,
        TipoId: item.tipoId,
        DataAvaliacao: item.dataDebito,
        ValorAvaliacao: item.valorDebito,
        Conteudo: this.retornaConteudo(item)
      };
    });
    const obj = {
      PrecificadorId: this.precificadorId,
      ObservacaoPassivo: '',
      Requisicaopassivos: requisicaoPassivos
    };

    this.precificadorService.cadastrarPassivos(obj).subscribe(data => {
    })
  }

  retornaConteudo(item) {
    const dividaFiscal = {
      orgaoResponsavel: item.orgaoResponsavel,
      descricao: item.descricao,
      cidade: item.cidade,
      uf: item.uf
    };
    const dividaTrabalhista = {
      numProcesso: item.numProcesso,
      faseProcesso: item.faseProcesso,
      partesEnvolvidas: item.partesEnvolvidas,
      comarca: item.comarca,
      uf: item.uf
    };
    const acordoJudicial = {
      numProcesso: item.numProcesso,
      tipoProcesso: item.tipoProcesso,
      partesEnvolvidas: item.partesEnvolvidas,
      comarca: item.comarca,
      uf: item.uf
    };
    const outros = {
      descricao: item.descricao,
      comarca: item.comarca,
      uf: item.uf
    }


    if (item.tipoId == 1) {
      return JSON.stringify(dividaFiscal)
    }
    if (item.tipoId == 2) {
      return JSON.stringify(dividaTrabalhista)
    }
    if (item.tipoId == 3) {
      return JSON.stringify(acordoJudicial)
    }
    if (item.tipoId == 4) {
      return JSON.stringify(outros)
    }
  }

  getPassivos() {
    this.precificadorService.passivos(this.precificadorId).subscribe(data => {
      this.dadosDaAPI = data
      if (this.dadosDaAPI && this.dadosDaAPI.length > 0) {

        this.dadosDaAPI.forEach(dado => {
          dado.conteudo = JSON.parse(dado.conteudo);
          const dataAvaliacao = dado.dataAvaliacao
          const dataAvaliacaoFormatada = this.pipe.transform(dataAvaliacao, 'yyyy-MM-dd');
          const form = this.fb.group({
            tipoId: [dado.tipoPassivo.id.toString()],
            orgaoResponsavel: [dado.conteudo.orgaoResponsavel],
            descricao: [dado.conteudo.descricao],
            cidade: [dado.conteudo.cidade],
            uf: [dado.conteudo.uf],
            id: [dado.id],
            numProcesso: [dado.conteudo.numProcesso],
            faseProcesso: [dado.conteudo.faseProcesso],
            partesEnvolvidas: [dado.conteudo.partesEnvolvidas],
            valorDebito: [dado.valorPassivo],
            valorAtualizado: [dado.valorPassivoAtualizadoIndice],
            comarca: [dado.conteudo.comarca],
            tipoProcesso: [dado.conteudo.tipoProcesso],
            dataDebito: [dataAvaliacaoFormatada],
          });

          this.forms.push(form);
        });
      }
    })
  }

  getPassivosAtualizados() {
    this.forms = []
    this.precificadorService.passivos(this.precificadorId).subscribe(data => {
      this.dadosDaAPI = data
      if (this.dadosDaAPI && this.dadosDaAPI.length > 0) {

        this.dadosDaAPI.forEach(dado => {
          dado.conteudo = JSON.parse(dado.conteudo);
          const dataAvaliacao = dado.dataAvaliacao
          const dataAvaliacaoFormatada = this.pipe.transform(dataAvaliacao, 'yyyy-MM-dd');
          const form = this.fb.group({
            tipoId: [dado.tipoPassivo.id.toString()],
            orgaoResponsavel: [dado.conteudo.orgaoResponsavel],
            descricao: [dado.conteudo.descricao],
            cidade: [dado.conteudo.cidade],
            uf: [dado.conteudo.uf],
            id: [dado.id],
            numProcesso: [dado.conteudo.numProcesso],
            faseProcesso: [dado.conteudo.faseProcesso],
            partesEnvolvidas: [dado.conteudo.partesEnvolvidas],
            valorDebito: [dado.valorPassivo],
            valorAtualizado: [dado.valorPassivoAtualizadoIndice],
            comarca: [dado.conteudo.comarca],
            tipoProcesso: [dado.conteudo.tipoProcesso],
            dataDebito: [dataAvaliacaoFormatada],
          });
          
          this.forms.push(form);
        });
      }
    })
  }

  removeForm(index: number) {
    this.forms.splice(index, 1);
  }

  openModalAnexo() {
    const menu = this.dialog.open(AnexarPassivosComponent, {
      width: '750px',
      panelClass: 'bg-color',
      height: 'auto',
      // data: this.fonteGrupo
    });
    menu.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        const passivosAnexados = dialogResult.Passivos
        passivosAnexados.forEach(dado => {
          dado.Conteudo = JSON.parse(dado.Conteudo);
          const dataAvaliacao = dado.DataAvaliacao
          const dataAvaliacaoFormatada = this.pipe.transform(dataAvaliacao, 'yyyy-MM-dd');
          const form = this.fb.group({
            tipoId: [dado.TipoPassivo.Id.toString()],
            orgaoResponsavel: [dado.Conteudo.OrgaoResponsavel],
            descricao: [dado.Conteudo.Descricao],
            cidade: [dado.Conteudo.Cidade],
            uf: [dado.Conteudo.Uf],
            id: [dado.Id],
            numProcesso: [dado.Conteudo.NumProcesso],
            faseProcesso: [dado.Conteudo.FaseProcesso],
            partesEnvolvidas: [dado.Conteudo.PartesEnvolvidas],
            valorDebito: [dado.ValorPassivo],
            valorAtualizado: [dado.valorPassivoAtualizadoIndice],
            comarca: [dado.Conteudo.Comarca],
            tipoProcesso: [dado.Conteudo.TipoProcesso],
            dataDebito: [dataAvaliacaoFormatada],
          });

          this.forms.push(form);
        });
      }
    });
  }

  deletarTodosPassivos(){
    const dados = {
      title: '',
      message: 'Deseja realmente deletar todos os passivos da lista?',
      cancelText: 'Cancelar',
      confirmText: 'Deletar'
    }
    const dialogRef = this.dialog.open(ModalConfirmacaoComponent, {
      maxWidth: "470px",
      data: dados
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if(dialogResult == true){
        this.forms = []
      }
    });
  }

  cadastrarPassivosAtualizados() {

    const formItens = this.forms.map(control => control.value);

     // Verifica se pelo menos um item tem dataDebito preenchido
      const temDataDebito = formItens.some(item => item.dataDebito);

      let temErro = false; // Flag para evitar requisição caso tenha erro

      formItens.forEach((item, index) => {
        if (!item.dataDebito) {
          this.openSnackBarWarning('Aviso', `Preencha o campo Data do Débito no formulário ${index + 1} antes de continuar.`);
          temErro = true;
        }
      });
    
      if (temErro) {
        return; // Interrompe a execução se houver erro
      }

    const requisicaoPassivos = formItens.map(item => {
      return {
        Id: item.id,
        TipoId: item.tipoId,
        DataAvaliacao: item.dataDebito,
        ValorAvaliacao: item.valorDebito,
        Conteudo: this.retornaConteudo(item)
      };
    });
    const obj = {
      PrecificadorId: this.precificadorId,
      ObservacaoPassivo: '',
      Requisicaopassivos: requisicaoPassivos
    };

    this.precificadorService.cadastrarPassivos(obj).subscribe(data => {
      this.getPassivosAtualizados()
    })
  }

  onBlurFunction() {
    this.cadastrarPassivosAtualizados()
  }

  openSnackBarWarning(titulo: string, mensagem: string) {
    this._snackBar.openFromComponent(MensageErrorResponseComponent, {
      data: {
        titulo: titulo,
        mensagem: mensagem,
      },
      duration: 5000,
      horizontalPosition: 'right',
      verticalPosition: 'top'
    });
  }
}


