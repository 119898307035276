import { UsuarioService } from '../../../shared/services/http/usuario.service';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UiService } from '../../../shared/services/ui.service';
import { Location } from '@angular/common';

@Component({
    selector: 'ngx-reset-password-page',
    styleUrls: ['./alteracao-senha.page.scss'],
    templateUrl: './alteracao-senha.page.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlteracaoSenhaPage implements OnInit {

    errors: string[] = [];
    messages: string[] = [];
    user: any = {};
    resetPasswordForm!: FormGroup;

    constructor(
        protected cd: ChangeDetectorRef,
        protected fb: FormBuilder,
        protected usuarioService: UsuarioService,
        protected loading: UiService,
        protected router: Router,
        private route: ActivatedRoute,
        public location: Location
    ) { }

    ngOnInit(): void {
        this.resetPasswordForm = this.fb.group({
            password: ['', [
                Validators.required,
                Validators.minLength(8),
                this.validatePassword // Adicionando validação personalizada
            ]],
            confirmPassword: ['', [Validators.required, Validators.minLength(8)]]
        });
    }

    validatePassword(control: any) {
        const password = control.value;
        const hasUpperCase = /[A-Z]/.test(password);
        const hasLowerCase = /[a-z]/.test(password);
        const hasNumber = /[0-9]/.test(password);
        const hasSpecial = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(password);

        const isValid = hasUpperCase && hasLowerCase && hasNumber && hasSpecial;
        return isValid ? null : { senhaInvalida: true };
    }

    get password() { return this.resetPasswordForm.get('password'); }
    get confirmPassword() { return this.resetPasswordForm.get('confirmPassword'); }

    resetPass(): void {
        this.errors = this.messages = [];
        this.user = this.resetPasswordForm.value;

        if (this.resetPasswordForm.valid) {
            this.route.queryParams.subscribe(params => {
                if (params['token']) {
                    let request = this.resetPasswordForm.value;
                    request.token = params['token'];

                    this.loading.loading();
                    this.usuarioService.finalizarRecuperacaoSenha(request).subscribe(
                        (data: any) => {
                            this.loading.loaded().subscribe(() => {
                                this.router.navigate(['/auth/login']);
                            });
                        },
                        (error: any) => {
                            // Tratar erro
                        }
                    );
                } else {
                    this.loading.loading();
                    this.usuarioService.alterarSenha(this.user).subscribe(
                        (data: any) => {
                            this.loading.loaded().subscribe(() => {
                                this.router.navigate(['/auth/login']);
                            });
                        },
                        (error: any) => {
                            this.loading.loaded();
                            this.loading.info('Ocorreu um erro, tente novamente')
                        }
                    );
                }
            });
        } else {
            // Formulário inválido, lidar com isso se necessário
        }
    }

    backRoute() {
        this.location.back()
    }
}
