<div class="background-not-found">
    <div class="center d-flex justify-content-center flex-column text-center align-middle">
        <div class="middle">
            <div>
                <img  class="img-not-found" src="https://www.radioterracampestre.com.br/uploads/default/notfound.png" alt="" width="400vw">
            </div>
            <div>
                <h1 class="title-not-found">Página não encontrada</h1>
                <button class="btn btn-azul" (click)="backtoHome()"> Voltar ao Portal</button>
            </div>
        </div>    
    </div>    
</div>
