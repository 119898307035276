import { HttpStatusCode } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Data } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MensageResponseComponent } from 'src/app/components/view/mensage-response/mensage-response.component';
import { ProdutosEnum } from 'src/app/shared/enums/produtos.enum';
import { PesquisaService } from 'src/app/shared/services/http/pesquisa.service';
import { UtilService } from 'src/app/shared/services/http/util.service';
import { UiService } from 'src/app/shared/services/ui.service';
import { Textos } from 'src/app/shared/textos';

@Component({
  selector: 'app-modal-grupo-pesquisa',
  templateUrl: './modal-grupo-pesquisa.component.html',
  styleUrls: ['./modal-grupo-pesquisa.component.scss']
})
export class ModalGrupoPesquisaComponent implements OnInit {
  grupoFontes;
  idFonteGrupo;
  selectedFonte;
  nome;

  constructor(
    private ui: UiService,
    private toastr: ToastrService,
    private utilService: UtilService,
    private pesquisaService: PesquisaService,
    private _snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<ModalGrupoPesquisaComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Data,
  ) { }

  ngOnInit(): void {
    this.grupoFontes = this.data.grupos
   this.selectedFonte = this.data.grupos[0].fonteGrupo.id
  }

  grupoFonteSelection(){
    this.idFonteGrupo = this.selectedFonte
    console.log(this.selectedFonte)
  }

  realizarPesquisa(){
    this.ui.loading()
       // verifica o tipo do documento 
    let tipoDocumento = this.data.doc.replaceAll('.','').replaceAll('-','').replaceAll('/','');
    if(tipoDocumento.length == 11){
      tipoDocumento = 1
    }
    if(tipoDocumento.length == 14){
      tipoDocumento = 2
    }
    
    const request = {
      nome: '',
      notificacao: true,
      numero: this.data.doc.replaceAll('.','').replaceAll('-','').replaceAll('/',''),
      tipo: tipoDocumento
    }

    this.utilService.resolverNome(request, this.data.header).subscribe(resultadoResolverNome => {
      if(!resultadoResolverNome || !resultadoResolverNome.nome) {
          this.ui.loaded().subscribe(() => {
              this.nome = "";
              this.toastr.warning(Textos.NOME_NAO_ENCONTRADO, "Portal Credvalue");
              this.dialogRef.close()
          });
          return;
      }
       const requestPesquisa = {
        idFonteGrupo: this.selectedFonte,
        nome: resultadoResolverNome.nome,
        notificacao: true,
        numero: this.data.doc.replaceAll('.','').replaceAll('-','').replaceAll('/',''),
        tipo: tipoDocumento
      }
       this.pesquisaService.cadastrarCliente(requestPesquisa).subscribe(data => {
        if (HttpStatusCode.Ok) {
          this.ui.loaded().subscribe(() => {
            if(this.data.idProduto == ProdutosEnum.Cadastrais){
              this.openSnackBar('Sucesso', 'Pesquisa Cadastral Lançada com Sucesso');
            }
            if(this.data.idProduto == ProdutosEnum.Processo){
              this.openSnackBar('Sucesso', 'Pesquisa de Processos Lançada com Sucesso');
            }
            if(this.data.idProduto == ProdutosEnum.Patrimonial){
              this.openSnackBar('Sucesso', 'Pesquisa Patrimonial Lançada com Sucesso');
            }
            if(this.data.idProduto == ProdutosEnum.Credito){
              this.openSnackBar('Sucesso', 'Pesquisa de Crédito Lançada com Sucesso');
            }
            if(this.data.idProduto == ProdutosEnum.Dossie){
              this.openSnackBar('Sucesso', 'Pesquisa de Dossiê Completo Lançada com Sucesso');
            }
            this.dialogRef.close()
          })
        }
      });
    }, (err:any) => {
      this.ui.loaded().subscribe(() => {
        this.toastr.info('Ocorreu um erro ao realizar a pesquisa, tente novamente.')
        this.dialogRef.close()
      })
    });
  }

  openSnackBar(titulo: string, mensagem: string) {
    this._snackBar.openFromComponent(MensageResponseComponent, {
       data: {
         titulo: titulo,
         mensagem: mensagem,
       },
       duration: 5000,
       horizontalPosition: 'right',
       verticalPosition: 'top'
    });
   }

}
