<div class="main content-margin">
  <mat-card class="container mat-elevation-z8" style="height: 97vh important;">
    <mat-card-header>
        <mat-card-title>
          <p class="title-card">DashBoard</p>
        </mat-card-title>
    </mat-card-header>
    <div class="content-box d-flex  justify-content-center flex-column">
      <table class="table-dashboard mt-4 table-dashboard-desk">
        <thead class="thead-dashboard">
          <tr class="tr-dashboard">
            <th class="th-dashboard" scope="col">Empresa</th>
            <th class="th-dashboard" scope="col">Pacote</th>
            <th class="th-dashboard" scope="col">Usuários</th>
            <th class="th-dashboard" scope="col">Pesquisas em Andamento</th>
            <th class="th-dashboard" scope="col">Pesquisas Realizadas</th>
            <th class="th-dashboard" scope="col">Último Acesso</th>
          </tr>
        </thead>
        <tbody class="tbody-dashboard">
          <tr class="tr-dashboard">
            <td class="td-dashboard" data-label="Empresa">{{ nomeEmpresa }}</td>
            <td class="td-dashboard" data-label="Pacote">{{nomeDoPacote}}</td>
            <td class="td-dashboard" data-label="Usuários">{{qtdeUsuarios}}</td>
            <td class="td-dashboard" data-label="Pesquisas em Andamento">{{pesquisaEmAndamento}}</td>
            <td class="td-dashboard" data-label="Pesquisas Realizadas">{{pesquisasRealizadas}}</td>
            <td class="td-dashboard" data-label="Último Acesso">{{ultimoAcesso}}</td>
          </tr>
        </tbody>
      </table>

      <table class="table-dashboard-mobile mt-4">
        <thead class="thead-dashboard-mobile">
          <tr class="tr-dashboard-mobile">
            <th class="th-dashboard-mobile" scope="col">Empresa</th>
            <th class="th-dashboard-mobile" scope="col">Pacote</th>
            <th class="th-dashboard-mobile" scope="col">Usuários</th>
          </tr>
        </thead>
        <tbody class="tbody-dashboard-mobile">
          <tr class="tr-dashboard-mobile">
            <td class="td-dashboard-mobile" data-label="Empresa">{{ nomeEmpresa }}</td>
            <td class="td-dashboard-mobile" data-label="Pacote">{{nomeDoPacote}}</td>
            <td class="td-dashboard-mobile" data-label="Usuários">{{qtdeUsuarios}}</td>
          </tr>
        </tbody>
      </table>
      <div class="line-separator"></div>
      <table class="table-dashboard-mobile mt-2">
        <thead class="thead-dashboard-mobile">
          <tr class="tr-dashboard-mobile">
            <th class="th-dashboard-mobile" scope="col">Pesq. em Andamento</th>
            <th class="th-dashboard-mobile" scope="col">Pesq. Realizadas</th>
            <th class="th-dashboard-mobile" scope="col">Último Acesso</th>
          </tr>
        </thead>
        <tbody class="tbody-dashboard-mobile">
          <tr class="tr-dashboard-mobile">
            <td class="td-dashboard-mobile" data-label="Pesquisas em Andamento">{{pesquisaEmAndamento}}</td>
            <td class="td-dashboard-mobile" data-label="Pesquisas Realizadas">{{pesquisasRealizadas}}</td>
            <td class="td-dashboard-mobile" data-label="Último Acesso">{{ultimoAcesso}}</td>
          </tr>
        </tbody>
      </table>

      <div class="line-separator"></div>
      <div class="row text-center mt-5">
        <div class="col-md">
          <button class="btn btn-azul" (click)="exibirRelatorio()" title="Relatórios" [disabled]="!habilitaRelatorios()">Relatórios</button>
        </div>
        <!-- <div class="col-md">
          <button class="btn btn-azul" (click)="openDialog()" title="Comprar créditos">Comprar Créditos</button>
        </div> -->
        <div class="col-md">
          <button class="btn btn-azul" (click)="clickTabela()" title="Tabela" [disabled]="!habilitaTabela()">Tabela</button>
        </div>
        <div class="col-md">
          <button class="btn btn-azul" (click)="clickContrato()" title="Ver Contrato" [disabled]="!habilitaContrato()">Ver Contrato</button>
        </div>
        <div class="col-md">
          <button class="btn btn-azul" (click)="clickContato()" title="Contato Suporte" [disabled]="!habilitaContatoSuporte()">Contato Suporte</button>
        </div>
      </div>
    </div>
    <div class="header-abas" *ngIf="mensagemErro">
      <span>{{mensagemErro}}</span>
    </div>

    <section class="grafico mt-2">
      <div class="row charts d-flex justify-content-center">

        <div [ngClass]=" exibeRelatorio ? '' : 'd-none'">
          <div class="col d-flex mt-3">
            <div *ngIf="idCliente" class="componentClickedFirst" (click)="changeDisplayConsultas(); corClicked()" id="1">
              Consultas Realizadas
            </div>
            <div *ngIf="idCliente" class="componentClicked" (click)="changeDisplayVisao(); corClicked2()" id="2">
              Visão Processual
            </div>
            <div [ngClass]="displayUso ? 'componentClickedFirst' : 'componentClicked'" (click)="changeDisplayUso(); corClicked3()" id="4">
              Relatorio de Uso
            </div>
            <div class="componentClicked" (click)="closeComponent(); corClicked2()" id="3">
              Voltar
            </div>
          </div>
          <app-visao-processual *ngIf="idCliente" [ngClass]=" displayVisao ? 'd-flex' : 'd-none'"></app-visao-processual>
          <app-consultas-realizadas *ngIf="idCliente" [ngClass]=" displayConsultas ? 'd-flex' : 'd-none'"></app-consultas-realizadas>
          <app-relatorio-uso [numeroIdCliente]="numeroIdCliente" [ngClass]=" displayUso ? 'd-flex' : 'd-none'"></app-relatorio-uso>
        </div>

        <div [ngClass]=" !exibeRelatorio ? 'd-flex' : 'd-none'" class="justify-content-between graphs">
          <div class="col-sm-4 chart-col">
            <div id="chart2" [ngClass]="renderCharts">
            </div>
          </div>
          <div class="col-sm-8 chart-col spacing">
            <div id="chart" [ngClass]="renderCharts">
              <div id="responsive-chart" class="d-flex justify-content-center"></div>
            </div>
          </div>
        </div>

        <!-- <div class="row d-flex justify-content-between graphs content-graphs">
          <div class="col-sm-4 chart-col d-flex flex-column align-items-center" id="chart2"  [ngClass]="renderCharts">
            <div class="mt-3">
              <p>Processos</p>
            </div>
            <div id="pizzaChart" class="d-flex justify-content-center"></div>
          </div>
        
          <div class="col-sm-8 chart-col">
            <div id="chart" [ngClass]="renderCharts">
              <div id="lineChart" class="d-flex justify-content-center"></div>
            </div>
          </div>
        </div>

        <div class="row d-flex justify-content-between graphs content-graphs" >
          <div class="col-sm-4 chart-col d-flex flex-column align-items-center" id="chart2"  [ngClass]="renderCharts">
            <div class="mt-3">
              <p>Análise de Crédito</p>
            </div>
            <div id="barChart" class="d-flex justify-content-center"></div>
          </div>
          <div class="col-sm-8 chart-col">
            <div id="chart" [ngClass]="renderCharts">
              <div class="mt-3">
                <p>Patrimônios</p>
              </div>
             <app-mapa-brasil></app-mapa-brasil>
            </div>
          </div>
        </div> -->

      </div>
    </section>
    <mat-card-footer>
      <div class="text-footer mt-1 mb-3">
        Tecnologia para agilizar a Jornada<br>
        Jurídica e de Compliances
      </div>
    </mat-card-footer>
  </mat-card>
</div> 
