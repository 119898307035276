<mat-toolbar class="mat-elevation-z8 justify-content-between">
  <button
    mat-icon-button
    
  >
    <!-- <mat-icon *ngIf="!sidenav.opened"> menu </mat-icon>
    <mat-icon *ngIf="sidenav.opened"> close </mat-icon> -->
  </button>
  <div class="d-flex justify-content-between">
    <div class="logo-box">
        <img src="../assets/img/logo-credvalue-v2.svg" alt="Cred value" class="logo-header img-fluid">
    </div>
  </div>  
  <div class="logout float-right">
    <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
      <mat-icon>exit_to_app</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <button mat-menu-item routerLink="/alteracao-senha">
        <mat-icon>lock_open</mat-icon>
        <span>Alterar Senha</span>
      </button>
      <button mat-menu-item routerLink="/auth/logout">
        <mat-icon>exit_to_app</mat-icon>
        <span>Sair</span>
      </button>
    </mat-menu>
  </div>
</mat-toolbar>
