<div class="container">
    <loading *ngIf="isLoading"></loading>
    <div class="row text-left mb">
        <div class="col-md-5 d-flex">
            <div class="d-flex flex-column">
                <mat-form-field class="example-form-field" appearance="outline">
                    <mat-label>Periodo</mat-label>
                    <mat-date-range-input
                      [formGroup]="campaignOne"
                      [rangePicker]="campaignOnePicker"
                      [comparisonStart]="campaignTwo.value.start"
                      [comparisonEnd]="campaignTwo.value.end">
                      <input matStartDate placeholder="DD/MM/AAAA" formControlName="start" [(ngModel)]="formFiltro.cadastroDe" name="CadastroAte">
                      <input matEndDate placeholder="DD/MM/AAAA" formControlName="end" [(ngModel)]="formFiltro.cadastroAte" name="CadastroAte">
                    </mat-date-range-input>
                    <mat-datepicker-toggle matSuffix [for]="campaignOnePicker"></mat-datepicker-toggle>
                    <mat-date-range-picker #campaignOnePicker></mat-date-range-picker>
                </mat-form-field>
                <small>* Somente pesquisas já finalizadas</small>
            </div>
            <button mat-flat-button (click)="gerarRelatorio()" [disabled]="!formFiltro.cadastroDe || !formFiltro.cadastroAte" class="gs-botaoAzulPadrao m-2">Gerar Relatorio </button>
        </div>
    </div>
</div>