<div class="text-center p-2 header"> Solicitação de pesquisa {{produto}}</div>
<div class="mt-5">
    <div>
        <p class="paragraph" *ngIf="!isDetech">{{data.mensagem}}</p>
        <p class="paragraph" *ngIf="isDetech && !exibirMensagemDataErrada">Este cpf pertence a menor de idade, é necessária a data de aniversário para processar a solicitação</p>
        <p class="paragraph" *ngIf="isDetech && exibirMensagemDataErrada">A data de aniversário informada não pertence ao menor de idade, é necessária a data de aniversário para processar a solicitação</p>
        <div class="d-flex justify-content-center">
            <mat-form-field class="example-full-width teia-form" appearance="outline">
                <input matInput type="date" placeholder="DD/MM/AAAA" [(ngModel)]="dataNascimento">
            </mat-form-field>
        </div>
    </div>
    <div class="d-flex justify-content-between mt-2">
        <button class="btn btn-primary btn-cancel" mat-dialog-close (click)="cancelar()">Cancelar</button>
        <button class="btn btn-primary btn-go" (click)="enviarDataNascimento()" [disabled]="!dataNascimento" *ngIf="!isDetech">Confirmar</button>
        <button class="btn btn-primary btn-go" (click)="enviarDataNascimentoDetech(data.tipoPesquisa)" [disabled]="!dataNascimento" *ngIf="isDetech">Confirmar</button>
    </div>
</div>