<div class="header text-center d-flex justify-content-between">
    <div></div>
    <h2>Editar {{data.label}}</h2>
    <button mat-icon-button aria-label="close dialog" mat-dialog-close style="margin-top: -4px;">
        <mat-icon>close</mat-icon>
    </button>
</div>
<div class="container mt-5">
    <div class="row">
        <div class="col">
            <mat-form-field class="example-full-width d-block" appearance="outline">
                <div class="chip-container">
                    <mat-chip *ngFor="let item of addedItems" (removed)="removeChip(item)"
                        class="rounded-chip d-flex justify-content-center align-items-center">
                        {{ item.tag }}
                        <mat-icon matChipRemove>cancel</mat-icon>
                    </mat-chip>
                </div>
                <mat-label *ngIf="addedItems.length == 0">{{data.label}}</mat-label>
                <input #autoInput matInput type="text" matInput [formControl]="myControl" [matAutocomplete]="auto"
                    (keydown.enter)="onEnter($event)" [ngClass]="addedItems.length > 0 ? 'mt-3' : '' ">
                    <mat-hint class="mt-3">Escreva e aperte ENTER para adicionar a {{data.label}}</mat-hint>
                <mat-autocomplete #auto="matAutocomplete">
                    <mat-option *ngFor="let option of tagIds" class="d-flex justify-content-between">
                        <div class="d-flex justify-content-between">
                            <div (click)="addChip(option)" style="width: -webkit-fill-available;">
                                {{option.tag }}</div>
                            <div>
                                <!-- <mat-icon class="trashIcon" (click)="deletarTagExistente(option)">delete</mat-icon> -->
                            </div>
                        </div>
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
        </div>
    </div>
    <div class="d-flex justify-content-between" style="margin: 10px 0 -24px 0;">
        <button mat-flat-button mat-dialog-close class="cancelar m-3"> Cancelar</button>
        <button  mat-flat-button class="gs-botaoAzulPadrao m-3" (click)="atualizarChips()" *ngIf="pesquisaId"> Salvar Edição</button>
        <button  mat-flat-button class="gs-botaoAzulPadrao m-3" (click)="enviarTagsRefazer()" *ngIf="!pesquisaId"> Continuar</button>
    </div>
</div>

