import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { CityUFService } from 'src/app/shared/services/precificador/UfByProcesso.service';
import { PrecificadorService } from 'src/app/shared/services/precificador/precificador.service';


@Component({
  selector: 'app-step-four',
  templateUrl: './step-four.component.html',
  styleUrls: ['./step-four.component.scss']
})
export class StepFourComponent implements OnInit {

  @Input() precificadorId: number;

  pipe = new DatePipe('pt-BR');

  forms: FormGroup[] = [];
  dadosDaAPI: any[]
  
  formProcessos = [];
  //precificadorId = 0;
  constructor(
    private precificadorService: PrecificadorService,
    private fb: FormBuilder,
    private cityUFService: CityUFService
  ) { }

  ngOnInit() {
    this.precificadorId = this.precificadorService.precificadorIdPaivalue;
    console.log(this.precificadorId)
    this.getCadastrarTempoRecuperacao(this.precificadorId)
  }

  addForm() {
    const form = this.fb.group({
      tipoProcesso: [],
      numeroProcesso: [],
      comarca: [],
      uf: [],
      credor: [],
      id: [0],
      valorCausa: [],
      anoProcesso: [],
      prazoRecuperacao: [],
      valorTotalCustaProcessos: [],
      propostaAcordo: [],
    })
    form.get('numeroProcesso').valueChanges.subscribe(value => {
      if (value && value.length >= 21) {
        const uf = value.slice(18, 20);
        form.get('uf').setValue(this.cityUFService.getUFCode(uf));
      } else {
        form.get('uf').setValue('');
      }
    });
    this.forms.push(form)
  }

  getCadastrarTempoRecuperacao(idPrecificador){
    this.precificadorService.tempoRecuperacao(idPrecificador).subscribe(data => {

      this.dadosDaAPI = data
      if (this.dadosDaAPI && this.dadosDaAPI.length > 0) {

        this.dadosDaAPI.forEach(dado => {
          console.log(dado)
          const form = this.fb.group({
            tipoProcesso: [dado.tipoProcesso],
            numeroProcesso: [dado.numeroProcesso],
            comarca: [dado.comarca],
            uf: [dado.uf],
            credor: [dado.credor],
            id: [dado.id],
            valorCausa: [dado.valorCausa],
            anoProcesso: [dado.anoProcesso],
            prazoRecuperacao: [dado.prazoRecuperacao],
            valorTotalCustaProcessos: [dado.valorTotalCustaProcessos],
            propostaAcordo: [dado.propostaAcordo],
          });
          console.log(form)
  
          this.forms.push(form);
          console.log(this.forms)
        });
      }
    })
  }

  cadastrarTempoRecuperacao(){
    // const formItens = this.formProcessos.map(control => control.value);
    const obj = {
      PrecificadorId: this.precificadorId,
      ObservacaoTempoRecuperacao: '',
      ListRequisicaoTempoRecuperacao: this.forms.map(x =>x.value)
    };
  
    this.precificadorService.cadastrarTempoRecuperacao(obj).subscribe(data => {
    })
  }

  removeForm(index: number) {
    this.forms.splice(index, 1);
  }


}
