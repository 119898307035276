import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Data, Route, Router } from '@angular/router';

@Component({
  selector: 'app-modal-valores-adicionais',
  templateUrl: './modal-valores-adicionais.component.html',
  styleUrls: ['./modal-valores-adicionais.component.scss']
})
export class ModalValoresAdicionaisComponent implements OnInit {

 valorTotal;
 produto;

  constructor(
    private route: Router,
    public dialogRef: MatDialogRef<ModalValoresAdicionaisComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Data,
  ) { }

  ngOnInit(): void {
    console.log(this.route.url)

    if(this.route.url == '/dossie'){
      this.produto = 'Dossiê Completo'
      this.valorTotal = this.data.valorAdicionalTotal;
      console.log(this.data.valorAdicionalTotal)
    }
    if(this.route.url == '/credito'){
      this.produto = 'Analise de Crédito'
      this.valorTotal = this.data.valorAdicionalTotal;
    }
    if(this.route.url == '/cadastrais'){
      this.produto = 'Cadastrais'
      this.valorTotal = this.data.valorAdicionalTotal;
    }
    if(this.route.url == '/patrimonial'){
      this.produto = 'Patrimonial'
      const valores = this.data.map(x => Number(x.fonteConfiguracao.preco ? x.fonteConfiguracao.preco : 0 ))
      const soma = valores.reduce((acumulador, valor) => acumulador + valor, 0);
      this.valorTotal = soma;
  }
  }

  realizarPesquisa(): void {
    this.dialogRef.close('realizarPesquisa');
  }

  fecharModal(): void {
    this.dialogRef.close('cancelar');
  }

}
