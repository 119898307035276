<div class="container">
    <div class="row text-left mb">
        <loading *ngIf="isLoading"></loading>
        <div class="mb-4 form">
            <mat-form-field class="example-form-field" appearance="outline">
                <mat-label>Periodo</mat-label>
                <mat-date-range-input
                  [formGroup]="campaignOne"
                  [rangePicker]="campaignOnePicker"
                  [comparisonStart]="campaignTwo.value.start"
                  [comparisonEnd]="campaignTwo.value.end">
                  <input matStartDate placeholder="DD/MM/AAAA" formControlName="start" [(ngModel)]="formFiltro.cadastroDe" name="CadastroAte">
                  <input matEndDate placeholder="DD/MM/AAAA" formControlName="end" [(ngModel)]="formFiltro.cadastroAte" name="CadastroAte">
                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix [for]="campaignOnePicker"></mat-datepicker-toggle>
                <mat-date-range-picker #campaignOnePicker></mat-date-range-picker>
            </mat-form-field>
            <button mat-flat-button (click)="getFontes()" [disabled]="!formFiltro.cadastroDe || !formFiltro.cadastroAte" class="gs-botaoAzulPadrao m-3">Filtrar <mat-icon>search</mat-icon></button>
        </div>
        <div class="colunas d-flex my-3">
            <div class="col-4 lfr">
                <div class=" total d-flex justify-content-between">
                    <div>
                        <p class="title">Quantidade por Tribunal:</p>
                    </div>
                    <div class="totalValue">
                        <p>{{totalTribunal}}</p>
                    </div>
                </div>    
                
                <table class=" mt-3 table-item d-flex justify-content-between table table-striped flex-column">
                    <tr class="d-flex justify-content-between mb-2" *ngFor="let item of tribunal">
                        <td> {{item.nome}}</td>
                        <td> {{item.total}} </td>
                    </tr>
                </table>
              </div>
        
              <div class="col-4 lft">
                <div class=" total d-flex justify-content-between">
                    <div>
                        <p class="title">Quantidade por Classe Processual:</p>
                    </div>
                    <div class="totalValue">
                        <p>{{totalClasseProcessual}}</p>
                    </div>
                </div>  
                
                <table class="mt-3 table-item d-flex justify-content-between table table-striped flex-column">
                    <tr class="d-flex justify-content-between mb-2" *ngFor="let item of classeProcessual">
                        <th> {{item.nome}}</th>
                        <td> {{item.total}} </td>
                    </tr>
                </table>
              </div>
        
              <div class="col-4 lft">
                <div class=" total d-flex justify-content-between">
                    <div>
                        <p class="title">Quantidade por Polo:</p>
                    </div>
                    <div class="totalValue">
                        <p>{{totalPolo}}</p>
                    </div>
                </div> 
               
                <table class="mt-3 table-item d-flex justify-content-between table table-striped flex-column">
                    <tr class="d-flex justify-content-between mb-2" *ngFor="let item of polo">
                        <th> {{item.nome}}</th>
                        <td> {{item.total}} </td>
                    </tr>
                </table>
              </div>
        </div>
    </div>
</div>