<mat-accordion *ngIf="exibeRegistradores">
    <mat-expansion-panel class="mt-3 py-2" [expanded]="true" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <h3 class="title-cordion mb-0 d-flex align-items-center">
            <mat-icon class="detech-icon">location_city</mat-icon>&nbsp; Registradores Qualificada {{ uf }}
          </h3>
        </mat-panel-title>
      </mat-expansion-panel-header>
  
      <table class="table" *ngIf="!isLoading && !exibeMensagem" style="width: 100%; border-collapse: collapse; table-layout: fixed;">
        <thead class="thead-light">
          <tr class="text-center" *ngIf="data?.quantidadeDias !== null && statusPesquisa > 0">
            <th scope="col" colspan="3" class="thead-top-left p-2">Resultados podem retornar em até 7 dias</th>
            <th scope="col" colspan="2" class="thead p-2" *ngIf="data.quantidadeDias <= 7 && statusPesquisa === 4">
              Sua pesquisa demorou {{ data.quantidadeDias }} dia(s)
            </th>
            <th scope="col" colspan="2" class="thead p-2" *ngIf="data.quantidadeDias > 7 && statusPesquisa === 4">
              Sua pesquisa demorou mais de 7 dias
            </th>
            <th scope="col" colspan="2" class="thead p-2" *ngIf="data.quantidadeDias <= 7 && statusPesquisa !== 4">
              Sua pesquisa está no {{ data.quantidadeDias }} dia
            </th>
            <th scope="col" colspan="2" class="thead p-2" *ngIf="data.quantidadeDias > 7 && statusPesquisa !== 4">
              Sua pesquisa está demorando mais de 7 dias
            </th>
            <th scope="col" colspan="2" class="thead-top-right p-2">
              <div class="d-flex align-items-end largura-fixa" style="justify-content: space-evenly;">
                <div class="circle" [ngClass]="{ 'circle-active': data.quantidadeDias >= day, 'circle-disabled': data.quantidadeDias < day }" *ngFor="let day of [1, 2, 3, 4, 5, 6, 7]">
                  {{ day }}
                </div>
              </div>
            </th>
          </tr>
          <tr *ngIf="statusPesquisa === null">
            <th scope="col" colspan="7" class="thead-top-only p-2">
              <div class="loader dot-space mt-2">
                <span>carregando</span>
                <span class="loader__dot">.</span><span class="loader__dot">.</span><span class="loader__dot">.</span>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngIf="data.dados?.length > 0; else noData">
            <tr class="text-center table-info">
              <td colspan="1"><b>UF</b></td>
              <td><b>Cidade</b></td>
              <td><b>Cartório</b></td>
              <td><b>Matrícula</b></td>
              <td><b>Transcrição</b></td>
              <td colspan="2"><b>Proprietário Atual/Observação</b></td>
            </tr>
            <tr class="text-center table-info" *ngFor="let registrador of data.dados">
              <td colspan="1">{{ uf }}</td>
              <td>{{ registrador?.cidade }}</td>
              <td>{{ registrador?.cartorio }}</td>
              <td>{{ registrador?.matricula }}</td>
              <td>{{ registrador?.numeroTranscricao }}</td>
              <td colspan="2">{{ registrador?.proprietarioAtual }}</td>
            </tr>
          </ng-container>
          <ng-template #noData>
            <tr>
              <td colspan="7" class="text-center">Não há resultados disponíveis</td>
            </tr>
          </ng-template>
        </tbody>
      </table>
  
      <div class="d-flex justify-content-center text-center flex-column" *ngIf="!isLoading && exibeMensagem">
        <div class="d-flex justify-content-end" *ngIf="exibeReprocessar">
          <button mat-flat-button class="gs-botaoAzulPadrao m-3" (click)="reprocessar(idPesquisa, 224)">Refresh <mat-icon>cached</mat-icon></button>
        </div>
        <div>
            <p>{{ data.mensagem }}</p>
        </div>
      </div>
      <loader-only *ngIf="isLoading"></loader-only>
    </mat-expansion-panel>
  </mat-accordion>
  