export enum CruzamentoEnum {
    Aguardando = 0,
  ProcessarCruzamento = 1,
  ProcessandoCruzamento = 2,
  GerarRelatorio = 3,
  GerandoRelatorio = 4,
  GerarPlanilha = 5,
  GerandoPlanilha = 6,
  CruzamentoFinalizado = 7,
  PesquisaCancelada = 8,
  FinalizarPesquisaParcial = 9
}