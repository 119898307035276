import { NgModule, ModuleWithProviders } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './auth.interceptor';
import { AuthGuard } from './auth.guard';

import {
  LoginPage,
  NgxAuthComponent,
  LogoutPage,
  RecuperacaoSenhaPage,
  AlteracaoSenhaPage  
} from './components';

import { AuthRoutingModule } from './auth-routing.module';

@NgModule({
  declarations: [
    LoginPage,
    NgxAuthComponent,
    LogoutPage,
    RecuperacaoSenhaPage,
    AlteracaoSenhaPage
  ],
  imports: [
    AuthRoutingModule,
    ReactiveFormsModule,
    CommonModule
  ],
  exports: [],
  providers: [   
  ],
})
export class AuthModule {
  static forRoot(): ModuleWithProviders<AuthModule> {
    return {
      ngModule: AuthModule,
      providers: [
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        AuthGuard],
    };
  }
}
