<div style="width: 800px;">
    <div class="d-flex justify-content-between">
        <h2>Contratos Encontrados</h2>
        <mat-icon class="icon" mat-dialog-close>close</mat-icon>
    </div>
    <div class="content-contrato" *ngIf="listaAnexos.length > 0">
        <div class="anexos d-flex justify-content-between mt-2" *ngFor="let o of listaAnexos">
            <li>{{o.arquivoNome}}</li>
            <li>{{o.comentario}}</li>
            <li>{{o.dataCadastro | date}}</li>
            <li class="icon" (click)="visualizarContrato(o)" title="Clique para visualizar o contrato">
                <mat-icon>remove_red_eye</mat-icon>
            </li>
        </div>
    </div>

    <div class="d-flex justify-content-center" *ngIf="listaAnexos.length == 0">
        <p>Nenhum contrato encontrado</p>
    </div>
</div>

<ng-template #modalVisualizacaoContrato>
    <div style="width: 800px;">
        <div class="d-flex justify-content-between">
            <h2>{{nomeAnexo}}</h2>
            <mat-icon class="icon" mat-dialog-close>close</mat-icon>
        </div>
        <div>
            <div class="pdf-container" *ngIf="innerHtml" [innerHTML]="innerHtml" ></div>
        </div>
        <div>
            <div class="row">
                <div class="col-lg-10"></div>
                <div class="col-lg-2">
                </div>
            </div>
        </div>
    </div>
</ng-template>


