import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { PesquisaService } from 'src/app/shared/services/http/pesquisa.service';
import { UiService } from 'src/app/shared/services/ui.service';

@Component({
  selector: 'app-sintese-processo',
  templateUrl: './sintese-processo.component.html',
  styleUrls: ['./sintese-processo.component.scss']
})
export class SinteseProcessoComponent implements OnInit {

  @Input() dadosAba: any
  idPesquisa;
  isLoadingSintese = false
  sintese = [];
  documento;
  dadosSintese = []

  objProcessos = [];
  sinteseEmProcessamento = false;
  exibeLinhaTempo = false
  exibeReprocessarSintese = false;
  exibeMensagemAtualizacaoProcesso = false;
  exibeBotaoRefresh = false;


  exibirSinteseProcesso = true;

  constructor(
    public dialog: MatDialog,
    private ui: UiService,
    private toastr: ToastrService,
    private pesquisaService: PesquisaService,
    public sanitizer: DomSanitizer,
    public router: Router,
  ) { }

  ngOnInit(): void {
    console.log(this.dadosAba)
    this.documento = this.dadosAba.titulo.replaceAll('.', '').replaceAll('-', '').replaceAll('/', '');
    if (this.dadosAba.id.id) {
      this.idPesquisa = this.dadosAba.id.id
    }
    else {
      this.idPesquisa = this.dadosAba.id
    }
    this.getProcessosStatus();
  }


  getProcessosStatus() {
    this.isLoadingSintese = true;
    this.exibeReprocessarSintese = false;
    this.exibeBotaoRefresh = false;
    this.sinteseEmProcessamento = false
    let tentativas = 0;

    const tryGetProcessosStatus = () => {
      this.pesquisaService.getSinteseProcesso(this.idPesquisa).subscribe(
        (response) => {
          this.ui.loaded().subscribe(() => {
            console.log(response);
            if (response.codigo === 1000) {
              this.objProcessos = response;
              this.isLoadingSintese = false;
              this.dadosSintese = response.dados;
              this.exibeReprocessarSintese = false
              this.exibeLinhaTempo = true;
            }

            if (response.codigo === 2000 && tentativas <= 4) {
              this.objProcessos = response;
              this.isLoadingSintese = false;
              this.dadosSintese = response.dados;
              this.exibeLinhaTempo = true;
              this.exibeMensagemAtualizacaoProcesso = true;
              setTimeout(() => {
                tentativas++;
                tryGetProcessosStatus();
              }, 15000);

              if (tentativas === 4) {
                this.exibeMensagemAtualizacaoProcesso = true;
                this.exibeBotaoRefresh = true
              }
            }

            if (response.codigo === 4000) {
              this.objProcessos = response;
              this.isLoadingSintese = false;
              this.exibeReprocessarSintese = true;
            }

            if (response.codigo === 3000 && tentativas <= 10) {
              this.objProcessos = response;
              this.exibeReprocessarSintese = true;
              this.sinteseEmProcessamento = true
              setTimeout(() => {
                tentativas++;
                tryGetProcessosStatus();
              }, 6000);

              // Verificar o número máximo de tentativas para exibir o reprocessamento
              if (tentativas === 10) {
                this.exibeReprocessarSintese = true;
                this.exibeBotaoRefresh = true
              }
            }
          });
        },
        (error) => {
          if (error.status === 501) {
            this.exibeReprocessarSintese = true;
          }
        }
      );
    };

    tryGetProcessosStatus();
  }
}
