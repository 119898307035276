<mat-accordion *ngIf="exibeCadastroExpulsoes">
    <mat-expansion-panel class="mt-3 py-2" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <h3 class=" title-cordion mb-0 d-flex align-items-center">
            <mat-icon class="detech-icon">label_important</mat-icon>&nbsp; Desligamentos na administração pública
          </h3>
        </mat-panel-title>
  
      </mat-expansion-panel-header>
      <table class="table" *ngIf="!isLoadingCadastroExpulsoes && !exibeMensagemCadastroExpulsoes">
        <thead class="thead-light">
          <tr class="text-center">
            <th scope="col" class="thead-top-left p-2">Código da Sanção</th>
            <th scope="col" class="thead p-2">Categoria da Sanção</th>
            <th scope="col" class="thead p-2">Número do Processo</th>
            <th scope="col" class="thead p-2">Data Inicio da Sanção</th>
            <th scope="col" class="thead p-2">Data Final da Sanção</th>
            <th scope="col" class="thead p-2">Data Transito em Julgado</th>
            <th scope="col" class="thead p-2">Abrangencia Definida</th>
            <th scope="col" class="thead p-2">Cargo Efetivo</th>
            <th scope="col" class="thead p-2">Orgão de Lotação</th>
            <th scope="col" class="thead-top-right p-2">UF</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngIf="dataCadastroExpulsoes.dados?.length > 0; else noDataCadastroExpulsoes">
            <tr class="text-center table-info" *ngFor="let dados of dataCadastroExpulsoes.dados">
              <td>{{dados?.codigo_da_sancao}}</td>
              <td>{{dados?.categoria_da_sancao}}</td>
              <td>{{dados?.numero_do_processo}}</td>
              <td>{{dados?.data_inicio_sancao}}</td>
              <td>{{dados?.data_final_sancao}}</td>
              <td>{{dados?.data_do_transito_em_julgado}}</td>
              <td>{{dados?.abrangencia_definida_em_decisao_judicial}}</td>
              <td>{{dados?.cargo_efetivo}}</td>
              <td>{{dados?.orgao_de_lotacao}}</td>
              <td>{{dados?.uf_orgao_sancionador}}</td>
            </tr>
          </ng-container>
          <ng-template #noDataCadastroExpulsoes>
            <tr>
              <td colspan="10" class="text-center">Não há resultados disponíveis</td>
            </tr>
          </ng-template>
        </tbody>
      </table>
      <div class="d-flex justify-content-center text-center flex-column" *ngIf="!isLoadingCadastroExpulsoes && exibeMensagemCadastroExpulsoes">
        <div class="d-flex justify-content-end" *ngIf="exibeReprocessarCadastroExpulsoes">
          <button mat-flat-button class="gs-botaoAzulPadrao m-3" (click)="getCadastroExpulsoes(idPesquisa, 129)">Refresh <mat-icon>cached
          </mat-icon></button>
        </div>
        <div>
          <p>{{dataCadastroExpulsoes.mensagem}}</p>
        </div>
      </div>
       <loader-only *ngIf="isLoadingCadastroExpulsoes"></loader-only>
    </mat-expansion-panel>
  </mat-accordion>